<template>
  <div class="StudyConversationStream">
    <StudyConversationMessage
      v-for="(message, index) in visibleMessages"
      :key="message.id"
      :message="message"
      :options="messageOptions(message, index)"
      :stream-parameters="streamParameters"
      class="StudyConversationStream__message"
      @update:message="onUpdateMessage"
      @create="onUpdateMessage"
    ></StudyConversationMessage>

    <StudyConversationStreamAddButton
      v-if="
        conversationStream.state !== StreamState.RESOLVED &&
        conversationStream.state !== StreamState.NOT_ANSWERED &&
        visibleMessages.length &&
        !isSavedMessageSaving
      "
      class="StudyConversationStream__add-button"
      :expanded.sync="isNewMessageExpanded"
    />
    <StudyConversationMessage
      v-if="isNewMessageVisible"
      :key="newMessageKey"
      :options="newMessageOptions"
      :message="savedMessage"
      :stream-parameters="streamParameters"
      class="StudyConversationStream__message StudyConversationStream__new-message"
      send-using-store
      @update:message="onUpdateMessage"
      @create="onCreateMessage"
    />
  </div>
</template>

<script>
import { QuestionModel } from '@/models/question'
import { ConversationStreamModel } from '@/models/conversationStream'
import StudyConversationMessage from '@/components/modules/study/StudyConversationMessage.vue'
import {
  StudyConversationMessageFileSize,
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StreamState } from '@/common/enums'
import StudyConversationStreamAddButton from '@/components/modules/study/conversation-stream/StudyConversationStreamAddButton.vue'
import { StudyModel } from '@/models/study'

export default {
  name: 'StudyConversationStream',
  components: {
    StudyConversationStreamAddButton,
    StudyConversationMessage
  },
  props: {
    study: { type: StudyModel, required: true },
    question: { type: QuestionModel, required: true },
    conversationStream: { type: ConversationStreamModel, default: null }
  },
  data() {
    return {
      isNewMessageExpanded: false,
      localConversationStream: new ConversationStreamModel({
        questionId: this.question.id,
        studyId: this.question.studyId
      }),
      newMessageKey: 1,
      StudyConversationMessageType,
      StudyConversationMessageFileSize,
      fixedMessages: [],
      showFixedMessages: false,
      StreamState
    }
  },
  computed: {
    isNewMessageVisible() {
      return (
        (this.conversationStream.state !== StreamState.RESOLVED &&
          (this.isNewMessageExpanded ||
            (this.conversationStream.state === StreamState.NOT_ANSWERED && !this.visibleMessages.length))) ||
        this.isSavedMessageSaving
      )
    },
    hasAnswer() {
      return this.visibleMessages.length > 0
    },
    visibleMessages() {
      return this.showFixedMessages ? this.fixedMessages : this.messages
    },
    isSavedMessageSaving() {
      return this.savedMessage?.isSaving
    },
    savedMessage() {
      const message = this.$store.getters['studyMessages/savedMessage']({ questionId: this.question.id })
      return message ? new StudyConversationMessageModel(message) : null
    },
    newMessageOptions() {
      return new StudyConversationMessageOptionsModel({
        type: !this.visibleMessages.length
          ? StudyConversationMessageType.ANSWER
          : StudyConversationMessageType.NEW_COMMENT,
        allowToEdit: true,
        fileSliderSize: StudyConversationMessageFileSize.SMALL,
        allowToRemove: true
      })
    },
    messages() {
      return (
        this.conversationStream?.messages
          ?.filter((m) => !m.deletedAt)
          ?.sort((a, b) => a.createdAt - b.createdAt) || []
      )
    },
    streamParameters() {
      return new StudyConversationMessageStreamParametersModel({
        question: this.question,
        prospect: this.$store.getters['user/userObject'],
        studyId: this.question.studyId
      })
    },
    isLastMessageFromModerator() {
      return this.visibleMessages?.[this.visibleMessages?.length - 1]?.user
    }
  },
  watch: {
    hasAnswer: {
      handler() {
        this.newMessageKey = Math.random() * 8000
      }
    },
    isLastMessageFromModerator: {
      handler() {
        if (this.isLastMessageFromModerator || this.savedMessage) {
          this.isNewMessageExpanded = true
        }
      },
      immediate: true
    },
    conversationStream: {
      handler() {
        if (!this.$_.isEqual(this.localConversationStream, this.conversationStream)) {
          this.localConversationStream = new ConversationStreamModel(this.conversationStream)
        }
      },
      deep: true,
      immediate: true
    },
    isSavedMessageSaving: {
      handler(to) {
        if (to) {
          this.fixedMessages = [...this.messages]
          this.showFixedMessages = true
        } else {
          this.showFixedMessages = false
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdateMessage(message) {
      this.$emit('updateMessage', { message })
    },
    onCreateMessage({ message }) {
      this.$emit('updateMessage', { message })
      this.newMessageKey = Math.random() * 8000
      this.isNewMessageExpanded = false
    },
    messageOptions(message, index) {
      return new StudyConversationMessageOptionsModel({
        type: index === 0 ? StudyConversationMessageType.ANSWER : StudyConversationMessageType.COMMENT,
        allowToEdit:
          this.conversationStream.state !== StreamState.RESOLVED && this.study.allowResponseEditing,
        fileSliderSize: StudyConversationMessageFileSize.SMALL,
        allowToRemove:
          ((index === 0 && this.visibleMessages.length === 1) || index !== 0) &&
          this.conversationStream.state !== StreamState.RESOLVED &&
          this.study.allowResponseEditing,
        elementsVisibility: {
          header: true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationStream {
  $root: &;
  &__message {
    @apply mt-8;
    &:first-child {
      @apply mt-0;
    }
  }
  &__add-button {
    @apply mt-4;
    & + #{$root}__new-message {
      @apply mt-4;
    }
  }
}
</style>
