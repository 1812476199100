import { Connection } from '@/plugins/conversationWebsocket'
import store from '../../store'
import Vue from 'vue'
import globalRoutes from '@/utils/globalRoutes'
import { InfoPopup } from '@/store/modules/infoPopup'

let host = globalRoutes.api?.replace('http', 'ws')

new Vue({
  data() {
    return {
      connection: null
    }
  },
  computed: {
    authToken() {
      return store.state.auth.token ? store.state.auth.token.replace('Bearer ', '') : null
    }
  },
  watch: {
    authToken: {
      immediate: true,
      handler() {
        if (this.authToken) {
          if (!this.connection) {
            this.connection = new Connection(`${host}/api/pwa/ws`)
            this.connection.on('_reopen', this.sendToken)
            this.connection.on('_open', this.sendToken)
            this.connection.on('conversationStreamMessage', (result) => {
              if (!store.getters['studies/isMessageSending']({ questionId: result.questionId })) {
                store.dispatch('studies/addNewConversationStream', {
                  questionId: result.questionId,
                  newMessage: result.conversationMessage
                })
              }
            })
            this.connection.on('showPopup', (result) => {
              store.commit('infoPopup/ADD_INFO_POPUP', {
                infoPopup: new InfoPopup({
                  message: result?.popupText
                })
              })
            })
            this.connection.on('prospectAccountUpdate', ({ prospectAccount }) => {
              store.commit('user/UPDATE_USER', prospectAccount)
            })
            /*this.connection.on('conversationStreamState', ({ questionId, state }) => {
              store.commit('studies/UPDATE_CONVERSATION_STREAM_STATE', { questionId, state })
            })
            this.connection.on('studyUpdate', ({ study }) => {
              store.commit('studies/UPDATE_STUDY', { study })
            })
            this.connection.on('topicsUpdate', ({ study }) => {
              store.commit('studies/UPDATE_TOPICS', { study })
            })
            this.connection.on('screeningQuestionsUpdate', ({ study }) => {
              store.commit('studies/UPDATE_SCREENING_QUESTIONS', { study })
              store.commit('studies/UPDATE_SCREENING_QUESTIONS', { study })
            })*/
          } else {
            this.connection.reconnect()
          }
        } else {
          if (this.connection) {
            this.connection.close()
          }
        }
      }
    }
  },
  methods: {
    sendToken() {
      this.connection.request('token', this.authToken)
    }
  }
})
