<template>
  <div class="ConversationHead">
    <div
      v-if="studyState.isInvited && userName"
      class="ConversationHead__inner bg-neutral-02 text-primary-01-400"
    >
      {{ $t('you_are_invited_to_a_conversation', { name: userName }) }}
    </div>
    <div
      v-if="studyState.isInvited && !userName"
      class="ConversationHead__inner bg-neutral-02 text-primary-01-400"
    >
      {{ $t('you_are_invited_to_a_conversation_empty') }}
    </div>
    <div v-if="studyState.isPendingApproval" class="ConversationHead__inner bg-neutral-01-400 text-white">
      {{ $t('pending_approval_for_a_conversation') }}
    </div>
    <div v-if="studyState.isLive" class="ConversationHead__inner bg-accent-02 text-white">
      {{ $t('live_conversation') }}
    </div>
    <div v-if="studyState.isWaiting" class="ConversationHead__inner bg-success-01 text-white">
      {{ $t('waiting_to_start') }}
    </div>
  </div>
</template>

<script>
import { StudyStates } from '@/mixins/studyStates'

export default {
  name: 'ConversationHead',
  mixins: [StudyStates],
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    userName() {
      return this.$store.state.user.user.firstName
    }
  }
}
</script>

<style lang="scss" scoped>
.ConversationHead {
  .ConversationHead__inner {
    @apply w-full px-4 py-2 font-bold text-2xl text-center font-serif rounded-tl rounded-tr;
  }
}
</style>
