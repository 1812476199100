import { PollModel } from '@/models/poll'

export class QuestionPollModel extends PollModel {
  constructor(poll) {
    const { studyId, questionId } = poll || {}
    super(poll)

    this.studyId = studyId || null
    this.questionId = questionId || null
  }

  static parseFromApi({ poll, studyId, questionId }) {
    return new QuestionPollModel({
      ...PollModel.parseFromApi({ poll }),
      studyId,
      questionId
    })
  }
}
