<template>
  <div class="ForumChatEditorDetailsContent">
    <div v-if="localChat.image" class="ForumChatEditorDetailsContent__avatar">
      <div class="ForumChatEditorDetailsContent__avatar-helper">
        <ImageLoader
          class="ForumChatEditorDetailsContent__avatar-image"
          :file="localChat.image"
          large
        ></ImageLoader>
      </div>
    </div>
    <div class="ForumChatEditorDetailsContent__info-wrapper">
      <div class="ForumChatEditorDetailsContent__header">
        <h2 class="ForumChatEditorDetailsContent__title">
          {{ localChat.name }}
        </h2>
        <div v-if="isFromEditor && !isActionsHidden" class="ForumChatEditorDetailsContent__actions-desktop">
          <router-link
            class="ForumChatEditorDetailsContent__actions-desktop-action"
            :to="`/chats/${localChat.id}/search`"
          >
            <LLButton small secondary>
              <template #icon-left>
                <SearchIcon />
              </template>
            </LLButton>
          </router-link>
        </div>
        <div v-if="isFromEditor && !isActionsHidden" class="ForumChatEditorDetailsContent__actions-mobile">
          <LLOptionsList placement="bottom-end" :offset="[0, 0]">
            <template #trigger="{ opened }">
              <LLButton tertiary small :arrow-opened="opened" :loading="pendingStates.isUpdatingChatState">
                <template #icon-left>
                  <OptionsIcon />
                </template>
              </LLButton>
            </template>
            <template #buttons>
              <router-link :to="`/chats/${localChat.id}/search`">
                <LLOptionsListButton v-if="isFromEditor" small>
                  {{ $t('forum.chat_page.search_button') }}
                </LLOptionsListButton>
              </router-link>
            </template>
          </LLOptionsList>
        </div>
      </div>
      <div class="ForumChatEditorDetailsContent__description">
        <div class="ForumChatEditorDetailsContent__description-title">
          {{ $t('forum.chat_page.description_title') }}
        </div>
        <TextHider
          :lines="descriptionLines"
          absolute-block
          class="ForumChatEditorDetailsContent__description-text"
        >
          <LLWysiwygOutput :text="localChat.description"></LLWysiwygOutput>
        </TextHider>
      </div>
      <div class="ForumChatEditorDetailsContent__values">
        <LLMetaIconInfo
          class="ForumChatEditorDetailsContent__value"
          :text="$tc('common_amount_no_digits_members', localChat.meta.memberCount)"
          :value="localChat.meta.memberCount"
        >
          <ParticipantsIcon />
        </LLMetaIconInfo>
        <LLMetaIconInfo
          class="ForumChatEditorDetailsContent__value"
          :text="$tc('common_amount_no_digits_threads', localChat.meta.threadCount)"
          :value="localChat.meta.threadCount"
        >
          <TopicRedIcon />
        </LLMetaIconInfo>
      </div>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
import LLOptionsList from '@/components/common/LLOptionsList'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton'
import TextHider from '@/components/common/TextHider'
import { screenCatcher } from '@/mixins/screenCatcher'
import TopicRedIcon from '@/assets/icons/TopicRedIcon'
import LLMetaIconInfo from '@/components/atoms/LLMetaIconInfo'
import ParticipantsIcon from '@/assets/icons/ParticipantsIcon'
import { ForumChatModel } from '@/models/forum/forumChat'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
import { ForumChatState } from '@/common/enums'
import OptionsIcon from '@/assets/icons/OptionsIcon'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
export default {
  name: 'ForumChatEditorDetailsContent',
  components: {
    ImageLoader,
    OptionsIcon,
    LLWysiwygOutput,
    ParticipantsIcon,
    LLMetaIconInfo,
    TopicRedIcon,
    TextHider,
    LLOptionsListButton,
    LLOptionsList,
    LLButton
  },
  mixins: [screenCatcher],
  props: {
    chat: { type: ForumChatModel, default: null },
    isFromEditor: { type: Boolean, default: false },
    isActionsHidden: { type: Boolean, default: false }
  },
  data() {
    return {
      localChat: null,
      pendingStates: {
        isUpdatingChatState: false
      }
    }
  },
  computed: {
    descriptionLines() {
      if (this.tailwindScreens.lg) {
        return 3
      } else if (this.tailwindScreens.md) {
        return 3
      } else if (!this.tailwindScreens.sm) {
        return 5
      }
      return 1
    },
    chatStates() {
      return ForumChatState
    },
    isChatWechat() {
      return this.chat?.isWechat
    }
  },
  watch: {
    chat: {
      handler() {
        if (!this.$_.isEqual(this.chat, this.localChat)) {
          this.localChat = new ForumChatModel(this.chat)
        }
      },
      deep: true
    }
  },
  created() {
    this.localChat = new ForumChatModel(this.chat)
  },
  methods: {
    onEdit() {
      this.$emit('enableEdit')
    },
    onUpdateChat({ chat }) {
      this.$emit('updateChat', { chat: new ForumChatModel(chat) })
      this.localChat = chat
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatEditorDetailsContent {
  @apply flex items-start;
  @apply flex-col;
  @screen sm {
    @apply flex-row;
  }
  &__avatar {
    @apply w-full flex-shrink-0 relative rounded overflow-hidden;
    padding-top: 100%;
    @screen sm {
      width: 140px;
      height: 140px;
      padding-top: 0;
      @apply mr-7;
    }
    @screen md {
      width: 200px;
      height: 200px;
      padding-top: 0;
    }
    @screen lg {
      width: 260px;
      height: 260px;
      padding-top: 0;
    }
    &-helper {
      @apply w-full h-full absolute top-0 left-0 bg-neutral-01-75;
    }
    &-image {
      @apply w-full h-full object-cover rounded;
    }
  }
  &__state-label {
    @apply absolute top-0 left-0;
  }
  &__info-wrapper {
    @apply flex-1 mt-4 w-full min-w-0;
    @screen sm {
      @apply mt-0 flex flex-col self-stretch;
    }
  }
  &__header {
    @apply flex min-w-0 items-start;
  }
  &__title {
    @apply min-w-0 flex-1 break-words w-full;
    @screen sm {
      @apply truncate mt-0;
    }
  }
  &__top-actions {
    @apply ml-8;
  }
  &__actions-desktop {
    @apply ml-8;
    @screen lg {
      @apply flex flex-shrink-0;
    }
    @apply hidden;
    &-action {
      @apply ml-8;
      &:first-child {
        @apply ml-0;
      }
    }
  }
  &__actions-mobile {
    @screen sm {
      @apply ml-4;
    }
    @apply flex flex-shrink-0;
    @screen lg {
      @apply hidden;
    }
  }
  &__description {
    @apply mt-4 flex-1;
    &-title {
      @apply text-button-01;
    }
    &-text {
      @apply mt-2;
    }
  }
  &__values {
    @apply mt-4 flex flex-col;
    @screen sm {
      @apply flex-row;
    }
    @screen lg {
      @apply flex-col;
    }
  }
  &__value {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
    @screen sm {
      @apply mt-0 ml-4;
      &:first-child {
        @apply ml-0;
      }
    }
    @screen lg {
      @apply ml-0 mt-2;
    }
  }
  &__wechat-icon {
    @apply absolute w-7 right-0 top-0 mr-1 mt-0;
  }
}
</style>
