<template>
  <div class="QuestionValue flex-col">
    <LLWysiwygOutput
      class="text-primary-01-400 font-bold mb-4"
      data-e2e="questionBody"
      :text="questionBody"
    ></LLWysiwygOutput>
    <div
      v-if="questionAttachedFiles.length"
      class="QuestionValue__file-slider-wrapper mob-only:w-full"
      data-e2e="questionValue"
    >
      <!--      <LLFileGallery :files="question.attachedFiles"></LLFileGallery>-->
      <LLFileSlider
        class="QuestionValue__file-slider"
        pre-large
        small-container
        :files="questionAttachedFiles"
      ></LLFileSlider>
    </div>
  </div>
</template>

<script>
import LLFileSlider from '@/components/common/LLFileSlider'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
export default {
  name: 'QuestionValue',
  components: { LLWysiwygOutput, LLFileSlider },
  props: {
    question: {
      type: Object,
      default: null
    }
  },
  computed: {
    questionAttachedFiles() {
      return [...(this.question?.files || [])].sort((a, b) => a.order - b.order)
    },
    image() {
      if (this.question.image) {
        return (
          'https://test-afterlogic.s3.amazonaws.com/' +
          this.question.image +
          '?AWSAccessKeyId=AKIA44EROZIHNTZU2HNX&Expires=1607698244&Signature=6zdjXdyuKBakcJiUJm%2FqvRbVz5I%3D'
        )
      } else {
        return null
      }
    },
    questionBody() {
      return this.question?.body
    }
  }
}
</script>

<style lang="scss" scoped>
.QuestionValue {
  $root: &;
  &__file-slider-wrapper {
    @apply min-w-0 flex-shrink-0 mb-4;
    width: 190px;
    @screen mob-only {
      @apply w-full;
    }
    #{$root}__file-slider {
    }
  }
}
</style>
