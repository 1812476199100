<template>
  <div class="StudyConversationMessageActions">
    <LLOptionsList class="StudyConversationMessageActions__button">
      <template #trigger="{ opened }">
        <LLButton tiny tertiary :active="opened">
          <template #icon-left><OptionsIcon /></template>
        </LLButton>
      </template>
      <template #buttons>
        <LLOptionsListButton v-if="permissions.edit" v-popper-close small @click="onEditClick">
          {{ $t('button_edit') }}
        </LLOptionsListButton>
        <LLOptionsListButton v-if="permissions.remove" v-popper-close small @click="onRemoveClick">
          {{ $t('button_remove') }}
        </LLOptionsListButton>
      </template>
    </LLOptionsList>
    <portal to="confirm-modal">
      <LLConfirmModalTransition v-if="isRemoveConfirmationOpened">
        <LLConfirmModal :max-width="600">
          <template #title>{{ $t('study_conversation_message.remove_confirmation_modal.title') }}</template>
          <template #default>
            {{ $t('study_conversation_message.remove_confirmation_modal.message') }}
          </template>
          <template #actions>
            <LLButton :loading="isRemovePending" primary small @click="remove">{{
              $t('button_remove')
            }}</LLButton>
            <LLButton
              :disabled="isRemovePending"
              secondary
              small
              @click="isRemoveConfirmationOpened = false"
              >{{ $t('button_cancel') }}</LLButton
            >
          </template>
        </LLConfirmModal>
      </LLConfirmModalTransition>
    </portal>
  </div>
</template>

<script>
import OptionsIcon from '@/assets/icons/OptionsIcon.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'

export default {
  name: 'StudyConversationMessageActions',
  components: {
    LLConfirmModalTransition,
    LLConfirmModal,
    LLOptionsListButton,
    LLOptionsList,
    LLButton,
    OptionsIcon
  },
  props: {
    message: { type: StudyConversationMessageModel, required: true },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true },
    permissions: { type: Object, required: true }
  },
  data() {
    return {
      StudyConversationMessageType,
      isRemovePending: false,
      isRemoveConfirmationOpened: false
    }
  },
  methods: {
    onEditClick() {
      this.$emit('edit')
    },
    onRemoveClick() {
      this.isRemoveConfirmationOpened = true
    },
    async remove() {
      if (this.isRemovePending) return
      this.isRemovePending = true
      try {
        const { studyId, questionId, prospectId } = this.message
        const params = { studyId, questionId, prospectId, messageId: this.message?.id }

        const { conversationMessage: message } = await this.$api.studies.removeConversationStream(params)
        this.localMessage = StudyConversationMessageModel.parseFromApi({
          message,
          ...this.message,
          pollType:
            (this.options.type === StudyConversationMessageType.ANSWER &&
              this.streamParameters?.question?.poll?.type) ||
            null
        })
        this.$emit('updateMessage', { message: new StudyConversationMessageModel(this.localMessage) })
        this.isRemoveConfirmationOpened = false
      } catch (e) {
        this.$toast.error(this.$getErrorMessage(e))
        this.isRemovePending = false
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageActions {
  &__button {
  }
}
</style>
