<template>
  <QuestionsStars
    v-model="localResponse.numberValue"
    :amount="poll.starsAmount"
    :locked="locked"
  ></QuestionsStars>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'
import QuestionsStars from '@/pages/Conversation/Question/QuestionAnswer/QuestionsStars.vue'

export default {
  name: 'PollNumberEditorStars',
  components: { QuestionsStars },

  props: {
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    locked: { type: Boolean, default: false }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    },
    isLocalResponseValid() {
      return !!this.localResponse.numberValue || this.localResponse.numberValue === 0
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      deep: true
    },
    isLocalResponseValid: {
      handler() {
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberEditorStars {
}
</style>
