<template>
  <div class="ForumChatThreadCardHeader" :class="classList">
    <div class="ForumChatThreadCardHeader__main">
      <div class="ForumChatThreadCardHeader__name-wrapper">
        <router-link
          v-if="hasLink"
          :to="link"
          tag="a"
          class="ForumChatThreadCardHeader__name ForumChatThreadCardHeader__name_link"
          @click.native="onLinkClick"
        >
          {{ thread.title }}
        </router-link>
        <span v-else class="ForumChatThreadCardHeader__name">
          {{ thread.title }}
        </span>
      </div>
      <div v-if="!hideActions && !compact" class="ForumChatThreadCardHeader__main-aside">
        <ForumChatThreadCardHeaderMainAside
          :thread="thread"
          :is-reaction-animations-enabled="isReactionAnimationsEnabled"
          :compact="compact"
          :mobile="mobile"
          :dark="dark"
          @reactionSelect="onReactionSelect"
        >
          <template #actions>
            <slot name="actions"> </slot>
          </template>
        </ForumChatThreadCardHeaderMainAside>
      </div>
    </div>
    <ForumChatThreadCardHeaderMeta
      class="ForumChatThreadCardHeader__meta"
      :thread="thread"
      :dark="dark"
      :mobile="mobile"
      :compact="compact"
    />
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatThreadCardHeaderMainAside from '@/components/modules/forum-chat/new-thread-card/header/ForumChatThreadCardHeaderMainAside.vue'
import ForumChatThreadCardHeaderMeta from '@/components/modules/forum-chat/new-thread-card/header/ForumChatThreadCardHeaderMeta.vue'

export default {
  name: 'ForumChatThreadCardHeader',
  components: { ForumChatThreadCardHeaderMeta, ForumChatThreadCardHeaderMainAside },
  props: {
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    hasLink: { type: Boolean, default: true },
    thread: { type: ForumThreadModel, required: true },
    dark: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    isReactionAnimationsEnabled: { type: Boolean, default: true }
  },
  data() {
    return {}
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact,
        [`${this.$options.name}_dark`]: this.dark
      }
    },
    link() {
      return `/chats/${this.thread.chatId}/threads/${this.thread.id}`
    }
  },
  methods: {
    onReactionSelect({ reaction }) {
      this.$emit('reactionSelect', { reaction })
    },
    onLinkClick() {
      if (this.$route.path === this.link) {
        window.location.reload()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeader {
  $root: &;
  @apply py-4 px-6 bg-neutral-01-25 w-full;
  &_compact,
  &_mobile {
    @apply px-4;
  }
  &_dark {
    @apply bg-primary-01-400 text-white;
  }
  &__main {
    @apply flex justify-between w-full min-w-0;
  }
  &__name-wrapper {
    @apply mr-4 min-w-0 w-full flex-1;
    &:last-child {
      @apply mr-0;
    }
  }
  &__main-aside {
    @apply flex-shrink-0;
  }
  &__name {
    @apply break-words text-primary-01-400 text-button-01 min-w-0 block;
    @at-root #{$root}_compact & {
      @apply text-body-02 truncate w-full;
    }
    @at-root #{$root}_mobile & {
      @apply text-body-02;
    }
    @at-root #{$root}_dark & {
      @apply text-white;
    }
    &_link {
      @apply transition duration-200;
      &:hover {
        @apply text-primary-01-200;
      }
      &:focus-visible {
        @apply text-primary-01-200;
      }
      &:active {
        @apply text-primary-01-600;
      }
    }
  }
  &__meta {
    @apply mt-2;
  }
  /*&__meta {
    @apply mt-1 flex flex-col items-end;
    @screen md {
      @apply flex justify-between w-full flex-row;
    }
  }*/
}
</style>
