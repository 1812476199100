<template>
  <div class="LLFileSliderFile" :class="classList" data-e2e="iconImage" @click="openFile">
    <div v-if="isVideo" class="LLFileSliderFile__video-backplate"></div>
    <div v-if="!src && thumbnailGeneratingError" class="LLFileSliderFile__unsupported-extension">
      .{{ fileExtension }}
    </div>
    <img v-if="src && !thumbnailPosition" ref="image" :src="src" class="LLFileSliderFile__image" />
    <div v-if="src && thumbnailPosition" class="LLFileSliderFile__image-overflow">
      <img
        ref="overflowed-image"
        :src="src"
        :style="thumbnailPositionStyle"
        class="LLFileSliderFile__image-overflow-image"
      />
    </div>
    <div v-if="isPdf" ref="pdf" class="flex items-center justify-center w-full h-full text-neutral-01-800">
      <svg class="icon fill-current" xmlns="http://www.w3.org/2000/svg" height="48" width="48">
        <path
          d="M16.55 26.45H18.4V22.3H20.8Q21.6 22.3 22.125 21.775Q22.65 21.25 22.65 20.45V18.05Q22.65 17.25 22.125 16.725Q21.6 16.2 20.8 16.2H16.55ZM18.4 20.45V18.05H20.8V20.45ZM24.85 26.45H29.05Q29.8 26.45 30.35 25.925Q30.9 25.4 30.9 24.6V18.05Q30.9 17.25 30.35 16.725Q29.8 16.2 29.05 16.2H24.85ZM26.7 24.6V18.05H29.05V24.6ZM33.35 26.45H35.2V22.3H37.7V20.45H35.2V18.05H37.7V16.2H33.35ZM13 38Q11.8 38 10.9 37.1Q10 36.2 10 35V7Q10 5.8 10.9 4.9Q11.8 4 13 4H41Q42.2 4 43.1 4.9Q44 5.8 44 7V35Q44 36.2 43.1 37.1Q42.2 38 41 38ZM13 35H41Q41 35 41 35Q41 35 41 35V7Q41 7 41 7Q41 7 41 7H13Q13 7 13 7Q13 7 13 7V35Q13 35 13 35Q13 35 13 35ZM7 44Q5.8 44 4.9 43.1Q4 42.2 4 41V10H7V41Q7 41 7 41Q7 41 7 41H38V44ZM13 7Q13 7 13 7Q13 7 13 7V35Q13 35 13 35Q13 35 13 35Q13 35 13 35Q13 35 13 35V7Q13 7 13 7Q13 7 13 7Z"
        />
      </svg>
    </div>
    <div v-if="isVideo" class="LLFileSliderFile__video-wrapper">
      <div class="LLFileSliderFile__video">
        <PlayIcon class="LLFileSliderFile__video-icon"></PlayIcon>
      </div>
    </div>
    <div v-if="isVideo && isTemporary && !src" class="invisible fixed top-0 left-0 w-40 h-40 z-50">
      <canvas ref="thumbnail-helper-canvas" class="" width="100" height="100"></canvas>
      <video
        v-if="isVideo && isTemporary && !src && realVideoDataUrl"
        ref="thumbnail-helper-video"
        muted
        preload="auto"
        :src="realVideoDataUrl"
        class=""
      ></video>
    </div>
    <div v-if="isUploading" class="LLFileSliderFile__uploading">
      <div class="LLFileSliderFile__uploading-background"></div>
      <div class="LLFileSliderFile__uploading-progress">
        <LLRoundProgress2
          :percent="loadedPercentage"
          :percent-secondary="0"
          :color="'white'"
          :color-secondary="'transparent'"
          :stroke-width="2"
          :base-stroke-width="0"
          micro
        ></LLRoundProgress2>
      </div>
    </div>
  </div>
</template>

<script>
import PlayIcon from '@/assets/icons/PlayIcon'
import LLRoundProgress2 from '@/components/common/ui-components/LLRoundProgress2'
/**
 * File of file slider
 * @version 1.0.0
 */
export default {
  name: 'LLFileSliderFile',
  components: { LLRoundProgress2, PlayIcon },
  props: {
    file: { type: Object, required: true },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  data() {
    return {
      extension: false,
      src: null,
      widthLargerThanHeight: null,
      realVideoDataUrl: null,
      loadingThumbnailError: false,
      thumbnailLoading: false,
      thumbnailGenerating: false,
      thumbnailLoadingError: false,
      thumbnailGeneratingError: false
    }
  },
  computed: {
    classList() {
      const classes = []
      const rootClass = 'LLFileSliderFile'
      if (!this.src) {
        classes.push(`${rootClass}_with-backplate`)
      }
      return classes
    },
    thumbnailPositionStyle() {
      if (this.thumbnailPosition) {
        return {
          left: `-${this.thumbnailPosition?.left}%`,
          top: `-${this.thumbnailPosition?.top}%`,
          width: this.widthLargerThanHeight === false ? '100%' : 'auto',
          height: this.widthLargerThanHeight ? '100%' : 'auto'
        }
      } else {
        return null
      }
    },
    fileExtension() {
      return this.file.extension
    },
    isImage() {
      return this.file.mimetype.substr(0, 4) === 'imag'
    },
    isVideo() {
      return this.file.mimetype.substr(0, 4) === 'vide'
    },
    isPdf() {
      return this.file.mimetype === 'application/pdf'
    },
    thumbnailPosition() {
      return this.file.thumbnailPosition
    },
    thumbnail250() {
      return this.file.thumbnails.find((t) => t.size === 250)?.location
    },
    thumbnail50() {
      return this.file.thumbnails.find((t) => t.size === 50)?.location
    },
    thumbnail80() {
      return this.file.thumbnails.find((t) => t.size === 80)?.location
    },
    isTemporary() {
      return !!this.file?.isTemporary
    },
    temporaryData() {
      return this.file?.temporaryData
    },
    browserFile() {
      return this.temporaryData?.browserFile
    },
    uploadingState() {
      return this.$store.getters['fileUpload/uploadingFileByUUID'](this.file.id)
    },
    isUploading() {
      return !!this.uploadingState
    },
    loadedPercentage() {
      return this.$store.getters['fileUpload/filesLoadedPercentage']([this.file.id])
    }
  },
  watch: {
    file: {
      handler() {
        this.renderThumbnail()
      },
      deep: true
    }
  },
  mounted() {
    this.renderThumbnail()
  },
  methods: {
    renderThumbnail() {
      if (!this.isTemporary) {
        this.tryThumbnail()
      } else {
        this.generateThumbnailFromFile()
      }
    },
    openFile() {
      if (!this.thumbnailGeneratingError && !this.thumbnailGenerating && !this.isUploading) {
        this.$emit('open')
      }
    },
    tryThumbnail() {
      this.thumbnailLoading = true
      let attempts = 0
      const sizes = [
        { size: 50, url: this.thumbnail50 },
        { size: 80, url: this.thumbnail80 },
        { size: 250, url: this.thumbnail250 },
        { size: 'original', url: this.file.location }
      ]
      const getSize = (size) => sizes.find((s) => s.size === size)
      const tryLoad = (size) => {
        this.loadImage(size.url, () => {
          if (size.size === 'original' && this.isVideo) return
          if (sizes[sizes.indexOf(size) + 1]) {
            tryLoad(sizes[sizes.indexOf(size) + 1])
          } else {
            if (attempts < 5) {
              setTimeout(() => {
                tryLoadDependsOnSize()
              }, 2000)
              attempts++
            }
          }
        })
      }
      const tryLoadDependsOnSize = () => {
        if (this.small) {
          tryLoad(getSize(50))
        } else if (this.large) {
          tryLoad(getSize(250))
        } else {
          tryLoad(getSize(80))
        }
      }
      tryLoadDependsOnSize()
    },
    loadImage(src, onError) {
      if (!src) {
        onError()
        return
      }
      const newImg = new Image()
      newImg.src = src
      newImg.addEventListener('load', () => {
        this.widthLargerThanHeight = newImg.width > newImg.height
        this.imageLoaded(src)
        newImg.remove()
      })
      if (onError) {
        newImg.addEventListener('error', onError)
      }
    },
    imageLoaded(src) {
      this.thumbnailLoading = false
      this.thumbnailGenerating = false
      this.src = src
    },
    generateThumbnailFromFile() {
      this.thumbnailGenerating = true
      try {
        if (this.isImage) {
          let src = URL.createObjectURL(this.browserFile)
          const newImg = new Image()
          newImg.addEventListener('load', () => {
            this.widthLargerThanHeight = newImg.width > newImg.height
            this.imageLoaded(src)
            newImg.remove()
          })
          newImg.addEventListener('error', () => {
            this.thumbnailGeneratingError = true
            this.thumbnailGenerating = false
          })
          newImg.src = src
        } else if (this.isVideo) {
          this.realVideoDataUrl = URL.createObjectURL(this.browserFile)

          try {
            this.$nextTick(() => {
              try {
                let videoBlock = this.$refs['thumbnail-helper-video']
                let takeShot = () => {
                  try {
                    let canvas = this.$refs['thumbnail-helper-canvas']
                    canvas.width = videoBlock.videoWidth
                    canvas.height = videoBlock.videoHeight
                    canvas
                      .getContext('2d')
                      .drawImage(videoBlock, 0, 0, videoBlock.videoWidth, videoBlock.videoHeight)
                    this.src = canvas.toDataURL('image/jpg')
                    videoBlock.removeEventListener('canplaythrough', takeShot)
                  } catch (e) {
                    this.$api.system.sendError({ message: e.message, stack: e.stack, e })
                  }
                }
                videoBlock.addEventListener('canplaythrough', takeShot)
              } catch (e) {
                this.$api.system.sendError({ message: e.message, stack: e.stack, e })
              }
            })
          } catch {
            this.$api.system.sendError({ message: e.message, stack: e.stack, e })
          }
        }
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileSliderFile {
  $root: &;
  @apply rounded overflow-hidden relative;
  &__video-backplate {
    @apply absolute w-full h-full bg-black;
  }
  &__loading {
    @apply w-full h-full bg-neutral-01-50 rounded;
  }
  @apply w-full h-full rounded;
  &_with-backplate {
    @apply bg-neutral-01-50;
  }
  &__image-wrapper {
    @apply w-full h-full rounded overflow-hidden;
  }

  #{$root}__image {
    @apply w-full h-full object-cover absolute;
  }
  &__unsupported-extension {
    @apply flex items-center justify-center w-full h-full text-neutral-01-400 text-caption-01 absolute;
  }
  &__video-wrapper {
    @apply w-full h-full rounded overflow-hidden absolute;
    #{$root}__video {
      &-icon {
        width: 25%;
        height: 25%;
      }
      @apply w-full h-full flex items-center justify-center rounded overflow-hidden;
    }
  }
  &__image-overflow {
    @apply w-full h-full overflow-hidden relative;
    &-image {
      min-width: 100%;
      min-height: 100%;
      max-width: initial;
      max-height: initial;
      @apply absolute;
    }
  }
  &__uploading {
    @apply absolute w-full h-full;
    &-background {
      @apply absolute w-full h-full bg-primary-01 opacity-30;
    }
    &-progress {
      @apply absolute w-full h-full flex items-center justify-center;
    }
  }
}
</style>
