<template>
  <div class="PollMatrixEditorCompact">
    <div v-for="row in rows" :key="row.id" class="PollMatrixEditorCompact__item">
      <span class="PollMatrixEditorCompact__row PollMatrixEditorCompactRow">
        <div class="PollMatrixEditorCompactRow__row-text">
          {{ row.text }}
        </div>
        <div v-if="row.image && isLabelVisible" class="PollMatrixEditorCompactRow__image-wrapper">
          <LLFileSlider
            :pre-large="showLargeThumbnail"
            :medium="showLargeThumbnail"
            :files="[row.image]"
            :small="!showLargeThumbnail"
          ></LLFileSlider>
        </div>
      </span>
      <span class="PollMatrixEditorCompact__column">{{ columnText(row.id) }}</span>
    </div>
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import LLFileSlider from '@/components/common/LLFileSlider.vue'

export default {
  name: 'PollMatrixEditorCompact',
  components: { LLFileSlider },
  props: {
    value: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: false },
    poll: { type: PollModel, required: true }
  },
  computed: {
    showLargeThumbnail() {
      return this.poll.isThumbnailsLarge
    },
    isLabelVisible() {
      return !this.poll.isLabelsHidden
    },
    rows() {
      return this.poll.rows
    },
    columns() {
      return this.poll.columns
    },
    columnObject() {
      return this.poll.columns?.reduce((acc, column) => ({ ...acc, [column.id]: column }), {})
    }
  },
  methods: {
    columnText(rowId) {
      const columnIdList = [
        ...(this.value.find((value) => value.id === rowId)?.columnIdList || []).sort(
          (a, b) => this.columnObject[a]?.order - this.columnObject[b]?.order
        )
      ]
      if (columnIdList?.length) {
        const text = columnIdList
          .map((columnId) => this.poll.columns.find((column) => column.id === columnId)?.text)
          .join(', ')
        return text || this.$t('no_answer')
      } else {
        return this.$t('no_answer')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.PollMatrixEditorCompact {
  &__item {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
  &__row {
    @apply text-body-02 block text-neutral-01-400 break-words;
  }
  &__column {
    @apply block text-body-01 mt-1 break-words;
  }
  &Row {
    &__image-wrapper {
      @apply mt-1 mb-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
}
</style>
