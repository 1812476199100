<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.6266 29.72C35.7287 29.72 38.2435 27.2053 38.2435 24.1032C38.2435 21.0011 35.7287 18.4863 32.6266 18.4863C29.5245 18.4863 27.0098 21.0011 27.0098 24.1032C27.0098 27.2053 29.5245 29.72 32.6266 29.72Z"
      fill="#EB6F6B"
    />
    <path
      d="M53.8621 54.1742L54.4766 48.9619L47.8228 48.1775L47.2083 53.3897L53.8621 54.1742Z"
      fill="#E5AE6E"
    />
    <path d="M25.3577 17.87L28.4673 11.9697L20.9352 8.00019L17.8257 13.9005L25.3577 17.87Z" fill="#86AA54" />
    <path
      d="M30.3641 56.7148L40.8459 49.3262L39.9494 48.0543L29.4676 55.443L30.3641 56.7148Z"
      fill="#EDD46C"
    />
    <path d="M47.81 24.6057L56.1938 16.2539L54.1123 14.1644L45.7285 22.5162L47.81 24.6057Z" fill="#B95627" />
    <path
      d="M14.2616 48.2321L9.86841 38.2656L7.19573 39.4231L11.5889 49.3896L14.2616 48.2321Z"
      fill="#7AA33F"
    />
    <path
      d="M53.6476 39.2316C50.1417 38.6519 48.0965 38.4349 46.6181 39.133C44.8815 39.9485 43.9605 42.0476 42.414 46.3256C43.2447 41.6839 43.4806 39.4158 42.2403 37.8306C41.2805 36.595 39.409 35.7772 36.2292 34.5792C39.7223 35.1553 41.7804 35.3759 43.2424 34.6884C44.9942 33.8623 45.9129 31.7773 47.4617 27.4863C46.726 31.6212 46.4573 33.867 47.2787 35.4252C48.0989 36.9834 50.0173 37.8717 53.6465 39.2327L53.6476 39.2316Z"
      fill="#E5CB5B"
    />
    <path
      d="M20.9381 21.2795C18.3576 23.1388 16.9307 24.3018 16.4363 25.6854C15.855 27.3077 16.5975 29.2272 18.3232 32.9294C15.9166 29.4514 14.6327 27.8601 12.849 27.6395C11.4619 27.4645 9.76516 28.1335 7 29.3931C9.56959 27.5392 11.0074 26.3708 11.4963 25.0036C12.0812 23.3649 11.3442 21.4563 9.61305 17.7432C11.7607 20.8384 13.0084 22.437 14.5096 22.9055C16.0126 23.374 17.7836 22.7196 20.9345 21.2777L20.9381 21.2795Z"
      fill="#2C4E6B"
    />
    <path
      d="M15.6604 42.4373C19.9992 45.0174 24.7775 44.2629 26.6941 41.5966C28.5413 39.0248 27.9895 34.3124 25.7474 33.1914C24.5726 32.6045 22.7535 32.9129 21.9604 33.9957C20.9261 35.4084 22.0216 37.6187 22.471 38.5274C24.3165 42.2532 29.0783 45.4468 31.9747 43.9727C32.4985 43.7058 33.1974 43.1669 33.7591 41.9631"
      stroke="#EDD46C"
      stroke-width="1.24819"
      stroke-miterlimit="10"
    />
    <path
      d="M38.4892 10.3577C39.0221 13.1983 41.1512 14.9711 43.0197 14.7914C44.8216 14.6185 46.6343 12.6058 46.2682 11.2177C46.0754 10.4912 45.2561 9.82395 44.4915 9.89049C43.4943 9.9769 42.9593 11.2813 42.7394 11.8171C41.8373 14.0154 42.2671 17.2682 43.9952 17.9596C44.3084 18.085 44.8035 18.1822 45.5325 17.9708"
      stroke="#EFA279"
      stroke-width="0.978269"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: 'ReactionAnimated2Icon'
}
</script>

<style scoped lang="scss"></style>
