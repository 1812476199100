<template>
  <div class="SearchResponsesItemChat">
    <div v-if="text" class="SearchResponsesItemChat__result-text" v-html="text"></div>
    <SearchResponsesItemChatContext :response="response" class="SearchResponsesItemChat__context" />
    <SearchResponsesItemChatUser
      :response="response"
      :with-demographics="withDemographics"
      class="SearchResponsesItemChat__user"
    ></SearchResponsesItemChatUser>
  </div>
</template>

<script>
import SearchResponsesItemChatContext from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/Chat/SearchResponsesItemChatContext'
import SearchResponsesItemChatUser from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/Chat/SearchResponsesItemChatUser'
export default {
  name: 'SearchResponsesItemChat',
  components: { SearchResponsesItemChatUser, SearchResponsesItemChatContext },
  props: {
    response: { type: Object, required: true },
    withDemographics: { type: Boolean, default: false }
  },
  computed: {
    text() {
      return this.response
        ? '...' +
            [this.response.highlight.text?.map((el) => el.replace('<p>', '').replace('</p>', ''))]
              .filter(Boolean)
              .flat(1)
              .join('...<br>...') +
            '...'
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemChat {
  &__result-text {
    @apply text-body-01 break-words;

    ::v-deep em {
      @apply bg-accent-03-200 not-italic;
    }
  }
  &__context {
    @apply w-full mt-2;
  }
  &__user {
    @apply mt-6;
  }
}
</style>
