<template>
  <svg
    class="icon fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23C5.92487 23 0.999999 18.0751 0.999999 12C1 5.92487 5.92487 0.999999 12 0.999999C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 24C5.37258 24 -8.1423e-07 18.6274 -5.24537e-07 12C-2.34843e-07 5.37258 5.37258 -8.1423e-07 12 -5.24537e-07C18.6274 -2.34843e-07 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM16.2929 8.43945L12 12.7323L7.70709 8.43945L6.29288 9.85367L12 15.5608L17.7071 9.85367L16.2929 8.43945Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDown'
}
</script>
