<template>
  <div class="LLFileModalFile">
    <LLFileModalImage v-if="isImage" :file="file"></LLFileModalImage>
    <LLFileModalVideo v-if="isVideo" :file="file"></LLFileModalVideo>
  </div>
</template>

<script>
import LLFileModalImage from '@/components/common/fileGallery/files/LLFileModalImage'
import LLFileModalVideo from '@/components/common/fileGallery/files/LLFileModalVideo'
export default {
  name: 'LLFileModalFile',
  components: { LLFileModalVideo, LLFileModalImage },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileType() {
      return this.file.mimetype.substr(0, 4)
    },
    isImage() {
      return this.fileType === 'imag'
    },
    isVideo() {
      return this.fileType === 'vide'
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileModalFile {
}
</style>
