<template>
  <LLPopper
    class="LLTimestamp"
    :class="classList"
    tooltip-dark
    tooltip-with-arrow
    :offset="[0, 2]"
    placement="top"
    trigger="hover"
    :strategy="strategy"
  >
    <template #trigger>
      <div class="LLTimestamp__content">
        <span class="LLTimestamp__label">
          <slot :time="momentString">
            {{ $tc('timestamp_created', momentString) }}
          </slot>
        </span>
      </div>
    </template>
    <template #tooltip>
      {{ timeString }}
    </template>
  </LLPopper>
</template>

<script>
import moment from 'moment'
import LLPopper from '@/components/utils/LLPopper'
export default {
  name: 'LLTimestamp',
  components: { LLPopper },
  props: {
    timestamp: { type: Number, required: true },
    white: { type: Boolean, default: false },
    strategy: { type: String, default: undefined }
  },
  computed: {
    classList() {
      const baseClass = 'LLTimestamp'
      const classes = []
      /* styles */
      if (this.white) {
        classes.push(`${baseClass}_white`)
      }
      return classes
    },
    momentString() {
      moment.locale(this.$i18n.locale)
      const currentTime = this.$store.state.currentTime || Date.now()
      return moment
        .unix(this.timestamp / 1000)
        .from(moment.unix(currentTime < this.timestamp ? this.timestamp / 1000 + 1 : currentTime / 1000))
    },
    timeString() {
      return new Date(this.timestamp).toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTimestamp {
  @apply text-sm text-neutral-01 transition duration-200;
  &_white {
    @apply text-neutral-01-50;
    &:hover {
      @apply text-neutral-01;
    }
  }
  &__content {
    @apply relative cursor-default;
    &:before {
      content: '';
      $paddingX: 3px;
      $paddingY: 2px;
      @apply absolute left-0 top-0 bg-neutral-01-25 transition duration-200;
      width: calc(100% + (#{$paddingX} * 2));
      height: calc(100% + (#{$paddingY} * 2));
      transform: scale(0, 1);
      margin-left: -#{$paddingX};
      margin-top: -#{$paddingY};
      border-radius: 5px;
    }
    &:hover {
      &:before {
        transform: scale(1, 1);
      }
    }
  }
  &__label {
    @apply relative;
  }
}
</style>
