<template>
  <div class="ConversationLinkWrapper w-full" data-e2e="studyCard">
    <router-link
      v-if="studyState.isWaiting || studyState.isPendingApproval"
      tag="a"
      :to="`/study/${study.id}`"
    >
      <Conversation :study="study" @studyUpdate="onStudyUpdate"></Conversation>
    </router-link>
    <Conversation v-else :study="study" @studyUpdate="onStudyUpdate"></Conversation>
  </div>
</template>

<script>
import { StudyStates } from '@/mixins/studyStates'
import Conversation from '@/pages/Dashboard/components/DashboardConversation.vue'

export default {
  name: 'ConversationLinkWrapper',
  components: { Conversation },
  mixins: [StudyStates],
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  methods: {
    onStudyUpdate({ study }) {
      this.$emit('studyUpdate', { study })
    }
  }
}
</script>

<style lang="scss" scoped>
.ConversationLinkWrapper {
}
</style>
