import { render, staticRenderFns } from "./ForumResponseReplies.vue?vue&type=template&id=5bed4a32&scoped=true"
import script from "./ForumResponseReplies.vue?vue&type=script&lang=js"
export * from "./ForumResponseReplies.vue?vue&type=script&lang=js"
import style0 from "./ForumResponseReplies.vue?vue&type=style&index=0&id=5bed4a32&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bed4a32",
  null
  
)

export default component.exports