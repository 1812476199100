import { MessageModel } from '@/models/message'
const state = () => ({
  showModal: false,
  topicId: null
})

let exitCallback = null

export default {
  namespaced: true,
  state: state(),
  getters: {
    unsavedMessagesByTopicId: (state, getters, rootState, rootGetters) => (topicId) => {
      const unsavedMessages = []
      const topic = rootGetters['studies/topicById'](topicId)
      topic?.questions?.forEach((q) => {
        const messageObject = rootGetters['studies/savedMessages']?.find((m) => m.questionId === q.id)
        const message = messageObject
          ? MessageModel.parseFromTempMessage({
              message: messageObject.tempMessageData,
              sendingPromise: messageObject.sendingPromise,
              topicId: topic.id,
              questionId: q.id
            })
          : undefined
        if (message && !message?.isEmpty) {
          unsavedMessages.push(message)
        }
      })
      return unsavedMessages
    },
    unsavedMessagesByTopicIdNotSending: (state, getters) => (topicId) => {
      return getters.unsavedMessagesByTopicId(topicId).filter((m) => !m.sendingPromise)
    }
  },
  mutations: {
    UPDATE_SHOW_MODAL(state, value) {
      state.showModal = !!value
    },
    SET_TOPIC_ID(state, { topicId }) {
      state.topicId = topicId
    }
  },
  actions: {
    async closeModal({ commit, dispatch, rootGetters }, { withCallback }) {
      commit('UPDATE_SHOW_MODAL', false)
      const haveUnansweredQuestions = rootGetters['unansweredQuestions/haveUnansweredQuestions']()
      if (!withCallback && exitCallback) {
        if (haveUnansweredQuestions) {
          // console.log('have una q', haveUnansweredQuestions)
          const cb = !withCallback && exitCallback ? exitCallback : () => {}
          dispatch('unansweredQuestions/showWarning', { callback: cb }, { root: true })
        } else {
          // console.log('no una q', haveUnansweredQuestions)
          exitCallback()
          exitCallback = null
        }
      }
    },
    async showWarning({ commit }, { topicId, callback }) {
      commit('SET_TOPIC_ID', { topicId })
      commit('UPDATE_SHOW_MODAL', true)
      exitCallback = callback
    }
  }
}
