var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PollEditorNumberSlider"},[_c('LLInputLocked',{staticClass:"PollEditorNumberSlider__style-selector",attrs:{"dark-caption":"","required":""},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_vm._v(_vm._s(_vm.$t('forum.poll.editor.number.slider.title')))]},proxy:true}])},[_c('div',{staticClass:"PollEditorNumberSlider__range-inputs"},[_c('ValidationProvider',{staticClass:"PollEditorNumberSlider__input-wrapper",attrs:{"rules":{
          required: true,
          'not-duplicated': [_vm.localPoll.sliderTo],
          max_value: _vm.localPoll.sliderTo ? Number(_vm.localPoll.sliderTo) - 1 : 2000000000
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('LLInputText',{staticClass:"PollEditorNumberSlider__value-input",attrs:{"errors":errors,"show-error-in-tooltip":"","disabled":_vm.isPollCreated,"mask":{ mask: '-{0,1}9{*}', autoUnmask: true }},model:{value:(_vm.localPoll.sliderFrom),callback:function ($$v) {_vm.$set(_vm.localPoll, "sliderFrom", $$v)},expression:"localPoll.sliderFrom"}})]}}])}),_c('span',{staticClass:"PollEditorNumberSlider__delimeter"},[_vm._v(_vm._s(_vm.$t('forum.poll.editor.number.slider.to')))]),_c('ValidationProvider',{staticClass:"PollEditorNumberSlider__input-wrapper",attrs:{"rules":{
          required: true,
          'not-duplicated': [_vm.localPoll.sliderFrom],
          min_value: _vm.localPoll.sliderFrom ? Number(_vm.localPoll.sliderFrom) + 1 : -Infinity,
          max_value: 2000000000
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('LLInputText',{staticClass:"PollEditorNumberSlider__value-input",attrs:{"errors":errors,"disabled":_vm.isPollCreated,"show-error-in-tooltip":"","mask":{ mask: '-{0,1}9{*}', autoUnmask: true }},model:{value:(_vm.localPoll.sliderTo),callback:function ($$v) {_vm.$set(_vm.localPoll, "sliderTo", $$v)},expression:"localPoll.sliderTo"}})]}}])})],1)]),_c('LLInputLocked',{staticClass:"PollEditorNumberSlider__label-input",attrs:{"dark-caption":""},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_vm._v(_vm._s(_vm.$t('forum.poll.editor.number.slider.left_label')))]},proxy:true}])},[_c('LLInputText',{attrs:{"show-error-in-tooltip":""},model:{value:(_vm.localPoll.sliderFromLabel),callback:function ($$v) {_vm.$set(_vm.localPoll, "sliderFromLabel", $$v)},expression:"localPoll.sliderFromLabel"}})],1),_c('LLInputLocked',{staticClass:"PollEditorNumberSlider__label-input",attrs:{"dark-caption":""},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_vm._v(_vm._s(_vm.$t('forum.poll.editor.number.slider.right_label')))]},proxy:true}])},[_c('LLInputText',{attrs:{"show-error-in-tooltip":""},model:{value:(_vm.localPoll.sliderToLabel),callback:function ($$v) {_vm.$set(_vm.localPoll, "sliderToLabel", $$v)},expression:"localPoll.sliderToLabel"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }