<template>
  <div class="ParticipantImageName">
    <div class="ParticipantImageName__image">
      <div class="ParticipantImageName__image-inner">
        <ProspectAvatar
          :disable-new-sms-indicator="disableNewSmsIndicator"
          :prospect="prospectObject"
          small
          disable-tooltip
        ></ProspectAvatar>
      </div>
    </div>
    <div v-if="!withoutName" class="ParticipantImageName__name">
      {{ fullLastName ? prospectObject.fullName : prospectObject.fullNameWithShortLastName }}
    </div>
  </div>
</template>

<script>
import { ProspectModel } from '@/models/prospect'
import ProspectAvatar from '@/components/modules/prospects/ProspectAvatar'

export default {
  name: 'ParticipantImageName',
  components: { ProspectAvatar },
  props: {
    participant: { type: Object, required: true },
    name: { type: String, default: null },
    withoutName: { type: Boolean, default: false },
    hideWeChat: { type: Boolean, default: false },
    fullLastName: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disableNewSmsIndicator: { type: Boolean, default: false }
  },
  computed: {
    prospectObject() {
      return ProspectModel.parseFromApi({ prospect: this.participant })
    },
    image() {
      return this?.participant?.image
    },
    wechatId() {
      return this?.participant?.wechatId
    },
    firstName() {
      return this.participant?.firstName ? this.$_.upperFirst(this.participant?.firstName) : null
    },
    lastName() {
      if (this.participant?.lastName) {
        if (this.fullLastName) {
          return this.$_.upperFirst(this.participant?.lastName)
        } else {
          return this.$_.upperCase(this.participant?.lastName?.charAt(0))
        }
      } else {
        return null
      }
    },
    localName() {
      if (this.name) {
        return this.name
      }
      if (this.firstName || this.lastName) {
        return [this.firstName, this.lastName].filter((n) => !!n).join(' ')
      } else if (this.participant?.email) {
        return this.participant?.email
      } else if (this.participant?.wechatId) {
        return this.participant?.wechatId
      } else {
        return 'N/A'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ParticipantImageName {
  $root: &;
  @apply flex items-center min-w-0 outline-none;
  &_clickable {
    @apply cursor-pointer;
  }
  &:not(#{$root}_clickable) {
    @apply cursor-default;
  }
  &__image {
    @apply relative flex-shrink-0 w-7 h-7;
    &-inner {
      @apply w-full h-full;
      img {
        @apply w-full h-full;
      }
    }
  }

  &__name {
    @apply text-subheading-01 ml-2 text-primary-01 truncate;
    max-width: 250px;
  }

  &__wechat {
    @apply absolute top-0 right-0 -mr-2 -mt-2;
  }
}
</style>
