<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.18203 4.25449C2.18203 3.1099 3.1099 2.18203 4.25449 2.18203H15.7443C16.8889 2.18203 17.8168 3.1099 17.8168 4.25449V15.7443C17.8168 16.8889 16.8889 17.8168 15.7443 17.8168H4.25449C3.1099 17.8168 2.18203 16.8889 2.18203 15.7443V4.25449ZM4.25449 0.582031C2.22625 0.582031 0.582031 2.22625 0.582031 4.25449V15.7443C0.582031 17.7726 2.22625 19.4168 4.25449 19.4168H15.7443C17.7726 19.4168 19.4168 17.7726 19.4168 15.7443V4.25449C19.4168 2.22625 17.7726 0.582031 15.7443 0.582031H4.25449ZM8.05926 6.41063C7.81127 6.28663 7.51676 6.29989 7.2809 6.44565C7.04505 6.59142 6.90149 6.84891 6.90149 7.12617V12.8711C6.90149 13.1483 7.04505 13.4058 7.2809 13.5516C7.51676 13.6974 7.81127 13.7106 8.05926 13.5866L13.8042 10.7142C14.0752 10.5787 14.2464 10.3016 14.2464 9.99863C14.2464 9.69561 14.0752 9.4186 13.8042 9.28309L8.05926 6.41063ZM11.6575 9.99863L8.50149 11.5767V8.4206L11.6575 9.99863Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VideoIcon'
}
</script>

<style lang="scss" scoped>
.VideoIcon {
}
</style>
