<template>
  <div class="SearchResponsesItemStudy">
    <div v-if="text" class="SearchResponsesItemStudy__result-text" v-html="text"></div>
    <SearchResponsesItemStudyContext
      :response="response"
      class="SearchResponsesItemStudy__context"
    ></SearchResponsesItemStudyContext>
    <SearchResponsesItemStudyUser
      :response="response"
      :with-demographics="withDemographics"
      class="SearchResponsesItemStudy__user"
    ></SearchResponsesItemStudyUser>
  </div>
</template>

<script>
import SearchResponsesItemStudyUser from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/Study/SearchResponsesItemStudyUser'
import SearchResponsesItemStudyContext from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/Study/SearchResponsesItemStudyContext'
export default {
  name: 'SearchResponsesItemStudy',
  components: { SearchResponsesItemStudyContext, SearchResponsesItemStudyUser },
  props: {
    response: { type: Object, required: true },
    withDemographics: { type: Boolean, default: false }
  },
  computed: {
    text() {
      return this.response
        ? '...' +
            [
              this.response.highlight.text?.map((el) => el.replace('<p>', '').replace('</p>', '')),
              this.response.highlight.translates,
              this.response.highlight.transcribes
            ]
              .filter(Boolean)
              .flat(1)
              .join('...<br>...') +
            '...'
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemStudy {
  &__result-text {
    @apply text-body-01;

    ::v-deep em {
      @apply bg-accent-03-200 not-italic;
    }
  }
  &__context {
    @apply w-full mt-2;
  }
  &__user {
    @apply mt-6;
  }
}
</style>
