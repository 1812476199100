/*
This is a module for switching questions after pressing the "Send" button.
How it works:
First, from the main parent component (StudyPageBody), we add event handlers that should trigger after pressing the "Send" button.
There we handle this event to understand if this question exists and if it's necessary to switch the topic, for example.
After that, when the questions are initialized, they add events to questionEvents. This event will be triggered from StudyPageBody when everything is loaded, through fireQuestionEvent.
There will be a handler in the questions that will cause the page to scroll to it.
*/

let sendEvent = null
let questionEvents = {}
export default {
  addEvent(callback) {
    sendEvent = callback
  },
  emit({ questionId }) {
    if (sendEvent) {
      sendEvent({ questionId })
    }
  },
  removeSendEvent() {
    sendEvent = null
  },

  addQuestionEvent({ questionId, callback }) {
    questionEvents[questionId] = callback
  },
  removeQuestionEvent({ questionId }) {
    delete questionEvents[questionId]
  },
  fireQuestionEvent({ questionId }) {
    if (questionEvents[questionId]) {
      questionEvents[questionId]()
    }
  }
}
