import Network from '../../network.js'

export default () => ({
  get({ chatId }) {
    return Network.get(`/forum-chats/${chatId}`, null, true)
  },
  fetch({ page, perPage, sort }) {
    return Network.getWithConfig(
      `/forum-chats`,
      {
        params: {
          page,
          perPage,
          sort
        }
      },
      true
    )
  },
  updateState({ chatId, state }) {
    return Network.put(
      `/forum-chats/${chatId}/state`,
      {
        state
      },
      true
    )
  },
  create({ chat }) {
    return Network.post(
      `/forum-chats`,
      {
        ...chat
      },
      true
    )
  },
  save({ chat }) {
    return Network.put(
      `/forum-chats/${chat.id}`,
      {
        ...chat
      },
      true
    )
  },
  delete({ chatId }) {
    return Network.delete(`/forum-chats/${chatId}`, true)
  },
  getMembers({ chatId, search, filters, page, sort, perPage, cancelToken }) {
    return Network.getWithConfig(
      `/forum-chats/${chatId}/members`,
      {
        params: {
          search,
          ...filters,
          page,
          sort,
          perPage
        },
        cancelToken
      },
      true
    )
  },
  addMembersByProspectId({ prospectIdList, chatId, prospectFilters }) {
    return Network.post(
      `/forum-chats/${chatId}/members/account`,
      {
        prospectIdList,
        prospectFilters
      },
      true
    )
  },
  addMembersByEmail({ emailList, chatId }) {
    return Network.post(
      `/forum-chats/${chatId}/members/email`,
      {
        emailList
      },
      true
    )
  },
  addMembersByCollectionId({ collectionIdList, chatId }) {
    return Network.post(`/forum-chats/${chatId}/members/collections`, {
      collectionIdList
    })
  },
  removeMembers({ prospectIdList, memberFilters, chatId }) {
    return Network.delete(`/forum-chats/${chatId}/members`, {
      data: {
        memberFilters,
        prospectIdList
      }
    })
  },
  getCollections({ chatId, search, page, sort, perPage, cancelToken }) {
    return Network.getWithConfig(`/forum-chats/${chatId}/members/collections`, {
      params: {
        search,
        page,
        sort,
        perPage
      },
      cancelToken
    })
  },
  removeCollection({ collectionIdList, collectionSearchQuery, chatId }) {
    return Network.delete(`/forum-chats/${chatId}/members/collections`, {
      data: {
        collectionIdList,
        search: collectionSearchQuery
      }
    })
  },
  getNotes({ chatId }) {
    return Network.get(`/forum-chats/${chatId}/notes`)
  },
  saveNote({ chatId, note }) {
    const { id, text, isPinned } = note
    let url = `/forum-chats/${chatId}/notes`
    let method = null
    if (note.id) {
      url = `/forum-chats/${chatId}/notes/${id}`
      method = Network.put
    } else {
      url = `/forum-chats/${chatId}/notes`
      method = Network.post
    }
    return method(url, {
      note: {
        isPinned,
        text,
        id: note.id || undefined
      }
    })
  },
  deleteNote({ chatId, noteId }) {
    return Network.delete(`/forum-chats/${chatId}/notes/${noteId}`)
  },
  getFiles({ chatId }) {
    return Network.get(`/forum-chats/${chatId}/files`)
  },
  addFiles({ chatId, fileIdList }) {
    return Network.post(`/forum-chats/${chatId}/files`, { fileIdList })
  },
  delefeFile({ chatId, fileId }) {
    return Network.delete(`/forum-chats/${chatId}/files/${fileId}`)
  },
  getUserChatPermissions({ chatId, page, filters, perPage, sort }) {
    return Network.get(`/forum-chats/${chatId}/user-permission`, {
      params: {
        ...filters,
        page,
        perPage,
        sort
      }
    })
  },
  setUserChatPermissions({ chatId, userChatPermissionList }) {
    return Network.post(`/forum-chats/${chatId}/user-permission`, {
      userChatPermissionList
    })
  },
  setUserChatPermissionsBulk({ chatId, userFilters, permissions }) {
    return Network.post(`/forum-chats/${chatId}/user-permission/bulk`, {
      userFilters,
      ...permissions
    })
  },
  contactMembersEmail({ chatId, prospectIdList, prospectFilters, template, subject }) {
    return Network.post(`/forum-chats/${chatId}/members/contact/email`, {
      prospectIdList,
      prospectFilters,
      template,
      subject
    })
  },
  contactMembersSms({ chatId, prospectIdList, prospectFilters, template }) {
    return Network.post(`/forum-chats/${chatId}/members/contact/sms`, {
      prospectIdList,
      prospectFilters,
      template
    })
  },
  contactCollectionsEmail({ chatId, collectionIdList, searchQuery, template, subject }) {
    return Network.post(`/forum-chats/${chatId}/members/collections/contact/email`, {
      collectionIdList,
      searchQuery,
      template,
      subject
    })
  },
  contactCollectionsSms({ chatId, collectionIdList, searchQuery, template }) {
    return Network.post(`/forum-chats/${chatId}/members/collections/contact/sms`, {
      collectionIdList,
      searchQuery,
      template
    })
  }
})
