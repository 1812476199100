<template>
  <svg
    class="EmojiIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0025 0.3125C4.65094 0.3125 0.315002 4.64844 0.315002 10C0.315002 15.3516 4.65094 19.6875 10.0025 19.6875C15.3541 19.6875 19.69 15.3516 19.69 10C19.69 4.64844 15.3541 0.3125 10.0025 0.3125ZM10.0025 17.8125C5.69391 17.8125 2.19 14.3086 2.19 10C2.19 5.69141 5.69391 2.1875 10.0025 2.1875C14.3111 2.1875 17.815 5.69141 17.815 10C17.815 14.3086 14.3111 17.8125 10.0025 17.8125ZM6.8775 9.375C7.56891 9.375 8.1275 8.81641 8.1275 8.125C8.1275 7.43359 7.56891 6.875 6.8775 6.875C6.1861 6.875 5.6275 7.43359 5.6275 8.125C5.6275 8.81641 6.1861 9.375 6.8775 9.375ZM13.1275 9.375C13.8189 9.375 14.3775 8.81641 14.3775 8.125C14.3775 7.43359 13.8189 6.875 13.1275 6.875C12.4361 6.875 11.8775 7.43359 11.8775 8.125C11.8775 8.81641 12.4361 9.375 13.1275 9.375ZM13.2838 12.2109C12.4713 13.1875 11.272 13.75 10.0025 13.75C8.73297 13.75 7.53375 13.1914 6.72125 12.2109C6.38922 11.8125 5.79547 11.7617 5.40094 12.0898C5.0025 12.4219 4.95172 13.0117 5.27985 13.4102C6.45172 14.8164 8.17438 15.6211 10.0025 15.6211C11.8306 15.6211 13.5533 14.8164 14.7252 13.4102C15.0572 13.0117 15.0025 12.4219 14.6041 12.0898C14.2095 11.7617 13.6158 11.8125 13.2838 12.2109Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmojiIcon'
}
</script>

<style lang="scss" scoped>
.EmojiIcon {
  @apply fill-current;
}
</style>
