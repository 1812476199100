<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1506 7.81199C10.876 7.95932 11.454 8.30499 11.8846 8.84899C12.3266 9.38165 12.5476 10.0107 12.5476 10.736C12.5476 11.7447 12.2076 12.5437 11.5276 13.133C10.859 13.711 9.8843 14 8.60364 14H3.45264V1.99799H8.50164C9.7143 1.99799 10.6436 2.26432 11.2896 2.79699C11.947 3.32965 12.2756 4.07765 12.2756 5.04099C12.2756 5.78899 12.0773 6.40099 11.6806 6.87699C11.2953 7.35299 10.7853 7.66465 10.1506 7.81199ZM5.84964 7.02999H8.00864C8.6093 7.02999 9.06264 6.89965 9.36864 6.63899C9.68597 6.36699 9.84464 5.98165 9.84464 5.48299C9.84464 4.98432 9.69164 4.59899 9.38564 4.32699C9.07964 4.05499 8.6093 3.91898 7.97464 3.91898H5.84964V7.02999ZM8.12764 12.045C8.7623 12.045 9.24964 11.909 9.58964 11.637C9.92964 11.3537 10.0996 10.9457 10.0996 10.413C10.0996 9.88032 9.92397 9.47232 9.57264 9.18899C9.23264 8.89432 8.7453 8.74699 8.11064 8.74699H5.84964V12.045H8.12764Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BoldIcon'
}
</script>
