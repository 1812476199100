<template>
  <LLConfirmModalTransition>
    <LLConfirmModal v-if="localShowModal" class="SubmitAnswerConfirmation" :opened.sync="localShowModal">
      <template #title>
        {{ title }}
      </template>
      <template #default>
        <div class="SubmitAnswerConfirmation__content">
          <div class="SubmitAnswerConfirmation__bottom-description">
            {{ content }}
          </div>
        </div>
      </template>
      <template #actions>
        <div class="SubmitAnswerConfirmation__actions">
          <LLButton class="SubmitAnswerConfirmation__action" green small @click.native="submit">
            <slot name="submit-button-caption">
              {{ $t('button_submit') }}
            </slot>
          </LLButton>
          <LLButton class="SubmitAnswerConfirmation__action" small secondary @click.native="cancelClick">
            <slot name="cancel-button-caption">
              {{ $t('button_cancel') }}
            </slot>
          </LLButton>
        </div>
      </template>
    </LLConfirmModal>
  </LLConfirmModalTransition>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
import LLConfirmModal from '@/components/common/LLConfirmModal'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'

export default {
  name: 'SubmitAnswerConfirmation',
  components: { LLConfirmModalTransition, LLButton, LLConfirmModal },
  props: {
    showModal: { type: Boolean, default: false },
    title: { type: String, default: '' },
    content: { type: String, default: '' }
  },
  data() {
    return {
      localShowModal: false
    }
  },
  watch: {
    localShowModal: {
      handler() {
        this.$emit('update:showModal', this.localShowModal)
      }
    },
    showModal: {
      handler() {
        this.localShowModal = this.showModal
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      this.$emit('confirm')
      this.localShowModal = false
    },
    cancelClick() {
      this.$emit('cancel')
      this.localShowModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.SubmitAnswerConfirmation {
  &__bottom-description {
    @apply mt-5;
  }
  &__content {
    max-width: 500px;
  }
  &__actions {
    @apply flex;
    @screen mob-only {
      @apply flex-col items-end;
    }
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
    @screen mob-only {
      @apply ml-0 mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
}
</style>
