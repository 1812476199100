<template>
  <div v-if="isFooterVisible" class="ForumResponseBodyFooter">
    <LLButton
      v-if="permissions.showReplyButton"
      small
      tertiary
      class="ForumResponseBodyFooter__reply-button"
      @click="onReplyButtonClick"
    >
      <template v-if="showReplyIcon" #icon-left>
        <ForumReply2Icon class="ForumResponseBodyFooter__reply-button-icon" />
      </template>
      <template #default> {{ $t('button_reply') }} </template>
    </LLButton>
    <LLButton
      v-if="permissions.showGoToParentButton && response.replyToId"
      small
      tertiary
      class="ForumResponseBodyFooter__button"
      @click="scrollToParent"
    >
      <template #icon-left>
        <StrokeArrowIcon />
      </template>
    </LLButton>
    <ForumResponseReactionButton
      v-if="permissions.showReactionButton"
      class="ForumResponseBodyFooter__button"
      @reactionSelect="onReactionSelect"
    />
    <ForumResponseAnimatedReactionButton
      v-if="permissions.showAnimatedReactionButton"
      class="ForumResponseBodyFooter__button"
      @reactionSelect="onReactionSelect"
    />
    <ForumResponseActionsButton
      v-if="permissions.showActionsButton"
      :response="response"
      class="ForumResponseBodyFooter__button"
      @replyButtonClick="onReplyButtonClick"
      @reactionSelect="onReactionSelect"
      @edit="onEdit"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import ForumResponseReactionButton from '@/components/pages/forum/response/buttons/ForumResponseReactionButton.vue'
import ForumResponseAnimatedReactionButton from '@/components/pages/forum/response/buttons/ForumResponseAnimatedReactionButton.vue'
import ForumResponseActionsButton from '@/components/pages/forum/response/buttons/ForumResponseActionsButton.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import ForumReply2Icon from '@/assets/icons/ForumReply2Icon.vue'
import StrokeArrowIcon from '@/assets/icons/StrokeArrowIcon.vue'
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'

export default {
  name: 'ForumResponseBodyFooter',
  components: {
    StrokeArrowIcon,
    ForumReply2Icon,
    ForumResponseActionsButton,
    ForumResponseAnimatedReactionButton,
    ForumResponseReactionButton,
    LLButton
  },
  props: {
    isActionsHidden: { type: Boolean, default: false },
    response: { type: ForumResponseModel, required: true },
    isAbleToReply: { type: Boolean, default: true },
    replyLevel: { type: Number, default: 0 }
  },
  computed: {
    ...mapGetters('permissions', ['allowToAddReactionOnResponse', 'allowToAddNewResponse']),
    showReplyIcon() {
      return this.replyLevel < 2
    },
    isAbleToAddNewReply() {
      return this.isAbleToReply
    },
    isAbleToRemoveResponse() {
      return (
        this.response.prospect?.id === this.$store.getters['user/user']?.id ||
        this.response.thread?.prospect?.id === this.$store.getters['user/user']?.id
      )
    },
    isAbleToEditResponse() {
      return (
        this.response.prospect?.id === this.$store.getters['user/user']?.id ||
        this.response.thread?.prospect?.id === this.$store.getters['user/user']?.id
      )
    },
    permissions() {
      return {
        showReplyButton: this.isAbleToAddNewReply && !this.isActionsHidden,
        showGoToParentButton: !!this.response.parentResponseId,
        showReactionButton: !this.isActionsHidden,
        showAnimatedReactionButton: !this.isActionsHidden,
        showActionsButton: !this.isActionsHidden && (this.isAbleToEditResponse || this.isAbleToRemoveResponse)
      }
    },
    isFooterVisible() {
      return Object.keys(this.permissions).find((permission) => this.permissions[permission])
    }
  },
  methods: {
    onReactionSelect(reaction) {
      this.$emit('reactionSelect', reaction)
    },
    onEdit() {
      this.$emit('edit')
    },
    onRemove() {
      this.$emit('remove')
    },
    onReplyButtonClick() {
      this.$emit('replyButtonClick')
    },
    scrollToParent() {
      scrollToForumReplyHelper.setResponsePath({
        responsePath: [
          this.response.parentResponseId,
          ...(this.response.replyToId !== this.response.parentResponseId ? [this.response.replyToId] : [])
        ]
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseBodyFooter {
  @apply flex;
  &__button {
    @apply ml-2;
    &:first-child {
      @apply ml-0;
    }
  }
  &__reply-button {
    @apply -ml-4;
  }
  &__reply-button-icon {
    @apply w-5 h-5;
  }
}
</style>
