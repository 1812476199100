<template>
  <div class="SearchResponses">
    <SearchContentContainer :pending="pendingResults">
      <template #filters>
        <SearchResponsesFilter
          :study="study"
          :chat="chat"
          :init-filters="filterValues"
          @updateStartFilters="updateStartFilters"
          @updateFilterLabels="updateFilterLabels"
          @apply="applyFilters"
        ></SearchResponsesFilter>
      </template>
      <template #before-results-load>
        <LLFilterLabels
          v-if="filterLabels.length"
          class="SearchResponses__filter-labels"
          :labels="filterLabels"
        ></LLFilterLabels>
      </template>
      <template #results>
        <SearchContentHeader>
          <template #actions>
            <LLFilterLabels
              v-if="filterLabels.length"
              class="SearchResponses__filter-labels"
              :labels="filterLabels"
            ></LLFilterLabels>
            <SearchResponsesHeaderActions
              :sort-type.sync="sortBy"
              :with-demographics.sync="showDemographicsInfo"
              :total="total"
              @update="search"
            ></SearchResponsesHeaderActions>
          </template>
        </SearchContentHeader>
        <SearchResponsesResults
          :search-value="searchValue"
          :filter-values="filterValues"
          :sort-by="sortBy"
          :total="total"
          :with-demographics="showDemographicsInfo"
          :results="searchResults"
          :chat="chat"
        ></SearchResponsesResults>
      </template>
    </SearchContentContainer>
  </div>
</template>

<script>
import SearchContentContainer from '@/components/pages/search/SearchModule/SearchContent/SearchContentContainer'
import { StudyModel } from '@/models/study'
import SearchResponsesFilter from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesFilter'
import SearchContentHeader from '@/components/pages/search/SearchModule/SearchContent/SearchContentHeader'
import SearchResponsesHeaderActions from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesHeaderActions'
import SearchResponsesResults from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesResults'
import { SearchResponsesSortTypes } from '@/common/enums'
import LLFilterLabels from '@/components/pages/search/filter/LLFilterLabels'
import { ForumChatModel } from '@/models/forum/forumChat'
export default {
  name: 'SearchResponses',
  components: {
    LLFilterLabels,
    SearchResponsesResults,
    SearchResponsesHeaderActions,
    SearchContentHeader,
    SearchResponsesFilter,
    SearchContentContainer
  },
  props: {
    study: { type: [StudyModel, null], default: null },
    chat: { type: [ForumChatModel, null], default: null },
    searchValue: { type: String, default: null },
    pendingResults: { type: Boolean, default: false },
    searchResults: { type: Array, default: () => {} },
    total: { type: Number, default: 0 },
    initFilters: { type: Object, default: null }
  },
  data() {
    return {
      showDemographicsInfo: false,
      sortBy: SearchResponsesSortTypes.RELEVANCY,
      filterValues: {
        teamId: [],
        studyId: [],
        chatId: [],
        groupId: [],
        participantId: [],
        topicId: [],
        questionId: [],
        tagId: [],
        memberId: [],
        threadId: []
      },
      searchRequestPromise: null,
      filterLabels: []
    }
  },
  created() {
    if (this.study) {
      this.filterValues.studyId = [this.study.id]
    } else if (this.chat) {
      this.filterValues.chatId = [this.chat.id]
    }
    if (this.initFilters) {
      this.filterValues = { ...this.filterValues, ...this.initFilters }
    }
    this.search()
  },
  methods: {
    search() {
      let sort = ''
      if (this.sortBy === SearchResponsesSortTypes.DATE) {
        sort = '-updated_at'
      }
      this.$emit('search', {
        filters: {
          ...this.filterValues,
          prospectId: this.filterValues.memberId,
          memberId: undefined
        },
        sort
      })
    },
    applyFilters({
      chatId,
      teamId,
      studyId,
      groupId,
      participantId,
      topicId,
      questionId,
      tagId,
      memberId,
      threadId
    }) {
      this.filterValues.teamId = [...teamId]
      this.filterValues.studyId = [...studyId]
      this.filterValues.chatId = [...chatId]
      this.filterValues.groupId = [...groupId]
      this.filterValues.participantId = [...participantId]
      this.filterValues.topicId = [...topicId]
      this.filterValues.questionId = [...questionId]
      this.filterValues.tagId = [...tagId]
      this.filterValues.threadId = [...threadId]
      this.filterValues.memberId = [...memberId]
      this.search()
      this.$emit('updateStartFilters', { teamId, studyId, chatId })
    },
    updateStartFilters({ teamId, studyId, chatId }) {
      this.$emit('updateStartFilters', { teamId, studyId, chatId })
    },
    updateFilterLabels({ filterLabels }) {
      this.filterLabels = filterLabels
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponses {
  &__filter-labels {
    @apply mb-6;
  }
}
</style>
