<template>
  <LLSelectInputNew
    v-model="tempValue"
    error-space
    :errors="errors"
    :values="years"
    :filter-mask="{ mask: '9999', autoUnmask: true }"
    with-filter
    :filter-fn="(value, text) => value.toString().includes(text)"
    :placeholder="$t('placeholder_select-year')"
    class="ProspectDetailsModalBasics__input"
  >
    <template #caption>
      <slot name="caption"></slot>
    </template>
  </LLSelectInputNew>
</template>

<script>
import LLSelectInputNew from '@/components/common/LLSelectInputNew'
import { getBirthYearsArray } from '@/utils/dateUtils'
export default {
  name: 'LLYearInput',
  components: { LLSelectInputNew },
  props: { value: { type: Number, default: 0 }, errors: { type: Array, default: () => [] } },
  data() {
    return {
      tempValue: null
    }
  },
  computed: {
    years() {
      return getBirthYearsArray()
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          const date = new Date(this.value)
          this.tempValue = date.getUTCFullYear()
        }
      },
      immediate: true
    },
    tempValue: {
      handler() {
        if (_.isNumber(this.tempValue)) {
          const dateUTC = new Date(Date.UTC(this.tempValue, 0, 1))
          this.$emit('input', dateUTC.getTime())
        } else {
          this.$emit('input', null)
        }
      },
      immediate: true
    }
  }
}
</script>
