<template>
  <div class="LLSquareSpoilerArrow" @click="arrowClick">
    <ArrowMiniIcon class="LLSquareSpoilerArrow__arrow" :class="arrowClasses"></ArrowMiniIcon>
  </div>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'

export default {
  name: 'LLSquareSpoilerArrow',
  components: { ArrowMiniIcon },
  props: {
    opened: { type: Boolean, default: false }
  },
  computed: {
    arrowClasses() {
      const classes = []
      const rootClass = 'LLSquareSpoilerArrow__arrow'
      if (this.opened) {
        classes.push(`${rootClass}_opened`)
      }
      return classes
    }
  },
  methods: {
    arrowClick() {
      this.$emit('update:opened', !this.opened)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSquareSpoilerArrow {
  @apply w-8 h-8 flex-shrink-0 flex items-center justify-center transition duration-200 bg-transparent cursor-pointer;
  &:hover {
    @apply bg-primary-01-25 rounded;
  }
  &__arrow {
    @apply text-primary-01-400 transition duration-200;
    &_opened {
      transform: rotate(90deg);
    }
  }
}
</style>
