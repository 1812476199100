<template>
  <label class="LLInputCaption" :class="inputClasses">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'LLInputCaption',
  props: {
    disabled: { type: Boolean, default: false }
  },
  computed: {
    inputClasses() {
      const classes = []
      const rootClass = 'LLInputCaption'
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputCaption {
  @apply text-body-02 mb-2 text-neutral-01-900 block;
  &_disabled {
    @apply text-neutral-01-400;
  }
}
</style>
