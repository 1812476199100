<template>
  <svg
    class="Success02Icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.40804 13.7334C5.6304 12.9558 5.6304 11.695 6.40804 10.9173V10.9173C7.18568 10.1397 8.44648 10.1397 9.22412 10.9173L13.4482 15.1415L10.6322 17.9575L6.40804 13.7334Z"
    />
    <path
      d="M15.6061 7.40804C16.3838 6.6304 17.6446 6.6304 18.4222 7.40804V7.40804C19.1998 8.18568 19.1998 9.44648 18.4222 10.2241L10.6463 18L7.83024 15.1839L15.6061 7.40804Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Success02Icon'
}
</script>

<style lang="scss" scoped>
.Success02Icon {
  @apply fill-current;
}
</style>
