<template>
  <div class="SearchResponsesItemChatUser">
    <div class="SearchResponsesItemChatUser__main-row">
      <div class="SearchResponsesItemChatUser__image-wrapper">
        <ForumResponseAvatar :response="responseObject" />
      </div>
      <div class="SearchResponsesItemChatUser__row">
        <div class="SearchResponsesItemChatUser__row-item">
          <span v-if="false" class="SearchResponsesItemChatUser__row-item-description"
            >{{ $t('prospect-account_first-name') }}:</span
          >
          <span class="SearchResponsesItemChatUser__row-item-value">
            <ForumResponseProfile :response="responseObject" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantUtils from '@/utils/participantUtils'
import { getAge } from '@/utils/dateUtils'
import { ProspectModel } from '@/models/prospect'
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
import { UserModel } from '@/models/user'
import ForumResponseAvatar from '@/components/pages/forum/response/ForumResponseAvatar.vue'
import ForumResponseProfile from '@/components/pages/forum/response/ForumResponseProfile.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'
export default {
  name: 'SearchResponsesItemChatUser',
  components: { ForumResponseProfile, ForumResponseAvatar },
  props: {
    response: { type: Object, required: true },
    withDemographics: { type: Boolean, default: false }
  },
  data() {
    return {}
  },
  computed: {
    responseObject() {
      return ForumResponseModel.parseFromApi({
        response: this.response.threadResponse,
        threadId: this.thread.id,
        chatId: this.chat.id,
        user: this.isUser ? this.user : null,
        prospect: this.isProspect ? this.prospect : null
      })
    },
    anonymousReplyLetter() {
      return this.$t('forum.response.anonymous_reply_user_name').slice(0, 1)
    },
    isProspect() {
      return !!this.response.prospect
    },
    isUser() {
      return this.response.user
    },
    prospect() {
      return ProspectModel.parseFromApi({ prospect: this.response?.prospect })
    },
    user() {
      return UserModel.parseFromApi({ user: this.response?.user })
    },
    chat() {
      return ForumChatModel.parseFromApi({ chat: this.response?.chat })
    },
    thread() {
      return ForumThreadModel.parseFromApi({ thread: this.response?.thread, chatId: this.chat.id })
    },
    userName() {
      return this.isProspect ? this.prospect.fullNameWithShortLastName : this.user.fullNameWithShortLastName
    },
    prospectDataProcessed() {
      const prospect = { ...this.participant }
      return {
        firstName: _.upperFirst(prospect?.firstName) || null,
        lastName: _.upperFirst(prospect?.lastName) || null,
        phone: prospect?.phone || null,
        location: ParticipantUtils.getLocation(prospect),
        email: prospect?.email || null,
        gender: prospect?.gender
          ? this.$t(`gender_${this.genders.find((g) => g.value === prospect?.gender)?.label}`)
          : null,
        age: prospect?.birthday ? getAge(prospect.birthday) : null,
        ethnicity: prospect?.ethnicity
          ? this.$t(`ethnicity_${this.ethnicities.find((g) => g.value === prospect?.ethnicity)?.label}`)
          : null
      }
    },
    prospectName() {
      return [this.participant?.firstName, this.participant?.lastName?.charAt(0)].filter(Boolean).join(' ')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemChatUser {
  &__main-row {
    @apply flex items-center min-w-0;
  }
  &__row {
    @apply flex flex-col min-w-0;
    @screen md {
      @apply flex flex-row;
    }
  }
  &__row-item {
    @apply text-body-02 ml-4;
    &:first-child {
      @apply ml-4;
    }
    &-description {
      @apply text-neutral-01-400;
    }
    &-value {
    }
  }
  &__demography {
    @apply ml-7 mt-2;
  }
  &__user-image {
    @apply w-7 h-7 rounded-full object-cover;
  }
  &__user-anonymous-image {
    @apply w-7 h-7 rounded-full object-cover bg-neutral-01-400 text-body-02 text-white flex items-center justify-center;
  }
}
</style>
