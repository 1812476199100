<template>
  <div class="StudyConversationMessageProfileName" :class="classList">
    <div v-if="isOwnerVisible" class="StudyConversationMessageProfileName__name">
      {{ fullName }}
    </div>
    <div v-else-if="isOwnerModerator" class="StudyConversationMessageProfileName__moderator-name">
      {{ $t('study_conversation_message.hidden_moderator_name') }}
    </div>
  </div>
</template>

<script>
import { StudyConversationMessageOptionsModel } from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'

export default {
  name: 'StudyConversationMessageProfileName',
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout
      }
    },
    showFullLastName() {
      return false
    },
    fullName() {
      if (this.message.isOwnerUser) {
        return this.message.user.fullName
      } else {
        return this.$t('study_conversation_message.prospect_name')
      }
    },
    prospect() {
      return this.streamParameters.prospect
    },
    user() {
      return this.message.user
    },
    isOwnerVisible() {
      return !this.message.isOwnerUser
    },
    isOwnerModerator() {
      return this.message.isOwnerUser
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageProfileName {
  $root: &;
  &__name,
  &__moderator-name {
    @apply font-semibold truncate;
    @at-root #{$root}_mobile & {
      @apply text-body-02;
    }
    @at-root #{$root}_compact & {
      @apply text-body-02;
    }
  }
}
</style>
