<template>
  <div class="PollEditorNumber">
    <LLInputLocked class="PollEditorNumber__style-selector" required dark-caption>
      <template #caption>{{ $t('forum.poll.editor.number.answer_style') }}</template>
      <template #default>
        <LLInputSelect
          v-model="localPoll.numberStyle"
          :key-fn="(type) => type.value"
          :caption-fn="(type) => $t(`forum.poll.number_style.${type.label}`)"
          :reduce="(type) => type.value"
          :disabled="isPollCreated"
          :values="styleList"
          :max-height="500"
          :placeholder="$t('select_placeholder')"
          class="PollEditor__type-selector"
        >
        </LLInputSelect>
      </template>
    </LLInputLocked>
    <PollEditorNumberStars
      v-if="localPoll.numberStyle === PollNumberStyleEnum.STARS"
      class="PollEditorNumber__stars"
      :poll.sync="localPoll"
    ></PollEditorNumberStars>
    <PollEditorNumberSlider
      v-if="localPoll.numberStyle === PollNumberStyleEnum.SLIDER"
      class="PollEditorNumber__slider"
      :poll.sync="localPoll"
    ></PollEditorNumberSlider>
    <PollChoiceListOptions class="PollEditorNumber__options" :poll.sync="localPoll" />
  </div>
</template>

<script>
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'
import { PollNumberStyle } from '@/common/enums'
import { PollModel } from '@/models/poll'
import PollEditorNumberStars from '@/components/pages/forum/thread/poll/editor/number/PollEditorNumberStars.vue'
import PollEditorNumberSlider from '@/components/pages/forum/thread/poll/editor/number/PollEditorNumberSlider.vue'
import PollChoiceListOptions from '@/components/pages/forum/thread/poll/PollChoiceListOptions.vue'

export default {
  name: 'PollEditorNumber',
  components: {
    PollChoiceListOptions,
    PollEditorNumberSlider,
    PollEditorNumberStars,
    LLInputSelect,
    LLInputLocked
  },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      numberStyle: '',
      localPoll: new PollModel(this.poll)
    }
  },
  computed: {
    isPollCreated() {
      return !!this.poll?.id
    },
    PollNumberStyleEnum() {
      return PollNumberStyle
    },
    styleList() {
      const typeList = Object.keys(PollNumberStyle).map((key) => ({
        label: key.toLowerCase(),
        value: PollNumberStyle[key]
      }))
      return typeList
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.PollEditorNumber {
  &__style-selector {
    max-width: 300px;
  }
  &__stars {
    @apply mt-4;
  }
  &__slider {
    @apply mt-4;
  }
  &__options {
    @apply mt-8;
  }
}
</style>
