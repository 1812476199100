<template>
  <svg
    width="24"
    class="CSVIcon"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7_9811)">
      <path
        d="M18.9465 5.82734L15.6695 2.55078C15.318 2.19922 14.8414 2 14.3457 2H6.37461C5.33984 2.00391 4.5 2.84375 4.5 3.87852V20.1254C4.5 21.1602 5.33984 22 6.37461 22H17.6223C18.6574 22 19.5 21.1602 19.5 20.1254V7.15508C19.5 6.65938 19.298 6.17891 18.9465 5.82734ZM14.498 4.03086L17.4703 7.00273H14.498V4.03086ZM17.625 20.1254H6.37461V3.87852H12.6234V7.94023C12.6234 8.45977 13.0414 8.87734 13.5609 8.87734H17.625V20.1254ZM13.25 12.3125V13.125C13.25 14.5109 13.7531 15.816 14.6672 16.8004C14.7852 16.9273 14.9512 17 15.125 17C15.2988 17 15.4648 16.9273 15.5828 16.8004C16.4973 15.816 17 14.5109 17 13.125V12.3125C17 12.1398 16.8602 12 16.6875 12H16.0625C15.8898 12 15.75 12.1398 15.75 12.3125V13.125C15.75 13.9168 15.5273 14.6941 15.125 15.3469C14.7227 14.6945 14.5 13.9168 14.5 13.125V12.3125C14.5 12.1398 14.3602 12 14.1875 12H13.5625C13.3898 12 13.25 12.1398 13.25 12.3125ZM9.1875 12H8.875C7.83945 12 7 12.8395 7 13.875V15.125C7 16.1605 7.83945 17 8.875 17H9.1875C9.36016 17 9.5 16.8602 9.5 16.6875V16.0625C9.5 15.8898 9.36016 15.75 9.1875 15.75H8.875C8.52969 15.75 8.25 15.4703 8.25 15.125V13.875C8.25 13.5297 8.52969 13.25 8.875 13.25H9.1875C9.36016 13.25 9.5 13.1102 9.5 12.9375V12.3125C9.5 12.1398 9.36016 12 9.1875 12ZM11.5098 13.659C11.4559 13.6125 11.427 13.5594 11.427 13.509C11.427 13.3871 11.6008 13.2504 11.8336 13.2504H12.3125C12.4852 13.2504 12.625 13.1105 12.625 12.9379V12.3129C12.625 12.1402 12.4852 12.0004 12.3125 12.0004H11.8332C10.9195 12.0004 10.1766 12.6773 10.1766 13.509C10.1766 13.9254 10.3664 14.3262 10.6973 14.6082L11.5523 15.3414C11.6062 15.3879 11.6352 15.441 11.6352 15.4914C11.6352 15.6133 11.4613 15.75 11.2285 15.75H10.75C10.5773 15.75 10.4375 15.8898 10.4375 16.0625V16.6875C10.4375 16.8602 10.5773 17 10.75 17H11.2293C12.143 17 12.8859 16.323 12.8859 15.4914C12.8859 15.075 12.6961 14.6742 12.3652 14.3922L11.5098 13.659Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_9811">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CSVIcon'
}
</script>

<style scoped lang="scss">
.CSVIcon {
  @apply fill-current;
}
</style>
