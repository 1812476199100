<template>
  <div class="StudyConversationMessageHeader" :class="classList">
    <div
      v-if="options.elementsVisibility.participantAvatar"
      class="StudyConversationMessageHeader__avatar-wrapper"
    >
      <StudyConversationMessageAvatar
        :message="message"
        :is-compact-layout="isCompactLayout"
        :is-mobile-layout="isMobileLayout"
        :stream-parameters="streamParameters"
      />
    </div>
    <div class="StudyConversationMessageHeader__content">
      <StudyConversationMessageProfileName
        class="StudyConversationMessageHeader__profile"
        :message="message"
        :stream-parameters="streamParameters"
        :is-compact-layout="isCompactLayout"
        :is-mobile-layout="isMobileLayout"
      />
      <StudyConversationMessageTimestamp
        class="StudyConversationMessageHeader__timestamp"
        :message="message"
      />
    </div>
  </div>
</template>

<script>
import { StudyConversationMessageOptionsModel } from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import StudyConversationMessageAvatar from '@/components/modules/study/study-conversation-message/StudyConversationMessageAvatar.vue'
import StudyConversationMessageTimestamp from '@/components/modules/study/study-conversation-message/StudyConversationMessageTimestamp.vue'
import StudyConversationMessageProfileName from '@/components/modules/study/study-conversation-message/StudyConversationMessageProfileName.vue'

export default {
  name: 'StudyConversationMessageHeader',
  components: {
    StudyConversationMessageAvatar,
    StudyConversationMessageTimestamp,
    StudyConversationMessageProfileName
  },
  props: {
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true },
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageHeader {
  $root: &;
  @apply flex items-center min-w-0;
  &_compact,
  &_mobile {
    @apply items-start;
  }
  &__avatar-wrapper {
    @at-root #{$root}_compact &,
      #{$root}_mobile & {
      @apply mt-1;
    }
  }
  &__profile {
    @apply min-w-0;
    max-width: 100%;
  }
  &__content {
    @apply flex ml-2 items-center min-w-0;
    &:first-child {
      @apply ml-0;
    }

    @at-root #{$root}_compact & {
      @apply flex-col items-start;
    }
    @at-root #{$root}_mobile & {
      @apply flex-col items-start;
    }
  }
  &__timestamp {
    @apply ml-4 mt-0.5;
    @at-root #{$root}_compact & {
      @apply ml-0 mt-0;
    }
    @at-root #{$root}_mobile & {
      @apply ml-0 mt-0;
    }
  }
}
</style>
