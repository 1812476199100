export default function handleApiError(error, router) {
  if (!error.response || !error.response.data) {
    console.error('Неизвестная ошибка:', error)
    return
  }

  const key = error.response.data.key

  switch (key) {
    case 'DOES_NOT_EXIST_STUDY':
      router.push({
        path: '/',
        replace: true,
        query: {
          force: true
        }
      })
      break
  }
}
