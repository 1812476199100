<template>
  <div v-if="isNewReplyVisible || hasReplies" class="ForumResponseReplies" :class="classList">
    <div class="ForumResponseReplies__new-response-wrapper">
      <ForumResponse
        v-if="isNewReplyVisible"
        ref="new-reply"
        class="ForumResponseReplies__new-response"
        :parent-response-id="parentResponseIdOfNewReply"
        :reply-id="response.id"
        :chat-id="response.chatId"
        :is-able-to-reply="isAbleToReply"
        :thread-id="response.threadId"
        @update="onNewReplyCreate"
        @cancel="hideNewReply"
      />
    </div>
    <div
      v-if="(!repliesExpanded || (isFetching && !replyList.length)) && !isNewReplyVisible"
      class="ForumResponseReplies__thread-lines"
    >
      <div class="ForumResponseReplies__thread-line-parent-main-cutter"></div>
      <div class="ForumResponseReplies__thread-line-reply-button"></div>
    </div>
    <div v-if="hasReplies" class="ForumResponseRepliesSpoiler">
      <LLButton
        class="ForumResponseRepliesSpoiler__button ForumResponseRepliesSpoilerButton"
        small
        tertiary
        @click="onExpand"
      >
        <template #icon-left>
          <TopicRedIcon class="ForumResponseRepliesSpoilerButton__icon" />
        </template>
        <LLChevron class="ForumResponseRepliesSpoilerButton__chevron" :opened="repliesExpanded">
          {{ $tc('common_amount_replies', repliesCount) }}
        </LLChevron>
      </LLButton>
    </div>
    <div v-if="repliesExpanded" class="ForumResponseReplies__replies">
      <LLButton
        v-if="isLoadMoreButtonVisible"
        class="ForumResponseReplies__load-more-button"
        small
        secondary
        @click="loadMore"
      >
        {{ $t('button_load-more') }}
      </LLButton>
      <LLLoader v-if="isFetching" class="ForumResponseReplies__loader" left></LLLoader>
      <LLLoaderError
        v-else-if="isError"
        class="ForumChatThreadList__error"
        :has-cancel="false"
        @retry="onRetry"
      ></LLLoaderError>
      <div>
        <ForumResponse
          v-for="(reply, counter) in mappedResponseList"
          :key="reply.id"
          :is-editable="isEditable"
          :reply-level="replyLevel + 1"
          :response="reply"
          :is-last="counter === mappedResponseList.length - 1"
          :is-first="counter === 0"
          :is-able-to-reply="isAbleToReply"
          class="ForumResponseReplies__reply"
          @read="onReplyRead"
          @update="updateReply"
          @remove="removeReply"
          @updateReply="updateReply"
        >
        </ForumResponse>
      </div>
      <div
        v-if="false && !isNewReplyVisible && isEditable && isAbleToReply"
        class="ForumResponseReplies__new-reply-button-wrapper"
      >
        <button class="ForumResponseReplies__new-reply-button" @click="showNewReply">
          {{ $t('forum.response.write_a_reply_button') }}
        </button>
        <!--            <LLButton class="ForumResponseReplies__new-reply-button" small >
            {{ $t('forum.response.write_a_reply_button') }}
          </LLButton>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import threadEditor from '@/utils/modules/forum/threadEditor'
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLLoader from '@/components/common/LLLoader.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'
import TopicRedIcon from '@/assets/icons/TopicRedIcon.vue'
import LLChevron from '@/components/common/LLChevron.vue'

const CancelToken = axios.CancelToken

export default {
  name: 'ForumResponseReplies',
  components: {
    LLChevron,
    TopicRedIcon,
    LLLoaderError,
    LLLoader,
    LLButton,
    ForumResponse: () => import('@/components/pages/forum/response/ForumResponse')
  },
  props: {
    replyLevel: { type: Number, default: 0 },
    isNewReplyVisible: { type: Boolean, required: false },
    response: { type: ForumResponseModel, required: true },
    isEditable: { type: Boolean, default: true },
    isAbleToReply: { type: Boolean, default: true }
  },
  data() {
    return {
      repliesExpanded: false,
      users: {},
      total: undefined,
      prospects: {},
      replyList: [],
      cancelTokenSource: null,
      isFetching: false,
      isError: false
    }
  },
  computed: {
    classList() {
      const baseClass = 'ForumResponseReplies'
      const classes = []
      if (this.replyLevel >= 1) {
        classes.push(`${baseClass}_compact`)
      }
      return classes
    },
    hasReplies() {
      return !!this.repliesCount
    },
    parentResponseIdOfNewReply() {
      return this.replyLevel > 1 ? this.response?.parentResponseId : this.response?.id
    },
    isLoadMoreButtonVisible() {
      return this.repliesCount > this.mappedResponseList.length && !this.isFetching
    },
    repliesCount: {
      get() {
        return this.total ?? this.response.repliesCount
      },
      set(newValue) {
        this.total = newValue < 0 ? 0 : newValue
      }
    },
    mappedResponseList() {
      const remapResponse = (response) => {
        const newResponse = new ForumResponseModel(response)
        if (!newResponse.user) {
          newResponse.user = newResponse.userId ? this.users[newResponse.userId] : null
        }
        if (!newResponse.prospect) {
          newResponse.prospect = newResponse.prospectId ? this.prospects[newResponse.prospectId] : null
        }
        if (newResponse.replies.length) {
          newResponse.replies = newResponse.replies
            .filter((reply) => !reply.deletedAt)
            .map((reply) => {
              return remapResponse(reply)
            })
        }
        return newResponse
      }
      return this.replyList
        .filter((response) => !response.deletedAt)
        .map((response) => {
          return remapResponse(response)
        })
        .sort((a, b) => a.createdAt - b.createdAt)
    }
  },
  watch: {
    isEditable: {
      handler(to) {
        if (!to) {
          this.hideNewReply()
        }
      }
    },
    repliesExpanded: {
      handler() {
        /* this.replyList = []
        if (to) {
          this.fetchReplies()
        } */
      }
    },
    total: {
      handler() {
        if (this.total === 0) {
          this.repliesExpanded = false
        }
        this.$emit('updateTotal', this.total)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fireScrollToHelper()
      scrollToForumReplyHelper.addListener({ callback: this.fireScrollToHelper })
      threadEditor.addEvent(threadEditor.enums.RESPONSE_CREATE, this.createResponseFromSocket)
      threadEditor.addEvent(threadEditor.enums.RESPONSE_UPDATE, this.updateResponseFromSocket)
      threadEditor.addEvent(threadEditor.enums.RESPONSE_DELETE, this.deleteResponseFromSocket)
      this.initReplies()
    })
  },
  beforeDestroy() {
    scrollToForumReplyHelper.removeListener({ callback: this.fireScrollToHelper })
  },
  destroyed() {
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_CREATE, this.createResponseFromSocket)
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_UPDATE, this.updateResponseFromSocket)
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_DELETE, this.deleteResponseFromSocket)
  },
  methods: {
    onReplyRead({ responseId }) {
      this.$emit('read', { responseId })
    },
    onRetry() {
      this.initReplies()
    },
    initReplies() {
      if (
        !this.response.replies?.length ||
        scrollToForumReplyHelper.isWatchingResponse({ responseId: this.response?.id })
      ) {
        return
      }
      this.response.replies.forEach((reply) => {
        this.updateReply({ response: reply, doNotUpdateCount: true })
      })
      this.repliesExpanded = true
    },
    async fireScrollToHelper() {
      if (
        scrollToForumReplyHelper.isWatchingResponse({ responseId: this.response?.id }) &&
        scrollToForumReplyHelper.hasChild &&
        this.repliesCount
      ) {
        scrollToForumReplyHelper.removeResponse({ responseId: this.response?.id })
        const replyToWatch = scrollToForumReplyHelper.responseToWatch
        this.repliesExpanded = true
        if (!this.replyList.find((reply) => reply.id === replyToWatch)) {
          await this.fetchReplies()
          if (!this.replyList.find((reply) => reply.id === replyToWatch)) {
            this.$notify({
              group: 'main',
              title: this.$t('forum.response.cannot-find-message')
            })
            scrollToForumReplyHelper.removeAll()
          }
        }
      }
    },
    onExpand() {
      this.repliesExpanded = !this.repliesExpanded
      this.replyList = []
      if (this.repliesExpanded) {
        this.fetchReplies()
      }
    },
    createResponseFromSocket({ response }) {
      if (response?.parentResponseId === this.response.id) {
        this.repliesExpanded = true
        this.updateReply({ response: new ForumResponseModel(response) })
      }
    },
    updateResponseFromSocket({ response }) {
      if (response?.parentResponseId === this.response.id) {
        this.updateReply({ response: new ForumResponseModel(response) })
      }
    },
    deleteResponseFromSocket({ responseId, parentResponseId }) {
      if (this.response.id === parentResponseId) {
        this.removeReply({ responseId, isFromSocket: true })
      }
      this.replyList.forEach((reply) => {
        if (reply.replyToId === responseId) {
          reply.replyToId = null
        }
      })
    },
    loadMore() {
      this.fetchReplies()
    },
    hideNewReply() {
      this.$emit('update:isNewReplyVisible', false)
    },
    showNewReply() {
      this.$emit('update:isNewReplyVisible', true)
    },
    onNewReplyCreate({ response }) {
      this.hideNewReply()
      this.updateReply({ response })
      this.repliesExpanded = true
    },
    removeReply({ responseId, isFromSocket }) {
      const oldReply = this.replyList.find((item) => item.id === responseId)
      if ((isFromSocket && !oldReply) || (oldReply && !oldReply.deletedAt)) {
        this.repliesCount--
      }
      if (oldReply && !oldReply.deletedAt) {
        oldReply.deletedAt = Date.now()
      }
    },
    updateReply({ response, doNotUpdateCount = false }) {
      const oldReplyIndex = this.replyList.findIndex((item) => item.id === response.id)
      const oldReply = this.replyList?.[oldReplyIndex]
      if (oldReply?.response?.updatedAt === response.updatedAt) {
        return
      }
      if (oldReplyIndex !== -1) {
        this.replyList.splice(oldReplyIndex, 1, response)
      } else if (this.replyLevel <= 1) {
        if (!doNotUpdateCount) this.repliesCount++
        this.replyList.push(response)
        if (response.user) {
          this.users = { ...this.users, [response.user.id]: response.user }
        }
        if (response.prospect) {
          this.prospects = { ...this.prospects, [response.prospect.id]: response.prospect }
        }
      } else {
        this.$emit('updateReply', { response })
      }
    },
    focusOnNewReply() {
      if (this.$refs['new-reply']) {
        this.$refs['new-reply'].focus()
      }
    },
    async fetchReplies() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.cancelTokenSource = CancelToken.source()

      this.isFetching = true
      this.error = null
      try {
        const { chatId, threadId, id: responseId } = this.response
        const {
          threadResponses: serverResponseList,
          totalRecords,
          users,
          prospects
        } = await this.$api.forum.responses.getRepliesList({
          chatId,
          threadId,
          timestampOffset: !scrollToForumReplyHelper.responseToWatch
            ? this.mappedResponseList?.[0]?.createdAt || undefined
            : undefined,
          replyId: scrollToForumReplyHelper.responseToWatch
            ? scrollToForumReplyHelper.responseToWatch
            : undefined,
          parentResponseId: responseId,
          perPage: scrollToForumReplyHelper.responseToWatch ? undefined : 10,
          cancelToken: this.cancelTokenSource.token
        })
        const replyList = serverResponseList.map((response) =>
          ForumResponseModel.parseFromApi({ response, chatId, threadId })
        )
        const usersObject = {}
        users.forEach((user) => {
          usersObject[user.id] = UserModel.parseFromApi({ user })
        })
        const prospectObject = {}
        prospects.forEach((prospect) => {
          prospectObject[prospect.id] = ProspectModel.parseFromApi({ prospect })
        })
        this.users = { ...this.users, ...usersObject }
        this.prospects = { ...this.prospects, ...prospectObject }
        replyList.forEach((reply) => {
          this.updateReply({ response: reply })
        })
        this.repliesCount = totalRecords
        // this.replyList = [...this.replyList, ...replyList]
      } catch (e) {
        console.log(e)
      } finally {
        this.isFetching = false
        this.silentFetching = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseReplies {
  $root: &;

  &__reply {
    @apply mt-4 w-full relative;
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      /*&:before {
        @apply absolute bg-white;
        content: '';
        width: 2px;
        height: calc(100% - 13px);
        margin-left: -19px;
        margin-top: 13px;
        @screen md {
          height: calc(100% - 17px);
          margin-left: -30px;
          margin-top: 17px;
        }
      }*/
    }
  }

  &__load-more-button {
    @apply mb-4;
  }

  &__new-reply-button-wrapper {
    @apply flex;
  }

  &__new-reply-button {
    @apply text-body-02 text-primary-01-400 transition duration-200;
    &:hover {
      @apply text-primary-01-200;
    }

    &:focus-visible {
      @apply text-primary-01-200 outline-none;
    }

    &:focus-within {
      @apply outline-none;
    }

    &:active {
      @apply text-primary-01-600;
    }

    @apply mt-2 mr-4;
    @screen md {
      @apply mr-0;
    }
  }

  &__replies {
    @apply mt-4;
  }

  &__loader {
    @apply mb-4;
  }

  &Spoiler {
    @apply w-full flex justify-start relative;
    &__button {
      @apply -ml-4;
    }
  }

  &SpoilerButton {
    &__icon {
      @apply h-5 w-5 text-accent-01;
    }

    &__chevron {
      @apply pr-0.5;
    }
  }
  &__new-response-wrapper {
    @apply mb-2;
    &:last-child {
      @apply mb-0;
    }
    @apply mt-4;
    @screen md {
      @apply mt-0;
    }
  }

  &__new-response {
  }

  &__thread-lines {
    @apply absolute left-0 top-0 h-full;
  }

  &__thread-line-parent-main-cutter {
    @apply bg-white absolute left-0 top-0;
    width: 2px;
    height: 100%;
    margin-left: -19px;
    margin-top: 15px;
    @screen md {
      margin-left: -30px;
    }
  }

  &__thread-line-reply-button {
    @apply bg-neutral-01-50 absolute left-0 top-0;
    height: 2px;
    width: 14px;
    margin-left: -19px;
    margin-top: 14px;
    @screen md {
      width: 20px;
      margin-left: -30px;
      margin-top: 13px;
    }
  }
}
</style>
