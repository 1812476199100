<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49958 8.75C2.49958 5.25 5.24829 2.5 8.74667 2.5C12.245 2.5 14.9938 5.25 14.9938 8.75C14.9938 12.25 12.245 15 8.74667 15C5.2483 15 2.49958 12.25 2.49958 8.75ZM17.8667 19.625C18.3664 20.125 19.1161 20.125 19.6158 19.625C20.1156 19.125 20.1156 18.375 19.6158 17.875L15.7426 14C16.8671 12.5 17.4918 10.75 17.4918 8.75C17.4918 3.875 13.6186 0 8.74592 0C3.87319 0 0 3.875 0 8.75C0 13.625 3.87319 17.5 8.74592 17.5C10.745 17.5 12.4942 16.875 13.9935 15.75L17.8667 19.625Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>
