<template>
  <div class="StudyNewPageBody">
    <StudyNewPageCommonFixedTopics
      :topics="filteredTopics"
      :conversation-streams="conversationStreams"
      :selected-topic-id="selectedTopicId"
      :visible="isFixedTopicsVisible"
      @update:selectedTopicId="onChangeTopic"
    />
    <StudyNewPageTopicSelector
      ref="topicsSelector"
      class="StudyNewPageBody__topic-selector"
      :topics="filteredTopics"
      :conversation-streams="conversationStreams"
      :selected-topic-id="selectedTopicId"
      @update:selectedTopicId="onChangeTopic"
      @updateMessage="onUpdateMessage"
    ></StudyNewPageTopicSelector>
    <div class="StudyNewPageBody__questions-content">
      <transition name="questions" mode="out-in">
        <StudyNewPageQuestions
          v-if="selectedTopic"
          :key="selectedTopicId"
          class="StudyNewPageBody__questions"
          :conversation-streams="conversationStreams"
          :question-fetcher="questionsFetcher"
          :study="study"
          :topic="selectedTopic"
          @updateMessage="onUpdateMessage"
          @fetchTopicsMeta="fetchTopicsMeta"
        ></StudyNewPageQuestions>
      </transition>
      <transition name="button-fade" mode="out-in">
        <StudyNewPageNextTopicButton
          v-if="selectedTopicId && !questionsFetcher.isFetching"
          class="StudyNewPageBody__next-topic-button"
          :selected-topic-id="selectedTopicId"
          :topics="unlockedTopicList"
          @update:selectedTopicId="onChangeTopic"
        />
      </transition>
      <StudyNewPageBodyModals
        v-if="!questionsFetcher.isFetching"
        ref="page-modals"
        :question-fetcher="questionsFetcher"
        :topics="topics"
        :selected-topic-id="selectedTopicId"
        :conversation-streams="conversationStreams"
        :study="study"
      />
    </div>
  </div>
</template>

<script>
import StudyNewPageTopicSelector from '@/components/pages/study-new/StudyNewPageTopicSelector.vue'
import StudyNewPageCommonFixedTopics from '@/components/pages/study-new/common/StudyNewPageCommonFixedTopics.vue'
import StudyNewPageNextTopicButton from '@/components/pages/study-new/StudyNewPageNextTopicButton.vue'
import { StudyModel } from '@/models/study'
import { StreamState } from '@/common/enums'
import { TopicModel } from '@/models/topic'
import StudyNewPageBodyUtils from '@/components/pages/study-new/page-body/StudyNewPageBodyUtils.js'
import StudyNewPageBodyModals from '@/components/pages/study-new/page-body/StudyNewPageBodyModals.vue'
import StudyNewPageQuestions from '@/components/pages/study-new/StudyNewPageQuestions.vue'
import studyNextQuestionScroller from '@/utils/modules/studyNextQuestionScroller'
import studyErrorHandler from '@/utils/studyErrorHandler'

export default {
  name: 'StudyNewPageBody',
  components: {
    StudyNewPageQuestions,
    StudyNewPageBodyModals,
    StudyNewPageNextTopicButton,
    StudyNewPageCommonFixedTopics,
    StudyNewPageTopicSelector
  },
  props: {
    study: { type: StudyModel, required: true },
    /**
     * @type {TopicModel[]}
     */
    topics: { type: Array, default: () => [] },
    /**
     * @type {ConversationStreamModel[]}
     */
    conversationStreams: { type: Array, required: true, default: () => [] }
  },
  data() {
    return {
      selectedTopicId: null,
      isFixedTopicsVisible: false,
      isFirstTimeChangeTopic: true,
      questionsFetcher: new StudyNewPageBodyUtils({ studyId: this.study.id }),
      isQuestionSent: false
    }
  },
  computed: {
    isStudyFinished() {
      const studyQuestionIdList = this.topics.reduce((result, topic) => {
        result = result.concat(topic.questionIdList)
        return result
      }, [])
      return (
        studyQuestionIdList.filter((questionId) => {
          return this.conversationStreams.find((conversationStream) => {
            return (
              conversationStream.questionId === questionId &&
              conversationStream.state === StreamState.NOT_ANSWERED
            )
          })
        })?.length === 0
      )
    },
    shouldScrollToNextQuestion() {
      return (
        !this.isStudyFinished &&
        this.isQuestionSent &&
        !this.questionsFetcher.isFetchingPart &&
        !this.questionsFetcher.isFetching &&
        !this.$store.getters['studyMessages/hasSendingMessage']
      )
    },
    questions() {
      return this.questionsFetcher.questions
    },
    orderedQuestions() {
      return [...this.questions].sort((a, b) => a?.order - b?.order)
    },
    firstUnansweredQuestion() {
      return this.orderedQuestions.find((question) => {
        return this.conversationStreamsObject[question.id]?.state === StreamState.NOT_ANSWERED
      })
    },
    conversationStreamsObject() {
      return this.conversationStreams.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    filteredTopics() {
      return this.topics.map((topic) => {
        /*const isLocked = !!topic.lockerIds.find((lockerTopicId) => {
          return this.topicsObject[lockerTopicId].questionIdList.find((questionId) => {
            return this.conversationStreamsObject?.[questionId]?.state !== StreamState.RESOLVED
          })
        })*/
        const isLocked = !topic.isVisible && !topic.hideLockedTopics
        return new TopicModel({ ...topic, isLocked: isLocked })
      })
    },
    topicsObject() {
      return this.topics.reduce((result, topic) => {
        result[topic.id] = topic
        return result
      }, {})
    },
    selectedTopic() {
      return this.topics.find((topic) => topic.id === this.selectedTopicId)
    },
    currentTopicQuestions() {
      return this.selectedTopic?.questionIdList?.map((questionId) => this.questionsObject[questionId]) || []
    },
    currentQuestionIdList() {
      return this.questionsFetcher.questions.map((question) => question.id).sort((a, b) => a.localeCompare(b))
    },
    unlockedTopicList() {
      return this.filteredTopics.filter(
        (topic) => !topic.isLocked && topic.isVisible && topic.questionIdList?.length
      )
    }
  },
  watch: {
    selectedTopicId: {
      handler() {
        this.questionsFetcher.setTopicId({ topicId: this.selectedTopicId })
        this.questionsFetcher.fetchQuestions()
      }
    },
    'selectedTopic.questionIdList': {
      handler(to, from) {
        if (_.isEqual(to, from)) {
          return
        }
        this.onUpdateTopicQuestions()
      },
      deep: true
    },
    shouldScrollToNextQuestion(to, from) {
      if (to && !from) {
        this.$nextTick(() => {
          this.scrollToNextQuestion()
        })
      }
    }
  },
  created() {
    this.questionsFetcher.errorHandler = (e) => {
      studyErrorHandler(e, this.$router)
    }
  },
  mounted() {
    studyNextQuestionScroller.addEvent(this.questionScrollerHandler)
    window.addEventListener('scroll', this.scrollChecker)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollChecker)
  },
  beforeDestroy() {
    this.questionsFetcher.destroy()
  },
  methods: {
    onUpdateTopicQuestions() {
      if (this.questionsFetcher.isFetching || this.questionsFetcher.isFetchingPart) {
        return
      }
      const disappearedQuestionIdList = this.questionsFetcher.questions
        .map((question) => question.id)
        .filter((questionId) => !this.selectedTopic.questionIdList.includes(questionId))
      if (disappearedQuestionIdList.length) {
        disappearedQuestionIdList.forEach((questionId) =>
          this.questionsFetcher.removeQuestion({ questionId })
        )
      }

      const appearedQuestionIdList = this.selectedTopic.questionIdList.filter(
        (questionId) => !this.questionsFetcher.questions.find((question) => question.id === questionId)
      )
      if (appearedQuestionIdList.length) {
        this.questionsFetcher.onQuestionVisibilityUpdate({
          questionsToUpdate: [
            {
              questionId: appearedQuestionIdList[0],
              topicId: this.selectedTopic.id,
              isVisible: true
            }
          ]
        })
      }
    },
    fetchTopicsMeta() {
      this.$emit('fetchTopicsMeta')
    },
    scrollToNextQuestion() {
      if (this.firstUnansweredQuestion) {
        setTimeout(() => {
          studyNextQuestionScroller.fireQuestionEvent({ questionId: this.firstUnansweredQuestion?.id })
        }, 400)
      } else if (!this.isStudyFinished) {
        const selectedTopicIndex = this.unlockedTopicList.findIndex(
          (topic) => topic.id === this.selectedTopicId
        )
        if (selectedTopicIndex === -1) return
        if (this.unlockedTopicList[selectedTopicIndex + 1]) {
          this.selectedTopicId = this.unlockedTopicList[selectedTopicIndex + 1].id
          this.isQuestionSent = true
          return
        }
      }
      this.isQuestionSent = false
    },
    questionScrollerHandler() {
      this.isQuestionSent = true
    },
    scrollChecker() {
      const topicsSelectorBlock = this.$refs.topicsSelector.$el
      if (!topicsSelectorBlock) return
      const rect = topicsSelectorBlock.getBoundingClientRect()
      const headerHeight = document.querySelector('.header').offsetHeight || 68
      this.isFixedTopicsVisible = rect.top < headerHeight
    },
    onChangeTopic(topicId) {
      if (!topicId) {
        topicId = null
        return
      }
      if (
        !this.selectedTopic?.order ||
        this.selectedTopic?.order > this.topics?.find((topic) => topic?.id === topicId)?.order
      ) {
        this.selectedTopicId = topicId
        this.questionsFetcher.addCallbackToNextSuccessFetch({
          callback: () => {
            this.$nextTick(() => {
              this.scrollToTopicInstructions()
            })
          }
        })
      } else {
        const pageModals = this.$refs['page-modals']
        if (!pageModals) {
          this.selectedTopicId = topicId
          this.questionsFetcher.addCallbackToNextSuccessFetch({
            callback: () => {
              this.$nextTick(() => {
                this.scrollToTopicInstructions()
              })
            }
          })
          return
        }
        pageModals.onRouteLeave(() => {
          this.selectedTopicId = topicId
          this.questionsFetcher.addCallbackToNextSuccessFetch({
            callback: () => {
              this.$nextTick(() => {
                this.scrollToTopicInstructions()
              })
            }
          })
        })
      }
    },
    onUpdateMessage({ message }) {
      this.$emit('updateMessage', { message })
    },
    scrollToTopicInstructions() {
      if (this.isFirstTimeChangeTopic) {
        this.isFirstTimeChangeTopic = false
        return
      }
      setTimeout(() => {
        this.$refs.topicsSelector?.scrollToInstructions && this.$refs.topicsSelector?.scrollToInstructions()
      }, 350)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageBody {
  &__questions-content {
    @apply flex flex-col items-stretch;
    min-height: 220px;
  }
  &__questions {
    @apply mt-8 flex-1;

    &.questions-enter-active,
    &.questions-leave-active {
      transition-property: opacity, transform;
      transition-duration: 0.3s;
    }
    &.questions-enter,
    &.questions-leave-to {
      opacity: 0;
    }

    &.questions-enter {
      transform: translateX(20px);
    }

    &.questions-leave-to {
      transform: translateX(-20px);
    }
  }
  &__next-topic-button {
    @apply mt-8 flex-shrink-0 w-full;
    &.button-fade-enter-active {
      transition-property: opacity, transform;
      transition-duration: 0.3s;
    }
    &.button-fade-leave-active {
      transition-property: opacity, transform;
      transition-duration: 0.3s;
    }
    &.button-fade-enter {
      opacity: 0;
      transform: translateY(-10px);
    }

    &.button-ade-leave-to {
      opacity: 0;
      transform: translateY(10px);
    }
  }
}
</style>
