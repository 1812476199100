<template>
  <LLPage class="StudyPage">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #content>
      <UploadProgress></UploadProgress>
      <div v-if="pending" class="StudyPage__loading">
        <LLLoader></LLLoader>
      </div>
      <div v-else-if="study" class="StudyPage__content">
        <StudyNewPageScreening
          v-if="isScreeningPageVisible"
          :study="study"
          :topics="topics"
          :screening-questions="screeningQuestions"
        />
        <StudyNewPageCommon
          v-else-if="isCommonStudyPageVisible"
          ref="common"
          :study="study"
          :topics="topics"
          :questions="questions"
          @studyUpdate="onStudyUpdate"
          @fetchTopicsMeta="fetchTopicsMeta"
        />
      </div>
    </template>
  </LLPage>
</template>

<script>
import UploadProgress from '@/components/common/UploadProgress.vue'
import LLPage from '@/components/layout/LLPage.vue'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs.vue'
import LLLoader from '@/components/common/LLLoader.vue'
import { StudyModel } from '@/models/study'
import StudyNewPageScreening from '@/components/pages/study-new/StudyNewPageScreening.vue'
import StudyNewPageCommon from '@/components/pages/study-new/StudyNewPageCommon.vue'
import { TopicModel } from '@/models/topic'
import { QuestionModel } from '@/models/question'
import studyMessages from '@/utils/modules/studyMessages'

export default {
  name: 'StudyNewPage',
  components: { StudyNewPageCommon, StudyNewPageScreening, LLLoader, LLBreadCrumbs, LLPage, UploadProgress },
  beforeRouteLeave(to, from, next) {
    if (to?.query?.force) {
      next()
      return
    }
    if (this.$refs?.common?.$refs?.body?.$refs?.['page-modals']) {
      this.$refs?.common?.$refs?.body?.$refs?.['page-modals'].onRouteLeave(next)
    } else {
      next()
    }
  },
  data() {
    return {
      pending: false,
      redirectedFromDestination: false,
      study: null,
      topics: [],
      questions: [],
      screeningQuestions: []
    }
  },
  computed: {
    isCommonStudyPageVisible() {
      return (
        this.study.isLive ||
        this.study.isWaiting ||
        this.study.isPendingApproval ||
        (this.study.isInvited && !this.study.screeningQuestions.length)
      )
    },
    isScreeningPageVisible() {
      return this.study.isInvited && this.study.screeningQuestions.length
    },
    studyId() {
      return +this.$route.params?.studyId
    },
    crumbs() {
      return [
        {
          name: this.$t('main_home_label'),
          path: '/'
        },
        ...(this.study
          ? [
              {
                name: this.study?.name
              }
            ]
          : [])
      ]
    }
  },
  created() {
    if (+this.$store.state?.completeRegistration?.studyId === this.studyId) {
      this.redirectedFromDestination = true
    }
    this.$store.commit('completeRegistration/RESET_DESTINATION')
    this.fetchStudy()
  },
  mounted() {
    studyMessages.connect({
      token: this.$store.state.auth.token.replace('Bearer ', '')
    })
    studyMessages.addEvent(studyMessages.enums.QUESTION_DELETE, this.fetchStudySilent)
    studyMessages.addEvent(studyMessages.enums.QUESTION_UPDATE, this.fetchStudySilent)
    studyMessages.addEvent(studyMessages.enums.TOPIC_UPDATE, this.fetchStudySilent)
    studyMessages.addEvent(studyMessages.enums.TOPIC_DELETE, this.fetchStudySilent)
    studyMessages.addEvent(studyMessages.enums.STUDY_UPDATE, this.onStudyUpdated)
    studyMessages.addEvent(studyMessages.enums.PROSPECT_ACCOUNT_UPDATE, this.onProspectAccountUpdated)
  },
  beforeDestroy() {
    studyMessages.removeEvent(studyMessages.enums.QUESTION_DELETE, this.fetchStudySilent)
    studyMessages.removeEvent(studyMessages.enums.QUESTION_UPDATE, this.fetchStudySilent)
    studyMessages.removeEvent(studyMessages.enums.TOPIC_UPDATE, this.fetchStudySilent)
    studyMessages.removeEvent(studyMessages.enums.TOPIC_DELETE, this.fetchStudySilent)
    studyMessages.removeEvent(studyMessages.enums.STUDY_UPDATE, this.onStudyUpdated)
    studyMessages.removeEvent(studyMessages.enums.PROSPECT_ACCOUNT_UPDATE, this.onProspectAccountUpdated)
    studyMessages.destroy()
  },
  methods: {
    onProspectAccountUpdated() {
      this.fetchStudy({ silent: true })
    },
    onStudyUpdated({ study }) {
      if (!study.isLive && !study.isPendingApproval && !study.isInvited && !study.isWaiting) {
        this.$router.push({
          path: '/',
          replace: true,
          query: {
            force: true
          }
        })
        return
      }
      this.fetchStudy({ silent: true })
    },
    fetchStudySilent() {
      this.fetchStudy({ silent: true })
    },
    async fetchTopicsMeta() {
      const { topicMeta: topicMetaList } = await this.$api.studies.getStudyMeta({ studyId: this.studyId })
      topicMetaList.forEach((topicMeta) => {
        const topic = this.topics.find((topic) => {
          return topic.id === topicMeta.topicId
        })
        if (topic) {
          topic.questionIdList = topicMeta.questions
          topic.isVisible = topicMeta.isVisible
        }
      })

      const topicMetaObject = topicMetaList.reduce(
        (acc, topicMeta) => ({ ...acc, [topicMeta.topicId]: topicMeta }),
        {}
      )
      const topicsWithoutQuestions = this.topics.filter(
        (topic) => !topicMetaObject?.[topic?.id]?.questions?.length
      )
      topicsWithoutQuestions.forEach((topicWithoutQuestions) => {
        const topic = this.topics.find((topic) => {
          return topic.id === topicWithoutQuestions?.id
        })
        topic.questionIdList = []
      })
    },
    async fetchStudy(options) {
      const { silent } = options || {}
      this.pending = !silent
      try {
        const { study } = await this.$api.studies.get({ studyId: this.studyId })
        const { topics } = study
        const allTopicQuestionIdList = this.topics.reduce(
          (acc, topic) => ({ ...acc, [topic.id]: [...topic.questionIdList] }),
          {}
        )
        this.topics = topics
          .map((topic) => TopicModel.parseFromApi({ topic, studyId: study.id }))
          .map((topic) => new TopicModel({ ...topic, questionIdList: allTopicQuestionIdList[topic.id] }))
        this.screeningQuestions = study.screeningQuestions.map((screeningQuestion) =>
          QuestionModel.parseFromApi({ question: screeningQuestion, studyId: study.id })
        )
        this.study = StudyModel.parseFromApi({ study })
        await this.fetchTopicsMeta()
      } catch (e) {
        console.log(e)
        if (this.redirectedFromDestination) {
          this.$router.replace('/')
        } else {
          this.$router.replace('/404')
        }
      }
      this.pending = false
    },
    onStudyUpdate({ study }) {
      this.study = study
    }
  }
}
</script>

<style scoped lang="scss">
.StudyPage {
  &__loading {
    @apply h-48 items-center justify-center flex;
  }
}
</style>
