<template>
  <div class="dropdown-nav relative" :class="[whiteClass, transparentClass]">
    <button
      ref="button"
      type="button"
      class="dropdown-nav__button flex items-center focus:outline-none"
      :class="{ bordered: bordered }"
      @click="show = !show"
    >
      <slot name="action">
        <AddCircle v-if="!noIcon" class="text-accent-01 mr-2" />
      </slot>
      <span v-if="$slots['title']" class="title font-second font-bold mr-2 text-primary-01-400">
        <slot name="title" />
      </span>
      <span v-if="$slots['label']" class="mr-2 text-primary-01-400">
        <slot name="label" />
      </span>
      <ChevronDown v-if="!noChevron" :class="{ rotate: show }" class="text-neutral-01 chevron-down" />
    </button>

    <div
      v-show="show"
      ref="menu"
      class="dropdown-nav__menu mt-4 py-2 w-48 bg-bg-01 border border-neutral-01-50 rounded shadow-xl"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLDropdown',
  props: {
    white: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    noChevron: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false
  }),
  computed: {
    whiteClass() {
      return this.white ? 'white' : ''
    },
    transparentClass() {
      return this.transparent ? 'dropdown-nav_transparent' : ''
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickListener)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickListener)
  },
  methods: {
    onClickListener(event) {
      const { target } = event
      const isClickButton = this.$refs.button.contains(target)

      if (this.show && !isClickButton) {
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-nav {
  &__menu {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 1;
  }
  &.white {
    .title {
      @apply text-white;
    }
    .chevron-down {
      @apply text-white;
    }
  }
}

.chevron-down {
  transition: 0.2s;
}

.rotate {
  transform: rotate(180deg);
}

.bordered {
  @apply px-2 py-1 border border-neutral-01-50 rounded;

  .title {
    @apply font-normal text-sm;
  }
}
</style>
