import _ from 'lodash'

export class FileModel {
  constructor({
    id,
    createdAt,
    thumbnails,
    location,
    mimetype,
    fileName,
    extension,
    order,
    thumbnailPosition,
    isTemporary,
    temporaryData,
    uploadingInfo
  }) {
    this.id = id
    this.createdAt = createdAt || null
    this.thumbnails = thumbnails
    this.location = location
    this.mimetype = mimetype
    this.fileName = fileName
    this.extension = extension
    this.order = order ?? undefined
    this.thumbnailPosition = thumbnailPosition
      ? {
          top: thumbnailPosition?.top || null,
          left: thumbnailPosition?.left || null
        }
      : null
    this.order = order ?? undefined
    this.isTemporary = isTemporary || false
    this.temporaryData = temporaryData || null
    this.uploadingInfo = _.cloneDeep(uploadingInfo)
  }

  static parseFromApi({ file, isTemporary, temporaryData }) {
    return new FileModel({
      id: file?.id,
      createdAt: file?.createdAt,
      location: file?.location,
      mimetype: file?.mimetype,
      fileName: file?.fileName,
      extension: file?.extension,
      thumbnails: file?.thumbnails?.map((thumbnail) => ({
        size: thumbnail?.size,
        location: thumbnail?.location
      })),
      thumbnailPosition: file?.thumbnailPosition
        ? { top: file?.thumbnailPosition?.top, left: file?.thumbnailPosition?.left }
        : null,
      order: file?.order ?? undefined,
      isTemporary: isTemporary,
      temporaryData: temporaryData,
      uploadingInfo: _.cloneDeep(file?.uploadingInfo)
    })
  }

  getThumbnail50() {
    return this.thumbnails.find((thumbnail) => thumbnail.size === 50)
  }

  getThumbnail80() {
    return this.thumbnails.find((thumbnail) => thumbnail.size === 80)
  }
}
