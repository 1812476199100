import Network from '../network.js'

export default {
  fetch(request) {
    return Network.get('/prospects/self', request, true)
  },
  sync(request) {
    return Network.put('/prospects/self', request, true)
  },
  syncLanguage(request) {
    return Network.put('/prospects/self/language', request, true)
  }
}
