<template>
  <div class="ConversationWaiting">
    <div class="ConversationWaiting__title" data-e2e="waitingTitleBlock">
      {{ $t('conversation_waiting_block_title') }}
    </div>
    <div class="ConversationWaiting__description" data-e2e="waitingDescriptionBlock">
      {{ $t('conversation_waiting_block_description') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversationWaiting'
}
</script>

<style lang="scss" scoped>
.ConversationWaiting {
  width: 284px;
  @apply bg-success-01 pt-6 pb-10 px-5 text-center;
  &__title {
    @apply font-bold text-white text-2xl font-serif px-4;
    line-height: 32px;
  }
  &__description {
    @apply text-white font-semibold mt-5;
    line-height: 19px;
  }
}
</style>
