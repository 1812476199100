<template>
  <div class="LLActionMenuSelectorContainer">
    <div class="LLActionMenuSelectorContainer__checkbox-list">
      <slot name="checkbox-list"></slot>
    </div>

    <div v-if="isEmpty" class="LLActionMenuSelectorContainer__empty-state">
      <slot name="empty-state">No data</slot>
    </div>

    <div class="LLActionMenuSelectorContainer__footer">
      <div class="LLActionMenuSelectorContainer__loading-wrapper">
        <LoadingIcon v-if="loading" class="LLActionMenuSelectorContainer__loading"></LoadingIcon>
      </div>
      <div class="LLActionMenuSelectorContainer__actions">
        <slot name="action-button"></slot>
        <LLButton v-popper-close :disabled="disabled" small @click.native="confirm">
          <slot name="confirm-button-label">{{ $t('button_confirm') }}</slot>
        </LLButton>
      </div>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
import LoadingIcon from '@/components/common/LoadingIcon'
export default {
  name: 'LLActionMenuSelectorContainer',
  components: { LoadingIcon, LLButton },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    isEmpty: { type: Boolean, default: false }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLActionMenuSelectorContainer {
  @apply px-2;
  &__checkbox-list {
    @apply w-full;
  }
  &__loading {
    @apply w-5 h-5;
  }
  &__footer {
    @apply mt-4 flex items-end justify-between;
  }
  &__loading-wrapper {
    @apply flex-shrink-0;
  }
  &__actions {
    @apply flex flex-1 overflow-hidden justify-end items-center;
  }
  &__empty-state {
    @apply text-neutral-01-400 text-body-03 text-center py-5;
  }
  &__test {
    max-width: 40px;
    @apply truncate inline;
  }
}
</style>
