<template>
  <div>
    <PollResultsChartPie
      :data-label-callback="dataLabel"
      :tooltip-label-callback="tooltipLabel"
      :chart-pie-data="chartData"
    />
  </div>
</template>

<script>
import PollResultsChartPie from '@/components/modules/forum-chat/poll/results/PollResultsChartPie.vue'
import { getBgColor } from '@/utils/getRandomBackground'

export default {
  name: 'PollMultipleResultsPie',
  components: { PollResultsChartPie },
  props: {
    data: { type: Array, required: true }
  },
  computed: {
    chartData() {
      const backgrounds = getBgColor(this.data?.length)
      return {
        labels: this.data.map((item) => item.text),
        datasets: [
          {
            label: '',
            backgroundColor: backgrounds,
            data: this.data.map((item) => item.value),
            borderWidth: 0,
            hoverOffset: 0
          }
        ]
      }
    }
  },
  methods: {
    tooltipLabel(ctx) {
      return `${ctx.label}: ${ctx.parsed}`
    },
    dataLabel(value) {
      return `${value}%`
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultipleResultsPie {
}
</style>
