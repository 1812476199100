<template>
  <LLPopper
    class="LLInputSuggestions"
    trigger="manual"
    :offset="[0, 0]"
    strategy="absolute"
    placement="bottom-start"
    :show="showSuggestions"
  >
    <template #trigger>
      <div ref="input-wrapper" class="LLInputSuggestions__input">
        <slot name="input"></slot>
      </div>
    </template>
    <template #tooltip>
      <div class="LLInputSuggestions__list tooltip tooltip_white tooltip_without-arrow">
        <div ref="list-inner" :style="listStyle" class="tooltip-inner LLInputSuggestions__list-inner">
          <LLOptionsListButton
            v-for="suggestion in suggestions"
            :key="keyFn(suggestion)"
            ref="selected-suggestions"
            small
            :preselected="suggestion === selectedSuggestion"
            :unselected="suggestion !== selectedSuggestion"
            class="LLInputSuggestions__button"
            @click.prevent.native="selectSuggestion(suggestion)"
            @mousedown.prevent.native
            @mouseenter.native="preselectSuggestion(suggestion)"
          >
            {{ captionFn(suggestion) }}
          </LLOptionsListButton>
        </div>
      </div>
    </template>
  </LLPopper>
</template>

<script>
import LLPopper from '@/components/utils/LLPopper'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton'
export default {
  name: 'LLInputSuggestions',
  components: { LLOptionsListButton, LLPopper },
  props: {
    suggestions: { type: Array, default: () => [] },
    keyFn: { type: Function, default: (v) => v },
    captionFn: { type: Function, default: (v) => v }
  },
  data() {
    return {
      inputFocused: false,
      selectedSuggestion: null,
      inputWidth: null
    }
  },
  computed: {
    showSuggestions() {
      return !!(this.suggestionsLength && this.inputFocused)
    },
    suggestionsLength() {
      return this.suggestions?.length || 0
    },
    hasSuggestions() {
      return !!this.suggestionsLength
    },
    selectedSuggestionIndex() {
      return this.suggestions.indexOf(this.selectedSuggestion)
    },
    listStyle() {
      return {
        width: this.inputWidth ? `${this.inputWidth}px` : '300px'
      }
    }
  },
  watch: {
    suggestions: {
      handler() {
        if (this.suggestions.length) {
          this.selectedSuggestion = this.suggestions[0]
        } else {
          this.selectedSuggestion = null
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initInputEvents()
      this.initResizeObserver()
    })
  },
  beforeDestroy() {
    this.destroyInputEvents()
    this.destroyResizeObserver()
  },
  methods: {
    initResizeObserver() {
      const inputWrapper = this.$refs?.['input-wrapper']
      if (!inputWrapper) {
        return
      }
      const resizeObserver = new ResizeObserver(() => {
        this.inputWidth = inputWrapper?.offsetWidth
      })

      resizeObserver.observe(inputWrapper)
      this.resizeObserver = resizeObserver
    },
    destroyResizeObserver() {
      this.resizeObserver.disconnect()
    },
    preselectSuggestion(suggestion) {
      this.selectedSuggestion = suggestion
    },
    getInputElement() {
      const treeWalker = document.createTreeWalker(
        this.$refs?.['input-wrapper'],
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode() {
            return NodeFilter.FILTER_ACCEPT
          }
        },
        false
      )

      let node
      while ((node = treeWalker.nextNode()) && node.tagName !== 'INPUT') {}
      return node
    },
    destroyInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.removeEventListener('keydown', this.inputOnKeyDown)
      element.removeEventListener('focus', this.inputOnFocus)
      element.removeEventListener('blur', this.inputOnBlur)
    },
    initInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.addEventListener('keydown', this.inputOnKeyDown)
      element.addEventListener('focus', this.inputOnFocus)
      element.addEventListener('blur', this.inputOnBlur)
    },
    preselectNextSuggestion() {
      const newIndex =
        !this.selectedSuggestion || this.selectedSuggestionIndex === this.suggestionsLength - 1
          ? 0
          : this.selectedSuggestionIndex + 1
      this.selectedSuggestion = this.suggestions?.[newIndex]
    },
    preselectPreviousSuggestion() {
      const newIndex =
        !this.selectedSuggestion || this.selectedSuggestionIndex === 0
          ? this.suggestionsLength - 1
          : this.selectedSuggestionIndex - 1
      this.selectedSuggestion = this.suggestions?.[newIndex]
    },
    scrollToSuggestion() {
      if (
        this.$refs['list-inner'] &&
        this.$refs['selected-suggestions']?.[this.selectedSuggestionIndex]?.$el
      ) {
        this.$refs['selected-suggestions'][this.selectedSuggestionIndex].$el.scrollIntoView({
          block: 'nearest'
        })
      }
    },
    inputOnKeyDown(e) {
      if (!this.hasSuggestions) {
        return
      }
      if (e.keyCode === 38) {
        this.preselectPreviousSuggestion()
        this.scrollToSuggestion()
      } else if (e.keyCode === 40) {
        this.preselectNextSuggestion()
        this.scrollToSuggestion()
      } else if (e.keyCode === 13) {
        if (this.selectedSuggestion) {
          e.stopPropagation()
          e.preventDefault()
          this.selectSuggestion(this.selectedSuggestion)
        }
      }
    },
    inputOnFocus() {
      this.inputFocused = true
    },
    inputOnBlur() {
      this.inputFocused = false
    },
    selectSuggestion(suggestion) {
      this.$emit('select', suggestion)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSuggestions {
  @apply w-full;
  &__input {
    @apply w-full;
  }
  &__list {
    @apply bg-white;
    &-inner {
      max-height: 190px;
      @apply overflow-y-auto;
    }
  }
}
</style>
