<template>
  <div class="ForumThreadPageAsideThemes">
    <div class="ForumThreadPageAsideThemes__title">Themes</div>
    <div class="ForumThreadPageAsideThemes__list">
      <LLTags
        :with-remove="false"
        :with-input="false"
        overflow-hidden
        :overflow-lines="3"
        :values="['Test', 'Test2', 'Test3', 'Test4', 'Test5', 'Test6', 'Test7', 'Test8']"
      ></LLTags>
    </div>
  </div>
</template>

<script>
import LLTags from '@/components/common/ui-components/LLTags.vue'

export default {
  name: 'ForumThreadPageAsideThemes',
  components: { LLTags }
}
</script>

<style scoped lang="scss">
.ForumThreadPageAsideThemes {
  &__title {
    @apply text-primary-01-400 font-medium text-lg;
  }
  &__list {
    @apply mt-2;
  }
}
</style>
