export const InputStyling = {
  name: 'InputStyling',
  props: {
    disabled: { type: Boolean, default: false },
    stateError: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  data() {
    return {
      rootStyleClass: null,
      active: false,
      filled: false,
      error: false
    }
  },
  computed: {
    inputClasses() {
      const classes = []
      const rootClass = this.rootStyleClass
      if (this.active) {
        classes.push(`${rootClass}_active`)
      }
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.error || this.stateError) {
        classes.push(`${rootClass}_error`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      if (this.filled) {
        classes.push(`${rootClass}_filled`)
      }
      return classes
    }
  },
  methods: {
    onFocus() {
      this.active = true
      this.$emit('focus')
    },
    onBlur() {
      this.active = false
      this.$emit('blur')
    },
    onKeyPress(e) {
      this.$emit('keyPress', e)
    }
  }
}
