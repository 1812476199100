<template>
  <div class="LLFilteringSearch">
    <SearchIcon class="LLFilteringSearch__icon" />
    <input
      :value="searchValue"
      :placeholder="_placeholder"
      class="LLFilteringSearch__input"
      type="text"
      @input="onInput"
    />
    <button v-if="showRemoveButton" class="LLFilteringSearch__remove" @click="clearSearchValue">
      <CloseIcon class="LLFilteringSearch__remove-icon" />
    </button>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/SearchIcon'
import CloseIcon from '@/assets/icons/CloseIcon'
export default {
  name: 'LLFilteringSearch',
  components: { CloseIcon, SearchIcon },
  props: {
    placeholder: { type: String, default: 'Search' },
    value: { type: String, default: '' }
  },
  data() {
    return {
      searchValue: ''
    }
  },
  computed: {
    _placeholder() {
      return this.placeholder || this.$t('search-bar_placeholder')
    },
    showRemoveButton() {
      return this.searchValue.length
    }
  },
  watch: {
    value: {
      handler(to) {
        this.searchValue = to
      },
      immediate: true
    },
    searchValue: {
      handler(to) {
        this.$emit('input', to)
      }
    }
  },
  methods: {
    onInput(e) {
      this.searchValue = e.target.value
    },
    clearSearchValue() {
      this.searchValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFilteringSearch {
  @apply flex items-center border-b border-neutral-01-50 py-2 px-3;
  &__icon {
    @apply h-4 flex-shrink-0 text-accent-01-400;
  }

  &__input {
    @apply h-4 flex-1 ml-2.5 outline-none text-body-03 text-primary-01 min-w-0;
    &::placeholder {
      @apply text-neutral-01-400;
    }
  }
  &__remove {
    @apply flex-shrink-0 ml-2.5 outline-none text-neutral-01-400;
    &:focus {
      @apply text-neutral-01-300;
    }
    &:hover {
      @apply text-neutral-01-300;
    }
    &:active {
      @apply text-neutral-01-500;
    }
  }
  &__remove-icon {
    @apply h-3 w-3;
  }
}
</style>
