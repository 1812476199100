<template>
  <div class="LLFilter">
    <div class="LLFilter__filter-list"></div>
    <div class="LLFilter__header">
      <div class="LLFilter__title">{{ $t('search-page_filter_title') }}</div>
      <LLButton class="LLFilter__clear-button" secondary small :disabled="cleared" @click="clear">
        {{ $t('search-page_filter_clear-button') }}</LLButton
      >
    </div>
    <!--    <LLButton class="LLFilter__apply-button" @click="apply">Apply</LLButton>-->
    <slot></slot>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
export default {
  name: 'LLFilter',
  components: { LLButton },
  props: {
    cleared: { type: Boolean, default: false }
  },
  methods: {
    apply() {
      this.$emit('apply')
    },
    clear() {
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFilter {
  &__header {
    @apply flex justify-between items-center;
  }
  &__title {
    @apply text-subheading-01;
  }
  &__clear-button {
  }
  &__apply-button {
    @apply mt-4 w-full;
  }
}
</style>
