<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" class="fill-current" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 11H6V9H14V11Z" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'AddCircleFalseIcon'
}
</script>
