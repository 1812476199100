<template>
  <div class="text-borders">
    <span class="text-borders__text">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TextBorders'
}
</script>

<style lang="scss" scoped>
.text-borders {
  position: relative;
  overflow: hidden;
  text-align: center;
  line-height: 1;
  &__text {
    position: relative;
    display: inline-block;
    @apply font-semibold;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      border-bottom: 1px solid currentColor;
      width: 50vw;
    }
    &::before {
      right: 100%;
      margin-right: 10px;
    }
    &::after {
      left: 100%;
      margin-left: 10px;
    }
  }
}
</style>
