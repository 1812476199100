<template>
  <div class="PollMultiple">
    <PollMultipleEditor
      v-if="(!poll.isResponded && !showResults) || editMode"
      :response.sync="localResponse"
      :poll="poll"
      @update:isValid="onIsValidUpdate"
    ></PollMultipleEditor>
    <PollMultipleResults v-else :thread="thread" :poll="poll" />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollType } from '@/common/enums'
import PollMultipleEditor from '@/components/modules/poll/multiple/PollMultipleEditor.vue'
import { PollResponseModel } from '@/models/pollResponse'
import PollMultipleResults from '@/components/modules/forum-chat/poll/multiple/PollMultipleResults.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollMultiple',
  components: { PollMultipleResults, PollMultipleEditor },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    showResults: { type: Boolean, default: true },
    isValid: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
      },
      deep: true
    }
  },
  methods: {
    onIsValidUpdate(isValid) {
      this.$emit('update:isValid', isValid)
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultiple {
}
</style>
