<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4336 11.565L8 9.13137L5.56641 11.565L4.43504 10.4336L6.86863 8L4.43359 5.56496L5.56496 4.43359L8 6.86863L10.435 4.43359L11.5664 5.56496L9.13137 8L11.565 10.4336L10.4336 11.565Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClearSolidIcon'
}
</script>
