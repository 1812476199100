<template>
  <div class="PollResultsTable">
    <LLTable
      :selection-enabled="false"
      :is-loading="initLoading"
      :pagination-loading="paginationLoading"
      :pagination-enabled="!paginationFinished"
      :rows="rows"
      :total-rows="totalRecords"
      :columns="columns"
      :max-height="600"
      :fixed-header="true"
      class="UserManagementTable__table"
      @paginationEvent="loadMembers"
      @sortEvent="sortEvent"
    >
      <template #table-column="props">
        <PollResultsTableColumn :poll="poll" :column-props="props" />
      </template>
      <template #table-row="props">
        <PollResultsTableRow
          :thread="thread"
          :poll="poll"
          :row-props="props"
          @updatePollResponse="reloadMembers"
      /></template>
      <template #emptystate>
        <TableEmptyState>{{ $tc('common_amount_members', 0) }}</TableEmptyState>
      </template>
    </LLTable>
  </div>
</template>

<script>
import LLTable from '@/components/common/table/LLTable.vue'
import TableEmptyState from '@/components/common/table/TableEmptyState.vue'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import PollResultsTableRow from '@/components/modules/forum-chat/poll/results/PollResultsTableRow.vue'
import { PollNumberStyle, PollType } from '@/common/enums'
import PollResultsTableColumn from '@/components/modules/forum-chat/poll/results/PollResultsTableColumn.vue'
import { pollResponseParser } from '@/utils/poll'
import { ForumPollModel } from '@/models/forum/forumPoll'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollResultsTable',
  components: { PollResultsTableColumn, PollResultsTableRow, TableEmptyState, LLTable },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: ForumPollModel, required: true },
    pollResponses: { type: Array, default: null }
  },
  data() {
    return {
      sort: '',
      loading: false,
      page: 1,
      totalRecords: null,
      currentRequest: null
    }
  },
  computed: {
    sortedPollRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    members() {
      return [
        {
          prospect: this.$store.getters['user/userObject'],
          pollResponse: this.pollResponseParser(this.pollResponses)
        }
      ]
    },
    rows() {
      return this.members.map((member) => ({
        key: member?.prospect?.id || member?.user?.id,
        disabled: false,
        data: member
      }))
    },
    columns() {
      const columns = [
        {
          label: this.$t('recruitment_table_col_name'),
          field: 'name'
        },
        ...(this.poll?.type === PollType.NUMBER_ENTRY
          ? [
              {
                label: (() => {
                  switch (this.poll.numberStyle) {
                    case PollNumberStyle.STARS:
                      return this.$t('forum.poll.results.table.columns.stars')
                    case PollNumberStyle.SLIDER:
                      return this.$t('forum.poll.results.table.columns.scale')
                    case PollNumberStyle.NUMBER_INPUT:
                      return this.$t('forum.poll.results.table.columns.value')
                  }
                })(),
                field: 'numberValue'
              }
            ]
          : []),
        ...(this.poll?.type === PollType.RANKED_LIST
          ? [
              {
                label: this.$t('forum.poll.results.table.column_ranked'),
                field: 'ranked'
              }
            ]
          : []),
        ...(this.poll?.type === PollType.MATRIX
          ? [
              {
                label: this.$t('forum.poll.results.table.column_matrix'),
                field: 'matrix'
              }
            ]
          : []),
        ...(this.poll?.type === PollType.MULTIPLE_CHOICE
          ? [
              ...this.sortedPollRows.map((row, index) => ({
                field: `multiple:${index}`,
                multipleRowId: row.id,
                multipleRowIndex: index
              }))
            ]
          : []),
        ...(this.thread?.isActive
          ? [
              {
                label: '',
                field: 'actions'
              }
            ]
          : [])
      ]
      return columns
    },
    initLoading() {
      return this.loading && this.page === 1
    },
    paginationLoading() {
      return this.loading && this.page > 1
    },
    paginationFinished() {
      return this.members?.length >= this.totalRecords
    }
  },
  mounted() {
    /*this.resetPagination()
    this.loadMembers()*/
  },
  methods: {
    resetPagination() {
      this.page = 1
    },
    sortEvent({ field, type }) {
      this.sort = `${type === 'desc' ? '-' : '+'}${field}`
      /*this.resetPagination()
      this.loadMembers()*/
    },
    pollResponseParser(serverPollResponses) {
      return pollResponseParser(serverPollResponses, this.poll.type)
    },
    loadMembers() {
      this.loading = true
      const currentMembersRequest = this.$api.forum.poll
        .getMembersResults({
          perPage: 20,
          sort: this.sort || undefined,
          page: this.page,
          chatId: this.poll.chatId,
          threadId: this.poll.threadId
        })
        .then((membersData) => {
          if (this.currentRequest === currentMembersRequest) {
            if (this.page === 1) {
              this.members = []
            }
            this.members = [
              ...this.members,
              ...membersData.members.map((member) => {
                const parsedMember = {
                  prospect: member.accountId
                    ? ProspectModel.parseFromApi({ prospect: { ...member, id: member.accountId } })
                    : undefined,
                  user: member.userId
                    ? UserModel.parseFromApi({ user: { ...member, id: member.userId } })
                    : undefined,
                  location: member.location,
                  pollResponse: this.pollResponseParser(member.pollResponses)
                }
                return parsedMember
              })
            ]
            this.totalRecords = membersData.totalRecords
            this.page++
          }
        })
        .catch((e) => {
          console.log(e)
          if (this.page === 1) {
            this.users = []
            this.totalRecords = 0
          }
        })
        .finally(() => {
          if (this.currentRequest === currentMembersRequest) {
            this.loading = false
          }
        })
      this.currentRequest = currentMembersRequest
    },
    reloadMembers() {
      this.$emit('updatePollResponse')
      /*this.resetPagination()
      this.loadMembers()*/
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsTable {
}
</style>
