<template>
  <LLTimestamp class="ForumResponseTimestamp" :timestamp="response.createdAt">
    <template #default="data">
      {{ $tc('timestamp_replied', data.time) }}
    </template>
  </LLTimestamp>
</template>

<script>
import LLTimestamp from '@/components/common/LLTimestamp.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseTimestamp',
  components: { LLTimestamp },
  props: {
    response: { type: ForumResponseModel, default: null }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseTimestamp {
  @apply text-xs;
}
</style>
