<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.1731 3.12758V2.01758H3.0451V6.26558H1.8091V3.12758H1.1731ZM15.1136 3.00781H6.48337C5.98632 3.00781 5.58337 3.41076 5.58337 3.90781C5.58337 4.40487 5.98632 4.80781 6.48337 4.80781H15.1136C15.6107 4.80781 16.0136 4.40487 16.0136 3.90781C16.0136 3.41076 15.6107 3.00781 15.1136 3.00781ZM15.1136 11.5391H6.48337C5.98632 11.5391 5.58337 11.942 5.58337 12.4391C5.58337 12.9361 5.98632 13.3391 6.48337 13.3391H15.1136C15.6107 13.3391 16.0136 12.9361 16.0136 12.4391C16.0136 11.942 15.6107 11.5391 15.1136 11.5391ZM6.48337 6.26562H11.0503C11.5473 6.26562 11.9503 6.66857 11.9503 7.16563C11.9503 7.66268 11.5473 8.06563 11.0503 8.06563H6.48337C5.98632 8.06563 5.58337 7.66268 5.58337 7.16563C5.58337 6.66857 5.98632 6.26562 6.48337 6.26562ZM2.10711 12.6458C1.77911 12.9818 1.34111 13.3518 0.793109 13.7558V14.6378H3.85911V13.6838H2.33511C2.74711 13.4278 3.09311 13.1178 3.37311 12.7538C3.65711 12.3898 3.79911 12.0098 3.79911 11.6138C3.79911 11.2458 3.68311 10.9418 3.45111 10.7018C3.21911 10.4618 2.86111 10.3418 2.37711 10.3418C1.93311 10.3418 1.56311 10.4738 1.26711 10.7378C0.971109 10.9978 0.811109 11.3938 0.787109 11.9258H1.93311C1.93711 11.7138 1.96511 11.5618 2.01711 11.4698C2.07311 11.3778 2.16111 11.3318 2.28111 11.3318C2.49311 11.3318 2.59911 11.4638 2.59911 11.7278C2.59911 11.9998 2.43511 12.3058 2.10711 12.6458Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderedList'
}
</script>
