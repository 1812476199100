<template>
  <svg
    class="icon fill-current"
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1 5.0002L1.4 9.7002L3.72843e-07 8.3002L3.3 5.0002L2.13053e-06 1.7002L1.4 0.300196L6.1 5.0002Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowMini'
}
</script>
