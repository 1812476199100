<template>
  <div class="ScreeningQuestion StudyNewPageScreeningQuestion">
    <div class="w-full flex justify-center p-6 mob:p-0 border border-neutral-01-50 left-border rounded">
      <div class="Question w-full bg-white mob:p-8 mob-only:p-4">
        <div class="flex">
          <div
            class="add-section__add-icon w-8 h-8 rounded-full bg-neutral-01-25 flex justify-center items-center mr-4"
          >
            {{ index + 1 }}
          </div>
          <QuestionValue :question="question"></QuestionValue>
        </div>
        <StudyConversationMessage
          class="StudyNewPageScreeningQuestion__message"
          :stream-parameters="streamParameters"
          :options="messageOptions"
          :message="message"
          :send-using-store="false"
          @updateTempMessage="onUpdateTempMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { QuestionModel } from '@/models/question'
import StudyConversationMessage from '@/components/modules/study/StudyConversationMessage.vue'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import {
  StudyConversationMessageFileSize,
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import QuestionValue from '@/pages/Conversation/Question/QuestionValue.vue'

export default {
  name: 'StudyNewPageScreeningQuestion',
  components: { QuestionValue, StudyConversationMessage },
  props: {
    message: { type: StudyConversationMessageModel, default: null },
    question: { type: QuestionModel, required: true },
    index: { type: Number, required: true }
  },
  computed: {
    streamParameters() {
      return new StudyConversationMessageStreamParametersModel({
        question: this.question,
        prospect: this.$store.getters['user/userObject'],
        studyId: this.question.studyId
      })
    },
    messageOptions() {
      return new StudyConversationMessageOptionsModel({
        type: StudyConversationMessageType.SCREENING,
        allowToEdit: true,
        fileSliderSize: StudyConversationMessageFileSize.SMALL,
        allowToRemove: true,
        elementsVisibility: {
          header: false,
          actions: false
        }
      })
    }
  },
  methods: {
    onUpdateTempMessage({ message }) {
      this.$emit('updateTempMessage', { message })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageScreeningQuestion {
  .Question {
    @screen mob {
      @apply p-8;
    }
    @screen mob-only {
      @apply p-4;
    }
  }
  .left-border {
    border-left-color: var(--color-neutral-01-400) !important;
    border-left-width: 3px !important;
  }
  &__message {
    @apply mt-4;
  }
  .add-section__add-icon {
    min-width: 2rem;
  }
}
</style>
