<template>
  <div class="PrivacyPolicyOtherLanguages">
    <p>{{ $t('privacy_policy_protecting') }}</p>
    <p>{{ $t('privacy_policy_governing_of_using') }}</p>
    <p class="font-bold">{{ $t('privacy_policy_notice').toUpperCase() }}</p>
    <h3>{{ $t('privacy_policy_collection') }}</h3>
    <p>{{ $t('privacy_policy_personal_info') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_list_first') }}</li>
      <li>{{ $t('privacy_policy_list_second') }}</li>
      <li>{{ $t('privacy_policy_list_third') }}</li>
    </ul>
    <p>{{ $t('privacy_policy_purpose_title') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_purpose_first') }}</li>
      <li>{{ $t('privacy_policy_purpose_second') }}</li>
      <li>{{ $t('privacy_policy_purpose_third') }}</li>
      <li>{{ $t('privacy_policy_purpose_forth') }}</li>
      <li>{{ $t('privacy_policy_purpose_fifth') }}</li>
      <li>{{ $t('privacy_policy_purpose_six') }}</li>
    </ul>
    <h3>{{ $t('privacy_policy_legal_base') }}</h3>
    <p>{{ $t('privacy_policy_legal_base_desc') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_legal_base_first') }}</li>
      <li>{{ $t('privacy_policy_legal_base_second') }}</li>
    </ul>
    <h3>{{ $t('privacy_policy_cookies') }}</h3>
    <p>{{ $t('privacy_policy_cookies_desc') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_cookies_first') }}</li>
      <li>{{ $t('privacy_policy_cookies_second') }}</li>
      <li>
        {{ $t('privacy_policy_cookies_third') }}
        <ul>
          <li>
            <b>{{ $t('privacy_policy_cookies_google_analytics_title') }}.</b>
            {{ $t('privacy_policy_cookies_google_analytics_description') }}
          </li>
        </ul>
      </li>
    </ul>
    <p>{{ $t('privacy_policy_cookies_manage') }}</p>
    <h3>{{ $t('privacy_policy_sharing_personal_information_title') }}</h3>
    <p>{{ $t('privacy_policy_sharing_personal_information_description') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_sharing_personal_information_list_1') }}</li>
      <li>{{ $t('privacy_policy_sharing_personal_information_list_2') }}</li>
      <li>{{ $t('privacy_policy_sharing_personal_information_list_3') }}</li>
      <li>{{ $t('privacy_policy_sharing_personal_information_list_4') }}</li>
    </ul>
    <h3>{{ $t('privacy_policy_children_information_collection_title') }}</h3>
    <p>{{ $t('privacy_policy_children_information_collection_description') }}</p>

    <h3>{{ $t('privacy_policy_opt_out_title') }}</h3>
    <p>{{ $t('privacy_policy_opt_out_description_1') }}</p>
    <p>{{ $t('privacy_policy_opt_out_description_2') }}</p>
    <p>{{ $t('privacy_policy_opt_out_description_3') }}</p>

    <h3>{{ $t('privacy_policy_your_rights_title') }}</h3>
    <p>{{ $t('privacy_policy_your_rights_description') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_your_rights_list_1') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_2') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_3') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_4') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_5') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_6') }}</li>
      <li>{{ $t('privacy_policy_your_rights_list_7') }}</li>
    </ul>
    <h3>{{ $t('privacy_policy_third_party_links_title') }}</h3>
    <p>{{ $t('privacy_policy_third_party_links_description') }}</p>
    <h3>{{ $t('privacy_policy_personal_information_security_title') }}</h3>
    <p>{{ $t('privacy_policy_personal_information_security_description') }}</p>
    <h3>{{ $t('privacy_policy_data_retention_title') }}</h3>
    <p>{{ $t('privacy_policy_data_retention_description') }}</p>
    <h3>{{ $t('privacy_policy_international_data_transfers_title') }}</h3>
    <p>{{ $t('privacy_policy_international_data_transfers_description') }}</p>
    <ul>
      <li>{{ $t('privacy_policy_international_data_transfers_list_1') }}</li>
      <li>{{ $t('privacy_policy_international_data_transfers_list_2') }}</li>
    </ul>
    <h3>{{ $t('privacy_policy_assignment_title') }}</h3>
    <p>{{ $t('privacy_policy_assignment_description') }}</p>
    <h3>{{ $t('privacy_policy_dispute_resolution_title') }}</h3>
    <p>{{ $t('privacy_policy_dispute_resolution_description_1') }}</p>
    <p>
      {{ $t('privacy_policy_dispute_resolution_address_1') }}<br />
      {{ $t('privacy_policy_dispute_resolution_address_2') }}<br />
      {{ $t('privacy_policy_dispute_resolution_address_3') }}
    </p>
    <p>{{ $t('privacy_policy_dispute_resolution_description_2') }}</p>
    <p>{{ $t('privacy_policy_dispute_resolution_description_3') }}</p>

    <h3>{{ $t('privacy_policy_choice_of_law_title') }}</h3>
    <p>{{ $t('privacy_policy_choice_of_law_description') }}</p>

    <h3>{{ $t('privacy_policy_california_rights_title') }}</h3>
    <p>{{ $t('privacy_policy_california_rights_description_1') }}</p>
    <p>{{ $t('privacy_policy_california_rights_description_2') }}</p>
    <h3>{{ $t('privacy_policy_contact_information_title') }}</h3>
    <p>{{ $t('privacy_policy_contact_information_description') }}</p>
    <p>
      {{ $t('privacy_policy_contact_information_address_1') }}<br />
      {{ $t('privacy_policy_contact_information_address_2') }}<br />
      {{ $t('privacy_policy_contact_information_address_3') }}<br />
      {{ $t('privacy_policy_contact_information_address_4') }}
    </p>
    <p>
      {{ $t('privacy_policy_contact_information_email_title') }}<br />
      {{ $t('privacy_policy_contact_information_email') }}
    </p>
    <p>
      {{ $t('privacy_policy_contact_information_phone_title') }}<br />
      {{ $t('privacy_policy_contact_information_phone') }}
    </p>
    <h3>{{ $t('privacy_policy_changes_to_privacy_policy_title') }}</h3>
    <p>{{ $t('privacy_policy_changes_to_privacy_policy_description') }}</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyOtherLanguages'
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyOtherLanguages {
  @apply text-primary-01;
  max-width: 730px;
  padding-bottom: 60px;
  /deep/ {
    h2 {
      font-family: var(--font-main) !important;
      @apply mt-10 font-semibold text-2xl;
    }
    h3 {
      font-family: var(--font-main);
      @apply mt-8 text-base font-semibold;
    }
    p {
      font-family: var(--font-main);
      @apply my-3;
    }
    li {
      @apply ml-10;
    }
  }
}
</style>
