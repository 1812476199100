<template>
  <div v-if="!cookieAgreement" class="CookiesAgreement">
    <div class="flex justify-between flex-col items-end md:flex-row md:items-center">
      <div class="text-sm text-primary-01 CookiesAgreement__text">
        {{ $t('cookie_agreement_message') }}
      </div>
      <div class="CookiesAgreement__buttons mt-4 md:mt-0 flex-shrink-0 pl-8">
        <LLButton
          green
          class="CookiesAgreement__button"
          small
          data-e2e="cookiesBtn"
          @click.native="setCookieAgreementTrue"
        >
          {{ $t('cookie_agreement_accept_button') }}
        </LLButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LLButton from '@/components/common/ui-components/LLButton.vue'
export default {
  name: 'CookiesAgreement',
  components: { LLButton },
  computed: {
    ...mapGetters('ui', ['cookieAgreement'])
  },
  methods: {
    setCookieAgreementTrue() {
      this.$store.commit('ui/SET_COOKIE_AGREEMENT', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.CookiesAgreement {
  padding: 28px 35px;
  @apply bg-neutral-01-50;
  .CookiesAgreement__text {
    max-width: 840px;
  }
}
</style>
