<template>
  <div>
    <BarChart
      class="PollResultsChartBar"
      :width="450"
      :height="chartHeight > 288 ? chartHeight : 288"
      :chart-data="chartData"
      :options="options"
    />
  </div>
</template>

<script>
import BarChart from '@/components/common/BarChart.vue'

export default {
  name: 'PollResultsChartBar',
  components: { BarChart },
  props: {
    chartBarData: { type: Object, required: true },
    chartBarOptions: { type: Object, default: null },
    showLegend: { type: Boolean, default: false },
    max: { type: Number, default: null },
    min: { type: Number, default: null },
    tooltipLabelCallback: { type: Function, default: null },
    tooltipFilterCallback: { type: Function, default: null },
    dataLabelCallback: { type: Function, default: null },
    legendLabelCallback: { type: Function, default: null },
    dataLabelFilterCallback: { type: Function, default: () => true },
    dataLabelsAnchor: { type: String, default: 'end' },
    dataLabelsAlign: { type: String, default: 'start' },
    stacked: { type: Boolean, default: false },
    hideX: { type: Boolean, default: false }
  },
  computed: {
    chartHeight() {
      return this.chartData.labels?.length * 35 + 40
    },
    chartData() {
      return this.$_.merge({}, this.chartBarData)
    },
    options() {
      return this.$_.merge(
        {
          responsive: true,
          indexAxis: 'y',
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },

          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: this.showLegend,
              labels: {
                filter: this.legendLabelCallback || undefined
              }
            },
            datalabels: {
              color: '#FFFFFF',
              anchor: this.dataLabelsAnchor,
              align: this.dataLabelsAlign,
              display: (context) => {
                const value = context.dataset.data[context.dataIndex]
                const percentage = this.max > 0 ? Math.round((value / this.max) * 100) : 0
                return percentage > 3 && this.dataLabelFilterCallback(context)
              },
              clamp: true,
              font: {
                weight: 'bold'
              },
              formatter: this.dataLabelCallback || undefined
            },
            tooltip: {
              filter: this.tooltipFilterCallback || undefined,
              callbacks: {
                label: this.tooltipLabelCallback || undefined
              }
            }
          },
          scales: {
            gridLines: {
              display: false
            },
            x: {
              grid: {
                display: false
              },
              max: this?.max || undefined,
              min: this?.min || undefined,
              stacked: this.stacked ?? undefined,
              display: !this.hideX
            },
            y: {
              grid: {
                display: false
              },
              stacked: this.stacked ?? undefined,
              ticks: {
                callback(value) {
                  const label = this.getLabelForValue(value)
                  const numberOfBarChartLabelLinesLimit = 2
                  const chunkString = (str, maxWidth) => {
                    const sections = []
                    const words = str.split(' ')
                    let builder = ''

                    for (const word of words) {
                      if (word.length > maxWidth) {
                        sections.push(builder.trim())
                        builder = ''
                        sections.push(word.trim())
                        continue
                      }

                      const temp = `${builder} ${word}`
                      if (temp.length > maxWidth) {
                        sections.push(builder.trim())
                        builder = word
                        continue
                      }

                      builder = temp
                    }
                    sections.push(builder.trim())

                    return sections
                  }

                  let formattedLabel = label + '  '
                  if (label.length > 30) {
                    const wrappedLabel = chunkString(label, 25)
                    formattedLabel =
                      wrappedLabel.length > numberOfBarChartLabelLinesLimit
                        ? wrappedLabel.slice(0, numberOfBarChartLabelLinesLimit)
                        : wrappedLabel
                    for (let i = 0; i < formattedLabel.length; i++) {
                      if (i < formattedLabel.length - 1) formattedLabel[i] = formattedLabel[i] + '  '
                      else
                        formattedLabel[i] =
                          wrappedLabel.length > numberOfBarChartLabelLinesLimit
                            ? formattedLabel[i] + '...'
                            : formattedLabel[i] + '  '
                    }
                    // console.log('formatted', formattedLabel)
                  }
                  return formattedLabel
                }
              }
            }
          }
        },
        this.chartBarOptions
      )
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsChartBar {
}
</style>
