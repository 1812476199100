import { BaseModel } from '@/models/baseModel'

export class ForumReactionModel extends BaseModel {
  constructor(reactionData = {}) {
    super()
    const { emoji, count, isOwnReaction, members, isViewed } = reactionData || {}

    this.emoji = emoji
    this.count = count || 0
    this.isOwnReaction = isOwnReaction || false
    this.isViewed = isViewed ?? true
    this.members = Array.isArray(members) ? [...members] : []
  }

  static parseFromApi({ reaction }) {
    return new ForumReactionModel({
      emoji: reaction?.emoji,
      count: reaction?.count,
      isOwnReaction: reaction?.isOwnReaction,
      isViewed: reaction?.isViewed,
      members: Array.isArray(reaction?.members) ? [...reaction?.members] : []
    })
  }
}
