<template>
  <svg
    class="ChevronDownIcon"
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5997 9.30273L0.662205 2.42148C0.441892 2.20117 0.441892 1.84492 0.662205 1.62461L1.59033 0.696484C1.81064 0.476171 2.16689 0.476171 2.3872 0.696484L7.99814 6.25117L13.6091 0.696484C13.8294 0.476172 14.1856 0.476172 14.406 0.696484L15.3341 1.62461C15.5544 1.84492 15.5544 2.20117 15.3341 2.42148L8.39658 9.30274C8.17627 9.52305 7.82002 9.52305 7.5997 9.30273Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronDownIcon'
}
</script>

<style lang="scss" scoped>
.ChevronDownIcon {
  @apply fill-current;
}
</style>
