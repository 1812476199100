<template>
  <div class="ForumResponseBody">
    <div v-if="response.repliesCount && false" class="ForumResponseBody__thread-lines">
      <div class="ForumResponseBody__thread-line-vertical"></div>
      <div class="ForumResponseBody__thread-line-horisontal"></div>
    </div>
    <div class="ForumResponseBody__content">
      <div v-if="response.files.length && tailwindScreens.init" class="ForumResponseBody__files">
        <LLFileSlider :medium="tailwindScreens.lg" :files="response.files"></LLFileSlider>
      </div>
      <div class="ForumResponseBody__text">
        <LLWysiwygOutput :text="response.text" />
      </div>
      <ForumResponseReactions
        class="ForumResponseBody__reactions"
        :response="response"
        :disabled="!isReactionsEnabled"
        :reaction-emoji-list="reactionEmojiList"
        :disable-reactanimation="disableReactanimation"
        @updateReactions="onUpdateReactions"
        @updateReactionEmojiList="onUpdateReactionEmojiList"
      />
      <ForumResponseBodyFooter
        class="ForumResponseBody__footer"
        :response="response"
        :is-actions-hidden="!isEditable"
        :is-able-to-reply="isAbleToReply"
        :reply-level="replyLevel"
        @replyButtonClick="onReplyButtonClick"
        @reactionSelect="onReactionSelect"
        @edit="onEdit"
        @remove="onRemove"
      />
    </div>
  </div>
</template>

<script>
import { ForumResponseModel } from '@/models/forum/forumResponse'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
import LLFileSlider from '@/components/common/LLFileSlider'
import ForumResponseReactions from '@/components/pages/forum/response/ForumResponseReactions'
import ForumResponseBodyFooter from '@/components/pages/forum/response/body/ForumResponseBodyFooter.vue'
import { screenCatcher } from '@/mixins/screenCatcher'

export default {
  name: 'ForumResponseBody',
  components: {
    ForumResponseBodyFooter,
    ForumResponseReactions,
    LLFileSlider,
    LLWysiwygOutput
  },
  mixins: [screenCatcher],
  props: {
    response: { type: ForumResponseModel, required: true },
    reactionEmojiList: { type: Array, default: () => [] },
    isEditable: { type: Boolean, default: true },
    disableReactanimation: { type: Boolean, default: false },
    replyLevel: { type: Number, default: 0 },
    isAbleToReply: { type: Boolean, default: true }
  },
  computed: {
    isReactionsEnabled() {
      return this.isEditable
    }
  },
  methods: {
    onUpdateReactionEmojiList(reactionEmojiList) {
      this.$emit('updateReactionEmojiList', reactionEmojiList)
    },
    onReactionSelect(reaction) {
      this.$emit('reactionSelect', reaction)
    },
    onUpdateReactions({ reactions }) {
      this.$emit('updateReactions', { reactions })
    },
    onEdit() {
      this.$emit('edit')
    },
    onRemove() {
      this.$emit('remove')
    },
    onReplyButtonClick() {
      this.$emit('replyButtonClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumResponseBody {
  @apply relative;
  &__text {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
  }

  &__files {
    @apply mt-2;
  }

  &__reactions {
    @apply mt-2;
  }
  &__footer {
    @apply mt-1;
  }

  &__thread-lines {
    @apply absolute left-0 top-0 h-full;
  }

  &__thread-line-vertical {
    @apply bg-neutral-01-100;
    width: 2px;
    height: calc(100% + 6px);
    margin-left: -30px;
    margin-top: 16px;
  }
  &__thread-line-horisontal {
    @apply bg-neutral-01-100;
    width: 25px;
    height: 2px;
    margin-left: -30px;
    margin-top: 0px;
  }
}
</style>
