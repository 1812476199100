import { render, staticRenderFns } from "./ForumResponseBody.vue?vue&type=template&id=4410dc20&scoped=true"
import script from "./ForumResponseBody.vue?vue&type=script&lang=js"
export * from "./ForumResponseBody.vue?vue&type=script&lang=js"
import style0 from "./ForumResponseBody.vue?vue&type=style&index=0&id=4410dc20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4410dc20",
  null
  
)

export default component.exports