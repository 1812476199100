<template>
  <div class="TableEmptyState">
    <div class="TableEmptyState__inner">
      <NoDataIcon />
      <div class="TableEmptyState__label">
        <slot>{{ $t('table_selected_empty') }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import NoDataIcon from '@/assets/icons/NoDataIcon'
export default {
  name: 'TableEmptyState',
  components: { NoDataIcon }
}
</script>

<style lang="scss" scoped>
.TableEmptyState {
  @apply h-full bg-white flex items-center justify-center py-18 px-4;
  &__inner {
    @apply flex flex-col items-center;
  }
  &__label {
    @apply text-body-01 text-neutral-01-600 mt-6;
  }
}
</style>
