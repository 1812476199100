<template>
  <div class="LLFileModalVideo flex w-full items-stretch">
    <div class="LLFileModalVideo__video-wrapper">
      <video
        v-if="src"
        playsinline
        draggable="false"
        :src="src"
        :poster="poster"
        controls
        class="w-full h-full object-contain select-none LLFileModalVideo__video"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLFileModalVideo',
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    poster() {
      return this.file?.thumbnails?.find((t) => t.size === 250)?.location
    },
    isTemporary() {
      return !!this.file?.temporary || !!this.file?.isTemporary
    },
    tempSrc() {
      return URL.createObjectURL(this.file.temporaryData.browserFile)
    },
    src() {
      return `${this.isTemporary ? this.tempSrc : this.file.location}`
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.LLFileModalVideo {
  $root: &;
  user-select: text;

  .LLFileModalVideo__video-wrapper {
    width: 100%;
  }

  .LLFileModalVideo__video {
    height: 360px;
    background-color: #000000;
  }

  &.LLFileModalVideo_with-transcribe {
    @screen mob {
      @apply flex-col;
    }
    #{$root}__video-wrapper {
      width: 60%;
      max-width: 640px;
      flex-shrink: 0;
    }

    #{$root}__transcribes-container {
      height: 360px;

      /deep/ .highlight-menu-outer {
        position: static !important;

        .HighlightMenu {
          left: 0;
          top: 0;
        }
      }

      #{$root}__transcribe-wrapper {
        &:first-child {
        }

        &:nth-child(2) {
          @apply mt-6;
        }
      }

      @apply flex flex-col;
      #{$root}__transcribe {
        @apply overflow-y-auto;
        word-break: break-word;
        position: relative;
        @apply border border-neutral-01-50 rounded w-full px-4 py-3 flex-1 flex-shrink-0;
        &:first-child {
        }

        &:nth-child(2) {
          @apply mt-6;
        }
      }
    }

    #{$root}__transcribe-save-button {
      top: 100%;
      @apply mt-4;
    }
  }
}
</style>
