<template>
  <div class="DashboardChats" :class="{ DashboardChats_inactive: isLoading && fetchedChatList.length }">
    <div v-if="isLoading && !fetchedChatList.length" class="DashboardChats__loading">
      <LLLoader>
        <template #default>{{ $t('dashboard.loader') }}</template>
      </LLLoader>
    </div>
    <div v-else-if="error" class="DashboardChats__error">
      <LLLoaderError @retry="fetch">{{ $t(error) }}</LLLoaderError>
    </div>
    <div v-else-if="!fetchedChatList.length" class="DashboardChats__empty">
      {{ $tc('common_amount_chats', 0) }}
    </div>
    <div v-else class="DashboardChats__content">
      <div v-for="chat in fetchedChatList" :key="chat.id" class="DashboardChats__conversation">
        <ChatCard class="DashboardChats__chat-card" :chat="chat" />
      </div>
    </div>
    <div
      v-if="fetchedChatList.length && !error && total > perPage"
      class="DashboardChats__pagination-container"
    >
      <LLPagination
        :disabled="isLoading"
        :per-page="perPage"
        :total="total"
        :current="page"
        @goToPage="changePage"
      />
    </div>
  </div>
</template>

<script>
import LLLoader from '@/components/common/LLLoader'
import LLLoaderError from '@/components/common/LLLoaderError'
import LLPagination from '@/components/common/LLPagination'
import { ForumChatModel } from '@/models/forum/forumChat'
import ChatCard from '@/components/modules/chat/ChatCard'
export default {
  name: 'DashboardChats',
  components: { ChatCard, LLPagination, LLLoaderError, LLLoader },
  data() {
    return {
      isLoading: false,
      error: null,
      fetchedChatList: [],
      page: 1,
      total: 0,
      perPage: 10
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    changePage(page) {
      this.page = page
      this.fetch()
    },
    async fetch() {
      this.error = null
      this.isLoading = true
      try {
        const { chats, totalRecords } = await this.$api.forum.chats.fetch({
          page: this.page,
          perPage: this.perPage
        })
        this.fetchedChatList = []
        this.total = 0
        this.$nextTick(() => {
          this.fetchedChatList = chats.map((chat) => ForumChatModel.parseFromApi({ chat }))
          this.total = totalRecords
        })
      } catch (e) {
        console.log(e)
        this.error = this.$getErrorMessage(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardChats {
  &_inactive {
    @apply opacity-60 pointer-events-none select-none;
  }
  &__loading {
    @apply py-20;
  }
  &__error {
    @apply py-20;
  }
  &__empty {
    @apply text-body-02 text-neutral-01-400 text-center py-20;
  }
  &__pagination-container {
    @apply mt-6 flex justify-center;
  }
  &__content {
    @apply flex flex-wrap;
  }
  &__conversation {
    @apply flex sm:w-1/2 mob:w-full mt-6 mob:mt-5;

    &:nth-child(2n) {
      @screen sm {
        padding-left: 0.75rem;
      }
    }

    &:nth-child(2n + 1) {
      @screen sm {
        padding-right: 0.75rem;
      }
    }
  }
  &__chat-card {
    @apply w-full;
  }
}
</style>
