<template>
  <div class="LLFilterLabels">
    <LLTags
      :with-input="false"
      :with-remove="withRemove"
      :overflow-hidden="overflowHidden"
      :overflow-lines="overflowLines"
      :key-fn="(tag) => tag.label + Math.random()"
      :values="labels"
      @remove="onRemove"
    >
      <template #tag-value="{ value }">
        {{ value.label }}
      </template>
    </LLTags>
  </div>
</template>

<script>
import LLTags from '@/components/common/ui-components/LLTags.vue'
export default {
  name: 'LLFilterLabels',
  components: { LLTags },
  props: {
    labels: { type: Array, default: () => [] },
    overflowHidden: { type: Boolean, default: false },
    overflowLines: { type: Number, default: 1 },
    withRemove: { type: Boolean, default: true }
  },
  methods: {
    onRemove(tag) {
      console.log(tag.clear)
      if (tag?.clear) {
        tag.clear()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFilterLabels {
  /*@apply flex flex-wrap -mt-2;
  &__tag-wrapper {
    max-width: 100%;
    @apply flex-shrink-0 mr-2 mt-2;
  }
  &__tag {
  }*/
}
</style>
