<template>
  <div class="StudyNewPageQuestions">
    <LLLoader v-if="questionFetcher.isFetching" class="StudyNewPageQuestions__loader">
      {{ $t('please_wait') }}
    </LLLoader>
    <LLLoaderError v-else-if="questionFetcher.fetchError" @retry="questionFetcher.fetchQuestions()">
      {{ errorMessage }}
    </LLLoaderError>
    <LLEmptyState v-else-if="!questions.length">
      {{ $t('study_conversation.topic.questions.empty') }}
    </LLEmptyState>
    <div v-else class="StudyNewPageQuestions__questions-wrapper">
      <StudyNewPageQuestion
        v-for="(question, index) in orderedQuestions"
        :key="question.id"
        :conversation-stream="conversationStreamsObject[question.id]"
        :study="study"
        :question="question"
        :index="index"
        @updateMessage="onUpdateMessage"
      />
    </div>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import { TopicModel } from '@/models/topic'
import StudyNewPageBodyUtils from '@/components/pages/study-new/page-body/StudyNewPageBodyUtils.js'
import LLLoader from '@/components/common/LLLoader.vue'
import LLEmptyState from '@/components/common/ui-components/LLEmptyState.vue'
import StudyNewPageQuestion from '@/components/pages/study-new/StudyNewPageQuestion.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'

export default {
  name: 'StudyNewPageQuestions',
  components: { LLLoaderError, StudyNewPageQuestion, LLEmptyState, LLLoader },
  props: {
    study: { type: StudyModel, required: true },
    topic: { type: TopicModel, required: true },
    conversationStreams: { type: Array, required: true, default: () => [] },
    questionFetcher: { type: StudyNewPageBodyUtils, required: true }
  },
  computed: {
    questions() {
      return this.questionFetcher.questions
    },
    errorMessage() {
      return this.questionFetcher.fetchError ? this.$getErrorMessage(this.questionFetcher.fetchError) : null
    },
    orderedQuestions() {
      return [...this.questions].sort((a, b) => a?.order - b?.order)
    },
    conversationStreamsObject() {
      return this.conversationStreams.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    hasDifferentQuestionInTopic() {
      return (
        this.topic.questionIdList.length !== this.orderedQuestions.length &&
        !this.questionFetcher.isFetching &&
        !this.questionFetcher.isFetchingPart
      )
    }
  },
  watch: {
    hasDifferentQuestionInTopic: {
      handler(to) {
        if (to) {
          this.$emit('fetchTopicsMeta')
        }
      },
      immediate: true
    },
    questions: {
      handler() {
        this.$emit('updateQuestions', this.questions)
      },
      deep: true
    }
  },
  methods: {
    onUpdateMessage({ message }) {
      this.$emit('updateMessage', { message })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageQuestions {
  &__loader {
    @apply py-8;
  }
}
</style>
