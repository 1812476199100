<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.24212 10.9768C8.26753 10.8916 8.29294 10.8042 8.31504 10.7157C8.31835 10.7013 8.70284 9.21571 9.11384 7.66482C9.82094 4.99779 9.9933 4.46128 10.032 4.37942C10.1634 4.1073 10.3767 3.90155 10.6838 3.75C10.949 3.61947 11.2871 3.53319 11.6892 3.49336C11.8075 3.48119 11.919 3.41593 12.0008 3.30973C12.0748 3.2135 12.1179 3.09181 12.1179 2.97566C12.1179 2.7135 11.9047 2.5 11.6428 2.5H6.25671C5.99487 2.5 5.78163 2.7135 5.78163 2.97566V2.99226C5.78163 3.22677 5.94957 3.42367 6.18048 3.46128C6.48652 3.51106 6.90636 3.57965 7.23561 3.73562C7.40465 3.81527 7.53612 3.91261 7.62341 4.02323C7.72284 4.14823 7.77145 4.29314 7.77145 4.4646C7.77145 4.7854 7.67754 5.1073 7.58695 5.41814C7.56816 5.48341 7.54828 5.55199 7.52949 5.61836C7.41349 6.03319 7.24223 6.66261 7.04447 7.39159C6.58817 9.06969 6.02028 11.1571 5.87775 11.5542C5.78163 11.8219 5.42477 12.4558 4.19728 12.5498C4.07796 12.5586 3.96637 12.6128 3.88572 12.7002C3.80396 12.7887 3.75977 12.9027 3.75977 13.0232V13.0243C3.75977 13.2865 3.973 13.5 4.23485 13.5H9.62207C9.88392 13.5 10.0972 13.2865 10.0972 13.0243V13.021C10.0972 12.781 9.91817 12.5785 9.68063 12.5498C9.63422 12.5442 9.58892 12.5387 9.54031 12.5332C9.06744 12.479 8.47966 12.4104 8.23217 12.0885C8.11727 11.9392 8.08081 11.7423 8.11838 11.4889C8.14268 11.3175 8.1913 11.1515 8.24212 10.9768Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ItalicIcon'
}
</script>
