<template>
  <div class="LLSpoilerWrapper">
    <button class="LLSpoilerWrapper__toggler" :disabled="disabled" @click="clickEvent">
      <slot name="toggler" :expanded="localExpanded"></slot>
    </button>
    <slot v-if="localExpanded" name="content"></slot>
  </div>
</template>
<script>
/*
 * There 2 types of using expanded prop:
 * 1. Pass expanded.sync
 * 2. Get expanded from slot scope
 *
 */
export default {
  name: 'LLSpoilerWrapper',
  props: {
    expanded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      localExpanded: false
    }
  },
  watch: {
    expanded: {
      handler() {
        this.localExpanded = this.expanded
      },
      immediate: true
    },
    disabled: {
      handler(value) {
        if (value) {
          this.localExpanded = false
        }
      },
      immediate: true
    },
    localExpanded() {
      if (this.disabled) {
        this.localExpanded = false
        this.$emit('update:expanded', false)
        return
      }
      this.$emit('update:expanded', this.localExpanded)
    }
  },
  methods: {
    clickEvent() {
      this.localExpanded = !this.localExpanded
      this.$emit('expandByClick', this.localExpanded)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSpoilerWrapper {
  &__toggler {
    @apply cursor-pointer select-none block w-full;
    &:disabled {
      @apply cursor-default;
    }
    &:focus {
      @apply outline-none;
    }
    &:focus-visible {
      @apply outline-black;
    }
  }
}
</style>
