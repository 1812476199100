<template>
  <div class="UserEditModalInterestsList">
    <div class="UserEditModalInterestsList__title">{{ $t('dashboard_profile_editor_interests_title') }}</div>
    <div class="UserEditModalInterestsList__grid">
      <div v-for="(interest, key) in selectedInterests" :key="interest.id">
        <LLCheckbox
          v-model="selectedInterests[key].checked"
          class="UserEditModalInterestsList__checkbox"
          data-e2e="selectInterestsCheckbox"
        >
          {{ $t('dashboard_profile_editor_interests_interest_' + interest.name) }}
        </LLCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox'

export default {
  name: 'UserEditModalInterestsList',
  components: { LLCheckbox },
  props: {
    userInterestsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedInterests: []
    }
  },
  computed: {
    ...mapGetters('user', ['interests'])
  },
  watch: {
    selectedInterests: {
      handler(newValues) {
        let userInterestsList = [...this.userInterestsList]
        newValues.forEach((nv) => {
          let found = userInterestsList.find((uil) => uil === nv.id)
          if (found && !nv.checked) {
            userInterestsList.splice(userInterestsList.indexOf(found), 1)
          } else if (!found && nv.checked) {
            userInterestsList.push(nv.id)
          }
        })
        if (userInterestsList.length !== this.userInterestsList.length) {
          this.$emit('update:userInterestsList', userInterestsList)
          return
        }
        let theSame = true
        userInterestsList.forEach((uil) => {
          if (!this.userInterestsList.find((_uil) => _uil === uil)) {
            theSame = false
          }
        })
        this.userInterestsList.forEach((_uil) => {
          if (!userInterestsList.find((uil) => _uil === uil)) {
            theSame = false
          }
        })
        if (!theSame) {
          this.$emit('update:userInterestsList', userInterestsList)
        }
      },
      deep: true
    },
    interestsList: {
      handler() {
        this.recountCheckboxes()
      },
      immediate: true
    },
    interests: {
      handler() {
        this.recountCheckboxes()
      },
      immediate: true
    }
  },
  created() {
    this.interests.push()
  },
  mounted() {
    this.$store.dispatch('user/fetchInterests')
    if (this.interests.length === 0) {
    }
  },
  methods: {
    recountCheckboxes() {
      this.selectedInterests = this.interests.map((i) => ({
        id: i.id,
        name: i.name,
        checked: !!this.userInterestsList.find((il) => il === i.id)
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.UserEditModalInterestsList {
  &__title {
    @apply text-subheading-01 mb-4;
  }
  &__grid {
    @apply grid grid-flow-row grid-cols-3 gap-y-4 gap-x-2 mob-only:grid-cols-2;
  }
  &__checkbox {
    /deep/ .ll-checkbox__label {
      @apply w-auto;
    }
  }
}
</style>
