<template>
  <div class="StudyConversationMessageEditorText">
    <StudyConversationMessageEditorTextMobile
      v-if="isMobileLayout"
      :question="question"
      :message.sync="localMessage"
      :is-compact-layout="isCompactLayout"
      :is-mobile-layout="isMobileLayout"
      :is-send-pending="isSendPending"
      :is-able-to-cancel="isAbleToCancel"
      :is-valid="isValid"
      :options="options"
      @sendClick="onSendClick"
      @cancelClick="onCancelClick"
    />
    <StudyConversationMessageEditorTextDesktop
      v-else
      :question="question"
      :message.sync="localMessage"
      :is-compact-layout="isCompactLayout"
      :is-mobile-layout="isMobileLayout"
      :is-send-pending="isSendPending"
      :is-valid="isValid"
      :is-able-to-cancel="isAbleToCancel"
      :options="options"
      @sendClick="onSendClick"
      @cancelClick="onCancelClick"
    />
  </div>
</template>

<script>
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { QuestionModel } from '@/models/question'
import StudyConversationMessageEditorTextDesktop from '@/components/modules/study/study-conversation-message/editor/text/StudyConversationMessageEditorTextDesktop.vue'
import StudyConversationMessageEditorTextMobile from '@/components/modules/study/study-conversation-message/editor/text/StudyConversationMessageEditorTextMobile.vue'

export default {
  name: 'StudyConversationMessageEditorText',
  components: {
    StudyConversationMessageEditorTextDesktop,
    StudyConversationMessageEditorTextMobile
  },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true },
    question: { type: QuestionModel, required: true },
    isSendPending: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false },
    isAbleToCancel: { type: Boolean, default: false }
  },
  data() {
    return {
      localMessage: null
    }
  },
  computed: {
    isProbesVisible() {
      return this.options.type === StudyConversationMessageType.NEW_COMMENT
    }
  },
  watch: {
    message: {
      handler() {
        if (!this.$_.isEqual(this.message, this.localMessage)) {
          this.localMessage = new StudyConversationMessageModel(this.message)
        }
      },
      deep: true,
      immediate: true
    },
    localMessage: {
      handler() {
        this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
      },
      deep: true
    }
  },
  methods: {
    onSendClick() {
      this.$emit('sendClick')
    },
    onCancelClick() {
      this.$emit('cancelClick')
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageEditorText {
  &__probes {
    @apply mb-2;
  }
}
</style>
