<template>
  <div class="LLTagsInput">
    <div class="LLTagsInput__hidden">{{ currentValue }}</div>
    <div class="LLTagsInput__wrapper">
      <v-popover placement="bottom-start" trigger="manual" offset="5" :open="!!invalidError.length">
        <LLSuggestions :suggestions="suggestions" @select="selectSuggestion">
          <template #input>
            <input
              ref="input"
              v-model="currentValue"
              :placeholder="placeholder"
              type="text"
              class="LLTagsInput__input"
              :maxlength="maxLength"
              :class="{ LLTagsInput__input_invalid: invalid }"
              @keydown="keyDown"
              @keyup="keyUp"
              @focus="onFocus"
              @blur="onBlur"
            />
          </template>
          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </LLSuggestions>
        <template #popover>
          {{ invalidError }}
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
import LLSuggestions from '@/components/common/ui-components/LLInputSuggestionsOld'
export default {
  name: 'LLTagsInput',
  components: { LLSuggestions },
  props: {
    suggestions: { type: [Array, null], default: null },
    value: { type: String, default: null },
    validFn: { type: [Function, null], default: null },
    maxLength: { type: Number, default: null },
    placeholder: { type: String, default: null }
  },
  data() {
    return {
      currentValue: '',
      invalid: false,
      invalidError: '',
      backspaceKeyDown: false
    }
  },
  computed: {},
  watch: {
    currentValue() {
      this.$emit('input', this.currentValue)
      this.invalid = false
      this.invalidError = ''
    },
    value() {
      this.currentValue = this.value
    }
  },
  methods: {
    keyDown(e) {
      if (e.keyCode === 8 && this.currentValue) {
        this.backspaceKeyDown = true
      }
      if (e.keyCode === 8 && !this.currentValue && !this.backspaceKeyDown) {
        this.$emit('removeLastKeyDown')
      } else if (e.keyCode === 13 && this.currentValue) {
        if (this.validFn) {
          const { value, error } = this.validFn(this.currentValue)
          if (!value) {
            this.invalid = true
            this.invalidError = this.$t(error)
            return
          }
        }
        this.$emit('add', this.currentValue)
        this.currentValue = ''
      }
    },
    keyUp() {
      this.$emit('removeLastKeyUp')
      this.backspaceKeyDown = false
    },
    selectSuggestion(suggestion) {
      this.currentValue = ''
      this.$emit('selectSuggestion', suggestion)
      this.$refs.input.focus()
    },
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTagsInput {
  @apply relative h-6;
  max-width: 100%;
  &__hidden {
    @apply relative whitespace-nowrap text-body-02 text-transparent truncate;
    min-width: 2rem;
    max-width: 100%;
  }
  &__wrapper {
    @apply absolute left-0 top-0 w-full h-full;
  }
  &__input {
    @apply outline-none text-body-02 w-full h-full;
    &_invalid {
      @apply text-status-03-500;
    }
  }
}
</style>
