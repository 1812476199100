var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"ll-checkbox min-w-0 w-full rounded",class:[
      {
        'll-checkbox_locked': _vm.locked && !_vm.shouldBeChecked,
        white: _vm.white,
        'll-checkbox_checked': _vm.shouldBeChecked
      },
      `ll-checkbox_${_vm.styleClass}`
    ]},[_c('label',{staticClass:"ll-checkbox__label items-start"},[_c('div',{staticClass:"flex-shrink-0 mt-0.5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"ll-checkbox__input",attrs:{"type":"checkbox"},domProps:{"value":_vm.value,"checked":_vm.shouldBeChecked,"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,_vm.value)>-1:(_vm.innerValue)},on:{"change":[function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}},_vm.updateInput]}}),_c('span',{staticClass:"ll-checkbox__icon",class:{ 'is-checked': _vm.shouldBeChecked }},[_c('CheckedIcon')],1)]),_c('div',{staticClass:"ll-checkbox__text text-base text-primary-01"},[_vm._t("default")],2)]),(errors[0])?_c('div',{staticClass:"text-error-01 text-xs mt-2"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.validationError)?_c('div',{staticClass:"text-error-01 text-xs mt-2"},[_vm._v(" "+_vm._s(_vm.validationError)+" ")]):_vm._e()])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }