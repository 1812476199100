import { v4 as uuidV4 } from 'uuid'
import { BaseModel } from '@/models/baseModel'
import { FileModel } from '@/models/file'

export class PollChoiceModel extends BaseModel {
  constructor(choiceData = {}) {
    super()
    const { id, text, image, order, tempId, randomOrder, isOrderFixed, isExclusive } = choiceData || {}

    this.id = id || null
    this.text = text || ''
    this.image = image ? new FileModel(image) : null
    this.order = order || null
    this.randomOrder = randomOrder || null
    this.isOrderFixed = isOrderFixed || false
    this.tempId = tempId || uuidV4()
    this.isExclusive = isExclusive || false
  }

  static parseFromApi({ choice }) {
    return new PollChoiceModel({
      id: choice?.id,
      text: choice?.text,
      image: choice?.image ? new FileModel(choice?.image) : null,
      order: choice?.order,
      randomOrder: choice?.randomOrder,
      isOrderFixed: choice?.isOrderFixed,
      isExclusive: choice?.isExclusive
    })
  }

  convertToApi() {
    return {
      id: this.id || undefined,
      text: this.text,
      imageId: this.image?.id || null,
      order: this.order || undefined,
      isOrderFixed: this.isOrderFixed ?? undefined,
      isExclusive: this.isExclusive ?? undefined
    }
  }
}
