<template>
  <svg
    class="icon fill-current"
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="2" />
    <rect y="6" width="20" height="2" />
    <rect y="12" width="20" height="2" />
  </svg>
</template>

<script>
export default {
  name: 'BurgerIcon'
}
</script>
