<template>
  <div class="LLAvatarCropperWrapper">
    <div class="LLAvatarCropperWrapper__trigger" @click="onTriggerClick">
      <slot></slot>
    </div>
    <input
      ref="file-input"
      type="file"
      class="LLAvatarCropperWrapper__file-input"
      accept="image/png,image/jpeg,image/gif"
      @input="inputFile"
    />
    <LLAvatarCropper v-if="file" :file="file" @done="onDone" @cancel="onCancel"></LLAvatarCropper>
  </div>
</template>

<script>
import LLAvatarCropper from '@/components/common/LLAvatarCropper/LLAvatarCropper'
export default {
  name: 'LLAvatarCropperWrapper',
  components: { LLAvatarCropper },
  data() {
    return {
      file: null
    }
  },
  computed: {},
  watch: {
    file(to) {
      if (!to) {
        this.$refs['file-input'].value = ''
      }
    }
  },
  methods: {
    onTriggerClick() {
      this.$refs['file-input'].click()
    },
    inputFile() {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
      }
    },
    onCancel() {
      this.file = null
    },
    onDone({ file }) {
      this.$emit('upload', file)
      this.file = null
    }
  }
}
</script>

<style lang="scss" scoped>
.LLAvatarCropperWrapper {
  &__file-input {
    @apply hidden;
  }
}
</style>
