<template>
  <svg
    width="20"
    height="20"
    class="fill-current"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" />
    <path
      d="M5.375 10.375H14.75V7.875"
      stroke="white"
      stroke-width="1.6"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.875 12.875L5.375 10.375L7.875 7.875"
      stroke="white"
      stroke-width="1.6"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnsweredIcon'
}
</script>

<style lang="scss" scoped>
.Answered {
}
</style>
