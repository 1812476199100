<template>
  <div class="PollResultsPercentage">
    <div class="PollResultsPercentage__title">{{ $t('forum.poll.results.total_participants') }}</div>
    <div class="PollResultsPercentage__list">
      <div v-for="(item, index) in list" :key="item.id" class="PollResultsPercentageItem">
        <div class="PollResultsPercentageItem__index">{{ index + 1 }}</div>
        <div class="PollResultsPercentageItem__text">
          {{ item.text }}
        </div>
        <div class="PollResultsPercentageItem__value">{{ item.value }} %</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PollResultsPercentage',
  props: {
    list: { type: Array, required: true }
  }
}
</script>

<style scoped lang="scss">
.PollResultsPercentage {
  &__title {
    @apply text-primary-01-400 font-semibold text-xl uppercase;
  }
  &__list {
  }
  &Item {
    @apply flex mt-4;
    &__index {
      @apply flex justify-center items-center h-7 w-7 rounded-full text-sm font-bold flex-shrink-0 text-primary-01-400 border;
    }

    &__text {
      @apply text-primary-01 text-sm font-bold ml-3 my-auto break-words;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__value {
      @apply text-primary-01-400 font-semibold text-2xl ml-auto pl-3 flex-shrink-0;
    }
  }
}
</style>
