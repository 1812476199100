<template>
  <transition name="file-modal" duration="200" appear>
    <div
      v-if="selectedFile"
      class="LLFileModal modal flex items-start justify-center px-10"
      :class="{ 'LLFileModal_w-video': currentIsVideo }"
    >
      <div class="modal-content">
        <div class="flex items-stretch">
          <div class="w-20 flex-shrink-0 flex items-center justify-center">
            <CircleArrowLeftIcon
              class="w-6 h-6 text-primary-01-400 cursor-pointer"
              @click.native="prevFile"
            ></CircleArrowLeftIcon>
          </div>
          <div class="flex-1">
            <LLFileModalFile :file="selectedFile"></LLFileModalFile>
          </div>
          <div class="w-20 flex-shrink-0 flex items-center justify-center">
            <CircleArrowRightIcon
              class="w-6 h-6 text-primary-01-400 cursor-pointer"
              @click.native="nextFile"
            ></CircleArrowRightIcon>
          </div>
        </div>
        <div class="w-full flex justify-between h-20 items-center px-20">
          <div>
            <div v-if="files.length" class="font-semibold text-neutral-01 text-sm w-full text-center">
              <template v-if="currentIsImage">{{
                $t('image_counter', { current: currentIndex + 1, all: files.length })
              }}</template>
              <template v-if="currentIsVideo">{{
                $t('video_counter', { current: currentIndex + 1, all: files.length })
              }}</template>
            </div>
          </div>
        </div>
        <CloseIcon class="absolute LLFileModal__close cursor-pointer" @click.native="close"></CloseIcon>
      </div>
    </div>
  </transition>
</template>

<script>
import CircleArrowLeftIcon from '@/assets/icons/CircleArrowLeftIcon'
import LLFileModalFile from '@/components/common/fileGallery/files/LLFileModalFile'
import CircleArrowRightIcon from '@/assets/icons/CircleArrowRightIcon'
import CloseIcon from '@/assets/icons/CloseIcon'
export default {
  name: 'LLFileModal',
  components: { CloseIcon, CircleArrowRightIcon, LLFileModalFile, CircleArrowLeftIcon },
  props: {
    selectedFile: {
      type: [Object, null],
      default: null
    },
    files: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data: () => ({}),
  computed: {
    currentIndex() {
      return this.files.indexOf(this.selectedFile)
    },
    currentFileType() {
      return this.selectedFile.mimetype.substr(0, 4)
    },
    currentIsImage() {
      return this.currentFileType === 'imag'
    },
    currentIsVideo() {
      return this.currentFileType === 'vide'
    }
  },
  methods: {
    changeFile(index) {
      this.$emit('update:selectedFile', this.files[index])
    },
    close() {
      this.$emit('update:selectedFile', null)
    },
    nextFile() {
      if (this.files.length - 1 >= this.currentIndex + 1) {
        this.changeFile(this.currentIndex + 1)
      }
    },
    prevFile() {
      if (this.currentIndex !== 0) {
        this.changeFile(this.currentIndex - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileModal {
  &_w-video {
    .modal-content {
      max-width: 1286px;
    }
  }

  &.file-modal-enter-active,
  &.file-modal-leave-active {
    .modal-content {
      transition-property: transform, opacity;
      transition-duration: 0.2s;
    }
    &:before {
      transition: opacity 0.2s;
    }
  }
  &.file-modal-enter, &.file-modal-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    &:before {
      opacity: 0;
    }
  }
  &.file-modal-enter {
    .modal-content {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  &.file-modal-leave-to {
    .modal-content {
      transform: scale(1.1);
      opacity: 0;
    }
  }
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  .LLFileModal__close {
    position: absolute;
    right: 1.75rem;
    top: 1.5rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    @apply bg-primary-01;
    opacity: 0.3;
    content: '';
    top: 0;
    left: 0;
  }
}

.modal-carousel {
  /deep/ .slick-slider {
    position: relative !important;

    .slider-arrow {
      @apply absolute;
      top: 50%;
      @apply -mt-4;
      &.slider-arrow_next {
        right: 1.5rem;
      }

      &.slider-arrow_prev {
        left: 1.5rem;
      }
    }
  }

  /deep/ [aria-hidden='true'] {
    visibility: hidden;
  }
}

/* Modal Content (image) */
.modal-content {
  width: auto;
  max-width: 1000px;
  display: block;
  @apply relative bg-white pt-6 px-0;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}
/*

!* Add Animation *!
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
*/

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
