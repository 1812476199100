<template>
  <tr class="LLTable__data-row" :class="classes" data-e2e="tableRow">
    <td v-if="draggable" class="LLTable__data-row-handler" data-e2e="question-moving-handler">
      <DragIcon class="row-handler" />
    </td>
    <td v-if="expandable" class="LLTable__data-row-expander">
      <LLButton small tertiary @click="localExpanded = !localExpanded">
        <template #icon-left>
          <CircleArrowUpIcon
            :class="{ 'LLTable__data-row-expander-icon_expanded': localExpanded }"
            class="LLTable__data-row-expander-icon"
          ></CircleArrowUpIcon>
        </template>
      </LLButton>
    </td>
    <td v-if="selectionEnabled">
      <LLCheckbox
        :model-value="selected"
        without-margin
        data-e2e="rowCheckBox"
        class="LLTable__data-row-checkbox"
        @change="selectionMethod"
      ></LLCheckbox>
    </td>
    <td
      v-for="column in columns"
      :key="getRowColumnKey(row, column)"
      :style="columnStyle(column)"
      :data-e2e="column.field"
    >
      <slot
        name="table-row"
        :row="originalRow"
        :formatted-row="modifiedRow"
        :column="column"
        :value="rowColumnValue(row, column)"
        :expanded="expanded"
      >
        {{ rowColumnValue(row, column) }}
      </slot>
    </td>
  </tr>
</template>

<script>
import { v4 as uuidV4 } from 'uuid'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'
import DragIcon from '@/assets/icons/DragIcon'
import LLButton from '@/components/common/ui-components/LLButton'
import CircleArrowUpIcon from '@/assets/icons/CircleArrowUpIcon.vue'
export default {
  name: 'LLTableRow',
  components: { CircleArrowUpIcon, LLButton, DragIcon, LLCheckbox },
  props: {
    row: { type: Object, required: true },
    rowKey: { type: [Number, String], default: null },
    columns: { type: Array, default: () => [] },
    selectionEnabled: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    zebra: { type: Boolean, default: true },
    noPadding: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    expandable: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false }
  },
  data() {
    return {
      localSelected: false,
      localExpanded: false
    }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLTable__data-row'
      if (this.zebra) {
        classes.push(`${rootClass}_zebra`)
      }
      if (this.noPadding) {
        classes.push(`${rootClass}_no-padding`)
      }
      return classes
    },
    originalRow() {
      return this.row
    },
    modifiedRow() {
      const modifiedRow = { ...this.row }
      this.columns.forEach((column) => {
        if (column?.formatFn) {
          modifiedRow[column.field] = column.formatFn(modifiedRow[column.field], this.row)
        }
      })
      return modifiedRow
    }
  },
  watch: {
    expanded: {
      handler() {
        this.localExpanded = this.expanded
      },
      immediate: true
    },
    localExpanded: {
      handler() {
        if (this.expanded !== this.localExpanded) {
          this.$emit('updateExpanded', this.localExpanded)
        }
      }
    }
    /* localSelected: {
      immediate: true,
      handler(value) {
        this.$emit('updateSelection', value)
      }
    },
    selected: {
      immediate: true,
      handler(value) {
        this.localSelected = value
      }
    } */
  },
  methods: {
    columnStyle(column) {
      const style = {}
      style.width = column.width || undefined
      style.minWidth = column.minWidth || undefined
      style.maxWidth = column.maxWidth || undefined
      return style
    },
    getRowColumnKey(row, column) {
      if (row.key) {
        return row.key + column.field
      } else {
        return uuidV4()
      }
    },
    rowColumnValue(row, column) {
      return this.modifiedRow[column.field]
    },
    selectionMethod(e) {
      this.$emit('updateSelection', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTable__data-row {
  $root: &;
  /*&_zebra {
    &:nth-child(odd) {
      @apply bg-neutral-01-15;
    }
    &:hover {
      @apply bg-primary-01-25;
    }
  }*/
  td {
    @apply px-5 text-left align-top py-4;
    min-height: 52px;
  }
  &_no-padding {
    td {
      @apply px-0 py-0;
    }
  }
  &-checkbox {
    @apply mt-1;
  }
  &:hover {
    #{$root}-handler {
      @apply opacity-100;
    }
  }
  &-expander {
    /*width: 60px;*/
    padding-top: 0.75rem !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 1.25rem !important;
    &-icon {
      @apply w-5 h-5;
      @apply duration-200 transition-all transition transform rotate-90;
      &_expanded {
        @apply rotate-180;
      }
    }
  }
  &-handler {
    @apply absolute text-neutral-01-400 left-0 top-0 h-full flex items-start justify-center w-3 opacity-0 transition-opacity duration-200;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 22px !important;

    .row-handler {
      @apply flex-shrink-0;
      @apply cursor-move;
    }
  }
}
</style>
