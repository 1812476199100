<template>
  <div class="LLInputLocked" :class="classes">
    <div class="LLInputLocked__caption">
      <slot name="caption"></slot>
      <span v-if="required" class="LLInputLocked__required">*</span>
      <span v-if="optional" class="LLInputLocked__optional"> {{ $t('input_caption_optional') }}</span>
    </div>
    <div v-if="!truncate" class="LLInputLocked__value-wrapper_normal">
      <div class="LLInputLocked__value">
        <slot>{{ $t('recruiting_details_not_available') }}</slot>
      </div>
    </div>
    <div v-if="truncate" class="LLInputLocked__value-wrapper_truncated">
      <v-popover ref="popover" :disabled="!isTruncated" trigger="click" placement="top-end" offset="5">
        <div ref="truncate-wrapper" class="LLInputLocked__value-wrapper-truncate">
          <div ref="truncate-text" class="LLInputLocked__value">
            <slot>{{ $t('recruiting_details_not_available') }}</slot>
          </div>
        </div>
        <template v-if="isTruncated" #popover>
          <div class="LLInputLocked__truncate-popover">
            <slot>{{ $t('recruiting_details_not_available') }}</slot>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLInputLocked',
  props: {
    truncate: { type: Boolean, default: false },
    darkCaption: { type: Boolean, default: false },
    bigCaption: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    optional: { type: Boolean, default: false }
  },
  data() {
    return {
      isTruncated: false,
      resizeObserver: null
    }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLInputLocked'
      if (this.darkCaption) {
        classes.push(`${rootClass}_dark-caption`)
      }
      if (this.bigCaption) {
        classes.push(`${rootClass}_big-caption`)
      }
      return classes
    }
  },
  mounted() {
    this.onResize()
    this.initResizeListeners()
  },
  beforeDestroy() {
    this.destroyResizeListeners()
  },
  methods: {
    initResizeListeners() {
      const resizeObserver = new ResizeObserver(() => {
        this.onResize()
      })
      this.$nextTick(() => {
        try {
          resizeObserver.observe(this.$refs['truncate-text'])
        } catch {}
      })

      this.resizeObserver = resizeObserver
    },
    destroyResizeListeners() {
      this.resizeObserver.disconnect()
    },
    onResize() {
      if (!this.truncate) {
        return
      }
      this.$nextTick(() => {
        const truncateText = this.$refs['truncate-text']
        const truncateWrapper = this.$refs['truncate-wrapper']
        if (truncateText && truncateWrapper) {
          this.isTruncated = truncateText?.scrollWidth > truncateWrapper?.offsetWidth
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputLocked {
  $root: &;
  &__caption {
    @apply text-neutral-01-400 text-body-02 mb-2;
  }
  &_dark-caption {
    > #{$root}__caption {
      @apply text-accent-02-900;
    }
  }
  &_big-caption {
    > #{$root}__caption {
      @apply text-button-01;
    }
  }
  &__required {
    @apply text-status-03-300 absolute ml-1;
    vertical-align: super;
  }
  &__optional {
    @apply text-neutral-01-400 text-caption-02;
  }
  &__value-wrapper {
    &_normal {
      #{$root}__value {
        @apply break-words;
      }
    }
    &_truncated {
      ::v-deep .trigger {
        display: block !important;
        @apply w-full;
      }
      #{$root}__value-wrapper-truncate {
        @apply flex min-w-0 w-full;
      }
      #{$root}__value {
        @apply min-w-0 w-full truncate;
      }
    }
  }
}
.LLInputLocked__truncate-popover {
  max-width: 350px;
  @apply break-words;
}
</style>
