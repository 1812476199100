<template>
  <transition name="file-modal" duration="200" appear>
    <div class="LLAvatarCropper modal">
      <div class="modal-content">
        <div v-if="firstUploadPending && !cropperReady && !error" class="LLAvatarCropper__loader">
          <LoadingIcon></LoadingIcon>
        </div>
        <div v-if="error" class="LLAvatarCropper__error">
          Error
          <div class="LLAvatarCropper__actions">
            <LLButton class="LLAvatarCropper__action" secondary @click.native="cancel">Cancel</LLButton>
          </div>
        </div>
        <div v-if="!firstUploadPending && !error" class="LLAvatarCropper__content">
          <Cropper
            v-if="dataUrl"
            ref="cropper"
            :src="dataUrl"
            class="LLAvatarCropper__cropper"
            :canvas="true"
            :stencil-props="{
              aspectRatio: 1,
              resizable: true
            }"
            @ready="onCropperReady"
          ></Cropper>
          <div class="LLAvatarCropper__actions">
            <LLButton
              class="LLAvatarCropper__action"
              :disabled="!cropperReady"
              :loading="processedUploadPending"
              @click.native="confirm"
              >Confirm</LLButton
            >
            <LLButton class="LLAvatarCropper__action" secondary @click.native="cancel">Cancel</LLButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css'
import { Cropper } from 'vue-advanced-cropper'
import Api from '@/services/api'
import axios from 'axios'
import LoadingIcon from '@/components/common/LoadingIcon'
import LLButton from '@/components/common/ui-components/LLButton'
export default {
  name: 'LLAvatarCropper',
  components: {
    LLButton,
    LoadingIcon,
    Cropper
  },
  props: {
    file: { type: File, default: null }
  },
  data() {
    return {
      dataUrl: null,
      fileUrl: '',
      firstUploadPending: true,
      processedUploadPending: false,
      error: false,
      cropperReady: false
    }
  },
  computed: {},
  mounted() {
    this.tryLoad()
  },
  methods: {
    onCropperReady() {
      this.cropperReady = true
    },
    async confirm() {
      this.processedUploadPending = true
      try {
        const result = this.$refs.cropper.getResult()
        const croppedDataBlob = await new Promise((resolve) => {
          result.canvas.toBlob((blob) => {
            resolve(blob)
          }, 'image/png')
        })

        const serverFile = await Api.studies.prepareUpload({
          originalName: this.file.name,
          mimetype: 'image/png',
          size: croppedDataBlob.size
        })

        const uploadingFields = serverFile?.file?.uploadingInfo?.fields
        const uploadingUrl = serverFile?.file?.uploadingInfo?.url

        const formData = new FormData()
        Object.keys(uploadingFields).forEach((key) => {
          formData.append(key, uploadingFields[key])
        })
        formData.append('file', croppedDataBlob)
        const uploadedFile = await axios.post(uploadingUrl, formData)
        this.$emit('done', {
          file: uploadedFile?.data?.file
        })
      } catch (e) {
        this.error = true
      }
      this.processedUploadPending = false
    },
    cancel() {
      this.$emit('cancel')
    },
    imageLoaded() {
      this.loaded = true
    },
    async tryLoad() {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.dataUrl = reader.result
        this.firstUploadPending = false
      }
      reader.readAsDataURL(this.file)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLAvatarCropper {
  @apply flex items-start justify-center px-10;
  overflow: hidden !important;
  &__error {
    @apply p-8;
    @apply text-subheading-01 text-status-03-600;
  }

  &.file-modal-enter-active,
  &.file-modal-leave-active {
    .modal-content {
      transition-property: transform, opacity;
      transition-duration: 0.2s;
    }
    &:before {
      transition: opacity 0.2s;
    }
  }
  &.file-modal-enter, &.file-modal-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    &:before {
      opacity: 0;
    }
  }
  &.file-modal-enter {
    .modal-content {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  &.file-modal-leave-to {
    .modal-content {
      transform: scale(1.1);
      opacity: 0;
    }
  }

  &__cropper {
    @apply w-full h-full;
    min-width: 200px;
    min-height: 200px;
    max-height: 450px;
  }
  &__loader {
    @screen md {
      width: 430px;
      height: 560px;
    }
    @apply flex items-center justify-center;
  }
  &__content {
    @apply p-8;
  }
  &__actions {
    @apply mt-8 flex;
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
  }
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  .LLFileModal__close {
    position: absolute;
    right: 1.75rem;
    top: 1.5rem;
  }

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    @apply bg-primary-01;
    opacity: 0.3;
    content: '';
    top: 0;
    left: 0;
  }
}

/* Modal Content (image) */
.modal-content {
  width: auto;
  max-width: 1000px;
  display: block;
  @apply relative bg-white px-0;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
