<template>
  <LLPopper
    class="ForumResponseAnimatedReactionButton"
    :offset="[0, 0]"
    tooltip-white
    :show.sync="isAnimatedReactionSilectorVisible"
    placement="top-start"
  >
    <template #trigger>
      <LLButton tertiary-dark small>
        <template #icon-left>
          <ReactionAnimated2Icon class="ForumResponseAnimatedReactionButton__icon" />
        </template>
      </LLButton>
    </template>
    <template #tooltip>
      <LLTextareaEmoji show-animated-only @select="onReactionSelect" />
    </template>
  </LLPopper>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLTextareaEmoji from '@/components/common/LLTextarea/LLTextareaEmoji.vue'
import LLPopper from '@/components/utils/LLPopper.vue'
import ReactionAnimated2Icon from '@/assets/icons/ReactionAnimated2Icon.vue'

export default {
  name: 'ForumResponseAnimatedReactionButton',
  components: { ReactionAnimated2Icon, LLPopper, LLTextareaEmoji, LLButton },
  data() {
    return {
      isAnimatedReactionSilectorVisible: false
    }
  },
  computed: {},
  methods: {
    onReactionSelect(reaction) {
      this.isAnimatedReactionSilectorVisible = false
      this.$emit('reactionSelect', reaction.emoji)
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseAnimatedReactionButton {
  &__icon {
    @apply w-5 h-5;
  }
}
</style>
