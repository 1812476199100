<template>
  <button
    class="LLOptionsListButton"
    :class="buttonStyle"
    :disabled="disabled"
    data-e2e="optionsListBtn"
    @click="onClick"
  >
    <div v-if="slots.iconLeft" class="LLOptionsListButton__icon">
      <slot name="icon"></slot>
    </div>
    <div class="LLOptionsListButton__content">
      <slot></slot>
    </div>
    <div v-if="withArrow" class="LLOptionsListButton__arrow">
      <ArrowMiniIcon></ArrowMiniIcon>
    </div>
    <div v-if="slots.iconRight" class="LLOptionsListButton__icon-right">
      <slot name="icon-right"></slot>
    </div>
  </button>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
export default {
  name: 'LLOptionsListButton',
  components: { ArrowMiniIcon },
  props: {
    small: { type: Boolean, default: false },
    withArrow: { type: Boolean, default: false },
    preselected: { type: Boolean, default: false },
    unselected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      slots: {
        iconRight: false,
        iconLeft: false
      }
    }
  },
  computed: {
    buttonStyle() {
      const classes = []
      const addClass = (value) => {
        classes.push(`LLOptionsListButton_${value}`)
      }
      /* style class */
      if (this.small) {
        addClass('small')
      } else {
        addClass('normal')
      }
      if (this.withArrow) {
        addClass('with-arrow')
      }
      if (this.slots.iconLeft) {
        addClass('with-icon')
      }
      if (this.slots.iconRight) {
        addClass('with-icon-right')
      }
      if (this.preselected) {
        addClass('preselected')
      }
      if (this.unselected) {
        addClass('unselected')
      }
      if (this.disabled) {
        addClass('disabled')
      }
      return classes
    }
  },
  created() {
    this.checkSlots()
  },
  beforeUpdate() {
    this.checkSlots()
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    },
    checkSlots() {
      this.$nextTick(() => {
        this.slots.iconRight = !!this.$slots?.['icon-right']?.[0]
        this.slots.iconLeft = !!this.$slots?.icon?.[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLOptionsListButton {
  $root: &;
  @apply outline-none;
  &_normal {
    @apply text-base;
    @apply w-full px-5 py-2 font-semibold text-neutral-01 cursor-pointer block text-left;
    &:hover:not(#{$root}_unselected) {
      @apply bg-neutral-01-25;
    }
    &:focus:not(#{$root}_unselected) {
      @apply bg-neutral-01-25;
    }
    &#{$root}_preselected {
      @apply bg-neutral-01-25;
    }
  }
  &_small {
    @apply py-2 w-full text-left px-4 text-neutral-01-400 text-body-02 cursor-pointer transition duration-200 block;
    &:hover:not(#{$root}_unselected) {
      @apply text-primary-01-400 bg-primary-01-25;
    }
    &:focus:not(#{$root}_unselected) {
      @apply text-primary-01-400 bg-primary-01-25;
    }
    &#{$root}_preselected {
      @apply text-primary-01-400 bg-primary-01-25;
    }
  }
  &_with-icon {
    @apply flex items-center;
    #{$root}__content {
      @apply flex-shrink-0;
    }
    #{$root}__icon {
      @apply flex-shrink-0 mr-3;
    }
  }
  &_with-icon-right {
    @apply flex items-center;
    #{$root}__content {
      @apply flex-shrink-0;
    }
    #{$root}__icon-right {
      @apply flex-shrink-0 ml-3;
    }
  }
  &_with-arrow {
    @apply flex items-center;
    #{$root}__content {
      @apply flex-shrink-0;
    }
    #{$root}__arrow {
      @apply flex-shrink-0 ml-3;
    }
  }
  &_disabled {
    @apply pointer-events-none text-neutral-01-75;
  }
}
</style>
