<template>
  <div class="ForumThreadPageResponseList">
    <LLLoader v-if="isFetching && !responseList.length" class="ForumThreadPageResponseList__loader">
      Getting responses
    </LLLoader>
    <div v-else-if="error">
      <LLLoaderError @retry="fetchResponses">
        {{ error }}
      </LLLoaderError>
    </div>
    <div
      v-else
      class="ForumThreadPageResponseList__list"
      :class="{ ForumThreadPageResponseList__list_fetching: isFetching && !silentFetching }"
    >
      <ForumResponse
        v-if="isAbleToAddNewResponses"
        :key="newResponseKey"
        class="ForumThreadPageResponseList__response"
        :chat-id="thread.chatId"
        :thread-id="thread.id"
        @update="createResponse"
        @remove="onResponseRemove"
      />
      <ForumResponse
        v-for="response in mappedResponseList"
        :key="response.id + '-new'"
        :response="response"
        :is-editable="thread.isActive"
        :disable-reactanimation="!!thread.archivedAt || !!thread.hiddenAt"
        :is-able-to-reply="isAbleToAddNewResponses"
        class="ForumThreadPageResponseList__response"
        @read="onResponseRead"
        @update="updateResponse"
        @remove="onResponseRemove"
      />
      <div
        v-if="(responseList.length > perPage || total > perPage) && !error"
        class="ForumThreadPageResponseList__pagination-container"
      >
        <LLPagination
          :per-page="perPage"
          :total="total"
          :current="page"
          @goToPage="changePage"
        ></LLPagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLLoader from '@/components/common/LLLoader'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import LLPagination from '@/components/common/LLPagination'
import threadEditor from '@/utils/modules/forum/threadEditor'
import LLLoaderError from '@/components/common/LLLoaderError'
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'
import ForumResponse from '@/components/pages/forum/response/ForumResponse.vue'
const CancelToken = axios.CancelToken
import _ from 'lodash'

export default {
  name: 'ForumThreadPageResponseList',
  components: { ForumResponse, LLLoaderError, LLPagination, LLLoader },
  props: {
    thread: { type: ForumThreadModel, default: null }
  },
  data() {
    return {
      items: [],
      users: {},
      prospects: {},
      isFetching: true,
      newResponseKey: Date.now(),
      error: null,
      responseList: [],
      page: 1,
      perPage: 10,
      total: 0,
      cancelTokenSource: null,
      silentFetching: false,
      readReasponseIdList: []
    }
  },
  computed: {
    isAbleToAddNewResponses() {
      return (
        this.thread?.isActive && (!this.thread?.poll?.isRequiredResponse || this.thread?.poll.isResponded)
      )
    },
    mappedResponseList() {
      const remapResponse = (response) => {
        const newResponse = new ForumResponseModel(response)
        newResponse.user = newResponse.userId ? this.users[newResponse.userId] : null
        newResponse.prospect = newResponse.prospectId ? this.prospects[newResponse.prospectId] : null
        if (newResponse.replies.length) {
          newResponse.replies = newResponse.replies.map((reply) => {
            return remapResponse(reply)
          })
        }
        return newResponse
      }
      return this.responseList
        .map((response) => {
          return remapResponse(response)
        })
        .sort((a, b) => b.createdAt - a.createdAt)
    }
  },
  created() {},
  async mounted() {
    await this.checkResponseId()
    await this.fetchResponses()
    this.$nextTick(() => {
      threadEditor.addEvent(threadEditor.enums.RESPONSE_CREATE, this.createResponseFromSocket)
      threadEditor.addEvent(threadEditor.enums.RESPONSE_UPDATE, this.updateResponseFromSocket)
      threadEditor.addEvent(threadEditor.enums.RESPONSE_DELETE, this.deleteResponseFromSocket)
    })
  },
  beforeDestroy() {
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_CREATE, this.createResponseFromSocket)
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_UPDATE, this.updateResponseFromSocket)
    threadEditor.removeEvent(threadEditor.enums.RESPONSE_DELETE, this.deleteResponseFromSocket)
  },
  methods: {
    async checkResponseId() {
      const responseId = this.$route?.query?.goToResponseId
      if (!responseId) {
        return
      }
      const { path } = (await this.getResponsePath({ responseId })) || {}
      if (path) {
        scrollToForumReplyHelper.setResponsePath({ responsePath: path })
      }
    },
    async getResponsePath({ responseId }) {
      try {
        const { chatId, id: threadId } = this.thread
        const { path } = await this.$api.forum.responses.getResponsePath({
          chatId,
          threadId,
          responseId
        })
        return { path }
      } catch (e) {
        // Do nothing
      }
    },
    createResponseFromSocket({ response }) {
      if (this.page === 1 && !response?.parentResponseId) {
        this.fetchSilent()
      }
    },
    updateResponseFromSocket({ response }) {
      this.responseList = this.responseList.map((oldResponse) =>
        response.id !== oldResponse.id ? oldResponse : new ForumResponseModel(response)
      )
    },
    deleteResponseFromSocket({ responseId }) {
      if (this.responseList.find((response) => response.id === responseId)) {
        this.fetchSilent()
      }
    },
    onResponseRead({ responseId }) {
      if (!this.readReasponseIdList.find((_responseId) => responseId === _responseId)) {
        this.readReasponseIdList.push(responseId)
        this.sendReadResponse()
      }
    },
    sendReadResponse: _.debounce(function () {
      const readReasponseIdList = [...this.readReasponseIdList]
      this.readReasponseIdList = []
      this.$api.forum.responses
        .updateReadState({
          chatId: this.thread?.chatId,
          threadId: this.thread?.id,
          responseIdList: readReasponseIdList
        })
        .catch(() => {
          this.readReasponseIdList = [...this.readReasponseIdList, ...readReasponseIdList]
        })
    }, 3000),
    fetchSilent() {
      if (this.isFetching) {
        return
      }
      this.silentFetching = true
      this.fetchResponses()
    },
    resetPagination() {
      this.page = 1
      this.responseList = []
    },
    onResponseRemove({ responseId }) {
      this.responseList = this.responseList.filter((item) => item.id !== responseId)
      this.fetchResponses()
    },
    changePage(page) {
      this.resetPagination()
      this.page = page
      this.fetchResponses()
    },
    createResponse({ response }) {
      if (this.page !== 1) {
        this.resetPagination()
      }
      this.updateResponse({ response })
      if (!this.isFetching) {
        this.fetchResponses()
      }
      this.newResponseKey = Date.now()
      /* this.updateResponse({ response }) */
    },
    updateResponse({ response }) {
      const responseIndex = this.responseList.findIndex((_response) => _response?.id === response?.id)
      if (responseIndex !== -1) {
        this.responseList.splice(responseIndex, 1, response)
      } else {
        this.responseList.push(response)
      }
      if (response.user) {
        this.users = { ...this.users, [response.user.id]: response.user }
      }
      if (response.prospect) {
        this.prospects = { ...this.prospects, [response.prospect.id]: response.prospect }
      }
    },
    async fetchResponses() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.cancelTokenSource = CancelToken.source()

      this.isFetching = true
      this.error = null
      try {
        const { chatId, id: threadId } = this.thread
        const {
          page,
          threadResponses: serverResponseList,
          totalRecords,
          users,
          prospects
        } = await this.$api.forum.responses.getList({
          chatId,
          threadId,
          perPage: this.perPage,
          page: !scrollToForumReplyHelper.responseToWatch ? this.page : undefined,
          responseId: scrollToForumReplyHelper.responseToWatch
            ? scrollToForumReplyHelper.responseToWatch
            : undefined,
          cancelToken: this.cancelTokenSource.token
        })
        const responseList = serverResponseList.map((response) =>
          ForumResponseModel.parseFromApi({ response, chatId, threadId })
        )
        const usersObject = {}
        if (responseList.length === 0 && this.page !== 1) {
          this.resetPagination()
          this.fetchResponses()
          return
        }
        users.forEach((user) => {
          usersObject[user.id] = UserModel.parseFromApi({ user })
        })
        const prospectObject = {}
        prospects.forEach((prospect) => {
          prospectObject[prospect.id] = ProspectModel.parseFromApi({ prospect })
        })
        this.users = { ...this.users, ...usersObject }
        this.prospects = { ...this.prospects, ...prospectObject }
        this.total = totalRecords
        // this.responseList = [...this.responseList, ...responseList]
        this.responseList = responseList
        this.page = page || this.page
        /* const response = ForumResponseModel.parseFromApi({ response: serverResponse, chatId, threadId }) */
        if (!this.responseList.find((response) => response.id === scrollToForumReplyHelper.responseToWatch)) {
          scrollToForumReplyHelper.removeAll()
        }
      } catch (e) {
        console.log(e)
        if (!axios.isCancel(e)) {
          this.error = this.$getErrorMessage(e)
          this.isFetching = false
          this.silentFetching = false
        }
      } finally {
        this.isFetching = false
        this.silentFetching = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumThreadPageResponseList {
  &__list {
    @apply transition duration-200;
    &_fetching {
      @apply opacity-60 pointer-events-none select-none;
    }
  }
  &__loader {
    @apply py-20;
  }
  &__response {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
  &__pagination-container {
    @apply mt-6 flex justify-center;
  }
}
</style>
