<template>
  <div class="DashboardBody">
    <div v-if="isLoading" class="DashboardBody__loading">
      <LLLoader>
        <template #default>{{ $t('dashboard.loader') }}</template>
      </LLLoader>
    </div>
    <div v-else-if="error" class="DashboardBody__error">
      <LLLoaderError @retry="fetch" />
    </div>
    <div v-else class="DashboardBody__content">
      <DashboardTabs
        :selected-tab.sync="selectedTab"
        :chat-amount="meta.chatCount"
        :study-amount="meta.studyCount"
      ></DashboardTabs>

      <transition name="tab" mode="out-in">
        <DashboardStudies v-if="selectedTab === 'studies'" class="DashboardBody__tab" />
        <DashboardChats v-else-if="selectedTab === 'chats'" class="DashboardBody__tab" />
      </transition>
    </div>
  </div>
</template>

<script>
import LLLoader from '@/components/common/LLLoader'
import LLLoaderError from '@/components/common/LLLoaderError'
import DashboardStudies from '@/components/pages/dashboard/DashboardStudies'
import DashboardChats from '@/components/pages/dashboard/DashboardChats'
import DashboardTabs from '@/components/pages/dashboard/DashboardTabs'
export default {
  name: 'DashboardBody',
  components: { DashboardTabs, DashboardChats, DashboardStudies, LLLoaderError, LLLoader },
  data() {
    return {
      selectedTab: null,
      isLoading: false,
      error: null,
      meta: { chatCount: 0, studyCount: 0 }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    onTabChange(name) {
      this.selectedTab = name
    },
    async fetch() {
      this.isLoading = true
      this.error = null
      try {
        const { chatCount, studyCount } = await this.$api.dashboard.getMeta()
        this.meta.chatCount = chatCount
        this.meta.studyCount = studyCount
        if (this.$route.path === '/chats') {
          this.selectedTab = 'chats'
        } else {
          this.selectedTab = studyCount || !chatCount ? 'studies' : 'chats'
        }
      } catch (e) {
        this.error = this.$getErrorMessage(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardBody {
  &__loading {
    @apply py-20;
  }
  &__error {
    @apply py-20;
  }

  &__tab {
    &.tab-enter-active,
    &.tab-leave-active {
      transition-property: opacity, transform;
      transition-duration: 0.3s;
    }
    &.tab-enter,
    &.tab-leave-to {
      opacity: 0;
    }

    &.tab-enter {
      transform: translateX(20px);
    }

    &.tab-leave-to {
      transform: translateX(-20px);
    }
  }
}
</style>
