<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.68321 9.64179L6.73322 10.5697L1.43093 5.24531L2.35883 4.31741L4.85532 4.31741L7.21926 1.97557C7.07744 1.76387 7.01316 1.50966 7.03732 1.256C7.06148 1.00234 7.17259 0.764829 7.35182 0.583714L7.68321 0.274414L11.7262 4.3395L11.4169 4.6488C11.2358 4.82804 10.9983 4.93915 10.7446 4.96331C10.491 4.98746 10.2368 4.92319 10.0251 4.78136L7.68321 7.1453V9.64179ZM0.273682 11.7261L3.48341 7.31924L4.68057 8.5164L0.273682 11.7261Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PinIcon'
}
</script>
