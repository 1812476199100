const EventEmitter = require('events')

export class Connection extends EventEmitter {
  constructor(uri) {
    super()
    this.uri = uri
    this.queue = new Set()
    this.init()
  }
  init() {
    this.websocket = new WebSocket(this.uri)
    this.websocket.onopen = this._open.bind(this)
    this.handleEvents()
  }
  reconnect() {
    this.websocket = new WebSocket(this.uri)
    this.websocket.onopen = this._reopen.bind(this)
    this.handleEvents()
  }
  handleEvents() {
    this.handlers = new Set()
    this.addHandler(this._message.bind(this))
    this.websocket.onmessage = (msg) => {
      for (let handler of this.handlers) {
        handler(msg)
      }
    }
    this.websocket.onclose = this._close.bind(this)
    this.websocket.onerror = this._error.bind(this)
  }
  addHandler(handler) {
    this.handlers.add(handler)
  }
  removeHandler(handler) {
    this.handlers.delete(handler)
  }
  _open() {
    this.emit('_open')
    this.sendQueue()
  }
  _reopen() {
    this.emit('_reopen')
    this.sendQueue()
  }
  sendQueue() {
    for (let msg of this.queue) {
      this.queue.delete(msg)
      this._send(msg)
    }
  }
  _message(msg) {
    try {
      let message = JSON.parse(msg.data)
      message.data = message.data || []
      if (message.callback != null) {
        let response = {
          success: (res) => {
            this.send(message.callback, res)
          },
          error: (err) => {
            this.send(message.callback, null, err)
          }
        }
        this.emit(message.event, response, ...message.data)
      } else {
        this.emit(message.event, ...message.data)
      }
    } catch (e) {
      console.error('Message receiving error: ', e)
    }
  }
  _close(event) {
    this.emit('_close', event)
    if (event.code !== 1000) {
      setTimeout(() => this.reconnect(), 2000)
    }
  }
  _error(error) {
    this.emit('_error', error)
  }
  close() {
    this.websocket.close(1000)
  }
  _send(data) {
    if (this.websocket.readyState === 1) {
      this.websocket.send(data)
    } else {
      this.queue.add(data)
    }
  }
  send(event, ...data) {
    this._send(JSON.stringify({ event, data }))
  }
  request(event, ...data) {
    let timestamp = Number(new Date()),
      rand = Math.floor(Math.random() * 1000000)

    let callback = `_${timestamp}_${rand}`

    return new Promise((resolve, reject) => {
      this._send(JSON.stringify({ event, data, callback }))

      this.once(callback, (err, response) => {
        if (err != null) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })
  }
  setRoom(roomId) {
    return this.request('_room', roomId)
  }
}
