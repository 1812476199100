const initialState = () => ({
  threadSort: null
})

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_THREAD_SORT(s, sort) {
      s.threadSort = sort
    },
    REMOVE_ALL(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    }
  },
  actions: {
    reset({ commit }) {
      commit('REMOVE_ALL')
    }
  }
}
