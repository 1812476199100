<template>
  <svg
    class="ReactionIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.685 3.1123C6.33459 3.11365 2 7.44907 2 12.7998C2 18.1514 6.33594 22.4873 11.6875 22.4873C17.0391 22.4873 21.375 18.1514 21.375 12.7998H19.5C19.5 17.1084 15.9961 20.6123 11.6875 20.6123C7.37891 20.6123 3.875 17.1084 3.875 12.7998C3.875 8.49203 7.37756 4.98865 11.685 4.9873V3.1123ZM9.8125 10.9248C9.8125 11.6162 9.25391 12.1748 8.5625 12.1748C7.87109 12.1748 7.3125 11.6162 7.3125 10.9248C7.3125 10.2334 7.87109 9.6748 8.5625 9.6748C9.25391 9.6748 9.8125 10.2334 9.8125 10.9248ZM16.0625 10.9248C16.0625 11.6162 15.5039 12.1748 14.8125 12.1748C14.1211 12.1748 13.5625 11.6162 13.5625 10.9248C13.5625 10.2334 14.1211 9.6748 14.8125 9.6748C15.5039 9.6748 16.0625 10.2334 16.0625 10.9248ZM11.6875 16.5498C12.957 16.5498 14.1563 15.9873 14.9688 15.0107C15.3008 14.6123 15.8945 14.5615 16.2891 14.8896C16.6875 15.2217 16.7422 15.8115 16.4102 16.21C15.2383 17.6162 13.5156 18.4209 11.6875 18.4209C9.85938 18.4209 8.13672 17.6162 6.96484 16.21C6.63672 15.8115 6.6875 15.2217 7.08594 14.8896C7.48047 14.5615 8.07422 14.6123 8.40625 15.0107C9.21875 15.9912 10.418 16.5498 11.6875 16.5498Z"
    />
    <path
      d="M22.8675 5.16667H19.1175V1.41667C19.1175 1.18646 18.9311 1 18.7008 1H17.8675C17.6373 1 17.4508 1.18646 17.4508 1.41667V5.16667H13.7008C13.4706 5.16667 13.2842 5.35312 13.2842 5.58333V6.41667C13.2842 6.64687 13.4706 6.83333 13.7008 6.83333H17.4508V10.5833C17.4508 10.8135 17.6373 11 17.8675 11H18.7008C18.9311 11 19.1175 10.8135 19.1175 10.5833V6.83333H22.8675C23.0977 6.83333 23.2842 6.64687 23.2842 6.41667V5.58333C23.2842 5.35312 23.0977 5.16667 22.8675 5.16667Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ReactionIcon'
}
</script>

<style lang="scss" scoped>
.ReactionIcon {
  @apply fill-current;
}
</style>
