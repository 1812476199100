<template>
  <div class="LLMetaIconInfo">
    <div class="LLMetaIconInfo__icon-wrapper">
      <slot></slot>
    </div>
    <div class="LLMetaIconInfo__value-text">
      <span class="">{{ value }}</span>
      <span v-if="text && showText" class=""> {{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLMetaIconInfo',
  props: {
    value: { type: Number, default: 0 },
    text: { type: String, default: '' },
    showText: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss" scoped>
.LLMetaIconInfo {
  @apply flex items-center;
  &__icon-wrapper {
    @apply w-6 h-6 flex flex-shrink-0 items-center justify-center text-accent-01-400;
    & > * {
      @apply text-accent-01-400 w-5;
    }
  }
  &__value-text {
    @apply text-body-02 ml-1;
    height: 1.25rem;
  }
}
</style>
