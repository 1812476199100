<template>
  <svg
    icon
    fill-current
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0633 15.8333C18.0633 16.2754 17.8932 16.6993 17.5904 17.0118C17.2877 17.3244 16.877 17.5 16.4488 17.5H3.53294C3.10475 17.5 2.6941 17.3244 2.39133 17.0118C2.08855 16.6993 1.91846 16.2754 1.91846 15.8333V4.16667C1.91846 3.72464 2.08855 3.30072 2.39133 2.98816C2.6941 2.67559 3.10475 2.5 3.53294 2.5H7.56915L9.18364 5H16.4488C16.877 5 17.2877 5.17559 17.5904 5.48816C17.8932 5.80072 18.0633 6.22464 18.0633 6.66667V15.8333Z"
      stroke="#7990A2"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1.27148 9H17.7392" stroke="#7990A2" stroke-width="1.4" />
  </svg>
</template>

<script>
export default {
  name: 'ArchiveIcon'
}
</script>

<style scoped></style>
