<template>
  <div class="LLRangeSlider" :class="classes">
    <div class="LLRangeSlider__container">
      <div class="LLRangeSlider__range-values-wrapper">
        <div ref="range-min" class="LLRangeSlider__range-value" :class="{ 'opacity-0': hideMin }">
          {{ min }}
        </div>
        <div ref="range-max" class="LLRangeSlider__range-value" :class="{ 'opacity-0': hideMax }">
          {{ max }}
        </div>
      </div>
      <div class="LLRangeSlider__slider-container">
        <div class="LLRangeSlider__amount-button-wrapper">
          <DecrementRoundIcon
            data-e2e="questionDecrementBtn"
            class="LLRangeSlider__amount-button"
            @click.native="decrementValue"
          ></DecrementRoundIcon>
        </div>
        <div class="LLRangeSlider__slider-wrapper">
          <div class="LLRangeSlider__slider">
            <div ref="slider-line" class="LLRangeSlider__slider-line" @mousedown.stop="onTogglerMouseDown">
              <div class="LLRangeSlider__slider-back"></div>
              <div class="LLRangeSlider__slider-filled" :style="filledStyle"></div>
            </div>
            <div
              ref="slider-toggle"
              class="LLRangeSlider__slider-toggler"
              :style="togglerStyle"
              @mousedown.stop="onTogglerMouseDown"
            >
              <div class="LLRangeSlider__slider-touch-indicator"></div>
              <div class="LLRangeSlider__slider-toggler-round"></div>
              <div ref="value-tooltip" class="LLRangeSlider__value-tooltip" :style="tooltipStyle">
                <div class="LLRangeSlider__value-tooltip-text" data-e2e="questionSliderValue">
                  {{ currentValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="LLRangeSlider__amount-button-wrapper">
          <IncrementRoundIcon
            data-e2e="questionIncrementBtn"
            class="LLRangeSlider__amount-button"
            @click.native="incrementValue"
          ></IncrementRoundIcon>
        </div>
      </div>
      <div class="LLRangeSlider__range-prompt-wrapper">
        <div class="LLRangeSlider__range-prompt" data-e2e="questionStrMin">{{ strMin }}</div>
        <div class="LLRangeSlider__range-prompt" data-e2e="questionStrMax">{{ strMax }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DecrementRoundIcon from '@/assets/icons/DecrementRoundIcon.vue'
import IncrementRoundIcon from '@/assets/icons/IncrementRoundIcon.vue'

export default {
  name: 'LLRangeSlider',
  components: { IncrementRoundIcon, DecrementRoundIcon },
  props: {
    value: { type: Number, default: null },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 10 },
    disabled: { type: Boolean, default: false },
    interacted: { type: Boolean, default: false },
    strMin: { type: String, default: '' },
    strMax: { type: String, default: '' }
  },
  data() {
    return {
      mouseDown: false,
      togglerPosition: 0,
      currentValue: null,
      hideMax: false,
      hideMin: false,
      tooltipWidth: 0,
      initDone: false
    }
  },
  computed: {
    togglerStyle() {
      const percentage = Math.round(((this.currentValue - this.min) / (this.max - this.min)) * 100)
      return {
        left: `calc(${percentage}% - 10px * ${percentage / 100})`
      }
    },
    tooltipStyle() {
      return {
        marginLeft: `${-(this.tooltipWidth / 2 - 10)}px`
      }
    },
    filledStyle() {
      const percentage = Math.round(((this.currentValue - this.min) / (this.max - this.min)) * 100)
      return {
        width: `${percentage}%`
      }
    },
    classes() {
      const classes = []
      const rootClass = 'LLRangeSlider'
      if (this.mouseDown) {
        classes.push(`${rootClass}_active`)
      }
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      return classes
    }
  },
  watch: {
    value: {
      handler() {
        this.currentValue = this.value
      }
    },
    currentValue: {
      handler() {
        this.checkTooltipPosition()
        if (this.initDone || this.interacted) {
          this.$emit('input', this.currentValue)
          this.$emit('update:interacted', true)
        }
      }
    },
    disabled: {
      handler(value) {
        if (value) {
          this.onMouseUp()
        }
      }
    }
  },
  created() {
    if (typeof this.value !== 'number') {
      this.currentValue = this.min
    } else if (this.value < this.min) {
      this.currentValue = this.min
    } else if (this.currentValue > this.max) {
      this.currentValue = this.max
    } else {
      this.currentValue = this.value
    }
    this.$nextTick(() => {
      this.initDone = true
    })
  },
  mounted() {
    this.$refs['slider-toggle'].addEventListener('touchstart', this.onTogglerMouseDown)
    this.checkTooltipPosition()
  },
  beforeDestroy() {
    this.$refs['slider-toggle'].removeEventListener('touchstart', this.onTogglerMouseDown)
  },
  destroyed() {},
  methods: {
    checkTooltipPosition() {
      this.$nextTick(() => {
        try {
          const rangeMax = this.$refs['range-max']
          const rangeMin = this.$refs['range-min']
          const valueTooltip = this.$refs['value-tooltip']
          const rangeMaxPosition = rangeMax.getBoundingClientRect()
          const rangeMinPosition = rangeMin.getBoundingClientRect()
          let valueTooltipPosition = valueTooltip.getBoundingClientRect()
          this.tooltipWidth = valueTooltipPosition.width
          this.$nextTick(() => {
            valueTooltipPosition = valueTooltip.getBoundingClientRect()
            this.hideMax = valueTooltipPosition.x + valueTooltipPosition.width > rangeMaxPosition.x
            this.hideMin = valueTooltipPosition.x < rangeMinPosition.x + rangeMinPosition.width
          })
        } catch (e) {
          this.hideMax = false
          this.hideMin = false
        }
      })
    },
    onMouseUp() {
      this.mouseDown = false
      document.removeEventListener('mouseup', this.onMouseUp)
      document.removeEventListener('touchend', this.onMouseUp)
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('touchmove', this.onMouseMove)
    },
    getSliderPointerPercentage(e) {
      const sliderLine = this.$refs['slider-line']
      const sliderPosition = sliderLine.getBoundingClientRect()
      const pointerPosition = e.touches ? e?.touches[0]?.clientX : e?.clientX
      return Math.round(((pointerPosition - sliderPosition.left - 10) / (sliderPosition.width - 10)) * 100)
    },
    onTogglerMouseDown(e) {
      this.onMouseMove(e)
      document.addEventListener('touchmove', this.onMouseMove)
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)
      document.addEventListener('touchend', this.onMouseUp)
      this.mouseDown = true
    },
    getNearestminPercentage(percentage) {
      const value = Math.round(percentage / (100 / (this.max - this.min))) + this.min
      if (value > this.max) {
        this.currentValue = this.max
      } else if (value < this.min) {
        this.currentValue = this.min
      } else {
        this.currentValue = value
      }
    },
    onMouseMove(e) {
      if (this.disabled) {
        return
      }
      this.getNearestminPercentage(this.getSliderPointerPercentage(e))
    },
    decrementValue() {
      this.currentValue = this.currentValue > this.min ? this.currentValue - 1 : this.currentValue
    },
    incrementValue() {
      this.currentValue = this.currentValue < this.max ? this.currentValue + 1 : this.currentValue
    }
  }
}
</script>

<style lang="scss" scoped>
.LLRangeSlider {
  $root: &;
  @mixin sliderValueActive {
    #{$root}__value-tooltip {
      @apply bg-neutral-01-700 text-white;
      #{$root}__value-tooltip-text {
        transform: translateY(0rem);
      }
      &:after {
        border-top-color: var(--color-neutral-01-700);
      }
    }
  }
  @apply select-none;
  &__container {
    @apply w-full;
    max-width: 328px;

    #{$root}__range-values-wrapper {
      @apply w-full px-10 flex justify-between relative;
      #{$root}__range-value {
        @apply text-caption-02 text-neutral-01-400;
      }
    }

    #{$root}__slider-container {
      @apply w-full flex mt-1;
      #{$root}__amount-button-wrapper {
        @apply flex-shrink-0;
        #{$root}__amount-button {
          @apply w-6 h-6 flex items-center justify-center rounded-full cursor-pointer;
          &-icon {
            @apply text-white w-2.5;
          }
        }
      }

      #{$root}__slider-wrapper {
        @apply flex-1 px-4 flex items-center;
      }
    }

    #{$root}__range-prompt-wrapper {
      @apply w-full px-10 flex justify-between mt-1;
      #{$root}__range-prompt {
        @apply text-caption-02 break-words text-neutral-01-400;
        max-width: 45%;
      }
    }
  }

  #{$root}__value-tooltip {
    transition-duration: 200ms;
    transition-property: background-color;
    background: rgba(0, 0, 0, 0);
    @apply h-6 absolute top-0 left-0 -mt-8 rounded flex items-center justify-center px-1;
    &:after {
      border-top-color: transparent;
      width: 0;
      transition-duration: 200ms;
      height: 0;
      border-style: solid;
      position: absolute;
      content: '';
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      @apply absolute;
    }
    #{$root}__value-tooltip-text {
      @apply text-body-02;
      /*transform: translateY(0.25rem);*/
    }
  }

  #{$root}__slider {
    @apply w-full relative;
    #{$root}__slider-line {
      @apply w-full relative h-1;
      #{$root}__slider-back {
        @apply w-full bg-neutral-01-50 h-1 rounded cursor-pointer;
      }

      #{$root}__slider-filled {
        @apply h-1 rounded w-1/2 absolute left-0 top-0 cursor-pointer;
      }
    }
    #{$root}__slider-toggler {
      @apply w-5 h-5  absolute;
      top: -8px;
      #{$root}__slider-touch-indicator {
        margin-top: -14px;
        margin-left: -14px;
        transform: scale(0);
        @apply w-12 h-12 rounded-full bg-accent-01 absolute left-0 top-0 opacity-10 transition duration-200;
      }
      #{$root}__slider-toggler-round {
        @apply w-5 h-5 border bg-white relative rounded-full cursor-pointer;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      }
      &:hover {
        @include sliderValueActive;
        #{$root}__slider-touch-indicator {
          transform: scale(1);
        }
      }
    }
  }

  & {
    #{$root}__slider-filled {
      @apply bg-accent-01;
    }
    #{$root}__slider-toggler-round {
      @apply border-accent-01;
    }
    #{$root}__amount-button {
      @apply text-accent-01;
    }
  }
  &_active {
    @include sliderValueActive;
    #{$root}__slider-touch-indicator {
      transform: scale(1) !important;
      opacity: 0.3 !important;
    }
  }
  &_disabled {
    @apply pointer-events-none;
    #{$root}__slider-filled {
      @apply bg-neutral-01-400;
    }
    #{$root}__slider-toggler-round {
      @apply border-neutral-01-400;
    }
    #{$root}__amount-button {
      @apply text-neutral-01-400;
    }
  }
}
</style>
