var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PollNumberEditorValue__value-wrapper"},[_c('LLInputLocked',{staticClass:"PollNumberValue__value-wrapper",attrs:{"dark-caption":""},scopedSlots:_vm._u([{key:"caption",fn:function(){return [_vm._v(_vm._s(_vm.$t('forum.poll.number_value.input_caption')))]},proxy:true}])},[_c('ValidationObserver',{ref:"validation-observer"},[_c('ValidationProvider',{attrs:{"mode":"eager","disalbed":_vm.locked,"rules":{
          required: true,
          max_value: 2000000000
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('LLInputText',{staticClass:"PollNumberValue__value",attrs:{"errors":errors,"disabled":_vm.locked,"mask":{
            alias: 'numeric',
            groupSeparator: '',
            allowMinus: true,
            digits: 0,
            digitsOptional: false,
            placeholder: '',
            rightAlign: false
          }},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }