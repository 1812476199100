<template>
  <div class="UserEditModalHeader">
    <div class="UserEditModalHeader__image-wrapper" data-e2e="userImage">
      <UserImageUploader
        editable
        :string="permanentUser.fullName"
        :image="user.image"
        @update="updateImage"
      ></UserImageUploader>
    </div>
    <div class="UserEditModalHeader__content">
      <h3 class="UserEditModalHeader__title" data-e2e="userName">
        {{ permanentUser.fullName }}
      </h3>
      <div class="UserEditModalHeader__created" data-e2e="userCreated">
        <div class="UserEditModalHeader__created-description">{{ $t('dashboard_profile_created') }}:</div>
        <div class="UserEditModalHeader__created-value">
          {{ createdAt }}
        </div>
      </div>
      <div class="UserEditModalHeader__info">
        <div v-if="permanentUser.email" class="UserEditModalHeader__info-item" data-e2e="userEmail">
          <div class="UserEditModalHeader__info-description">{{ $t('dashboard_profile_email') }}:</div>
          <div class="UserEditModalHeader__info-value">{{ permanentUser.email }}</div>
        </div>
        <div v-if="permanentUser.phone" class="UserEditModalHeader__info-item" data-e2e="userPhone">
          <div class="UserEditModalHeader__info-description">{{ $t('dashboard_profile_phone') }}:</div>
          <div class="UserEditModalHeader__info-value">{{ permanentUser.phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProspectModel } from '@/models/prospect'
import UserImageUploader from '@/components/common/UserImageUploader'
import moment from 'moment'

export default {
  name: 'UserEditModalHeader',
  components: { UserImageUploader },
  props: {
    user: { type: ProspectModel, required: true },
    permanentUser: { type: ProspectModel, required: true }
  },
  data() {
    return {
      localUser: null
    }
  },
  computed: {
    createdAt() {
      let startDate = moment.unix(this.permanentUser.createdAt / 1000)
      return startDate.format('MM/DD/YYYY')
    }
  },
  watch: {
    user: {
      handler() {
        if (!this.$_.isEqual(this.user, this.localUser)) {
          this.localUser = new ProspectModel(this.user)
        }
      },
      immediate: true,
      deep: true
    },
    localUser: {
      handler() {
        this.$emit('update:user', new ProspectModel(this.localUser))
      },
      deep: true
    }
  },
  methods: {
    updateImage(image) {
      this.localUser.image = image
    }
  }
}
</script>

<style lang="scss" scoped>
.UserEditModalHeader {
  @apply flex;
  @screen md-max {
    @apply flex-col;
  }
  &__image-wrapper {
    @apply flex-shrink-0;

    @screen md-max {
      @apply flex justify-center pt-4;
    }
  }
  &__content {
    @apply ml-7 flex-1 min-w-0 flex flex-col justify-between;
    @screen md-max {
      @apply ml-0 mt-4;
    }
  }
  &__title {
    @apply truncate;
    @screen md-max {
      @apply text-center pb-8 mb-4;
      @apply border-b border-neutral-01-50;
    }
  }
  &__created {
    @apply text-body-03 text-neutral-01-400 flex;
    @screen md-max {
      @apply mb-4;
    }
    &-description {
      @apply text-body-02;
    }
    &-value {
      @apply ml-1;
    }
  }
  &__info {
    &-item {
      @apply flex mt-1;
      &:first-child {
        @apply mt-0;
      }
    }
    &-description {
      @apply text-subheading-01;
    }
    &-value {
      @apply truncate min-w-0 ml-1;
    }
  }
}
</style>
