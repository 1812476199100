import { Extension } from '@tiptap/vue-2'

const EnterHardBreak = Extension.create({
  addCommands() {
    return {
      addNewline:
        () =>
        ({ state, dispatch }) => {
          const { schema, tr } = state

          const hardBreak = schema.nodes.hardBreak
          const transaction = tr.replaceSelectionWith(hardBreak.create()).scrollIntoView()
          if (dispatch) dispatch(transaction)
          return true
        }
    }
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.addNewline()
    }
  }
})

export default EnterHardBreak
