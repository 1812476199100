<template>
  <div class="LLWysiwygOutput" :class="classList">
    <div v-if="!usingSlot" class="LLWysiwygOutput__html" v-html="html"></div>
    <slot v-else></slot>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import emojiToHtml from '@/utils/emojiToHtml'
import mentionToHtml from '@/utils/mentionToHtml'

const allowedTags = ['strong', 'em', 'ol', 'br', 'u', 'p', 'ul', 'li', 'a']
export default {
  name: 'LLWysiwygOutput',
  props: {
    text: { type: String, default: '' },
    usingSlot: { type: Boolean, default: false },
    sanitizeFull: { type: Boolean, default: false },
    truncate: { type: Boolean, default: false }
  },
  computed: {
    html() {
      return !this.sanitizeFull ? this.sanitizedHtml : this.sanitizedFull
    },
    sanitizedHtml() {
      return mentionToHtml.span(
        emojiToHtml.span(
          sanitizeHtml(this.text, {
            allowedTags
          })
        )
      )
    },
    sanitizedFull() {
      let currentTag
      const tagsWithSpaces = ['p', 'li', 'ul', 'ol', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'br']
      const transformTags = {}
      tagsWithSpaces.forEach((tag) => {
        transformTags[tag] = (tagName) => {
          console.log('tagName', tagName)
          currentTag = tagName
          return {
            tagName: ''
          }
        }
      })
      const sanitizedText = sanitizeHtml(this.text, {
        allowedTags: [],
        transformTags: {
          br: () => ({
            tagName: '',
            text: ' '
          }),
          ...transformTags
        },
        textFilter(text) {
          console.log(currentTag, text)
          if (tagsWithSpaces.includes(currentTag)) {
            currentTag = undefined
            return ` ${text}`
          }
          currentTag = undefined
          return text
        }
      })

      return mentionToHtml.span(emojiToHtml.span(sanitizedText))
    },
    classList() {
      return {
        [`${this.$options.name}_truncate`]: this.truncate
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.LLWysiwygOutput {
  $root: &;
  @apply cursor-text;
  ::v-deep {
    * {
      @apply break-words;
      font-family: 'Open Sans', 'Arial', 'Noto Color Emoji', 'Noto Emoji', sans-serif;
    }

    .emoji {
      @apply w-4 inline select-all pointer-events-none;
    }

    .mention {
      @apply text-accent-01;
    }

    ul {
      list-style: disc;
      padding-left: 1.5rem;
    }

    ol {
      list-style: decimal;
      padding-left: 1.5rem;
    }

    a {
      @apply text-primary-01-300 underline;
    }

    p {
      min-height: 1em;
    }
  }

  &__html {
    #{$root}_truncate & {
      @apply truncate;
    }
  }
}
</style>
