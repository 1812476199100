import moment from 'moment'
import { FileModel } from '@/models/file'
import participantUtils from '@/utils/participantUtils'

export class ProspectModel {
  constructor(prospectData = {}) {
    const {
      id,
      firstName,
      lastName,
      email,
      birthday,
      createdAt,
      updatedAt,
      deletedAt,
      lastActivityAt,
      invitedAt,
      registeredAt,
      region,
      city,
      requireRegistration,
      country,
      placeId,
      gender,
      ethnicity,
      image,
      luxuryQualified,
      superstar,
      phone,
      language,
      maritalStatus,
      householdIncome,
      children,
      occupation,
      employmentStatus,
      interestIdList,
      smsConsentAccepted,
      aiWarningAccepted
    } = prospectData
    this.id = id || null
    this.firstName = firstName || ''
    this.lastName = lastName || ''
    this.email = email || ''
    this.phone = phone || ''
    this.birthday = birthday || null
    this.createdAt = createdAt || null
    this.lastActivityAt = lastActivityAt || null
    this.invitedAt = invitedAt || null
    this.registeredAt = registeredAt || null
    this.updatedAt = updatedAt || null
    this.deletedAt = deletedAt || null
    this.region = region || null
    this.city = city || null
    this.requireRegistration = requireRegistration ?? true
    this.country = country || null
    this.placeId = placeId || null
    this.gender = gender || null
    this.ethnicity = ethnicity || null
    this.image = image || null
    this.luxuryQualified = luxuryQualified || null
    this.superstar = superstar || null
    this.maritalStatus = maritalStatus || null
    this.language = language || null
    this.householdIncome = householdIncome || null
    this.children = children || null
    this.occupation = occupation || null
    this.employmentStatus = employmentStatus || null
    this.interestIdList = interestIdList || []
    this.smsConsentAccepted = smsConsentAccepted || null
    this.aiWarningAccepted = aiWarningAccepted || null
  }

  static parseFromApi({ prospect }) {
    return new ProspectModel({
      id: prospect?.id || null,
      firstName: prospect?.firstName || null,
      lastName: prospect?.lastName || null,
      email: prospect?.email || null,
      phone: prospect?.phone || null,
      birthday: prospect?.birthday || null,
      createdAt: prospect?.createdAt || null,
      lastActivityAt: prospect?.lastActivityAt || null,
      invitedAt: prospect?.invitedAt || null,
      registeredAt: prospect?.registeredAt || null,
      updatedAt: prospect?.updatedAt || null,
      deletedAt: prospect?.deletedAt || null,
      region: prospect?.region || null,
      city: prospect?.city || null,
      country: prospect?.country || null,
      placeId: prospect?.placeId || null,
      gender: prospect?.gender || null,
      requireRegistration: prospect?.requireRegistration ?? true,
      ethnicity: prospect?.ethnicity || null,
      labels: prospect?.labels ? prospect.labels : [],
      image: prospect?.image ? FileModel.parseFromApi({ file: prospect?.image }) : null,
      luxuryQualified: prospect?.luxuryQualified || null,
      superstar: prospect?.superstar || null,
      maritalStatus: prospect?.maritalStatus || null,
      language: prospect?.preferredLanguage || null,
      householdIncome: prospect?.hhi || null,
      children: prospect?.children || '',
      occupation: prospect?.occupation || null,
      employmentStatus: prospect?.employmentStatus || null,
      interestIdList: prospect?.interestIdList || [],
      smsConsentAccepted: prospect?.smsConsentAccepted || null,
      aiWarningAccepted: prospect?.aiWarningAccepted || null
    })
  }

  get age() {
    return this.birthday
      ? Math.abs(Math.floor(moment.duration(moment.unix(this?.birthday / 1000).diff(moment.now())).asYears()))
      : null
  }

  get firstNameUppercase() {
    return this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1)
  }

  get lastNameUppercase() {
    return this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1)
  }

  get lastNameUppercaseShort() {
    return `${this.lastNameUppercase.substring(0, 1)}`
  }

  get fullName() {
    let str = ''
    if (this.firstNameUppercase) str = this.firstNameUppercase + (this.lastName ? ' ' : '')
    if (this.lastNameUppercase) str = `${str}${this.lastNameUppercase}`
    if (str.length) {
      return str
    } else if (this.wechatId) {
      return this.wechatId
    } else if (this.email) {
      return this.email
    }
    return null
  }

  get isDeleted() {
    return !!this.deletedAt
  }

  get fullLocation() {
    return participantUtils.getLocation(this)
  }

  get location() {
    return {
      region: this.region,
      city: this.city,
      country: this.country,
      placeId: this.placeId
    }
  }

  set location({ region, city, country, placeId }) {
    this.region = region
    this.city = city
    this.country = country
    this.placeId = placeId
  }

  get isProfileCompleted() {
    return (
      this.firstName?.length &&
      this.lastName?.length &&
      this.phone?.length &&
      this.email?.length &&
      this.gender &&
      this.language &&
      this.birthday &&
      (this.country?.length || this.region?.length || this.city?.length)
    )
  }

  get fullNameWithShortLastName() {
    let str = ''
    if (this.firstNameUppercase) str = this.firstNameUppercase + (this.lastName ? ' ' : '')
    if (this.lastNameUppercase) str = `${str}${this.lastNameUppercaseShort}`
    if (str.length) {
      return str
    } else if (this.wechatId) {
      return this.wechatId
    } else if (this.email) {
      return this.email
    }
    return null
  }
}
