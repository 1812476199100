<template>
  <div class="LLFileUploaderWrapper">
    <button class="LLFileUploaderWrapper__file-button" tabindex="-1" @click="triggerInput">
      <slot></slot>
      <input
        ref="file-input"
        class="LLFileUploaderWrapper__file-input"
        multiple
        type="file"
        :accept="fileMimeType"
        @change="previewFiles"
      />
    </button>
  </div>
</template>

<script>
import fileSize from 'filesize'
import { v4 as uuidV4 } from 'uuid'
let allowedFileMimeTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  'image/tiff',
  'video/mp4',
  'video/quicktime',
  'video/ogg',
  'video/webm'
]
export default {
  name: 'LLFileUploaderWrapper',
  props: {
    fileMimeType: {
      type: String,
      default: ''
    },
    fileUploading: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    hideProgress: {
      //whether to hide the file upload progress bar
      type: Boolean,
      default: false
    },
    square: {
      //for square button on the left of input box
      type: Boolean,
      default: false
    },
    preparable: {
      type: Boolean,
      default: false
    },
    studyId: { type: Number, default: null }
  },
  data: () => ({
    uploadedFiles: [],
    currentLoadingFiles: [],
    uploadingState: {
      totalFiles: 0,
      loadedFiles: 0,
      totalSize: 0,
      loadedSizes: []
    },
    globalFiles: [],
    cancelAxiosToken: null,
    cancelTokens: [],
    uploadingFilesUuids: []
  }),
  computed: {
    uploadingFiles() {
      return this.$store.getters['fileUpload/uploadingFilesByUUID'](this.uploadingFilesUuids)
    },
    loading() {
      return !!this.uploadingFiles.length
    }
  },
  watch: {
    uploadingFiles: {
      deep: true,
      handler() {
        if (this.uploadingFiles.length && !this.uploadingFiles.filter((f) => f.uploading).length) {
          this.$store.commit('fileUpload/REMOVE_FILE_DATA', this.uploadingFilesUuids)
        }
      }
    },
    loading(to) {
      this.$emit('update:fileUploading', to)
      if (to) {
        window.addEventListener('beforeunload', this.preventWindowClose)
      } else {
        window.removeEventListener('beforeunload', this.preventWindowClose)
      }
    }
  },
  methods: {
    triggerInput() {
      this.$refs['file-input'].dispatchEvent(new MouseEvent('click'))
    },
    preventWindowClose(e) {
      ;(e || window.event).returnValue = null
      return null
    },
    previewFiles(event) {
      const files = [...event.target.files]
      if (!files) return
      event.target.value = null

      if (this.preparable) {
        this.prepare(files)
        return
      }
      const action = this.preparable ? 'fileUpload/upload' : 'fileUpload/prepareAndUpload'
      const promises = []
      this.globalFiles = [...this.globalFiles, ...files]
      files.forEach((file) => {
        let uuid = uuidV4()
        promises.push(
          this.$store
            .dispatch(action, {
              uuid,
              file,
              studyId: this.studyId
            })
            .then((response) => {
              this.uploadedFiles.push(response)
            })
            .catch((e) => {
              if (e !== 'canceled') {
                this.$errorAlert(e)
              }
            })
        )
        this.uploadingFilesUuids.push(uuid)
      })
      this.$emit('startUpload', this.uploadingFilesUuids)
      Promise.all(promises).then(() => {
        this.$emit('upload', this.uploadedFiles)
        this.uploadedFiles = []
        this.uploadingFilesUuids = []
      })
    },
    prepare(files) {
      files.forEach((file) => {
        let fileType = null
        try {
          let isHeic = new RegExp(/\.heic$/).test(file.name) || new RegExp(/\.heif$/).test(file.name)
          fileType = file.type || (isHeic ? 'image/heic' : null)
          if (!allowedFileMimeTypes.includes(file.type) && !isHeic) {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Error',
              text: this.$t('error_conversation_stream_file_wrong_type')
            })
            return
          }
          if (file.size > process.env.VUE_APP_UPLOAD_FILE_SIZE_LIMIT) {
            this.$notify({
              group: 'main',
              type: 'error',
              title: 'Error',
              text: this.$t('error_conversation_stream_file_too_big', {
                size: fileSize(process.env.VUE_APP_UPLOAD_FILE_SIZE_LIMIT, { round: 0 })
              })
            })
            return
          }
        } catch (e) {
          this.$api.system.sendError({ message: e.message, stack: e.stack, e })
        }
        this.$store
          .dispatch('fileUpload/prepare', { file, fileType, studyId: this.studyId })
          .then((file) => {
            this.$emit('prepare', file)
          })
          .catch((e) => {
            this.$api.system.sendError({ message: e.message, stack: e.stack, e })
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileUploaderWrapper {
  &__file-button {
    @apply cursor-pointer outline-none;
  }
  &__file-input {
    display: none;
  }
}
</style>
