import { eventNameEnums } from '@/utils/modules/forum/chatEditor/enums'

import { Connection } from '@/plugins/conversationWebsocket'
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
import { ForumReactionModel } from '@/models/forum/forumReaction'
import globalRoutes from '@/utils/globalRoutes'
let host = globalRoutes.api?.replace('http', 'ws')

let connection = null
let events = {}

const sendToken = ({ token, chatId }) => {
  connection.request('token', token)
  connection.setRoom(`chat-editing:${chatId}`)
}

const runEvents = (eventName, data) => {
  if (events[eventName]) {
    events[eventName].forEach((callback) => callback(data))
  }
}

export default {
  connect({ token, chatId }) {
    events = {}
    connection = new Connection(`${host}/api/pwa/ws`)
    connection.on('_reopen', () => {
      sendToken({ token, chatId })
    })
    connection.on('_open', () => {
      sendToken({ token, chatId })
    })
    connection.on(eventNameEnums.CHAT_UPDATE, (chat) => {
      runEvents(eventNameEnums.CHAT_UPDATE, {
        chat: ForumChatModel.parseFromApi({ chat })
      })
    })
    connection.on(eventNameEnums.CHAT_META_UPDATE, (chatMeta) => {
      runEvents(eventNameEnums.CHAT_META_UPDATE, {
        chatMeta
      })
    })
    connection.on(eventNameEnums.CHAT_THREAD_DELETE, ({ threadId }) => {
      runEvents(eventNameEnums.CHAT_THREAD_DELETE, {
        threadId
      })
    })
    connection.on(eventNameEnums.CHAT_THREAD_META_UPDATE, ({ threadId, meta }) => {
      runEvents(eventNameEnums.CHAT_THREAD_META_UPDATE, {
        threadId,
        meta
      })
    })
    connection.on(eventNameEnums.REACTIONS_UPDATE, ({ reactions, threadId }) => {
      runEvents(eventNameEnums.REACTIONS_UPDATE, {
        threadId,
        reactions: reactions.map((reaction) => ForumReactionModel.parseFromApi({ reaction }))
      })
    })
    connection.on(eventNameEnums.CHAT_THREAD_CREATE, ({ thread }) => {
      runEvents(eventNameEnums.CHAT_THREAD_CREATE, {
        thread: ForumThreadModel.parseFromApi({ thread, chatId })
      })
    })
    connection.on(eventNameEnums.CHAT_THREAD_UPDATE, ({ thread }) => {
      runEvents(eventNameEnums.CHAT_THREAD_UPDATE, {
        thread: ForumThreadModel.parseFromApi({ thread, chatId })
      })
    })
  },
  destroy() {
    if (connection) {
      connection.close()
    }
    events = {}
  },
  trigger(name, data) {
    runEvents(name, data)
  },
  addEvent(name, callback) {
    if (events[name]) {
      events[name].push(callback)
    } else {
      events[name] = [callback]
    }
  },
  removeEvent(name, callback) {
    if (events[name]) {
      events[name].splice(events[name].indexOf(callback), 1)
    }
    if (events[name]?.length === 0) {
      delete events[name]
    }
  },
  enums: eventNameEnums,
  connection() {
    return connection
  }
}
