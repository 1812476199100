<template>
  <div class="LLFilterItem">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LLFilterItem'
}
</script>

<style lang="scss" scoped>
.LLFilterItem {
  @apply py-5 border-neutral-01-400 border-b;
  &:last-child {
    @apply border-b-0 pb-0;
  }
}
</style>
