<template>
  <div class="LLLoaderError">
    <div class="LLLoaderError__notice">
      <slot>Error</slot>
    </div>
    <LLButton class="LLLoaderError__retry-button" small @click="retry">
      <slot name="error-button">Retry</slot>
    </LLButton>
    <LLButton v-if="hasCancel" class="LLLoaderError__cancel-button" secondary small @click="cancel">
      <slot name="error-button">Cancel</slot>
    </LLButton>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
export default {
  name: 'LLLoaderError',
  components: { LLButton },
  props: {
    hasCancel: { type: Boolean, default: false }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    retry() {
      this.$emit('retry')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLLoaderError {
  @apply flex flex-col items-center justify-center;
  &__notice {
    @apply text-caption-01 text-neutral-01-400 text-center;
  }
  &__retry-button {
    @apply mt-4;
  }
  &__cancel-button {
    @apply mt-4;
  }
}
</style>
