import { FileModel } from '@/models/file'
/*
import { UserState } from '@/common/enums'
*/
import { BaseModel } from '@/models/baseModel'

export class UserModel extends BaseModel {
  constructor(userData = {}) {
    super()
    const {
      id,
      firstName,
      lastName,
      email,
      createdAt,
      updatedAt,
      deletedAt,
      lastActivityAt,
      invitedAt,
      image,
      phone,
      tfaEnabled,
      settings
    } = userData

    this.id = id || null
    this.firstName = firstName || ''
    this.lastName = lastName || ''
    this.email = email || ''
    this.phone = phone || ''
    this.createdAt = createdAt || null
    this.lastActivityAt = lastActivityAt || null
    this.invitedAt = invitedAt || null
    this.updatedAt = updatedAt || null
    this.deletedAt = deletedAt || null
    this.image = image ? new FileModel(image) : null
    this.tfaEnabled = tfaEnabled ?? null

    this.settings = {
      isEditedAtIndicatorHidden: settings?.isEditedAtIndicatorHidden || false
    }
  }

  static parseFromApi({ user }) {
    return new UserModel({
      id: user?.id,
      firstName: user?.firstName || null,
      lastName: user?.lastName || null,
      email: user?.email || null,
      phone: user?.phone || null,
      createdAt: user?.createdAt || null,
      lastActivityAt: user?.lastActivityAt || null,
      invitedAt: user?.invitedAt || null,
      updatedAt: user?.updatedAt || null,
      deletedAt: user?.deletedAt || null,
      image: user?.image ? FileModel.parseFromApi({ file: user?.image }) : null,
      tfaEnabled: user?.enableTfa ?? null,
      settings: {
        isEditedAtIndicatorHidden: user?.settings?.isEditedAtIndicatorHidden || false
      }
    })
  }

  getApiObject() {
    return {
      id: this.id,
      firstName: this?.firstName,
      lastName: this?.lastName,
      email: this?.email,
      phone: this?.phone,
      imageId: this.image?.id,
      enableTfa: this.tfaEnabled ?? undefined
    }
  }

  getApiSettingsObject() {
    return {
      isEditedAtIndicatorHidden: this.settings.isEditedAtIndicatorHidden
    }
  }

  get firstNameUppercase() {
    return this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1)
  }

  get lastNameUppercase() {
    return this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1)
  }

  get lastNameUppercaseShort() {
    return `${this.lastNameUppercase.substring(0, 1)}`
  }

  get fullNameWithShortLastName() {
    let str = ''
    if (this.firstNameUppercase) str = this.firstNameUppercase + (this.lastName ? ' ' : '')
    if (this.lastNameUppercase) str = `${str}${this.lastNameUppercaseShort}`
    if (str.length) {
      return str
    } else if (this.email) {
      return this.email
    }
    return null
  }

  get fullName() {
    let str = ''
    if (this.firstNameUppercase) str = this.firstNameUppercase + (this.lastName ? ' ' : '')
    if (this.lastNameUppercase) str = `${str}${this.lastNameUppercase}`
    if (str.length) {
      return str
    } else if (this.email) {
      return this.email
    }
    return null
  }

  /*get state() {
    if (this.lastActivityAt) {
      return UserState.ACTIVE
    } else if (this.invitedAt) {
      return UserState.INVITED
    } else if (this.createdAt) {
      return UserState.CREATED
    }
  }*/
}
