import { FileModel } from '@/models/file'
import { QuestionChoiceOptionModel } from '@/models/questionChoiceOption'
import { QuestionPollModel } from '@/models/questionPoll'

export class QuestionModel {
  constructor(questionData = {}) {
    const {
      id,
      title,
      body,
      topicId,
      studyId,
      type,
      center,
      choiceOptions,
      matrixColumns,
      leftSide,
      rightSide,
      sliderLabelLeft,
      sliderLabelRight,
      starsCount,
      noLabel,
      yesLabel,
      order,
      hasConversationStreams,
      requireTextOrMediaResponse,
      selectedGroups,
      files,
      automaticallyResolve,
      hasRandomQuestionOrder,
      hasRandomChoiceOptionsOrder,
      hasRandomMatrixColumnsOrder,
      hideChoiceOptionsText,
      largeChoiceOptionsThumbnails,
      poll
    } = questionData
    this.id = id || undefined
    this.title = title || ''
    this.body = body || ''
    this.topicId = topicId || null
    this.studyId = studyId || null
    this.type = type || null
    this.center = center ?? null
    this.choiceOptions =
      choiceOptions?.map((questionChoiceOption) => new QuestionChoiceOptionModel(questionChoiceOption)) || []
    this.matrixColumns =
      matrixColumns?.map((questionChoiceOption) => new QuestionChoiceOptionModel(questionChoiceOption)) || []
    this.leftSide = leftSide ?? null
    this.rightSide = rightSide ?? null
    this.sliderLabelLeft = sliderLabelLeft || ''
    this.sliderLabelRight = sliderLabelRight || ''
    this.starsCount = starsCount || 0
    this.noLabel = noLabel || ''
    this.yesLabel = yesLabel || ''
    this.order = order ?? undefined
    this.requireTextOrMediaResponse = requireTextOrMediaResponse || false
    this.hasConversationStreams = hasConversationStreams || false
    this.hideChoiceOptionsText = hideChoiceOptionsText || false
    this.largeChoiceOptionsThumbnails = largeChoiceOptionsThumbnails || false
    this.poll = poll ? new QuestionPollModel(poll) : null
    this.selectedGroups = Array.isArray(selectedGroups) ? [...selectedGroups] : []
    this.files = Array.isArray(files) ? files.map((file) => new FileModel(file)) : []
    this.automaticallyResolve = automaticallyResolve || false
    this.hasRandomQuestionOrder = hasRandomQuestionOrder || false
    this.hasRandomChoiceOptionsOrder = hasRandomChoiceOptionsOrder || false
    this.hasRandomMatrixColumnsOrder = hasRandomMatrixColumnsOrder || false
  }

  static parseFromApi({ question, topicId, studyId }) {
    return new QuestionModel({
      id: question?.id || undefined,
      title: question?.title || '',
      body: question?.body || '',
      topicId: topicId || null,
      studyId: studyId || null,
      type: question?.type || null,
      center: question?.center ?? null,
      choiceOptions:
        question?.choiceOptions?.map((questionChoiceOption) =>
          QuestionChoiceOptionModel.parseFromApi({ questionChoiceOption })
        ) || [],
      matrixColumns:
        question?.matrixColumns?.map((questionChoiceOption) =>
          QuestionChoiceOptionModel.parseFromApi({ questionChoiceOption })
        ) || [],
      leftSide: question?.leftSide ?? null,
      rightSide: question?.rightSide ?? null,
      sliderLabelLeft: question?.sliderLabelLeft || '',
      sliderLabelRight: question?.sliderLabelRight || '',
      starsCount: question?.starsCount || 0,
      noLabel: question?.noLabel || '',
      yesLabel: question?.yesLabel || '',
      order: question?.order ?? undefined,
      requireTextOrMediaResponse: question?.text || false,
      hasConversationStreams: question?.hasConversationStreams || false,
      hideChoiceOptionsText: question?.hideChoiceOptionsText || false,
      largeChoiceOptionsThumbnails: question?.largeChoiceOptionsThumbnails || false,
      poll: question?.poll
        ? QuestionPollModel.parseFromApi({ poll: question?.poll, topicId, studyId })
        : null,
      selectedGroups: question?.groupSpecificSettings?.map((g) => g.id) || [],
      files:
        question?.attachedFiles
          ?.map((file) => FileModel.parseFromApi({ file }))
          ?.sort((a, b) => a?.order - b?.order) || [],
      automaticallyResolve: question?.automaticallyResolve,
      hasRandomQuestionOrder: question?.hasRandomQuestionOrder,
      hasRandomChoiceOptionsOrder: question?.hasRandomChoiceOptionsOrder,
      hasRandomMatrixColumnsOrder: question?.hasRandomMatrixColumnsOrder
    })
  }

  convertToApi() {
    const {
      id,
      title,
      body,
      order,
      requireTextOrMediaResponse,
      selectedGroups,
      files,
      automaticallyResolve,
      hasRandomQuestionOrder,
      hasRandomChoiceOptionsOrder,
      hasRandomMatrixColumnsOrder,
      hideChoiceOptionsText,
      largeChoiceOptionsThumbnails
    } = this
    return {
      id,
      title,
      body,
      order,
      hasRandomQuestionOrder,
      hasRandomChoiceOptionsOrder,
      hasRandomMatrixColumnsOrder,
      hideChoiceOptionsText,
      largeChoiceOptionsThumbnails,
      poll: this.poll?.convertToApi ? this.poll.convertToApi() : null,
      groupSpecificSettings: selectedGroups?.length
        ? this.selectedGroups.map((gId) => ({
            id: gId
          }))
        : null,
      text: requireTextOrMediaResponse,
      fileIdList: files.map((f, i) => ({ id: f.id, order: i })),
      automaticallyResolve
    }
  }
}
