<template>
  <svg
    class="icon fill-current"
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 6C5 4.89543 4.10457 4 3 4C1.89543 4 1 4.89543 1 6C1 7.10457 1.89543 8 3 8C4.10457 8 5 7.10457 5 6ZM12 6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6C8 7.10457 8.89543 8 10 8C11.1046 8 12 7.10457 12 6ZM17 4C18.1046 4 19 4.89543 19 6C19 7.10457 18.1046 8 17 8C15.8954 8 15 7.10457 15 6C15 4.89543 15.8954 4 17 4Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OptionsIcon'
}
</script>
