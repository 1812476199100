<template>
  <div class="ReactionAnimation">
    <div class="ReactionAnimation__overlay" :class="overlayClassList"></div>
    <div ref="animation" class="ReactionAnimation__container"></div>
    <div
      v-if="isFlyingContainerVisible"
      ref="flying-container"
      class="ReactionAnimation__flying-container"
      :style="flyingContainerTransformStyle"
    >
      <transition name="fade">
        <LLAnimation
          v-if="isAnimationVisible"
          :animation-name="animationName"
          class="ReactionAnimation__lottie"
          @complete="onAnimationComplete"
        ></LLAnimation>
      </transition>
    </div>
  </div>
</template>

<script>
import LLAnimation from '@/components/common/LLAnimation.vue'

export default {
  name: 'ReactionAnimation',
  components: { LLAnimation },
  props: {
    animationName: { type: String, required: true }
  },
  data() {
    return {
      flyingContainerPosition: {
        top: null,
        left: null,
        scale: 0
      },
      isFlyingContainerVisible: false,
      isOverlayVisible: false,
      isAnimationVisible: false
    }
  },
  computed: {
    overlayClassList() {
      const classes = []
      const rootClass = 'ReactionAnimation__overlay'
      if (this.isOverlayVisible) {
        classes.push(`${rootClass}_visible`)
      }
      return classes
    },
    flyingContainerTransformStyle() {
      return {
        transform: `translate(${this.flyingContainerPosition.left}px, ${this.flyingContainerPosition.top}px) scale(${this.flyingContainerPosition.scale})`
      }
    }
  },
  methods: {
    getCurrentElementPosition() {
      const coordinates = this.element.getBoundingClientRect()
      return { top: coordinates.top + coordinates.height / 2, left: coordinates.left + coordinates.width / 2 }
    },
    placeFlyingContainerToStart() {
      const { top, left } = this.getCurrentElementPosition()
      const currentContainerPosition = this.$refs.animation.getBoundingClientRect()

      this.flyingContainerPosition.top = -(
        currentContainerPosition.top +
        currentContainerPosition.height / 2 -
        top
      )
      this.flyingContainerPosition.left = -(
        currentContainerPosition.left +
        currentContainerPosition.width / 2 -
        left
      )
      this.flyingContainerPosition.scale = 0
    },
    startHover() {
      this.isAnimationVisible = true
      this.isOverlayVisible = true
      this.isFlyingContainerVisible = true
    },
    stopHover() {
      this.isAnimationVisible = false
      this.isOverlayVisible = false
      this.isFlyingContainerVisible = false
    },
    start(options) {
      this.element = options.element
      this.getCurrentElementPosition()
      this.$nextTick(() => {
        this.drawFlyingAnimation()
      })
    },
    drawFlyingAnimation() {
      this.placeFlyingContainerToStart()
      this.isFlyingContainerVisible = true

      setTimeout(() => {
        this.flyingContainerPosition.top = 0
        this.flyingContainerPosition.left = 0
        this.flyingContainerPosition.scale = 1
        this.isOverlayVisible = true
      }, 100)

      setTimeout(() => {
        this.isAnimationVisible = true
      }, 300)
    },
    onAnimationComplete() {
      this.isAnimationVisible = false
      setTimeout(() => {
        this.isOverlayVisible = false

        this.placeFlyingContainerToStart()

        setTimeout(() => {
          this.$emit('complete')
        }, 500)
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';
.ReactionAnimation {
  @apply fixed w-full h-full top-0 left-0;
  z-index: 9000;

  &__overlay {
    @apply absolute w-full h-full top-0 left-0 duration-500;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    &_visible {
      opacity: 1;
    }
  }

  &__container {
    $width: 500px;
    @apply absolute p-4;
    left: 50%;
    top: 50%;
    width: #{$width};
    height: #{$width};
    margin-top: -($width/2);
    margin-left: -($width/2);
  }

  &__flying-container {
    $width: 500px;
    @apply absolute p-4 rounded;
    left: 50%;
    top: 50%;
    width: #{$width};
    height: #{$width};
    margin-top: -($width/2);
    margin-left: -($width/2);
    transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    @media (max-width: 576px) {
      width: 320px;
      height: 320px;
      margin-left: -160px;
      margin-top: -160px;
    }
  }

  &__lottie {
    @apply w-full h-full;
  }
}
</style>
