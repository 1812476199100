import Vue from 'vue'
import VTooltipLibrary from 'v-tooltip'

Vue.use(VTooltipLibrary, {
  defaultHtml: false,
  defaultBoundariesElement: 'window'
})

Vue.directive('popper-close', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      let currentBlock = vnode?.componentInstance?.$parent || vnode?.context
      while (typeof currentBlock !== 'undefined' && !currentBlock?.popperCloseStop) {
        if (currentBlock.popperJS !== undefined && currentBlock.doClose) {
          currentBlock.doClose()
        }
        if (currentBlock.isLLPopper !== undefined && currentBlock.hideAction) {
          currentBlock.hideAction()
        }
        currentBlock = currentBlock?.$parent
      }
    })
  }
})

Vue.directive('popper-close-stop', {
  inserted(el, binding, vnode) {
    if (vnode?.componentInstance && typeof vnode.componentInstance === 'object') {
      vnode.componentInstance.popperCloseStop = true
    }
  }
})

Vue.directive('popper-close-only', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      const currentBlock = vnode?.componentInstance
      if (currentBlock.popperJS !== undefined && currentBlock.doClose) {
        currentBlock.doClose()
      }
      if (currentBlock.isLLPopper !== undefined && currentBlock.hideAction) {
        currentBlock.hideAction()
      }
    })
  }
})
