<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.88379 18.6641L21.0838 12.6641L7.88379 6.66406V11.3307L17.3124 12.6641L7.88379 13.9974V18.6641Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SendIcon'
}
</script>
