<template>
  <LLWordTextContainer class="TermsOfServiceNL">
    <p class="c2"><span class="c4">Inleiding</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span
        >SPARK Ideas LLC (&ldquo;SPARK&rdquo;) verleent u (&ldquo;U&rdquo; of &ldquo;Gebruiker&rdquo;,
        collectief &ldquo;Gebruikers&rdquo;) een niet-exclusieve, niet-overdraagbare toestemming voor het
        weergeven van de webpagina&#39;s en voor toegang tot de inhoud die wordt gehost op de
        LookLook&reg;-website (&ldquo;Website&rdquo;) en die toegankelijk is via de LookLook&reg; app voor
        mobiele apparaten (&ldquo;Mobiele App&rdquo;), onderworpen aan de beperkingen in deze
        Servicevoorwaardenovereenkomst (&ldquo;SVO&rdquo;). Deze SVO is een juridisch bindend contract tussen
        U en SPARK en regelt de toegang en het gebruik van de aangeboden diensten en alle informatie, inhoud,
        media, gedrukte materialen en overige elektronische documentatie toegankelijk vanaf </span
      ><span class="c13">www.spark-nyc.com</span><span>, </span><span class="c13">admin.looklook.app</span
      ><span>&nbsp;en </span><span class="c13">looklook.app</span><span>, </span
      ><span class="c13">www.looklook-app.com</span><span>, </span
      ><span class="c13">www.looklook-analysis.com</span
      ><span class="c0"
        >, of elk subdomein daarvan (waarvan elk deel uitmaakt van de Website), alsmede het gebruik van de
        aangeboden diensten, en alle informatie, inhoud, media, gedrukte materialen en overige elektronische
        documentatie die toegankelijk is via de Mobiele App, ongeacht of dergelijke materialen of diensten
        worden aangeboden in een openbaar toegankelijk of priv&eacute; (bijv. alleen voor leden) gedeelte van
        de Website of de Mobiele App.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c6">BELANGRIJKE MEDEDELING:</span><span class="c19">&nbsp;</span
      ><span class="c0"
        >HOUD ER REKENING MEE DAT DE HIERONDER UITEENGEZETTE ARBITRATIEBEPALING, DIE, BEHALVE WAAR EN VOOR
        ZOVER NIET UITGESLOTEN DOOR WETGEVING, VAN U VEREIST DAT U EVENTUELE CLAIMS TEN OPZICHTE VAN SPARK OP
        INDIVIDUELE BASIS LAAT ARBITREREN. ARBITRAGE OP EEN INDIVIDUELE BASIS HOUDT IN DAT U GEEN RECHT HEBT
        OP, EN DAARVAN AFZIET, OP EEN RECHTER OF EEN JURY DIE OORDEELT OVER UW CLAIMS, EN DAT U GEEN PROCES
        MAG VOEREN OP BASIS VAN GEZAMENLIJKHEID OF IN DE HOEDANIGHEID VAN EEN VERTEGENWOORDIGER.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >De Website en de Mobiele App worden gratis door SPARK aan Gebruikers aangeboden onder de
        uitdrukkelijke voorwaarde dat de Gebruikers de SVO en het Privacybeleid accepteren en naleven. Toegang
        en gebruik van de Website en de Mobiele App is onderhevig aan de continue naleving van de SVO en het
        Privacybeleid. SPARK behoudt zich uitdrukkelijk het recht voor om de SVO op elk moment te wijzigen.
        Wanneer wij dit doen, wijzigen we de &quot;Ingangsdatum&quot; zoals hierboven wordt genoemd. U krijgt
        bericht bij elke materi&euml;le aanpassingen of wijzigingen aan de SVO via een prominente melding op
        de Website en de Mobiele App en/of via een meer directe melding, zoals via een e-mailbericht. Door
        voortzetting van het gebruik van de Website en de Mobiele App, of enig gedeelte daarvan, nadat wij een
        dergelijke wijziging hebben aangekondigd, aanvaardt u deze SVO met wijzigingen.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Intellectueel eigendom</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Alle auteursrechtelijk beschermde werken die op de Website worden gehost, of die toegankelijk zijn
        via de Mobiele App, die zijn gemaakt door SPARK of namens hen door medewerkers, agenten of aannemers,
        inclusief maar niet beperkt tot ontwerpen, teksten, geluidsopnamen, beelden, pictogrammen en het
        algemene uiterlijk van de Website of delen daarvan, alsmede de interface en de inhoud van de Mobiele
        App, zijn het volledige en exclusieve eigendom van SPARK en/of zijn dochterondernemingen. De
        publicatie van dergelijke werken op de Website, of de toegang tot dergelijke werken via de Mobiele App
        vormt geen afstandsverklaring van intellectueel eigendom of eigendomsrechten van SPARK, inclusief maar
        niet beperkt tot auteursrechten, handelsmerken, servicemerken, patenten en andere intellectueel
        eigendommen of eigendomsrechten, of een overdracht door implicatie, uitsluiting of anderszins van
        enige dergelijke rechten of enige licentie aan Gebruikers van de Website of de Mobiele App, of aan
        enige derde partij. De inhoud van de Website en de Mobiele App worden beschermd door auteursrecht in
        de V.S. en andere landen, zowel als individuele werken en in sommige gevallen als verzamelingen, en
        door handelsmerkenrecht in de V.S. en andere landen. Door het gebruik van de Website of de Mobiele App
        gaat U ermee akkoord om geen auteursrecht, handelsmerk, eigendoms- of vertrouwelijke informatie daarin
        te gebruiken op een manier die niet consistent is met de SVO of die een schending vormt van wetgeving
        betreffende de bescherming van intellectueel eigendom of eigendomsrechten in de V.S. of andere
        landen</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >We bieden U toegang tot, en het gebruik van de Website en de Mobiele App in overeenstemming met een
        beperkte, persoonlijke, niet-exclusieve, niet-sublicentieerbare, niet-overdraagbare, herroepbare
        licentie. U kunt de Website en de Mobiele App gebruiken voor persoonlijk, niet-commercieel gebruik en
        in overeenstemming met deze SVO. Eventuele goodwill die ontstaat uit Uw gebruik van de Website en de
        Mobiele App komt ten gunste van SPARK. Deze licentie is voor U beschikbaar zo lang U niet bent
        geblokkeerd van de Website en de Mobiele App door geldend recht en Uw toegang niet door ons is
        be&euml;indigd. Als deze SVO niet afdwingbaar is op Uw locatie, kunt U de Website en de Mobiele App
        wellicht niet gebruiken. SPARK behoudt alle rechten, aanspraken en belangen die niet uitdrukkelijk
        worden toegekend onder deze licentie voor zover toegelaten door geldende wetten.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Geen materiaal van de Website of de Mobiele App mag worden gekopieerd, gereproduceerd, weergegeven,
        opnieuw gepubliceerd, onderworpen aan reverse engineering, gedownload, gepost, gedigitaliseerd,
        vertaald, gemodificeerd, verzonden, gedistribueerd of commercieel worden ge&euml;xploiteerd op enige
        manier zonder uitdrukkelijke toestemming van SPARK. U gaat akkoord met naleving van redelijke
        schriftelijke verzoeken van ons om ons te helpen bij het beschermen van onze eigendoms- en
        intellectuele eigendomsrechten.</span
      >
    </p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2"><span class="c4">Door gebruikers gegenereerde inhoud</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Gebruikers kunnen toestemming krijgen voor publicatie of indiening van door gebruikers gegenereerde
        inhoud op de Website of bij het gebruik van de Mobiele App, zoals beoordelingen, antwoorden op
        enqu&ecirc;tes en overige informatie aangevraagd door SPARK. Door gebruikers gegenereerde inhoud
        omvat, zonder beperkingen, posts, profielen, blogs, e-mail, bestanden, foto&#39;s, video&#39;s, en
        overige tekst, afbeeldingen of andere inhoud die worden ingediend op, of gepubliceerd door Gebruikers
        van de Website of de Mobiele App (bijv. het gebruik van velden in elektronische formulieren, scripts
        of toepassingen die ter beschikking worden gesteld aan Gebruikers van de Website of die toegankelijk
        zijn via de Mobiele App).</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Door het indienen of publiceren van door gebruikers gegenereerde inhoud op de Website of door het
        gebruik van de Mobiele App, geeft U uitdrukkelijk toestemming aan SPARK voor het gebruik van
        dergelijke inhoud, inclusief maar niet beperkt tot voor openbare weergave, herformattering, opname in
        advertenties en andere werken, het maken van afgeleide werken ervan, promotie en distributie. Als
        zodanig geeft U hierbij SPARK onherroepelijk het wereldwijde, niet-exclusieve, royalty-vrije,
        sub-licentieerbare en overdraagbare recht om dergelijke inhoud te gebruiken voor willekeurige
        commerci&euml;le of niet-commerci&euml;le doeleinden. U geeft tevens SPARK onherroepelijk het recht om
        naar haar eigen goeddunken willekeurige door gebruikers gegenereerde inhoud te delen met andere
        Gebruikers van de Website of de Mobiele App en met dochterondernemingen, klanten en commerci&euml;le
        partners van SPARK. Ten slotte doet U onherroepelijk afstand van enige claims ten opzichte van SPARK
        of enige andere partij (inclusief andere Gebruikers) en verklaringen van morele rechten of
        toekenningen met betrekking tot dergelijke inhoud. De term &quot;gebruiken&quot; in deze context moet
        uitdrukkelijk worden verstaan inclusief het recht om te gebruiken, kopi&euml;ren, openbaar uit te
        voeren of te tonen, distribueren, modificeren, vertalen en afgeleide werken op basis van dergelijke
        inhoud te maken.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >U gaat er uitdrukkelijk mee akkoord dat elke door gebruikers gegenereerde inhoud die U publiceert op
        de Website of indient via de Mobiele App niet vals, opzettelijk misleidend, beledigend, kwetsend,
        vulgair, haatdragend, intimiderend, obsceen, heiligschennend, seksueel geori&euml;nteerd, bedreigend,
        een aantasting van iemands privacy of op andere wijze een schending van wetgeving vormt. Verder
        bevestigt U uitdrukkelijk dat dergelijke inhoud geen schending vormt van: rechten van derden,
        inclusief enig auteursrecht, handelsmerk, patent, handelsgeheim, moreel recht, vertrouwensrecht,
        privacyrecht, recht op openbaarheid, of enig ander recht op intellectueel eigendom of eigendomsrecht;
        geen materiaal bevat dat onwettig is, inclusief illegale haatzaaiing of pornografie; minderjarigen
        niet exploiteert of op andere wijze schade toebrengt; of enige wetgeving in de V.S. schendt of
        schending bevordert. U kunt zichzelf blootstellen aan aansprakelijkheid door niet aan deze eisen te
        voldoen, waarbij SPARK geen enkele verantwoordelijkheid draagt voor enige dergelijke door gebruikers
        gegenereerde inhoud. Bovendien kunnen Gebruikers, die door gebruikers gegenereerde inhoud publiceren
        die deze voorwaarden of andere eisen van de SVO schendt, worden verwijderd van de Website of de
        Mobiele App, of worden onderworpen aan beperkte toegang tot de Website of de Mobiele App (of tot
        diensten aangeboden door de Website of de Mobiele App), zonder waarschuwing en naar exclusief
        goeddunken van SPARK.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Gebruikers bevestigen en stemmen toe dat SPARK niet verantwoordelijk is voor het bewaken van door
        gebruikers gegenereerde inhoud. SPARK en haar vertegenwoordigers hebben echter het recht (maar niet de
        verplichting) naar eigen goeddunken door gebruikers gegenereerde inhoud die beschikbaar is op de
        Website of de Mobiele App, of op diensten die worden aangeboden in verband met de Website of de
        Mobiele App, vooraf te controleren, te weigeren, te bewerken of te verwijderen. Gebruikers gaan verder
        akkoord met het aanvaarden van alle risico&#39;s in verband met het gebruik van enige door gebruikers
        gegenereerde inhoud, inclusief elk vertrouwen in de nauwkeurigheid, volledigheid of bruikbaarheid van
        dergelijke inhoud.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >In overeenstemming met de U.S. Childrens&rsquo; Online Privacy Protection Act of 1998 (COPPA) mogen
        Gebruikers geen door gebruikers gegenereerde inhoud bedoeld voor kinderen jonger dan 13 jaar op de
        Website of de Mobiele App publiceren zonder de uitdrukkelijke schriftelijke toestemming van SPARK. Het
        is gebruikers ook niet toegestaan om persoonsgegevens te verzamelen van kinderen jonger dan 13 jaar. U
        gaat er uitdrukkelijk mee akkoord om te handelen in overeenstemming met deze voorwaarden als U door
        gebruikers gegenereerde inhoud publiceert op de Website of door gebruikers gegenereerde inhoud indient
        via de Mobiele App.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Externe links</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >SPARK kan links op de Website of in de Mobiele App aanbieden naar andere internetpagina&#39;s of
        hulpbronnen. SPARK kan ook Gebruikers toestaan om links te posten naar andere internetpagina&#39;s of
        hulpbronnen (bijv. in door gebruikers gegenereerde inhoud). In beide gevallen is SPARK, haar
        dochterondernemingen of agenten, of enige andere partij die betrokken is bij de voorbereiding of
        publicatie van de Website of de Mobiele App niet verantwoordelijk voor de inhoud van enige gelinkte
        website of enige link opgenomen in een gelinkte website, noch beveelt SPARK dergelijke links of ander
        gerelateerd materiaal aan. Het is de verantwoordelijkheid van Gebruikers om voorzorgsmaatregelen te
        nemen als zij gelinkte websites of inhoud gehost op websites van derden openen.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">GEEN FRAMEN</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Zonder de voorafgaande schriftelijke toestemming van SPARK mag U geen inhoud van de Website en de
        Mobiele App opnemen in frames, of de schijn wekken dat deze door een website van derden wordt
        gepresenteerd of aanbevolen, noch enig intellectueel eigendom van de Website of de Mobiele App, SPARK
        of een van haar licentienemers opnemen in een andere website of overige dienst. Wij behouden ons het
        recht voor om onze toestemming zonder kennisgeving in te trekken.</span
      >
    </p>
    <p class="c1 c11"><span class="c4"></span></p>
    <p class="c2 c11"><span class="c4">Registratie</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Voor het verkrijgen van toegang tot bepaalde delen of functionaliteit van de Website of de Mobiele
        App (bijv. een voor leden gereserveerd gedeelte) moeten de Gebruikers mogelijk een account
        registreren. U bent verantwoordelijk voor het behouden van de vertrouwelijkheid van de inloggegevens
        van Uw account en de aanvullende persoonsgegevens. U zal ons in kennis stellen van enig bekend of
        vermoed ongeautoriseerd gebruik van Uw account. U gaat ermee akkoord dat U verantwoordelijk bent voor
        alle handelingen of omissies die plaatsvinden op Uw account terwijl Uw wachtwoord wordt gebruikt. Voor
        zover toegestaan door geldende wetgeving is SPARK niet verantwoordelijk voor enig verlies van
        activiteit die ontstaat door het ongeautoriseerd gebruik van Uw account. Geregistreerde Gebruikers van
        voor leden gereserveerde gedeelten moeten alle in de SVO beschreven vereisten naleven, inclusief maar
        niet beperkt tot aanvaardbare gebruiksvoorwaarden in verband met door gebruikers gegenereerde inhoud
        zoals hiervoor is beschreven.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Uw toegang tot dergelijke voor leden gereserveerde gedeelten, inhoud of overige functionaliteit van
        de Website of de Mobiele App waar registratie voor is vereist, wordt uitsluitend door SPARK verleend
        naar eigen goeddunken. Voor wat betreft deze toegang verklaart U dat U de wettelijke leeftijd hebt
        bereikt voor het aangaan van een bindend contract en dat U niet bent uitgesloten van het ontvangen van
        diensten onder de wetten van de V.S. of enige andere van toepassing zijnde wetgeving. U gaat verder
        akkoord met: (a) het verstrekken van waarachtige, actuele en complete informatie over Uzelf indien
        daarom verzocht en (b) het bijhouden en tijdig bijwerken van Uw informatie om deze waarachtig,
        nauwkeurig, actueel en compleet te houden. Indien u enige informatie verstrekt die onwaar,
        onnauwkeurig, niet actueel of incompleet is, of als SPARK redelijke gronden heeft om aan te nemen dat
        dergelijke informatie onwaar, onnauwkeurig, niet actueel of incompleet is, kan SPARK naar eigen
        goeddunken Uw toegang tot dergelijke voor leden gereserveerde gedeelten, inhoud of functionaliteit
        opschorten of be&euml;indigen en huidig en toekomstig gebruik van de Website of de Mobiele App (of
        gedeelten daarvan) weigeren.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >U bevestigt, stemt toe en gaat ermee akkoord dat SPARK Uw account-informatie en door gebruikers
        gegenereerde inhoud mag openen, opslaan en openbaren, indien vereist door wetgeving of als SPARK van
        mening is dat dergelijke opening, opslag of openbaarmaking redelijkerwijs noodzakelijk is om (a) te
        voldoen aan juridische processen; (b) de vereisten van de SVO af te dwingen; (c) claims te
        beantwoorden waarin wordt gesteld dat dergelijke inhoud inbreuk maakt op de rechten van derden; (d) Uw
        verzoeken aan de klantenservice te beantwoorden; of (e) de rechten, eigendommen of persoonlijke
        veiligheid van SPARK, andere Gebruikers of het publiek te beschermen.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Gebruikersgedrag </span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Naast eventuele andere algemene richtlijnen voor door gebruikers gegenereerde inhoud die hier worden
        gegeven, gaan geregistreerde Gebruikers uitdrukkelijk akkoord met het niet uploaden, publiceren,
        posten, e-mailen, overdragen noch anderszins beschikbaar maken op de Website of de Mobiele App van
        enige inhoud die:</span
      >
    </p>
    <p class="c2 c12">
      <span class="c0"
        >(a) onwettig, schadelijk, bedreigend, kwetsend, intimiderend, onrechtmatig, beledigend, vulgair,
        obsceen, lasterlijk, inbreuk maakt op de persoonlijke levenssfeer, haatdragend, of raciaal, etnisch of
        anderszins verwerpelijk is;</span
      >
    </p>
    <p class="c2 c12">
      <span class="c0"
        >(b) inbreuk maakt op patenten, handelsmerken, handelsgeheimen, auteursrechten of andere
        eigendomsrechten van derden;</span
      >
    </p>
    <p class="c2 c12">
      <span class="c0"
        >(c) niet rechtmatig door de Gebruiker beschikbaar mag worden gesteld (bijv. eigendomsinformatie en
        vertrouwelijke informatie verkregen of geopenbaard als onderdeel van werknemersrelaties of onder
        geheimhoudingsovereenkomsten);</span
      >
    </p>
    <p class="c2 c12">
      <span class="c0"
        >(d) ongevraagde of ongeautoriseerde reclame, promotiematerialen (bijv. junkmail, spam of andere
        vormen van aanbiedingen) bevat; of</span
      >
    </p>
    <p class="c2 c12">
      <span class="c0"
        >(e) softwarevirussen of andere computercode, bestanden of programma&#39;s bevat die zijn bedoeld voor
        verstoring, vernietiging of beperking van de functionaliteit van computersoftware of -hardware.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Gebruikers gaan verder akkoord om: (a) zich niet valselijk voor te doen als andere personen of
        entiteiten, inclusief maar niet beperkt tot een SPARK-medewerker of forumbeheerder, of niet frauduleus
        Uw verbinding met een persoon of entiteit te verklaren of anderszins foutief voor te stellen; (b) om
        geen andere geregistreerde Gebruikers van de Website of de Mobiele App te stalken of te intimideren;
        of (c) geen persoonsgegevens van andere Gebruikers te verzamelen of op te slaan.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Accounts van geregistreerde Gebruikers die bepalingen van de SVO schenden, of die inhoud op de
        Website of de Mobiele App uploaden, publiceren, posten, e-mailen, overdragen of anderszins beschikbaar
        maken die als verwerpelijk, haatzaaiend of kwetsend wordt beschouwd voor andere geregistreerde
        Gebruikers of SPARK-personeel, kunnen door SPARK worden beperkt, opgeschort of be&euml;indigd. De
        bepaling of inhoud wordt beschouwd als verwerpelijk, haatzaaiend of kwetsend wordt door SPARK naar
        eigen goeddunken gemaakt.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Leeftijdsbegrenzing</span></p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2">
      <span class="c0"
        >De Website en de Mobiele App zijn niet bedoeld voor gebruik door kinderen jonger dan 13 jaar. SPARK
        verzoekt of vraagt niet moedwillig naar persoonlijk identificeerbare informatie van personen jonger
        dan 13 jaar. Als SPARK desondanks kennis ontvangt dat zij dergelijke persoonsgegevens heeft verzameld,
        zal SPARK deze informatie van de Website of de Mobiele App verwijderen zo snel als redelijkerwijs
        praktisch mogelijk is.</span
      >
    </p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2"><span class="c4">Privacy</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >SPARK kan persoonlijke of samengevoegde informatie verzamelen over Gebruikers van de Website of de
        Mobiele App in overeenstemming met ons Privacybeleid [hier]. Raadpleeg ons Privacybeleid voor meer
        informatie over onze privacypraktijken.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4">Kennisgeving van claims betreffende schendingen van intellectueel eigendom</span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >SPARK voldoet aan de bepalingen van de Digital Millennium Copyright Act (DMCA). Zoals op een andere
        plaats reeds is beschreven, kan er op de Website en/of de Mobiele App af en toe door gebruikers
        gegenereerde inhoud worden weergegeven of inhoud op de Website en/of de Mobiele App worden
        gepubliceerd door derden (bijv. partners of dochterondernemingen van SPARK). SPARK geeft geen
        toestemming noch autoriseert zij het gebruik van inhoud op de Website of de Mobiele App die inbreuk
        maakt op de intellectueel eigendomsrechten of overige eigendomsrechten van anderen, en zal dergelijke
        inhoud verwijderen indien correct op de hoogte gesteld in overeenstemming met de procedure zoals
        uiteengezet in de SVO. Als U vindt dat enige inhoud op de Website en/of de Mobiele App Uw auteursrecht
        of handelsmerkrechten, Uw publiciteits- of andere eigendomsrechten schendt, neem dan contact op met de
        door SPARK aangewezen agent voor de kennisgeving van claims betreffende schending van auteursrecht of
        andere eigendomsrechten. Deze agent kan worden bereikt via de onderstaande contactgegevens:</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Malinda Sanna</span></p>
    <p class="c2"><span class="c0">malinda@spark-nyc.com</span></p>
    <p class="c2"><span class="c0">T.a.v.: DMCA-kennisgeving</span></p>
    <p class="c2"><span class="c0">1328 Ridge Road</span></p>
    <p class="c2"><span class="c0">Laurel Hollow, NY. 11791</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Voeg de hieronder genoemde informatie aan Uw verzoek toe om de verwerking te versnellen:</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0 start" start="1">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Een fysieke of elektronische handtekening van een persoon die is gemachtigd om op te treden namens
          de eigenaar van ten minste &eacute;&eacute;n exclusief recht dat vermoedelijk wordt
          geschonden.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0" start="2">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Een identificatie van het door auteursrecht beschermde werk, handelsmerk of eigendomsrecht waarvan
          de inbreuk wordt geclaimd, of, als U vindt dat meerdere werken, merken of eigendomsrechten zijn
          geschonden op de Website en/of de Mobiele App, een representatieve lijst van deze werken,
          handelsmerken of eigendomsrechten.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0" start="3">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Een identificatie van het gepubliceerde materiaal dat vermoedelijk inbreuk maakt, of op andere
          wijze onderwerp is van inbreuk makende activiteiten, waarvoor U om verwijdering verzoekt, en
          informatie die in redelijkheid voldoende is voor SPARK om dergelijk materiaal te kunnen
          opsporen.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0" start="4">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Informatie die in redelijkheid voldoende is voor SPARK om met U in contact te kunnen treden (bijv.
          een adres, telefoonnummer of e-mail-adres) met betrekking tot het verzoek.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0" start="5">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Een verklaring dat U ter goeder trouw van mening bent dat het gebruik van het materiaal op de
          aangeklaagde manier niet is geautoriseerd door de eigenaar, de agent of anderszins wettelijk is
          toegestaan.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_1-0" start="6">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Een verklaring dat de informatie in de kennisgeving nauwkeurig is, en dat U, op straffe van
          meineed, bent gemachtigd om op te treden namens de eigenaar van het intellectueel eigendom of
          eigendomsrecht dat vermoedelijk wordt geschonden.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Na ontvangst van een verzoek met bovengenoemde informatie, zal SPARK een onderzoek instellen en
        passende maatregelen nemen om inhoud, waarvan wordt vastgesteld dat deze rechten van derden schendt of
        overtreedt, te verwijderen.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >We kunnen onze Gebruikers inlichten door middel van een algemeen bericht op onze Website en/of
        Mobiele App, elektronische mail naar een e-mail-adres van een Gebruiker in onze bestanden of een
        geschreven communicatie die per post naar het fysieke postadres van een Gebruiker zoals dat in onze
        gegevens bekend is. Als U een dergelijke kennisgeving ontvangt, kunt U een schriftelijke
        tegen-kennisgeving verstrekken aan de aangewezen agent, waarin de onderstaande informatie is
        opgenomen. Vanwege de effectiviteit moet de tegen-kennisgeving bestaan uit een schriftelijke
        communicatie die het volgende bevat:
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c10 lst-kix_list_3-0 start" start="1">
      <li class="c2 c9 li-bullet-1"><span class="c0">Uw fysieke of elektronische handtekening;</span></li>
      <li class="c2 c9 li-bullet-1">
        <span class="c0"
          >Identificatie van het materiaal dat is verwijderd of waarvoor toegang is geblokkeerd, en de locatie
          waar het materiaal aanwezig was voordat het werd verwijderd of voordat de toegang werd
          geblokkeerd;</span
        >
      </li>
      <li class="c2 c9 li-bullet-1">
        <span class="c0"
          >Een verklaring van U, op straffe van meineed, dat U te goeder trouw van mening bent dat het
          materiaal is verwijderd of geblokkeerd als gevolg van een fout of van misidentificatie van het te
          verwijderen of blokkeren materiaal; en</span
        >
      </li>
      <li class="c2 c9 li-bullet-1">
        <span class="c0"
          >Uw naam, fysieke adres, telefoonnummer, en een verklaring dat U instemt met de jurisdictie van een
          federale rechtbank voor het rechtsgebied waar uw fysieke adres is gelocaliseerd, of als Uw fysieke
          adres zich buiten de V.S. bevindt, met een rechtsgebied waar wij mogelijk aanwezig zijn, en dat U
          dat u een betekening accepteert van de persoon die de kennisgeving verstrekt van het vermoedelijke
          inbreukmakende materiaal of een agent van een dergelijke persoon.</span
        >
      </li>
    </ol>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Vrijwaring van garantie en aansprakelijkheid</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >U begrijpt dat SPARK, behalve voor informatie, producten of diensten die duidelijk aanwijsbaar door
        SPARK zijn verstrekt, geen materiaal, informatie, producten of diensten op de Website of toegankelijk
        via de Mobiele App beoordeelt, bedrijft of beheert. Voor zover toegelaten door geldende wetten en
        behalve voor rechten toegekend aan consumenten onder van toepassing zijnde wetten, worden de Website
        en de Mobiele App ter beschikking gesteld op basis van &#39;als zodanig&#39; en &#39;zoals
        beschikbaar&#39;. SPARK verstrekt expliciet noch impliciet enige garantie betreffende de
        beschikbaarheid, het bedrijf of het beheer van de Website of de Mobiele App noch betreffende de
        informatie of inhoud op beide platforms. SPARK verstrekt expliciet noch impliciet enige garantie,
        representaties of aanbevelingen betreffende door gebruikers gegenereerde inhoud die wordt gepubliceerd
        op de Website of die toegankelijk wordt gemaakt op de Mobiele App, overige websites die worden geopend
        via op de Website of de Mobiele App geposte links, of in het algemeen op internet. Voor zover
        toegelaten door geldende wetten wijst SPARK iedere garantie af, expliciet of impliciet, inclusief maar
        niet beperkt tot de impliciete garanties van verkoopbaarheid en geschiktheid voor een bepaald doel.
        SPARK geeft geen garantie dat de Website en de Mobiele App zullen voldoen aan vereisten van
        Gebruikers, of dat deze foutvrij, betrouwbaar, zonder onderbrekingen of op elk moment beschikbaar
        zullen zijn. SPARK geeft geen garantie dat de diensten die kunnen worden verkregen uit het gebruik van
        de Website en de Mobiele App, inclusief ondersteuningsdiensten, effectief, betrouwbaar en nauwkeurig
        zullen zijn, noch dat deze zullen voldoen aan vereisten van Gebruikers. SPARK geeft geen garantie dat
        U toegang zult hebben tot, of gebruik kunt maken van de Website en de Mobiele App (rechtstreeks of via
        netwerken van derden) op elk moment of vanaf locaties van Uw keuze.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Voor zover toegelaten door geldende wetten en behalve voor rechten toegekend aan consumenten onder
        van toepassing zijnde wetten, is SPARK niet aansprakelijk voor enige schade als gevolg van het gebruik
        of van het niet kunnen gebruiken van de Website of de Mobiele App. U gaat er uitdrukkelijk mee akkoord
        dat U de Website en de Mobiele App gebruikt uitsluitend op eigen risico.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >In geen enkel geval is SPARK aansprakelijk voor letsel, verliezen, claims, schade of enige
        incidentele of gevolgschade, direct of indirect ontstaan uit of op enige manier gerelateerd aan de
        Website of de Mobiele App, of enige fout of vertraging in het bijwerken of opnemen van materialen op
        de Website of de Mobiele App, of enig gebruik van, of niet gebruik van materialen op de Website of de
        Mobiele App, zelfs als SPARK in kennis is gesteld van de mogelijkheid van dergelijke schades.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >In sommige rechtsgebieden wordt het uitsluiten van bepaalde garanties niet toegestaan, dus wellicht
        zijn de bovenstaande uitsluitingen niet voor u van toepassing.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c4"
        >Het recht om toegang te beperken, inhoud te verwijderen en de dienst te be&euml;indigen</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >SPARK heeft het recht om de toegang van Gebruikers tot de Website of de Mobiele App te beperken,
        inclusief individuele gebieden of diensten die door de Website of de Mobiele App worden geleverd, om
        welke reden ook. SPARK heeft het recht (doch niet de verplichting) om naar eigen goeddunken en op elk
        moment elke door gebruikers gegenereerde inhoud te weigeren of te verwijderen, die een schending vormt
        van de SVO of van enig SPARK-beleid, of van geldende wetgeving. SPARK behoudt zich tevens het recht
        voor om de Website of de Mobiele App te wijzigen, op te schorten of te be&euml;indigen, met of zonder
        kennisgeving, op elk moment en zonder aansprakelijkheid ten opzichte van Gebruikers van de Website of
        de Mobiele App.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2 c11"><span class="c4">Geschillenbeslechting en overeenstemming tot arbitrage</span></p>
    <p class="c1 c11"><span class="c4"></span></p>
    <p class="c2 c11">
      <span class="c0"
        >Behalve waar en voor zover toegelaten door geldende wetgeving, komen U en SPARK door het gebruik van
        de Website en/of de Mobiele App overeen dat in gevallen van onenigheid, claims, acties of geschillen
        voortkomende uit of in verband met Uw gebruik van de Website en/of de Mobiele App, of van de
        schending, handhaving, interpretatie of geldigheid van deze SVO of enig gedeelte daarvan
        (&quot;Geschil&quot;), beide partijen eerst in goed vertrouwen zullen trachten om een dergelijk
        Geschil te beslechten door een schriftelijke kennisgeving aan de andere partij te verstrekken waarin
        de feiten en omstandigheden van het Geschil worden beschreven en door de ontvangende partij een
        termijn van dertig (30) dagen toe te kennen voor het oplossen van het Geschil. Kennisgevingen aan ons
        moeten worden gestuurd naar:</span
      >
    </p>
    <p class="c1 c11"><span class="c0"></span></p>
    <p class="c2 c11"><span class="c0">Spark Ideas, LLC</span></p>
    <p class="c2 c11"><span class="c0">1328 Ridge Road</span></p>
    <p class="c2 c11"><span class="c0">Syosset, New York 11791</span></p>
    <p class="c1 c11"><span class="c0"></span></p>
    <p class="c2 c11">
      <span class="c0"
        >Zowel U als SPARK gaan ermee akkoord dat deze geschillenbeslechtingsprocedure een voorwaarde is
        waaraan moet worden voldaan, voordat enige procesvoering wordt gestart of claims tegenover de andere
        partij worden ingesteld. ALS EEN GESCHIL NIET KAN WORDEN OPGELOST DOOR BOVENGENOEMDE
        GESCHILLENBESLECHTINGSPROCEDURE, GAAT U ERMEE AKKOORD DAT HET EXCLUSIEVE RECHTSGEBIED VOOR DERGELIJK
        GESCHIL ZAL WORDEN BEPAALD DOOR BINDENDE ARBITRAGE OP INDIVIDUELE BASIS. ARBITRAGE OP EEN INDIVIDUELE
        BASIS HOUDT IN DAT U GEEN RECHT HEBT OP, EN DAARVAN AFZIET, OP EEN RECHTER OF EEN JURY DIE OORDEELT
        OVER UW CLAIMS, EN DAT U GEEN PROCES MAG VOEREN OP BASIS VAN GEZAMENLIJKHEID OF IN DE HOEDANIGHEID VAN
        EEN VERTEGENWOORDIGER. Overige rechten die U en wij anders in een rechtbank zouden hebben, zijn niet
        beschikbaar of zijn beperkt in arbitrage, inclusief het recht op ontdekking en beroep. Alle dergelijke
        geschillen zullen exclusief worden ingediend bij JAMS (www.jamsadr.com) voor bindende arbitrage onder
        haar dan geldende regels, voor &eacute;&eacute;n arbiter, met welke beide partijen onderling moeten
        instemmen. De arbiter zal eventueel hoorzittingen houden, per teleconferentie of videoconferentie, in
        plaats van persoonlijke zittingen, tenzij de arbiter op verzoek van U of van ons bepaalt dat een
        zitting in persoon passend is. Eventuele persoonlijke zittingen zullen worden gehouden op een locatie
        die gunstig is gelegen voor beide partijen met inachtneming van hun mogelijkheden om te reizen en
        andere ter zake doende omstandigheden. Mochten de partijen geen overeenstemming over de locatie kunnen
        bereiken, zal deze worden bepaald door JAMS of door de arbiter.</span
      >
    </p>
    <p class="c1 c11"><span class="c0"></span></p>
    <p class="c2 c11">
      <span class="c0"
        >De arbiter, en geen federale, nationale of lokale rechtbank of bureau, heeft de exclusieve autoriteit
        voor oplossing van elk geschil dat ontstaat door, of dat in verband staat met de interpretatie,
        toepasbaarheid, afdwingbaarheid of opstelling van deze SVO, inclusief elke claim die stelt dat deze
        SVO of een deel daarvan nietig of vernietigbaar is.</span
      >
    </p>
    <p class="c1 c11"><span class="c4"></span></p>
    <p class="c2 c11"><span class="c4">Geldend recht</span></p>
    <p class="c1 c11"><span class="c4"></span></p>
    <p class="c2">
      <span class="c0"
        >U gaat ermee akkoord dat, in het geval SPARK geen juridisch recht of rechtsmiddel uitoefent of
        afdwingt zoals opgenomen in de SVO (of waartoe SPARK is gerechtigd onder toepasselijke wetgeving), dit
        niet kan worden opgevat als een afstandsverklaring van deze rechten en rechtsmiddelen.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >De federale rechtbanken in New York, waarbij New York de exclusieve jurisdictie heeft om elk geschil
        te beoordelen dat voortkomt uit, of verband houdt met de SVO, inclusief de opstelling als een contract
        of de handhaving. Elke partij stemt hierbij toe en onderwerpt zich aan de exclusieve jurisdictie van
        dergelijke rechtbanken. Elke partij doet hierbij tevens afstand van enige rechten op juryrechtspraak
        in verbinding met enige handelingen of procesvoering voortkomende uit, of in verband met de SVO. Bij
        elke handeling of procesvoering waarbij rechten onder de SVO worden afgedwongen, heeft de winnende
        partij het recht op compensatie van zijn redelijke kosten en advocaathonoraria.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Scheidbaarheid</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >In het geval dat een of meer bepalingen van deze SVO als ongeldig, illegaal of niet-afdwingbaar
        worden beoordeeld, in enigerlei opzicht en om welke reden ook, zal de geldigheid, rechtmatigheid en
        afdwingbaarheid van een dergelijke bepaling of bepaling in elk ander opzicht en van de resterende
        bepalingen van deze SVO op geen enkele manier worden geschaad.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Be&euml;indiging</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >Deze SVO blijft van toepassing totdat deze door U of door SPARK wordt be&euml;indigd. SPARK mag op
        elk moment de juridische overeenkomst met U in de vorm van deze SVO om welke reden ook be&euml;indigen
        door middel van een kennisgeving. Bovendien behoudt SPARK zich uitdrukkelijk het recht voor om Uw
        recht op toegang tot en gebruik van de Website of de Mobiele App te be&euml;indigen indien U enige
        bepaling van de SVO schendt, als SPARK hiertoe wettelijk verplicht is of als SPARK de Website of de
        Mobiele App niet langer in stand houdt en de dienst be&euml;indigt.
      </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Toekenning</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2">
      <span class="c0"
        >U mag geen van uw rechten of verplichtingen onder deze SVO toekennen, overdragen of
        sub-licenti&euml;ren zonder onze uitdrukkelijke voorafgaande schriftelijke toestemming. Wij mogen
        enige of al uw rechten of verplichtingen onder deze SVO zonder beperkingen toekennen, overdragen of
        sub-licenti&euml;ren.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Kopregels</span></p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2">
      <span class="c0"
        >De kopregels in deze SVO zijn slechts bedoeld voor leesgemak en zijn niet bedoeld ter
        be&iuml;nvloeding van de interpretatie van de SVO.</span
      >
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c4">Contactinformatie</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c20">
      <span class="c0"
        >SPARK verwelkomt Uw vragen of opmerkingen aangaande deze SVO, de Website en de Mobiele App. Als U
        vragen of zorgen hebt, kunt U contact met ons opnemen via:</span
      >
    </p>
    <p class="c7"><span class="c0">Spark Ideas, LLC</span></p>
    <p class="c8"><span class="c0">1328 Ridge Road</span></p>
    <p class="c16"><span class="c0">Syosset, New York 11791</span></p>
    <p class="c7"><span class="c0">Telefoonnummer:</span></p>
    <p class="c2"><span class="c0">(917) 991-0477</span></p>
    <p class="c1"><span class="c0"></span></p>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'TermsOfServiceNL',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.TermsOfServiceNL {
  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }
  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }
  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  .lst-kix_list_3-0 > li:before {
    content: '' counter(lst-ctn-kix_list_3-0, decimal) '. ';
  }
  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }
  .lst-kix_list_3-1 > li:before {
    content: '' counter(lst-ctn-kix_list_3-1, lower-latin) '. ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '' counter(lst-ctn-kix_list_3-2, lower-roman) '. ';
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  .lst-kix_list_3-5 > li:before {
    content: '' counter(lst-ctn-kix_list_3-5, lower-roman) '. ';
  }
  .lst-kix_list_3-4 > li:before {
    content: '' counter(lst-ctn-kix_list_3-4, lower-latin) '. ';
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  .lst-kix_list_3-3 > li:before {
    content: '' counter(lst-ctn-kix_list_3-3, decimal) '. ';
  }
  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-8 > li:before {
    content: '' counter(lst-ctn-kix_list_3-8, lower-roman) '. ';
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  .lst-kix_list_3-6 > li:before {
    content: '' counter(lst-ctn-kix_list_3-6, decimal) '. ';
  }
  .lst-kix_list_3-7 > li:before {
    content: '' counter(lst-ctn-kix_list_3-7, lower-latin) '. ';
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }
  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }
  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }
  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-6 > li:before {
    content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  li.li-bullet-1:before {
    margin-left: -36pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 36pt;
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  .lst-kix_list_1-0 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
  }
  .lst-kix_list_1-1 > li:before {
    content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  .lst-kix_list_1-3 > li:before {
    content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
  }
  .lst-kix_list_1-4 > li:before {
    content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
  }
  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-7 > li:before {
    content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_1-5 > li:before {
    content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_list_1-8 > li:before {
    content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
  }
  ol {
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c5 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 3.6pt;
  }
  .c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: center;
    height: 12pt;
  }
  .c15 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt;
  }
  .c17 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c16 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c7 {
    padding-top: 13.3pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c20 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c13 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
  }
  .c14 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    text-align: left;
  }
  .c6 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    text-decoration: underline;
  }
  .c21 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c10 {
    padding: 0;
  }
  .c3 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c9 {
    margin-left: 36pt;
    padding-left: 18pt;
  }
  .c11 {
    page-break-after: avoid;
  }
  .c19 {
    font-weight: 700;
  }
  .c12 {
    text-indent: 36pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    padding-bottom: 12pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    padding-bottom: 4pt;
    font-family: 'Georgia';
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
  }
  p {
    color: #000000;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 0pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 0pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
