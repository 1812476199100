<template>
  <div class="PollRankedEditor">
    <PollRankedEditorBody
      v-model="localResponse.choiceList"
      :values="poll.rows"
      :hide-text="poll.isLabelsHidden"
      :large-thumbnails="poll.isThumbnailsLarge"
      :locked="locked"
    />
    <SubmitAnswerConfirmation
      v-if="showSubmitConfirmModal"
      :show-modal.sync="showSubmitConfirmModal"
      :title="$t('conversation-confirm-submit-ranked-list-title')"
      :content="$t('conversation-confirm-submit-ranked-list-content')"
      @confirm="onConfirm"
      @cancel="showSubmitConfirmModal = false"
    >
      <template #submit-button-caption>
        {{ $t('button_submit-response') }}
      </template>
    </SubmitAnswerConfirmation>
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollType } from '@/common/enums'
import PollRankedEditorBody from '@/components/modules/poll/ranked/editor/PollRankedEditorBody.vue'
import SubmitAnswerConfirmation from '@/components/conversation/SubmitAnswerConfirmation.vue'

export default {
  name: 'PollRankedEditor',
  components: { SubmitAnswerConfirmation, PollRankedEditorBody },
  props: {
    value: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    realValidation: { type: Boolean, default: false }
  },
  data() {
    return {
      showSubmitConfirmModal: false,
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    sortedRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    PollTypeEnum() {
      return PollType
    },
    isLocalResponseValid() {
      if (this.realValidation) {
        return !!this.localResponse.choiceList.length
      }
      return true
    }
  },
  watch: {
    response: {
      handler() {
        if (
          !this.$_.isEqual(this.response, this.localResponse) &&
          !(this.response === null && this.localResponse.isEmpty)
        ) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      deep: true
    },
    isLocalResponseValid: {
      handler() {
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      immediate: true
    }
  },
  mounted() {
    this.$emit('update:isValid', this.isLocalResponseValid)
  },
  methods: {
    onConfirm() {
      this.localResponse.choiceList = [...this.sortedRows].map((value) => value.id)
      this.$emit('update:response', new PollResponseModel(this.localResponse))
      this.$emit('update:isValid', this.isLocalResponseValid)
      this.$nextTick(() => {
        this.$emit('confirm-send')
      })
      this.showSubmitConfirmModal = false
    },
    send() {
      if (this.localResponse.choiceList.length) {
        this.$emit('confirm-send')
      } else {
        this.showSubmitConfirmModal = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.PollRankedEditor {
}
</style>
