import { v4 as uuidV4 } from 'uuid'

const initialState = () => ({
  infoPopupList: []
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    infoPopupList: (state) => state.infoPopupList
  },
  mutations: {
    ADD_INFO_POPUP(state, { infoPopup }) {
      state.infoPopupList.push(infoPopup)
    },
    REMOVE_INFO_POPUP(state, { id }) {
      state.infoPopupList = state.infoPopupList.filter((infoPopup) => infoPopup.id !== id)
    }
  }
}

export class InfoPopup {
  constructor({ message }) {
    this.id = uuidV4()
    this.message = message
  }
}
