<template>
  <button
    ref="button"
    class="LLButton"
    :type="type"
    :disabled="disabled"
    :class="classList"
    @mouseup="onMouseUp"
    @click="doAction"
  >
    <div class="LLButton__inner">
      <div v-if="slotsVisibility.iconLeft && !loading" class="LLButton__icon-left">
        <slot class="LLButton__icon" name="icon-left"></slot>
      </div>
      <div v-if="loading" class="LLButton__loading">
        <LoadingIcon class="LLButton__loading-icon"></LoadingIcon>
      </div>
      <div v-if="slotsVisibility.default" class="LLButton__caption leading-tight">
        <slot name="default"></slot>
      </div>
      <div v-if="slotsVisibility.iconRight && !loading" class="LLButton__icon-right">
        <slot class="LLButton__icon" name="icon-right"></slot>
      </div>
      <div v-if="withArrow" class="LLButton__arrow">
        <DropdownArrow :opened="arrowOpened" />
      </div>
    </div>
  </button>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
import DropdownArrow from '@/assets/icons/DropdownArrow.vue'
export default {
  name: 'LLButton',
  components: { DropdownArrow, LoadingIcon },
  props: {
    action: { type: Function, default: null },
    type: { type: String, default: 'button' },
    /* button states */
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    withArrow: { type: Boolean, default: false },
    arrowOpened: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    /* button styles */
    primary: { type: Boolean, default: null },
    primaryTransparent: { type: Boolean, default: null },
    secondary: { type: Boolean, default: false },
    darkned: { type: Boolean, default: false },
    tertiary: { type: Boolean, default: false },
    tertiaryDark: { type: Boolean, default: false },
    tertiaryWhite: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    green: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    noXPadding: { type: Boolean, default: false },
    /* button sizes */
    medium: { type: Boolean, default: null },
    small: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false }
  },
  data() {
    return {
      slotsVisibility: {
        iconLeft: false,
        iconRight: false,
        default: false
      }
    }
  },
  computed: {
    classList() {
      const baseClass = 'LLButton'
      const classes = []
      /* button styles */
      if (this.secondary) {
        classes.push(`${baseClass}_secondary`)
      } else if (this.outline) {
        classes.push(`${baseClass}_outline`)
      } else if (this.danger) {
        classes.push(`${baseClass}_danger`)
      } else if (this.darkned) {
        classes.push(`${baseClass}_darkned`)
      } else if (this.tertiary) {
        classes.push(`${baseClass}_tertiary`)
      } else if (this.tertiaryDark) {
        classes.push(`${baseClass}_tertiary-dark`)
      } else if (this.tertiaryWhite) {
        classes.push(`${baseClass}_tertiary-white`)
      } else if (this.green) {
        classes.push(`${baseClass}_green`)
      } else if (this.primaryTransparent) {
        classes.push(`${baseClass}_primary-transparent`)
      } else {
        classes.push(`${baseClass}_primary`)
      }
      if (this.small) {
        classes.push(`${baseClass}_small`)
      } else if (this.tiny) {
        classes.push(`${baseClass}_tiny`)
      } else {
        classes.push(`${baseClass}_medium`)
      }
      if (this.noXPadding) {
        classes.push(`${baseClass}_no-x-padding`)
      }
      if (this.round) {
        classes.push(`${baseClass}_round`)
      }
      if (this.disabled) {
        classes.push(`${baseClass}_disabled`)
      }
      if (this.active) {
        classes.push(`${baseClass}_active`)
      }
      if (this.loading) {
        classes.push(`${baseClass}_loading`)
      }
      if (!this.slotsVisibility.default) {
        classes.push(`${baseClass}_no-caption`)
      }
      return classes
    }
  },
  created() {
    this.checkSlotsVisibility()
  },
  beforeUpdate() {
    this.checkSlotsVisibility()
  },
  methods: {
    onMouseUp() {
      if (this.$refs?.button) {
        this.$refs.button.blur()
      }
    },
    doAction(e) {
      if (!this.disabled && !this.loading) {
        this.$emit('action')
        this.$emit('click', e)
        if (this.action) {
          this.action()
        }
      }
    },
    checkSlotsVisibility() {
      this.$nextTick(() => {
        this.slotsVisibility.iconLeft = !!this.$slots?.['icon-left']?.[0]
        this.slotsVisibility.iconRight = !!this.$slots?.['icon-right']?.[0]
        this.slotsVisibility.default = !!this.$slots?.default?.[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLButton {
  $root: &;
  & {
    @apply inline-flex align-top items-center justify-center cursor-pointer transition-colors duration-200 select-none rounded;
    &__inner {
      @apply flex items-center min-w-0;
    }
    &__icon-left {
    }
    &__icon-right {
    }
    &__arrow {
      @apply transform;
    }
    &__loading-icon {
      @apply h-5 w-5;
    }
    &__loading {
    }
    &:active {
      outline: none;
    }
    &__caption {
      @apply truncate min-w-0;
    }
  }

  &_disabled {
    @apply cursor-default;
  }
  &_loading {
    @apply cursor-default;
  }

  &_medium {
    @apply h-11 px-4 text-button-01;
    #{$root}__icon-left {
      @apply mr-2;
    }
    #{$root}__icon-right {
      @apply ml-2;
    }
    #{$root}__arrow {
      @apply ml-2.5;
    }
    #{$root}__loading {
      @apply mr-2;
    }
    &#{$root}_no-caption {
      @apply w-11;
    }
  }
  &_small {
    @apply h-8 px-4 text-button-02;
    #{$root}__icon-left {
      @apply mr-1.5;
    }
    #{$root}__icon-right {
      @apply ml-1.5;
    }
    #{$root}__arrow {
      @apply ml-2;
    }
    #{$root}__loading {
      @apply mr-1.5;
    }
    &#{$root}_no-caption {
      @apply w-8;
    }
  }

  &_tiny {
    @apply h-6 px-4 text-button-02;
    #{$root}__icon-left {
      @apply mr-1.5;
    }
    #{$root}__icon-right {
      @apply ml-1.5;
    }
    #{$root}__arrow {
      @apply ml-2;
    }
    #{$root}__loading {
      @apply mr-1.5;
    }
    &#{$root}_no-caption {
      @apply w-7;
    }
  }

  &_no-x-padding {
    @apply px-0;
  }

  &_no-caption {
    @apply px-0;
    #{$root}__icon-left {
      @apply mr-0;
    }
    #{$root}__icon-right {
      @apply ml-0;
    }
  }
  &_round {
    @apply rounded-full;
  }

  &_primary {
    & {
      @apply bg-primary-01-400 text-white;
    }
    &:hover {
      @apply bg-primary-01-300;
    }
    &:focus {
      @apply bg-primary-01-300 outline-none;
    }
    &:active {
      @apply bg-primary-01-600;
    }
    &#{$root}_disabled {
      @apply bg-neutral-01-75;
    }
    &#{$root}_loading {
      @apply bg-neutral-01-75;
    }
  }
  &_primary-transparent {
    & {
      @apply text-primary-01-400;
    }
    &:hover {
      @apply text-primary-01-200;
    }
    &:focus {
      @apply text-primary-01-200 outline-none;
    }
    &:active {
      @apply text-primary-01-600;
    }
    &#{$root}_disabled {
      @apply text-neutral-01-75;
    }
    &#{$root}_loading {
      @apply text-neutral-01-75;
    }
  }
  &_danger {
    & {
      @apply bg-status-03-600 text-white;
    }
    &:hover {
      @apply bg-status-03-500;
    }
    &:focus {
      @apply bg-status-03-500 outline-none;
    }
    &:active {
      @apply bg-status-03-700;
    }
    &#{$root}_disabled {
      @apply bg-neutral-01-75;
    }
    &#{$root}_loading {
      @apply bg-neutral-01-75;
    }
  }
  &_green {
    & {
      @apply bg-status-01-500 text-white;
    }
    &:hover {
      @apply bg-status-01-400;
    }
    &:focus {
      @apply bg-status-01-400 outline-none;
    }
    &:active {
      @apply bg-status-01-600;
    }
    &#{$root}_disabled {
      @apply bg-neutral-01-75;
    }
    &#{$root}_loading {
      @apply bg-neutral-01-75;
    }
  }
  &_secondary {
    & {
      @apply border border-neutral-01-75 text-primary-01-400 bg-white;
    }
    &:hover {
      @apply bg-primary-01-25 border-neutral-01-200;
    }
    &:focus {
      @apply bg-primary-01-25 border-neutral-01-100 outline-none;
    }
    &:active {
      @apply bg-primary-01-50 border-neutral-01-500;
    }
    &#{$root}_disabled {
      @apply border-neutral-01-75 text-neutral-01-100 bg-transparent;
    }
    &#{$root}_loading {
      @apply border-neutral-01-75 text-neutral-01-100 bg-transparent;
    }
  }
  &_darkned {
    & {
      @apply text-primary-01-400 bg-neutral-01-25;
    }
    &:hover {
      @apply bg-primary-01-25;
    }
    &:focus {
      @apply bg-primary-01-25 outline-none;
    }
    &:active {
      @apply bg-primary-01-50;
    }
    &#{$root}_disabled {
      @apply text-neutral-01-100 bg-neutral-01-15 bg-transparent;
    }
    &#{$root}_active {
      @apply bg-primary-01-50 text-primary-01-800;
      &:hover {
        @apply bg-primary-01-100;
      }
      &:focus {
        @apply bg-primary-01-100;
      }
    }
    &#{$root}_loading {
      @apply text-neutral-01-100 bg-transparent;
    }
  }
  &_tertiary {
    & {
      @apply text-primary-01-400;
    }
    &:hover {
      @apply bg-primary-01-25;
    }
    &:focus {
      @apply bg-primary-01-25 outline-none;
    }
    &:active {
      @apply bg-primary-01-50;
    }
    &#{$root}_disabled {
      @apply text-neutral-01-100 bg-transparent;
    }
    &#{$root}_active {
      @apply bg-primary-01-50 text-primary-01-800;
      &:hover {
        @apply bg-primary-01-75;
      }
      &:focus {
        @apply bg-primary-01-75;
      }
    }
    &#{$root}_loading {
      @apply text-neutral-01-100 bg-transparent;
    }
  }
  &_tertiary-dark {
    & {
      @apply text-primary-01-400;
    }
    &:hover {
      @apply bg-primary-01-50;
    }
    &:focus {
      @apply bg-primary-01-50 outline-none;
    }
    &:active {
      @apply bg-primary-01-100;
    }
    &#{$root}_disabled {
      @apply text-neutral-01-100 bg-transparent;
    }
    &#{$root}_loading {
      @apply text-neutral-01-100 bg-transparent;
    }
  }
  &_tertiary-white {
    & {
      @apply text-white;
    }
    &:hover {
      @apply bg-white text-primary-01-800;
    }
    &:focus {
      @apply bg-white text-primary-01-800 outline-none;
    }
    &:active {
      @apply bg-primary-01-75 text-primary-01-800;
    }
    &#{$root}_disabled {
      @apply text-primary-01-200 border-primary-01-200 bg-transparent;
    }
    &#{$root}_loading {
      @apply text-neutral-01-100 bg-transparent;
    }
  }
  &_outline {
    & {
      @apply text-white border-white border;
    }
    &:hover {
      @apply bg-white text-primary-01-800;
    }
    &:focus {
      @apply bg-white text-primary-01-800 outline-none;
    }
    &:active {
      @apply bg-primary-01-75 text-primary-01-800;
    }
    &#{$root}_disabled {
      @apply text-primary-01-200 border-primary-01-200 bg-transparent;
    }
    &#{$root}_loading {
      @apply text-neutral-01-100 bg-transparent;
    }
  }
}
</style>
