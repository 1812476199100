<template>
  <div class="LLInputContainer" :class="inputClasses">
    <div class="LLInputContainer__input-wrapper">
      <div class="LLInputContainer__input-container">
        <div v-if="showLeftIcon" class="LLInputContainer__left-icon">
          <slot name="left-icon"></slot>
        </div>
        <div class="LLInputContainer__content">
          <div v-if="showPlaceholder" class="LLInputContainer__placeholder">
            <slot name="placeholder"></slot>
          </div>
          <div v-if="showTextInput" class="LLInputContainer__text-input">
            <slot name="text-input"> </slot>
          </div>
        </div>
        <div v-if="withCleaner" class="LLInputContainer__cleaner">
          <CloseIcon
            v-if="withCleaner"
            class="LLInputContainer__cleaner-icon"
            @click.stop.prevent.native="onClearClick"
          ></CloseIcon>
        </div>
        <div v-if="showRightIcon" class="LLInputContainer__right-icon">
          <slot name="right-icon"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/CloseIcon'
export default {
  name: 'LLInputContainer',
  components: { CloseIcon },
  props: {
    focused: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorState: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false },
    withCleaner: { type: Boolean, default: false },
    freeSize: { type: Boolean, default: false }
  },
  data() {
    return {
      showLeftIcon: false,
      showRightIcon: false,
      showCaption: false,
      showPlaceholder: false,
      showTextInput: true,
      showErrorMessage: false,
      rootStyleClass: 'LLInputContainer'
    }
  },
  computed: {
    inputClasses() {
      const classes = []
      const rootClass = this.rootStyleClass
      if (this.focused) {
        classes.push(`${rootClass}_focused`)
      }
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.error || this.stateError) {
        classes.push(`${rootClass}_error`)
      }
      if (this.freeSize) {
        classes.push(`${rootClass}_free-size`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      if (this.tiny) {
        classes.push(`${rootClass}_tiny`)
      }
      if (this.filled) {
        classes.push(`${rootClass}_filled`)
      }
      return classes
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  methods: {
    setShowSlots() {
      this.$nextTick(() => {
        this.showLeftIcon = !!this.$slots?.['left-icon']?.[0]
        this.showRightIcon = !!this.$slots?.['right-icon']?.[0]
        this.showCaption = !!this.$slots?.caption?.[0]
        this.showPlaceholder = !!this.$slots?.placeholder?.[0]
        this.showTextInput = !!this.$slots?.['text-input']?.[0]
        this.showErrorMessage = !!this.$slots?.['error-message']?.[0]
      })
    },
    onClearClick() {
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputContainer {
  @apply bg-white flex flex-col items-stretch;
  $root: &;
  &__input-wrapper {
    @apply border-neutral-01-75;
  }
  &__input-container {
  }
  &__right-icon {
    @apply text-neutral-01-800;
  }
  &__left-icon {
    @apply text-neutral-01-800;
  }
  &__text-input {
    @apply text-neutral-01-900 text-body-01;
  }
  &__placeholder {
    @apply text-neutral-01-400 text-body-01;
  }
  &:hover:not(#{$root}_disabled):not(#{$root}_error):not(#{$root}_focused) {
    #{$root}__input-wrapper {
      @apply border-neutral-01-400;
    }
  }

  &_small {
    @apply h-8;
    #{$root}__text-input {
      @apply text-body-03;
    }
    #{$root}__placeholder {
      @apply text-body-03;
    }
    #{$root}__input-wrapper {
      @apply h-8;
    }
    #{$root}__left-icon {
      @apply ml-2;
    }
    #{$root}__right-icon {
      @apply mr-2;
    }
    #{$root}__cleaner {
      @apply pr-2;
    }
  }
  &_tiny {
    @apply h-6;
    #{$root}__text-input {
      @apply text-body-03;
    }
    #{$root}__placeholder {
      @apply text-body-03;
    }
    #{$root}__input-wrapper {
      @apply h-6;
    }
    #{$root}__left-icon {
      @apply ml-1.5;
    }
    #{$root}__right-icon {
      @apply mr-1.5;
    }
    #{$root}__cleaner {
      @apply pr-1.5;
    }
  }

  &_focused {
    #{$root}__input-wrapper {
      @apply border-neutral-01-700;
    }
  }
  &_disabled {
    #{$root}__input-wrapper {
      @apply border-neutral-01-100 bg-neutral-01-15;
    }
    #{$root}__right-icon {
      @apply text-neutral-01-400;
    }
    #{$root}__left-icon {
      @apply text-neutral-01-400;
    }
    #{$root}__text-input {
      @apply text-neutral-01-400;
    }
    #{$root}__placeholder {
      @apply text-neutral-01-400;
    }
    #{$root}__error-message {
      @apply text-status-03-600 mt-1 text-caption-02 px-1;
    }
    @apply pointer-events-none;
  }
  &_error {
    #{$root}__input-wrapper {
      @apply border-status-03-600;
    }
  }

  &_free-size {
    #{$root}__input-wrapper {
      @apply h-auto;
    }
  }

  @apply w-full;
  &__input-wrapper {
    @apply border rounded transition duration-200 inline-block w-full h-11;
  }
  &__input-container {
    @apply flex items-center w-full h-full;
  }
  &__right-icon {
    @apply flex-shrink-0 flex items-center justify-center mr-3 w-6 h-6;
  }
  &__left-icon {
    @apply flex-shrink-0 flex items-center justify-center ml-3 w-6 h-6;
  }
  &__text-input {
    @apply w-full h-full left-0 top-0 flex items-center relative;
  }
  &__placeholder {
    @apply absolute w-full h-full left-0 top-0 flex items-center;
  }
  &__content {
    @apply flex-1 relative h-full min-w-0;
  }
  &__cleaner {
    @apply flex-shrink-0 flex items-center pr-4;
    #{$root}__cleaner-icon {
      @apply h-3 text-neutral-01-800 cursor-pointer;
    }
  }
}
</style>
