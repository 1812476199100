const span = (plainText) => {
  const result = plainText.replace(/{{mention:(.*?)}}/gi, (match) => {
    const options = JSON.parse(JSON.parse([...match.matchAll(/{{mention:(.*?)}}/gi)][0][1]))

    return `<span class="mention">@${options?.fullName}</span>`
  })
  return result
}
const tiptap = (plainText) => {
  const result = plainText.replace(/{{mention:(.*?)}}/gi, (match) => {
    const options = JSON.parse(JSON.parse([...match.matchAll(/{{mention:(.*?)}}/gi)][0][1]))

    return `<span class="mention"${options?.prospectId ? ` prospectid="${options?.prospectId}"` : ''}${
      options?.userId ? ` userid="${options?.userId}"` : ''
    }${options?.fullName ? ` fullname="${options?.fullName}"` : ''}>@${options?.fullName}</span>`
  })
  return result
}

export default { tiptap, span }
