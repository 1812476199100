<template>
  <div ref="card" class="ForumChatThreadCard" :class="classList">
    <ForumChatThreadCardIndicators
      class="ForumChatThreadCard__indicators"
      :thread="localThread"
    ></ForumChatThreadCardIndicators>
    <ForumChatThreadCardHeader
      :thread="localThread"
      :is-reaction-animations-enabled="isReactionAnimationsEnabled"
      :has-link="hasLink"
      :mobile="mobile"
      :compact="compact"
      :dark="dark"
      :hide-actions="hideActions"
      @reactionSelect="onReactionSelect"
    >
      <template v-if="isActionsVisible" #actions>
        <ForumChatThreadCardActions
          :thread="localThread"
          :dark="dark"
          @update="onThreadUpdate"
          @remove="$emit('remove')"
        />
      </template>
    </ForumChatThreadCardHeader>
    <ForumChatThreadCardBody
      class="ForumChatThreadCard__body"
      :thread="localThread"
      :reaction-emoji-list="reactionEmojiList"
      :has-link="hasLink"
      :mobile="mobile"
      :compact="compact"
      @updateReactionEmojiList="onUpdateReactionEmojiList"
      @update="onThreadUpdate"
    >
      <template #poll>
        <ForumPoll
          :is-able-to-vote="localThread.isActive"
          :poll="localThread.poll"
          :thread="thread"
          @update="onUpdatePoll"
          @vote="reloadThread"
        />
      </template>
    </ForumChatThreadCardBody>
    <ForumChatThreadCardFooter
      class="ForumChatThreadCard__footer"
      :thread="thread"
      :has-link="hasLink"
      :mobile="mobile"
      :compact="compact"
      :has-link-button="hasLinkButton"
    />
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatThreadCardIndicators from '@/components/modules/forum-chat/new-thread-card/ForumChatThreadCardIndicators.vue'
import ForumChatThreadCardHeader from '@/components/modules/forum-chat/new-thread-card/ForumChatThreadCardHeader.vue'
import ForumChatThreadCardActions from '@/components/modules/forum-chat/new-thread-card/ForumChatThreadCardActions.vue'
import ForumChatThreadCardBody from '@/components/modules/forum-chat/new-thread-card/ForumChatThreadCardBody.vue'
import ForumPoll from '@/components/modules/forum-chat/ForumChatPoll.vue'
import ForumChatThreadCardFooter from '@/components/modules/forum-chat/new-thread-card/ForumChatThreadCardFooter.vue'
import chatEditor from '@/utils/modules/forum/chatEditor'
import { ForumReactionModel } from '@/models/forum/forumReaction'

export default {
  name: 'ForumChatThreadCard',
  components: {
    ForumChatThreadCardFooter,
    ForumPoll,
    ForumChatThreadCardBody,
    ForumChatThreadCardActions,
    ForumChatThreadCardHeader,
    ForumChatThreadCardIndicators
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    hasLink: { type: Boolean, default: true },
    hasLinkButton: { type: Boolean, default: true },
    isReactionAnimationsEnabled: { type: Boolean, default: true },
    dark: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    compact: { type: Boolean, default: false }
  },
  data() {
    return {
      reactionEmojiList: [],
      localThread: null,
      resizeObserver: null,
      currentWidth: null
    }
  },
  computed: {
    mobile() {
      return !this.compact && this.currentWidth < 540
    },
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    },
    isActionsVisible() {
      return this.localThread.prospect?.id === this.$store.getters['user/user'].id
    }
  },
  watch: {
    thread: {
      handler() {
        if (!this.$_.isEqual(this.thread, this.localThread)) {
          this.localThread = new ForumThreadModel(this.thread)
        }
      },
      deep: true,
      immediate: true
    },
    localThread: {
      handler() {
        this.$emit('update', { thread: new ForumThreadModel(this.localThread) })
      },
      deep: true
    }
  },
  mounted() {
    this.checkWidth()
    this.$nextTick(() => {
      chatEditor.addEvent(chatEditor.enums.REACTIONS_UPDATE, this.onSocketReactionsUpdate)
      window.addEventListener('resize', this.checkWidth)
      this.resizeObserver = new ResizeObserver(this.checkWidth)
      this.resizeObserver.observe(this.$refs.card)
    })
  },
  beforeDestroy() {
    chatEditor.removeEvent(chatEditor.enums.REACTIONS_UPDATE, this.onSocketReactionsUpdate)
    window.removeEventListener('resize', this.checkWidth)
    if (this.resizeObserver?.disconnect) this.resizeObserver.disconnect()
  },
  methods: {
    checkWidth() {
      this.currentWidth = this.$refs.card.clientWidth
    },
    onUpdatePoll({ poll }) {
      this.localThread.poll = poll
    },
    onReactionSelect({ reaction }) {
      const reactionIndex = this.reactionEmojiList.indexOf(reaction)
      if (reactionIndex !== -1) {
        this.reactionEmojiList.splice(reactionIndex, 1)
      } else {
        this.reactionEmojiList.push(reaction)
      }
    },
    onThreadUpdate({ thread }) {
      this.localThread = new ForumThreadModel(thread)
    },
    onUpdateReactionEmojiList(reactionEmojiList) {
      this.reactionEmojiList = [...reactionEmojiList]
    },
    updateReactions({ reactions }) {
      this.$emit('update', {
        thread: new ForumThreadModel({
          ...this.localThread,
          reactions: reactions.map((reaction) => new ForumReactionModel(reaction))
        })
      })
    },
    onSocketReactionsUpdate({ threadId, reactions }) {
      if (threadId === this.localThread?.id) {
        this.updateReactions({ reactions })
      }
    },
    async reloadThread() {
      const { thread: serverThread } = await this.$api.forum.threads.get({
        chatId: this.thread.chatId,
        threadId: this.thread.id
      })
      const thread = ForumThreadModel.parseFromApi({ thread: serverThread, chatId: this.thread.chatId })
      this.localThread = new ForumThreadModel(thread)
      this.$emit('update', { thread })
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCard {
  $root: &;
  @apply border border-neutral-01-50 transition duration-200 relative;
  &__indicators {
    @apply absolute top-0 left-0 ml-4 -mt-3;
  }
  &__body {
    @apply pt-4;
    &:last-child {
      @apply pb-4;
    }
  }
  &__footer {
    @apply pb-4 pt-4;
    @at-root #{$root}_compact & {
      @apply pt-2;
    }
    @at-root #{$root}_mobile & {
      @apply pt-2;
    }
  }
}
</style>
