<template>
  <div class="SearchModule">
    <SearchHeader
      class="SearchModule__header"
      :search-category.sync="searchCategory"
      :search-value.sync="searchValue"
      :study="study"
      :chat="chat"
      @search="ohHeaderSearch"
    ></SearchHeader>
    <SearchContent
      ref="search-content"
      class="SearchModule__content"
      :search-category="searchCategory"
      :search-value="searchValue"
      :study="study"
      :chat="chat"
      :pending-results="pendingResults"
      :search-results="searchResults"
      :total="total"
      @search="startDebouncedSearch"
    ></SearchContent>
    <div v-if="showContent && searchResults.length && total > 10" class="SearchModule__pagination-wrapper">
      <SearchPagination
        :current="page"
        :total="total"
        :per-page="10"
        :pending="pendingResults"
        @goToPage="paginationSearch"
      ></SearchPagination>
    </div>
  </div>
</template>

<script>
import SearchHeader from '@/components/pages/search/SearchModule/SearchHeader'
import SearchContent from '@/components/pages/search/SearchModule/SearchContent'
import { StudyModel } from '@/models/study'
import { SearchCategories } from '@/common/enums'
import SearchPagination from '@/components/pages/search/SearchModule/SearchContent/SearchPagination'
import { ForumChatModel } from '@/models/forum/forumChat'

export default {
  name: 'SearchModule',
  components: { SearchPagination, SearchContent, SearchHeader },
  props: {
    study: { type: [StudyModel, null], default: null },
    chat: { type: [ForumChatModel, null], default: null }
  },
  data() {
    return {
      searchFilters: {},
      showContent: false,
      pendingResults: false,
      searchRequestPromise: null,
      searchValue: '',
      searchCategory: null,
      searchResults: [],
      total: 0,
      page: 1,
      paginationSearchFn: null
    }
  },
  computed: {
    SearchCategories() {
      return SearchCategories
    }
  },
  watch: {
    searchCategory() {
      this.searchResults = []
    }
  },
  created() {
    if (this.chat) {
      this.searchCategory = SearchCategories.RESPONSES
    }
  },
  methods: {
    ohHeaderSearch() {
      if (!this.searchCategory) {
        this.searchCategory = SearchCategories.RESPONSES
      }
      if (this.paginationSearchFn) {
        this.paginationSearchFn()
      } else {
        this.showContent = true
      }
    },
    startDebouncedSearch({ filters, page, sort }) {
      if (this.searchValue.length < 3) {
        this.paginationSearchFn = (page) => {
          this.search({ filters, page, sort })
        }
        this.pendingResults = false
        return
      }
      this.pendingResults = true
      this.searchDebounced({ filters, page, sort })
    },
    searchDebounced: _.debounce(function (payload) {
      this.search(payload)
    }, 100),
    search({ filters, page, sort }) {
      this.showContent = true
      this.pendingResults = true
      this.page = page || 1
      const searchRequestPromise = this.$api.search.get({
        type: this.searchCategory,
        query: this.searchValue,
        page: this.page,
        perPage: 10,
        filters,
        sort
      })
      this.searchRequestPromise = searchRequestPromise
      searchRequestPromise
        .then(({ data, total }) => {
          if (this.searchRequestPromise === searchRequestPromise) {
            this.searchResults = [...data]
            this.total = total
            this.pendingResults = false
          }
        })
        .catch(() => {
          this.pendingResults = false
        })
      this.paginationSearchFn = (page) => {
        this.startDebouncedSearch({ filters, page, sort })
      }
    },
    paginationSearch(page) {
      if (this.paginationSearchFn) {
        this.paginationSearchFn(page)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchModule {
  &__header {
    @apply mt-9 mx-auto;
    max-width: 900px;
    width: 100%;
  }
  &__content {
    @apply w-full mt-6;
    @screen md {
      @apply mt-14;
    }
  }
  &__pagination-wrapper {
    @apply flex items-center justify-center mt-6;
  }
}
</style>
