<template>
  <div v-if="hasContent" class="StudyConversationMessageBodyText" :class="classList">
    <LLFileSlider
      v-if="!!message.files.length"
      class="StudyConversationMessageBodyText__files"
      :search-text="options.highlightSerachText"
      :highlight-enabled="isHighlightEnabled"
      :large="options.fileSliderSize === StudyConversationMessageFileSize.LARGE && tailwindScreens.md"
      :medium="options.fileSliderSize === StudyConversationMessageFileSize.LARGE && !tailwindScreens.md"
      :small="options.fileSliderSize === StudyConversationMessageFileSize.SMALL"
      :files="message.files"
      :hide-images="!options.elementsVisibility.images"
      :hide-videos="!options.elementsVisibility.videos"
      with-transcribes
    ></LLFileSlider>

    <LLWysiwygOutput class="StudyConversationMessageBodyText__text" :text="textFiltered" />
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageFileSize
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'

export default {
  name: 'StudyConversationMessageBodyText',
  components: { LLWysiwygOutput, LLFileSlider },
  mixins: [screenCatcher],
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true }
  },
  computed: {
    isHighlightEnabled() {
      return (
        this.$store.getters['permissions/allowToHighlight'] &&
        !this.message.isOwnerUser &&
        this.options.isHighlighterEnabled
      )
    },
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout
      }
    },
    hasContent() {
      return this.textFullFiltered || this.message.files.length
    },
    StudyConversationMessageFileSize() {
      return StudyConversationMessageFileSize
    },
    textFiltered() {
      return sanitizeHtml(this.message.text, {
        allowedTags: ['strong', 'em', 'ol', 'br', 'u', 'p', 'ul', 'li', 'a']
      })
    },
    textFullFiltered() {
      return sanitizeHtml(this.message.text, {
        allowedTags: []
      })
    }
  },
  methods: {
    onHighlightRender() {
      if (this.$refs['search-highlighter']) {
        this.$refs['search-highlighter'].render()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageBodyText {
  $root: &;
  &__files {
    @apply mb-3;
  }
  &__text {
    @at-root #{$root}_compact &,
      #{$root}_mobile & {
      @apply text-body-03;
    }
    ::v-deep {
      ul {
        list-style: disc;
        padding-left: 1.5rem;
      }
      ol {
        list-style: decimal;
        padding-left: 1.5rem;
      }
      a {
        @apply text-primary-01-300 underline;
      }
      p {
        min-height: 1em;
      }
    }
  }
}
</style>
