<template>
  <svg width="255" height="33" viewBox="0 0 255 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M148.941 1.12678C148.79 1.12678 148.638 1.20346 148.525 1.28013C148.411 1.39514 148.374 1.54849 148.374 1.70184V31.2212C148.374 31.3745 148.411 31.5279 148.525 31.6429C148.638 31.7579 148.79 31.7962 148.941 31.7962H162.135C162.286 31.7962 162.437 31.7579 162.513 31.6429C162.589 31.5279 162.664 31.3745 162.664 31.2212C162.664 31.0679 162.589 30.9528 162.513 30.8378C162.437 30.7228 162.286 30.6461 162.135 30.6461H149.508V1.70184C149.508 1.54849 149.432 1.39514 149.319 1.28013C149.205 1.20346 149.092 1.12678 148.941 1.12678Z"
      fill="#191A2E"
    />
    <path
      d="M173.702 4.15539C175.781 2.89028 178.012 2.23855 180.431 2.23855C182.851 2.23855 185.081 2.89028 187.123 4.11706C189.164 5.38217 190.79 7.10733 191.999 9.33087C193.209 11.5544 193.814 13.9313 193.814 16.4615C193.814 19.0684 193.209 21.4453 191.999 23.5922C190.79 25.739 189.164 27.4642 187.123 28.7676C185.081 30.0328 182.851 30.6461 180.431 30.6461C177.974 30.6461 175.743 30.0328 173.702 28.7676C171.66 27.5025 170.035 25.7774 168.825 23.5922C167.615 21.4453 167.048 19.0684 167.048 16.4615C167.048 13.9313 167.615 11.5544 168.825 9.33087C170.035 7.184 171.66 5.45885 173.702 4.15539ZM187.69 3.15864C185.421 1.77851 183.002 1.08845 180.431 1.08845C177.785 1.08845 175.327 1.77851 173.135 3.15864C170.942 4.53876 169.165 6.37893 167.842 8.75581C166.557 11.1327 165.914 13.7013 165.914 16.4615C165.914 17.8416 166.065 19.1834 166.368 20.4485C166.67 21.752 167.161 22.9788 167.842 24.1672C169.127 26.5441 170.866 28.3843 173.135 29.7644C175.365 31.1445 177.785 31.7962 180.431 31.7962C183.002 31.7962 185.421 31.1445 187.69 29.7644C189.92 28.3843 191.697 26.5441 192.982 24.1672C194.268 21.8287 194.948 19.2601 194.948 16.4615C194.948 13.6629 194.268 11.0944 192.982 8.75581C191.697 6.41727 189.92 4.53876 187.69 3.15864Z"
      fill="#191A2E"
    />
    <path
      d="M208.346 4.15539C210.426 2.89028 212.656 2.23855 215.076 2.23855C217.495 2.23855 219.726 2.89028 221.767 4.11706C223.809 5.38217 225.434 7.10733 226.644 9.33087C227.854 11.5544 228.459 13.9313 228.459 16.4615C228.459 19.0684 227.854 21.4453 226.644 23.5922C225.434 25.739 223.809 27.4642 221.767 28.7676C219.726 30.0328 217.495 30.6461 215.076 30.6461C212.618 30.6461 210.388 30.0328 208.346 28.7676C206.305 27.5025 204.679 25.7774 203.47 23.5922C202.26 21.4453 201.693 19.0684 201.693 16.4615C201.693 13.9313 202.26 11.5544 203.47 9.33087C204.679 7.184 206.305 5.45885 208.346 4.15539ZM222.334 3.15864C220.066 1.77851 217.647 1.08845 215.076 1.08845C212.429 1.08845 209.972 1.77851 207.779 3.15864C205.587 4.53876 203.81 6.37893 202.487 8.75581C201.201 11.1327 200.559 13.7013 200.559 16.4615C200.559 17.8416 200.71 19.1834 201.012 20.4485C201.315 21.752 201.806 22.9788 202.487 24.1672C203.772 26.5441 205.511 28.3843 207.779 29.7644C210.01 31.1445 212.429 31.7962 215.076 31.7962C217.647 31.7962 220.066 31.1445 222.334 29.7644C224.565 28.3843 226.342 26.5441 227.627 24.1672C228.913 21.8287 229.593 19.2601 229.593 16.4615C229.593 13.6629 228.913 11.0944 227.627 8.75581C226.342 6.41727 224.565 4.53876 222.334 3.15864Z"
      fill="#191A2E"
    />
    <path
      d="M253.937 1.08845C253.785 1.08845 253.634 1.16512 253.521 1.28013L237.075 20.7169V1.6635C237.075 1.51015 237 1.35681 236.886 1.28013C236.773 1.20346 236.66 1.12678 236.508 1.12678C236.357 1.12678 236.206 1.20346 236.092 1.28013C235.979 1.35681 235.941 1.51015 235.941 1.6635V31.2212C235.941 31.4129 235.979 31.5662 236.092 31.6429C236.206 31.7196 236.357 31.7579 236.508 31.7579C236.66 31.7579 236.773 31.7196 236.886 31.6429C237 31.5662 237.075 31.4129 237.075 31.2212V22.4804L241.801 16.9216L253.71 31.5662C253.823 31.7196 253.974 31.7962 254.126 31.7962C254.277 31.7962 254.39 31.7579 254.504 31.6812C254.617 31.6046 254.693 31.4512 254.73 31.2979C254.73 31.1445 254.693 30.9912 254.579 30.8378L242.557 16.0398L254.352 2.04687C254.428 1.97019 254.504 1.81685 254.504 1.6635C254.504 1.51015 254.428 1.39514 254.315 1.28013C254.201 1.16512 254.088 1.08845 253.937 1.08845Z"
      fill="#191A2E"
    />
    <path
      d="M0 31.7962H26.0315L26.4296 25.0833H25.3844L25.1355 26.0928C24.4885 28.7679 23.941 29.9287 19.4116 29.9287H13.4388C9.30761 29.9287 8.71033 29.1212 8.71033 24.0739V8.42717C8.71033 3.37985 9.30761 2.57228 13.4388 2.57228V1.51234H0V2.57228C4.13119 2.57228 4.72847 3.37985 4.72847 8.42717V24.8309C4.72847 29.8783 4.13119 30.7363 0 30.7363V31.7962Z"
      fill="#191A2E"
    />
    <path
      d="M62.6246 16.6543C62.6246 7.87196 55.1586 0.654297 46.05 0.654297C36.8918 0.654297 29.4258 7.87196 29.4258 16.6543C29.4258 25.4366 36.8918 32.6543 46.05 32.6543C55.1586 32.6543 62.6246 25.4366 62.6246 16.6543ZM46.05 2.62275C54.6111 2.62275 57.8961 10.3956 57.8961 16.6543C57.8961 22.913 54.6111 30.6354 46.05 30.6354C37.4393 30.6354 34.1542 22.913 34.1542 16.6543C34.1542 10.3956 37.4393 2.62275 46.05 2.62275Z"
      fill="#191A2E"
    />
    <path
      d="M101.218 16.6543C101.218 7.87196 93.7523 0.654297 84.6438 0.654297C75.4855 0.654297 68.0195 7.87196 68.0195 16.6543C68.0195 25.4366 75.4855 32.6543 84.6438 32.6543C93.7523 32.6543 101.218 25.4366 101.218 16.6543ZM84.6438 2.62275C93.2048 2.62275 96.4899 10.3956 96.4899 16.6543C96.4899 22.913 93.2048 30.6354 84.6438 30.6354C76.033 30.6354 72.748 22.913 72.748 16.6543C72.748 10.3956 76.033 2.62275 84.6438 2.62275Z"
      fill="#191A2E"
    />
    <path
      d="M118.658 14.2316L127.369 6.66061C130.853 3.63222 132.645 2.57228 136.477 2.57228V1.51234H124.93V2.57228C128.066 2.57228 127.867 3.93505 124.731 6.66061L114.527 15.4934V8.42717C114.527 3.37985 115.125 2.57228 119.256 2.57228V1.51234H105.817V2.57228C109.948 2.57228 110.545 3.37985 110.545 8.42717V24.8309C110.545 29.8783 109.948 30.7363 105.817 30.7363V31.7962H119.256V30.7363C115.125 30.7363 114.527 29.8783 114.527 24.8309V17.8152L115.821 16.7552L128.414 27.8089C130.405 29.525 129.808 30.7363 126.523 30.7363V31.7962H140.509V30.7363C139.165 30.7363 135.93 29.424 134.088 27.8089L118.658 14.2316Z"
      fill="#191A2E"
    />
  </svg>
</template>

<script>
export default {
  name: 'SiteLogo'
}
</script>
