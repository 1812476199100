<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.61706 1.78281C2.15416 1.78281 1.77891 2.15807 1.77891 2.62097V13.3881C1.77891 13.7625 2.02441 14.0796 2.36323 14.1871L10.5816 5.9687C10.855 5.69533 11.2982 5.69533 11.5716 5.9687L14.2223 8.61943V2.62097C14.2223 2.15807 13.8471 1.78281 13.3842 1.78281H2.61706ZM14.2223 10.5993L11.0766 7.45362L4.304 14.2262H13.3842C13.8471 14.2262 14.2223 13.851 14.2223 13.3881V10.5993ZM0.378906 2.62097C0.378906 1.38487 1.38096 0.382812 2.61706 0.382812H13.3842C14.6203 0.382812 15.6223 1.38487 15.6223 2.62097V13.3881C15.6223 14.6242 14.6203 15.6262 13.3842 15.6262H2.61706C1.38096 15.6262 0.378906 14.6242 0.378906 13.3881V2.62097ZM5.30882 4.85508C5.05829 4.85508 4.8552 5.05817 4.8552 5.3087C4.8552 5.55922 5.05829 5.76232 5.30882 5.76232C5.55935 5.76232 5.76244 5.55922 5.76244 5.3087C5.76244 5.05817 5.55935 4.85508 5.30882 4.85508ZM3.4552 5.3087C3.4552 4.28497 4.28509 3.45508 5.30882 3.45508C6.33254 3.45508 7.16244 4.28497 7.16244 5.3087C7.16244 6.33242 6.33254 7.16232 5.30882 7.16232C4.28509 7.16232 3.4552 6.33242 3.4552 5.3087Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhotoIcon'
}
</script>

<style scoped></style>
