<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M13.8591 12.0001L18.0669 7.79229C18.3103 7.54893 18.3103 7.154 18.0669 6.91025L17.0892 5.93252C16.8458 5.68916 16.4509 5.68916 16.2071 5.93252L11.9997 10.1407L7.79189 5.93291C7.54854 5.68955 7.15361 5.68955 6.90986 5.93291L5.93252 6.91025C5.68916 7.15361 5.68916 7.54854 5.93252 7.79229L10.1403 12.0001L5.93252 16.2079C5.68916 16.4513 5.68916 16.8462 5.93252 17.0899L6.91025 18.0677C7.15361 18.311 7.54854 18.311 7.79229 18.0677L11.9997 13.8595L16.2075 18.0673C16.4509 18.3106 16.8458 18.3106 17.0896 18.0673L18.0673 17.0896C18.3106 16.8462 18.3106 16.4513 18.0673 16.2075L13.8591 12.0001Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseRegularIcon'
}
</script>
