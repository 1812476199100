export const screenCatcher = {
  name: 'screenCatcher',
  data() {
    return {
      tailwindScreens: {
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
        mobOnly: false,
        mob: false,
        init: false
      }
    }
  },
  mounted() {
    const matcherMobOnly = window.matchMedia('(max-width: 640px)')
    const matcherMob = window.matchMedia('(max-width: 1023px)')
    const matcherSm = window.matchMedia('(min-width: 576px)')
    const matcherMd = window.matchMedia('(min-width: 768px)')
    const matcherLg = window.matchMedia('(min-width: 992px)')
    const matcherXl = window.matchMedia('(min-width: 1200px)')
    const matcherXXL = window.matchMedia('(min-width: 1536px)')
    const checkMatcher = () => {
      this.tailwindScreens.mobOnly = matcherMobOnly.matches
      this.tailwindScreens.mob = matcherMob.matches
      this.tailwindScreens.sm = matcherSm.matches
      this.tailwindScreens.md = matcherMd.matches
      this.tailwindScreens.lg = matcherLg.matches
      this.tailwindScreens.xl = matcherXl.matches
      this.tailwindScreens.xxl = matcherXXL.matches
    }
    checkMatcher()
    document.addEventListener('DOMContentLoaded', checkMatcher)
    matcherMobOnly.addEventListener('change', checkMatcher)
    matcherMob.addEventListener('change', checkMatcher)
    matcherSm.addEventListener('change', checkMatcher)
    matcherMd.addEventListener('change', checkMatcher)
    matcherLg.addEventListener('change', checkMatcher)
    matcherXl.addEventListener('change', checkMatcher)
    matcherXXL.addEventListener('change', checkMatcher)
    matcherMob.addEventListener('resize', checkMatcher)
    this.tailwindScreens.init = true
  }
}
