import Vue from 'vue'
import VModal from 'vue-js-modal'

Vue.use(VModal, {
  dialog: true,
  // TODO: See: https://github.com/euvl/vue-js-modal/issues/561#issuecomment-661682707
  dynamicDefaults: {
    scrollable: true,
    adaptive: true,
    width: '100%',
    height: 'auto'
  }
})
