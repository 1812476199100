export default {
  methods: {
    goToStudy() {},
    goToDashboard() {},
    goToSignIn() {},
    async goToSetPassword() {
      await this.$router.push('/set-password')
    }
  }
}
