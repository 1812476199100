<template>
  <svg
    class="icon fill-current"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7 10.7C18.7 10.3 19 10 19.4 10H21.5C21.1 4.7 16.8 0.3 11.4 0V2.1C11.4 2.5 11.1 2.8 10.7 2.8C10.3 2.8 10 2.5 10 2.1V0C4.7 0.3 0.3 4.7 0 10.1H2.1C2.5 10.1 2.7 10.4 2.7 10.8C2.7 11.2 2.4 11.4 2.1 11.4H0C0.3 16.8 4.7 21.1 10.1 21.5V19.4C10.1 19 10.4 18.7 10.8 18.7C11.2 18.7 11.5 19 11.5 19.4V21.5C16.9 21.2 21.2 16.8 21.6 11.4H19.5C18.9 11.4 18.7 11.1 18.7 10.7ZM16.9 7.2L11.2 13C11 13.2 10.6 13.3 10.3 13L6.4 9.8C6.1 9.6 6 9.2 6.3 8.9C6.5 8.6 6.9 8.6 7.2 8.8L10.7 11.6L16 6.3C16.3 6 16.7 6 16.9 6.3C17.2 6.6 17.2 7 16.9 7.2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DateIcon'
}
</script>
