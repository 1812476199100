<template>
  <div class="PollEditorNumberStars">
    <LLInputLocked class="PollEditorNumber__style-selector" dark-caption required>
      <template #caption>{{ $t('forum.poll.editor.number.stars') }}</template>
      <ValidationProvider
        v-slot="{ errors }"
        class="QuestionEditorTypeSlider__input-wrapper"
        :rules="{
          required: true
        }"
      >
        <LLInputSelect
          v-model="localPoll.starsAmount"
          :errors="errors"
          class="PollEditorNumberStars__input"
          show-error-in-tooltip
          :disabled="isPollCreated"
          filterable
          :filter-mask="{ mask: '9[9]', greedy: false }"
          :values="values"
        ></LLInputSelect>
        <!--        <LLInputText
          v-model="localPoll.starsAmount"
          :errors="errors"
          class="PollEditorNumberStars__input"
          show-error-in-tooltip
          :mask="{ mask: '9{1,2}', autoUnmask: true }"
        ></LLInputText>-->
      </ValidationProvider>
    </LLInputLocked>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { PollModel } from '@/models/poll'
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'

export default {
  name: 'PollEditorNumberStars',
  components: { LLInputSelect, LLInputLocked, ValidationProvider },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      localPoll: new PollModel(this.poll)
    }
  },
  computed: {
    isPollCreated() {
      return !!this.poll?.id
    },
    values() {
      const numbersArray = []
      for (let i = 1; i <= 10; i++) {
        numbersArray.push(i)
      }
      return numbersArray
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    }
  },
  mounted() {
    if (!this.localPoll.starsAmount) {
      this.localPoll.starsAmount = 5
    }
  }
}
</script>

<style scoped lang="scss">
.PollEditorNumberStars {
  &__input {
    max-width: 250px;
  }
}
</style>
