<template>
  <LLWordTextContainer class="PrivacyPolicyEN">
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Protecting your data is our priority. This Privacy Policy applies to the websites, apps and services
        that are controlled by Spark Ideas, LLC (&ldquo;Spark,&rdquo; &ldquo;we,&rdquo; us&rdquo; or
        &ldquo;our&rdquo;), including www.spark-nyc.com and https://looklook.app (collectively, the
        &ldquo;Sites&rdquo;) and the LookLook mobile application (the &ldquo;Mobile App&rdquo;). Please read
        this Privacy Policy carefully, because by using the Sites or the Mobile App, you agree with the data
        practices described in this policy.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Your use of the Sites or the Mobile App is also governed by the Terms of Service, which can be found
        here.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >IMPORTANT NOTICE: PLEASE NOTE THE ARBITRATION PROVISION SET FORTH BELOW, WHICH REQUIRES YOU, EXCEPT
        TO WHERE AND TO THE EXTENT PROHIBITED BY LAW, TO ARBITRATE ANY CLAIMS YOU MAY HAVE AGAINST SPARK ON AN
        INDIVIDUAL BASIS. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE
        RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED
        OR REPRESENTATIVE CAPACITY.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Collection and Use of Your Personal Information</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >&ldquo;Personal information&rdquo; is any information relating to an identified or identifiable
        natural person.
      </span>
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span>We collect the following information from and about you:</span>
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <a id="t.97b80cb4864a38139697d086c9d9aa682a66fd08"></a><a id="t.0"></a>
    <table class="c22">
      <tr class="c16">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Demographic data</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">We use this data to</span></p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Name</span></p>
          <p class="c8"><span class="c5">Telephone number</span></p>
          <p class="c8"><span class="c5">City, state, and country</span></p>
          <p class="c8"><span class="c5">Email address</span></p>
          <p class="c8"><span class="c5">Job Title</span></p>
          <p class="c8"><span class="c5">Company</span></p>
          <p class="c8"><span class="c5">Education</span></p>
          <p class="c8"><span class="c5">Income bracket</span></p>
          <p class="c8"><span class="c5">Marital status</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <ul class="c14 lst-kix_list_6-0 start">
            <li class="c8 c12 li-bullet-0"><span class="c5">Conduct studies</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Communicate with clients and prospects (email, contact forms, WeChat)</span>
            </li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Contact participants for any future marketing and research studies.</span>
            </li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Enable customers to use our research platform</span>
            </li>
            <li class="c8 c12 li-bullet-0"><span class="c5">Resolve issues</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5"
                >Offer customer support<br /><br />Legal basis: consent, contract, legitimate interest.</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr class="c13">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Preferences</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Hobbies</span></p>
          <p class="c8"><span class="c5">Interests</span></p>
          <p class="c8"><span class="c5">Shopping habits</span></p>
          <p class="c8"><span class="c5">Brand preference</span></p>
          <p class="c8"><span class="c5">Purchase history</span></p>
          <p class="c8"><span class="c5">Other topics relevant for the study</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <ul class="c14 lst-kix_list_7-0 start">
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Conduct studies<br /></span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5"><br /><br /><br /><br /></span>
          </p>
          <p class="c8"><span class="c5">Legal basis: consent</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Media</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Photographs</span></p>
          <p class="c8"><span class="c5">Videos</span></p>
          <p class="c8"><span class="c5">Voice</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <ul class="c14 lst-kix_list_7-0">
            <li class="c8 c12 li-bullet-0"><span class="c5">Conduct studies</span></li>
          </ul>
          <p class="c8">
            <span class="c5"><br /><br />Legal basis: consent</span>
          </p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Communication</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c23">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Participant interviews</span></p>
          <p class="c8">
            <span class="c5">General inquiries (email or contact for customer requests)</span>
          </p>
          <p class="c8"><span class="c5">Customer support</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <ul class="c14 lst-kix_list_7-0">
            <li class="c8 c12 li-bullet-0"><span class="c5">Conduct studies</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Communicate with clients and prospects (email, contact forms, WeChat)</span>
            </li>
            <li class="c8 c12 li-bullet-0"><span class="c5">Offer customer support</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Distribute rewards<br /></span>
            </li>
          </ul>
          <p class="c8"><span class="c5">Legal basis: consent, contract</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">Technology</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <p class="c3"><span class="c5"></span></p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c1" colspan="1" rowspan="1">
          <p class="c8"><span class="c5">City geolocation</span></p>
          <p class="c8"><span class="c5">IP address</span></p>
          <p class="c8"><span class="c5">Device and browser data</span></p>
        </td>
        <td class="c9" colspan="1" rowspan="1">
          <ul class="c14 lst-kix_list_8-0 start">
            <li class="c8 c12 li-bullet-0"><span class="c5">Conduct studies</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Enable customers to use our research platform</span>
            </li>
            <li class="c8 c12 li-bullet-0"><span class="c5">Improve our products and services</span></li>
            <li class="c8 c12 li-bullet-0"><span class="c5">Resolve issues</span></li>
            <li class="c8 c12 li-bullet-0">
              <span class="c5">Secure platform<br /><br />Legal basis: consent, legitimate interest </span>
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">.</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Cookies and Other Similar Technologies</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We use necessary and anonymized cookies and other similar technologies on our Sites and the Mobile
        App to help collect information. We may use session cookies or persistent cookies. Session cookies
        only last for the specific duration of your visit and are deleted when you close your browser.
        Persistent cookies remain on your device&rsquo;s hard drive until you delete them or they expire.
        <br /><br />We use the following types of cookies:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Essential. Some cookies are essential in order to enable you to move around our Sites and/or the
        Mobile App and use their features, such as accessing secure areas of the Sites and/or the Mobile App.
        Without these cookies, we cannot enable appropriate content based on the type of device you are
        using.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >&nbsp;Performance and Analytics. We use cookies to see how you use our Sites and/or the Mobile App in
        order to improve their performance and develop them. No personal information is collected through
        cookies.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Sharing and Disclosure of Your Personal Information</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We share or disclose your personal information in the following instances and to the following
        parties:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We share information provided through the Sites and Mobile App with our clients for their marketing
        and research studies and business purposes.</span
      >
    </p>
    <p class="c4">
      <span
        >We share information provided through the Sites and Mobile App with third party service providers to
        administer studies and provide reports to clients. The updated list of our third-party service
        providers can be reviewed </span
      ><span class="c11"><a class="c6" :href="linkToSubProcessors">here</a><span class="c5"></span>.</span>
    </p>
    <p class="c4">
      <span class="c5"
        >We may share or transfer your information in the course of any direct or indirect reorganization
        process including, but not limited to, mergers, acquisitions, divestitures, bankruptcies, and sales of
        all or a part of our assets. Please see Assignment below for more information.</span
      >
    </p>
    <p class="c4">
      <span class="c5"
        >We will disclose your personal information to comply with the applicable law or in the good faith
        belief that such action is necessary in order to conform to the requirements of the law or to comply
        with legal process served on us, to protect and defend our rights or property, or to act in urgent
        circumstances to protect the personal safety of our users.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Sale within the definition of the applicable US consumer privacy laws is defined as selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or otherwise communicating
        orally, in writing, or by electronic or other means, a consumer&#39;s personal information by the
        business to another business or a third party for monetary, and sometimes other valuable
        consideration.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We do not sell personal information, as defined by US state privacy laws, nor we would ever sell your
        personal information to any third party in any part of the world.
      </span>
    </p>
    <p class="c4"><span class="c5">. </span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Collection and Use of Information from Children</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We do not knowingly collect information from children under the age of 13 in the United States or
        under the age of 16 in the EU. In the event that we learn that a person under the age of 13 in the
        United State or under the age of 16 in the EU, has provided us with personal information, we will
        delete such personal information.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Access and Opt Out</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >If your information has changed and you wish to update the information we have on file for you,
        please contact us using the contact information below.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >If you no longer wish to receive marketing communications from us, you can opt-out by following the
        unsubscribe instructions provided in our marketing communication or by contacting us at the address
        below. We will process your unsubscribe as soon as possible and in compliance with applicable law, but
        please be aware that in some circumstances, you may receive a few more messages until the unsubscribe
        is processed and completed.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Please note that even if you opt-out of our marketing communications, we will still send you
        administrative messages regarding your orders and the Sites and/or the Mobile App, including, for
        example, important updates regarding the Sites and/or the Mobile App.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Your Rights</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5">If you are an EEA, UK, or Swiss resident, you have the right to request:</span>
    </p>
    <p class="c4 c10 c18"><span class="c5"></span></p>
    <ul class="c14 lst-kix_list_1-0 start">
      <li class="c2 li-bullet-0">
        <span class="c5">to know whether your personal information is processed </span>
      </li>
      <li class="c2 li-bullet-0"><span class="c5">access to your personal information</span></li>
      <li class="c2 li-bullet-0">
        <span class="c5">we edit or send you a copy of your personal information</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c5">erasure or anonymization of your personal information</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c5">we restrict usage of your personal information</span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c5">we size further study communication with you or object study participation </span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c5">To be informed if we utilize automatic decision making and profiling tools </span>
      </li>
      <li class="c2 li-bullet-0">
        <span class="c5">Lodge a complaint with the relevant data protection supervisory authority</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c5"><br />List of EU data protection authorities:</span>
    </p>
    <p class="c4">
      <span><br /></span
      ><span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1661275427212259&amp;usg=AOvVaw3HQLa-k2uhqAxY7KIzQ4Ze"
          >EEA
        </a></span
      ><span><br /></span
      ><span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1661275427212637&amp;usg=AOvVaw1X4ZWaUkBduvj_egrcjBrl"
          >Switzerland</a
        ></span
      ><span><br /></span
      ><span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1661275427212888&amp;usg=AOvVaw2rTW_mQNJ17jLGwVylkBKn"
          >United Kingdom</a
        ></span
      ><span class="c5"
        >&nbsp;<br /><br />If you are California, Connecticut, Utah, Colorado, Virginia or other US state with
        privacy legislation, you have the right to request:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <ul class="c14 lst-kix_list_2-0 start">
      <li class="c2 li-bullet-0"><span class="c5">access to your personal information</span></li>
      <li class="c2 li-bullet-0"><span class="c5">rectification of your personal information</span></li>
      <li class="c2 li-bullet-0">
        <span class="c5"
          >know whether we sold your information and opt out of sale of personal information</span
        >
      </li>
      <li class="c2 li-bullet-0"><span class="c5">deletion of your personal information</span></li>
      <li class="c2 li-bullet-0">
        <span class="c5"
          >Right to receive your personal information in easily understandable, and to the extent technically
          feasible, in a structured, commonly used, machine-readable format</span
        >
      </li>
    </ul>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">List of US data protection authorities:</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1661275427213914&amp;usg=AOvVaw2VKTKqamZ4YFmUND1Ird6l"
          >California
        </a></span
      >
    </p>
    <p class="c4">
      <span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1661275427214298&amp;usg=AOvVaw2onCZlMyy6C55GWJ6cJ_f3"
          >Connecticut</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1661275427214601&amp;usg=AOvVaw0uAvcU9O6RpUnyT9a8tKEg"
          >Utah</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1661275427214924&amp;usg=AOvVaw37_RY79MJITB3cTOHJIhPa"
          >Colorado</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c11"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1661275427215259&amp;usg=AOvVaw1rJdG1mdEHQlbRgPFzZKOy"
          >Virginia</a
        ></span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span>If you reside within the borders of the People&#39;s Republic of China, please visit our</span
      ><span class="c5 c15">&nbsp;Chinese privacy policy. </span>
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c20">
      <span>If you wish to exercise your rights, please email your request to </span
      ><span class="c11"><a class="c6" href="mailto:privacy@Spark-nyc.com">privacy@Spark-nyc.com</a></span
      ><span class="c5">. </span>
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Third Party Links</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Spark encourages you to review the privacy statements of websites linked to and from the Sites and/or
        the Mobile App so that you can understand how those websites collect, use and share your information.
        Spark is not responsible for the privacy statements or any content on these websites outside of the
        Sites and the Mobile App.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Security of your Personal Information</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Spark implements reasonable measures to secure your personal information from unauthorized access,
        use or disclosure. That said, please understand that no data transmissions over the Internet can be
        guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any
        information you transmit to us and you understand that any information that you transfer to us is done
        at your own risk. If we learn of a security systems&rsquo; breach, we may attempt to notify you
        electronically so that you can take appropriate protective steps. By using the Sites and/or the Mobile
        App or providing personal information to us, you agree that we can communicate with you electronically
        regarding security, privacy and administrative issues relating to your use of the Sites and/or the
        Mobile App. We may post a notice via the Sites and the Mobile App if a security breach occurs. We may
        also send an email to you at the email address you have provided to us in these circumstances.
        Depending on where you live, you may have a legal right to receive notice of a security breach in
        writing.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Data Retention</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >We retain your personal information for the period necessary to fulfill the purposes outlined in this
        Privacy Policy unless a longer retention period is required or permitted by law.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">International Data Transfers</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >If you choose to provide us with information, we may transfer that information to our affiliates and
        subsidiaries or to other third parties, across borders, and from your country or jurisdiction to other
        countries or jurisdictions around the world. If you are visiting from the EU or other regions with
        laws governing data collection and use that may differ from U.S. law, please note that you are
        transferring your personal information to the U.S. and other jurisdictions which may not have the same
        data protection laws as your country of residence. You acknowledge that you understand that by
        providing your personal information:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Your personal information will be used for the uses identified above in accordance with this Privacy
        Policy and signed Data Protection Agreement. Your personal information may be transferred to the U.S.
        and other jurisdictions in accordance with applicable law as indicated above. You can review our
        current list of Sub-processors and their supplementary measures for data transfer here.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Assignment</span></p>
    <p class="c4">
      <span class="c5"
        >In the event that all or part of our assets are sold or acquired by another party, or in the event of
        any direct or indirect reorganization process including, but not limited to, mergers, acquisitions,
        divestitures, bankruptcies, and sales of all or a part of our assets, you grant us the right to assign
        the personal information collected via the Sites and/or the Mobile App following completion of such
        transaction and/or during the assessment process pending transfer. If transferred in such a case, your
        information would remain subject to this Privacy Policy or a privacy policy that, at a minimum,
        protects your privacy to an equal degree as this Privacy Policy.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Dispute Resolution and Agreement to Arbitrate</span></p>
    <p class="c4">
      <span class="c5"
        >Except where and to the extent prohibited by law, by using the Sites and/or the Mobile App, you and
        Spark agree that, if there is any controversy, claim, action, or dispute arising out of or related to
        your use of the Sites and/or the Mobile App, or the breach, enforcement, interpretation, or validity
        of this Privacy Policy or any part of it (&ldquo;Dispute&rdquo;), both parties shall first try in good
        faith to settle such Dispute by providing written notice to the other party describing the facts and
        circumstances of the Dispute and allowing the receiving party thirty (30) days in which to respond to
        or settle the Dispute. Notice shall be sent to us at:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Spark Ideas, LLC</span></p>
    <p class="c4"><span class="c5">1328 Ridge Road</span></p>
    <p class="c4"><span class="c5">Syosset, New York 11791</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Both you and Spark agree that this dispute resolution procedure is a condition precedent that must be
        satisfied before initiating any litigation or filing any claim against the other party. IF ANY DISPUTE
        CANNOT BE RESOLVED BY THE ABOVE DISPUTE RESOLUTION PROCEDURE, YOU AGREE THAT THE SOLE AND EXCLUSIVE
        JURISDICTION FOR SUCH DISPUTE WILL BE DECIDED BY BINDING ARBITRATION ON AN INDIVIDUAL BASIS.
        ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE
        OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR
        REPRESENTATIVE CAPACITY. Other rights that you and we would otherwise have in court will not be
        available or will be more limited in arbitration, including discovery and appeal rights. All such
        disputes shall be exclusively submitted to JAMS (www.jamsadr.com) for binding arbitration under its
        rules then in effect, before one arbitrator to be mutually agreed upon by both parties. The arbitrator
        will conduct hearings, if any, by teleconference or videoconference, rather than by personal
        appearances, unless the arbitrator determines upon request by you or by us that an in-person hearing
        is appropriate. Any in-person appearances will be held at a location which is reasonably convenient to
        both parties with due consideration of their ability to travel and other pertinent circumstances. If
        the parties are unable to agree on a location, such determination will be made by JAMS or by the
        arbitrator.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority
        to resolve any dispute arising under or relating to the interpretation, applicability, enforceability,
        or formation of this Privacy Policy, including any claim that all or any part of this Privacy Policy
        is void or voidable.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Choice of Law</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >This Privacy Policy has been made in, and shall be construed in accordance with, the laws of the
        State of New York, without giving effect to any conflict of law principles. The parties acknowledge
        that this Privacy Policy evidences a transaction involving interstate commerce. Notwithstanding the
        provision in the preceding paragraph with respect to applicable substantive law, any arbitration
        conducted pursuant to the terms of this Privacy Policy shall be governed by the Federal Arbitration
        Act (9 U.S.C. &sect;&sect; 1-16).</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >Your California Privacy Rights and How We Respond to &ldquo;Do Not Track&rdquo; Signals</span
      >
    </p>
    <p class="c4">
      <span class="c5"
        >California Civil Code Section 1798.83 permits visitors to the Sites and/or the Mobile App, who are
        California residents to request certain information regarding our disclosure of personal information
        to third parties for their direct marketing purposes. We do not share your personal information with
        third parties for their direct marketing purposes.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4">
      <span class="c5"
        >At this time, our Sites do not recognize automated browser signals regarding tracking mechanisms,
        which may include &ldquo;Do Not Track&rdquo; instructions.</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Contact Information</span></p>
    <p class="c4">
      <span class="c5"
        >Spark welcomes your questions or comments regarding this Privacy Policy and our privacy practices.
        Please contact us at:</span
      >
    </p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Spark Ideas, LLC</span></p>
    <p class="c4"><span class="c5">1328 Ridge Road</span></p>
    <p class="c4"><span class="c5">Syosset, New York 11791</span></p>
    <p class="c4"><span class="c5">United States of America</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Email Address:</span></p>
    <p class="c4"><span class="c5">privacy@spark-nyc.com</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Telephone number:</span></p>
    <p class="c4"><span class="c5">(917) 991-0477</span></p>
    <p class="c4 c10"><span class="c5"></span></p>
    <p class="c4"><span class="c5">Changes to this Privacy Policy</span></p>
    <p class="c4">
      <span class="c5"
        >Spark will occasionally update this Privacy Policy to reflect company and customer feedback. When we
        do, we will also revise the &ldquo;Effective&rdquo; date at the top of this Privacy Policy. If
        material changes are made to the Privacy Policy, we will notify you by placing a prominent notice on
        the Sites and the Mobile App. We encourage you to periodically review this Privacy Policy to be
        informed of how Spark is handling your information.</span
      >
    </p>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyEN',
  components: { LLWordTextContainer },
  computed: {
    linkToSubProcessors() {
      return `${process.env.VUE_APP_ADMIN_PROTOCOL}://${process.env.VUE_APP_ADMIN_HOST}/sub-processors`
    }
  }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyEN {
  max-width: 730px;
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_7-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_list_7-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_4-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '\0025a0  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-4 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_1-7 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_1-5 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_list_1-8 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c9 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 318pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c1 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c21 {
    padding-top: 0pt;
    padding-bottom: 3pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c5 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c20 {
    padding-top: 0pt;
    padding-bottom: 8pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c11 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;

    text-decoration: underline;
  }
  .c22 {
    margin-left: -5pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c17 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c14 {
    padding: 0;
    margin: 0;
  }
  .c6 {
    text-decoration: inherit;
  }
  .c12 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c18 {
    margin-left: 36pt;
  }
  .c10 {
    height: 11pt;
  }
  .c16 {
    height: 24.2pt;
  }
  .c19 {
    height: 144.4pt;
  }
  .c7 {
    height: 78.2pt;
  }
  .c13 {
    height: 1.2pt;
  }
  .c23 {
    height: 37.8pt;
  }
  .title {
    padding-top: 0pt;

    padding-bottom: 3pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;

    padding-bottom: 16pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
  }
  p {
    margin: 0;
  }
  h1 {
    padding-top: 20pt;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;

    padding-bottom: 4pt;

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
