<template>
  <svg
    class="icon stroke-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1445 2.12053C17.5151 1.49112 16.7538 1.10148 16.0045 1.01756C15.1653 0.921647 14.3561 1.22137 13.7267 1.85078L13.4629 2.11454C13.4629 2.11454 13.4569 2.11454 13.4569 2.12053L11.8084 3.769L11.8025 3.77499L2.34925 13.2222C2.25934 13.3121 2.20539 13.426 2.17541 13.5399L1.01849 18.4673C0.964541 18.6891 1.03048 18.9169 1.19233 19.0787C1.31821 19.2046 1.48006 19.2706 1.6539 19.2706C1.70185 19.2706 1.7558 19.2646 1.80376 19.2526L6.72518 18.0957C6.83308 18.0717 6.94098 18.0177 7.03089 17.9338C7.03689 17.9278 7.04288 17.9218 7.04888 17.9158L16.5081 8.45663C16.5081 8.45663 16.5141 8.45064 16.5141 8.44464L18.1445 6.80817L18.4143 6.53842C19.0437 5.909 19.3374 5.09976 19.2475 4.26054C19.1636 3.50524 18.774 2.74395 18.1445 2.12053ZM2.52309 17.742L3.17648 14.9726L5.29851 17.0886L2.52309 17.742ZM6.58131 16.5371L3.73396 13.6898L12.27 5.14771L15.1174 7.99506L6.58131 16.5371ZM16.0345 7.07792L13.1872 4.23057L13.9185 3.49925L16.7658 6.3466L16.0345 7.07792ZM14.8536 2.59409C15.1533 2.3663 15.489 2.2644 15.8607 2.30636C16.3163 2.35431 16.8018 2.61807 17.2214 3.03768C17.641 3.45729 17.8988 3.94283 17.9527 4.39841C17.9947 4.76407 17.8928 5.10575 17.665 5.40547L14.8536 2.59409Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditIcon2'
}
</script>
