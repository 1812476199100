<template>
  <div class="DashboardPage">
    <DashboardWelcome class="DashboardPage__welcome"></DashboardWelcome>
    <DashboardBody class="DashboardPage__tabs"></DashboardBody>
  </div>
</template>

<script>
import DashboardWelcome from '@/components/pages/dashboard/DashboardWelcome'
import DashboardBody from '@/components/pages/dashboard/DashboardBody'
export default {
  name: 'DashboardPage',
  components: { DashboardBody, DashboardWelcome }
}
</script>

<style lang="scss" scoped>
.DashboardPage {
  &__welcome {
    @apply mx-auto w-full;
  }
  &__tabs {
    @apply mt-6;
  }
}
</style>
