<template>
  <div class="StudyConversationMessageEditorTextDesktop" :class="classList">
    <ValidationProvider
      class="StudyConversationMessageEditorTextMobile__textarea-wrapper"
      name="messageText"
      rules="required"
      immediate
    >
      <LLTextarea
        v-model="localMessage.text"
        size="compact"
        dynamic-size
        :max-height="200"
        :placeholder="localMessage.files.length > 0 ? $t('add_comment_or_send') : $t('type_here')"
        data-e2e="questionInput"
        class="StudyConversationMessageEditorTextMobile__textarea"
      >
        <template v-if="localMessage.files.length" #bottom-content>
          <LLFileSlider
            class="StudyConversationMessageEditorTextDesktop__files"
            :files.sync="localMessage.files"
            :editable="true"
          ></LLFileSlider>
        </template>
      </LLTextarea>
    </ValidationProvider>
    <div
      v-if="isFileUploaderVisible || isActionsVisible"
      class="StudyConversationMessageEditorTextDesktop__footer"
    >
      <div
        v-if="isFileUploaderVisible"
        class="StudyConversationMessageEditorTextDesktop__file-uploader-wrapper"
      >
        <LLFileUploader
          class="StudyConversationMessageEditorTextDesktop__file-uploader"
          :preparable="!localMessage.id"
          :study-id="localMessage.studyId"
          @startUpload="onStartUpload"
          @prepare="onPrepare"
          @upload="onUpload"
        ></LLFileUploader>
        <LLUploadProgress
          class="StudyConversationMessageEditorTextDesktop__file-uploader-progress"
          :uploading-files-uuids.sync="uploadingFilesUuids"
        ></LLUploadProgress>
      </div>
      <div v-if="isActionsVisible" class="StudyConversationMessageEditorTextDesktop__actions-wrapper">
        <LLButton
          small
          :loading="isSendPending"
          :disabled="!isValid"
          primary
          class="StudyConversationMessageEditorTextDesktop__action"
          @click="onSendClick"
        >
          <template v-if="message.id">{{ $t('button_save') }}</template>
          <template v-else>{{ $t('button_send') }}</template>
        </LLButton>

        <LLButton
          v-if="isAbleToCancel"
          class="StudyConversationMessageEditorTextDesktop__action"
          small
          secondary
          :disabled="isSendPending"
          @click="onCancelClick"
          >{{ $t('button_cancel') }}</LLButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { QuestionModel } from '@/models/question'
import LLTextarea from '@/components/common/LLTextarea.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import LLFileUploader from '@/components/common/LLFileUploader/LLFileUploader.vue'
import LLUploadProgress from '@/components/common/LLUploadProgress.vue'
import { ConversationMessageFileModel } from '@/models/conversationMessageFile'
import LLButton from '@/components/common/ui-components/LLButton.vue'

export default {
  name: 'StudyConversationMessageEditorTextDesktop',
  components: {
    LLButton,
    LLUploadProgress,
    LLFileUploader,
    LLFileSlider,
    LLTextarea,
    ValidationProvider
  },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true },
    question: { type: QuestionModel, required: true },
    isSendPending: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false },
    isAbleToCancel: { type: Boolean, default: false }
  },
  data() {
    return {
      localMessage: null,
      uploadingFilesUuids: []
    }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_screening`]: this.options.type === StudyConversationMessageType.SCREENING
      }
    },
    isActionsVisible() {
      return this.options.type !== StudyConversationMessageType.SCREENING
    },
    isFileUploaderVisible() {
      return this.options.type !== StudyConversationMessageType.SCREENING
    }
  },
  watch: {
    message: {
      handler() {
        if (!this.$_.isEqual(this.message, this.localMessage)) {
          this.localMessage = new StudyConversationMessageModel(this.message)
        }
      },
      deep: true,
      immediate: true
    },
    localMessage: {
      handler() {
        this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
      },
      deep: true
    }
  },
  methods: {
    onSendClick() {
      this.$emit('sendClick')
    },
    onCancelClick() {
      this.$emit('cancelClick')
    },
    onPrepare(file) {
      this.localMessage.files = [
        ...this.localMessage.files,
        new ConversationMessageFileModel({
          ...file,
          prospectId: this.message.prospectId,
          messageId: this.message.id,
          studyId: this.message.studyId
        })
      ]
    },
    onStartUpload(uploadingFilesUuids) {
      this.uploadingFilesUuids = uploadingFilesUuids
    },
    onUpload(fileList) {
      const convertedFileList = fileList.map(
        (file) =>
          new ConversationMessageFileModel({
            ...file,
            questionId: this.message.questionId,
            prospectId: this.message.prospectId,
            messageId: this.message.id,
            studyId: this.message.studyId
          })
      )
      this.localMessage.files = [...this.localMessage.files, ...convertedFileList]
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageEditorTextDesktop {
  $root: &;
  &:not(#{$root}_screening) {
    @apply pr-6 pl-7 py-6 bg-neutral-01-25 rounded relative;
    &:before {
      @apply w-1 h-full absolute bg-neutral-01-100 top-0 left-0;
      content: '';
    }
  }

  &__file-uploader-wrapper {
    @apply flex justify-between items-center flex-wrap;
  }
  &__file-uploader {
    @apply mr-6;
  }
  &__footer {
    @apply flex justify-between mt-4;
  }
  &__actions-wrapper {
    @apply flex;
  }
  &__action {
    @apply ml-2;
    &:first-child {
      @apply ml-0;
    }
  }
}
</style>
