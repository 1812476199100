import { v4 as uuidV4 } from 'uuid'
import { FileModel } from '@/models/file'

export class QuestionChoiceOptionModel {
  constructor(questionChoiceOption = {}) {
    const { id, text, image, isChoiceOrderFixed } = questionChoiceOption
    this.id = id || null
    this.text = text || ''
    this.image = image ? new FileModel(image) : null
    this.isChoiceOrderFixed = isChoiceOrderFixed || false
  }

  static parseFromApi({ questionChoiceOption }) {
    if (typeof questionChoiceOption === 'string') {
      return new QuestionChoiceOptionModel({
        id: questionChoiceOption?.id,
        text: questionChoiceOption,
        image: questionChoiceOption?.image ? new FileModel(questionChoiceOption?.image) : null
      })
    }
    return new QuestionChoiceOptionModel({
      id: questionChoiceOption?.id,
      text: questionChoiceOption?.text,
      image: questionChoiceOption?.image ? new FileModel(questionChoiceOption?.image) : null,
      isChoiceOrderFixed: questionChoiceOption.isChoiceOrderFixed
    })
  }

  generateId() {
    this.id = uuidV4()
  }

  static create() {
    return new QuestionChoiceOptionModel({
      id: uuidV4(),
      text: '',
      image: null,
      isChoiceOrderFixed: false
    })
  }

  getApiObject() {
    return {
      id: this.id,
      text: this.text,
      imageId: this.image?.id || undefined,
      isChoiceOrderFixed: this.isChoiceOrderFixed || undefined
    }
  }
}
