import Vue from 'vue'
import App from './App.vue'
import Api from './services/api'
import routerPush from './mixins/routerPush'
import RouterPushPlugin from './plugins/routerPush'
import _ from 'lodash'

window.addEventListener('error', function (e) {
  console.error(e)
  try {
    Api.system.sendError({ message: e.message, stack: e.stack, e })
  } catch {}
})
window.addEventListener('unhandledrejection', function (e) {
  console.error(e)
  try {
    Api.system.sendError({ message: e.reason.message, stack: e.reason.stack, e })
  } catch {}
})
Vue.config.errorHandler = function (e) {
  console.error(e)
  try {
    Api.system.sendError({ message: e.message, stack: e.stack, e })
  } catch (e) {}
}

require('./services/api')
require('./services/conversationsSocket')
require('./imports')
require('./plugins/vee-validate')
require('./plugins/combined-inject')

require('./plugins/portal-vue')
require('./plugins/vue-tooltip')
require('./plugins/vue-inputmask')
require('./plugins/vue-js-modal')
require('./plugins/notification')

import router from './router'
import store from './store'
import i18n from './i18n'

import './assets/styles/globals.scss'
import './assets/styles/_variables.scss'
import './assets/styles/_transitions.scss'

import './registerServiceWorker'

Vue.config.productionTip = false
Vue.prototype.$_ = _

Vue.prototype.$routerPush = new RouterPushPlugin()

new Vue({
  mixins: [routerPush],
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
