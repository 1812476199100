<template>
  <div>
    <PieChart
      class="PollResultsChartPie"
      :width="450"
      :height="300"
      :chart-data="chartData"
      :options="options"
    ></PieChart>
  </div>
</template>

<script>
import PieChart from '@/components/common/PieChart.vue'

export default {
  name: 'PollResultsChartPie',
  components: { PieChart },
  props: {
    chartPieData: { type: Object, required: true },
    chartPieOptions: { type: Object, default: null },
    showLegend: { type: Boolean, default: false },
    dataLabelCallback: { type: Function, default: null },
    tooltipLabelCallback: { type: Function, default: null },
    dataLabelsAnchor: { type: String, default: 'start' },
    dataLabelsAlign: { type: String, default: 'end' },
    dataLabelsTextAlign: { type: String, default: 'end' }
  },
  computed: {
    chartData() {
      return this.$_.merge({}, this.chartPieData)
    },
    options() {
      return this.$_.merge(
        {
          responsive: true,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },

          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: this.showLegend,
              labels: {
                filter: () => {
                  return /* this.question.type === 70 ? item.text !== 'Offset' : true */ true
                }
              }
            },
            datalabels: {
              color: '#FFFFFF',
              clamp: true,
              display: (context) => {
                const value = context.dataset.data[context.dataIndex]
                return value > 5
              },
              font: {
                family: 'Roboto',
                weight: 'bold',
                size: '14'
              },
              formatter: this.dataLabelCallback || undefined,
              offset: 55,
              anchor: this.dataLabelsAnchor,
              align: this.dataLabelsAlign,
              textAlign: this.dataLabelsTextAlign
            },
            tooltip: {
              callbacks: {
                label: this.tooltipLabelCallback || undefined
              }
            }
          }
        },
        this.chartPieOptions
      )
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsChartPie {
}
</style>
