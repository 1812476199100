<template>
  <div class="LLSpoilerTogglerCircleArrow" :class="classList">
    <CircleArrowUpIcon
      class="LLSpoilerTogglerCircleArrow__icon"
      :class="{ LLSpoilerTogglerCircleArrow__icon_expanded: expanded }"
    ></CircleArrowUpIcon>
    <div class="LLSpoilerTogglerCircleArrow__title">
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>
import CircleArrowUpIcon from '@/assets/icons/CircleArrowUpIcon'
export default {
  name: 'LLSpoilerTogglerCircleArrow',
  components: { CircleArrowUpIcon },
  props: {
    expanded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      const classes = []
      const rootClass = 'LLSpoilerTogglerCircleArrow'
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSpoilerTogglerCircleArrow {
  $root: &;
  @apply flex text-left;
  &_disabled {
    #{$root}__icon {
      @apply text-neutral-01-400;
    }
    #{$root}__title {
      @apply text-neutral-01-400;
    }
  }
  &_small {
    #{$root}__icon {
      @apply w-5 h-5;
    }
    #{$root}__title {
      @apply text-body-02 ml-2;
    }
  }
  &__icon {
    @apply text-primary-01-400 duration-200 flex-shrink-0  transition-all transition transform rotate-90;
    &_expanded {
      @apply rotate-180;
    }
  }
  &__title {
    @apply ml-4 text-primary-01-400 text-subheading-01 min-w-0 flex-1 break-words;
  }
}
</style>
