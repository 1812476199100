<template>
  <div class="SearchContentHeader">
    <slot name="actions"></slot>
    <div class="SearchContentHeader__delimiter"></div>
    <slot name="selectors"></slot>
  </div>
</template>

<script>
export default {
  name: 'SearchContentHeader'
}
</script>

<style lang="scss" scoped>
.SearchContentHeader {
  &__delimiter {
    @apply w-full h-px bg-neutral-01-400 mt-2 mb-4;
  }
}
</style>
