/**
 * Pad a number with leading zeros
 *
 * @param {*} number
 * @param {*} width
 * @param {*} z
 *
 * pad(10, 4);      // 0010
 * pad(9, 4);       // 0009
 * pad(123, 4);     // 0123
 * pad(10, 4, '-'); // --10
 */

export function pad(number, width, z = '0') {
  number = number + ''

  return number.length >= width ? number : new Array(width - number.length + 1).join(z) + number
}

export function getLocation({ country, region, city }) {
  try {
    const concatArray = []
    if (city) concatArray.push(city)
    if (region) concatArray.push(region)
    if (country) concatArray.push(country)
    if (concatArray.length) {
      return concatArray.join(', ')
    }
  } catch {
    return ''
  }
}

export function getTwoLetters(string) {
  let splicedString = string.replace(/\s+/g, ' ').trim().split(' ') || []
  splicedString = splicedString
    .map((s) => s?.replace(/[\W]/g, ''))
    .filter(
      (s) =>
        typeof s === 'string' &&
        s?.length !== 0 &&
        s.toLowerCase() !== 'a' &&
        s.toLowerCase() !== 'the' &&
        s.toLowerCase() !== 'to'
    )
  if (!splicedString.length) {
    return null
  }
  const resultString = []
  if (splicedString.length >= 2) {
    resultString.push(splicedString?.[0]?.charAt(0)?.toUpperCase())
    resultString.push(splicedString?.[1]?.charAt(0)?.toUpperCase())
  } else {
    resultString.push(splicedString[0]?.slice(0, 2)?.toUpperCase())
  }
  return resultString.join('')
}
