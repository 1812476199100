var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-section w-full",class:{
    'is-expanded': _vm.isVisible,
    'header-not-separated': _vm.headerNotSeparated,
    'disable-body-padding': _vm.disableBodyPadding,
    'is-white': _vm.white,
    'arrow-on-top': _vm.arrowOnTop,
    'is-disabled': _vm.disabled,
    'is-light': _vm.light,
    'is-clean': _vm.clean
  }},[_c('div',{staticClass:"add-section__header",on:{"click":function($event){$event.preventDefault();return _vm.toggleVisibility.apply(null, arguments)}}},[(_vm.$slots['beforeNumber'])?_c('div',{staticClass:"overflow-ellipsis ml-4 text-primary-01 py-4"},[_vm._t("beforeNumber")],2):_vm._e(),(_vm.$slots['number'])?_c('div',{staticClass:"add-section__add-icon w-8 h-8 rounded-full bg-neutral-01-25 flex justify-center items-center"},[_vm._t("number")],2):_vm._e(),(_vm.$slots['title'])?_c('div',{staticClass:"add-section__title ml-2 overflow-ellipsis overflow-hidden whitespace-nowrap",attrs:{"data-e2e":"title"}},[_vm._t("title")],2):_vm._e(),(_vm.$slots['wrap-title'])?_c('div',{staticClass:"add-section__title ml-4"},[_vm._t("wrap-title")],2):_vm._e(),(_vm.$slots['actions'])?_c('div',{staticClass:"add-section__actions",on:{"click":_vm.onActionsClick}},[_vm._t("actions")],2):_vm._e(),(_vm.$slots['status'])?_c('div',{staticClass:"add-section__actions"},[_vm._t("status")],2):_vm._e(),_c('div',{staticClass:"add-section__toggle-icon text-primary-01-400"},[_c('ArrowDown')],1)]),(_vm.isVisible)?_c('div',{staticClass:"add-section__body p-6 mob:p-4 border border-neutral-01-50 bg-neutral-01-15"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }