var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LLFileSlider w-full",class:{
    LLFileSlider_overflowed: _vm.overflowed,
    LLFileSlider_small: _vm.small,
    LLFileSlider_large: _vm.large,
    LLFileSlider_medium: _vm.medium,
    'LLFileSlider_pre-large': _vm.preLarge,
    LLFileSlider_single: _vm.single,
    'LLFileSlider_small-container': _vm.smallContainer
  }},[_c('div',{staticClass:"LLFileSlider__container"},[_c('div',{staticClass:"LLFileSlider__files-wrapper"},[_c('div',{ref:"files",staticClass:"LLFileSlider__files"},[_c('div',{ref:"files-inner",staticClass:"LLFileSlider__files-inner"},_vm._l((_vm.files),function(file){return _c('div',{key:file.id,staticClass:"LLFileSlider__file"},[_c('LLFileSliderFile',{attrs:{"small":_vm.small,"large":_vm.large || _vm.preLarge || _vm.medium,"file":file},on:{"open":function($event){return _vm.openModal(file)}}}),(_vm.editable)?_c('a',{staticClass:"LLFileSlider__file-remove",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.removeAttachedFiles($event, file)}}},[_c('ClearSolidIcon',{staticClass:"text-accent-01"})],1):_vm._e()],1)}),0)])]),(_vm.overflowed)?_c('div',{staticClass:"LLFileSlider__arrows"},[_c('button',{staticClass:"LLFileSlider__arrow",on:{"click":_vm.swipeLeft}},[_c('ArrowRight')],1),_c('button',{staticClass:"LLFileSlider__arrow",on:{"click":_vm.swipeRight}},[_c('ArrowRight')],1)]):_vm._e()]),(_vm.selectedFile)?_c('LLFileModal',{attrs:{"files":_vm.viewableFiles,"selected-file":_vm.selectedFile},on:{"update:selectedFile":function($event){_vm.selectedFile=$event},"update:selected-file":function($event){_vm.selectedFile=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }