<template>
  <div class="SearchResponsesItemChatContext">
    <LLChevron class="SearchResponsesItemChatContext__chevron" :opened.sync="contextOpened">
      <div class="SearchResponsesItemChatContext__chevron-label">
        {{ $t('search-page_responses_see-in-context') }}
      </div>
      <template #arrow>
        <ArrowMiniIcon class="SearchResponsesItemChatContext__chevron-arrow"></ArrowMiniIcon>
      </template>
    </LLChevron>
    <div v-if="contextOpened" class="SearchResponsesItemChatContext__content">
      <div class="SearchResponsesItemChatContext__thread-title">
        {{ $t('search-page_responses_thread_name', { name: thread.title }) }}
      </div>
      <LLTimestamp class="SearchResponsesItemChatContext__date" :timestamp="threadResponse.createdAt">
        <template #default="data">
          {{ $tc('timestamp_replied', data.time) }}
        </template>
      </LLTimestamp>
      <LLWysiwygOutput
        class="SearchResponsesItemChatContext__response"
        :text="response.text"
      ></LLWysiwygOutput>
      <LLFileSlider
        v-if="files.length"
        class="SearchResponsesItemChatContext__files"
        :files="files"
        small
      ></LLFileSlider>
      <router-link
        :to="{
          path: `/chats/${threadResponse.chatId}/threads/${threadResponse.threadId}`,
          query: nuxtLinkQuery
        }"
        target="_blank"
      >
        <LLButton class="SearchResponsesItemChatContext__go-to-conversation-button" small>{{
          $t('search-page_responses_see-in-chat')
        }}</LLButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LLChevron from '@/components/common/LLChevron'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLButton from '@/components/common/ui-components/LLButton'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
import LLFileSlider from '@/components/common/LLFileSlider'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import LLTimestamp from '@/components/common/LLTimestamp'
export default {
  name: 'SearchResponsesItemChatContext',
  components: { LLTimestamp, LLFileSlider, LLWysiwygOutput, LLButton, LLChevron },
  props: {
    response: { type: Object, required: true }
  },
  data() {
    return {
      contextOpened: false
    }
  },
  computed: {
    threadResponse() {
      return ForumResponseModel.parseFromApi({
        response: this.response?.threadResponse,
        threadId: this.response?.thread?.id,
        prospect: this.response.prospect,
        user: this.response.user,
        chatId: this.response?.chat?.id
      })
    },
    nuxtLinkQuery() {
      return {
        goToResponseId: this.threadResponse?.id
      }
    },
    thread() {
      return ForumThreadModel.parseFromApi({ thread: this.response?.thread })
    },
    files() {
      return this.threadResponse?.files || []
    },
    repliedToTime() {
      moment.locale(this.$i18n.locale)
      const currentTime = this.$store.state.currentTime || Date.now()
      return moment
        .unix(this.threadResponse.createdAt / 1000)
        .from(
          moment.unix(
            currentTime < this.threadResponse.createdAt
              ? this.threadResponse.createdAt / 1000 + 1
              : currentTime / 1000
          )
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemChatContext {
  &__chevron-arrow {
    @apply text-accent-01-400;
  }
  &__chevron-label {
    @apply text-caption-01 text-primary-01-400;
  }
  &__content {
    @apply mt-2.5 flex flex-col items-start;
  }
  &__thread {
    &-title {
      @apply text-caption-01 text-primary-01-400 break-words;
      max-width: 100%;
    }
  }
  &__date {
    @apply text-caption-02 text-neutral-01-400 mt-2;
  }
  &__response {
    @apply text-body-03 mt-2 break-words;
    max-width: 100%;
  }
  &__go-to-conversation-button {
    @apply mt-5;
  }
  &__files {
    @apply mt-3;
  }
}
</style>
