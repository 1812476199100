import { CompleteRegistrationDestinationType } from '@/common/enums'

const initialState = () => ({
  dataCollected: true,
  passwordSet: false,
  email: '',
  destination: null,
  token: '',
  studyId: null,
  chatId: null
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    destinationIsStudy(state) {
      return state.destination === CompleteRegistrationDestinationType.STUDY
    },
    destinationIsDashboard(state) {
      return state.destination === CompleteRegistrationDestinationType.DASHBOARD
    },
    destinationIsChat(state) {
      return state.destination === CompleteRegistrationDestinationType.CHAT
    }
  },
  mutations: {
    SET_DATA_COLLECTED(state, status) {
      state.dataCollected = status
    },
    SET_PASSWORD_SET(state, status) {
      state.passwordSet = status
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_EMAIL(state, mail) {
      state.email = mail
    },
    SET_DESTINATION_DASHBOARD(state) {
      state.destination = CompleteRegistrationDestinationType.DASHBOARD
    },
    SET_DESTINATION_STUDY(state, { studyId }) {
      state.destination = CompleteRegistrationDestinationType.STUDY
      state.studyId = studyId
    },
    SET_DESTINATION_CHAT(state, { chatId }) {
      state.destination = CompleteRegistrationDestinationType.CHAT
      state.chatId = chatId
    },
    RESET_DESTINATION(state) {
      state.destination = null
      state.studyId = null
      state.chatId = null
    },
    REMOVE_ALL(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    }
  },
  actions: {
    reset({ commit }) {
      commit('REMOVE_ALL')
    }
  }
}
