import axios from 'axios'
import store from '../../store'
import eventBus from '../../services/eventBus'
import globalRoutes from '@/utils/globalRoutes'
import router from '../../router'

let apiUrl = globalRoutes.api + '/api/pwa'

const Config = () => ({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
})

const GetBody = (body, type) => {
  if (type === 'multipart/form-data') {
    /* let form_data = new FormData();

    for ( let key in body ) {
      form_data.append(key, body[key]);
    }*/
    return body
  } else {
    return body
  }
}

const secureConfig = () => {
  if (!store.state.auth.token) {
    throw new Error('No authorised')
  }
  let conf = Config()
  conf.headers.Authorization = store.state.auth.token
  return conf
}

const getAxios = (secured, type) => {
  let conf = null
  if (secured) {
    conf = secureConfig()
  } else {
    conf = Config()
  }

  if (type === 'multipart/form-data') conf.headers['Content-Type'] = 'multipart/form-data'

  let HTTP = axios.create(conf)

  HTTP.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        error?.response?.status === 503 &&
        error?.response?.data?.key === 'SERVICE_UNAVAILABLE' &&
        !window.location.href.includes('/maintenance')
      ) {
        router.push('/maintenance')
        return Promise.resolve()
      }
      // Do something with response error
      if (!error?.response?.status) {
        eventBus.$emit('internet-connection-error')
      }
      if (error?.response?.status === 500 || error?.response?.status === 401) {
        console.log('should logout')
        //store.dispatch('auth/logOut')
        //router.push('/sign-in')
      }
      return Promise.reject(error)
    }
  )

  return HTTP
}

export default {
  post(url, body, secured, type, axiosConfig) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .post(url, GetBody(body, type), { ...axiosConfig, withCredentials: true })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  postWithConfig(url, body, config, secured) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .post(url, body, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  getWithConfig(url, config, secured) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .get(url, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  get(url, body, secured) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .get(url, {
          params: body || {}
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  put(url, body, secured, type) {
    return new Promise((resolve, reject) => {
      getAxios(secured, type)
        .put(url, GetBody(body, type) || {})
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  patch(url, body, secured, type) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .patch(url, GetBody(body, type) || {})
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          error.name = 'NetworkError'
          reject(error)
        })
    })
  },
  delete(url, body, secured) {
    return new Promise((resolve, reject) => {
      getAxios(secured)
        .delete(url, { params: body || {} })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
