<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.89332 8.0001C1.89332 4.62749 4.62736 1.89344 7.99998 1.89344C11.3726 1.89344 14.1067 4.62749 14.1067 8.0001C14.1067 11.3727 11.3726 14.1068 7.99998 14.1068C4.62736 14.1068 1.89332 11.3727 1.89332 8.0001ZM7.99998 0.773438C4.0088 0.773438 0.773315 4.00893 0.773315 8.0001C0.773315 11.9913 4.0088 15.2268 7.99998 15.2268C11.9912 15.2268 15.2266 11.9913 15.2266 8.0001C15.2266 4.00893 11.9912 0.773438 7.99998 0.773438ZM8.55997 4.00062C8.55997 3.69135 8.30925 3.44062 7.99997 3.44062C7.69069 3.44062 7.43997 3.69135 7.43997 4.00062V8.00062C7.43997 8.21274 7.55981 8.40664 7.74953 8.5015L10.4162 9.83484C10.6928 9.97315 11.0292 9.86102 11.1675 9.5844C11.3058 9.30777 11.1937 8.97139 10.9171 8.83308L8.55997 7.65453V4.00062Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon'
}
</script>
