<template>
  <LLWordTextContainer class="PrivacyPolicyNL">
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >De bescherming van uw gegevens is onze prioriteit. Dit Privacybeleid is van toepassing op de
        websites, apps en diensten onder beheer van Spark Ideas, LLC (&ldquo;Spark,&rdquo; &ldquo;wij,&rdquo;
        ons&rdquo; of &ldquo;onze&rdquo;), inclusief www.spark-nyc.com en https://looklook.app (collectief: de
        &ldquo;Sites&rdquo;) en de LookLook mobiele app (de &ldquo;Mobiele App&rdquo;). Lees dit Privacybeleid
        zorgvuldig, omdat u door het gebruik van de Websites of de Mobiele App akkoord gaat met de
        gegevenspraktijken zoals omschreven in dit beleid.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Uw gebruik van de Sites of de Mobiele App is tevens onderworpen aan de Servicevoorwaarden, die hier
        kunnen worden ingezien.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >BELANGRIJKE MEDEDELING: HOUD ER REKENING MEE DAT DE HIERONDER UITEENGEZETTE ARBITRATIEBEPALING, DIE
        VAN U VEREIST, BEHALVE WAAR EN VOOR ZOVER NIET UITGESLOTEN DOOR WETGEVING, DAT U EVENTUELE CLAIMS TEN
        OPZICHTE VAN SPARK OP INDIVIDUELE BASIS LAAT ARBITREREN. ARBITRAGE OP EEN INDIVIDUELE BASIS HOUDT IN
        DAT U GEEN RECHT HEBT OP, EN DAARVAN AFZIET, OP EEN RECHTER OF EEN JURY DIE OORDEELT OVER UW CLAIMS,
        EN DAT U GEEN PROCES MAG VOEREN OP BASIS VAN GEZAMENLIJKHEID OF IN DE HOEDANIGHEID VAN EEN
        VERTEGENWOORDIGER.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Verzameling en Gebruik van Uw Persoonsgegevens</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >&ldquo;Persoonsgegevens&rdquo; betreft elke informatie in relatie met een ge&iuml;dentificeerde of
        identificeerbare natuurlijke persoon.
      </span>
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span>We verzamelen de volgende informatie van en over u:</span></p>
    <hr style="page-break-before: always; display: none" />
    <p class="c1"><span class="c2"></span></p>
    <p class="c1"><span class="c2"></span></p>
    <a id="t.1b90e7a161979baf98fc7a4d2582e248d2fcbbc1"></a><a id="t.0"></a>
    <table class="c14">
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Demografische gegevens</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">We gebruiken deze gegevens voor</span></p>
        </td>
      </tr>
      <tr class="c23">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Naam</span></p>
          <p class="c0"><span class="c2">Telefoonnummer:</span></p>
          <p class="c0"><span class="c2">Stad, staat en land</span></p>
          <p class="c0"><span class="c2">E-mailadres</span></p>
          <p class="c0"><span class="c2">Functie</span></p>
          <p class="c0"><span class="c2">Bedrijf</span></p>
          <p class="c0"><span class="c2">Opleiding</span></p>
          <p class="c0"><span class="c2">Inkomensschijf</span></p>
          <p class="c0"><span class="c2">Huwelijkse status</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
          <p class="c0"><span class="c2">- Uitvoeren onderzoek</span></p>
          <p class="c0">
            <span class="c2"
              >- Communiceren met klanten en prospects (e-mail, contactformulieren, WeChat)</span
            >
          </p>
          <p class="c0">
            <span class="c2"
              >- Contact opnemen met deelnemers voor eventuele toekomstige marketing- en
              research-onderzoeken.</span
            >
          </p>
          <p class="c0">
            <span class="c2">- Klanten in staat stellen om ons onderzoeksplatform te gebruiken</span>
          </p>
          <p class="c0"><span class="c2">- Oplossen van problemen</span></p>
          <p class="c0">
            <span>- Aanbieden van klantondersteuning<br /><br /></span><span class="c8">Juridische basis</span
            ><span class="c2">: toestemming, contract, gerechtigd belang.</span>
          </p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Voorkeuren</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Hobbies</span></p>
          <p class="c0"><span class="c2">Interesses</span></p>
          <p class="c0"><span class="c2">Shoppinggewoonten</span></p>
          <p class="c0"><span class="c2">Merkvoorkeur</span></p>
          <p class="c0"><span class="c2">Aankoopgeschiedenis</span></p>
          <p class="c0"><span class="c2">Overige onderwerpen relevant voor het onderzoek</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0">
            <span class="c2">- Uitvoeren onderzoek<br /></span>
          </p>
          <p class="c0">
            <span><br /><br /><br /><br /><br /><br /><br /></span><span class="c8">Juridische basis</span
            ><span class="c2">: toestemming</span>
          </p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c8 c12">Media</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Foto&#39;s</span></p>
          <p class="c0"><span class="c2">Video&#39;s</span></p>
          <p class="c0"><span class="c2">Stem</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">- Uitvoeren onderzoek</span></p>
          <p class="c0">
            <span><br /><br /></span><span class="c8">Juridische basis</span
            ><span class="c2">: toestemming</span>
          </p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Communicatie</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Deelnemerinterviews</span></p>
          <p class="c0">
            <span class="c2">Algemene inlichtingen (e-mail of contact voor klantverzoeken</span>
          </p>
          <p class="c0"><span class="c2">Klantondersteuning</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">- Uitvoeren onderzoek</span></p>
          <p class="c0">
            <span class="c2"
              >- Communiceren met klanten en prospects (e-mail, contactformulieren, WeChat)</span
            >
          </p>
          <p class="c0"><span class="c2">- Aanbieden van klantondersteuning</span></p>
          <p class="c0">
            <span>- Verdelen van beloningen<br /><br /></span><span class="c8">Juridische basis</span
            ><span class="c2">: toestemming, contract</span>
          </p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Technologie</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Stad geolocatie</span></p>
          <p class="c0"><span class="c2">IP-adres</span></p>
          <p class="c0"><span class="c2">Apparaat- en browsergegevens</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">- Uitvoeren onderzoek</span></p>
          <p class="c0">
            <span class="c2">- Klanten in staat stellen om ons onderzoeksplatform te gebruiken</span>
          </p>
          <p class="c0"><span class="c2">- Verbeteren van onze producten en diensten</span></p>
          <p class="c0"><span class="c2">- Oplossen van problemen</span></p>
          <p class="c0">
            <span>- Veiligstellen platform<br /><br /></span><span class="c8">Juridische basis</span
            ><span class="c2">: toestemming, gerechtigd belang </span>
          </p>
        </td>
      </tr>
    </table>

    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Cookies en andere vergelijkbare technologie&euml;n</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >We gebruiken noodzakelijke en geanonimiseerde cookies en andere vergelijkbare technologie&euml;n op
        onze Sites en de Mobiele App ter ondersteuning van het verzamelen van gegevens. We kunnen
        sessioncookies of persistent cookies gebruiken. Sessioncookies blijven slechts bestaan gedurende uw
        bezoek en worden verwijderd zodra u uw browser sluit. Persistent cookies blijven bestaan op de harde
        schijf van uw apparaat totdat u deze verwijdert of totdat deze zijn verlopen. <br /><br />We gebruiken
        de volgende cookietypen:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Essenti&euml;le. Sommige cookies zijn essentieel voor uw naviatie door onze Sites en/of de Mobiele
        App en het gebruik van hun functies, zoals het openen van beveiligde gedeeltes van de Sites en/of de
        Mobiele App. Zonder deze cookies kunnen wij niet de juiste inhoud aanbieden op basis van het type
        apparaat dat u gebruikt.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >&nbsp;Prestaties en analyse. We gebruiken cookies om te kunnen zien hoe u onze Sites en/of de Mobiele
        App gebruikt, zodat wij hun prestaties kunnen verbeteren en deze kunnen ontwikkelen. Er worden geen
        persoonsgegevens verzameld via cookies.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Het delen en openbaren van uw Persoonsgegevens</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >We delen of openbaren uw persoonsgegevens in de volgende gevallen en naar de volgende partijen:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >We delen informatie die via de Sites en de Mobiele App worden verstrekt met onze klanten voor hun
        marketing- en research-onderzoeken en bedrijfsdoeleinden.</span
      >
    </p>
    <p class="c4">
      <span class="c2"
        >We delen informatie via de Sites en de Mobiele App met externe serviceproviders voor het uitvoeren
        van onderzoeken en het leveren van rapporten aan klanten. De bijgewerkte lijst met onze externe
        serviceproviders kunt u hier nalezen.</span
      >
    </p>
    <p class="c4">
      <span class="c2"
        >We kunnen uw informatie delen of overdragen in het kader van een direct of indirect
        reorganisatieproces inclusief, maar niet beperkt tot fusies, acquisities, afstotingen, faillissementen
        en verkoop van alle of een deel van onze activa. Raadpleeg Toekenning hieronder voor meer
        informatie.</span
      >
    </p>
    <p class="c4">
      <span class="c2"
        >Wij openbaren uw persoonsgegevens ter naleving van toepasselijke wetgeving of als wij te goeder trouw
        menen dat een dergelijke handeling noodzakelijk is om te voldoen aan de eisen van de wetgeving of van
        een juridisch proces dat tegen ons wordt aangespannen, ter bescherming en verdediging van onze
        eigendomsrechten, of om te handelen in urgente omstandigheden ter bescherming van de veiligheid van
        onze gebruikers.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >&#39;Verkoop&#39; in de zin van de van toepassing zijnde wetgeving in de V.S. met betrekking tot
        consumentenprivacy wordt gedefinieerd als verkopen, verhuren, uitbrengen, openbaren, verspreiden,
        beschikbaar maken, overdragen of anderszins mondeling communiceren, schriftelijk of via elektronische
        of andere middelen, de persoonlijke informatie van een consument door het bedrijf naar een ander
        bedrijf of een externe partij voor geldelijke, en soms voor andere waarde-overwegingen.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >We verkopen geen persoonsgegevens, zoals gedefinieerd in privacywetgeving in de V.S., noch zouden we
        ooit uw persoonsgegevens aan enige externe partij in enig deel van de wereld verkopen.
      </span>
    </p>
    <p class="c4"><span class="c2">. </span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Verzameling en Gebruik van gegevens van kinderen</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >We verzamelen niet bewust informatie van kinderen jonger dan 13 jaar in de V.S. of jonger dan 16 jaar
        in de EU. In gevallen dat wij te weten komen dat een persoon van jonger dan 13 jaar in de V.S. of
        jonger dan 16 jaar in de EU ons persoonsgegevens heeft verstrekt, zullen wij dergelijke
        persoonsgegevens verwijderen.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Toegang en Opt-Out</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Als uw gegevens zijn gewijzigd en als u de door ons opgeslagen gegevens van u wilt bijwerken, neem
        dan contact met ons op via de onderstaande contactgegevens.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Als u niet langer marketingcommunicaties van ons wenst te ontvangen, kunt u zich via opt-out afmelden
        door de instructies te volgen die wordt verstrekt in onze marketingcommunicatie of door contact met
        ons op te nemen via het onderstaande adres. We zullen uw afmelding zo snel mogelijk verwerken en in
        naleving van toepasselijke wetgeving, maar houd er rekening mee dat u in sommige omstandigheden nog
        enkele berichten kunt ontvangen voordat de afmelding volledig is verwerkt en is afgesloten.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Houd er rekening mee dat, zelfs nadat u zich hebt afgemeld van onze marketingcommunicaties, wij u nog
        steeds berichten van administratieve aard zullen sturen met betrekking tot uw bestellingen en de Sites
        en/of de Mobiele App, inclusief bijvoorbeeld belangrijke updates met betrekking tot de Sites en/of de
        Mobiele App.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Uw rechten</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Als u een inwoner bent van de EEA, het VK of Zwitserland, hebt u het recht te verzoeken om:</span
      >
    </p>
    <p class="c1 c18"><span class="c2"></span></p>
    <ul class="c15 lst-kix_list_1-0 start">
      <li class="c3 li-bullet-0">
        <span class="c2">bevestiging of uw persoonsgegevens worden verwerkt </span>
      </li>
      <li class="c3 li-bullet-0"><span class="c2">toegang tot uw persoonsgegevens</span></li>
      <li class="c3 li-bullet-0">
        <span class="c2"
          >uw persoonsgegevens door ons te laten bewerken of in kopie naar u te laten versturen</span
        >
      </li>
      <li class="c3 li-bullet-0">
        <span class="c2">verwijderen of anonimiseren van uw persoonsgegevens</span>
      </li>
      <li class="c3 li-bullet-0">
        <span class="c2">het beperken van ons gebruik van uw persoonsgegevens</span>
      </li>
      <li class="c3 li-bullet-0">
        <span class="c2"
          >het stoppen van verdere onderzoekscommunicatie met u of bezwaar tegen onderzoeksdeelname
        </span>
      </li>
      <li class="c3 li-bullet-0">
        <span class="c2"
          >informatie over het feit of wij gebruikmaken van automatische besluitvorming en
          profileringshulpmiddelen
        </span>
      </li>
      <li class="c3 li-bullet-0">
        <span class="c2">een klacht in te dienen bij de relevante gegevensbeschermingsautoriteit</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c2"><br />Lijst met gegevensbeschermingsautoriteiten in de EU:</span>
    </p>
    <p class="c4">
      <span><br /></span
      ><span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1660300946364742&amp;usg=AOvVaw3AQ-O9xOzrGzvy-02wGZNS"
          >EEA
        </a></span
      ><span><br /></span
      ><span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1660300946365130&amp;usg=AOvVaw27Ktup72bVwy7pQH7oTJmH"
          >Zwitserland</a
        ></span
      ><span><br /></span
      ><span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1660300946365337&amp;usg=AOvVaw3s6T5KZtjPsyUrRqdqXpj7"
          >Verenigd Koninkrijk</a
        ></span
      ><span class="c2"
        >&nbsp;<br /><br />Als u inwoner bent van Californi&euml;, Connecticut, Utah, Colorado, Virginia of
        een andere VS-staat met privacywetgeving, hebt u het recht te verzoeken om:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <ul class="c15 lst-kix_list_2-0 start">
      <li class="c3 li-bullet-0"><span class="c2">toegang tot uw persoonsgegevens</span></li>
      <li class="c3 li-bullet-0"><span class="c2">rectificatie van uw persoonsgegevens</span></li>
      <li class="c3 li-bullet-0">
        <span class="c2"
          >bevestiging of wij uw persoonsgegevens hebben verkocht en of u uw toestemming voor verkoop van
          persoonsgegevens hebt ingetrokken</span
        >
      </li>
      <li class="c3 li-bullet-0"><span class="c2">verwijdering van uw persoonsgegevens</span></li>
      <li class="c3 li-bullet-0">
        <span class="c2"
          >Het recht om uw persoonsgegevens te ontvangen in een begrijpbaar, en voor zover technisch mogelijk,
          in een gestructureerd, gangbaar en machineleesbaar formaat.</span
        >
      </li>
    </ul>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Lijst met gegevensbeschermingsautoriteiten in de V.S.:</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1660300946367186&amp;usg=AOvVaw2QeZm6sh8WB2WTTWs9WAQK"
          >Californi&euml;
        </a></span
      >
    </p>
    <p class="c4">
      <span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1660300946367480&amp;usg=AOvVaw0EIjPAUgZUeJ737gm7ishR"
          >Connecticut</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1660300946367790&amp;usg=AOvVaw1-Rkc2nO0tTjfHc29ZiZkl"
          >Utah</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1660300946368069&amp;usg=AOvVaw1o_ZIG1dyyKwE0DmKSXJZu"
          >Colorado</a
        ></span
      >
    </p>
    <p class="c4">
      <span class="c7"
        ><a
          class="c9"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1660300946368367&amp;usg=AOvVaw2OgHCAJ0Y6eXYIHV3QWBqY"
          >Virginia</a
        ></span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span>Als u inwoner bent van de Volksrepubliek China, raadpleegt u ons Chinees privacybeleid. </span>
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c21">
      <span>Als u uw rechten wilt uitoefenen, dient u uw verzoek per e-mail te versturen aan </span
      ><span class="c7"><a class="c9" href="mailto:privacy@Spark-nyc.com">privacy@Spark-nyc.com</a></span
      ><span class="c2">. </span>
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Links van externe partijen</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Spark spoort u aan om de privacyverklaringen te lezen op websites waar vanuit de Sites en/of de
        Mobiele App naar wordt gelinkt, zodat u begrijpt hoe deze websites uw gegevens verzamelen, gebruiken
        en delen. Spark is niet verantwoordelijk voor de privacyverklaringen of willekeurige inhoud op deze
        websites buiten de Sites en de Mobiele App.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Beveiliging van uw persoonsgegevens</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Spark heeft redelijke maatregelen ingevoerd om uw persoonsgegevens te beveiligen tegen
        ongeautoriseerde toegang, toepassing of openbaarmaking. Hierbij moet rekening worden gehouden met het
        feit dat er geen garanties kunnen worden gegeven dat gegevensoverdracht via internet altijd voor 100%
        veilig is. Als gevolg daarvan kunnen we de veiligheid van informatie die u naar ons overdraagt niet
        garanderen, waarbij u begrijpt dat elke gegevensoverdracht door u aan ons op uw eigen risico is. Als
        wij te weten komen dat er een schending van beveiligingssystemen heeft plaatsgevonden, kunnen wij
        proberen u elektronisch te waarschuwen zodat u passende beveiligingsmaatregelen kunt treffen. Door de
        Sites en/of de Mobiele App te gebruiken, of door persoonsgegevens aan ons te verstrekken, gaat u ermee
        akkoord dat wij met u elektronisch kunnen communiceren over problemen met beveiliging, privacy en
        administratie met betrekking tot uw gebruik van de Sites en/of de Mobiele App. We kunnen een
        kennisgeving posten via de Sites en de Mobiele App als er zich een schending van de beveiliging
        voordoet. We kunnen u tevens een e-mail zenden naar het e-mailadres dat u ons hebt verstrekt voor
        dergelijke omstandigheden. Afhankelijk van uw woonplaats hebt u mogelijk het recht om schriftelijke
        kennisgevingen te ontvangen van schendingen van de beveiliging.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Bewaren van gegevens</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Wij bewaren uw persoonsgegevens gedurende de periode die noodzakelijk is om de in dit Privacybeleid
        beschreven doelen te verwezenlijken, tenzij een langere bewaarperiode wettelijk wordt vereist of
        toegestaan.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Internationale gegevensoverdracht</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Als u ons vrijwillig informatie verstrekt, kunnen wij deze informatie overdragen naar onze partners
        en dochterondernemingen of naar andere externe partijen, over grenzen, en van uw land of rechtsgebied
        naar andere landen of rechtsgebieden over de wereld. Als u onze websites bezoekt vanuit de EU of
        vanuit andere regio&#39;s met geldende wetgeving betreffende gegevensverzameling en -gebruik, houd er
        dan rekening mee dat u in dat geval persoonsgegevens overdraagt naar de V.S. en andere rechtsgebieden,
        waar wellicht niet dezelfde gegevensbeschermingswetgeving geldt als in uw land. U bevestigt dat u
        begrijpt dat door het verstrekken van uw persoonsgegevens:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Uw persoonsgegevens zullen worden gebruikt voor de doelen die hierboven zijn beschreven in
        overeenstemming met dit Privacybeleid en de ondertekende Gegevensbeschermingsovereenkomst. Uw
        persoonsgegevens kunnen worden overgedragen naar de V.S. en andere rechtsgebieden in overeenstemming
        met de van toepassing zijnde wetgeving zoals hierboven aangegeven. U kunt onze actuele lijst met
        sub-verwerkers en hun aanvullende maatregelen voor gegevensoverdracht hier inzien.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Toekenning</span></p>
    <p class="c4">
      <span class="c2"
        >In het geval dat alle of een deel van onze activa worden verkocht of worden overgenomen door een
        andere partij, of in het geval van een direct of indirect reorganisatieproces inclusief, maar niet
        beperkt tot fusies, acquisities, afstotingen, faillissementen en verkoop van alle of een deel van onze
        activa, geeft u ons het recht voor toekenning van de persoonsgegevens die zijn verzameld via de Sites
        en/of de Mobiele App na afronding van dergelijke transacties en/of tijdens het beoordelingsproces in
        afwachting van een overdracht. Bij overdracht in dergelijke gevallen blijft uw informatie onderworpen
        aan dit Privacybeleid of aan een privacybeleid dat uw privacy ten minste in gelijke mate beschermt als
        dit Privacybeleid.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Geschillenbeslechting en overeenstemming tot arbitrage</span></p>
    <p class="c4">
      <span class="c2"
        >Behalve waar en voor zover toegelaten door geldende wetgeving, komen u en SPARK door het gebruik van
        de Sites en/of de Mobiele App overeen dat in gevallen van onenigheid, claims, acties of geschillen
        voortkomende uit of in verband met uw gebruik van de Sites en/of de Mobiele App, of van de schending,
        handhaving, interpretatie of geldigheid van dit Privacybeleid of enig gedeelte daarvan
        (&quot;Geschil&quot;), beide partijen eerst in goed vertrouwen zullen trachten om een dergelijk
        Geschil te beslechten door een schriftelijke kennisgeving aan de andere partij te verstrekken waarin
        de feiten en omstandigheden van het Geschil worden beschreven en door de ontvangende partij een
        termijn van dertig (30) dagen toe te kennen voor het oplossen van het Geschil. Kennisgevingen aan ons
        moeten worden gestuurd naar:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Spark Ideas, LLC</span></p>
    <p class="c4"><span class="c2">1328 Ridge Road</span></p>
    <p class="c4"><span class="c2">Syosset, New York 11791</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Zowel U uls Spark gaan ermee akkoord dat deze geschillenbeslechtingsprocedure een voorwaarde is
        waaraan moet worden voldaan, voordat enige procesvoering wordt gestart of claims tegenover de andere
        partij worden ingesteld. ALS EEN GESCHIL NIET KAN WORDEN OPGELOST DOOR BOVENGENOEMDE
        GESCHILLENBESLECHTINGSPROCEDURE, GAAT U ERMEE AKKOORD DAT HET EXCLUSIEVE RECHTSGEBIED VOOR DERGELIJK
        GESCHIL ZAL WORDEN BEPAALD DOOR BINDENDE ARBITRAGE OP INDIVIDUELE BASIS. ARBITRAGE OP EEN INDIVIDUELE
        BASIS HOUDT IN DAT U GEEN RECHT HEBT OP, EN DAARVAN AFZIET, OP EEN RECHTER OF EEN JURY DIE OORDEELT
        OVER UW CLAIMS, EN DAT U GEEN PROCES MAG VOEREN OP BASIS VAN GEZAMENLIJKHEID OF IN DE HOEDANIGHEID VAN
        EEN VERTEGENWOORDIGER. Overige rechten die u en wij anders in een rechtbank zouden hebben, zijn niet
        beschikbaar of zijn beperkt in arbitrage, inclusief het recht op ontdekking en beroep. Alle dergelijke
        geschillen zullen exclusief worden ingediend bij JAMS (www.jamsadr.com) voor bindende arbitrage onder
        haar dan geldende regels, voor &eacute;&eacute;n arbiter, met welke beide partijen onderling moeten
        instemmen. De arbiter zal eventueel hoorzittingen houden, per teleconferentie of videoconferentie, in
        plaats van persoonlijke zittingen, tenzij de arbiter op verzoek van u of van ons bepaalt dat een
        zitting in persoon passend is. Eventuele persoonlijke zittingen zullen worden gehouden op een locatie
        die gunstig is gelegen voor beide partijen met inachtneming van hun mogelijkheden om te reizen en
        andere ter zake doende omstandigheden. Mochten de partijen geen overeenstemming over de locatie kunnen
        bereiken, zal deze worden bepaald door JAMS of door de arbiter.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >De arbiter, en geen federale, nationale of lokale rechtbank of bureau, heeft de exclusieve autoriteit
        voor oplossing van elk geschil dat ontstaat door, of dat in verband staat met de interpretatie,
        toepasbaarheid, afdwingbaarheid of opstelling van dit Privacybeleid, inclusief elke claim die stelt
        dat dit Privacybeleid of een deel daarvan nietig of vernietigbaar is.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Rechtskeuze</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Dit Privacybeleid is opgesteld en zal worden ge&iuml;nterpreteerd in overeenstemming met de wetten
        van de Staat New York, zonder uitvoering te geven aan enigerlei richtlijn inzake strijdige wetgeving.
        De partijen bevestigen dat dit Privacybeleid een transactie aantoont met betrekking tot
        interstatelijke handel. Ondanks de bepaling in de vorige paragraaf met betrekking tot het van
        toepassing zijnde materieel recht, zal arbitrage uitgevoerd in overeenstemming met de bepalingen van
        dit Privacybeleid onderworpen zijn aan de Federal Arbitration Act (9 U.S.C. &sect;&sect; 1-16).</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Uw privacyrechten in Californi&euml; en hoe we omgaan met &quot;Niet volgen&quot;-signalen</span
      >
    </p>
    <p class="c4">
      <span class="c2"
        >De Civil Code van Californi&euml; Section 1798.83 geeft bezoekers van de Sites en/of de Mobiele App,
        die inwoners zijn van Californi&euml; de mogelijkheid om bepaalde informatie op te vragen met
        betrekking tot onze openbaarmaking van persoonsgegevens aan externe partijen voor hun direct
        marketing-doelen. We delen uw persoonsgegevens niet met externe partijen voor hun direct
        marketing-doelen.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4">
      <span class="c2"
        >Op dit moment herkennen onze Sites geen geautomatiseerde browsersignalen met betrekking tot
        trackingmechanismen, mogelijk inclusief &ldquo;Niet volgen&rdquo;-instructie.</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Contactinformatie</span></p>
    <p class="c4">
      <span class="c2"
        >Spark verwelkomt uw vragen of opmerkingen aangaande dit Privacybeleid en onze privacypraktijken. Neem
        contact met ons op via:</span
      >
    </p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Spark Ideas, LLC</span></p>
    <p class="c4"><span class="c2">1328 Ridge Road</span></p>
    <p class="c4"><span class="c2">Syosset, New York 11791</span></p>
    <p class="c4"><span class="c2">Verenigde Staten van Amerika</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">E-mailadres:</span></p>
    <p class="c4"><span class="c2">privacy@spark-nyc.com</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Telefoonnummer:</span></p>
    <p class="c4"><span class="c2">(917) 991-0477</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span class="c2">Wijzigingen aan dit Privacybeleid</span></p>
    <p class="c4">
      <span class="c2"
        >Spark zal dit Privacybeleid van tijd tot tijd bijwerken voor verwerking van feedback van het bedrijf
        en van klanten. Als wij dit doen, reviseren we ook de ingangsdatum bovenaan dit Privacybeleid. Bij
        wezenlijke wijzigingen aan dit Privacybeleid zullen wij u informeren via een prominente kennisgeving
        op de Sites en de Mobiele App. Wij moedigen u aan om dit Privacybeleid periodiek na te lezen om
        ge&iuml;nformeerd te blijven over de verwerking van uw gegevens door Spark.</span
      >
    </p>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyNL',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyNL {
  max-width: 730px;
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_2-7 > li:before {
    content: '\0025cb  ';
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_2-5 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-8 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }

  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-2 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_1-4 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-7 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-5 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_2-1 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-8 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-2 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
  }

  ol {
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c11 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c5 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 318pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c3 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c20 {
    padding-top: 0pt;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c16 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c25 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c12 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c21 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c0 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c7 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }

  .c14 {
    margin-left: -5pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c17 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c15 {
    padding: 0;
  }

  .c9 {
    color: inherit;
    text-decoration: inherit;
  }

  .c13 {
    height: 78.2pt;
  }

  .c8 {
    font-weight: 700;
  }

  .c6 {
    height: 165.2pt;
  }

  .c22 {
    background-color: #ffff00;
  }

  .c18 {
    margin-left: 36pt;
  }

  .c24 {
    height: 115.8pt;
  }

  .c19 {
    height: 75.2pt;
  }

  .c23 {
    height: 241.8pt;
  }

  .c10 {
    height: 24.2pt;
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    padding-bottom: 16pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
  }

  p {
    color: #000000;
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
