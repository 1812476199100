<template>
  <div class="PollResultsAverage">
    <LLRoundProgress large color="#508BBA" color-secondary="#DAE3EB" :percent="percent">
      <div class="text-primary-01-400">
        <div
          class="PollResultsAverage__value"
          :class="{
            PollResultsAverage__value_small: average >= 10000 && average < 1000000,
            PollResultsAverage__value_tiny: average >= 1000000
          }"
        >
          {{ computedAverage }}
        </div>
        <div class="text-center text-lg font-semibold">{{ $t('forum.poll.results.average') }}</div>
      </div>
    </LLRoundProgress>
  </div>
</template>

<script>
import LLRoundProgress from '@/components/common/ui-components/LLRoundProgress.vue'

export default {
  name: 'PollResultsAverage',
  components: { LLRoundProgress },
  props: {
    average: { type: Number, default: null },
    min: { type: Number, default: null },
    max: { type: Number, default: null }
  },
  computed: {
    computedAverage() {
      return Math.round((this.average + Number.EPSILON) * 100) / 100
    },
    percent() {
      return this.max ? Math.round(((this.average - this.min) / (this.max - this.min)) * 100) : 100
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsAverage {
  @apply flex justify-center items-center;
  &__value {
    @apply text-center font-semibold text-6xl;
    &_small {
      @apply text-5xl;
    }
    &_tiny {
      font-size: 29px;
    }
  }
}
</style>
