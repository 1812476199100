<template>
  <div class="PollRankedResults">
    <PollResults
      :filter-type="filterTypeToSend"
      :filter-values="filterValues"
      :poll="poll"
      :thread="thread"
      :export-data="exportData"
      @updateResult="onResultUpdate"
      @updateLocations="onLocationsUpdate"
    >
      <template #chart>
        <PollRankedResultsBar v-if="result" class="PollRankedResults__bar" :data="resultPercentage" />
      </template>
      <template #filters>
        <PollResultsFilterAdvanced
          :filter-type.sync="filterType"
          :filter-values.sync="filterValues"
          :location-groups="filterLocationGroups"
        />
      </template>
    </PollResults>
  </div>
</template>

<script>
import PollResultsFilterAdvanced from '@/components/modules/forum-chat/poll/results/PollResultsFilterAdvanced.vue'
import PollResults from '@/components/modules/forum-chat/poll/ForumPollResults.vue'
import { PollModel } from '@/models/poll'
import PollRankedResultsBar from '@/components/modules/poll/ranked/PollRankedResultsBar.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollRankedResults',
  components: {
    PollRankedResultsBar,
    PollResults,
    PollResultsFilterAdvanced
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      filterType: null,
      filterValues: [],
      filteredResult: null,
      result: null,
      filterLocationGroups: []
    }
  },
  computed: {
    sortedRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    exportData() {
      if (!this.resultPercentage) {
        return []
      }
      const result = []
      this.resultPercentage.forEach((row) => {
        const csvRow = { [this.$t('forum.poll.results.export.category')]: row.text }
        row.ranks.forEach((rank) => {
          csvRow[this.$tc('forum.poll.results.ranked.rank_index', rank.rank + 1)] = rank.percentage
        })
        result.push(csvRow)
      })
      return result
    },
    resultPercentage() {
      if (!this.result) {
        return null
      }
      const rows = this.sortedRows.map((row) => ({
        id: row.id,
        text: row.text
      }))

      const rowsCount = this.sortedRows.length
      const isOdd = !!(this.sortedRows.length % 2)

      rows.forEach((row) => {
        for (let rank = 1; rank <= rowsCount; rank++) {
          const foundResult = this.result.results?.find(
            (result) => result.order === rank && result.rowId === row.id
          )
          if (!row.ranks) {
            row.ranks = []
          }
          row.ranks.push({
            rank,
            count: foundResult?.count ?? 0
            /* percentage: foundResult?.count
              ? Math.round((foundResult.count / this.result.totalCount) * 100)
              : 0 */
          })
        }
      })

      rows.forEach((row) => {
        const totalCount = row.ranks.reduce((acc, rank) => acc + rank.count, 0)
        row.ranks.forEach((rank) => {
          rank.percentage = totalCount ? Math.round((rank.count / totalCount) * 100) : 0
        })
      })

      rows.forEach((row) => {
        const sum = row.ranks
          .slice(Math.floor(rowsCount / 2), rowsCount)
          .reduce(
            (acc, rowRank, index) =>
              isOdd && index === 0 ? rowRank.percentage / 2 : acc + rowRank.percentage,
            0
          )
        row.lowHalfPercent = sum
      })

      rows.sort((a, b) => a.lowHalfPercent - b.lowHalfPercent)

      rows.forEach((row) => {
        row.offset = row.lowHalfPercent - rows[0].lowHalfPercent
      })

      return rows
    },
    filterTypeToSend() {
      return this.filterValues?.length ? this.filterType : null
    }
  },
  methods: {
    onResultUpdate({ result }) {
      this.result = result
    },
    onLocationsUpdate({ locations }) {
      this.filterLocationGroups = locations
    }
  }
}
</script>

<style scoped lang="scss">
.PollRankedResults {
  &__bar {
    @apply w-full;
  }
}
</style>
