const initialState = () => ({
  selectedStudyId: null,
  selectedTopicId: null,
  showAllQuestionsAnsweredConfirmation: false
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    selectedStudyId(state) {
      return state.selectedStudyId
    },
    selectedStudyshowAllQuestionsAnsweredConfirmation(state) {
      return state.showAllQuestionsAnsweredConfirmation
    },
    selectedStudy(state, getters, rootState, rootGetters) {
      return rootGetters['studies/getStudyById'](getters.selectedStudyId)
    },

    topics(state, getters) {
      return [...(getters?.selectedStudy?.topics || [])].sort((a, b) => a.order - b.order)
    },
    selectedTopicId(state) {
      return state.selectedTopicId
    },
    selectedTopic(state, getters) {
      return getters.topics.find((t) => t.id === getters.selectedTopicId)
    },

    selectedTopicQuestions(state, getters) {
      return getters.selectedTopic?.questions
        ? [...getters.selectedTopic.questions].sort((a, b) => a.order - b.order)
        : []
    }
  },
  mutations: {
    SET_SELECTED_STUDY_ID(state, studyId) {
      state.selectedStudyId = studyId
    },
    SET_SELECTED_TOPIC_ID(state, topicId) {
      state.selectedTopicId = topicId
    },
    SET_SHOW_ALL_ANSWERED_MODAL(state, showModal) {
      state.showAllQuestionsAnsweredConfirmation = showModal
    },
    REMOVE_ALL(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    }
  },
  actions: {
    selectStudy({ commit }, studyId) {
      commit('SET_SELECTED_STUDY_ID', studyId)
    },
    selectTopic({ commit }, topicId) {
      commit('SET_SELECTED_TOPIC_ID', topicId)
    },
    reset({ commit }) {
      commit('REMOVE_ALL')
    }
  }
}
