<template>
  <div class="UserEditModalProfile">
    <div class="UserEditModalProfile__value">
      <ValidationProvider v-slot="{ errors }" :rules="textInputRules" immediate>
        <LLInputText
          v-model.trim="localUser.firstName"
          :placeholder="$t('placeholder_first_name_text_box')"
          :errors="errors"
          data-e2e="firstNameFirstVisit"
        >
          <template #caption>{{ $t('placeholder_first_name') }}</template>
        </LLInputText>
      </ValidationProvider>
    </div>
    <div class="UserEditModalProfile__value">
      <ValidationProvider v-slot="{ errors }" :rules="textInputRules" immediate>
        <LLInputText
          v-model.trim="localUser.lastName"
          :placeholder="$t('placeholder_last_name_text_box')"
          :errors="errors"
          data-e2e="lastNameFirstVisit"
        >
          <template #caption>{{ $t('placeholder_last_name') }}</template>
        </LLInputText>
      </ValidationProvider>
    </div>
    <div class="UserEditModalProfile__value">
      <LLPhoneInput v-model="localUser.phone" :required="!!textInputRules" data-e2e="phoneFirstVisit">
        <template #caption>{{ $t('placeholder_mobile_phone') }}</template>
      </LLPhoneInput>
    </div>
    <div class="UserEditModalProfile__value">
      <ValidationProvider v-slot="{ errors }" :rules="textInputRules" immediate>
        <LLSelectInputNew
          v-model="localUser.gender"
          :errors="errors"
          :key-fn="(value) => value.value"
          error-space
          :caption="(gender) => $t(`gender_${gender.label}`)"
          :reduce="(gender) => gender.value"
          :values="genders"
          :placeholder="$t('placeholder_gender')"
          class="ProspectDetailsModalBasics__input"
          data-e2e="genderFirstVisit"
        >
          <template #caption>{{ $t('placeholder_gender') }}</template>
        </LLSelectInputNew>
      </ValidationProvider>
    </div>
    <div class="UserEditModalProfile__value" data-e2e="yearsFirstVisit">
      <ValidationProvider v-slot="{ errors }" :rules="textInputRules" immediate>
        <LLYearInput v-model="localUser.birthday" :errors="errors">
          <template #caption>{{ $t('placeholder_date_of_birth') }}</template>
        </LLYearInput>
        <div class="UserEditModalProfile__birthday-notice">
          {{ $t('your_birthday_won_t_be_shown_publicity') }}
        </div>
      </ValidationProvider>
    </div>
    <div class="UserEditModalProfile__value">
      <ValidationProvider v-slot="{ errors }" :rules="textInputRules" immediate>
        <LLSelectInputNew
          v-model="language"
          :values="availableLocales"
          :caption="(l) => l.label"
          rules="required"
          data-e2e="userLanguageInput"
          :errors="errors"
          :key-fn="(value) => value.value"
          error-space
          :reduce="(l) => l.code"
          :placeholder="$t('placeholder_preferred_language')"
        >
          <template #caption>{{ $t('placeholder_preferred_language') }}</template>
        </LLSelectInputNew>
      </ValidationProvider>
    </div>
    <div class="UserEditModalProfile__value UserEditModalProfile__value_full">
      <LLLocationInput
        :validation-rules="textInputRules"
        :placeholder="$t('placeholder_start-typing')"
        :location="localUser.location"
        data-e2e="locationFirstVisit"
        @update="updateLocation"
      >
        <template #caption>
          {{ $t('placeholder_location') }}
        </template>
      </LLLocationInput>
      <div class="UserEditModalProfile__birthday-notice">
        {{ $t('location_input_notice') }}
      </div>
    </div>
    <div class="UserEditModalProfile__value UserEditModalProfile__value_full">
      <ValidationProvider
        v-slot="{ errors }"
        name="sms-consent-agreement"
        :rules="smsConsentRequired ? 'required' : undefined"
        immediate
      >
        <LLCheckbox
          v-model="localUser.smsConsentAccepted"
          class="UserEditModalProfile__checkbox UserEditModalProfile__sms-consent-checkbox"
          :errors="errors"
          data-e2e="sms_consent_agreement_checkbox"
        >
          <span class="UserEditModalProfile__sms-consent-checkbox-text">
            {{ $t('placeholder_sms_consent_agreement') }}
          </span>
          <span v-if="smsConsentRequired" class="UserEditModalProfile__sms-consent-checkbox-required">*</span>
        </LLCheckbox>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="ai-warning-agreement"
        :rules="aiWarningRequired ? 'required' : undefined"
        immediate
      >
        <LLCheckbox
          v-model="localUser.aiWarningAccepted"
          class="UserEditModalProfile__checkbox UserEditModalProfile__sms-consent-checkbox"
          :errors="errors"
          data-e2e="sms_consent_agreement_checkbox"
        >
          <span class="UserEditModalProfile__ai-warning-checkbox-text">
            {{ $t('placeholder_ai-warning-text') }}
          </span>
          <span v-if="aiWarningRequired" class="UserEditModalProfile__ai-warning-checkbox-required">*</span>
          <span class="UserEditModalProfile__ai-warning-checkbox-notice">
            {{ $t('placeholder_ai-warning-notice') }}
          </span>
        </LLCheckbox>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import LLPhoneInput from '@/components/common/LLPhoneInput'
import { ProspectModel } from '@/models/prospect'
import LLLocationInput from '@/components/common/LLLocationInput'
import LLSelectInputNew from '@/components/common/LLSelectInputNew'
import LLYearInput from '@/components/common/LLYearInput'
import LLInputText from '@/components/common/LLInput/LLInputText'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox'
import languageLabels from '@/common/languages.json'
export default {
  name: 'UserEditModalProfile',
  components: { LLCheckbox, LLInputText, LLYearInput, LLSelectInputNew, LLLocationInput, LLPhoneInput },
  props: {
    user: { type: ProspectModel, default: () => new ProspectModel() },
    smsConsentRequired: { type: Boolean, default: false },
    aiWarningRequired: { type: Boolean, default: false }
  },
  data() {
    return {
      localUser: null
    }
  },
  computed: {
    textInputRules() {
      if (this.localUser?.requireRegistration) {
        return 'required'
      } else {
        return null
      }
    },
    language: {
      get() {
        let value = ''
        if (!this.localUser?.language) {
          value = this.$i18n.locale
        } else {
          value = this.localUser?.language
        }
        return this.availableLocales.find((l) => l.code === value)?.code
      },
      set(locale) {
        locale = locale?.toLowerCase()
        this.localUser.language = locale
      }
    },
    genders() {
      return this.$store.getters.gendersArray
    },
    availableLocales() {
      return this.$i18n.availableLocales.map((l) => ({
        code: l,
        label: languageLabels?.[l]
      }))
    },
    currentGlobalLocale() {
      return this.$i18n.locale
    }
  },
  watch: {
    currentGlobalLocale() {
      if (this.localUser) {
        this.localUser.language = this.$i18n.locale
      }
    },
    user: {
      handler() {
        if (!this.$_.isEqual(this.user, this.localUser)) {
          this.localUser = new ProspectModel(this.user)
        }
        if (this.localUser?.firstName || this.localUser?.lastName) {
          this.localUser?.firstName.trim()
          this.localUser?.lastName.trim()
        }
      },
      immediate: true,
      deep: true
    },
    localUser: {
      handler() {
        this.$emit('update:user', new ProspectModel(this.localUser))
      },
      deep: true
    }
  },
  methods: {
    updateGlobalLocation() {},
    updateLocation(location) {
      this.localUser.location = { ...location }
    }
  }
}
</script>

<style lang="scss" scoped>
.UserEditModalProfile {
  $root: &;
  @apply grid grid-cols-2 items-start;
  @screen md-max {
    @apply grid-cols-1;
  }
  #{$root}__value {
    @apply pt-6;
    &:nth-child(1) {
      @apply pt-0;
    }
    &:nth-child(2) {
      @apply pt-0;
      @screen md-max {
        @apply pt-6;
      }
    }
    &:nth-child(2n) {
      @apply pl-5;
      @screen md-max {
        @apply pl-0;
      }
    }
    &:nth-child(2n-1) {
      @apply pr-5;
      @screen md-max {
        @apply pr-0;
      }
    }
    #{$root}__input {
      @apply w-full;
    }
    &_full {
      padding-right: 0 !important;
      padding-left: 0 !important;
      grid-column-start: 1;
      grid-column-end: 3;
      @screen md-max {
        grid-column-end: 2;
      }
    }
  }
  &__sms-consent-checkbox {
    @apply mt-4;
    &-required {
      @apply text-status-03-300 absolute ml-1;
      vertical-align: super;
    }
    &-text {
      @apply text-body-02 -mt-0.5;
    }
  }
  &__ai-warning-checkbox {
    @apply mt-4;
    &-required {
      @apply text-status-03-300 absolute ml-1;
      vertical-align: super;
    }
    &-text {
      @apply text-body-02 -mt-0.5;
    }
    &-notice {
      @apply block text-caption-01 text-neutral-01-400;
    }
  }
  &__birthday-notice {
    @apply text-neutral-01-400 text-caption-02 mt-1 absolute;
  }
}
</style>
