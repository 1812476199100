<template>
  <div ref="response" class="ForumResponse" :class="classList">
    <div ref="wrapper" class="ForumResponse__wrapper">
      <transition name="fade">
        <div
          v-if="animationBackground.scrolledAnimationFlag"
          ref="scrolled-background"
          :style="animationBackgroundStyle"
          class="ForumResponse__scrolled-background"
        ></div>
      </transition>
      <ForumResponseScrollToHelper
        ref="scroll-helper"
        :response="response"
        :parent-response-id="replyId"
        @fireBackgroundAnimation="fireScrollToBackgroundAnimation"
        @focus="focus"
        @addMention="onAddMention"
      />
      <div v-if="localResponse" class="ForumResponse__thread-lines">
        <div
          v-if="localResponse.repliesCount || isNewReplyVisible"
          class="ForumResponse__thread-line-main"
        ></div>
        <div
          v-if="localResponse.parentResponseId && localResponse.id"
          class="ForumResponse__thread-line-user-to-parent"
        ></div>
        <div v-if="isLast" class="ForumResponse__thread-line-last-main-cutter"></div>
      </div>
      <div v-if="isAsideVisible" class="ForumResponse__aside">
        <ForumResponseAvatar :response="response" />
      </div>
      <div class="ForumResponse__content">
        <ForumResponseHeader v-if="isHeaderVisible" class="ForumResponse__header" :response="response" />
        <ForumResponseBody
          v-if="!isEditMode"
          ref="body"
          class="ForumResponse__body"
          :response="response"
          :reaction-emoji-list="reactionEmojiList"
          :is-editable="isEditable"
          :disable-reactanimation="disableReactanimation"
          :reply-level="replyLevel"
          :is-able-to-reply="isAbleToReply"
          @updateReactions="updateReactions"
          @updateReactionEmojiList="onUpdateReactionEmojiList"
          @reactionSelect="onReactionSelect"
          @edit="onEdit"
          @remove="onRemove"
          @replyButtonClick="onReplyButtonClick"
        />
        <ForumResponseEditor
          v-if="isEditMode"
          ref="editor"
          class="ForumResponse__editor"
          :response="localResponse"
          @cancel="onEditCancel"
          @update="updateResponse"
        />
        <ForumResponseReplies
          v-if="response"
          ref="replies"
          class="ForumResponse__replies"
          :is-new-reply-visible.sync="isNewReplyVisible"
          :reply-level="replyLevel"
          :response="response"
          :is-able-to-reply="isAbleToReply"
          :is-editable="isEditable"
          @read="onReplyRead"
          @updateTotal="onUpdateRepliesTotal"
          @updateReply="updateReply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ForumResponseHeader from '@/components/pages/forum/response/ForumResponseHeader.vue'
import ForumResponseBody from '@/components/pages/forum/response/ForumResponseBody.vue'
import ForumResponseEditor from '@/components/pages/forum/response/ForumResponseEditor.vue'
import ForumResponseReplies from '@/components/pages/forum/response/ForumResponseReplies.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import threadEditor from '@/utils/modules/forum/threadEditor'
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'
import { ForumReactionModel } from '@/models/forum/forumReaction'
import ForumResponseAvatar from '@/components/pages/forum/response/ForumResponseAvatar.vue'
import ForumResponseScrollToHelper from '@/components/pages/forum/response/ForumResponseScrollToHelper.vue'

export default {
  name: 'ForumResponse',
  components: {
    ForumResponseScrollToHelper,
    ForumResponseAvatar,
    ForumResponseReplies,
    ForumResponseEditor,
    ForumResponseBody,
    ForumResponseHeader
  },
  props: {
    replyLevel: { type: Number, default: 0 },
    parentResponseId: { type: String, default: null },
    replyId: { type: String, default: null },
    threadId: { type: String, default: null },
    chatId: { type: [Number, String], default: null },
    response: { type: ForumResponseModel, default: null },
    isEditable: { type: Boolean, default: true },
    disableReactanimation: { type: Boolean, default: false },
    isAbleToReply: { type: Boolean, default: true },
    isLast: { type: Boolean, default: false },
    isFirst: { type: Boolean, default: false }
  },
  data() {
    return {
      isRead: true,
      reactionEmojiList: [],
      localResponse: null,
      isEditMode: false,
      isNewReplyVisible: false,
      animationBackground: {
        scrolledAnimationFlag: false,
        animationTimeout: null,
        blockPosition: {
          width: 100,
          heigth: 100
        }
      }
    }
  },
  computed: {
    isHeaderVisible() {
      return this.localResponse?.id
    },
    isAsideVisible() {
      return this.localResponse?.id
    },
    classList() {
      const baseClass = 'ForumResponse'
      const classes = []
      if (!this.localResponse?.id && this.localResponse?.parentResponseId) {
        classes.push(`${baseClass}_new-reply`)
      } else if (!this.localResponse?.id && !this.localResponse?.parentResponseId) {
        classes.push(`${baseClass}_new`)
      } else {
        classes.push(`${baseClass}_standard`)
      }
      if (this.animationBackground.scrolledAnimationFlag) {
        classes.push(`${baseClass}_scrolled`)
      }
      if (this.replyLevel >= 1) {
        classes.push(`${baseClass}_deep`)
      }
      return classes
    },
    animationBackgroundStyle() {
      return {
        width: `${this.animationBackground.blockPosition.width + 30}px`,
        height: `${this.animationBackground.blockPosition.height + 22}px`,
        marginLeft: '-15px',
        marginTop: '-15px'
      }
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new ForumResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        // this.$emit('update', new ForumResponseModel(this.localResponse))
      },
      deep: true
    }
  },
  created() {
    this.isEditMode = !this.response?.id
    if (!this.response) {
      this.localResponse = new ForumResponseModel({
        chatId: this.chatId,
        threadId: this.threadId,
        parentResponseId: this.parentResponseId,
        replyToId: this.replyId
      })
    }
    this.isRead = this.response?.isRead ?? true
  },
  mounted() {
    this.$nextTick(() => {
      threadEditor.addEvent(threadEditor.enums.REACTIONS_UPDATE, this.onSocketReactionsUpdate)
    })
  },
  beforeDestroy() {
    threadEditor.removeEvent(threadEditor.enums.REACTIONS_UPDATE, this.onSocketReactionsUpdate)
  },
  methods: {
    onReplyRead({ responseId }) {
      this.$emit('read', { responseId })
    },
    onResponseObserve() {
      setTimeout(() => {
        this.isRead = true
        if (this.localResponse?.id) {
          this.$emit('read', { responseId: this.localResponse?.id })
        }
      }, 500)
    },
    onUpdateReactionEmojiList(reactionEmojiList) {
      this.reactionEmojiList = [...reactionEmojiList]
    },
    onSocketReactionsUpdate({ responseId, reactions }) {
      if (responseId === this.response?.id) {
        this.updateReactions({ reactions })
      }
    },
    onReactionSelect(reaction) {
      const reactionIndex = this.reactionEmojiList.indexOf(reaction)
      if (reactionIndex !== -1) {
        this.reactionEmojiList.splice(reactionIndex, 1)
      } else {
        this.reactionEmojiList.push(reaction)
      }
    },
    fireScrollToBackgroundAnimation() {
      this.$nextTick(() => {
        setTimeout(() => {
          const content = this.$refs.body?.$el || this.$refs.editor?.$el
          const block = this.$refs.wrapper
          if (!block || !content) return
          const blockPosition = block.getBoundingClientRect()
          const contentPosition = content.getBoundingClientRect()

          this.animationBackground.blockPosition.width = contentPosition.right - blockPosition.left
          this.animationBackground.blockPosition.height = contentPosition.bottom - blockPosition.top

          this.animationBackground.scrolledAnimationFlag = true
          clearTimeout(this.animationBackground.animationTimeout)
          this.animationBackground.animationTimeout = setTimeout(() => {
            this.animationBackground.scrolledAnimationFlag = false
          }, 2000)
        }, 100)
      })
    },
    onUpdateRepliesTotal(total) {
      const response = new ForumResponseModel(this.localResponse)
      response.repliesCount = total
      this.updateResponse({ response })
    },
    updateReactions({ reactions }) {
      this.localResponse.reactions = reactions.map((reaction) => new ForumReactionModel(reaction))
      this.updateResponse({ response: this.localResponse })
    },
    updateReply({ response }) {
      this.$emit('updateReply', { response })
    },
    updateResponse({ response }) {
      this.localResponse = new ForumResponseModel(response)
      this.$emit('update', { response })
    },
    onEdit() {
      this.isEditMode = true
    },
    onReplyButtonClick() {
      scrollToForumReplyHelper.setNewReplyToWatch({
        newReplyId: this.response.id,
        prospect: this.response?.prospect,
        user: this.response?.user
      })
      this.isNewReplyVisible = true
    },
    onRemove() {
      this.$emit('remove', { responseId: this.localResponse.id })
    },
    onAddMention({ prospect, user }) {
      let fullName
      if (prospect) {
        fullName = prospect?.fullNameWithShortLastName
      } else if (user) {
        fullName = user?.fullNameWithShortLastName
      }
      if (!fullName) {
        return
      }
      this.$nextTick(() => {
        if (this.$refs.editor) {
          this.$refs.editor.insertMention({
            prospectId: prospect ? prospect?.id : undefined,
            userId: user ? user?.id : undefined,
            fullName
          })
        }
      })
    },
    focus() {
      this.$refs['scroll-helper'].fireScrollToAction()
      this.$nextTick(() => {
        if (this.$refs.editor) {
          this.$refs.editor.focus()
        }
      })
    },
    onEditCancel() {
      if (this.response?.id) {
        this.isEditMode = false
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponse {
  $root: &;
  @apply flex bg-white relative;

  /*&_scrolled {
    & > #{$root}__wrapper {
      & > #{$root}__scrolled-background {
        @apply bg-accent-03-200;
      }
    }
  }*/

  &_new-reply {
    @apply w-full relative py-4;
    padding-right: 19px;
    @screen md {
      padding-right: 30px;
    }
    &:before {
      @apply absolute w-full h-full bg-neutral-01-15;
      width: calc(100% + 17px);
      height: 100%;
      top: 0;
      content: '';
      left: -17px;
      @screen md {
        width: calc(100% + 28px);
        left: -28px;
      }
    }
  }

  &_new {
    @apply bg-neutral-01-15 w-full relative px-6 py-4;
    &:before {
      @apply w-1 bg-neutral-01-50 h-full absolute left-0 top-0;
      content: '';
    }
  }

  &__wrapper {
    @apply relative w-full flex;
  }

  &__aside {
    @apply mr-1.5 flex-shrink-0 relative;
    @screen md {
      @apply mr-3;
    }
  }

  &__content {
    @apply flex-1 relative min-w-0;
  }

  &__body {
    @apply relative;
  }

  &__header {
    & + #{$root}__body {
      @apply mt-1;
    }

    & + #{$root}__editor {
      @apply mt-3;
    }
  }

  &__scrolled-background {
    @apply absolute bg-accent-03-200 top-0 left-0 w-10 h-10;
    transition-duration: 2s;
    transition-property: opacity;
  }

  &__replies {
    @apply mt-2 relative;
  }

  &__thread-lines {
    @apply absolute h-full left-0 top-0;
  }

  &__thread-line-main {
    @apply bg-neutral-01-50 absolute top-0 left-0;
    width: 2px;
    height: calc(100% - 33px);
    margin-left: 11px;
    margin-top: 30px;
    @screen md {
      height: calc(100% - 44px);
      margin-left: 14px;
      margin-top: 44px;
    }
  }

  &__thread-line-user-to-parent {
    @apply bg-neutral-01-50 absolute top-0 left-0;
    margin-left: -19px;
    margin-top: 11px;
    width: 13px;
    height: 2px;
    @screen md {
      margin-left: -28px;
      margin-top: 15px;
      width: 19px;
    }
  }

  &__thread-line-last-main-cutter {
    @apply absolute bg-white;
    content: '';
    width: 2px;
    height: calc(100% - 13px);
    margin-left: -19px;
    margin-top: 13px;
    @screen md {
      height: calc(100% - 17px);
      margin-left: -30px;
      margin-top: 17px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
