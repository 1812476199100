<template>
  <div className="PollMatrix">
    <PollMatrixEditor
      v-if="(!poll.isResponded && !showResults) || editMode"
      v-model="localResponse.matrixList"
      :poll="poll"
      :compact="false"
      :response.sync="localResponse"
      :is-first-time-fill="isFirstTimeFill"
      @update:isValid="onIsValidUpdate"
    />
    <PollMatrixResults v-else :thread="thread" :poll="poll" />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollType } from '@/common/enums'
import { PollResponseModel } from '@/models/pollResponse'
import PollMatrixEditor from '@/components/modules/poll/matrix/PollMatrixEditor.vue'
import PollMatrixResults from '@/components/modules/forum-chat/poll/matrix/PollMatrixResults.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollMatrix',
  components: { PollMatrixResults, PollMatrixEditor },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    showResults: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
      },
      deep: true
    }
  },
  methods: {
    onIsValidUpdate(isValid) {
      this.$emit('update:isValid', isValid)
    }
  }
}
</script>

<style scoped lang="scss">
.PollMatrix {
}
</style>
