<template>
  <div class="ForumChatMainEmpty">
    <div class="ForumChatMainEmpty__message">
      <span> {{ $t('forum.chat_page.empty.label') }}</span>
      <span v-if="chat.isMembersAbleThreadCreate"> {{ $t('forum.chat_page.empty.create_suggestion') }}</span>
    </div>
    <router-link
      v-if="chat.isMembersAbleThreadCreate"
      class="ForumChatMainEmpty__add-button-wrapper"
      :to="newThreadLink"
    >
      <LLAddLabel red> {{ $t('forum.chat_page.threads.add_button') }} </LLAddLabel>
    </router-link>
  </div>
</template>

<script>
import { ForumChatModel } from '@/models/forum/forumChat'
import LLAddLabel from '@/components/atoms/LLAddLabel'

export default {
  name: 'ForumChatMainEmpty',
  components: { LLAddLabel },
  props: {
    chat: { type: ForumChatModel, required: true }
  },
  computed: {
    newThreadLink() {
      return `/chats/${this.chat.id}/threads/create`
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatMainEmpty {
  @apply flex flex-col items-center py-20;
  &__message {
    @apply text-body-02 text-neutral-01-400 text-center  flex flex-col  items-center;
  }
  &__add-button-wrapper {
    @apply mt-6;
  }
}
</style>
