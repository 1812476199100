<template>
  <div class="LLInputText">
    <LLInputCaption v-if="showCaption" :disabled="disabled"><slot name="caption"></slot></LLInputCaption>
    <LLInputContainer
      :error="hasError"
      class="LLInputText"
      :with-cleaner="showCleaner"
      :disabled="disabled"
      :focused.sync="focused"
      :small="small"
      :class="inputClasses"
      @click.stop
      @clear="onClear"
    >
      <template #left-icon>
        <slot name="left-icon"></slot>
      </template>
      <template v-if="showPlaceholder" #placeholder>
        <div class="LLInputText__placeholder">
          {{ placeholder }}
        </div>
      </template>
      <template #text-input>
        <input
          v-if="mask"
          ref="input"
          v-mask="mask"
          :pattern="pattern"
          :inputmode="inputmode"
          :value="currentValue"
          :maxlength="maxLength"
          :disabled="disabled"
          class="LLInputText__input"
          type="text"
          @input="(e) => updateCurrentValue(e.target.value)"
          @focus="onFocus"
          @blur="onBlur"
        />
        <input
          v-else
          ref="input"
          :value="currentValue"
          :pattern="pattern"
          :inputmode="inputmode"
          :disabled="disabled"
          :maxlength="maxLength"
          class="LLInputText__input"
          type="text"
          @input="(e) => updateCurrentValue(e.target.value)"
          @focus="onFocus"
          @blur="onBlur"
        />
      </template>
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </LLInputContainer>
    <LLPopper
      v-if="(hasError || showError) && showErrorInTooltip"
      :show="focused"
      tooltip-red
      tooltip-with-arrow
      placement="bottom-end"
      :offset="[0, 5]"
      trigger="none"
    >
      <template #trigger></template>
      <template #tooltip>
        <slot name="error"></slot>
        <template v-if="hasError">{{ errorsString }}</template>
      </template>
    </LLPopper>
    <LLInputError v-if="(hasError || showError) && !showErrorInTooltip">
      <div v-if="hasError" class="LLTextInputNew__error LLTextInputNew__styling-error-color">
        <slot name="error"></slot>
        <template v-if="hasError">{{ errorsString }}</template>
      </div>
    </LLInputError>
  </div>
</template>

<script>
import LLInputError from './LLInputError'
import LLInputCaption from './LLInputCaption'
import LLInputContainer from '@/components/common/LLInput/LLInputContainer'
import LLPopper from '@/components/utils/LLPopper.vue'

export default {
  name: 'LLInputText',
  components: { LLPopper, LLInputCaption, LLInputError, LLInputContainer },
  props: {
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    value: { type: [String, Number], default: '' },
    mask: { type: [String, Object], default: null },
    maxLength: { type: Number, default: null },
    withCleaner: { type: Boolean, default: false },
    withCleanerWithValue: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    cleanerFunction: { type: Function, default: null },
    showErrorInTooltip: { type: Boolean, default: false },
    pattern: { type: String, default: null },
    inputmode: { type: String, default: null }
  },
  data() {
    return {
      currentValue: '',
      focused: false,
      showCaption: false,
      showError: false,
      rootStyleClass: 'LLInputText'
    }
  },
  computed: {
    showPlaceholder() {
      return (
        (!this.currentValue?.toString()?.length && !this.mask) ||
        (!this.currentValue?.toString()?.length && this.mask && !this.focused)
      )
    },
    showCleaner() {
      return !!(this.withCleaner || (this.withCleanerWithValue && this.currentValue))
    },
    hasError() {
      return !!this.errors?.length
    },
    errorsString() {
      return this.hasError ? this.errors.join(', ') : null
    },
    inputClasses() {
      const classes = []
      const rootClass = this.rootStyleClass
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      return classes
    }
  },
  watch: {
    value: {
      handler(value) {
        this.currentValue = value
      },
      immediate: true
    },
    currentValue: {
      handler(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    updateCurrentValue(value) {
      this.currentValue = value
    },
    setShowSlots() {
      this.$nextTick(() => {
        this.showError = !!this.$slots?.error?.[0]
        this.showCaption = !!this.$slots?.caption?.[0]
      })
    },
    onClear() {
      if (this.cleanerFunction) {
        this.cleanerFunction()
      } else {
        this.currentValue = ''
        this.$refs.input.focus()
      }
    },
    onFocus() {
      this.focused = true
      this.$emit('focus')
    },
    onBlur() {
      this.focused = false
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputText {
  $root: &;
  &_disabled {
    @apply cursor-default pointer-events-none;
  }

  &__placeholder {
    @apply px-4 whitespace-nowrap truncate select-none;
  }

  &__input {
    @apply bg-transparent outline-none w-full h-full px-4;
    font-size: inherit;
    color: inherit;
  }
  &_small {
    #{$root}__input {
      @apply px-2;
    }
    #{$root}__placeholder {
      @apply px-2;
    }
  }
}
</style>
