<template>
  <LLConfirmModalTransition>
    <LLConfirmModal class="StudyNewPageFinished">
      <template #title>
        <template>
          <p class="text-accent-02">{{ $t('all_questions_answered_confirmation_title') }}</p>
        </template>
      </template>
      <template #default>
        <div class="StudyNewPageFinished__content">
          <div class="StudyNewPageFinished__bottom-description">
            <LLWysiwygOutput :text="content" />
          </div>
        </div>
      </template>
      <template #actions>
        <div class="StudyNewPageFinished__actions">
          <LLButton secondary class="StudyNewPageFinished__action" small @click.native="cancelClick">
            {{ $t('all_questions_answered_confirmation_close') }}</LLButton
          >
        </div>
      </template>
    </LLConfirmModal>
  </LLConfirmModalTransition>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import { StudyModel } from '@/models/study'

export default {
  name: 'StudyNewPageFinished',
  components: { LLConfirmModalTransition, LLWysiwygOutput, LLConfirmModal, LLButton },
  props: {
    study: { type: StudyModel, required: true }
  },
  computed: {
    content() {
      return (
        this.study.allResponsesSubmittedMessage ||
        this.$t('all_questions_answered_confirmation_default_content')
      )
    }
  },
  methods: {
    cancelClick() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageFinished {
  &__bottom-description {
    @apply mt-5;
  }
  &__content {
    max-width: 500px;
  }
  &__actions {
    @apply flex;
    @screen mob-only {
      @apply flex-col items-end;
    }
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
    @screen mob-only {
      @apply ml-0 mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
}
</style>
