<template>
  <div class="SearchResponsesItemStudyContext">
    <LLChevron class="SearchResponsesItemStudyContext__chevron" :opened.sync="contextOpened">
      <div class="SearchResponsesItemStudyContext__chevron-label">
        {{ $t('search-page_responses_see-in-context') }}
      </div>
      <template #arrow>
        <ArrowMiniIcon class="SearchResponsesItemStudyContext__chevron-arrow"></ArrowMiniIcon>
      </template>
    </LLChevron>
    <div v-if="contextOpened" class="SearchResponsesItemStudyContext__content">
      <div class="SearchResponsesItemStudyContext__question-title">{{ question.title }}</div>
      <LLWysiwygOutput
        class="SearchResponsesItemStudyContext__question-body"
        :text="question.body"
      ></LLWysiwygOutput>
      <LLTimestamp class="SearchResponsesItemStudyContext__date" :timestamp="response.createdAt">
        <template #default="data">
          {{ $tc('timestamp_replied', data.time) }}
        </template>
      </LLTimestamp>
      <LLWysiwygOutput
        class="SearchResponsesItemStudyContext__response"
        :text="response.text"
      ></LLWysiwygOutput>
      <LLWysiwygOutput
        class="SearchResponsesItemStudyContext__translation"
        :text="translation"
      ></LLWysiwygOutput>
      <LLFileSlider
        v-if="files.length"
        class="SearchResponsesItemStudyContext__files"
        :files="files"
        small
      ></LLFileSlider>
      <div v-for="file in transcribeFiles" :key="file.id" class="SearchResponsesItemStudyContext__transcribe">
        <div class="SearchResponsesItemStudyContext__transcribe-video-wrapper">
          <LLFileSliderFile small :file="file"></LLFileSliderFile>
        </div>
        <div class="SearchResponsesItemStudyContext__transcribe-content">
          <LLWysiwygOutput
            v-if="transcribeOriginal(file)"
            class="SearchResponsesItemStudyContext__response"
            :text="transcribeOriginal(file).text"
          ></LLWysiwygOutput>
          <LLWysiwygOutput
            v-if="transcribeTranslation(file)"
            class="SearchResponsesItemStudyContext__translate"
            :text="transcribeTranslation(file).text"
          ></LLWysiwygOutput>
        </div>
      </div>
      <NuxtLink :to="{ path: `/study/${studyId}/moderate`, query: nuxtLinkQuery }" target="_blank">
        <LLButton class="SearchResponsesItemStudyContext__go-to-conversation-button" small>{{
          $t('search-page_responses_see-in-conversation')
        }}</LLButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import LLChevron from '@/components/common/LLChevron'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
import LLButton from '@/components/common/ui-components/LLButton'
import LLFileSliderFile from '@/components/common/LLFileSlider/LLFileSliderFile'
import LLFileSlider from '@/components/common/LLFileSlider'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
import LLTimestamp from '@/components/common/LLTimestamp'
export default {
  name: 'SearchResponsesItemStudyContext',
  components: {
    LLTimestamp,
    LLWysiwygOutput,
    LLFileSlider,
    LLFileSliderFile,
    LLButton,
    ArrowMiniIcon,
    LLChevron
  },
  props: {
    response: { type: Object, required: true }
  },
  data() {
    return {
      contextOpened: false
    }
  },
  computed: {
    nuxtLinkQuery() {
      return {
        goToQuestionId: this.question?.id,
        goToParticipantId: this.prospect?.id,
        goToConversationMessageId: this.response?.id,
        goToTopicId: this.response?.topic?.id
      }
    },
    files() {
      return this.response?.files?.filter((f) => !f.transcribes.length) || []
    },
    transcribeFiles() {
      return this.response?.files?.filter((f) => f.transcribes.length) || []
    },
    studyId() {
      return this.response?.study?.id
    },
    question() {
      return this.response?.question
    },
    prospect() {
      return this.response?.prospect
    },
    translation() {
      return this.response?.translate?.text
    }
  },
  methods: {
    transcribeOriginal(file) {
      return file?.transcribes?.find((t) => t.isOrigin)
    },
    transcribeTranslation(file) {
      return file?.transcribes?.find((t) => !t.isOrigin)
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemStudyContext {
  &__chevron-arrow {
    @apply text-accent-01-400;
  }
  &__chevron-label {
    @apply text-caption-01 text-primary-01-400;
  }
  &__content {
    @apply mt-2.5 flex flex-col items-start;
  }
  &__question {
    &-title {
      @apply text-caption-01 text-primary-01-400;
    }
    &-body {
      @apply text-body-03 mt-1;
    }
  }
  &__date {
    @apply text-caption-02 text-neutral-01-400 mt-2;
  }
  &__response {
    @apply text-body-03 mt-2;
  }
  &__go-to-conversation-button {
    @apply mt-5;
  }
  &__translation {
    @apply border-success-01 border-l-4 pl-2 text-body-03 mt-2;
  }
  &__files {
    @apply mt-3;
  }
  &__transcribe {
    @apply flex items-start mt-3;
    &-video-wrapper {
      @apply flex-shrink-0;
      width: 50px;
      height: 50px;
    }
    &-content {
      @apply flex-1 ml-4;
      .SearchResponsesItemStudyContext__response {
        @apply mt-0;
      }
    }
  }
}
</style>
