<template>
  <div class="LLTextareaEmoji">
    <div class="LLTextareaEmoji__list" :class="{ LLTextareaEmojiAnimated__list: showAnimatedOnly }">
      <div
        v-if="showAnimated || showAnimatedOnly"
        class="LLTextareaEmojiSuggested"
        :class="{ 'no-border': showAnimatedOnly }"
      >
        <div class="LLTextareaEmojiSuggested__title">
          {{ $t('emoji-textarea_animated') }}
        </div>
        <div class="LLTextareaEmojiSuggested__list">
          <LLButton
            v-for="emoji in animatedEmojies"
            :key="emoji.emoji"
            tertiary
            small
            :title="emoji.description"
            class="LLTextareaEmojiCategoryItem"
            @click="onEmojiClick(emoji)"
          >
            <template #icon-left>
              <img class="LLTextareaEmojiCategoryItem__emoji" :src="getAnimatedReactionUrl(emoji)" />
            </template>
          </LLButton>
        </div>
      </div>
      <div v-if="showSuggested" class="LLTextareaEmojiSuggested">
        <div v-if="false" class="LLTextareaEmojiSuggested__title">
          {{ $t('emoji-textarea_suggested') }}
        </div>
        <div class="LLTextareaEmojiSuggested__list">
          <LLButton
            v-for="emoji in suggestedEmojies"
            :key="emoji.emoji"
            tertiary
            small
            :title="emoji.description"
            class="LLTextareaEmojiCategoryItem"
            @click="onEmojiClick(emoji)"
          >
            <template #icon-left>
              <img class="LLTextareaEmojiCategoryItem__emoji" :src="emojiSrc(emoji.emoji)" />
            </template>
          </LLButton>
        </div>
      </div>
      <div v-if="!showAnimatedOnly">
        <div
          v-for="category in emojiList"
          :key="category.name"
          class="LLTextareaEmoji__category LLTextareaEmojiCategory"
        >
          <div class="LLTextareaEmojiCategory__title">
            {{ category.name }}
          </div>
          <div class="LLTextareaEmojiCategory__list">
            <LLButton
              v-for="emoji in category.emojiList"
              :key="emoji.emoji"
              tertiary
              small
              :title="emoji.description"
              class="LLTextareaEmojiCategoryItem"
              @click="onEmojiClick(emoji)"
            >
              <template #icon-left>
                <img class="LLTextareaEmojiCategoryItem__emoji" :src="emojiSrc(emoji.emoji)" />
              </template>
            </LLButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojiList from '@/common/emojiList'
import LLButton from '@/components/common/ui-components/LLButton'
import unicode from '@/utils/unicode'
import { ReactionsEnums } from '@/common/reactionsEnums'
export default {
  name: 'LLTextareaEmoji',
  components: { LLButton },
  props: {
    showAnimated: { type: Boolean, default: false },
    showSuggested: { type: Boolean, default: false },
    showAnimatedOnly: { type: Boolean, default: false }
  },
  computed: {
    emojiList() {
      return emojiList
    },
    suggestedEmojies() {
      return [
        { emoji: '❤️', description: 'red heart', aliases: ['heart'] },
        { emoji: '🤣', description: 'rolling on the floor laughing', aliases: ['rofl'] },
        { emoji: '✅', description: 'check mark button', aliases: ['white_check_mark'] }
      ]
    },
    animatedEmojies() {
      return Object.keys(ReactionsEnums).map((reactionKey) => ({
        emoji: ReactionsEnums[reactionKey],
        description: ReactionsEnums[reactionKey],
        aliases: [ReactionsEnums[reactionKey]]
      }))
    }
  },
  methods: {
    getAnimatedReactionUrl(emoji) {
      return require(`@/assets/lottie-reactions/preview/${emoji.emoji}.svg`)
    },
    onEmojiClick(emoji) {
      this.$emit('select', emoji)
    },
    emojiSrc(emoji) {
      const emojiCodeList = unicode.parseEmoji(emoji)

      return `/images/emoji/${emojiCodeList.join('_')}.png`
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTextareaEmoji {
  &__list {
    @apply p-4;
    width: 288px;
    height: 260px;
    @screen sm {
      width: 384px;
      height: 300px;
    }
    @media (max-height: 450px) {
      height: 220px;
    }
    @apply overflow-y-auto;
  }
  &__category {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
  &Category {
    &__title {
      @apply text-body-02 text-primary-01-400;
    }
    &__list {
      @apply flex flex-wrap mt-2;
    }
    &Item {
      &__emoji {
        @apply w-6;
      }
    }
  }
  &Suggested {
    @apply mb-4 pb-4 border-neutral-01-100 border-b;
    &__title {
      @apply text-body-02 text-primary-01-400;
    }
    &__list {
      @apply flex flex-wrap mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
  .LLTextareaEmojiAnimated__list {
    width: 250px;
    height: 110px;
    overflow: hidden;
  }
  .no-border {
    border: none;
  }
}
</style>
