<template>
  <svg
    class="HomeIcon"
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02337 20.9456C5.90727 20.7214 4.98237 20.005 4.49299 18.9857C4.14946 18.2702 4.15775 18.3572 4.15775 15.4695C4.15775 12.9383 4.15932 12.8923 4.24978 12.7711C4.42372 12.5379 4.56764 12.4636 4.84516 12.4636C5.05528 12.4636 5.13067 12.4855 5.25962 12.5839C5.57207 12.8223 5.5637 12.7455 5.5637 15.3743C5.5637 17.3552 5.57476 17.7781 5.63234 17.9993C5.82205 18.7279 6.42267 19.3195 7.17636 19.5201C7.5332 19.6152 16.4486 19.6152 16.8054 19.5201C17.4946 19.3366 17.9996 18.8858 18.2961 18.1894C18.3943 17.9588 18.3961 17.9185 18.4181 15.4248C18.4398 12.9564 18.4426 12.8912 18.5324 12.771C18.7064 12.5379 18.8503 12.4636 19.1278 12.4636C19.3379 12.4636 19.4133 12.4855 19.5422 12.5839C19.8629 12.8286 19.853 12.7271 19.8347 15.5561C19.8164 18.383 19.8267 18.2812 19.4887 18.9857C19.1384 19.7157 18.5451 20.3087 17.819 20.6546C17.0691 21.0118 17.4574 20.9885 12.1025 20.998C8.21967 21.0049 7.26999 20.9951 7.02337 20.9456ZM2.41485 11.6641C2.11671 11.5342 1.95034 11.195 2.01318 10.8451C2.03998 10.6959 2.5672 10.1536 6.84969 5.86963C11.1322 1.5857 11.6744 1.0583 11.8235 1.0315C12.2498 0.954872 11.903 0.638799 17.1321 5.86963C22.3597 11.099 22.0468 10.7556 21.9677 11.1778C21.9264 11.3976 21.6595 11.6646 21.4397 11.7059C21.0188 11.7849 21.3301 12.0666 16.4652 7.20359L11.9907 2.73069L7.53861 7.18213C5.02349 9.6969 3.03189 11.6539 2.96091 11.6802C2.7928 11.7427 2.58081 11.7364 2.41485 11.6641Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>

<style scoped lang="scss">
.HomeIcon {
  @apply fill-current;
}
</style>
