<template>
  <svg
    class="icon stroke-current"
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="1"
      width="12"
      height="20"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 5.54492H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 2H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.69238 6.52148V16.5215" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.3076 6.52148V16.5215" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon'
}
</script>
