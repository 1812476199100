import { render, staticRenderFns } from "./StudyNewPageBody.vue?vue&type=template&id=88e8ef1a&scoped=true"
import script from "./StudyNewPageBody.vue?vue&type=script&lang=js"
export * from "./StudyNewPageBody.vue?vue&type=script&lang=js"
import style0 from "./StudyNewPageBody.vue?vue&type=style&index=0&id=88e8ef1a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e8ef1a",
  null
  
)

export default component.exports