<template>
  <div v-if="isAbleToEditResponse || isAbleToRemoveResponse" class="ForumResponseActionsButton">
    <LLOptionsList :disabled="isActionPending">
      <template #trigger>
        <LLButton :disabled="isActionPending" small tertiary-dark>
          <template #icon-left><OptionsIcon /></template>
        </LLButton>
      </template>
      <template #buttons>
        <LLOptionsListButton v-if="isAbleToEditResponse" v-popper-close small @click="edit">
          {{ $t('button_edit') }}
        </LLOptionsListButton>
        <LLOptionsListButton v-if="false" v-popper-close small @click="activateReply">
          {{ $t('button_reply') }}
        </LLOptionsListButton>
        <LLOptionsListButton
          v-if="isAbleToRemoveResponse"
          v-popper-close
          small
          @click="isRemoveConfirmationOpened = true"
        >
          {{ $t('button_remove') }}
        </LLOptionsListButton>
      </template>
    </LLOptionsList>
    <portal to="confirm-modal">
      <LLConfirmModalTransition v-if="isRemoveConfirmationOpened">
        <LLConfirmModal :max-width="600">
          <template #title>{{ $t('forum.response.remove_confirmation_modal.title') }}</template>
          <template #default>
            {{ $t('forum.response.remove_confirmation_modal.message') }}
          </template>
          <template #actions>
            <LLButton :loading="isActionPending" primary small @click="remove">{{
              $t('button_remove')
            }}</LLButton>
            <LLButton
              :disabled="isActionPending"
              secondary
              small
              @click="isRemoveConfirmationOpened = false"
              >{{ $t('button_cancel') }}</LLButton
            >
          </template>
        </LLConfirmModal>
      </LLConfirmModalTransition>
    </portal>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
import OptionsIcon from '@/assets/icons/OptionsIcon.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseActionsButton',
  components: {
    LLConfirmModalTransition,
    LLConfirmModal,
    OptionsIcon,
    LLOptionsListButton,
    LLOptionsList,
    LLButton
  },
  props: {
    response: { type: ForumResponseModel, required: true }
  },
  data() {
    return {
      isActionPending: false,
      isRemoveConfirmationOpened: false
    }
  },
  computed: {
    isAbleToRemoveResponse() {
      return (
        this.response.prospect?.id === this.$store.getters['user/user']?.id ||
        this.response.thread?.prospect?.id === this.$store.getters['user/user']?.id
      )
    },
    isAbleToEditResponse() {
      return (
        this.response.prospect?.id === this.$store.getters['user/user']?.id ||
        this.response.thread?.prospect?.id === this.$store.getters['user/user']?.id
      )
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    async remove() {
      this.isActionPending = true
      try {
        await this.$api.forum.responses.delete({
          chatId: this.response.chatId,
          threadId: this.response.threadId,
          responseId: this.response.id
        })
        this.isRemoveConfirmationOpened = false
        this.$emit('remove')
      } catch (e) {
        console.log(e)
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.isActionPending = false
    },
    activateReply() {
      this.$emit('replyButtonClick')
      this.$emit('update:isNewReplyVisible', true)
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseActionsButton {
}
</style>
