<template>
  <div class="StudyNewPageScreeningBody">
    <StudyNewPageScreeningQuestions
      :replies="replies"
      :study="study"
      :screening-questions="screeningQuestions"
      @updateTempMessage="onUpdateTempMessage"
    />
    <div class="StudyNewPageScreeningBody__actions">
      <LLButton
        class="StudyNewPageScreeningBody__action"
        :loading="isSavingPending"
        :disabled="isSubmitPending"
        secondary
        @click="onSaveProgressClick"
        >{{ $t('save_progress') }}</LLButton
      >
      <LLButton
        class="StudyNewPageScreeningBody__action"
        :loading="isSubmitPending"
        :disabled="!isValid || isSavingPending"
        primary
        @click="onSubmitClick"
        >{{ $t('button_submit') }}</LLButton
      >
    </div>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import StudyNewPageScreeningQuestions from '@/components/pages/study-new/screening/StudyNewPageScreeningQuestions.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import Api from '@/services/api'

export default {
  name: 'StudyNewPageScreeningBody',
  components: { LLButton, StudyNewPageScreeningQuestions },
  props: {
    replies: { type: Array, default: () => {} },
    study: { type: StudyModel, required: true },
    /**
     * @type {QuestionModel[]}
     */
    screeningQuestions: { type: Array, default: () => [] }
  },
  data() {
    return {
      isSavingPending: false,
      isSubmitPending: false
    }
  },
  computed: {
    isValid() {
      return this.replies.filter((reply) => reply.isValid).length === this.screeningQuestions.length
    }
  },
  methods: {
    onUpdateTempMessage({ message }) {
      this.$emit('updateTempMessage', { message })
    },
    async onSaveProgressClick() {
      this.isSavingPending = true
      try {
        await this.saveAllReplies()
      } catch (e) {
        this.$errorAlert(e)
      } finally {
        this.isSavingPending = false
      }
    },
    async onSubmitClick() {
      this.isSubmitPending = true
      try {
        await this.saveAllReplies()
        await this.submitScreeningQuestions()
        await Api.studies.accept({
          studyId: this.study.id
        })
        await this.$router.push({ name: 'Dashboard' })
      } catch (e) {
        this.$errorAlert(e)
      } finally {
        this.isSubmitPending = false
      }
    },
    async submitScreeningQuestions() {
      await this.$api.studies.submit({
        studyId: this.study.id
      })
    },
    async saveAllReplies() {
      const promises = []
      const apiReplies = this.replies
        .filter((reply) => reply.isValid)
        .map((reply) => ({
          reply: reply.convertToApi(),
          questionId: reply.questionId,
          studyId: this.study.id
        }))
      apiReplies.forEach((apiReply) => {
        promises.push(this.$api.studies.sendScreeningQuestionsReplies(apiReply))
      })
      await Promise.all(promises)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageScreeningBody {
  &__actions {
    @apply flex mt-8;
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
  }
}
</style>
