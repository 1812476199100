<template>
  <div class="LLSelectInputValue" :class="classes">
    <div class="LLSelectInputValue__caption">
      <slot name="caption"></slot>
    </div>
    <div v-if="showCleaner" class="LLSelectInputValue__cleaner-wrapper" @click="onCleanClick">
      <CloseIcon class="LLSelectInputValue__cleaner"></CloseIcon>
    </div>
    <ArrowMiniIcon class="LLSelectInputValue__arrow"></ArrowMiniIcon>
  </div>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
import CloseIcon from '@/assets/icons/CloseIcon'
export default {
  name: 'LLSelectInputValue',
  components: { CloseIcon, ArrowMiniIcon },
  props: {
    opened: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false },
    showCleaner: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLSelectInputValue'
      if (this.opened) {
        classes.push(`${rootClass}_opened`)
      }
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      return classes
    }
  },
  methods: {
    onCleanClick(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('clean')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSelectInputValue {
  @apply px-4 py-2 text-body-01 flex items-center justify-between min-w-0;
  $root: &;
  &_opened {
    #{$root}__arrow {
      @apply -rotate-90;
    }
  }
  &_disabled {
    #{$root}__arrow {
      @apply text-neutral-01-100;
    }
  }
  &__caption {
    @apply flex-1 truncate;
  }
  &__arrow {
    @apply transform rotate-90 transition duration-200 flex-shrink-0;
  }
  &__cleaner-wrapper {
    @apply flex-shrink-0 px-1 mr-1 cursor-pointer items-center;
    #{$root}__cleaner {
      @apply h-3 text-neutral-01-400;
    }
  }
}
</style>
