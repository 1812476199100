<template>
  <div class="StudyNewPageCommonHeader">
    <div v-if="!tailwindScreens.mobOnly" class="flex mob:flex-col min-w-0">
      <div class="flex-1 min-w-0">
        <div class="font-serif text-3xl text-primary-01 font-bold" data-e2e="studyName">
          {{ study.name }}
        </div>
        <div class="flex mob:flex-col mt-5 min-w-0">
          <div v-if="study.image" class="mr-7 w-52 h-52 flex-shrink-0 mob:w-full" data-e2e="studyImage">
            <ImageLoader
              class="StudyNewPageCommonHeader__image"
              :file="studyObject.image"
              large
            ></ImageLoader>
          </div>
          <div class="flex-1 min-w-0">
            <div class="mob:ml-0 mob:mt-7 Conversation__descriptions">
              <StudyDates class="mb-4" :study="study"></StudyDates>
              <div v-if="study.objective" class="text-base font-semibold color-primary-01">
                {{ $t('conversation_instructions_title') }}
              </div>
              <LLWysiwygOutput
                v-if="study.objective"
                class="text-base mt-2 color-primary-01 break-words"
                :text="study.objective"
              ></LLWysiwygOutput>
              <StudyButtons :study="study" @studyUpdate="onStudyUpdate"></StudyButtons>
              <LLButton
                v-if="study.isWaiting"
                secondary
                class="mt-5"
                data-e2e="goToDashboardBtn"
                @click="$router.push({ name: 'Dashboard' })"
                >{{ $t('conversation_go_to_my_dashboard') }}</LLButton
              >
            </div>
          </div>
          <ConversationWaiting
            v-if="study.isWaiting"
            class="flex-shrink-0 self-start ml-10 mob:hidden"
          ></ConversationWaiting>
        </div>
      </div>
      <div v-if="!details" class="flex-shrink-0 ml-12 mob:w-full mob:mt-8 mob:ml-0">
        <ConversationProgress
          v-if="conversationStreams"
          :conversation-streams="conversationStreams"
          :question-id-list="questionIdList"
          :study="study"
        ></ConversationProgress>
      </div>
    </div>

    <LLSpoiler
      v-if="tailwindScreens.mobOnly"
      clean
      white
      header-not-separated
      arrow-on-top
      disable-body-padding
      :expanded="isNoInProgressQuestions || details"
    >
      <template #wrap-title>
        <div class="Conversation__title font-serif text-primary-01 font-bold">{{ study.name }}</div>
      </template>
      <div class="flex mob:flex-col">
        <div class="flex-1 px-4 pb-4">
          <div class="flex mob:flex-col mt-5 mob:mt-0">
            <div v-if="study.image" class="mr-10 w-52 h-52 flex-shrink-0 mob:w-full">
              <ImageLoader :file="studyObject.image" large class="object-cover w-full h-full rounded" />
            </div>
            <StudyDates class="mb-4 mob:ml-0 mob:mt-7" :study="study"></StudyDates>
            <div v-if="study.objective" class="text-base font-semibold color-primary-01">
              {{ $t('conversation_instructions_title') }}
            </div>
            <LLWysiwygOutput
              class="text-base mt-2 color-primary-01"
              :text="study.objective"
            ></LLWysiwygOutput>
            <StudyButtons :study="study" @studyUpdate="onStudyUpdate"></StudyButtons>
          </div>
        </div>
        <div v-if="!details" class="flex-shrink-0 ml-24 mob:w-full mob:mt-9 mob:ml-0 mob:hidden">
          <ConversationProgress
            v-if="conversationStreams"
            :conversation-streams="conversationStreams"
            :question-id-list="questionIdList"
            :study="study"
            full-width
            class="Conversation__progress-mobile w-full border-l-0 border-r-0 border-b-0"
          ></ConversationProgress>
        </div>
      </div>
    </LLSpoiler>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import StudyDates from '@/components/study/StudyDates.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'
import ConversationProgress from '@/pages/Conversation/ConversationProgress.vue'
import ConversationWaiting from '@/pages/Conversation/ConversationWaiting.vue'
import StudyButtons from '@/components/study/StudyButtons.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StreamState } from '@/common/enums'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'

export default {
  name: 'StudyNewPageCommonHeader',
  components: {
    ImageLoader,
    StudyButtons,
    ConversationWaiting,
    ConversationProgress,
    LLWysiwygOutput,
    LLButton,
    StudyDates
  },
  mixins: [screenCatcher],
  props: {
    study: { type: StudyModel, required: true },
    details: { type: Boolean, default: false },
    questions: { type: Array, required: true, default: () => [] },
    conversationStreams: { type: Array, default: () => null },
    topics: { type: Array, default: () => [] }
  },
  computed: {
    studyObject() {
      return StudyModel.parseFromApi({ study: this.study })
    },
    studyForStudyButtons() {
      return { ...new StudyModel(this.study), hasScreeningQuestions: this.study.screeningQuestions.length }
    },
    questionIdList() {
      return this.topics.reduce((result, topic) => {
        return [...result, ...(topic?.questionIdList || [])]
      }, [])
    },
    isNoInProgressQuestions() {
      return !this.questions.find((question) => {
        this.conversationStreams?.find(
          (conversationStream) =>
            conversationStream.questionId === question.id && conversationStream.state === StreamState.ANSWERED
        )
      })
    }
  },
  methods: {
    onStudyUpdate({ study }) {
      //TODO: total refactor this buttons
      const updatedStudy = new StudyModel({
        ...this.study,
        invitationState: study.participantInvitationState
      })
      this.$emit('studyUpdate', { study: updatedStudy })
      if (study.participantInvitationState === 50) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageCommonHeader {
  &__image {
    @apply object-cover w-full h-full rounded;
  }
}
</style>
