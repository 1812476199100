<template>
  <div class="DashboardConversation w-full h-full flex flex-col">
    <ConversationHead class="flex-shrink-0" :study="study" data-e2e="studyHead"></ConversationHead>
    <div
      class="px-5 pt-6 pb-7 mob:px-4 mob:pt-5 mob:pb-5 border-neutral-01-50 border rounded-bl rounded-br flex-1"
    >
      <div class="flex-col">
        <div
          class="font-serif DashboardConversation__name break-words text-primary-01 font-bold leading-none"
          data-e2e="studyName"
        >
          {{ study.name }}
        </div>
        <div class="flex mob:flex-col mt-6 mob:mt-5" :class="{ 'mt-3': studyState.isLive }">
          <ConversationImage
            class="flex-shrink-0 sm:mr-6 mob:mb-5"
            :study="study"
            :class="{ 'mob:hidden': studyState.isLive }"
            data-e2e="studyImage"
          ></ConversationImage>
          <div class="flex-1">
            <StudyDates class="mb-4" :study="study" data-e2e="studyDate"></StudyDates>

            <InvitedConversationDescription
              v-if="studyState.isInvited"
              :study="study"
              data-e2e="invitedStudyBody"
              @studyUpdate="onStudyUpdate"
            ></InvitedConversationDescription>

            <PendingConversationDescription
              v-if="studyState.isPendingApproval"
              :study="study"
              data-e2e="pendingStudyBody"
            ></PendingConversationDescription>

            <WaitingConversationDescription
              v-if="studyState.isWaiting"
              :study="study"
              data-e2e="waitingStudyBody"
            ></WaitingConversationDescription>
            <LiveConversationDescription
              v-if="studyState.isLive"
              :study="study"
              data-e2e="liveStudyBody"
            ></LiveConversationDescription>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudyDates from '@/components/study/StudyDates'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StudyStates } from '@/mixins/studyStates'
import ConversationHead from '@/pages/Dashboard/components/conversation/ConversationHead'
import ConversationImage from '@/pages/Dashboard/components/conversation/ConversationImage'
import InvitedConversationDescription from '@/pages/Dashboard/components/conversation/descriptions/InvitedConversationDescription'
import PendingConversationDescription from '@/pages/Dashboard/components/conversation/descriptions/PendingConversationDescription'
import WaitingConversationDescription from '@/pages/Dashboard/components/conversation/descriptions/WaitingConversationDescription'
import LiveConversationDescription from '@/pages/Dashboard/components/conversation/descriptions/LiveConversationDescription'

export default {
  name: 'DashboardConversation',
  components: {
    LiveConversationDescription,
    WaitingConversationDescription,
    PendingConversationDescription,
    InvitedConversationDescription,
    ConversationImage,
    ConversationHead,
    StudyDates
  },
  mixins: [screenCatcher, StudyStates],
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  methods: {
    onStudyUpdate({ study }) {
      this.$emit('studyUpdate', { study })
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardConversation {
  &__name {
    font-size: 32px;
    @screen mob {
      font-size: 28px;
      line-height: 32px;
    }
  }

  .four-lines {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
