import { render, staticRenderFns } from "./ForumChatThreadCardFooter.vue?vue&type=template&id=f7285cc4&scoped=true"
import script from "./ForumChatThreadCardFooter.vue?vue&type=script&lang=js"
export * from "./ForumChatThreadCardFooter.vue?vue&type=script&lang=js"
import style0 from "./ForumChatThreadCardFooter.vue?vue&type=style&index=0&id=f7285cc4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7285cc4",
  null
  
)

export default component.exports