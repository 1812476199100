<template>
  <div class="LLLoader" :class="classList">
    <LoadingIcon class="LLLoader__icon"></LoadingIcon>
    <div v-if="slots.label" class="LLLoader__label"><slot></slot></div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
export default {
  name: 'LLLoader',
  components: { LoadingIcon },
  props: {
    left: { type: Boolean, default: false }
  },
  data() {
    return {
      slots: {
        label: false
      }
    }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_left`]: this.left
      }
    }
  },
  mounted() {
    this.checkSlots()
  },
  beforeUpdate() {
    this.checkSlots()
  },
  methods: {
    checkSlots() {
      this.slots.label = !!this.$slots?.default?.[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.LLLoader {
  @apply w-full flex justify-center items-center;
  &__icon {
    @apply w-5 h-5;
  }
  &__label {
    @apply ml-5 text-body-01 text-neutral-01-400;
  }
  &_left {
    @apply justify-start;
  }
}
</style>
