<template>
  <div class="PollMatrixEditor">
    <PollMatrixEditorDesktop
      v-if="!tailwindScreens.mob && !mobile && !compact"
      v-model="selectedValues"
      :locked="locked"
      :is-first-time-fill="isFirstTimeFill"
      :poll="poll"
    />
    <PollMatrixEditorMobile
      v-if="(tailwindScreens.mob || mobile) && !compact"
      v-model="selectedValues"
      :locked="locked"
      :is-first-time-fill="isFirstTimeFill"
      :poll="poll"
    />
    <PollMatrixEditorCompact v-if="compact" v-model="selectedValues" :locked="locked" :poll="poll" />
  </div>
</template>

<script>
import { screenCatcher } from '@/mixins/screenCatcher'
import PollMatrixEditorDesktop from '@/components/modules/poll/matrix/editor/PollMatrixEditorDesktop.vue'
import PollMatrixEditorMobile from '@/components/modules/poll/matrix/editor/PollMatrixEditorMobile.vue'
import PollMatrixEditorCompact from '@/components/modules/poll/matrix/editor/PollMatrixEditorCompact.vue'
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'

export default {
  name: 'PollMatrixEditor',
  components: { PollMatrixEditorCompact, PollMatrixEditorMobile, PollMatrixEditorDesktop },
  mixins: [screenCatcher],
  props: {
    value: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    selectedValues: {
      get() {
        return this.localResponse.matrixList.map((row) => {
          return {
            id: row.id,
            columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
          }
        })
      },
      set(value) {
        this.localResponse.matrixList = value.map((row) => {
          return {
            id: row.id,
            columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
          }
        })
      }
    },
    isLocalResponseValid() {
      return (
        this.localResponse.matrixList.filter((row) => row?.columnIdList?.length).length ===
        this.poll.rows.length
      )
    }
  },
  watch: {
    response: {
      handler() {
        if (
          !this.$_.isEqual(this.response, this.localResponse) &&
          !(this.response === null && this.localResponse.isEmpty)
        ) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      deep: true
    },
    isLocalResponseValid: {
      handler() {
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.PollMatrixEditor {
  &__row {
    @apply flex;
  }
}
</style>
