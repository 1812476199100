<template>
  <div class="LLWordTextContainer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LLWordTextContainer'
}
</script>

<style lang="scss" scoped>
.LLWordTextContainer {
  /deep/ {
    @apply font-body text-primary-01;
    max-width: 730px;
    padding-bottom: 60px;
    h2 {
      font-family: var(--font-main) !important;
      @apply mt-10 font-semibold text-2xl;
    }
    h3 {
      font-family: var(--font-main);
      @apply mt-8 text-base font-semibold;
    }
    p {
      font-family: var(--font-main);
      @apply my-3;
    }
    ol li {
      &::marker {
        content: '';
      }
    }
    a {
      @apply text-primary-01-400;
    }
    ul {
      padding-left: 0 !important;
    }
    ul li {
      &::marker {
        content: none;
      }
    }
    li {
      @apply ml-10;
    }
  }
}
</style>
