<template>
  <div class="LLInputSpoiler" :class="{ LLInputSpoiler_disabled: disabled }">
    <LLOptionsList :open.sync="opened" trigger="click" :offset="[0, 0]" placement="bottom-start">
      <template #trigger>
        <LLInputContainer
          ref="input-wrapper"
          :disabled="disabled"
          :focused="opened"
          :with-cleaner="withCleaner"
          @clear="onClear"
        >
          <template v-if="!showCaption" #placeholder>
            <div class="LLInputSpoiler__placeholder">
              {{ placeholder }}
            </div>
          </template>
          <template v-if="showCaption" #text-input>
            <div class="LLInputSpoiler__caption">
              <slot name="caption"></slot>
            </div>
          </template>
          <template #right-icon>
            <ArrowMiniIcon :class="arrowClasses" class="LLInputSpoiler__arrow" />
          </template>
        </LLInputContainer>
      </template>
      <template #buttons>
        <div class="LLInputSpoiler__content" :style="contentStyle">
          <slot name="content"></slot>
        </div>
      </template>
    </LLOptionsList>
  </div>
</template>

<script>
import LLInputContainer from '@/components/common/LLInput/LLInputContainer'
import LLOptionsList from '@/components/common/LLOptionsList'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'
export default {
  name: 'LLInputSpoiler',
  components: { ArrowMiniIcon, LLOptionsList, LLInputContainer },
  props: {
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    withCleaner: { type: Boolean, default: false }
  },
  data() {
    return {
      resizeObserver: null,
      inputWidth: null,
      showCaption: false,
      opened: false
    }
  },
  computed: {
    contentStyle() {
      return {
        width: this.inputWidth ? `${this.inputWidth}px` : '300px'
      }
    },
    arrowClasses() {
      const classes = []
      const rootClass = 'LLInputSpoiler__arrow'
      if (this.opened) {
        classes.push(`${rootClass}_opened`)
      }
      return classes
    }
  },
  watch: {
    opened() {
      if (this.opened) {
        this.$emit('show')
      } else {
        this.$emit('hide')
      }
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  mounted() {
    this.$nextTick(() => {
      this.initResizeObserver()
    })
  },
  beforeDestroy() {
    this.destroyResizeObserver()
  },
  methods: {
    onHide() {
      this.$emit('hide')
    },
    onShow() {
      this.opened = true
      this.$emit('show')
    },
    onClear() {
      this.$emit('clear')
    },
    initResizeObserver() {
      const inputWrapper = this.$refs?.['input-wrapper']?.$el
      if (!inputWrapper) {
        return
      }
      const resizeObserver = new ResizeObserver(() => {
        this.inputWidth = inputWrapper?.offsetWidth
      })

      resizeObserver.observe(inputWrapper)
      this.resizeObserver = resizeObserver
    },
    destroyResizeObserver() {
      this.resizeObserver.disconnect()
    },
    setShowSlots() {
      this.$nextTick(() => {
        this.showCaption = !!this.$slots?.caption?.[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSpoiler {
  &_disabled {
    @apply pointer-events-none;
  }
  &__placeholder {
    @apply px-4 select-none cursor-default;
  }
  &__caption {
    @apply px-4 truncate;
  }
  &__arrow {
    @apply transition duration-200;
    transform: rotate(90deg);
    &_opened {
      transform: rotate(270deg);
    }
  }
}
</style>
