<template>
  <div class="StudyNewPageScreening">
    <div class="StudyNewPageScreening__header">
      <StudyNewPageScreeningHeader :study="study" :topics="topics" />
    </div>
    <div class="StudyNewPageScreening__delimeter"></div>
    <div class="StudyNewPageScreening__content">
      <div v-if="isFetching" class="StudyNewPageCommon__loading">
        <LLLoader></LLLoader>
      </div>
      <div v-else-if="error" class="StudyNewPageCommon__error">
        <LLLoaderError @retry="fetchScreeningQuestionReplies"></LLLoaderError>
      </div>
      <div v-else class="StudyNewPageCommon__conversation">
        <StudyNewPageScreeningBody
          :study="study"
          :screening-questions="screeningQuestions"
          :replies="replies"
          @updateTempMessage="onUpdateTempMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import LLLoader from '@/components/common/LLLoader.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'
import StudyNewPageScreeningBody from '@/components/pages/study-new/screening/StudyNewPageScreeningBody.vue'
import StudyNewPageScreeningHeader from '@/components/pages/study-new/screening/StudyNewPageScreeningHeader.vue'

export default {
  name: 'StudyNewPageScreening',
  components: { StudyNewPageScreeningHeader, StudyNewPageScreeningBody, LLLoaderError, LLLoader },
  props: {
    study: { type: StudyModel, required: true },
    /**
     * @type {QuestionModel[]}
     */
    screeningQuestions: { type: Array, default: () => [] },
    topics: { type: Array, default: () => [] }
  },
  data() {
    return {
      isFetching: true,
      error: null,
      replies: []
    }
  },
  created() {
    this.fetchScreeningQuestionReplies()
  },
  methods: {
    onUpdateTempMessage({ message }) {
      const replyIndex = this.replies.findIndex((reply) => reply.questionId === message.questionId)
      replyIndex !== -1 ? this.$set(this.replies, replyIndex, message) : this.replies.push(message)
    },
    async fetchScreeningQuestionReplies() {
      this.isFetching = true
      try {
        const { screeningQuestionReplies } = await this.$api.studies.getScreeningQuestionsReplies({
          studyId: this.study.id
        })
        this.replies = screeningQuestionReplies
          .map((screeningQuestionReply) => {
            const questionId = screeningQuestionReply.screeningQuestionId
            const question = this.screeningQuestions.find((question) => question.id === questionId)
            return (
              (screeningQuestionReply.reply &&
                StudyConversationMessageModel.parseFromApi({
                  message: screeningQuestionReply.reply,
                  questionId,
                  studyId: this.study.id,
                  pollType: question.poll?.type
                })) ||
              null
            )
          })
          .filter((screeningQuestionReply) => screeningQuestionReply)
      } catch (e) {
        this.error = true
      } finally {
        this.isFetching = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageScreening {
  &__header {
    @apply w-full;
  }
  &__loading {
    @apply h-48 items-center justify-center flex;
  }
  &__error {
    @apply h-48 items-center justify-center flex;
  }
  &__delimeter {
    @apply w-full h-px bg-neutral-01-50 my-12;
  }
}
</style>
