<template>
  <LLWordTextContainer class="PrivacyPolicyDE">
    <p class="c1">
      <span class="c21"
        >Der Schutz Ihrer personenbezogenen Daten hat f&uuml;r uns Priorit&auml;t. Diese
        Datenschutzerkl&auml;rung gilt f&uuml;r die Websites, Apps und Dienste, die von Spark Ideas, LLC
        (&bdquo;Spark&ldquo;, &bdquo;wir&ldquo;, &bdquo;uns&ldquo; oder &bdquo;unser&ldquo;) kontrolliert
        werden, einschlie&szlig;lich spark-nyc.com</span
      ><span class="c18">&nbsp;und</span><span class="c19 c25">&nbsp;</span
      ><span class="c21">looklook.app</span><span class="c19 c30">&nbsp;</span
      ><span class="c0"
        >(zusammen die &bdquo;Sites&ldquo;) und der Mobile LookLook (die &bdquo;Mobile App&ldquo;). Bitte
        lesen Sie diese Datenschutzerkl&auml;rung sorgf&auml;ltig durch, denn durch die Nutzung der Sites oder
        der Mobile App stimmen Sie den in dieser Erkl&auml;rung beschriebenen Datenpraktiken zu.</span
      >
    </p>
    <p class="c1 c2"><span class="c19 c23"></span></p>
    <p class="c1 c11">
      <span class="c21"
        >Ihre Nutzung der Sites oder der Mobile App wird auch durch die Nutzungsbedingungen geregelt, die Sie
        hier finden</span
      ><span class="c14">:</span><span class="c21">&nbsp;hier</span>
    </p>
    <p class="c1 c2"><span class="c20 c19"></span></p>
    <p class="c1 c2"><span class="c19 c27"></span></p>
    <p class="c1 c11">
      <span class="c0"
        >Bitte beachten Sie die unten aufgef&uuml;hrte Schlichtungsbestimmung, die Sie verpflichtet,
        au&szlig;er in den gesetzlich verbotenen F&auml;llen und in dem gesetzlich verbotenen Umfang, alle
        Anspr&uuml;che, die Sie gegen Spark haben, auf individueller Basis zu schlichten.</span
      >
    </p>
    <p class="c1 c11">
      <span class="c0"
        >SCHLICHTUNG AUF INDIVIDUELLER BASIS BEDEUTET, DASS SIE NICHT DAS RECHT HABEN UND AUF DAS RECHT AUF
        EINEN RICHTERS ODER EINE JURY VERZICHTEN, &Uuml;BER IHRE ANSPR&Uuml;CHE ZU ENTSCHEIDEN, UND DASS SIE
        NICHT IN EINER KLASSEN-, SAMMEL- ODER REPR&Auml;SENTATIVEN WEISE VORGEHEN D&Uuml;RFEN.</span
      >
    </p>
    <p class="c1 c2"><span class="c0"></span></p>
    <p class="c1"><span class="c5">Erhebung und Verwendung Ihrer personenbezogenen Daten</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >&bdquo;Personenbezogene Daten&ldquo; sind alle Daten, die sich auf eine identifizierte oder
        identifizierbare nat&uuml;rliche Person beziehen. Wir erheben die folgenden Daten von und &uuml;ber
        Sie:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <a id="t.21f0679441b7e707fc6a1b3480cca53e7259f400"></a><a id="t.0"></a>
    <table class="c28">
      <tr class="c6">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Demografische Daten</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Wie wir diese Daten verwenden</span></p>
        </td>
      </tr>
      <tr class="c16">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1">
            <span class="c0">Name<br />Telefonnummer<br />Stadt, Bundesland und Land</span>
          </p>
          <p class="c1"><span class="c0">E-Mail Adresse</span></p>
          <p class="c1"><span class="c0">Beruflicher Titel</span></p>
          <p class="c1">
            <span class="c0">Unternehmen<br />Ausbildung <br />Einkommensklasse<br />Familienstand</span>
          </p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <ul class="c9 lst-kix_list_15-0 start">
            <li class="c1 c4 li-bullet-0"><span class="c0">Durchf&uuml;hren von Studien</span></li>
            <li class="c1 c4 li-bullet-0">
              <span class="c0"
                >Kommunikation mit Kunden und Interessenten (E-Mail, Kontaktformulare, WeChat)</span
              >
            </li>
            <li class="c1 c4 li-bullet-0">
              <span class="c0">Kunden die Nutzung unserer Forschungsplattform erm&ouml;glichen</span>
            </li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Probleme l&ouml;sen</span></li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Kundenunterst&uuml;tzung anbieten</span></li>
          </ul>
          <p class="c1">
            <span class="c0"
              >&nbsp;<br />
              <br />Rechtsgrundlage: Einwilligung, Vertrag, berechtigtes Interesse</span
            >
          </p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Pr&auml;ferenzen</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c32">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1">
            <span class="c0"
              >Hobbys<br />Interessen<br />Einkaufsgewohnheiten<br />Markenpr&auml;ferenz<br />Kaufhistorie<br />Andere
              f&uuml;r die Studie relevante Themen</span
            >
          </p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <ul class="c9 lst-kix_list_13-0 start">
            <li class="c1 c4 li-bullet-0"><span class="c0">Durchf&uuml;hren von Studien</span></li>
          </ul>
          <p class="c17">
            <span class="c0"><br />Rechtsgrundlage: Einwilligung</span>
          </p>
          <p class="c17"><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
          <p class="c3 c17"><span class="c0"></span></p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Dateien</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c29">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1">
            <span class="c0">Fotografien<br />Videos <br />Stimme</span>
          </p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <ul class="c9 lst-kix_list_13-0">
            <li class="c1 c4 li-bullet-0"><span class="c0">Durchf&uuml;hren von Studien</span></li>
          </ul>
          <p class="c1">
            <span class="c0"><br />Rechtsgrundlage: Einwilligung</span>
          </p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Kommunikation</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">Interviews mit Teilnehmern</span></p>
          <p class="c1">
            <span class="c0">Allgemeine Anfragen (E-Mail oder Kontakt f&uuml;r Kundenanfragen)</span>
          </p>
          <p class="c1"><span class="c0">Kundenbetreuung</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <ul class="c9 lst-kix_list_12-0 start">
            <li class="c1 c4 li-bullet-0"><span class="c0">Durchf&uuml;hren von Studien</span></li>
            <li class="c1 c4 li-bullet-0">
              <span class="c0"
                >Kommunikation mit Kunden und Interessenten (E-Mail, Kontaktformulare, WeChat)</span
              >
            </li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Kundensupport anbieten</span></li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Verteilen von Belohnungen</span></li>
          </ul>
          <p class="c1">
            <span class="c0"><br /><br />Rechtsgrundlage: Einwilligung, berechtigtes Interesse</span>
          </p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c5">Technologie</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c22">
        <td class="c12" colspan="1" rowspan="1">
          <p class="c1"><span class="c0">Geolokalisierung der Stadt</span></p>
          <p class="c1"><span class="c0">IP-Adresse</span></p>
          <p class="c1"><span class="c0">Ger&auml;te- und Browserdaten</span></p>
        </td>
        <td class="c26" colspan="1" rowspan="1">
          <ul class="c9 lst-kix_list_14-0 start">
            <li class="c1 c4 li-bullet-0"><span class="c0">Durchf&uuml;hren von Studien</span></li>
            <li class="c1 c4 li-bullet-0">
              <span class="c0"
                >Kunden die Nutzung unserer Forschungsplattform zu erm&ouml;glichen<br />Verbesserung unserer
                Produkte und Dienstleistungen</span
              >
            </li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Probleme beheben</span></li>
            <li class="c1 c4 li-bullet-0"><span class="c0">Plattform sichern</span></li>
          </ul>
          <p class="c1">
            <span class="c0"><br />Rechtsgrundlage: Einwilligung, berechtigtes Interesse</span>
          </p>
        </td>
      </tr>
    </table>

    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c5">Cookies und andere &auml;hnliche Technologien</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wir verwenden notwendige und anonymisierte Cookies und andere &auml;hnliche Technologien auf unseren
        Sites und der Mobile App, um Daten zu erheben. Wir verwenden m&ouml;glicherweise Sitzungscookies oder
        dauerhafte Cookies. Sitzungscookies dauern nur f&uuml;r die spezifische Dauer Ihres Besuchs und werden
        gel&ouml;scht, wenn Sie Ihren Browser schlie&szlig;en. Dauerhafte Cookies verbleiben auf der
        Festplatte Ihres Ger&auml;ts, bis Sie sie l&ouml;schen oder sie ablaufen. Wir verwenden die folgenden
        Arten von Cookies:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <ul class="c9 lst-kix_list_8-0 start">
      <li class="c1 c4 li-bullet-0">
        <span class="c19">Unverzichtbar</span
        ><span class="c0"
          >. Einige Cookies sind unerl&auml;sslich, damit Sie sich auf unseren Sites und / oder der Mobile App
          bewegen und deren Funktionen nutzen k&ouml;nnen, wie z. B. den Zugriff auf sichere Bereiche der
          Sites und / oder der Mobile App. Ohne diese Cookies k&ouml;nnen wir keine geeigneten Inhalte je nach
          Art des von Ihnen verwendeten Ger&auml;ts aktivieren.</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c19">Leistung und Analytik</span
        ><span class="c0"
          >. Wir verwenden Cookies, um zu sehen, wie Sie unsere Sites und / oder die Mobile App nutzen, um
          ihre Leistung zu verbessern und sie weiterzuentwickeln. Durch Cookies werden keine pers&ouml;nlichen
          Informationen gesammelt.</span
        >
      </li>
    </ul>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c5">Weitergabe und Offenlegung Ihrer personenbezogenen Daten</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wir geben Ihre personenbezogenen Daten in den folgenden F&auml;llen und an die folgenden Parteien
        weiter oder legen sie offen:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <ul class="c9 lst-kix_list_8-0">
      <li id="h.30j0zll" class="c17 c4 li-bullet-0">
        <span class="c10"
          >Wir geben Informationen, die &uuml;ber die Sites und die mobile App bereitgestellt werden, an
          unsere Kunden f&uuml;r deren Marketing- und Forschungsstudien und Gesch&auml;ftszwecke weiter.
        </span>
      </li>
      <li class="c17 c4 li-bullet-0">
        <span class="c10"
          >Wir geben Informationen, die &uuml;ber die Seiten und die mobile App bereitgestellt werden, an
          Drittanbieter weiter, um Studien zu verwalten und Kunden Berichte bereitzustellen. Die aktualisierte
          Liste unserer Drittdienstleister kann hier eingesehen werden.</span
        >
      </li>
      <li class="c17 c4 li-bullet-0">
        <span class="c10"
          >Wir k&ouml;nnen Ihre Daten im Zuge eines direkten oder indirekten Reorganisationsprozesses
          weitergeben oder &uuml;bertragen, einschlie&szlig;lich, aber nicht beschr&auml;nkt auf Fusionen,
          &Uuml;bernahmen, Ver&auml;u&szlig;erungen, Konkurse und Verk&auml;ufe aller oder eines Teils unserer
          Verm&ouml;genswerte. Weitere Informationen finden Sie unten unter Zuweisung.
        </span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Wir werden Ihre personenbezogenen Daten offenlegen, um geltendem Recht nachzukommen oder im guten
          Glauben, dass eine solche Ma&szlig;nahme erforderlich ist, um den Anforderungen des Gesetzes zu
          entsprechen oder um einem uns zugestellten Gerichtsverfahren nachzukommen, um unsere Rechte oder
          unser Eigentum zu sch&uuml;tzen und zu verteidigen oder um in dringenden F&auml;llen zum Schutz der
          pers&ouml;nlichen Sicherheit unserer Benutzer zu handeln.</span
        >
      </li>
    </ul>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Als Verkauf im Sinne der geltenden US-Verbraucherschutzgesetze gilt der Verkauf, die Vermietung, die
        Freigabe, die Offenlegung, die Verbreitung, die Zurverf&uuml;gungstellung, die &Uuml;bertragung oder
        die anderweitige m&uuml;ndliche, schriftliche, elektronische oder sonstige &Uuml;bermittlung der
        personenbezogenen Daten eines Verbrauchers durch das Unternehmen an ein anderes Unternehmen oder einen
        Dritten gegen eine finanzielle und manchmal auch andere wertvolle Gegenleistung.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wir verkaufen keine pers&ouml;nlichen Daten im Sinne der Datenschutzgesetze der US-Bundesstaaten, und
        wir w&uuml;rden Ihre pers&ouml;nlichen Daten auch niemals an Dritte in irgendeinem Teil der Welt
        verkaufen.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c5">Erhebung und Nutzung von Daten von Kindern</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wir erheben nicht wissentlich Daten von Kindern unter 13 Jahren in den Vereinigten Staatenvon Amerika
        oder unter 16 Jahren in der EU. Sollten wir erfahren, dass uns eine Person unter 13 Jahren in den
        Vereinigten Staaten von Amerika oder unter 16 Jahren in der EU personenbezogene Daten zur
        Verf&uuml;gung gestellt hat, werden wir diese personenbezogenen Daten l&ouml;schen.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Zugang und Abmeldung</span><a id="id.1fob9te"></a></p>
    <p class="c1 c2"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn sich Ihre Angaben ge&auml;ndert haben und Sie die Daten, die wir &uuml;ber Sie gespeichert
        haben, aktualisieren m&ouml;chten, wenden Sie sich bitte &uuml;ber die unten angegebenen Kontaktdaten
        an uns.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie keine Marketingmitteilungen mehr von uns erhalten m&ouml;chten, k&ouml;nnen Sie sich
        abmelden, indem Sie die in unserer Marketingmitteilung enthaltenen Anweisungen zur Abmeldung befolgen
        oder uns unter der unten angegebenen Adresse kontaktieren. Wir werden Ihre Abmeldung so schnell wie
        m&ouml;glich und in &Uuml;bereinstimmung mit geltendem Recht bearbeiten. Bitte beachten Sie jedoch,
        dass Sie unter Umst&auml;nden einige weitere Nachrichten erhalten, bis die Abmeldung bearbeitet und
        abgeschlossen ist.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Bitte beachten Sie, dass wir Ihnen auch dann, wenn Sie sich von unseren Marketingmitteilungen
        abmelden, administrative Mitteilungen bez&uuml;glich Ihrer Bestellungen und der Sites und / oder der
        Mobile App zusenden werden, einschlie&szlig;lich beispielsweise wichtiger Aktualisierungen
        bez&uuml;glich der Sites und / oder der Mobile App.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Ihre Rechte</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie im EWR, im Vereinigten K&ouml;nigreich oder in der Schweiz ans&auml;ssig sind, haben Sie das
        Recht zu verlangen:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <ul class="c9 lst-kix_list_10-0 start">
      <li class="c1 c4 li-bullet-0">
        <span class="c0">zu erfahren, ob Ihre personenbezogenen Daten verarbeitet werden </span>
      </li>
      <li class="c1 c4 li-bullet-0"><span class="c0">Zugang zu Ihren pers&ouml;nlichen Daten</span></li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >dass wir Ihre personenbezogenen Daten bearbeiten oder Ihnen eine Kopie davon zusenden</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0">die L&ouml;schung oder Anonymisierung Ihrer personenbezogenen Daten</span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0">die Verwendung Ihrer pers&ouml;nlichen Daten einzuschr&auml;nken</span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Wir begrenzen die weitere Studienkommunikation mit Ihnen oder lehnen die Teilnahme an der Studie
          ab.
        </span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >informiert zu werden, wenn wir automatische Entscheidungsfindungs- und Profiling-Tools verwenden
        </span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >eine Beschwerde bei der zust&auml;ndigen Datenschutzaufsichtsbeh&ouml;rde einzureichen</span
        >
      </li>
    </ul>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Liste der EU-Datenschutzbeh&ouml;rden:</span></p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1666017912322649&amp;usg=AOvVaw2q8UQp2EW3_isfaIIhhqql"
          >EWR</a
        ></span
      ><span class="c0">&nbsp;</span>
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1666017912323287&amp;usg=AOvVaw2bmgnXaOzADEbCye5Bn8aU"
          >Schweiz</a
        ></span
      >
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1666017912323750&amp;usg=AOvVaw3_EkzbottIMQcsJgF9Qp4J"
          >Vereinigtes K&ouml;nigreich</a
        ></span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie in Kalifornien, Connecticut, Utah, Colorado, Virginia oder einem anderen US-Bundesstaat mit
        Datenschutzgesetzen leben, haben Sie das Recht, einen Antrag zu stellen:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <ul class="c9 lst-kix_list_11-0 start">
      <li class="c1 c4 li-bullet-0"><span class="c0">Zugang zu Ihren pers&ouml;nlichen Daten</span></li>
      <li class="c1 c4 li-bullet-0"><span class="c0">Berichtigung Ihrer pers&ouml;nlichen Daten</span></li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >zu erfahren, ob wir Ihre Daten verkauft haben, und den Verkauf Ihrer pers&ouml;nlichen Daten
          abzulehnen</span
        >
      </li>
      <li class="c1 c4 li-bullet-0"><span class="c0">L&ouml;schung Ihrer pers&ouml;nlichen Daten</span></li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Recht auf Erhalt Ihrer personenbezogenen Daten in einem leicht verst&auml;ndlichen und, soweit
          technisch machbar, in einem strukturierten, allgemein gebr&auml;uchlichen, maschinenlesbaren
          Format</span
        >
      </li>
    </ul>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Liste der US-Datenschutzbeh&ouml;rden:</span></p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1666017912325320&amp;usg=AOvVaw0bKyztOrvUcz8Q9Hl_9Dnc"
          >Kalifornien</a
        ></span
      ><span class="c0">&nbsp;</span>
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1666017912325665&amp;usg=AOvVaw0hD0KTdPlfYglyHEDuxETz"
          >Connecticut</a
        ></span
      >
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1666017912325954&amp;usg=AOvVaw2kfA9j1sc8-NUiqVlECQmh"
          >Utah</a
        ></span
      >
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1666017912326251&amp;usg=AOvVaw0YpDIKYSGpvo-rAjOaVvU6"
          >Colorado</a
        ></span
      >
    </p>
    <p class="c1">
      <span class="c15"
        ><a
          class="c8"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1666017912326571&amp;usg=AOvVaw3WgsGyqYP8Fv_Y-6rJkuOx"
          >Virginia</a
        ></span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie innerhalb der Grenzen der Volksrepublik China wohnen, besuchen Sie bitte unsere chinesische
        Datenschutzerkl&auml;rung.
      </span>
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie Ihre Rechte aus&uuml;ben m&ouml;chten, senden Sie bitte eine E-Mail an
        privacy@spark-nyc.com.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Verkn&uuml;pfungen mit Dritten</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Spark empfiehlt Ihnen, die Datenschutzerkl&auml;rungen von Websites, die mit den Sites und / oder der
        Mobile App verkn&uuml;pft sind, zu &uuml;berpr&uuml;fen, damit Sie verstehen k&ouml;nnen, wie diese
        Websites Ihre Daten erheben, verwenden und weitergeben. Spark ist nicht verantwortlich f&uuml;r die
        Datenschutzerkl&auml;rungen oder Inhalte auf diesen Websites au&szlig;erhalb der Sites und der Mobile
        App.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c5">Sicherheit Ihrer personenbezogenen Daten</span></p>
    <p class="c1 c3"><span class="c5"></span></p>
    <p class="c1 c11">
      <span class="c0"
        >Spark ergreift angemessene Ma&szlig;nahmen, um Ihre personenbezogenen Daten vor unbefugtem Zugriff,
        unbefugter Nutzung oder Offenlegung zu sch&uuml;tzen. Bitte haben Sie jedoch Verst&auml;ndnis
        daf&uuml;r, dass f&uuml;r keine Daten&uuml;bertragung &uuml;ber das Internet eine 100%ige Sicherheit
        garantiert werden kann. Folglich k&ouml;nnen wir die Sicherheit von Daten, die Sie an uns
        &uuml;bertragen, nicht gew&auml;hrleisten oder garantieren, und Sie verstehen, dass alle Daten, die
        Sie an uns &uuml;bertragen, auf Ihr eigenes Risiko erfolgen. Wenn wir von einer Verletzung der
        Sicherheitssysteme erfahren, k&ouml;nnen wir versuchen, Sie auf elektronischem Wege zu
        benachrichtigen, damit Sie geeignete Schutzma&szlig;nahmen ergreifen k&ouml;nnen. Indem Sie die Sites
        und / oder die Mobile App nutzen oder uns personenbezogene Daten zur Verf&uuml;gung stellen,
        erkl&auml;ren Sie sich damit einverstanden, dass wir mit Ihnen auf elektronischem Wege &uuml;ber
        Sicherheits-, Datenschutz- und Verwaltungsfragen im Zusammenhang mit Ihrer Nutzung der Sites und /
        oder der Mobile App kommunizieren k&ouml;nnen. Wir k&ouml;nnen &uuml;ber die Sites und die Mobile App
        eine Mitteilung ver&ouml;ffentlichen, wenn eine Sicherheitsverletzung auftritt. Wir k&ouml;nnen Ihnen
        unter diesen Umst&auml;nden auch eine E-Mail an die E-Mail-Adresse senden, die Sie uns mitgeteilt
        haben. Je nachdem, wo Sie wohnen, haben Sie m&ouml;glicherweise einen Rechtsanspruch auf eine
        schriftliche Benachrichtigung &uuml;ber eine Sicherheitsverletzung.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Datenspeicherung</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <a id="id.3znysh7"></a>
    <p class="c1 c11">
      <span class="c0"
        >Wir speichern Ihre personenbezogenen Daten f&uuml;r den Zeitraum, der zur Erf&uuml;llung der in
        dieser Datenschutzerkl&auml;rung dargelegten Zwecke erforderlich ist, es sei denn, eine l&auml;ngere
        Aufbewahrungsfrist ist gesetzlich vorgeschrieben oder zul&auml;ssig.</span
      >
    </p>
    <p class="c1 c2"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Internationale Daten&uuml;bermittlung</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Wenn Sie sich daf&uuml;r entscheiden, uns Daten zur Verf&uuml;gung zu stellen, k&ouml;nnen wir diese
        Daten an unsere verbundenen Unternehmen und Tochtergesellschaften oder an andere Dritte, &uuml;ber
        Grenzen hinweg und von Ihrem Land oder Ihrer Gerichtsbarkeit in andere L&auml;nder oder
        Gerichtsbarkeiten auf der ganzen Welt &uuml;bermitteln. Wenn Sie uns aus der EU oder anderen Regionen
        besuchen, in denen f&uuml;r die Datenerfassung und -verwendung Gesetze gelten, die von den Gesetzen
        der USA abweichen k&ouml;nnen, beachten Sie bitte, dass Sie Ihre personenbezogenen Daten in die USA
        und andere Gerichtsbarkeiten &uuml;bertragen, die m&ouml;glicherweise nicht die gleichen
        Datenschutzgesetze wie Ihr Wohnsitzland haben. Sie best&auml;tigen, dass Sie dies durch die
        Bereitstellung Ihrer personenbezogenen Daten verstehen:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <ul class="c9 lst-kix_list_8-0">
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >dass Ihre personenbezogenen Daten f&uuml;r die oben genannten Zwecke in &Uuml;bereinstimmung mit
          dieser Datenschutzerkl&auml;rung verwendet werden und unterzeichnete Datenschutzvereinbarung</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Ihre personenbezogenen Daten in die USA und andere Gerichtsbarkeiten in &Uuml;bereinstimmung mit
          geltendem Recht, wie oben angegeben, &uuml;bermittelt werden k&ouml;nnen. &nbsp;Sie k&ouml;nnen
          unsere aktuelle Liste der Unterauftragsverarbeiter und deren zus&auml;tzliche Ma&szlig;nahmen zur
          Daten&uuml;bermittlung hier einsehen.</span
        >
      </li>
    </ul>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11"><span class="c5">Abtretung</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >F&uuml;r den Fall, dass unsere Verm&ouml;genswerte ganz oder teilweise von einer anderen Partei
        verkauft oder erworben werden, oder im Falle eines direkten oder indirekten Reorganisationsprozesses,
        einschlie&szlig;lich, aber nicht beschr&auml;nkt auf Fusionen, &Uuml;bernahmen,
        Ver&auml;u&szlig;erungen, Konkurse und Verk&auml;ufe aller oder Als Teil unseres Verm&ouml;gens
        gew&auml;hren Sie uns das Recht, die &uuml;ber die Websites und/oder die mobile App gesammelten
        personenbezogenen Daten nach Abschluss einer solchen Transaktion und/oder w&auml;hrend des
        Bewertungsprozesses bis zur &Uuml;bertragung abzutreten. Wenn Sie in einem solchen Fall
        &uuml;bertragen werden, unterliegen Ihre Daten dieser Datenschutzrichtlinie oder einer
        Datenschutzrichtlinie, die Ihre Privatsph&auml;re mindestens in gleichem Ma&szlig;e wie diese
        Datenschutzrichtlinie sch&uuml;tzt.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1 c11 c24"><span class="c5">Streitbeilegung und Vereinbarung zur Schlichtung</span></p>
    <p class="c1 c2 c24"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Mit Ausnahme der F&auml;lle, in denen und in dem Umfang, in denen dies gesetzlich verboten ist,
        stimmen Sie und Spark durch die Nutzung der Sites und / oder der Mobile App zu, dass Sie und Spark im
        Falle von Streitigkeiten, Anspr&uuml;chen, Klagen oder Auseinandersetzungen, die sich aus Ihrer
        Nutzung der Sites und / oder der Mobile App oder aus der Verletzung, Durchsetzung oder Auslegung oder
        der G&uuml;ltigkeit dieser Datenschutzerkl&auml;rung oder eines Teils davon ergeben oder damit in
        Zusammenhang stehen(&bdquo;Streitigkeit&ldquo;), einverstanden sind, dass beide Parteien zun&auml;chst
        in gutem Glauben versuchen, eine solche Streitigkeit beizulegen, indem sie der anderen Partei eine
        schriftliche Mitteilung zukommen lassen, in der die Fakten und Umst&auml;nde der Streitigkeit
        beschrieben werden und der empfangenden Partei eine Frist von drei&szlig;ig (30) Tagen einger&auml;umt
        wird, um auf die Streitigkeit zu antworten oder sie beizulegen. Die Benachrichtigung ist an uns zu
        senden:</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Spark Ideas, LLC</span></p>
    <p class="c1"><span class="c0">1328 Ridge Road</span></p>
    <p class="c1"><span class="c0">Syosset, New York 11791</span></p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Sowohl Sie als auch Spark stimmen zu, dass dieses Streitbeilegungsverfahren eine aufschiebende
        Bedingung ist, die erf&uuml;llt sein muss, bevor ein Rechtsstreit eingeleitet oder eine Klage gegen
        die andere Partei eingereicht werden kann. FALLS EIN STREITFALL NICHT DURCH DAS OBEN GENANNTE
        STREITBEILEGUNGSVERFAHREN GEL&Ouml;ST WERDEN KANN, STIMMEN SIE ZU, DASS DIE ALLEINIGE UND
        AUSSCHLIESSLICHE ZUST&Auml;NDIGKEIT F&Uuml;R EINEN SOLCHEN STREITFALL DURCH EIN VERBINDLICHES
        SCHIEDSVERFAHREN AUF INDIVIDUELLER BASIS ENTSCHIEDEN WIRD. SCHLICHTUNG AUF INDIVIDUELLER BASIS
        BEDEUTET, DASS SIE NICHT DAS RECHT HABEN UND AUF DAS RECHT AUF EINEN RICHTER ODER EINE JURY
        VERZICHTEN, &Uuml;BER IHRE ANSPR&Uuml;CHE ZU ENTSCHEIDEN, UND DASS SIE NICHT IN EINER KLASSEN-,
        SAMMEL- ODER REPR&Auml;SENTATIVEN WEISE VORGEHEN D&Uuml;RFEN. Andere Rechte, die Sie und wir sonst vor
        Gericht haben w&uuml;rden, stehen bei einem Schiedsverfahren nicht zur Verf&uuml;gung oder werden
        eingeschr&auml;nkter sein, einschlie&szlig;lich der Rechte auf Offenlegung und Berufung. Alle
        derartigen Streitigkeiten werden ausschlie&szlig;lich JAMS (www.jamsadr.com) zur verbindlichen
        Schlichtung nach den dann geltenden Regeln vorgelegt, und zwar vor einem Schiedsrichter, der von
        beiden Parteien einvernehmlich vereinbart wird. Der Schlichter wird Anh&ouml;rungen, sofern vorhanden,
        per Telefon- oder Videokonferenz statt durch pers&ouml;nliches Erscheinen durchf&uuml;hren, es sei
        denn, der Schlichter entscheidet auf Anfrage von Ihnen oder uns, dass eine pers&ouml;nliche
        Anh&ouml;rung angemessen ist. Alle pers&ouml;nlichen Auftritte werden an einem Ort abgehalten, der
        f&uuml;r beide Parteien unter Ber&uuml;cksichtigung ihrer Reisef&auml;higkeit und anderer relevanter
        Umst&auml;nde angemessen ist. K&ouml;nnen sich die Parteien nicht auf einen Ort einigen, wird diese
        Entscheidung von JAMS oder dem Schlichter getroffen.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <p class="c1">
      <span class="c0"
        >Der Schiedsrichter und nicht irgendein Bundes-, Staats- oder &ouml;rtliches Gericht oder eine
        Beh&ouml;rde hat die ausschlie&szlig;liche Befugnis zur Beilegung von Streitigkeiten, die sich aus
        oder im Zusammenhang mit der Auslegung, Anwendbarkeit, Durchsetzbarkeit oder Gestaltung dieser
        Datenschutzerkl&auml;rung ergeben, einschlie&szlig;lich aller Anspr&uuml;che, dass diese
        Datenschutzerkl&auml;rung ganz oder teilweise ung&uuml;ltig oder anfechtbar ist.</span
      >
    </p>
    <p class="c1 c3"><span class="c0"></span></p>
    <hr style="page-break-before: always; display: none" />
    <p class="c17 c3"><span class="c5"></span></p>
    <p class="c1 c11"><span class="c5">Gerichtsbarkeit </span></p>
    <p class="c1 c2"><span class="c5"></span></p>
    <a id="id.2et92p0"></a>
    <p class="c1 c7">
      <span class="c0"
        >Diese Datenschutzerkl&auml;rung wurde in &Uuml;bereinstimmung mit den Gesetzen des Staates New York
        erstellt und ist in &Uuml;bereinstimmung mit diesen auszulegen, ohne dass sich widersprechende
        Rechtsprinzipien zur Anwendung kommen. Die Parteien erkennen an, dass diese Datenschutzerkl&auml;rung
        eine Transaktion im zwischenstaatlichen Handel nachweist. Ungeachtet der Bestimmung im vorstehenden
        Absatz in Bezug auf das anwendbare materielle Recht unterliegt jedes Schiedsverfahren, das
        gem&auml;&szlig; den Bestimmungen dieser Datenschutzerkl&auml;rung durchgef&uuml;hrt wird, dem Federal
        Arbitration Act (9 U.S.C. &sect;&sect; 1-16).</span
      >
    </p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7">
      <span class="c5"
        >Ihre kalifornischen Datenschutzrechte und wie wir auf &bdquo;Don&#39;t Track&ldquo;-Signale
        reagieren</span
      >
    </p>
    <p class="c1 c2 c13"><span class="c5"></span></p>
    <p class="c1 c7">
      <span class="c0"
        >Abschnitt 1798.83 des kalifornischen Zivilgesetzbuches (California Civil Code) erlaubt es Besuchern
        der Sites und / oder der Mobile App, die in Kalifornien ans&auml;ssig sind, bestimmte Daten
        bez&uuml;glich unserer Offenlegung von personenbezogenen Daten an Dritte f&uuml;r deren
        Direktmarketingzwecke anzufordern. Wir geben Ihre personenbezogenen Daten nicht an Dritte f&uuml;r
        deren Direktmarketingzwecke weiter.</span
      >
    </p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7">
      <span class="c0"
        >Derzeit erkennen unsere Sites keine automatisierten Browsersignale bez&uuml;glich der
        Nachverfolgungsmechanismen, die &bdquo;Nicht nachverfolgen&ldquo;-Anweisungen enthalten
        k&ouml;nnen.</span
      >
    </p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7"><span class="c5">Kontaktdaten</span></p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7">
      <span class="c0"
        >Spark begr&uuml;&szlig;t Ihre Fragen oder Kommentare zu dieser Datenschutzerkl&auml;rung und unseren
        Datenschutzpraktiken. &nbsp;Kontaktieren Sie uns bitte unter</span
      >
    </p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7"><span class="c0">Spark Ideas, LLC</span></p>
    <p class="c1 c7"><span class="c0">1328 Ridge Road</span></p>
    <p class="c1 c7"><span class="c0">Syosset, New York 11791</span></p>
    <p class="c1 c7"><span class="c0">Vereinigte Staaten von Amerika</span></p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7"><span class="c0">E-Mail-Adresse:</span></p>
    <p class="c1 c7"><span class="c19 c20">privacy@spark-nyc.com</span></p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7"><span class="c0">Telefonnummer:</span></p>
    <p class="c1 c7"><span class="c0">(917) 991-0477</span></p>
    <p class="c1 c2 c13"><span class="c0"></span></p>
    <p class="c1 c7"><span class="c5">&Auml;nderungen dieser Datenschutzerkl&auml;rung</span></p>
    <p class="c1 c7">
      <span class="c0"
        >Spark wird diese Datenschutzerkl&auml;rung gelegentlich aktualisieren, um das Feedback des
        Unternehmens und der Kunden zu ber&uuml;cksichtigen. Wenn wir dies tun, werden wir auch das Datum des
        &bdquo;Inkrafttretens&ldquo; am Anfang dieser Datenschutzerkl&auml;rung &uuml;berarbeiten. Wenn
        wesentliche &Auml;nderungen an der Datenschutzerkl&auml;rung vorgenommen werden, werden wir Sie durch
        einen gut sichtbaren Hinweis auf den Sites und in der Mobile App benachrichtigen. Wir empfehlen Ihnen,
        diese Datenschutzerkl&auml;rung regelm&auml;&szlig;ig zu lesen, um dar&uuml;ber informiert zu sein,
        wie Spark Ihre Daten verwendet.</span
      >
    </p>
  </LLWordTextContainer>
</template>

<script>
export default {
  name: 'PrivacyPolicyDE'
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyDE {
  .lst-kix_list_14-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_14-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_14-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_14-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  .lst-kix_list_14-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_14-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_6-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_13-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_15-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-1 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }
  .lst-kix_list_15-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }
  .lst-kix_list_9-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_15-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-0 > li:before {
    content: '-  ';
  }
  .lst-kix_list_15-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_11-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_13-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_3-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-6 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_13-0 {
    list-style-type: none;
  }
  .lst-kix_list_8-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }
  .lst-kix_list_11-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-7 {
    list-style-type: none;
  }
  .lst-kix_list_7-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_2-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-3 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  .lst-kix_list_10-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  .lst-kix_list_13-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_13-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_15-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_15-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_10-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_10-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_15-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-2 {
    list-style-type: none;
  }
  .lst-kix_list_15-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_15-1 {
    list-style-type: none;
  }
  .lst-kix_list_15-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_15-0 {
    list-style-type: none;
  }
  .lst-kix_list_10-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_15-8 {
    list-style-type: none;
  }
  .lst-kix_list_4-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_15-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-6 {
    list-style-type: none;
  }
  .lst-kix_list_9-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_15-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-4 {
    list-style-type: none;
  }
  .lst-kix_list_15-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_12-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_11-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  .lst-kix_list_12-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_14-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-2 {
    list-style-type: none;
  }
  .lst-kix_list_13-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_14-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-6 > li:before {
    content: '  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ul.lst-kix_list_14-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-7 {
    list-style-type: none;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_12-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_14-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-8 > li:before {
    content: '\0025aa  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c12 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 170.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c26 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 296.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c10 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c17 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c20 {
    color: #0066ff;
    font-weight: 400;
    vertical-align: baseline;
    font-style: normal;
  }
  .c27 {
    color: #000000;
    font-weight: 400;
    vertical-align: baseline;
    font-style: normal;
  }
  .c15 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
  }
  .c23 {
    color: #0563c1;
    font-weight: 400;
    vertical-align: baseline;
    font-style: normal;
  }
  .c28 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c19 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
  }
  .c31 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 71.2pt 72pt 72pt 72pt;
  }
  .c30 {
    color: #0563c1;
    font-weight: 400;
  }
  .c25 {
    color: #0066ff;
    font-weight: 400;
  }
  .c18 {
    font-weight: 400;
  }
  .c4 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c2 {
    margin-left: 1pt;
    height: 10pt;
  }
  .c8 {
    color: inherit;
    text-decoration: inherit;
  }
  .c7 {
    margin-left: 1pt;
    margin-right: 4pt;
  }
  .c14 {
    color: #0066ff;
  }
  .c9 {
    padding: 0;
    margin: 0;
  }
  .c13 {
    margin-right: 4pt;
  }
  .c21 {
  }
  .c29 {
    height: 45pt;
  }
  .c6 {
    height: 15pt;
  }
  .c3 {
    height: 10pt;
  }
  .c32 {
    height: 90pt;
  }
  .c16 {
    height: 135pt;
  }
  .c24 {
    margin-right: 7pt;
  }
  .c33 {
    text-decoration: none;
  }
  .c11 {
    margin-left: 1pt;
  }
  .c22 {
    height: 105pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    padding-bottom: 4pt;

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
  }
  p {
    margin: 0;
    color: #000000;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
