<template>
  <div ref="select-wrapper" class="LLSelectInputNew" :class="inputClasses">
    <div v-if="hasCaptionSlot" class="LLSelectInputNew__caption LLSelectInputNew__styling-text-color">
      <slot name="caption"></slot>
    </div>
    <div class="LLSelectInputNew__container LLSelectInputNew__styling">
      <v-popover
        :open="listOpened"
        :auto-hide="false"
        :trigger="'manual'"
        class="LLSelectInputNew__popover"
        :popover-class="['tooltip_white', 'tooltip_without-arrow']"
        placement="bottom-center"
        offset="0"
      >
        <div class="LLSelectInputNew__popover-helper" @click="onListClick">
          <LLSelectInputValue
            :show-cleaner="showCleaner"
            :opened="listOpened"
            :disabled="disabled"
            class="LLSelectInputNew__input"
            @clean="onClean"
          >
            <template #caption>
              <div v-show="showFilterInput" class="LLSelectInputNew__filter-input-wrapper">
                <input
                  ref="filter-input"
                  v-model="filterText"
                  v-mask="filterMask"
                  class="LLSelectInputNew__filter-input"
                  type="text"
                  @click.stop
                />
              </div>
              <div
                v-if="showTempValue"
                data-e2e="SelectInputCaption"
                class="LLSelectInputNew__styling-text-input"
              >
                {{ getCaption(tempValue) }}
              </div>
              <div v-if="showPlaceholder" class="LLSelectInputNew__styling-placeholder-color">
                {{ placeholder }}
              </div>
            </template>
          </LLSelectInputValue>
        </div>
        <template #popover>
          <div class="LLSelectInputNew__values" :style="listValuesStyle">
            <div v-if="loading" class="LLSelectInputNew__loading">
              <LoadingIcon class="LLSelectInputNew__loading-icon"></LoadingIcon>
            </div>
            <div v-if="!loading" class="">
              <LLSelectListValue
                v-for="listValue in filteredValues"
                :key="valueKey(listValue)"
                :selected="preselectedValue === listValue"
                class="LLSelectInputNew__value"
                @mouseover.native="preselectValue(listValue)"
                @click.native="selectValue(listValue)"
              >
                <slot name="value-caption" :value="listValue">{{ getCaption(listValue) }}</slot>
              </LLSelectListValue>
            </div>
          </div>
        </template>
      </v-popover>
      <!--      <div class="LLSelectInputNew__trigger" @click="onListClick"></div>-->
    </div>
    <LLInputError v-if="hasErrorSlot">
      <slot name="error"></slot>
    </LLInputError>
    <LLInputError v-if="hasErrorSlot || hasError">
      <slot v-if="hasErrorSlot" name="error"></slot>
      <template v-if="hasError">{{ errorsString }}</template>
    </LLInputError>
  </div>
</template>

<script>
import { InputStyling } from '@/mixins/inputStyling'
import LLSelectInputValue from '@/components/common/LLSelectInput/LLSelectInputValue'
import LLSelectListValue from '@/components/common/LLSelectInput/LLSelectListValue'
import LoadingIcon from '@/components/common/LoadingIcon'
import LLInputError from '@/components/common/LLInput/LLInputError'
export default {
  name: 'LLSelectInputNew',
  components: { LLInputError, LoadingIcon, LLSelectListValue, LLSelectInputValue },
  mixins: [InputStyling],
  props: {
    errors: { type: Array, default: () => [] },
    value: { type: [String, Number, Object], default: '' },
    placeholder: { type: String, default: '' },
    values: { type: Array, default: () => [] },
    keyFn: { type: Function, default: (value) => value.key },
    reduce: { type: Function, default: (value) => value },
    filterFn: { type: Function, default: (value, text) => value.includes(text) },
    caption: { type: Function, default: (value) => value },
    withFilter: { type: Boolean, default: false },
    filterMask: { type: [String, Object], default: null },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    withCleaner: { type: Boolean, default: false }
  },
  data() {
    return {
      rootStyleClass: 'LLSelectInputNew',
      filled: true,
      tempValue: null,
      listOpened: false,
      selectWidth: 0,
      preselectedValue: null,
      filterText: ''
    }
  },
  computed: {
    showCleaner() {
      return this.tempValueExist && this.withCleaner
    },
    tempValueExist() {
      return this.tempValue !== null && this.tempValue !== undefined
    },
    showTempValue() {
      return (
        (!this.withFilter && this.tempValueExist) ||
        (this.withFilter && !this.listOpened && this.tempValueExist)
      )
    },
    showFilterInput() {
      return this.withFilter && this.listOpened
    },
    showPlaceholder() {
      return (
        (this.withFilter && !this.listOpened && !this.tempValueExist) ||
        (!this.withFilter && !this.tempValueExist)
      )
    },
    hasCaptionSlot() {
      return !!this.$slots?.caption
    },
    hasErrorSlot() {
      return !!this.$slots?.errors
    },
    listValuesStyle() {
      return { width: `${this.selectWidth}px` }
    },
    hasError() {
      return !!this.errors?.length
    },
    errorsString() {
      return this.hasError ? this.errors.join(',') : null
    },
    filteredValues() {
      if (this.filterText) {
        return this.values.filter((v) => this.filterFn(v, this.filterText))
      } else {
        return this.values
      }
    }
  },
  watch: {
    hasError: {
      immediate: true,
      handler(value) {
        this.error = value
      }
    },
    value: {
      handler(value) {
        const tempValue = this.values.find((v) => this.getReduceValue(v) === value)
        if (tempValue === undefined) {
          this.tempValue = null
        } else {
          this.tempValue = tempValue
        }
      },
      immediate: true
    },
    tempValue: {
      handler(value) {
        this.$emit('input', this.getReduceValue(value))
      },
      immediate: true
    },
    listOpened: {
      handler() {
        this.updateSelectWidth()
        this.active = this.listOpened
        if (this.listOpened && this.tempValue) {
          this.preselectedValue = this.tempValue
        }
        if (this.listOpened) {
          this.$nextTick(() => {
            if (this.withFilter && this.$refs['filter-input']) {
              this.$refs['filter-input'].focus()
            }
          })
        }
        if (this.listOpened) {
          this.$emit('listOpen')
        } else {
          this.$emit('listClose')
        }
      },
      immediate: true
    },
    filteredValues: {
      handler() {
        if (this.filteredValues?.length) {
          this.preselectedValue = this.filteredValues[0]
        }
      }
    }
  },
  mounted() {
    this.updateSelectWidth()
  },
  methods: {
    onClean() {
      this.tempValue = null
    },
    onListClick(e) {
      if (this.listOpened) {
        this.closeList()
      } else {
        document.body.click()
        this.openList()
        e.stopPropagation()
      }
    },
    openList() {
      this.listOpened = true
      window.addEventListener('keydown', this.processKeysEvents)
      window.addEventListener('click', this.closeList)
    },
    closeList() {
      this.filterText = ''
      this.listOpened = false
      window.removeEventListener('keydown', this.processKeysEvents)
      window.removeEventListener('click', this.closeList)
    },
    processKeysEvents(e) {
      if (e.code === 'Enter') {
        e.preventDefault()
        this.selectPreselectedValue()
      } else if (e.code === 'ArrowUp') {
        e.preventDefault()
        this.preselectPrevValue()
      } else if (e.code === 'ArrowDown') {
        e.preventDefault()
        this.preselectNextValue()
      }
    },
    preselectValue(value) {
      this.preselectedValue = value
    },
    getReduceValue(value) {
      try {
        return this.reduce(value)
      } catch {
        return null
      }
    },
    getCaption(value) {
      try {
        return this.caption(value)
      } catch {
        return null
      }
    },
    selectValue(value) {
      this.tempValue = value
      this.closeList()
    },
    selectPreselectedValue() {
      if (this.listOpened) {
        this.tempValue = this.preselectedValue
        this.closeList()
      }
    },
    preselectNextValue() {
      if (!this.filteredValues?.length) {
        return
      }
      if (!this.preselectedValue) {
        this.preselectedValue = this.filteredValues[0]
        return
      }
      const currentIndex = this.filteredValues.indexOf(this.preselectedValue)
      if (currentIndex === this.filteredValues.length - 1) {
        this.preselectedValue = this.filteredValues[0]
      } else {
        this.preselectedValue = this.filteredValues[currentIndex + 1]
      }
    },
    preselectPrevValue() {
      if (!this.filteredValues?.length) {
        return
      }
      if (!this.preselectedValue) {
        this.preselectedValue = this.filteredValues[this.filteredValues.length - 1]
        return
      }
      const currentIndex = this.filteredValues.indexOf(this.preselectedValue)
      if (currentIndex === 0) {
        this.preselectedValue = this.filteredValues[this.filteredValues.length - 1]
      } else {
        this.preselectedValue = this.filteredValues[currentIndex - 1]
      }
    },
    valueKey(value) {
      if (this.keyFn) {
        return this.keyFn(value)
      } else {
        return value
      }
    },
    updateSelectWidth() {
      if (!this.$refs['select-wrapper']) {
        return '100'
      } else {
        this.selectWidth = this.$refs['select-wrapper']?.clientWidth - 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSelectInputNew {
  $root: &;
  @import '../../assets/styles/components/input';
  @apply relative;
  &__filter-input {
    @apply outline-none w-full;
  }
  &__caption {
    @apply text-body-02 mb-2;
  }
  &__container {
    @apply flex items-stretch relative;
    #{$root}__input {
      @apply w-full outline-none px-4;
    }
    #{$root}__trigger {
      @apply w-full h-full;
    }
  }
  &__popover-helper {
    @apply w-full absolute top-0 left-0 h-full;
  }
  &__popover {
    @apply w-full absolute top-0 left-0 h-full;
    /deep/ .trigger {
      @apply block w-full absolute top-0 left-0 h-full;
    }
  }
  &__values {
    @apply py-1 overflow-y-auto;
    max-height: 350px;
    #{$root}__value {
      @apply w-full;
    }
  }
  &__input {
    @apply h-full;
  }
  #{$root}__error {
    @apply h-4 text-body-03;
  }
  &__loading {
    @apply w-full h-8 flex items-center justify-center;
  }
  &__loading-icon {
    @apply w-5 h-5;
  }
  &__styling-text-input {
    @apply truncate;
  }
  &__styling-placeholder-color {
    @apply truncate;
  }
}
</style>
