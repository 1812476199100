<template>
  <div class="FirstVisit">
    <div class="FirstVisit__content">
      <h2 class="FirstVisit__title">
        {{ $t('welcome_to_look_look_let_s_start_with_some_basic_details') }}
      </h2>
      <LoadingIcon v-if="userFetching" class="FirstVisit__loading"></LoadingIcon>
      <div v-if="!userFetching" class="FirstVisit__form-wrapper">
        <ValidationObserver ref="validation-observer">
          <UserEditModalProfile
            class="FirstVisit__form"
            sms-consent-required
            ai-warning-required
            :user.sync="localUser"
          ></UserEditModalProfile>
        </ValidationObserver>
        <div class="FirstVisit__actions">
          <LLButton :action="onSave" :loading="savePending" :disabled="!valid" data-e2e="submitBtn">
            {{ $t('submit') }}
          </LLButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProspectModel } from '@/models/prospect'
import Api from '@/services/api'
import UserEditModalProfile from '@/components/user/UserEditModal/UserEditModalContent/UserEditModalProfile'
import LLButton from '@/components/common/ui-components/LLButton'
import LoadingIcon from '@/components/common/LoadingIcon'
import { localeChanged } from 'vee-validate'
export default {
  name: 'FirstVisit',
  components: { LoadingIcon, LLButton, UserEditModalProfile },
  data() {
    return {
      localUser: null,
      valid: false,
      savePending: false,
      userFetching: false
    }
  },
  computed: {
    userInStore() {
      return this.$store.state.user.user
    },
    user() {
      return ProspectModel.parseFromApi({ prospect: this.userInStore })
    }
  },
  watch: {
    localUser: {
      async handler() {
        this.valid = await this.validate()
        const locale = this.localUser?.language?.toLowerCase()
        if (locale && this.$i18n.locale !== locale) {
          this.$i18n.locale = locale
          this.$store.commit('ui/SET_LOCALE', locale)
          localeChanged()
        }
      },
      deep: true
    }
  },
  async created() {
    this.userFetching = true
    await this.$store.dispatch('user/fetchUser')
    this.localUser = new ProspectModel(this.user)
    this.$nextTick(async () => {
      this.valid = await this.validate()
    })
    this.userFetching = false
  },
  async mounted() {
    this.$nextTick(async () => {
      this.valid = await this.validate()
    })
  },
  methods: {
    async onSave() {
      this.savePending = true
      try {
        const {
          firstName,
          lastName,
          phone,
          image,
          gender,
          birthday,
          language,
          region,
          city,
          country,
          placeId,
          interestIdList,
          smsConsentAccepted,
          aiWarningAccepted
        } = this.localUser
        const newUserData = {
          firstName,
          lastName,
          phone,
          gender,
          birthday,
          preferredLanguage: language,
          region,
          city,
          country,
          placeId: placeId || undefined,
          interestIdList,
          requireRegistration: false,
          imageId: image?.id,
          smsConsentAccepted: smsConsentAccepted || false,
          aiWarningAccepted: aiWarningAccepted || false
        }
        const { prospectAccount } = await Api.user.sync({ ...newUserData })
        this.$store.commit('user/UPDATE_USER', { ...prospectAccount })

        this.$emit('close')

        let locale = this.localUser?.language?.toLowerCase()
        if (locale) {
          this.$i18n.locale = locale
          localeChanged()
          this.$store.commit('ui/SET_LOCALE', locale)
        }
        await this.$router.push('/set-avatar')
      } catch (e) {
        this.$errorAlert(e)
      }
      this.savePending = false
    },
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver) {
        const validationInfo = await validationObserver.validateWithInfo()
        return validationInfo.flags.valid
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.FirstVisit {
  @apply flex h-full;
  &__title {
    @apply text-center;
  }
  &__loading {
    @apply mx-auto mt-10;
  }
  &__content {
    @apply m-auto py-10 px-10;
    @screen md-max {
      @apply px-4 pt-4;
    }
  }
  &__form-wrapper {
    max-width: 700px;
    @apply w-full m-auto mt-12;
  }
  &__actions {
    @apply mt-8;
  }
}
</style>
