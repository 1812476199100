import { render, staticRenderFns } from "./ForumReactionsItem.vue?vue&type=template&id=02495a6f&scoped=true"
import script from "./ForumReactionsItem.vue?vue&type=script&lang=js"
export * from "./ForumReactionsItem.vue?vue&type=script&lang=js"
import style0 from "./ForumReactionsItem.vue?vue&type=style&index=0&id=02495a6f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02495a6f",
  null
  
)

export default component.exports