<template>
  <div v-if="study.image" class="ConversationImage">
    <div class="flex flex-shrink-0 h-48 w-48 mob:w-full mob:h-auto">
      <ImageLoader :file="studyObject.image" large class="ConversationImage__image" />
    </div>
    <router-link
      v-if="studyState.isPendingApproval || studyState.isWaiting"
      tag="a"
      :to="`/study/${study.id}`"
      class="font-semibold block text-primary-01-400 mt-5 underline mob:hidden"
      >{{ $t('conversation_study_details') }}
    </router-link>
  </div>
</template>

<script>
import { StudyStates } from '@/mixins/studyStates'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
import { StudyModel } from '@/models/study'

export default {
  name: 'ConversationImage',
  components: { ImageLoader },
  mixins: [StudyStates],
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    studyObject() {
      return StudyModel.parseFromApi({ study: this.study })
    }
  }
}
</script>

<style lang="scss" scoped>
.ConversationImage {
  &__image {
    @apply h-full w-full rounded object-cover;
  }
}
</style>
