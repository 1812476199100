<template>
  <div class="SearchPagination">
    <LLPagination
      :loading="pending"
      :total="total"
      :current="current"
      :per-page="perPage"
      @goToPage="goToPage"
    ></LLPagination>
  </div>
</template>

<script>
import LLPagination from '@/components/common/LLPagination'
export default {
  name: 'SearchPagination',
  components: { LLPagination },
  props: {
    pending: { type: Boolean, default: false },
    current: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    perPage: { type: Number, default: 0 }
  },
  methods: {
    goToPage(page) {
      this.$emit('goToPage', page)
    }
  }
}
</script>
