<template>
  <svg
    class="ForumReply2Icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6C3 4.89543 3.89543 4 5 4H18C19.1046 4 20 4.89543 20 6V15C20 16.1046 19.1046 17 18 17H17C16.4477 17 16 17.4477 16 18V20.2987C16 21.2791 14.7358 21.674 14.1778 20.8679L11.7982 17.4308C11.6115 17.161 11.3042 17 10.976 17H5C3.89543 17 3 16.1046 3 15V6Z"
      stroke-width="1.5"
      fill="none"
    />
    <rect x="7" y="8.45215" width="9" height="1" rx="0.1" />
    <rect x="8" y="11.4521" width="8" height="1" rx="0.1" />
  </svg>
</template>

<script>
export default {
  name: 'ForumReply2Icon'
}
</script>

<style scoped lang="scss">
.ForumReply2Icon {
  @apply fill-current stroke-current;
}
</style>
