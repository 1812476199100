<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8002 2.49922C10.8002 2.05739 10.442 1.69922 10.0002 1.69922C9.55837 1.69922 9.2002 2.05739 9.2002 2.49922V10.5685L6.39925 7.76752C6.08684 7.4551 5.5803 7.4551 5.26788 7.76752C4.95546 8.07994 4.95546 8.58647 5.26788 8.89889L9.43455 13.0656C9.58458 13.2156 9.78806 13.2999 10.0002 13.2999C10.2124 13.2999 10.4159 13.2156 10.5659 13.0656L14.7326 8.89889C15.045 8.58647 15.045 8.07994 14.7326 7.76752C14.4202 7.4551 13.9136 7.4551 13.6012 7.76752L10.8002 10.5685V2.49922ZM2.5002 11.6992C2.94202 11.6992 3.3002 12.0574 3.3002 12.4992V15.8326C3.3002 16.0624 3.3915 16.2828 3.55404 16.4454C3.71657 16.6079 3.93701 16.6992 4.16686 16.6992H15.8335C16.0634 16.6992 16.2838 16.6079 16.4464 16.4454C16.6089 16.2828 16.7002 16.0624 16.7002 15.8326V12.4992C16.7002 12.0574 17.0584 11.6992 17.5002 11.6992C17.942 11.6992 18.3002 12.0574 18.3002 12.4992V15.8326C18.3002 16.4868 18.0403 17.1142 17.5777 17.5767C17.1151 18.0393 16.4877 18.2992 15.8335 18.2992H4.16686C3.51266 18.2992 2.88525 18.0393 2.42267 17.5767C1.96008 17.1142 1.7002 16.4868 1.7002 15.8326V12.4992C1.7002 12.0574 2.05837 11.6992 2.5002 11.6992Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon'
}
</script>
