<template>
  <div class="LLPhoneInput">
    <LLInputCaption><slot name="caption"></slot></LLInputCaption>
    <ValidationObserver>
      <div class="LLPhoneInput__inputs">
        <ValidationProvider
          v-slot="data"
          name="phoneCode"
          class="LLPhoneInput__input_code"
          :rules="{ required: required, ...codeRules }"
          immediate
        >
          <LLSelectInputNew
            v-model="tempValues.code"
            error-space
            :values="countryCodes"
            :reduce="(codeListValue) => codeListValue.value"
            :caption="(codeListValue) => codeListValue.label"
            :state-error="!!errors.length"
            :errors="data.errors"
            :filter-mask="{ mask: '[+9[9[9[9]]]]', greedy: false }"
            with-filter
            :filter-fn="(codeListValue, text) => codeListValue.label.includes(text)"
            :placeholder="$t('placeholder_phone_code')"
            class=""
          >
            <template #error></template>
          </LLSelectInputNew>
        </ValidationProvider>
        <ValidationProvider
          v-slot="data"
          name="phonenNumber"
          class="LLPhoneInput__input_phone"
          :rules="{ required: required, 'normal-regex': /^([0-9]{1,12})$/, ...numberRules }"
          immediate
        >
          <LLInputText
            v-model="tempValues.phone"
            error-space
            :disabled="!hasCode"
            :mask="{ mask: '9{1,12}', autoUnmask: true }"
            :errors="[...errors, ...data.errors]"
            :placeholder="$t('placeholder_phone_number')"
            class=""
          >
          </LLInputText>
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LLInputText from './LLInput/LLInputText'
import LLSelectInputNew from '@/components/common/LLSelectInputNew'
import { CountryList } from '@/common/countryCodes'
import LLInputCaption from '@/components/common/LLInput/LLInputCaption'
export default {
  name: 'LLPhoneInput',
  components: { LLInputCaption, LLInputText, LLSelectInputNew, ValidationProvider, ValidationObserver },
  props: {
    value: { type: String, default: '' },
    errors: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    numberRules: { type: Object, default: () => {} },
    codeRules: { type: Object, default: () => {} }
  },
  data() {
    return {
      tempValues: {
        code: '',
        phone: ''
      }
    }
  },
  computed: {
    currentPhoneString() {
      return this.tempValues.code && this.tempValues.phone
        ? `${this.tempValues.code ? this.tempValues.code : ''}${this.tempValues.phone}`
        : null
    },
    hasCode() {
      return !!this.tempValues?.code?.length
    },
    countryCodes() {
      return CountryList.sort((a, b) => {
        if (a.code === 'US') {
          return -1
        }
        if (a.dial_code < b.dial_code) {
          return -1
        }
        if (a.dial_code > b.dial_code) {
          return 1
        }
        return 0
      }).map((c) => ({
        label: `${c?.flag} ${c.dial_code}`,
        value: c.dial_code
      }))
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value !== this.currentPhoneString) {
          this.updateTempPhone()
        }
      },
      immediate: true
    },
    tempValues: {
      handler() {
        this.updateParentValue()
      },
      deep: true
    }
  },
  methods: {
    combineErrors(newErrorsArray) {
      return [this.errors, ...newErrorsArray].join(',')
    },
    updateParentValue() {
      this.$emit('input', this.currentPhoneString)
    },
    updateTempPhone() {
      let maxLength = 0
      let foundCode = null
      let phoneCutLength = 0
      this.countryCodes.forEach((c) => {
        if (this.value.startsWith(c.value) || `+${this.value}`.startsWith(c.value)) {
          if (c.value.length > maxLength) {
            maxLength = c.value.length
            foundCode = c.value
            if (this.value.startsWith(c.value)) {
              phoneCutLength = foundCode.length
            } else if (`+${this.value}`.startsWith(c.value)) {
              phoneCutLength = foundCode.length - 1
            }
          }
        }
      })
      if (foundCode) {
        this.tempValues.code = foundCode
        this.tempValues.phone = this.value.slice(phoneCutLength)
      } else {
        this.tempValues.code = null
        this.tempValues.phone = this.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLPhoneInput {
  $root: &;
  @apply relative;
  &__caption {
    @apply text-body-02 mb-2;
  }
  &__caption-helper {
    @apply h-5;
  }
  &__inputs {
    @apply flex;
    #{$root}__input {
      &_code {
        @apply w-32 flex-shrink-0;
      }
      &_phone {
        @apply ml-2 flex-1;
      }
    }
  }
}
</style>
