<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.0002 1.07422C0.558368 1.07422 0.200195 1.43239 0.200195 1.87422V18.1242C0.200195 18.566 0.558368 18.9242 1.0002 18.9242H11.0002C11.442 18.9242 11.8002 18.566 11.8002 18.1242V13.1242C11.8002 12.6824 11.442 12.3242 11.0002 12.3242C10.5584 12.3242 10.2002 12.6824 10.2002 13.1242V17.3242H1.8002V2.67422H10.2002V6.87422C10.2002 7.31605 10.5584 7.67422 11.0002 7.67422C11.442 7.67422 11.8002 7.31605 11.8002 6.87422V1.87422C11.8002 1.43239 11.442 1.07422 11.0002 1.07422H1.0002ZM15.5169 5.95103C15.1797 5.66563 14.6749 5.7077 14.3895 6.04498C14.1041 6.38227 14.1462 6.88705 14.4834 7.17244L16.8787 9.19924H5.0002C4.55837 9.19924 4.2002 9.55741 4.2002 9.99924C4.2002 10.4411 4.55837 10.7992 5.0002 10.7992H16.8787L14.4834 12.826C14.1462 13.1114 14.1041 13.6162 14.3895 13.9535C14.6749 14.2908 15.1797 14.3328 15.5169 14.0474L19.5794 10.6099C19.7591 10.4579 19.8627 10.2346 19.8627 9.99924C19.8627 9.76392 19.7591 9.54053 19.5794 9.38853L15.5169 5.95103Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExportIcon'
}
</script>
