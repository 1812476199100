<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.15071 2.15168C2.35188 1.95052 2.62472 1.8375 2.90922 1.8375H10.8863C11.1708 1.8375 11.4437 1.95052 11.6449 2.15168C11.846 2.35285 11.959 2.6257 11.959 2.9102V3.79654C11.959 4.18314 12.2724 4.49654 12.659 4.49654C13.0456 4.49654 13.359 4.18314 13.359 3.79654V2.9102C13.359 2.2544 13.0985 1.62546 12.6348 1.16174C12.1711 0.698015 11.5421 0.4375 10.8863 0.4375H2.90922C2.25342 0.4375 1.62448 0.698015 1.16076 1.16174C0.697039 1.62546 0.436523 2.2544 0.436523 2.9102V10.8873C0.436523 11.5431 0.697039 12.1721 1.16076 12.6358C1.62448 13.0995 2.25342 13.36 2.90922 13.36H3.79557C4.18217 13.36 4.49557 13.0466 4.49557 12.66C4.49557 12.2734 4.18217 11.96 3.79557 11.96H2.90922C2.62472 11.96 2.35188 11.847 2.15071 11.6458C1.94954 11.4447 1.83652 11.1718 1.83652 10.8873V2.9102C1.83652 2.6257 1.94954 2.35285 2.15071 2.15168ZM8.04101 9.11527C8.04101 8.52284 8.52127 8.04258 9.1137 8.04258H17.0908C17.6833 8.04258 18.1635 8.52284 18.1635 9.11527V17.0924C18.1635 17.6848 17.6833 18.1651 17.0908 18.1651H9.1137C8.52127 18.1651 8.04101 17.6848 8.04101 17.0924V9.11527ZM9.1137 6.64258C7.74807 6.64258 6.64101 7.74964 6.64101 9.11527V17.0924C6.64101 18.458 7.74807 19.5651 9.1137 19.5651H17.0908C18.4565 19.5651 19.5635 18.458 19.5635 17.0924V9.11527C19.5635 7.74964 18.4565 6.64258 17.0908 6.64258H9.1137Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CopyIcon'
}
</script>
