<template>
  <LLPage class="EditThreadPage">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #content>
      <ForumThreadEditorPage v-if="chat" :chat="chat" :thread="thread" />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'
import ForumThreadEditorPage from '@/components/pages/forum/thread/editor/ForumThreadEditorPage'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
export default {
  name: 'EditThreadPage',
  components: { LLBreadCrumbs, ForumThreadEditorPage, LLPage },
  data() {
    return {
      chat: '',
      thread: null,
      isFetching: false,
      error: null
    }
  },
  computed: {
    crumbs() {
      return [
        { name: this.$t('main_home_label'), path: '/' },
        {
          name: this.$t('forum.chat_list_page.title', { teamName: this.selectedTeam?.name }),
          path: '/chats'
        },
        {
          name: this.chat?.name || this.$t('forum.breadcrumbs.chat_placeholder'),
          path: `/chats/${this.chatIdParam}`
        },
        ...(this.threadIdParam
          ? [
              {
                name: this.thread?.title || this.$t('forum.breadcrumbs.thread_placeholder'),
                path: `/chats/${this.chatIdParam}/threads/${this.threadIdParam}`
              }
            ]
          : []),
        {
          name: this.threadIdParam
            ? this.$t('forum.thread_editor_page.page_name_edit')
            : this.$t('forum.thread_editor_page.page_name_create')
        }
      ]
    },
    threadIdParam() {
      return this.$route.params.threadId
    },
    chatIdParam() {
      return this.$route.params.chatId
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.error = null
      this.isFetching = true
      try {
        const { chat } = await this.fetchChat()
        this.chat = chat
        if (this.$route.params.threadId) {
          const { thread } = await this.fetchThread()
          this.thread = thread
        }
      } catch (e) {
        console.log(e)
        this.error = this.$getErrorMessage(e)
      }
      this.isFetching = false
    },
    async fetchChat() {
      try {
        const { chat: forumChat } = await this.$api.forum.chats.get({ chatId: this.chatIdParam })
        const chat = ForumChatModel.parseFromApi({ chat: forumChat })
        return { chat }
      } catch (e) {
        console.log(e)
        if (e?.response?.status === 404) {
          error({ statusCode: 404, message: 'Chat not found' })
        } else if (e?.response?.status) {
          throw error({ statusCode: e.response.status, message: 'Something went wrong' })
        } else {
          throw error({ statusCode: 500, message: 'Server error' })
        }
      }
    },

    async fetchThread() {
      try {
        const { thread: apiThread } = await this.$api.forum.threads.get({
          chatId: this.chatIdParam,
          threadId: this.threadIdParam
        })
        const thread = ForumThreadModel.parseFromApi({ thread: apiThread, chatId: this.chatIdParam })
        return { thread }
      } catch (e) {
        console.log(e)
        if (e?.response?.status === 404) {
          error({ statusCode: 404, message: 'Chat not found' })
        } else if (e?.response?.status) {
          throw error({ statusCode: e.response.status, message: 'Something went wrong' })
        } else {
          throw error({ statusCode: 500, message: 'Server error' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.EditThreadPage {
}
</style>
