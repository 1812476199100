import { render, staticRenderFns } from "./StudyNewPageUnfinishedError.vue?vue&type=template&id=68a591c2&scoped=true"
import script from "./StudyNewPageUnfinishedError.vue?vue&type=script&lang=js"
export * from "./StudyNewPageUnfinishedError.vue?vue&type=script&lang=js"
import style0 from "./StudyNewPageUnfinishedError.vue?vue&type=style&index=0&id=68a591c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a591c2",
  null
  
)

export default component.exports