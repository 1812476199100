<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.7748 1.90078C4.7748 1.50037 5.0994 1.17578 5.4998 1.17578C5.90021 1.17578 6.2248 1.50038 6.2248 1.90078V2.97578H13.7748V1.90078C13.7748 1.50037 14.0994 1.17578 14.4998 1.17578C14.9002 1.17578 15.2248 1.50038 15.2248 1.90078V2.97578H17.9998C18.4554 2.97578 18.8248 3.34515 18.8248 3.80078V18.0008C18.8248 18.4564 18.4554 18.8258 17.9998 18.8258H1.9998C1.54417 18.8258 1.1748 18.4564 1.1748 18.0008V3.80078C1.1748 3.34515 1.54417 2.97578 1.99981 2.97578H4.7748V1.90078ZM17.3748 4.42578H2.6248V6.57578H17.3748V4.42578ZM17.3748 8.02578H2.6248V17.3758H17.3748V8.02578ZM11.1748 14.0008C11.1748 13.5451 11.5442 13.1758 11.9998 13.1758H13.4998C13.9554 13.1758 14.3248 13.5451 14.3248 14.0008C14.3248 14.4564 13.9554 14.8258 13.4998 14.8258H11.9998C11.5442 14.8258 11.1748 14.4564 11.1748 14.0008Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon'
}
</script>
