import Vue from 'vue'

import auth from './modules/auth.js'
import user from './modules/user'
import studies from './modules/studies'
import appData from './modules/appData.js'
import system from './modules/system.js'
import interests from './modules/interests.js'
import dashboard from './modules/dashboard.js'
import forum from './modules/forum'
import search from './modules/search'

let Api = {
  auth,
  user,
  appData,
  studies,
  system,
  interests,
  dashboard,
  search,
  forum: forum()
}

export default Api

Vue.prototype.$api = Api
