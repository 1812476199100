<template>
  <svg
    class="icon stroke-current"
    width="20"
    height="20"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 1L16 5L5 16H1V12L12 1Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 21H19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>
