<template>
  <div
    class="PollMatrixEditorItem"
    :class="{
      PollMatrixEditorItem_checked: isChecked,
      PollMatrixEditorItem_disabled:
        disabled || (locked && !isChecked) || disabledByLimit || disabledByExclusive
    }"
  >
    <input
      :id="`poll-checkbox-${rowId}-${columnId}`"
      v-model="isChecked"
      :disabled="disabled || locked || disabledByLimit || disabledByExclusive"
      class="PollMatrixEditorItem__input"
      :type="!isRadio ? 'checkbox' : 'radio'"
      :value="true"
      @focus="onFocus"
      @blur="isFocused = false"
    />
    <label
      class="PollMatrixEditorItem__content"
      :disabled="disabled || locked || disabledByLimit || disabledByExclusive"
      :for="`poll-checkbox-${rowId}-${columnId}`"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      @mousedown="isMouseDown = true"
    >
      <div v-if="isRadio" class="PollMatrixEditorItem__radio">
        <LLRadioIcon :is-hovered="showHoveredState" :disabled="disabled || locked" :is-checked="isChecked" />
      </div>
      <div v-if="!isRadio" class="PollMatrixEditorItem__checkbox">
        <LLCheckboxIcon
          :is-hovered="showHoveredState"
          :disabled="disabled || locked || disabledByLimit || disabledByExclusive"
          :is-checked="isChecked"
        />
      </div>
      <div v-if="showText" class="PollMatrixEditorItem__answer">
        <div class="PollMatrixEditorItem__text-wrapper">
          {{ text }}
        </div>
      </div>
    </label>
  </div>
</template>

<script>
// TODO: hide label only for non-admin

import { PollModel } from '@/models/poll'
import { screenCatcher } from '@/mixins/screenCatcher'
import LLCheckboxIcon from '@/components/common/LLCheckboxIcon.vue'
import LLRadioIcon from '@/components/common/LLRadioIcon.vue'

export default {
  name: 'PollMatrixEditorItem',
  components: { LLRadioIcon, LLCheckboxIcon },
  mixins: [screenCatcher],
  props: {
    showText: { type: Boolean, default: false },
    rowId: { type: String, required: true },
    columnId: { type: String, required: true },
    poll: { type: PollModel, required: true },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    value: { type: Array, required: true }
  },
  data() {
    return {
      isChecked: false,
      isHovered: false,
      isFocused: false,
      isMouseDown: false
    }
  },
  computed: {
    disabledByLimit() {
      return (
        this.poll.isAnswerLimitEnabled &&
        this.value.find((row) => row.id === this.rowId)?.columnIdList?.length >=
          this.poll.answerLimitAmount &&
        !this.isRadio &&
        !this.isChecked
      )
    },
    disabledByExclusive() {
      return this.isExclusiveChoiceSelected && !this.currentRowSelectedIdObject[this.columnId]
    },
    text() {
      return this.poll.columns.find((column) => column.id === this.columnId)?.text
    },
    showHoveredState() {
      return this.isHovered || this.isFocused
    },
    isRadio() {
      return this.poll.isAnswerLimitEnabled && this.poll.answerLimitAmount === 1
    },
    mediumThumbnails() {
      return this.tailwindScreens.mobOnly
    },
    columnsObject() {
      return this.poll.columns.reduce((acc, row) => ({ ...acc, [row.id]: row }), {})
    },
    currentRowSelectedIdObject() {
      const selectedIdObject = {}
      this.value
        .find((row) => row.id === this.rowId)
        ?.columnIdList?.forEach((choiceId) => {
          selectedIdObject[choiceId] = true
        })
      return selectedIdObject
    },
    isExclusiveChoiceSelected() {
      return !!this.value
        .find((row) => row.id === this.rowId)
        ?.columnIdList?.find((choiceId) => this.columnsObject[choiceId]?.isExclusive)
    }
  },
  watch: {
    isExclusiveChoiceSelected: {
      handler(to) {
        if (to && !this.columnsObject[this.columnId]?.isExclusive) {
          this.isChecked = false
        }
      }
    },
    value: {
      handler() {
        const row = this.value.find((row) => row.id === this.rowId)
        this.isChecked =
          !!row?.columnIdList?.find((columnId) => columnId === this.columnId) &&
          (!this.isExclusiveChoiceSelected || this.columnsObject[this.columnId]?.isExclusive)
      },
      deep: true,
      immediate: true
    },
    isChecked: {
      handler() {
        const copiedValue = this.$_.cloneDeep(this.value)
        const valuesArray = copiedValue.find((row) => row.id === this.rowId)
        if (Array.isArray(valuesArray?.columnIdList)) {
          const valueIndex = valuesArray?.columnIdList.findIndex((columnId) => columnId === this.columnId)
          if (this.isChecked && valueIndex === -1) {
            if (this.isRadio) {
              valuesArray.columnIdList = [this.columnId]
            } else {
              valuesArray.columnIdList.push(this.columnId)
            }
          } else if (!this.isChecked && valueIndex !== -1) {
            valuesArray.columnIdList.splice(valueIndex, 1)
          }
        } else if (!valuesArray) {
          copiedValue.push({ id: this.rowId, columnIdList: [this.columnId] })
        }
        this.$emit('input', copiedValue)
        this.$emit('change')
      }
    }
  },
  methods: {
    onFocus() {
      if (!this.isMouseDown) {
        this.isFocused = true
      }
      this.isMouseDown = false
    }
  }
}
</script>

<style scoped lang="scss">
.PollMatrixEditorItem {
  $root: &;

  &__checkbox {
    width: 24px;
    height: 24px;
    @apply flex items-center justify-center flex-shrink-0;
  }

  &__radio {
    width: 24px;
    height: 24px;
    @apply flex items-center justify-center flex-shrink-0;
  }

  &__input {
    @apply w-0 h-0 opacity-0 absolute;
  }

  &__content {
    @apply flex p-2 rounded cursor-pointer transition duration-200;
  }

  &__answer {
    @apply ml-2 select-none flex;
  }

  &__image-wrapper:not(&__image-wrapper:last-child) {
    @apply mr-2 flex-shrink-0;
  }

  &__text-wrapper {
    @apply break-words;
  }

  &_checked {
    #{$root}__content {
      @apply bg-neutral-01-15;
    }
  }

  &_disabled {
    #{$root}__content {
      @apply cursor-default;
    }

    #{$root}__answer {
      @apply text-neutral-01-400;
    }
  }
}
</style>
