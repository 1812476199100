export default {
  isLive(study) {
    return study.participantApprovalState === 20 && study.state === 20
  },
  isPendingApproval(study) {
    return study.participantApprovalState === 10 && study.participantInvitationState === 40
  },
  isWaiting(study) {
    return study.participantApprovalState === 20 && study.state === 10
  },
  isInvited(study) {
    return (
      study.participantApprovalState === 10 &&
      (study.participantInvitationState === 10 ||
        study.participantInvitationState === 20 ||
        study.participantInvitationState === 30 ||
        study.participantInvitationState === 60)
    )
  }
}
