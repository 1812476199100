<template>
  <div class="SignIn"></div>
</template>

<script>
import globalRoutes from '@/utils/globalRoutes'

export default {
  name: 'SignIn',
  beforeMount() {
    window.location.href = globalRoutes.loginPage
  }
}
</script>

<style lang="scss" scoped>
.SignIn {
}
</style>
