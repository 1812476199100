export const CompleteRegistrationDestinationType = {
  DASHBOARD: 10,
  STUDY: 20,
  CHAT: 30
}
export const ConversationMessageType = {
  TEXT: 10,
  YES_OR_NO: 20,
  MULTIPLE_CHOICE: 30,
  SLIDER: 40,
  STARS: 50,
  SINGLE_CHOICE: 60,
  RANKED_LIST: 70,
  MATRIX_SINGLE_CHOICE: 80
}

export const ForumChatState = {
  DRAFT: 10,
  LIVE: 20,
  COMPLETED: 30,
  ARCHIVED: 40
}

export const ForumChatType = {
  DEFAULT: 10,
  WECHAT: 20
}
export const ForumThreadState = {
  ACTIVE: 10,
  HIDDEN: 20,
  ARCHIVED: 30
}

export const PollType = {
  MULTIPLE_CHOICE: 10,
  NUMBER_ENTRY: 20,
  RANKED_LIST: 30,
  MATRIX: 40
}

export const PollNumberStyle = {
  NUMBER_INPUT: 10,
  SLIDER: 20,
  STARS: 30
}

export const StreamState = {
  NOT_ANSWERED: 10,
  ANSWERED: 20,
  RESOLVED: 30
}

export const SearchCategories = {
  RESPONSES: 30
}

export const SearchResponsesSortTypes = {
  RELEVANCY: 10,
  DATE: 20
}
