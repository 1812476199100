<template>
  <div class="tabs" :class="classObject">
    <div class="tabs__header" :class="tabsHeaderClass">
      <nav class="tabs__list" :class="noBorder ? '' : 'border-b-2'">
        <a
          v-for="(tab, index) in tabs"
          :key="index"
          class="tabs__list-item"
          :class="{ 'tabs__list-item--active': tab.isActive }"
          href="#"
          :data-e2e="tab.testingString"
          @click.prevent="selectTab(tab, index)"
        >
          {{ tab.label }}
        </a>
      </nav>
    </div>
    <div class="tabs__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLTabs',
  props: {
    level: {
      type: [Number, String],
      required: false,
      default: 1
    },
    tabsHeaderClass: {
      type: String,
      required: false,
      default: ''
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    tabs: []
  }),
  computed: {
    classObject() {
      return [`level-${this.level}`]
    }
  },
  created() {
    this.tabs = this.$children
  },
  methods: {
    selectTab(tabElement, indexToSelect) {
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === indexToSelect
      })

      if (tabElement.name) this.$emit('change', tabElement.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  &__list {
    @apply flex flex-wrap border-neutral-01-15;
  }
  &__list-item {
    @apply uppercase text-sm tracking-wide text-neutral-01 border-neutral-01-15 mr-8 py-3 font-semibold;
    margin-bottom: -2px;
    .level-2 & {
      @apply normal-case tracking-normal mr-6 py-2;
    }
    &:last-child {
      @apply mr-0;
    }
    &--active {
      @apply text-accent-01 border-b-2 border-accent-01 pointer-events-none;
    }
    &:hover {
      @apply border-neutral-01 border-b-2;
    }
  }
}
</style>
