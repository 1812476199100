<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 11V14L14 11H8C6.89543 11 6 10.1046 6 9V2C6 0.9 6.9 0 8 0H18C19.1046 0 20 0.89543 20 2V9C20 10.1046 19.1046 11 18 11H17ZM14 13V15C14 16.1046 13.1046 17 12 17H6L3 20V17H2C0.89543 17 0 16.1046 0 15V8C0 6.9 0.9 6 2 6H4V9C4 11.2091 5.79086 13 8 13H14Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChatIcon'
}
</script>
