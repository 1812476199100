<template>
  <div class="ll-checkbox" :class="classList">
    <label v-if="caption" class="ll-checkbox__caption" :for="id">
      {{ caption }}
    </label>
    <label class="ll-checkbox__label" :class="{ 'is-disabled': disabled, 'not-inline': notInline }">
      <input
        :id="id"
        ref="input"
        class="ll-checkbox__input"
        type="checkbox"
        :value="value"
        :indeterminate.prop="indeterminate"
        :checked="shouldBeChecked"
        :disabled="disabled"
        @change="updateInput"
      />
      <span
        class="ll-checkbox__icon flex-shrink-0"
        :class="{ 'is-checked': shouldBeChecked, 'is-disabled': disabled }"
      >
        <CheckedIcon />
      </span>
      <slot />
    </label>

    <slot name="suffix" />
  </div>
</template>

<script>
import uid from '@/mixins/uid'

export default {
  name: 'LLCheckbox',
  mixins: [uid],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: { type: [String, Number, Object], default: '' },
    modelValue: { type: null, default: false },
    indeterminate: { type: Boolean, default: false },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
    caption: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    notInline: { type: Boolean, default: false },
    withoutMargin: { type: Boolean, default: false },
    itemsStart: { type: Boolean, default: false }
  },
  computed: {
    id() {
      return `ll-checkbox-${this.uid}`
    },
    shouldBeChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue === this.trueValue
    },
    classList() {
      const classes = []
      const rootClass = 'll-checkbox'
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.withoutMargin) {
        classes.push(`${rootClass}_without-margin`)
      }
      if (this.itemsStart) {
        classes.push(`${rootClass}_items-start`)
      }
      return classes
    }
  },
  watch: {
    shouldBeChecked: {
      handler(value) {
        if (!value && this.indeterminate) {
          this.$refs.input.indeterminate = true
        }
      }
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('checkboxChange', event.target.checked)
      const isChecked = event.target.checked

      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ll-checkbox {
  $root: &;
  font-size: 0;
  @apply select-none;
  &_without-margin {
    #{$root}__icon {
      @apply m-0;
    }
  }
  &_disabled {
    #{$root}__caption {
      @apply cursor-default;
    }
    #{$root}__label {
      @apply cursor-default;
    }
  }
  &_items-start {
    #{$root}__label {
      @apply items-start;
    }
  }
  &__caption {
    @apply inline-block mb-3 font-semibold cursor-pointer;
  }
  &__label {
    @apply w-full inline-flex items-center text-body-01 cursor-pointer;
    &.is-disabled {
      @apply text-neutral-01-200;
    }
    &.not-inline {
      @apply flex;
    }
  }
  &__input {
    // position: absolute;
    display: none;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
    &:indeterminate + #{$root}__icon {
      @apply items-center justify-center;
      svg {
        @apply hidden;
      }
      &:after {
        @apply w-2.5 h-0.5 bg-primary-01-400  transition duration-200 opacity-100;
        content: '';
        visibility: visible;
      }
    }
  }
  &__icon {
    @apply inline-flex mr-3 rounded bg-white text-white border border-primary-01-400 transition duration-200;
    width: 18px;
    height: 18px;
    box-shadow: 0px 2px 3px rgba(#000, 0.1);
    svg {
      @apply m-auto;
      width: 10px;
      height: 8px;
      opacity: 0;
      visibility: hidden;
    }
    &.is-checked {
      @apply border-primary-01-400 bg-primary-01-400;
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
    &.is-disabled {
      @apply bg-neutral-01-25 border-neutral-01-50;
    }
  }
}
</style>
