<template>
  <div class="SearchResponsesHeaderActions">
    <div class="SearchResponsesHeaderActions__aside">
      <div class="SearchResponsesHeaderActions__results-num">
        {{ $tc('common_amount_results', total) }}
      </div>
    </div>
    <div class="SearchResponsesHeaderActions__sort-by-wrapper">
      <div class="SearchResponsesHeaderActions__sort-by SortBy">
        <div class="SortBy__description">{{ $t('search-page_responses_sort-by') }}</div>
        <LLOptionsList :offset="[0, 5]" placement="bottom-end">
          <template #buttons>
            <LLOptionsListButton
              v-popper-close
              @click.native="localSortType = SearchResponsesSortTypes.RELEVANCY"
              >{{ $t('search-page_responses_sort-type_relevancy') }}</LLOptionsListButton
            >
            <LLOptionsListButton
              v-popper-close
              @click.native="localSortType = SearchResponsesSortTypes.DATE"
              >{{ $t('search-page_responses_sort-type_date') }}</LLOptionsListButton
            >
          </template>
          <template #trigger="{ opened }">
            <LLChevron :opened="opened" class="SortBy__chevron">
              <span class="SortBy__label">{{ currentSortTypeLabel }}</span>
              <template #arrow>
                <ArrowMiniIcon class="SortBy__icon"></ArrowMiniIcon>
              </template>
            </LLChevron>
          </template>
        </LLOptionsList>
      </div>
    </div>
  </div>
</template>

<script>
import LLChevron from '@/components/common/LLChevron'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton'
import { SearchResponsesSortTypes } from '@/common/enums'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
export default {
  name: 'SearchResponsesHeaderActions',
  components: { LLOptionsList, LLOptionsListButton, ArrowMiniIcon, LLChevron },
  props: {
    total: { type: Number, default: 0 },
    withDemographics: { type: Boolean, default: false },
    sortType: { type: Number, required: true }
  },
  data() {
    return {
      localSortType: this.sortType,
      SearchResponsesSortTypes
    }
  },
  computed: {
    currentSortTypeLabel() {
      switch (this.localSortType) {
        case this.SearchResponsesSortTypes.RELEVANCY:
          return this.$t('search-page_responses_sort-type_relevancy')
        case this.SearchResponsesSortTypes.DATE:
          return this.$t('search-page_responses_sort-type_date')
        default:
          return null
      }
    }
  },
  watch: {
    localSortType: {
      handler() {
        this.$emit('update:sortType', this.localSortType)
        this.$emit('update')
      }
    }
  },
  methods: {
    updateDemographics(value) {
      this.$emit('update:withDemographics', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesHeaderActions {
  @apply flex justify-between items-center;
  &__aside {
    @apply flex items-center;
  }
  &__results-num {
    @apply text-neutral-01-400 text-subheading-01;
  }
  &__demographic-toggle {
    @apply ml-7;
  }
  &__sort-by-wrapper {
  }
  .SortBy {
    @apply flex text-subheading-01 items-center;
    &__description {
      @apply text-neutral-01-400 mr-4;
    }
    &__label {
    }
    &__icon {
    }
    &__chevron {
      @apply text-primary-01-400;
    }
  }
}
</style>
