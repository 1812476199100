import { getLocation } from '@/utils/stringUtils'

export default {
  getLocation(participant) {
    try {
      const { country, region, city } = participant
      return getLocation({ country, region, city })
    } catch {
      return ''
    }
  },
  getPossibleBirthYears() {},
  getHHILabel(from, to) {
    const formatVal = (value) => (value < 1000 ? `$${value}` : `$${value / 1000}k`)
    const newFrom = from !== undefined ? (!to ? `${formatVal(from)}+` : formatVal(from)) : undefined
    const newTo = to ? formatVal(to) : undefined
    return [newFrom, newTo].filter((v) => v).join(' - ')
  }
}
