<template>
  <div class="StudyDates flex items-center">
    <div class="text-neutral-01 text-sm font-semibold leading-4" data-e2e="studyDates">
      {{ $t('conversation_date') }}: {{ dates }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'StudyDates',
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    dates() {
      let startDate = moment.unix(this.study.startDate / 1000)
      if (this.study.endDate) {
        let endDate = moment.unix(this.study.endDate / 1000)
        return startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY')
      } else {
        return startDate.format('MM/DD/YYYY')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.StudyDates {
}
</style>
