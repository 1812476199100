<template>
  <div class="ForumChatSmallDetails">
    <div v-if="chat.image" class="ForumChatSmallDetails__image-container">
      <ImageLoader :file="chat.image" small class="ForumChatSmallDetails__image"></ImageLoader>
    </div>
    <div class="ForumChatSmallDetails__body">
      <h3 class="ForumChatSmallDetails__title">
        {{ chat.name }}
      </h3>
      <div class="ForumChatSmallDetails__meta">
        <div class="ForumChatSmallDetails__meta-value">
          <LLMetaIconInfo
            :text="$tc('common_amount_no_digits_members', chat.meta.memberCount)"
            :show-text="tailwindScreens.sm"
            :value="chat.meta.memberCount"
          >
            <ParticipantsIcon />
          </LLMetaIconInfo>
        </div>
        <div class="ForumChatSmallDetails__meta-value">
          <LLMetaIconInfo
            :text="$tc('common_amount_no_digits_threads', chat.meta.threadCount)"
            :show-text="tailwindScreens.sm"
            :value="chat.meta.threadCount"
          >
            <TopicRedIcon />
          </LLMetaIconInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
import { ForumChatModel } from '@/models/forum/forumChat'
import LLMetaIconInfo from '@/components/atoms/LLMetaIconInfo'
import { screenCatcher } from '@/mixins/screenCatcher'
import ParticipantsIcon from '@/assets/icons/ParticipantsIcon.vue'
import TopicRedIcon from '@/assets/icons/TopicRedIcon.vue'
export default {
  name: 'ForumChatSmallDetails',
  components: { TopicRedIcon, ParticipantsIcon, LLMetaIconInfo, ImageLoader },
  mixins: [screenCatcher],
  props: {
    chat: { type: ForumChatModel, default: null },
    compact: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      const classes = []
      const rootClass = 'ForumChatSmallDetails'
      if (this.compact) {
        classes.push(`${rootClass}_compact`)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatSmallDetails {
  @apply border-neutral-01-50 border p-4 flex min-w-0 items-center;
  $root: &;
  &__image-container {
    @apply flex-shrink-0 mr-4;
  }
  &__image {
    width: 60px;
    height: 60px;
    @apply object-cover rounded-full;
  }
  &__title {
    @apply truncate;
  }
  &__body {
    @apply truncate flex flex-col justify-between;
  }
  &__meta {
    @apply flex;
    &-value {
      @apply ml-4;
      &:first-child {
        @apply ml-0;
      }
    }
  }
  &_compact {
    #{$root}__meta {
      @apply flex-wrap;
      &-value {
        @apply ml-0 mr-4;
      }
    }
  }
}
</style>
