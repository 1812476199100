<template>
  <div class="ForumChatThreadCardBody" :class="classList">
    <ForumChatThreadCardContent :thread="thread" :has-link="hasLink" :compact="compact" :mobile="mobile" />
    <ForumThreadReactions
      class="ForumChatThreadCardBody__reactions"
      :thread="thread"
      :reaction-emoji-list="reactionEmojiList"
      :is-reaction-animations-enabled="isReactionAnimationsEnabled"
      :disabled="!isReactionsEnabled"
      @updateReactions="updateReactions"
      @updateReactionEmojiList="onUpdateReactionEmojiList"
    ></ForumThreadReactions>
    <div v-if="thread.poll && !hasLink && !compact" class="ForumChatThreadCardBody__poll">
      <slot name="poll"></slot>
    </div>
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatThreadCardContent from '@/components/modules/forum-chat/new-thread-card/body/ForumChatThreadCardContent.vue'
import ForumThreadReactions from '@/components/pages/forum/thread/ForumThreadReactions.vue'
import { ForumThreadState } from '@/common/enums'
import { ForumReactionModel } from '@/models/forum/forumReaction'

export default {
  name: 'ForumChatThreadCardBody',
  components: { ForumThreadReactions, ForumChatThreadCardContent },
  props: {
    thread: { type: ForumThreadModel, required: true },
    hasLink: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    isReactionAnimationsEnabled: { type: Boolean, default: true },
    reactionEmojiList: { type: Array, required: true }
  },
  data() {
    return {
      localThread: null
    }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    },
    isReactionsEnabled() {
      return this.thread?.state === ForumThreadState.ACTIVE
    }
  },
  watch: {
    thread: {
      handler() {
        if (!this.$_.isEqual(this.thread, this.localThread)) {
          this.localThread = new ForumThreadModel(this.thread)
        }
      },
      deep: true,
      immediate: true
    },
    localThread: {
      handler() {
        this.$emit('update', { thread: new ForumThreadModel(this.localThread) })
      },
      deep: true
    }
  },
  methods: {
    updateReactions({ reactions }) {
      console.log('reactions', reactions)
      this.$emit('update', {
        thread: new ForumThreadModel({
          ...this.localThread,
          reactions: reactions.map((reaction) => new ForumReactionModel(reaction))
        })
      })
    },
    onUpdateReactionEmojiList(reactionEmojiList) {
      this.$emit('updateReactionEmojiList', reactionEmojiList)
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardBody {
  $root: &;
  @apply px-6 w-full;
  &_compact,
  &_mobile {
    @apply px-4;
  }
  &__reactions {
    @apply mt-4;
    @at-root #{$root}_compact & {
      @apply mt-2;
    }
  }
  &__poll {
    @apply mt-4;
  }
}
</style>
