<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.531 0.240848C11.7593 0.288459 12.9407 0.820127 13.8254 1.70488C14.7102 2.58963 15.2418 3.77096 15.2895 4.99928C15.3372 6.23043 14.8932 7.41119 14.036 8.26843L7.32247 14.9819C6.99692 15.3075 6.60639 15.5593 6.17493 15.7239C5.74368 15.8885 5.28186 15.9621 4.81688 15.9441C4.35208 15.926 3.89272 15.8169 3.46407 15.6258C3.03548 15.4347 2.64408 15.1646 2.31097 14.8315C1.64001 14.1606 1.23479 13.2629 1.19846 12.3256C1.16202 11.3855 1.50117 10.4794 2.16057 9.82005L7.38219 4.59843C7.84376 4.13686 8.47519 3.90251 9.12421 3.92767C9.77039 3.95272 10.3845 4.23149 10.8416 4.68866C11.2988 5.14583 11.5776 5.75991 11.6026 6.40609C11.6278 7.05511 11.3934 7.68654 10.9319 8.14811L7.9481 11.1319C7.63568 11.4443 7.12914 11.4443 6.81672 11.1319C6.5043 10.8195 6.50431 10.3129 6.81672 10.0005L9.80051 7.01674C9.93245 6.88479 10.0125 6.69223 10.0038 6.46806C9.99504 6.24104 9.89448 6.00424 9.71027 5.82003C9.52606 5.63583 9.28926 5.53527 9.06224 5.52647C8.83807 5.51778 8.64551 5.59785 8.51356 5.7298L3.29194 10.9514C2.96216 11.2812 2.77729 11.7484 2.79726 12.2637C2.81734 12.7817 3.04434 13.3022 3.44234 13.7002C3.63853 13.8964 3.86772 14.0539 4.11557 14.1644C4.36336 14.2749 4.62305 14.3353 4.87885 14.3453C5.13449 14.3552 5.38071 14.3145 5.60452 14.2291C5.82813 14.1437 6.02685 14.0148 6.1911 13.8506L12.9046 7.13706C13.4322 6.60944 13.7219 5.86755 13.6907 5.06125C13.6593 4.2521 13.3058 3.44804 12.6941 2.83625C12.0823 2.22446 11.2782 1.87101 10.4691 1.83965C9.66275 1.80839 8.92087 2.09808 8.39325 2.6257L3.91757 7.10137C3.60515 7.41379 3.09862 7.41379 2.7862 7.10137C2.47378 6.78895 2.47378 6.28242 2.7862 5.97L7.26188 1.49433C8.11912 0.637084 9.29987 0.193128 10.531 0.240848Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AttachmentIcon'
}
</script>
