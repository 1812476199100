<template>
  <div class="ErrorPage">
    <div class="ErrorPage__content">
      <div class="ErrorPage__logo-wrapper">
        <LogoBigIcon class="ErrorPage__logo" />
      </div>
      <div class="ErrorPage__error-info">
        <span class="ErrorPage__status"> 404 </span>
        <span class="ErrorPage__message">
          {{ $t('error-page.not-found-message') }}
        </span>
      </div>
      <router-link class="ErrorPage__home-link" tag="a" to="/">{{ $t('error-page.home-link') }}</router-link>
    </div>
  </div>
</template>

<script>
import LogoBigIcon from '@/assets/icons/LogoBigIcon'
export default {
  name: 'ErrorPage',
  components: { LogoBigIcon },
  layout: 'empty',
  props: {
    error: { type: Object, default: null }
  }
}
</script>

<style lang="scss" scoped>
.ErrorPage {
  @apply w-full h-full absolute overflow-y-auto;
  &__content {
    @apply flex flex-col items-center justify-center py-10 px-4;
    min-height: 100%;
  }
  &__logo {
    @apply w-full;
  }
  &__error-info {
    @apply mt-20 flex flex-col items-center;
  }
  &__status {
    font-size: 80px;
    @apply text-accent-01 mb-5 font-bold;
  }
  &__message {
    font-size: 24px;
    @apply text-center;
  }
  &__home-link {
    @apply text-accent-01 mt-20;
    &:hover {
      @apply text-accent-01-200;
    }
    &:focus-visible {
      @apply text-accent-01-200 outline-none;
    }
  }
}
</style>
