<template>
  <div class="bread-crumbs bg-neutral-01-25">
    <div class="container bread-crumbs__container" data-e2e="crumbLink">
      <div v-for="(crumb, index) in crumbs" :key="index" class="bread-crumbs__item flex items-center">
        <router-link
          v-if="crumb.path && !crumb.inactive"
          :to="crumb.path"
          class="bread-crumbs__item-text link"
        >
          {{ crumb.name }}
        </router-link>
        <span v-else-if="crumb.inactive" class="bread-crumbs__item-text">{{ crumb.name }}</span>
        <span v-else class="bread-crumbs__item-text bread-crumbs__current">{{ crumb.name }}</span>
        <span
          v-if="index !== crumbs.length - 1"
          class="bread-crumbs__item-text mx-2 px-1 transform scale-125"
        >
          »
        </span>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLBreadCrumbs',
  props: {
    crumbs: {
      type: Array,
      required: true,
      validator(val) {
        const pathlessCrumbIndex = val.findIndex((crumb) => !crumb.path)
        if (pathlessCrumbIndex !== -1 && pathlessCrumbIndex !== val.length - 1) {
          throw new Error('Only the last crumb can be without "path" prop')
        }

        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bread-crumbs {
  min-height: 22px;
  &__item {
    &-text {
      @apply truncate text-caption-01;
      max-width: 250px;
      &.link {
        @apply underline text-primary-01-400;
      }
    }
  }
  &__container {
    @apply flex items-center text-neutral-01 h-full flex-wrap;
  }

  &__current {
    @apply text-accent-01;
  }
}
</style>
