<template>
  <div class="LLCheckboxList" :class="classes">
    <LLFilteringSearch
      v-if="!hideSearch"
      v-model="searchValue"
      class="LLCheckboxList__search"
      :placeholder="_placeholder"
    />
    <div class="LLCheckboxList__list" :style="listStyle">
      <LLCheckboxListCheckboxes
        v-for="checkboxValue in valuesFilteredBySearch"
        :key="keyFn(checkboxValue)"
        v-model="currentValue"
        :key-fn="keyFn"
        :checkbox-value="checkboxValue"
        :label="label"
        :disabled="disabled"
        :disabled-value="disabledValue"
        :indeterminate-value="indeterminateValue"
        :selected="currentValue"
        :reduce="reduce"
        :children="children"
        @change="onChange"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </LLCheckboxListCheckboxes>
      <InfiniteScrollObserver
        v-if="withObserver"
        root-margin="10px"
        @intersect="$emit('intersect')"
      ></InfiniteScrollObserver>
    </div>
  </div>
</template>

<script>
import InfiniteScrollObserver from '@/components/utils/InfiniteScrollObserver'
import LLCheckboxListCheckboxes from '@/components/common/LLCheckboxList/LLCheckboxListCheckboxes'
import LLFilteringSearch from '@/components/common/LLFilteringSearch'

export default {
  name: 'LLCheckboxList',
  components: { LLFilteringSearch, LLCheckboxListCheckboxes, InfiniteScrollObserver },
  props: {
    keyFn: { type: Function, default: (item) => item.key },
    reduce: { type: Function, default: (item) => item },
    label: { type: Function, default: (item) => item },
    children: { type: [Function, Boolean, null], default: null },
    disabledValue: { type: [Function, null], default: () => false },
    indeterminateValue: { type: [Function, null], default: () => false },
    selected: { type: Array, default: () => [] },
    values: { type: Array, default: () => [] },
    searchPlaceholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    searchFn: { type: Function, default: null },
    noScroll: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    maxListHeight: { type: String, default: null },
    withObserver: { type: Boolean, default: false },
    clearValuesOnUpdate: { type: Boolean, default: true }
  },
  data() {
    return {
      currentValue: [],
      searchValue: ''
    }
  },
  computed: {
    listStyle() {
      return {
        maxHeight: this.maxListHeight || undefined
      }
    },
    _placeholder() {
      return this.searchPlaceholder || this.$t('search-bar_placeholder')
    },
    classes() {
      const classes = []
      const rootClass = 'LLCheckboxList'
      if (this.noScroll) {
        classes.push(`${rootClass}_no-scroll`)
      }
      return classes
    },
    // TODO: make search for children
    valuesFilteredBySearch() {
      if (!this.searchValue) {
        return this.values
      } else if (!this.searchFn) {
        return this.values.filter((value) => {
          return this.label(value).toUpperCase().includes(this.searchValue.toUpperCase())
        })
      } else {
        return this.values.filter((value) => this.searchFn(value, this.searchValue))
      }
    }
  },
  watch: {
    searchValue: {
      handler() {
        this.$emit('searchValueChange', this.searchValue)
      }
    },
    selected: {
      immediate: true,
      handler() {
        if (!_.isEqual(this.currentValue, this.selected)) {
          this.currentValue = [...this.selected]
        }
      }
    },
    values: {
      handler() {
        if (this.clearValuesOnUpdate) {
          this.currentValue = this.currentValue.filter((selectedItem) =>
            this.values.find((value) => this.reduce(value) === selectedItem)
          )
        }
        if (!_.isEqual(this.selected, this.currentValue)) {
          this.$emit('update:selected', [...this.currentValue])
          this.$emit('clearingUpdate', [...this.currentValue])
        }
      }
    }
  },
  methods: {
    onChange(payload) {
      this.currentValue = [...payload]
      this.$emit('update:selected', [...this.currentValue])
      this.$emit('checkboxChange', [...this.currentValue])
    }
  }
}
</script>

<style lang="scss" scoped>
.LLCheckboxList {
  $root: &;
  &__list {
    max-height: 335px;
    overflow-y: auto;
  }

  &_no-scroll {
    #{$root}__list {
      max-height: none;
      overflow-y: visible;
    }
  }

  &__search {
    @apply mb-3;
  }
}
</style>
