<template>
  <svg class="icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="fill-current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7323 16.0001L12.4395 11.7072L13.8537 10.293L19.5608 16.0001L13.8537 21.7072L12.4395 20.293L16.7323 16.0001Z"
    />
    <circle class="stroke-current" cx="16" cy="16" r="15.5" />
  </svg>
</template>

<script>
export default {
  name: 'CircleArrowRightIcon'
}
</script>
