<template>
  <LLWordTextContainer class="PrivacyPolicyES">
    <p class="c43">
      <span class="c12"
        >Proteger su datos es nuestra prioridad. Esta Pol&iacute;tica de Privacidad corresponde a las
        p&aacute;ginas de internet, aplicaciones y servicios controlados por Spark Ideas, LLC (denominados
        como &quot;Spark&quot;, &quot;nosotros&quot; o &quot;nuestro&quot;), incluyendo </span
      ><span class="c12 c31"
        ><a
          class="c1"
          href="https://www.google.com/url?q=http://www.spark-nyc.com&amp;sa=D&amp;source=editors&amp;ust=1666017689768382&amp;usg=AOvVaw3jZwmgXjEysVfoMTW2p-hc"
          >spark-nyc.com</a
        ></span
      ><span class="c12 c36">&nbsp;</span><span class="c12">y</span><span class="c12 c36">&nbsp;</span
      ><span class="c9 c31"
        ><a
          class="c1"
          href="https://www.google.com/url?q=http://looklook.app&amp;sa=D&amp;source=editors&amp;ust=1666017689768795&amp;usg=AOvVaw1-OmSo7ldz6w98Ysk9oE8T"
          >looklook.app</a
        ></span
      ><span class="c12 c36">&nbsp;</span
      ><span class="c12 c40"
        >(conjuntamente denominados como los &quot;Sitios&quot;) y la aplicaci&oacute;n m&oacute;vil LookLook
        (denominada como la &quot;Aplicaci&oacute;n M&oacute;vil&quot;). Por favor lea atentamente esta
        Pol&iacute;tica de Privacidad porque al usar los Sitios o la Aplicaci&oacute;n M&oacute;vil, usted
        est&aacute; de acuerdo con las pr&aacute;cticas de datos descritas en esta pol&iacute;tica.</span
      >
    </p>
    <p class="c0"><span class="c11"></span></p>
    <p class="c6 c53">
      <span class="c9"
        >Su uso de los Sitios o de la Aplicaci&oacute;n M&oacute;vil tambi&eacute;n est&aacute; regido por los
        T&eacute;rminos de Servicio que puede encontrar aqu&iacute;.</span
      >
    </p>
    <p class="c0"><span class="c11"></span></p>
    <p class="c0"><span class="c11"></span></p>
    <p class="c6 c10">
      <span class="c3"
        >POR FAVOR TENGA EN CUENTA LA PROVISI&Oacute;N DE ARBITRAJE ESTABLECIDA M&Aacute;S ADELANTE, LA CUAL,
        MIENTRAS LA LEY NO LO PROH&Iacute;BA, REQUIERE QUE USTED ARBITRE CUALQUIER RECLAMACI&Oacute;N QUE
        PUEDA TENER CONTRA SPARK EN UNA BASE INDIVIDUAL. ARBITRAJE EN UNA BASE INDIVIDUAL SIGNIFICA QUE USTED
        NO TENDR&Aacute;, Y RENUNCIA, AL DERECHO DE QUE UN JUEZ O JURADO FALLE SOBRE SU RECLAMACI&Oacute;N, Y
        QUE USTED NO PUEDE PROCEDER DE MANERA COLECTIVA, CONSOLIDADA O CON REPRESENTACI&Oacute;N.</span
      >
    </p>
    <p class="c0"><span class="c11"></span></p>
    <p class="c15 c6"><span class="c13">Captura y uso de sus datos personales</span></p>
    <p class="c0"><span class="c11"></span></p>
    <p class="c6 c51">
      <span class="c3"
        >Los &quot;datos personales&quot; consisten en informaci&oacute;n relacionada con una persona natural
        identificada o identificable. Nosotros captamos los siguientes datos:</span
      >
    </p>
    <p class="c0"><span class="c11"></span></p>
    <a id="t.886aab5030f79db8331abae98620eac60f8cff28"></a><a id="t.0"></a>
    <table class="c30">
      <tr class="c24">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Datos demogr&aacute;ficos</span></p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Utilizamos estos datos para</span></p>
        </td>
      </tr>
      <tr class="c32">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Nombre<br />N&uacute;mero de tel&eacute;fono<br />Ciudad, estado y pa&iacute;s<br />Direcci&oacute;n
              de correo electr&oacute;nico<br />Puesto de trabajo<br />Empresa<br />Formaci&oacute;n
              <br />Nivel de ingresos<br />Estado civil</span
            >
          </p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Realizar estudios<br />Comunicarse con clientes y prospectos (correo electr&oacute;nico,
              formularios de contacto, WeChat)<br />Permitir a los clientes utilizar nuestra plataforma de
              investigaci&oacute;n <br />Resolver problemas<br />Ofrecer asistencia al cliente<br />
              <br />
              <br />Base legal: consentimiento, contrato, inter&eacute;s leg&iacute;timo</span
            >
          </p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Preferencias</span></p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c55">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Aficiones<br />Intereses<br />H&aacute;bitos de compra<br />Preferencia de marca<br />Historial
              de compras<br />Otros temas relevantes para el estudio</span
            >
          </p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3">Realizar estudios<br /><br />Base legal: consentimiento</span>
          </p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Ficheros</span></p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c54">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3">Fotograf&iacute;as<br />V&iacute;deos <br />Voz</span>
          </p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3">Realizar estudios<br /><br />Base legal: consentimiento</span>
          </p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Comunicaci&oacute;n</span></p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Entrevistas con los participantes<br />Consultas generales (correo electr&oacute;nico o
              contacto para solicitudes de clientes)<br />Atenci&oacute;n al cliente</span
            >
          </p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Realizar estudios<br />Comunicarse con los clientes y prospectos (correo electr&oacute;nico,
              formularios de contacto, WeChat)<br />Ofrecer asistencia al cliente<br />Distribuir
              recompensas<br /><br />Base legal: consentimiento, inter&eacute;s leg&iacute;timo</span
            >
          </p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15"><span class="c13">Tecnolog&iacute;a</span></p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Geolocalizaci&oacute;n de la ciudad<br />Direcci&oacute;n IP<br />Datos del dispositivo y del
              navegador</span
            >
          </p>
        </td>
        <td class="c2" colspan="1" rowspan="1">
          <p class="c15">
            <span class="c3"
              >Realizar estudios<br />Permitir a los clientes utilizar nuestra plataforma de
              investigaci&oacute;n <br />Mejorar nuestros productos y servicios <br />Resolver problemas<br />Asegurar
              la plataforma<br /><br />Base legal: consentimiento, inter&eacute;s leg&iacute;timo</span
            >
          </p>
        </td>
      </tr>
    </table>

    <p class="c0"><span class="c14"></span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Cookies y otras tecnolog&iacute;as similares</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c26 c45">
      <span class="c3"
        >Usamos cookies necesarias y an&oacute;nimas y otras tecnolog&iacute;as similares en nuestros sitios y
        en la Aplicaci&oacute;n M&oacute;vil para ayudar a recolectar informaci&oacute;n. Podemos usar cookies
        de sesi&oacute;n o cookies persistentes. Las cookies de sesi&oacute;n solo permanecen durante el
        tiempo espec&iacute;fico de su visita y se eliminan al cerrar su navegador. Las cookies persistentes
        permanecen en el disco duro de su dispositivo hasta que usted las elimina o estas expiran. Usamos los
        siguientes tipos de cookies:</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <ul class="c16 lst-kix_list_10-0 start">
      <li class="c20 c44 li-bullet-0">
        <span class="c9 c39">Esencial</span
        ><span class="c9"
          >. Algunas cookies son esenciales para permitir su navegaci&oacute;n por nuestros Sitios y/o la
          Aplicaci&oacute;n M&oacute;vil y para que haga uso de sus caracter&iacute;sticas tales como ingresar
          a &aacute;reas seguras de los Sitios y/o de la Aplicaci&oacute;n M&oacute;vil. Sin estas cookies no
          podemos habilitar el contenido adecuado seg&uacute;n el tipo de dispositivo que est&eacute;
          usando.</span
        >
      </li>
    </ul>
    <p class="c0"><span class="c4"></span></p>
    <p class="c0"><span class="c4"></span></p>
    <ul class="c16 lst-kix_list_10-0">
      <li class="c20 c27 li-bullet-0">
        <span class="c9 c39">Desempe&ntilde;o y an&aacute;lisis</span
        ><span class="c9"
          >&nbsp;Usamos cookies para ver c&oacute;mo usa usted nuestros Sitios y/o Aplicaci&oacute;n
          M&oacute;vil a fin de mejorar el desempe&ntilde;o y desarrollarlos seg&uacute;n sus preferencias. No
          se recoge ninguna informaci&oacute;n personal a trav&eacute;s de las cookies.</span
        >
      </li>
    </ul>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6">
      <span class="c13">Transmisi&oacute;n y divulgaci&oacute;n de sus datos personales</span>
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6">
      <span class="c3"
        >Nosotros compartimos o divulgamos su informaci&oacute;n personal en los siguientes casos y a las
        siguientes partes:</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <ul class="c16 lst-kix_list_12-0 start">
      <li class="c5 c20 li-bullet-0">
        <span class="c3"
          >Compartimos informaci&oacute;n provista a trav&eacute;s de los Sitios y/o la Aplicaci&oacute;n
          m&oacute;vil con nuestros clientes para sus estudios de marketing e investigaci&oacute;n y fines
          comerciales.</span
        >
      </li>
      <li class="c5 c20 li-bullet-0">
        <span class="c9"
          >Compartimos informaci&oacute;n provista a trav&eacute;s de los Sitios y/o la Aplicaci&oacute;n
          m&oacute;vil con proveedores de servicios externos para administrar estudios y proporcionar reportes
          a los clientes. La lista actualizada de nuestros proveedores de servicios externos puede consultarse </span
        ><span class="c9 c28">aqu&iacute;.</span>
      </li>
      <li class="c5 c20 li-bullet-0">
        <span class="c3"
          >Podemos compartir o transferir su informaci&oacute;n durante cualquier proceso de
          reorganizaci&oacute;n directa o indirecta, incluyendo, de forma enunciativa y no limitativa,
          fusiones, adquisiciones, enajenaciones, quiebras y ventas de todos o parte de nuestros activos.
          Consulte la secci&oacute;n Cesi&oacute;n que se encuentra a continuaci&oacute;n para obtener
          m&aacute;s informaci&oacute;n.
        </span>
      </li>
      <li class="c5 c20 li-bullet-0">
        <span class="c9"
          >Divulgaremos su informaci&oacute;n personal para cumplir con leyes aplicables o en buena fe
          creyendo que dichas acciones son necesarias para cumplir con exigencias de ley o para cumplir con
          procesos legales que nos sean ordenados, para proteger y defender nuestros derechos o propiedad, o
          para actuar bajo circunstancias de urgencia en protecci&oacute;n de la seguridad personal de
          nuestros usuarios.</span
        >
      </li>
    </ul>
    <p class="c5 c29"><span class="c3"></span></p>
    <p class="c5">
      <span class="c3"
        >La venta, dentro de la definici&oacute;n de las leyes de privacidad del consumidor aplicables, se
        define como la venta, el alquiler, la liberaci&oacute;n, la divulgaci&oacute;n, la difusi&oacute;n, la
        puesta a disposici&oacute;n, la transferencia, o la comunicaci&oacute;n oral, por escrito o por medios
        electr&oacute;nicos o de otro tipo, de la informaci&oacute;n personal de un consumidor por parte de la
        empresa a otra empresa o a un tercero a cambio de una contraprestaci&oacute;n monetaria y, a veces, de
        otra contraprestaci&oacute;n valiosa.</span
      >
    </p>
    <p class="c5 c29"><span class="c3"></span></p>
    <p class="c5">
      <span class="c3"
        >No vendemos informaci&oacute;n personal, tal y como la definen las leyes de privacidad del estado de
        EE.UU., ni tampoco vender&iacute;amos nunca su informaci&oacute;n personal a ning&uacute;n tercero en
        ninguna parte del mundo.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Captura y uso de sus datos de menores</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c38">
      <span class="c3"
        >Nosotros no recolectamos intencionalmente informaci&oacute;n de menores de 13 a&ntilde;os en los
        Estados Unidos o menores de 16 en la UE. En caso de que sepamos que una persona menor de 13
        a&ntilde;os en los Estados Unidos o menor de 16 en la UE nos ha provisto informaci&oacute;n personal,
        eliminaremos dicha informaci&oacute;n personal.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Ingreso y opci&oacute;n de renuncia</span></p>
    <a id="id.30j0zll"></a>
    <p class="c0 c6"><span class="c3"></span></p>
    <p class="c6 c41">
      <span class="c3"
        >Si sus datos han cambiado y desea actualizar la informaci&oacute;n que tenemos en nuestros archivos,
        por favor cont&aacute;ctenos usando la siguiente informaci&oacute;n de contacto.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c26 c35">
      <span class="c3"
        >Si ya no desea recibir comunicaciones de mercadeo de nuestra parte, puede elegir su retiro siguiendo
        las instrucciones de cancelaci&oacute;n de suscripci&oacute;n provista en nuestras comunicaciones de
        mercadeo o contact&aacute;ndonos en la direcci&oacute;n a continuaci&oacute;n. Procesaremos su
        cancelaci&oacute;n de suscripci&oacute;n lo m&aacute;s pronto posible y en cumplimiento con las leyes
        aplicables, pero por favor tenga en cuenta que en ciertas circunstancias puede recibir algunos
        mensajes adicionales hasta que se haya realizado y completado el proceso.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c34">
      <span class="c3"
        >Por favor tenga presente que, aunque elija renunciar a nuestras comunicaciones de mercadeo,
        seguiremos envi&aacute;ndole mensajes administrativos respecto a sus &oacute;rdenes y los Sitios y/o
        Aplicaci&oacute;n M&oacute;vil, incluyendo, por ejemplo, actualizaciones importantes respecto a los
        Sitios y/o Aplicaci&oacute;n M&oacute;vil.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15"><span class="c13">Sus derechos</span></p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c3"
        >Si es usted residente en el EEE, el Reino Unido o Suiza, tiene derecho a solicitar</span
      >
    </p>
    <p class="c0"><span class="c3"></span></p>
    <ul class="c16 lst-kix_list_14-0 start">
      <li class="c15 c20 li-bullet-0"><span class="c3">saber si se procesan sus datos personales </span></li>
      <li class="c15 c20 li-bullet-0"><span class="c3">acceder a su informaci&oacute;n personal</span></li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">que se edite o env&iacute;e una copia de su informaci&oacute;n personal</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">la supresi&oacute;n o anonimizaci&oacute;n de su informaci&oacute;n personal</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">que se restrinja el uso de su informaci&oacute;n personal</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3"
          >que dimensionemos la comunicaci&oacute;n del estudio con usted o que nos opongamos a la
          participaci&oacute;n en el mismo
        </span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3"
          >Ser informado si utilizamos herramientas de toma de decisiones autom&aacute;ticas y
          elaboraci&oacute;n de perfiles
        </span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3"
          >Presentar una reclamaci&oacute;n ante la autoridad de control de protecci&oacute;n de datos
          correspondiente</span
        >
      </li>
    </ul>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15"><span class="c3">Lista de autoridades de protecci&oacute;n de datos de la UE:</span></p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c9 c31"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1666017689776965&amp;usg=AOvVaw2uGaA2RY9haUh_fv-MsR2c"
          >EEE</a
        ></span
      ><span class="c3">&nbsp;</span>
    </p>
    <p class="c15">
      <span class="c9 c31"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1666017689777354&amp;usg=AOvVaw0Lprb0JaVgc8zAVaW5wt1K"
          >Suiza</a
        ></span
      >
    </p>
    <p class="c15">
      <span class="c9 c31"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1666017689777589&amp;usg=AOvVaw3xBBy-jUv95MxZC3Tx7byk"
          >Reino Unido</a
        ></span
      >
    </p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c3"
        >Si se encuentra en California, Connecticut, Utah, Colorado, Virginia u otro estado de EE.UU. con
        legislaci&oacute;n sobre privacidad, tiene derecho a solicitar</span
      >
    </p>
    <p class="c0"><span class="c3"></span></p>
    <ul class="c16 lst-kix_list_15-0 start">
      <li class="c15 c20 li-bullet-0"><span class="c3">acceso a su informaci&oacute;n personal</span></li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">rectificaci&oacute;n de su informaci&oacute;n personal</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">saber si hemos vendido su informaci&oacute;n y optar por no venderla</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3">la eliminaci&oacute;n de su informaci&oacute;n personal</span>
      </li>
      <li class="c15 c20 li-bullet-0">
        <span class="c3"
          >Derecho a recibir su informaci&oacute;n personal en un formato f&aacute;cilmente comprensible y, en
          la medida en que sea t&eacute;cnicamente posible, en un formato estructurado, de uso com&uacute;n y
          legible por m&aacute;quina</span
        >
      </li>
    </ul>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15"><span class="c3">Lista de autoridades de protecci&oacute;n de datos de EE. UU:</span></p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1666017689778394&amp;usg=AOvVaw2ZeLT40WO1MfcTmC2o-EMq"
          >California&nbsp;</a
        ></span
      >
    </p>
    <p class="c15">
      <span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1666017689778664&amp;usg=AOvVaw2jqARBFnB4k1Lafl-yXgep"
          >Connecticut</a
        ></span
      >
    </p>
    <p class="c15">
      <span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1666017689778878&amp;usg=AOvVaw3gdiu92ThDJQezcOHTHTuz"
          >Utah</a
        ></span
      >
    </p>
    <p class="c15">
      <span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1666017689779075&amp;usg=AOvVaw2YKVEFXaoXSg6scUvVR-cl"
          >Colorado</a
        ></span
      >
    </p>
    <p class="c15">
      <span class="c8"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1666017689779313&amp;usg=AOvVaw1xB2RPcPeOdI6IqdthSxiX"
          >Virginia</a
        ></span
      >
    </p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c3"
        >Si reside dentro de las fronteras de la Rep&uacute;blica Popular China, visite nuestra
        pol&iacute;tica de privacidad en chino.
      </span>
    </p>
    <p class="c0"><span class="c3"></span></p>
    <p class="c15">
      <span class="c3"
        >Si desea ejercer sus derechos, env&iacute;e su solicitud por correo electr&oacute;nico a
        privacy@spark-nyc.com.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Enlaces a terceros</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c42">
      <span class="c3"
        >Spark le anima a revisar las pol&iacute;ticas de privacidad de sitios de internet vinculados hacia y
        desde los Sitios y/o la Aplicaci&oacute;n M&oacute;vil para que pueda entender de qu&eacute; manera
        dichos sitios de internet capturan, usan y comparten su informaci&oacute;n. Spark no se hace
        responsable de las declaraciones de privacidad o de cualquier contenido de dichos sitios ajenos a los
        Sitios y la Aplicaci&oacute;n M&oacute;vil.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Seguridad de sus datos personales</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c46">
      <span class="c3"
        >Spark implementa medidas razonables para proteger su informaci&oacute;n personal de accesos usos o
        divulgaciones no autorizados. Dicho esto, por favor comprenda que ninguna transmisi&oacute;n de Datos
        por internet se puede garantizar como 100% segura. En consecuencia, no podemos asegurar o garantizar
        la seguridad de cualquier tipo de informaci&oacute;n que nos env&iacute;e y usted entiende que
        cualquier informaci&oacute;n que nos transfiera se hace bajo su propio riesgo. Si nos enteramos de
        alguna falla en un sistema de seguridad, quiz&aacute;s intentemos notificarle electr&oacute;nicamente
        para que pueda tomar las medidas adecuadas. Al usar los Sitios y/o la Aplicaci&oacute;n M&oacute;vil,
        o al darnos informaci&oacute;n personal, usted acepta que podemos comunicarnos con usted de manera
        electr&oacute;nica en referencia a problemas de seguridad, privacidad o administrativos relacionados
        con su uso de los Sitios y/o la Aplicaci&oacute;n M&oacute;vil. Es posible que publiquemos un aviso
        por medio de los Sitios y la Aplicaci&oacute;n M&oacute;vil si llegara a presentarse una falla en la
        seguridad. Bajo esas circunstancias tambi&eacute;n podemos enviarle un correo electr&oacute;nico a la
        direcci&oacute;n que nos ha proporcionado. Dependiendo de su lugar de residencia, es posible que tenga
        derechos legales de recibir por escrito avisos acerca de fallas en la seguridad.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Retenci&oacute;n de datos</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c37">
      <span class="c3"
        >Nosotros retenemos su informaci&oacute;n personal por el periodo necesario para cumplir con los
        prop&oacute;sitos estipulados en esta Pol&iacute;tica de Privacidad, a menos que el periodo de
        retenci&oacute;n ya no sea requerido o permitido por la ley.</span
      >
    </p>
    <a id="id.1fob9te"></a>
    <p class="c37 c6 c29"><span class="c3"></span></p>
    <p class="c6 c15"><span class="c13">Transferencias internacionales de datos</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c22">
      <span class="c3"
        >Si elige suministrarnos informaci&oacute;n, es posible que transfiramos dicha informaci&oacute;n a
        nuestras filiales y subsidiarias o a otros terceros en el exterior, y desde su pa&iacute;s o
        jurisdicci&oacute;n a otros pa&iacute;ses o jurisdicciones alrededor del mundo. Si est&aacute;
        visit&aacute;ndonos desde la UE u otras regiones con leyes que rigen la recolecci&oacute;n de datos y
        usos diferentes a las leyes de los Estados Unidos, por favor tenga presente que est&aacute;
        transfiriendo su informaci&oacute;n personal a los Estados Unidos y otras jurisdicciones que pueden no
        tener las mismas leyes de protecci&oacute;n de datos que su pa&iacute;s de residencia. Usted reconoce
        que entiende que al suministrar su informaci&oacute;n personal:</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <ul class="c16 lst-kix_list_7-0 start">
      <li class="c23 li-bullet-1">
        <span class="c9"
          >Sus datos personales se usar&aacute;n para los fines identificados anteriormente seg&uacute;n esta
          Pol&iacute;tica de Privacidad y el Acuerdo de Protecci&oacute;n de Datos firmado
        </span>
      </li>
    </ul>
    <p class="c0"><span class="c4"></span></p>
    <ul class="c16 lst-kix_list_7-0">
      <li class="c52 li-bullet-1">
        <span class="c9"
          >Sus datos personales pueden ser transferidos a los Estados Unidos y a otras jurisdicciones
          seg&uacute;n las leyes aplicables, seg&uacute;n lo indicado anteriormente. &nbsp;Puede consultar
          nuestra lista actual de subprocesadores y sus medidas complementarias para la transferencia de datos </span
        ><span class="c9 c28">aqu&iacute;</span><span class="c9">.</span>
      </li>
    </ul>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Asignaci&oacute;n</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15">
      <span class="c3"
        >En caso de que todos o parte de nuestros activos sean vendidos o adquiridos por otra parte, o en el
        caso de cualquier proceso de reorganizaci&oacute;n directa o indirecta, incluyendo, de forma
        enunciativa y no limitativa, fusiones, adquisiciones, enajenaciones, quiebras y ventas de todos o
        parte de nuestros activos, usted nos otorga el derecho de ceder la informaci&oacute;n personal
        recopilada a trav&eacute;s de los Sitios y/o de la Aplicaci&oacute;n m&oacute;vil despu&eacute;s de
        completar dicha transacci&oacute;n y/o durante el proceso de evaluaci&oacute;n con transferencia
        pendiente. Si se transfieren en un caso de ese tipo, su informaci&oacute;n seguir&aacute; estando
        sujeta a esta Pol&iacute;tica de privacidad o a una pol&iacute;tica de privacidad que, como
        m&iacute;nimo, proteja su privacidad en un nivel equivalente al de esta Pol&iacute;tica de
        privacidad.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Resoluci&oacute;n de disputas y acuerdo de arbitraje</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c22 c6 c26">
      <span class="c3"
        >Salvo en casos y en la medida de lo prohibido por las leyes, al usar los Sitios y/o la
        Aplicaci&oacute;n M&oacute;vil usted y Spark acuerdan que, en caso de cualquier controversia,
        reclamaci&oacute;n, acci&oacute;n o disputa que surja de o en relaci&oacute;n con su uso de los Sitios
        y/o la Aplicaci&oacute;n M&oacute;vil, o por el incumplimiento, obligaci&oacute;n,
        interpretaci&oacute;n o validez de esta Pol&iacute;tica de Privacidad o cualquier parte de ella
        (&quot;Disputa&quot;), ambas partes deber&aacute;n primeramente procurar en buena fe resolver dicha
        Disputa mediante notificaci&oacute;n por escrito a la otra parte describiendo los hechos y
        circunstancias de la Disputa y permitiendo que la parte receptora disponga de treinta (30) d&iacute;as
        para responder o para resolver la Disputa. Las notificaciones deben ser enviadas a:</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">Spark Ideas, LLC</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">1328 Ridge Road</span></p>
    <p class="c15 c6"><span class="c3">Syosset, New York 11791</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c48">
      <span class="c3"
        >Tanto usted como Spark aceptan que este procedimiento de soluci&oacute;n de disputas es una
        condici&oacute;n precedente que debe ser cumplida antes de iniciar cualquier litigio o presentar
        alguna reclamaci&oacute;n contra la otra parte. SI MEDIANTE EL PROCEDIMIENTO DE SOLUCI&Oacute;N DE
        DISPUTAS NO SE PUEDE DAR SOLUCI&Oacute;N A ALGUNA DISPUTA, USTED ACEPTA QUE LA &Uacute;NICA Y
        EXCLUSIVA JURISDICCI&Oacute;N PARA DECIDOR SOBRE DICHA DISPUTA SEA UN ARBITRAJE VINCULANTE EN UNA BASE
        INDIVIDUAL. ARBITRAJE EN UNA BASE INDIVIDUAL SIGNIFICA QUE USTED NO TENDR&Aacute;, Y RENUNCIA, AL
        DERECHO DE QUE UN JUEZ O JURADO FALLE SOBRE SU RECLAMACI&Oacute;N, Y QUE USTED NO PUEDE PROCEDER DE
        MANERA COLECTIVA, CONSOLIDADA O CON REPRESENTACI&Oacute;N. Los otros derechos que usted y nosotros
        podremos tener ante una corte no estar&aacute;n disponibles o ser&aacute;n m&aacute;s limitados en
        arbitraje, incluyendo derechos de descubrimiento y de apelaci&oacute;n. Todas las disputas se
        someter&aacute;n exclusivamente a JAMS (www.jamsadr.com) para un arbitraje vinculante bajo sus normas
        vigentes al momento, ante un &aacute;rbitro que se acordar&aacute; mutuamente por ambas partes.
        &nbsp;De realizarse alguna, el &aacute;rbitro llevar&aacute; a cabo las audiencias por teleconferencia
        o videoconferencia y no a trav&eacute;s de comparecencias personales, a menos que el &aacute;rbitro
        determine a pedido suyo o nuestro que corresponde una audiencia en persona. Todas las comparecencias
        en persona tendr&aacute;n lugar en un sitio razonablemente conveniente para ambas partes, con la
        debida consideraci&oacute;n de su posibilidad de viajar y otras circunstancias pertinentes. Si las
        partes no pueden ponerse de acuerdo sobre un sitio, esa decisi&oacute;n ser&aacute; tomada por JAMS o
        por el &aacute;rbitro.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c18">
      <span class="c3"
        >El &aacute;rbitro, y no cualquier tribunal o agencia federal, estatal o local, tendr&aacute; la
        autoridad exclusiva para resolver cualquier disputa que surja bajo o en relaci&oacute;n con la
        interpretaci&oacute;n, la aplicabilidad, la obligaci&oacute;n o la formaci&oacute;n de esta
        Pol&iacute;tica de Privacidad, incluyendo cualquier reclamo de que la totalidad o parte de esta
        Pol&iacute;tica de Privacidad sea anulada o anulable.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Elecci&oacute;n de ley</span></p>
    <a id="id.3znysh7"></a>
    <p class="c0 c6"><span class="c3"></span></p>
    <p class="c46 c6 c47">
      <span class="c3"
        >Esta Pol&iacute;tica de Privacidad se ha hecho y se interpretar&aacute; seg&uacute;n las leyes del
        estado de New York, sin dar efecto a ning&uacute;n conflicto de principios de ley. Las partes
        reconocen que esta Pol&iacute;tica de Privacidad evidencia un acuerdo que involucra comercio
        interestatal. A pesar de las disposiciones del par&aacute;grafo anterior con respecto a la ley
        sustantiva aplicable, cualquier arbitraje realizado con relaci&oacute;n a los t&eacute;rminos de esta
        Pol&iacute;tica de Privacidad ser&aacute; gobernado por la Ley Federal de arbitraje (9 U.S.C.
        &sect;&sect; 1-16).</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6">
      <span class="c13"
        >Sus derechos de privacidad en California y c&oacute;mo respondemos a las se&ntilde;ales de &quot;no
        rastreo&quot;.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c50">
      <span class="c3"
        >El c&oacute;digo Civil de California, en la secci&oacute;n 1798.83 permite que los visitantes de los
        Sitios y/o la Aplicaci&oacute;n M&oacute;vil, que sean residentes de california, soliciten cierta
        informaci&oacute;n con relaci&oacute;n a nuestra divulgaci&oacute;n de datos personales a terceros
        para sus prop&oacute;sitos de mercadeo directo. Nosotros no compartimos sus datos personales con
        terceros para fines de mercadeo directo.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c25">
      <span class="c3"
        >En este momento, nuestros Sitios no reconocen se&ntilde;ales automatizadas de navegadores con
        relaci&oacute;n a mecanismos de rastreo, las cuales pueden incluir instrucciones de &quot;No
        rastreo&quot;.</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Informaci&oacute;n de contacto</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c33">
      <span class="c3"
        >Spark recibe sus preguntas o comentarios en relaci&oacute;n con esta Pol&iacute;tica de Privacidad y
        nuestras pr&aacute;cticas de privacidad. Por favor cont&aacute;ctenos a:</span
      >
    </p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">Spark Ideas, LLC</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">1328 Ridge Road</span></p>
    <p class="c15 c6"><span class="c3">Syosset, New York 11791</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">United States of America</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">Correo electr&oacute;nico:</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c21 c9">privacy@spark-nyc.com</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">N&uacute;mero de tel&eacute;fono:</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c3">(917) 991-0477</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c15 c6"><span class="c13">Cambios a esta pol&iacute;tica de privacidad</span></p>
    <p class="c0"><span class="c14"></span></p>
    <p class="c6 c49">
      <span class="c3"
        >En ocasiones, Spark actualizar&aacute; esta Pol&iacute;tica de Privacidad para que refleje la
        retroalimentaci&oacute;n de la compa&ntilde;&iacute;a y de los clientes. Cuando lo hagamos,
        tambi&eacute;n revisaremos la fecha de entrada en vigor en la parte superior de esta Pol&iacute;tica
        de Privacidad. Si se hacen cambios sustanciales a la Pol&iacute;tica de Privacidad, le notificaremos
        poniendo un aviso notorio en los Sitios y en la Aplicaci&oacute;n M&oacute;vil. Le animamos a revisar
        con frecuencia esta Pol&iacute;tica de Privacidad para estar informado acerca de c&oacute;mo Spark
        est&aacute; manejando su informaci&oacute;n.</span
      >
    </p>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyES',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyES {
  .lst-kix_list_14-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_14-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_14-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_14-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_14-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  .lst-kix_list_14-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_14-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_5-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_6-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_13-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_15-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-5 > li:before {
    content: '\0025aa  ';
  }
  li.li-bullet-1:before {
    margin-left: -18.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18.1pt;
  }
  .lst-kix_list_10-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-1 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }
  .lst-kix_list_15-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }
  .lst-kix_list_9-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_15-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_15-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_11-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_13-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_3-5 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-6 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_13-0 {
    list-style-type: none;
  }
  .lst-kix_list_8-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }
  .lst-kix_list_11-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-7 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-7 {
    list-style-type: none;
  }
  .lst-kix_list_7-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_2-6 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-1 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '  ';
  }
  .lst-kix_list_7-3 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  .lst-kix_list_10-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  .lst-kix_list_13-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_13-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: '  ';
  }
  .lst-kix_list_15-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_15-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_10-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_10-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_15-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-2 {
    list-style-type: none;
  }
  .lst-kix_list_15-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_15-1 {
    list-style-type: none;
  }
  .lst-kix_list_15-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_15-0 {
    list-style-type: none;
  }
  .lst-kix_list_10-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_15-8 {
    list-style-type: none;
  }
  .lst-kix_list_4-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_15-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-6 {
    list-style-type: none;
  }
  .lst-kix_list_9-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_15-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_15-4 {
    list-style-type: none;
  }
  .lst-kix_list_15-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_12-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_12-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_11-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-0 > li:before {
    content: '\002022  ';
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-2 > li:before {
    content: '  ';
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  .lst-kix_list_12-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_14-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-2 {
    list-style-type: none;
  }
  .lst-kix_list_13-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_14-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-6 > li:before {
    content: '  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ul.lst-kix_list_14-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-7 {
    list-style-type: none;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\002022  ';
  }
  .lst-kix_list_12-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_14-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-8 > li:before {
    content: '  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '  ';
  }
  .lst-kix_list_13-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_12-8 > li:before {
    content: '\0025aa  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c17 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 174.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c2 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 304.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c52 {
    margin-left: 37pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 15pt;
  }
  .c23 {
    margin-left: 37pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 27pt;
  }
  .c8 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-style: normal;
  }
  .c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c27 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 5pt;
  }
  .c34 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 34pt;
  }
  .c50 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 18pt;
  }
  .c43 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 10pt;
  }
  .c44 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 8pt;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 1pt;
  }
  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 9pt;
  }
  .c41 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 11pt;
  }
  .c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    height: 10pt;
  }
  .c33 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 10pt;
  }
  .c53 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 17pt;
  }
  .c51 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 15pt;
  }
  .c38 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 4pt;
  }
  .c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 1pt;
  }
  .c49 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 9pt;
  }
  .c37 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 32pt;
  }
  .c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 8pt;
  }
  .c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c45 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c42 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c46 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c35 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c48 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c21 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-style: normal;
  }
  .c12 {
    vertical-align: baseline;
    font-weight: 400;
  }
  .c30 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c13 {
    vertical-align: baseline;
    font-weight: 700;
  }
  .c9 {
    vertical-align: baseline;
    font-weight: 400;
  }
  .c31 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c19 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 71.2pt 72pt 72pt 72pt;
  }
  .c39 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c40 {
    color: #000000;
    text-decoration: none;
    font-style: normal;
  }
  .c20 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c16 {
    padding: 0;
    margin: 0;
  }
  .c1 {
    color: inherit;
    text-decoration: inherit;
  }
  .c6 {
    margin-left: 1pt;
  }
  .c54 {
    height: 45pt;
  }
  .c7 {
    height: 105pt;
  }
  .c28 {
    background-color: #ffff00;
  }
  .c26 {
    margin-right: 7pt;
  }
  .c55 {
    height: 90pt;
  }
  .c32 {
    height: 135pt;
  }
  .c29 {
    height: 10pt;
  }
  .c36 {
    color: #0563c1;
  }
  .c24 {
    height: 15pt;
  }
  .c47 {
    margin-right: 4pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    padding-bottom: 4pt;

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
  }
  p {
    margin: 0;
    color: #000000;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
