<template>
  <div class="DashboardTabs">
    <button
      class="DashboardTabs__tab"
      :class="{ DashboardTabs__tab_selected: selectedTab === 'studies' }"
      @click="changeTab('studies')"
    >
      {{ $t('dashboard.tabs.conversations') }}
      <div v-if="false" class="DashboardTabs__amount">
        {{ studyAmount > 99 ? '99+' : studyAmount }}
      </div>
    </button>
    <button
      class="DashboardTabs__tab"
      :class="{ DashboardTabs__tab_selected: selectedTab === 'chats' }"
      @click="changeTab('chats')"
    >
      {{ $t('dashboard.tabs.chats') }}
      <div v-if="false" class="DashboardTabs__amount">
        {{ chatAmount > 99 ? '99+' : chatAmount }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DashboardTabs',
  props: {
    selectedTab: { type: String, default: 'studies' },
    studyAmount: { type: Number, default: 0 },
    chatAmount: { type: Number, default: 0 }
  },
  methods: {
    changeTab(value) {
      this.$emit('update:selectedTab', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardTabs {
  $root: &;
  @apply border-b border-neutral-01-25 flex;
  &__tab {
    @apply py-2 flex items-center text-neutral-01-400 ml-6 uppercase outline-none transition duration-200 relative;
    font-size: 16px;

    &:after {
      @apply absolute bottom-0 w-full h-0.5 -mb-px transition duration-200;
      content: '';
    }

    &:not(&_selected) {
      &:hover {
        &:after {
          @apply bg-neutral-01-400;
        }
      }
      &:focus-visible {
        &:after {
          @apply bg-neutral-01-400;
        }
      }
    }
    @apply font-semibold;
    &:first-child {
      @apply ml-0;
    }
    &_selected {
      @apply text-primary-01-300;
      #{$root}__amount {
        @apply bg-primary-01-300;
      }
      &:after {
        @apply bg-primary-01-300;
      }
    }
  }
  &__amount {
    @apply w-6 h-6 rounded-full bg-neutral-01-400 flex items-center justify-center text-caption-01 text-white ml-2 transition duration-200;
  }
}
</style>
