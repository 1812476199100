<template>
  <div class="ForumThreadPageAsideThreads">
    <div class="ForumThreadPageAsideThreads__header">
      <div class="ForumThreadPageAsideThreadsTitle">
        <TopicRedIcon class="ForumThreadPageAsideThreadsTitle__icon"></TopicRedIcon>
        <span class="ForumThreadPageAsideThreadsTitle__text">
          {{ $t('forum.chat_page.threads.title') }}
        </span>
      </div>
      <div class="ForumThreadPageAsideThreads__sort-selector-wrapper">
        <LLOptionsList :offset="[0, 0]">
          <template #trigger="{ opened }">
            <LLButton class="ForumThreadPageAsideThreads__sort-selector-button" tiny tertiary>
              <LLChevron :opened="opened">{{ currentSortTypeLabel }}</LLChevron>
            </LLButton>
          </template>
          <template #buttons>
            <LLOptionsListButton
              v-for="sortType in sortTypes"
              :key="sortType.value"
              v-popper-close
              small
              @click="selectSortType(sortType)"
            >
              {{ sortType.label }}
            </LLOptionsListButton>
          </template>
        </LLOptionsList>
      </div>
    </div>
    <div class="ForumThreadPageAsideThreadsList">
      <div v-if="isError" class="ForumThreadPageAsideThreadsList__error">
        <LLLoaderError @retry="fetchThreads"></LLLoaderError>
      </div>
      <div
        v-else-if="!threadList.length && !isFetching && !silentFetching"
        class="ForumThreadPageAsideThreadsList__empty"
      >
        <LLEmptyState>{{ $tc('common_amount_threads', 0) }}</LLEmptyState>
      </div>
      <div v-else class="ForumThreadPageAsideThreadsList__inner">
        <ForumChatThreadCard
          v-for="threadItem in threadList"
          :key="threadItem.id"
          class="ForumThreadPageAsideThreadsList__card"
          :has-link-button="false"
          compact
          :thread="threadItem"
        />
        <InfiniteScrollObserver
          v-if="!isFetching && !paginationFinished"
          @intersect="changePage(page + 1)"
        ></InfiniteScrollObserver>
      </div>
      <div v-if="isFetching && !silentFetching" class="ForumThreadPageAsideThreadsList__loading">
        <LLLoader></LLLoader>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TopicRedIcon from '@/assets/icons/TopicRedIcon.vue'
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLLoader from '@/components/common/LLLoader.vue'
import LLEmptyState from '@/components/common/ui-components/LLEmptyState.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'
import { ForumThreadState } from '@/common/enums'
import ForumChatThreadCard from '@/components/modules/forum-chat/ForumChatThreadCard.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
import LLChevron from '@/components/common/LLChevron.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
import chatEditor from '@/utils/modules/forum/chatEditor'
import InfiniteScrollObserver from '@/components/utils/InfiniteScrollObserver.vue'
const CancelToken = axios.CancelToken

export default {
  name: 'ForumThreadPageAsideThreads',
  components: {
    InfiniteScrollObserver,
    LLOptionsListButton,
    LLChevron,
    LLOptionsList,
    LLButton,
    ForumChatThreadCard,
    LLLoaderError,
    LLEmptyState,
    LLLoader,
    TopicRedIcon
  },
  props: {
    chat: { type: ForumChatModel, required: true },
    thread: { type: ForumThreadModel, default: null }
  },
  data() {
    return {
      threadList: [],
      isError: false,
      isFetching: false,
      cancelTokenSource: null,
      perPage: 4,
      page: 1,
      total: 0,
      sort: null,
      state: ForumThreadState.ACTIVE,
      silentFetching: false,
      paginationFinished: false
    }
  },
  computed: {
    currentSortTypeLabel() {
      return this.sortTypes.find((sortType) => sortType.value === this.sort)?.label
    },
    sortTypes() {
      return [
        {
          label: this.$t('forum.chat_page.threads.sort.newest_first'),
          value: '-createdAt'
        },
        {
          label: this.$t('forum.chat_page.threads.sort.oldest_first'),
          value: '+createdAt',
          default: true
        },
        {
          label: this.$t('forum.chat_page.threads.sort.last_response'),
          value: '-lastResponse'
        }
      ]
    }
  },
  watch: {
    sort: {
      handler() {
        this.$store.commit('chats/SET_THREAD_SORT', this.sort)
        if (this.checkSort()) {
          this.resetPagination()
          this.fetchThreads()
        }
      },
      immediate: false
    }
  },
  mounted() {
    this.sort = this.$store.state.chats.threadSort
    this.checkSort()
    this.fetchThreads()
    this.$nextTick(() => {
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_CREATE, this.createThreadFromSocket)
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_UPDATE, this.updateThreadFromSocket)
      chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_META_UPDATE, this.updateThreadMetaFromSocket)
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_DELETE, this.deleteThreadFromSocket)
    })
  },
  beforeDestroy() {
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_CREATE, this.createThreadFromSocket)
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_UPDATE, this.updateThreadFromSocket)
    chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_META_UPDATE, this.updateThreadMetaFromSocket)
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_DELETE, this.deleteThreadFromSocket)
  },
  methods: {
    onThreadUpdate({ thread }) {
      const oldThread = this.threadList.find((_thread) => _thread.id === thread.id)
      if (oldThread?.state !== thread?.state || oldThread?.isPinned !== thread?.isPinned) {
        this.threadList.splice(this.threadList.indexOf(oldThread), 1, new ForumThreadModel(thread))
        this.fetchSilent()
      } else {
        this.threadList.splice(this.threadList.indexOf(oldThread), 1, new ForumThreadModel(thread))
      }
    },
    createThreadFromSocket() {
      this.fetchSilent()
    },
    deleteThreadFromSocket({ threadId }) {
      if (this.threadList.find((thread) => thread.id === threadId)) {
        this.fetchSilent()
      }
    },
    updateThreadFromSocket({ thread }) {
      this.onThreadUpdate({ thread })
      /* this.threadList = this.threadList.map((oldThread) =>
        thread.id !== oldThread.id ? oldThread : new ForumThreadModel(thread)
      ) */
    },
    updateThreadMetaFromSocket({ threadId, meta }) {
      const oldThread = this.threadList.find((_thread) => _thread.id === threadId)
      if (oldThread) {
        oldThread.setMetaFromApi({ meta })
      }
    },
    fetchSilent() {
      if (this.isFetching) {
        return
      }
      this.paginationFinished = false
      this.page = 1
      this.silentFetching = true
      this.fetchThreads()
    },
    resetPagination() {
      this.threadList = []
      this.paginationFinished = false
      this.page = 1
    },
    onRetry() {
      this.resetPagination()
      this.fetchThreads()
    },
    changePage(page) {
      this.page = page
      this.fetchThreads()
    },
    selectSortType(sortType) {
      this.sort = sortType.value
    },
    async fetchThreads() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.cancelTokenSource = CancelToken.source()

      const page = this.page
      this.isError = false
      this.total = 0

      try {
        this.isFetching = true
        const { threads, totalRecords } = await this.$api.forum.threads.getAll({
          chatId: this.chat.id,
          page,
          cancelToken: this.cancelTokenSource.token,
          state: this.state,
          perPage: this.perPage,
          sort: this.sort,
          teamId: this.selectedTeam?.id
        })
        if (!threads?.length) {
          this.paginationFinished = true
        }
        /*if (this.silentFetching) {
          this.threadList = []
        }*/
        this.total = totalRecords
        this.threadList = [
          ...this.threadList.filter((thread) => !threads.find((newThread) => thread.id === newThread.id)),
          ...threads.map((thread) => ForumThreadModel.parseFromApi({ thread, chatId: this.chat.id }))
        ]
        if (!this.threadList?.length || this.threadList.length >= totalRecords) {
          this.paginationFinished = true
        }
        this.isFetching = false
        this.silentFetching = false
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e)
          this.isError = true
          this.$toast.error(this.$getErrorMessage(e))
          this.isFetching = false
          this.silentFetching = false
        }
      }
    },
    checkSort() {
      if (!this.sortTypes.find((type) => type.value === this.sort)) {
        this.sort = this.sortTypes.find((type) => type.default).value
        return false
      }
      return true
    }
  }
}
</script>

<style scoped lang="scss">
.ForumThreadPageAsideThreads {
  @apply flex flex-col min-h-0;
  &__sort-selector-button {
    @apply px-1;
  }
  &__header {
    @apply flex items-center justify-between;
  }
  &Title {
    @apply flex items-center flex-shrink-0;
    &__icon {
      @apply text-accent-01;
    }
    &__text {
      @apply text-lg font-medium text-primary-01-400 ml-2;
    }
  }
  &List {
    @apply flex-1 min-h-0 mt-4;
    &__loading {
      @apply py-12;
    }
    &__empty {
      @apply py-8;
    }
    &__error {
      @apply py-8;
    }
    &__inner {
      @apply pt-4 h-full min-h-0;
    }
    &__card {
      @apply mt-4;
      &:first-child {
        @apply mt-0;
      }
    }
  }
  &__pagination-container {
    @apply mt-6 flex justify-center;
  }
}
</style>
