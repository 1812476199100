<template>
  <div class="PollNumberEditor">
    <PollNumberEditorSlider
      v-if="poll.numberStyle === PollStyleEnum.SLIDER"
      :response.sync="localResponse"
      :poll="poll"
      :locked="locked"
      :is-valid.sync="isValid"
      :is-first-time-fill="false"
    />
    <PollNumberEditorStars
      v-else-if="poll.numberStyle === PollStyleEnum.STARS"
      :response.sync="localResponse"
      :poll="poll"
      :locked="locked"
      :is-valid.sync="isValid"
    />
    <PollNumberEditorValue
      v-else-if="poll.numberStyle === PollStyleEnum.NUMBER_INPUT"
      :response.sync="localResponse"
      :poll="poll"
      :locked="locked"
      :is-valid.sync="isValid"
    />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'
import PollNumberEditorSlider from '@/components/modules/poll/number/editor/PollNumberEditorSlider.vue'
import PollNumberEditorStars from '@/components/modules/poll/number/editor/PollNumberEditorStars.vue'
import PollNumberEditorValue from '@/components/modules/poll/number/editor/PollNumberEditorValue.vue'

export default {
  name: 'PollNumberEditor',
  components: { PollNumberEditorValue, PollNumberEditorStars, PollNumberEditorSlider },
  props: {
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    editMode: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel(),
      isValid: false
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    }
  },
  watch: {
    response: {
      handler() {
        if (
          !this.$_.isEqual(this.response, this.localResponse) &&
          !(this.response === null && this.localResponse.isEmpty)
        ) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isValid)
      },
      deep: true
    },
    isValid: {
      handler() {
        this.$emit('update:isValid', this.isValid)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberEditor {
}
</style>
