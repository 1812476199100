<template>
  <LLPopper
    class="ForumResponseReactionButton"
    :offset="[0, 0]"
    tooltip-white
    :show.sync="isReactionSilectorVisible"
    placement="top-start"
  >
    <template #trigger>
      <LLButton tertiary-dark small>
        <template #icon-left>
          <ReactionIcon />
        </template>
      </LLButton>
    </template>
    <template #tooltip>
      <LLTextareaEmoji show-suggested @select="onReactionSelect" />
    </template>
  </LLPopper>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import ReactionIcon from '@/assets/icons/ReactionIcon.vue'
import LLTextareaEmoji from '@/components/common/LLTextarea/LLTextareaEmoji.vue'
import LLPopper from '@/components/utils/LLPopper.vue'

export default {
  name: 'ForumResponseReactionButton',
  components: { LLPopper, LLTextareaEmoji, ReactionIcon, LLButton },
  data() {
    return {
      isReactionSilectorVisible: false
    }
  },
  computed: {},
  methods: {
    onReactionSelect(reaction) {
      this.isReactionSilectorVisible = false
      this.$emit('reactionSelect', reaction.emoji)
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseReactionButton {
}
</style>
