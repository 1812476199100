<template>
  <div class="LLLineProgress relative h-1">
    <div class="absolute top-px h-0.5 w-full rounded bg-neutral-01-50"></div>
    <div
      v-if="progress"
      :class="[color]"
      class="h-1 w-1/2 rounded absolute"
      :style="{ width: progress + '%' }"
    ></div>
    <div
      v-for="progressItem in progressList"
      :key="progressItem.name"
      :class="'bg-' + progressItem.color"
      class="h-1 w-1/2 bg-success-01 rounded absolute"
      :style="{ width: progressItem.progress + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LLLineProgress',
  props: {
    colorGreen: {
      type: Boolean,
      default: false
    },
    colorYellow: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    progressList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    color() {
      if (this.colorGreen) return 'bg-success-01'
      if (this.colorYellow) return 'bg-answered-01'
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.LLLineProgress {
}
</style>
