<template>
  <div class="PollResultsFilterAdvanced">
    <div class="PollResultsFilterAdvanced__categories">
      <LLRadio
        v-for="option in filterOptions"
        :key="option.value"
        v-model="localFilterType"
        class="mr-4 text-sm text-primary-01"
        :value="option.value"
        >{{ $t(option.nameId) }}
      </LLRadio>
    </div>
    <div class="PollResultsFilterAdvanced__subfilters"></div>
    <LLCheckbox v-if="localFilterType" v-model="allSubFiltersSelected" class="text-sm text-primary-01">
      {{ filterSelectAllLabel }}
    </LLCheckbox>
    <LLCheckbox
      v-for="value in chartGroups[localFilterType]"
      :key="value.code"
      v-model="localFilterValues"
      :value="value.code"
      class="mt-4 text-sm text-primary-01"
    >
      <template #default>
        <div class="flex items-center">
          {{ value.label }}
        </div>
      </template>
    </LLCheckbox>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LLRadio from '@/components/common/LLRadio.vue'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'

export default {
  name: 'PollResultsFilterAdvanced',
  components: { LLCheckbox, LLRadio },
  props: {
    filterType: { type: String, default: null },
    filterValues: { type: Array, default: () => [] },
    locationGroups: { type: Array, default: () => [] }
  },
  data() {
    return {
      localFilterType: '',
      localFilterValues: [],
      filterSelected: {
        ageGroup: [],
        gender: [],
        locationGroup: [],
        ethnicity: []
      }
    }
  },
  computed: {
    ...mapState(['appData']),
    allSubFiltersSelected: {
      get() {
        return this.chartGroups?.[this.localFilterType]?.length === this.localFilterValues?.length
      },
      set(isSelected) {
        isSelected
          ? (this.localFilterValues = [
              ...(this.chartGroups?.[this.localFilterType]?.map((filter) => filter.code) || [])
            ])
          : (this.localFilterValues = [])
      }
    },
    filterSelectAllLabel() {
      const label = this.$t('forum.poll.filters.all')
      return label
    },
    filterOptions() {
      return [
        {
          nameId: 'forum.poll.filters.all',
          value: null
        },
        {
          nameId: 'user_details_age',
          value: 'age'
        },
        {
          nameId: 'user_details_gender',
          value: 'gender'
        },
        {
          nameId: 'user_details_location',
          value: 'location'
        },
        {
          nameId: 'filter_ethnicity',
          value: 'ethnicity'
        }
      ]
    },
    chartGroups() {
      return {
        gender: this.genderArray,
        ethnicity: this.ethnicityArray,
        location: this.locationsArray,
        age: this.ageArray
      }
    },
    ageArray() {
      return Object.keys(this.appData.quantAgeGroups).map((key) => ({
        label: key,
        code: key
      }))
    },
    genderArray() {
      return this.$store.getters.gendersAsArray.map((gender) => ({
        label: this.$t(`gender_${gender.label}`),
        code: gender.code
      }))
    },
    ethnicityArray() {
      return this.$store.getters.ethnicitiesArray.map((ethnicity) => ({
        label: this.$t(`ethnicity_${ethnicity.label}`),
        code: ethnicity.value
      }))
    },
    locationsArray() {
      const baseLocations = Object.keys(this.appData.quantRegionLocationGroups).map((item) => ({
        label: 'US: ' + item[0].toUpperCase() + item.slice(1),
        code: item
      }))
      return [
        ...baseLocations,
        ...this.locationGroups
          .filter(
            (group) =>
              !baseLocations.find((baseLocation) => group?.toLowerCase() === baseLocation.code.toLowerCase())
          )
          .map((item) => ({
            label: item,
            code: item?.toLowerCase()
          }))
      ]
    },
    chartGroupValue() {
      const value = {
        ageGroup: [],
        gender: [],
        locationGroup: [],
        ethnicity: []
      }
      const ageGroup = Object.keys(this.appData.quantAgeGroups)
      ageGroup.forEach((item) => value.ageGroup.push(item))
      ;['Male', 'Female', 'Non binary'].forEach((item) => value.gender.push(item))
      const locationGroup = Object.keys(this.appData.quantRegionLocationGroups)
      locationGroup.forEach((item) =>
        value.locationGroup.push('US: ' + item[0].toUpperCase() + item.slice(1))
      )
      this.ethnicityMapping.forEach((item) => value.ethnicity.push(item.label))
      return value
    }
  },
  watch: {
    filterType: {
      handler() {
        this.localFilterType = this.filterType
        this.allSubFiltersSelected = true
      },
      immediate: true
    },
    localFilterType: {
      handler() {
        this.$emit('update:filterType', this.localFilterType)
      }
    },
    filterValues: {
      handler() {
        if (!this.$_.isEqual(this.filterValues, this.localFilterValues)) {
          this.localFilterValues = this.$_.cloneDeep(this.filterValues)
        }
      },
      immediate: true
    },
    localFilterValues: {
      handler() {
        this.$emit('update:filterValues', this.$_.cloneDeep(this.localFilterValues))
      }
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.PollResultsFilterAdvanced {
  @apply flex flex-col flex-grow p-6 overflow-y-auto max-w-lg;
  &__categories {
    @apply flex flex-row items-center flex-wrap;
  }
  &__subfilters {
    @apply mt-4;
  }
}
</style>
