<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00018 14.4086C11.5389 14.4086 14.4076 11.5399 14.4076 8.00116C14.4076 4.46244 11.5389 1.59375 8.00018 1.59375C4.46147 1.59375 1.59277 4.46244 1.59277 8.00116C1.59277 11.5399 4.46147 14.4086 8.00018 14.4086ZM15.4076 8.00116C15.4076 12.0922 12.0912 15.4086 8.00018 15.4086C3.90918 15.4086 0.592773 12.0922 0.592773 8.00116C0.592773 3.91016 3.90918 0.59375 8.00018 0.59375C12.0912 0.59375 15.4076 3.91016 15.4076 8.00116ZM8.19818 5.01762C7.84387 4.95685 7.47948 5.02343 7.16955 5.20557C6.85963 5.38772 6.62417 5.67368 6.50487 6.01279C6.37658 6.37748 5.97694 6.56913 5.61225 6.44084C5.24756 6.31255 5.05591 5.9129 5.1842 5.54821C5.41321 4.89721 5.86523 4.34826 6.4602 3.99859C7.05516 3.64892 7.75468 3.5211 8.43486 3.63777C9.11504 3.75444 9.73198 4.10807 10.1764 4.63602C10.6208 5.16391 10.864 5.83203 10.8631 6.52206C10.8627 7.63395 10.0379 8.37189 9.44024 8.77034C9.12002 8.98382 8.80478 9.14094 8.57235 9.24424C8.45515 9.29634 8.3565 9.33584 8.28552 9.36288C8.24997 9.37643 8.22119 9.38691 8.2003 9.39433L8.17496 9.40321L8.16696 9.40594L8.16417 9.40688L8.16308 9.40725L7.94693 8.76175C8.1622 9.40754 8.16287 9.40732 8.16308 9.40725C7.79632 9.5295 7.39901 9.33158 7.27676 8.96482C7.15466 8.59854 7.3522 8.20267 7.71804 8.07987L7.71946 8.07938L7.94071 8.74308L7.75165 8.17572L7.71946 8.07938L7.73129 8.07523C7.74295 8.07109 7.76191 8.06421 7.78712 8.0546C7.83767 8.03535 7.91263 8.00541 8.00376 7.96491C8.188 7.88302 8.42832 7.76236 8.66366 7.60547C9.17693 7.26329 9.46306 6.89038 9.46306 6.52124L9.46306 6.5202C9.46359 6.16071 9.33689 5.81264 9.10538 5.53762C8.87387 5.2626 8.55249 5.07839 8.19818 5.01762ZM8.00011 11.0059C7.61351 11.0059 7.30011 11.3193 7.30011 11.7059C7.30011 12.0925 7.61351 12.4059 8.00011 12.4059H8.00844C8.39504 12.4059 8.70844 12.0925 8.70844 11.7059C8.70844 11.3193 8.39504 11.0059 8.00844 11.0059H8.00011Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HelpIcon'
}
</script>
