<template>
  <div
    class="LLFileSlider w-full"
    :class="{
      LLFileSlider_overflowed: overflowed,
      LLFileSlider_small: small,
      LLFileSlider_large: large,
      LLFileSlider_medium: medium,
      'LLFileSlider_pre-large': preLarge,
      LLFileSlider_single: single,
      'LLFileSlider_small-container': smallContainer
    }"
  >
    <div class="LLFileSlider__container">
      <div class="LLFileSlider__files-wrapper">
        <div ref="files" class="LLFileSlider__files">
          <div ref="files-inner" class="LLFileSlider__files-inner">
            <div v-for="file in files" :key="file.id" class="LLFileSlider__file">
              <LLFileSliderFile
                :small="small"
                :large="large || preLarge || medium"
                :file="file"
                @open="openModal(file)"
              ></LLFileSliderFile>
              <a
                v-if="editable"
                href="javascript:void(0)"
                class="LLFileSlider__file-remove"
                @click="removeAttachedFiles($event, file)"
              >
                <ClearSolidIcon class="text-accent-01" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="overflowed" class="LLFileSlider__arrows">
        <button class="LLFileSlider__arrow" @click="swipeLeft">
          <ArrowRight></ArrowRight>
        </button>
        <button class="LLFileSlider__arrow" @click="swipeRight">
          <ArrowRight></ArrowRight>
        </button>
      </div>
    </div>
    <LLFileModal v-if="selectedFile" :files="viewableFiles" :selected-file.sync="selectedFile"></LLFileModal>
  </div>
</template>

<script>
import _ from 'lodash'
import ClearSolidIcon from '@/assets/icons/ClearSolidIcon'
import ArrowRight from '@/assets/icons/ArrowRight'
import LLFileSliderFile from '@/components/common/LLFileSlider/LLFileSliderFile'
import LLFileModal from '@/components/common/fileGallery/LLFileModal'
import { ConversationMessageFileModel } from '@/models/conversationMessageFile'
import { FileModel } from '@/models/file'
/**
 * File of file slider
 * @version 1.0.0
 */

export default {
  name: 'LLFileSlider',
  components: { LLFileModal, LLFileSliderFile, ArrowRight, ClearSolidIcon },
  props: {
    large: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    preLarge: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    files: { type: Array, required: true },
    editable: { type: Boolean, default: false },
    smallContainer: { type: Boolean, default: false },
    single: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedFile: null,
      overflowed: false,
      resizeObserver: null
    }
  },
  computed: {
    visibleFiles() {
      return this.localFiles.filter((f) => this.isVisible(f))?.length
    },
    viewableFiles() {
      return this.files.filter((item) => item.mimetype !== 'application/pdf')
    },
    scrollLOffset() {
      if (this.smallContainer) {
        if (this.small) {
          return 70
        } else if (this.large) {
          return 270
        } else if (this.medium) {
          return 125
        } else if (this.preLarge) {
          return 206
        } else {
          return 100
        }
      } else {
        return 200
      }
    }
  },
  watch: {
    localFiles: {
      handler() {
        this.localFiles.forEach((f, i) => {
          f.order = i
        })
        this.$emit('update:files', [...this.localFiles])
      },
      deep: true
    },
    files: {
      deep: true,
      handler() {
        if (!this.$_.isEqual(this.localFiles, this.files)) {
          this.localFiles = this.files.map((f) => {
            if (f instanceof ConversationMessageFileModel) {
              return new ConversationMessageFileModel(f)
            } else if (f instanceof FileModel) {
              return new FileModel(f)
            }
            return FileModel.parseFromApi({ file: f })
          })
        }
        if (this.selectedFile) {
          this.selectedFile = this.localFiles.find((f) => f?.id === this.selectedFile?.id) ?? null
        }
        this.recountOverflow()
      },
      immediate: true
    }
  },
  mounted() {
    this.initRecountOverflow()
  },
  destroyed() {
    this.destroyRecountOverflow()
  },
  methods: {
    initResizeListeners() {
      const resizeObserver = new ResizeObserver(() => {
        this.recountOverflow()
      })
      this.$nextTick(() => {
        try {
          resizeObserver.observe(this.$refs['files-inner']?.$el)
          resizeObserver.observe(this.$refs.files?.$el)
        } catch {}
      })

      this.resizeObserver = resizeObserver
    },
    destroyResizeListeners() {
      this.resizeObserver.disconnect()
    },
    isVisible(file) {
      return !(this.isImage(file) && this.hideImages) && !(this.isVideo(file) && this.hideVideos)
    },
    isImage(file) {
      return file.mimetype.substr(0, 4) === 'imag'
    },
    isVideo(file) {
      return file.mimetype.substr(0, 4) === 'vide'
    },
    removeAttachedFiles(e, file) {
      e.preventDefault()
      e.stopPropagation()
      let files = [...this.files]
      files.splice(files.indexOf(file), 1)
      this.$emit('update:files', files)
    },
    closeModal() {
      this.selectedFile = null
    },
    openModal(file) {
      if (file.mimetype === 'application/pdf') {
        window.open(file.location, '_blank')
      } else if (file.mimetype.substr(0, 4) === 'imag' || file.mimetype.substr(0, 4) === 'vide') {
        this.selectedFile = file
      }
    },
    getScrollPosition() {
      return this.$refs.files.scrollLeft
    },
    swipeLeft() {
      this.swipe(-this.scrollLOffset)
    },
    swipeRight() {
      this.swipe(this.scrollLOffset)
    },
    swipe: _.debounce(
      function (offset) {
        this.$refs.files.scrollTo({
          left: this.getScrollPosition() + offset,
          behavior: 'smooth'
        })
      },
      300,
      {
        trailing: false,
        maxWait: 300,
        leading: true
      }
    ),
    initRecountOverflow() {
      this.recountOverflow()
      window.addEventListener('resize', this.recountOverflow)
    },
    destroyRecountOverflow() {
      window.removeEventListener('resize', this.recountOverflow)
    },
    recountOverflow() {
      this.$nextTick(() => {
        this.overflowed = this.$refs?.['files-inner']?.offsetWidth > this.$refs?.files?.offsetWidth
      })
    }
  }
}
</script>

<style scoped lang="scss">
$fileOffset: 1rem;
.LLFileSlider {
  $root: &;
  $imageSize: 80px;
  @mixin sizes {
    #{$root}__files-wrapper {
      height: calc(#{$imageSize} + #{$fileOffset * 2});
      margin-bottom: -$fileOffset;
      margin-top: -$fileOffset;
      #{$root}__files {
        #{$root}__file {
          margin-top: $fileOffset;
          width: $imageSize;
          height: $imageSize;
          max-width: $imageSize;
          max-height: $imageSize;
        }
      }
    }
  }
  @include sizes;
  &_small {
    $imageSize: 50px;
    @include sizes;
  }
  &_full-size {
    $imageSize: 100%;
    @include sizes;
    #{$root}__container {
      @apply w-full h-full;
    }
    #{$root}__files {
      @apply w-full h-full;
    }
    #{$root}__files-inner {
      @apply w-full h-full;
    }
  }
  &_large {
    $imageSize: 250px;
    @include sizes;
  }
  &_pre-large {
    $imageSize: 190px;
    @include sizes;
  }
  &_medium {
    $imageSize: 125px;
    @include sizes;
  }
  &_single {
    #{$root}__files-wrapper {
      #{$root}__files {
        overflow-x: hidden !important;
      }
    }
  }
  &_small-container {
    &:not(#{$root}_small):not(#{$root}_large):not(#{$root}_pre-large):not(#{$root}_medium) {
      #{$root}__arrows {
        margin-top: 0.5rem !important;
        @apply w-full flex justify-between;
        #{$root}__arrow {
          width: 1.5rem !important;
          height: 1.5rem !important;
          margin: 0 !important;
          @apply flex-shrink-0;
        }
      }
    }
  }
  &__container {
    #{$root}__arrows {
      @apply mt-4 select-none relative;
      @apply flex;
      #{$root}__arrow {
        @apply outline-none text-primary-01-400 transition duration-200;
        &:hover {
          @apply text-primary-01-200;
        }
        &:focus-visible {
          @apply text-primary-01-200;
        }
        &:active {
          @apply text-primary-01-600;
        }
        cursor: pointer;
        &:first-child {
          @apply mr-6 transform rotate-180;
        }
      }
    }
  }
  #{$root}__files-wrapper {
    @apply overflow-hidden;
    #{$root}__files-draggable-wrapper {
      @apply w-full;
    }
    #{$root}__files {
      @apply overflow-x-auto overflow-y-hidden pb-4 flex;
      &-inner {
        @apply flex flex-row items-center flex-nowrap min-w-0 flex-shrink-0;
      }
      #{$root}__file {
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        @apply cursor-pointer ml-4;
        &:first-child,
        &[style*='display: none'] + &:not([style*='display: none']) {
          @apply ml-0;
        }
        &-remove {
          @apply absolute rounded-full border border-white text-accent-01;
          background-color: white;
          top: -7px;
          right: -7px;
        }
        &-crop {
          @apply absolute rounded-full border border-white text-neutral-01-200;
          top: 32px;
          right: -7px;
        }
      }
    }
  }
}

.file-slider {
}

.files-container {
  @apply mb-2 relative rounded-md;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  cursor: pointer;
}

.file-img {
  @apply object-cover rounded;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
}

.remove-icon {
}

.bordered-y {
  @apply border-b border-t border-neutral-01-50;
}

/* base */
.flip-list {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.flip-list-move {
  transition: all 200ms ease-in-out 50ms;
}

/* appearing */
.flip-list-enter-active {
  transition: all 200ms ease-out;
}

/* disappearing */
.flip-list-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
}
</style>
