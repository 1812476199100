<template>
  <div class="LLDialog">
    <h3 v-if="slotsVisibility.title" class="LLDialog__title">
      <slot name="title"></slot>
    </h3>
    <div class="LLDialog__content">
      <slot name="content"></slot>
    </div>
    <div v-if="slotsVisibility.actions" class="LLDialog__actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLDialog',
  data() {
    return {
      slotsVisibility: {
        title: false,
        actions: false
      }
    }
  },
  created() {
    this.checkSlotsVisibility()
  },
  beforeUpdate() {
    this.checkSlotsVisibility()
  },
  methods: {
    checkSlotsVisibility() {
      this.$nextTick(() => {
        this.slotsVisibility.title = !!this.$slots?.title?.[0]
        this.slotsVisibility.actions = !!this.$slots?.actions?.[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLDialog {
  &__title {
    @apply mb-4 truncate;
  }
  &__content {
  }
  &__actions {
    @apply flex justify-end mt-4;
    .LLButton {
      @apply mr-4;
      &:last-child {
        @apply mr-0;
      }
    }
  }
}
</style>
