import Vue from 'vue'
import Api from '@/services/api'
import _ from 'lodash'

const app = new Vue()

const initialState = () => ({
  studies: [],
  conversationStreams: [],
  savedMessages: [],
  studiesLoading: false
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getStudyById: (state) => (id) => {
      return state.studies.find((study) => study.id === Number(id))
    },

    /* Topics */
    topicById: (state) => (tId) => {
      let topic = undefined
      state.studies.forEach((s) => {
        let found = s.topics.find((t) => t.id === tId)
        if (found) topic = found
      })
      return topic
    },
    topicQuestionsInProgressNum: (state, getters) => (tId) => {
      return getters.topicConversationStreams(tId).filter((cs) => cs.state === 20).length
    },
    topicQuestionsCompletedNum: (state, getters) => (tId) => {
      return getters.topicConversationStreams(tId).filter((cs) => cs.state === 30).length
    },
    topicQuestionsNum: (state, getters) => (tId) => {
      return getters.topicById(tId).questions.length
    },
    topicConversationStreams: (state, getters) => (tId) => {
      return getters
        .topicById(tId)
        .questions.map((q) => getters.conversationStreamOfQuestionId(q.id))
        .filter((cs) => typeof cs !== 'undefined')
    },
    topicHasNewMessage: (state, getters) => (tId) => {
      let conversationStreams = getters.topicConversationStreams(tId)
      let hasNew = false
      conversationStreams.forEach((cs) => {
        if (getters.conversationStreamHasNewMessage(cs.questionId)) hasNew = true
      })
      return hasNew
    },
    isTopicLocked: (state, getters) => (tId) => {
      let currentTopic = getters.topicById(tId)
      if (!currentTopic?.lockerIds?.length) {
        return false
      }
      return !!currentTopic.lockerIds.find((topicId) => !getters.isTopicResolved(topicId))
    },
    isTopicResolved: (state, getters) => (tId) => {
      let currentTopic = getters.topicById(tId)
      let resolved = true
      if (!currentTopic?.questions?.length) {
        resolved = false
      } else {
        currentTopic.questions.forEach((q) => {
          let convStreamsOfTopic = getters.conversationStreamOfQuestionId(q.id)
          if (!convStreamsOfTopic || convStreamsOfTopic.state !== 30) {
            resolved = false
          }
        })
      }
      return resolved
    },

    conversationStreams(state) {
      return state.conversationStreams
    },
    conversationStreamOfQuestionId: (state) => (questionId) => {
      let found = state.conversationStreams.find((cs) => cs.questionId === questionId)
      return found ? found : { messages: [], state: 10, questionId }
    },
    conversationStreamHasNewMessage: (state, getters) => (questionId) => {
      let cs = getters.conversationStreamOfQuestionId(questionId)
      if (!cs.messages || !cs.messages.length) return false
      return !!cs.messages[cs.messages.length - 1].user
    },
    getCountOfActive(state) {
      return state.studies.filter(
        (s) =>
          s.participantState === 10 ||
          s.participantState === 30 ||
          s.participantState === 40 ||
          s.participantState === 60 ||
          (s.participantState === 70 && s.state === 20)
      ).length
    },
    getActiveStudies(state) {
      return state.studies.filter(
        (s) =>
          (s.participantState === 10 ||
            s.participantState === 30 ||
            s.participantState === 40 ||
            s.participantState === 60 ||
            s.participantState === 70) &&
          s.state !== 30 &&
          s.isParticipantExported
      )
    },
    getInvitedStudies(state) {
      return state.studies.filter((s) => s.participantApprovalState === 10 && s.state !== 30)
    },
    getTopStudies(state) {
      return state.studies.filter((s) => s.participantApprovalState === 20 && s.state !== 30)
    },
    getCountOfCompleted() {
      // state.studies.filter // TODO count
      return 0
    },
    allQuestionsInfo(state) {
      return state.studies.reduce((sAcc, s) => {
        return [
          ...sAcc,
          ...s.topics.reduce((tAcc, t) => {
            return [...tAcc, ...t.questions.map((q) => ({ question: q, study: s, topic: t }))]
          }, [])
        ]
      }, [])
    },
    questionById: (state, getters) => (questionId) => {
      return getters.allQuestionsInfo.find((qo) => qo.question.id === questionId).question
    },
    allMessagesInfo: (state, getters) => {
      return getters.conversationStreams.reduce((csAcc, cs) => {
        return [...csAcc, ...cs.messages.map((m) => ({ message: m, questionId: cs.questionId }))]
      }, [])
    },
    questionOfMessage: (state, getters) => (messageId) => {
      return getters.questionById(getters.allMessagesInfo.find((m) => m.message.id === messageId).questionId)
    },
    savedMessages: (state) => state.savedMessages,
    savedMessagesPromisesById: (state) => (questionIdList) => {
      return state.savedMessages
        ?.filter((m) => questionIdList.find((qId) => qId === m.questionId))
        ?.map((m) => m.sendingPromise)
    },
    savedMessage:
      (state, getters) =>
      ({ studyId, questionId }) => {
        let storeMessage = getters.savedMessages.find(
          (m) => m.studyId === studyId && m.questionId === questionId
        )
        if (storeMessage) {
          return storeMessage.tempMessageData
        }
      },
    savedMessagePromise:
      (state, getters) =>
      ({ studyId, questionId }) => {
        let storeMessage = getters.savedMessages.find(
          (m) => m.studyId === studyId && m.questionId === questionId
        )
        if (storeMessage) {
          return storeMessage?.sendingPromise
        }
      },
    isMessageSending:
      (state, getters) =>
      ({ questionId }) => {
        let storeMessage = getters.savedMessages.find((m) => m.questionId === questionId)
        if (storeMessage) {
          return !!storeMessage?.sendingPromise?.then
        }
      }
  },
  mutations: {
    ADD_SAVED_MESSAGE(state, { tempMessageData, studyId, questionId }) {
      let storeMessage = state.savedMessages.find((m) => m.studyId === studyId && m.questionId === questionId)
      if (storeMessage) {
        storeMessage.tempMessageData = Object.assign(storeMessage.tempMessageData, tempMessageData)
      } else {
        state.savedMessages.push({
          tempMessageData,
          studyId,
          questionId,
          sendingPromise: null
        })
      }
    },
    SET_SAVED_MESSAGE_PROMISE(state, { promise, studyId, questionId }) {
      let storeMessage = state.savedMessages.find((m) => m.studyId === studyId && m.questionId === questionId)
      if (storeMessage) {
        storeMessage.sendingPromise = promise
      }
    },
    REMOVE_SAVED_MESSAGE(state, { studyId, questionId }) {
      state.savedMessages = state.savedMessages.filter(
        (m) => !(m.studyId === studyId && m.questionId === questionId)
      )
    },
    STUDIES_LOADING(state) {
      state.studiesLoading = true
    },
    STUDIES_LOADED(state) {
      state.studiesLoading = false
    },
    UPDATE_STUDY(state, { study: newStudy }) {
      const newStudyCloned = _.cloneDeep(newStudy)
      const study = state.studies.find((study) => study.id === newStudy.id)
      if (study) {
        state.studies = state.studies.map((s) => {
          if (s.id === newStudyCloned.id) {
            if (newStudyCloned.screeningQuestions) {
              newStudyCloned.screeningQuestions = newStudyCloned.screeningQuestions.map((question) => ({
                ...question,
                studyId: newStudyCloned.id
              }))
            } else {
              newStudyCloned.screeningQuestions = []
            }
            return { ...s, ...newStudyCloned }
          } else {
            return s
          }
        })
      } else {
        if (newStudyCloned.screeningQuestions) {
          newStudyCloned.screeningQuestions = newStudyCloned.screeningQuestions.map((question) => ({
            ...question,
            studyId: newStudyCloned.id,
            reply: null
          }))
        } else {
          study.screeningQuestions = []
        }
        state.studies.push(newStudyCloned)
      }
      //temp fix to run all watchers
      state.studies = _.cloneDeep(state.studies)
    },
    UPDATE_STUDIES(state, _studies) {
      let studies = _studies
      studies.forEach((study) => {
        if (study.screeningQuestions) {
          study.screeningQuestions.forEach((question) => {
            question.studyId = study.id
          })
        } else {
          study.screeningQuestions = []
        }
        if (study.topics) {
          study.topics.forEach((topic) => {
            topic.studyId = study.id
            topic.questions.forEach((question) => {
              question.conversationStream = []
              question.studyId = study.id
              question.topicId = topic.id
            })
          })
        } else {
          study.topics = []
        }
        if (study.screeningQuestions) {
          study.screeningQuestions.forEach((question) => {
            question.reply = null
          })
        }
      })
      state.studies = _.cloneDeep(studies)
    },
    UPDATE_CONVERSATION_STREAMS(state, data) {
      let tempFindingLocalStreams = [...state.conversationStreams]
      data.forEach((servConvStream) => {
        let foundLocalStream = tempFindingLocalStreams.find((s) => s.questionId === servConvStream.questionId)
        if (!foundLocalStream) {
          state.conversationStreams.push(servConvStream)
        } else {
          let localConvStream = state.conversationStreams[state.conversationStreams.indexOf(foundLocalStream)]
          localConvStream.messages = servConvStream.messages
          localConvStream.state = servConvStream.state
          tempFindingLocalStreams.splice(tempFindingLocalStreams.indexOf(foundLocalStream), 1)
        }
      })
    },
    UPDATE_CONVERSATION_STREAM_STATE(storeState, { questionId, state }) {
      let conversationStream = storeState.conversationStreams.find((cs) => cs?.questionId === questionId)
      if (conversationStream) {
        conversationStream.state = state
      } else {
        storeState.conversationStreams.push({ messages: [], state, questionId })
      }
    },
    ADD_MESSAGE_TO_STREAM(state, { questionId, newMessage }) {
      let conversationStream = state.conversationStreams.find((cs) => cs.questionId === questionId)
      if (!conversationStream) {
        state.conversationStreams.push({
          questionId,
          messages: [newMessage],
          state: 20
        })
        return
      }
      let message = conversationStream.messages.find((m) => m.id === newMessage.id)
      if (message) {
        Object.assign(message, newMessage)
      } else {
        conversationStream.messages.push(newMessage)
        if (conversationStream.state === 10) {
          conversationStream.state = 20
        }
      }
    },
    UPDATE_SCREENING_QUESTIONS(state, { study }) {
      let studyId = parseInt(study.id)
      let foundStudy = state.studies.find((s) => s.id === studyId)
      foundStudy.screeningQuestionsInstructions = study.screeningQuestionsInstructions

      let newScreeningQuestions = study.screeningQuestions.reduce((questionAcc, sq) => {
        let currentQuestion = foundStudy.screeningQuestions.find((cq) => cq.id === sq.id)
        if (currentQuestion) {
          return [...questionAcc, { ...currentQuestion, studyId, ...sq }]
        } else {
          return [
            ...questionAcc,
            {
              ...sq,
              studyId,
              reply: {
                text: '',
                yesOrNo: null,
                sliderValue: null,
                starsCount: null,
                chosenOptions: [],
                createdAt: null,
                updatedAt: null,
                fileIdList: [],
                isFinished: false
              }
            }
          ]
        }
      }, [])
      foundStudy.screeningQuestions = newScreeningQuestions
      state.studies = _.cloneDeep(state.studies)
    },
    UPDATE_TOPICS(state, { study }) {
      let studyId = parseInt(study.id)
      let foundStudy = state.studies.find((s) => s.id === studyId)
      study.topics.forEach((topic) => {
        topic.studyId = studyId
        topic.questions.forEach((question) => {
          question.conversationStream = []
          question.studyId = studyId
          question.topicId = topic.id
        })
      })
      foundStudy.topics = [...study.topics]
    },
    INSERT_SCREENING_QUESTIONS_REPLIES(state, data) {
      let study = state.studies.find((study) => study.id === data.studyId)
      study.screeningQuestions.forEach((question) => {
        let reply = data.replies.find((_reply) => _reply.screeningQuestionId === question.id)
        if (!reply) {
          return
        }
        question.reply = reply.reply
        if (!question.reply) {
          question.reply = {
            text: '',
            yesOrNo: null,
            sliderValue: null,
            starsCount: null,
            chosenOptions: [],
            createdAt: null,
            updatedAt: null,
            fileIdList: [],
            isFinished: false
          }
        }
      })
      state.studies = _.cloneDeep(state.studies)
    },
    REMOVE_ALL(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    }
  },
  actions: {
    async loadConversationStreams({ commit }, studyId) {
      let response = await Api.studies.getConversationStream({
        studyId: studyId
      })
      commit('UPDATE_CONVERSATION_STREAMS', response.conversationStreams)
    },
    async addNewConversationStream({ commit }, stream) {
      commit('ADD_MESSAGE_TO_STREAM', stream)
    },
    async fetchStudy({ commit }, { studyId }) {
      commit('STUDIES_LOADING')
      const { study } = await app.$api.studies.get({ studyId })
      commit('UPDATE_STUDY', { study })
      commit('STUDIES_LOADED')
    },
    async fetchStudies({ commit }, payload) {
      commit('STUDIES_LOADING')
      const { studies } = await app.$api.studies.fetch(payload)
      commit('UPDATE_STUDIES', studies)
      commit('STUDIES_LOADED')
    },
    async sendConversationMessage({}, request) {
      let message = await Api.studies.sendConversationStream(request)
      return message.conversationMessage
    },
    async removeConversationMessage({}, request) {
      let message = await Api.studies.removeConversationStream(request)
      return message.conversationMessage
    },
    async updateConversationMessage({}, request) {
      let message = await Api.studies.updateConversationStream(request)
      return message.conversationMessage
    },
    reset({ commit }) {
      commit('REMOVE_ALL')
    }
  }
}
