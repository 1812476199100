<template>
  <svg
    class="icon fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2 6H10.8V10.8H6V13.2H10.8V18H13.2V13.2H18V10.8H13.2V6ZM12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2 6H10.8V10.8H6V13.2H10.8V18H13.2V13.2H18V10.8H13.2V6ZM12 0C18.6 0 5.4 0 12 0V0ZM12 21.6C17.28 21.6 6.72 21.6 12 21.6V21.6Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddCircleIcon'
}
</script>
