<template>
  <div class="ll-radio">
    <label v-if="caption" class="ll-radio__caption" :for="id">
      {{ caption }}
    </label>
    <label class="ll-radio__label" :class="{ 'is-disabled': disabled }">
      <input
        :id="id"
        class="ll-radio__input"
        type="radio"
        :value="value"
        :checked="shouldBeChecked"
        :disabled="disabled"
        @change="updateInput"
      />
      <span class="ll-radio__icon" :class="{ 'is-checked': shouldBeChecked, 'is-disabled': disabled }" />
      <slot />
    </label>

    <slot name="suffix" />
  </div>
</template>

<script>
import uid from '@/mixins/uid'

export default {
  name: 'LLRadio',
  mixins: [uid],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: { type: [String, Number, Boolean], default: '' },
    modelValue: { type: null, default: false },
    caption: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    id() {
      return `ll-radio-${this.uid}`
    },
    shouldBeChecked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateInput() {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.ll-radio {
  &__caption {
    @apply inline-block mb-3 font-semibold cursor-pointer;
  }
  &__label {
    @apply w-full inline-flex items-center cursor-pointer;
    &.is-disabled {
      @apply text-neutral-01-200;
    }
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
  &__icon {
    @apply inline-flex mr-3 rounded-full bg-white text-white border border-neutral-01 transition duration-200;
    width: 18px;
    height: 18px;
    box-shadow: 0px 2px 3px rgba(#000, 0.1);
    &.is-checked {
      @apply border-primary-01-400 border-4;
    }
    &.is-disabled {
      @apply bg-neutral-01-25 border-neutral-01-50;
    }
  }
}
</style>
