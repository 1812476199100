<template>
  <div class="ForumChatThreadCardContent" :class="classList">
    <TextHider
      :absolute-block="compact"
      :lines="compact ? 2 : 5"
      class="ForumChatThreadCardContent__message-wrapper"
    >
      <LLWysiwygOutput :text="thread.text" class="ForumChatThreadCardContent__message"></LLWysiwygOutput>
    </TextHider>
    <div v-if="thread.files.length" class="ForumChatThreadCardContent__files">
      <LLFileSlider :files="thread.files" small></LLFileSlider>
    </div>
    <span v-if="hasLink && thread.hasPoll" class="ForumChatThreadCardContent__poll-notice">{{
      $t('forum.thread.poll-notice')
    }}</span>
  </div>
</template>

<script>
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'
import TextHider from '@/components/common/TextHider.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'ForumChatThreadCardContent',
  components: { LLFileSlider, TextHider, LLWysiwygOutput },
  props: {
    thread: { type: ForumThreadModel, required: true },
    hasLink: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardContent {
  $root: &;
  &__poll-notice {
    @apply text-body-02 mt-2 flex;
  }
  &__message-wrapper {
    @at-root #{$root}_compact & {
      @apply text-body-03;
    }
    @at-root #{$root}_mobile & {
      @apply text-body-03;
    }
  }
  &__files {
    @apply mt-2;
  }
}
</style>
