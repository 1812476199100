import { render, staticRenderFns } from "./PollNumberStars.vue?vue&type=template&id=5e8cd280&scoped=true"
import script from "./PollNumberStars.vue?vue&type=script&lang=js"
export * from "./PollNumberStars.vue?vue&type=script&lang=js"
import style0 from "./PollNumberStars.vue?vue&type=style&index=0&id=5e8cd280&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8cd280",
  null
  
)

export default component.exports