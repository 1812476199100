<template>
  <div
    ref="main"
    class="ForumThreadPageContent"
    :class="{ 'ForumThreadPageContent_aside-closed': !isAsideOpened }"
  >
    <ForumChatSmallDetails class="ForumThreadPageContent__chat-details" :chat="chat" />
    <router-link
      v-if="!tailwindScreens.lg"
      tag="a"
      class="ForumThreadPageContent__back-button-wrapper"
      :to="chatsLink"
    >
      <LLButton class="ForumThreadPageContent__back-button" secondary small>
        <template #icon-left>
          <ArrowMiniIcon class="ForumThreadPageContent__back-button-arrow"></ArrowMiniIcon>
        </template>
        {{ $t('forum.thread_page.back_button') }}
      </LLButton>
    </router-link>
    <ForumThreadPageAside
      v-if="isAsideOpened"
      ref="aside"
      class="ForumThreadPageContent__aside"
      :thread="thread"
      :chat="chat"
      @close="closeAside"
    />
    <LLButton
      v-if="!isAsideOpened"
      ref="aside-button"
      class="ForumThreadPageContent__aside-button"
      secondary
      @click="isAsideOpened = true"
    >
      {{ $t('forum.chat_page.threads.title') }}
      <template #icon-right> <ArrowMiniIcon class="ForumThreadPageContent__aside-button-icon" /> </template>
    </LLButton>
    <div class="ForumThreadPageContentMain">
      <div v-if="isAsideOpened" class="ForumThreadPageContentMain__helper-left"></div>
      <div class="ForumThreadPageContentMain__inner">
        <ForumChatThreadCard
          class="ForumThreadPageContent__thread-card"
          :thread="thread"
          dark
          :has-link="false"
          @update="onUpdateThread"
          @remove="onRemoveThread"
        />
        <ForumThreadPageResponseList class="ForumThreadPageContent__response-list" :thread="thread" />
      </div>
    </div>
  </div>
</template>

<script>
import ForumChatSmallDetails from '@/components/modules/forum-chat/ForumChatSmallDetails'
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLButton from '@/components/common/ui-components/LLButton'
import ForumThreadPageResponseList from '@/components/pages/forum/thread/response/ForumThreadPageResponseList'
import ForumThreadPageAside from '@/components/pages/forum/thread/ForumThreadPageAside.vue'
import ForumChatThreadCard from '@/components/modules/forum-chat/ForumChatThreadCard.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
export default {
  name: 'ForumThreadPageContent',
  components: {
    ForumChatThreadCard,
    ForumThreadPageAside,
    ForumThreadPageResponseList,
    LLButton,
    ForumChatSmallDetails
  },
  mixins: [screenCatcher],
  props: {
    chat: { type: ForumChatModel, required: true },
    thread: { type: ForumThreadModel, default: null }
  },
  data() {
    return {
      isAsideOpened: false
    }
  },
  computed: {
    chatsLink() {
      return `/chats/${this.chat?.id}`
    }
  },
  watch: {
    isAsideOpened() {
      this.$nextTick(() => {
        this.windowScrollHandler()
      })
    }
  },
  mounted() {
    if (this.tailwindScreens.lg) {
      this.isAsideOpened = true
    }
    window.addEventListener('scroll', this.windowScrollHandler)
    window.addEventListener('resize', this.windowScrollHandler)
    this.$nextTick(() => {
      this.windowScrollHandler()
    })
  },
  beforeDestroy() {
    document.body.classList.remove('block-scroll')
    document.documentElement.classList.remove('block-scroll')
    window.removeEventListener('scroll', this.windowScrollHandler)
    window.removeEventListener('resize', this.windowScrollHandler)
  },
  methods: {
    onUpdateThread({ thread }) {
      this.$emit('updateThread', { thread })
    },
    onRemoveThread() {
      this.$router.replace(this.chatsLink)
    },
    toggleBodyScrollBlock() {
      if (this.isAsideOpened && !this.tailwindScreens.sm) {
        document.body.classList.add('block-scroll')
        document.documentElement.classList.add('block-scroll')
      } else {
        document.body.classList.remove('block-scroll')
        document.documentElement.classList.remove('block-scroll')
      }
    },
    windowScrollHandler() {
      this.$nextTick(() => {
        this.toggleBodyScrollBlock()
        const main = this.$refs.main
        const header = document.querySelector('.header')
        const breadCrumbs = document.querySelector('.LLPage__breadcrumbs')
        const windowScroll = window.scrollY

        const headerHeight = header?.getBoundingClientRect()?.height
        const mainParams = main?.getBoundingClientRect()
        const breadCrumbsHeight = breadCrumbs?.getBoundingClientRect()?.height
        const windowHeight = window.innerHeight

        if (!this.tailwindScreens.sm) {
          this.unfixAside()
        } else if (mainParams.bottom + 32 < windowHeight) {
          this.fixAsideBottom(headerHeight)
        } else if (mainParams.top - 24 < headerHeight) {
          this.fixAsideTop(headerHeight)
        } else {
          this.unfixAside(
            windowScroll < breadCrumbsHeight ? headerHeight + (breadCrumbsHeight - windowScroll) : undefined
          )
        }

        if (mainParams.bottom + 32 < windowHeight) {
          this.fixAsideButtonBottom(headerHeight)
        } else if (mainParams.top - 24 < headerHeight) {
          this.unfixAsideButton()
        } else {
          this.unfixAsideButton()
        }
      })
    },
    fixAsideButtonBottom() {
      const button = this.$refs['aside-button']?.$el
      if (!button) {
        return
      }
      button.style.top = 'unset'
      button.style.position = 'absolute'
      button.style.bottom = `-32px`
    },
    unfixAsideButton() {
      const button = this.$refs['aside-button']?.$el
      if (!button) {
        return
      }
      button.style.removeProperty('position')
      button.style.removeProperty('top')
      button.style.removeProperty('bottom')
    },
    fixAsideBottom(height) {
      const aside = this.$refs.aside?.$el
      if (!aside) {
        return
      }
      aside.style.top = 'unset'
      aside.style.position = 'absolute'
      aside.style.bottom = `-32px`
      aside.style.height = `${window.innerHeight - height}px`
    },
    fixAsideTop(height) {
      const aside = this.$refs.aside?.$el
      if (!aside) {
        return
      }
      aside.style.removeProperty('bottom')
      aside.style.position = 'fixed'
      aside.style.top = `${height}px`
      aside.style.height = `${window.innerHeight - height}px`
    },
    unfixAside(height) {
      const aside = this.$refs.aside?.$el
      if (!aside) {
        return
      }
      if (height) {
        aside.style.height = `${window.innerHeight - height}px`
      } else {
        aside.style.removeProperty('height')
      }
      aside.style.removeProperty('position')
      aside.style.removeProperty('top')
    },
    closeAside() {
      this.isAsideOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumThreadPageContent {
  @apply relative;
  min-height: calc(100vh - var(--header-height) + 24px + 32px);
  &_aside-closed {
    @apply pb-6;
  }
  &__aside {
    @apply fixed w-full left-0;
    top: var(--header-height);
    z-index: 100;
    height: calc(100vh - var(--header-height));
    height: calc(100dvh - var(--header-height));
    min-height: 0;
    @screen sm {
      @apply absolute;
      width: calc(100vw * 1 / 4);
      left: calc(-1 * (100vw - 100%) / 2);
      max-width: 550px;
      min-width: 400px;
      top: -24px;
      z-index: 21;
    }
  }
  &__thread-card {
    @apply w-full;
  }
  &__back-button {
    @apply my-4;
    &-arrow {
      @apply rotate-180 transform;
    }
  }
  &Main {
    @apply w-full flex justify-center;
    &__helper-left {
      @apply flex-shrink-0;
      max-width: 0;
      @screen lg {
        width: calc((100vw * 1 / 4) - ((100vw - 100%) / 2) + 16px);
        max-width: calc(550px - ((100vw - 100%) / 2) + 16px);
        min-width: calc(400px - (100vw - 100%) / 2 + 16px);
      }
    }
    &__inner {
      max-width: 1990px;
      @apply w-full min-w-0;
      flex-grow: 2;
    }
  }
  &__response-list {
    @apply mt-4 mx-auto;
    @screen md {
      @apply px-10;
    }
  }
  .test-card {
    width: 400px;
  }
  &__aside-button {
    @apply fixed left-0 bottom-0;
    left: calc(-1 * (100vw - 100%) / 2);
    z-index: 21;
  }
  &__back-button-wrapper {
    @screen lg {
      display: none !important;
    }
  }
  &__chat-details {
    @screen lg {
      display: none !important;
    }
  }
}
</style>
