<template>
  <div ref="root" class="TextHider">
    <div v-show="isFullTextVisible && !absoluteBlock" ref="full-text" class="TextHider__full-text">
      <slot></slot>
      <button v-if="isTextOverflowed" class="TextHider__hide-more" @click="hide">
        {{ $t('text_hider.hide_more') }}
      </button>
    </div>
    <div v-show="!isFullTextVisible || absoluteBlock" ref="hidden-text" class="TextHider__hidden-text"></div>
    <div v-show="!isFullTextVisible || absoluteBlock" ref="line-helper" class="TextHider__lines-helper">
      <span v-for="index in lines" :key="index">
        <br />
      </span>
    </div>
    <transition name="full-text">
      <div v-if="isFullTextVisible && absoluteBlock" class="TextHiderAbsoluteBlock">
        <div class="TextHiderAbsoluteBlock__content">
          <slot></slot>
        </div>
        <LLButton class="TextHiderAbsoluteBlock__close-button" round small secondary @click="hide">
          <template #icon-right>
            <CloseRegularIcon class="TextHiderAbsoluteBlock__close-button-icon" />
          </template>
        </LLButton>
      </div>
    </transition>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
import CloseRegularIcon from '@/assets/icons/CloseRegularIcon'
export default {
  name: 'TextHider',
  components: { CloseRegularIcon, LLButton },
  props: {
    lines: { type: Number, default: 10 },
    absoluteBlock: { type: Boolean, default: false }
  },
  data() {
    return {
      isFullTextVisible: false,
      isTextOverflowed: false,
      resizeObserver: false,
      isAbsoluteBlockClosingAnimationRendering: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.render()
      this.initResizeObserver()
    })
  },
  updated() {
    if (!this.isFullTextVisible) {
      this.render()
    }
  },
  beforeDestroy() {
    this.destroyResizeObserver()
  },
  methods: {
    initResizeObserver() {
      const element = this.$refs.root
      const resizeObserver = new ResizeObserver(() => {
        if (!this.isFullTextVisible) {
          this.render()
        }
      })

      resizeObserver.observe(element)
      this.resizeObserver = resizeObserver
    },
    destroyResizeObserver() {
      this.$nextTick(() => {
        if (this.resizeObserver) {
          this.resizeObserver.disconnect()
        }
      })
    },
    hide() {
      if (!this.isFullTextVisible) return
      if (this.absoluteBlock) this.isAbsoluteBlockClosingAnimationRendering = true

      window.removeEventListener('click', this.hide)
      this.isFullTextVisible = false
      if (!this.absoluteBlock) {
        this.$nextTick(() => {
          this.render()
        })
      } else {
        setTimeout(() => {
          this.isAbsoluteBlockClosingAnimationRendering = false
          this.render()
        }, 300)
      }
    },
    findPoint(start) {
      const tree = document.createTreeWalker(this.$refs['hidden-text'], -1, {
        acceptNode(node) {
          if (!node?.hasAttribute || !node.hasAttribute('data-do-not-highlight')) {
            return NodeFilter.FILTER_ACCEPT
          } else {
            return NodeFilter.FILTER_REJECT
          }
        }
      })
      let stringCounter = 0
      let currentNode
      if (start === 0) {
        currentNode = tree.nextNode()
        while (currentNode.nodeType !== 3) {
          currentNode = tree.nextNode()
        }
        return { node: currentNode, offset: 0 }
      } else {
        while (stringCounter < start) {
          currentNode = tree.nextNode()
          if (currentNode.nodeType === 3) {
            stringCounter = stringCounter + currentNode.length
          }
        }
        return { node: currentNode, offset: start - stringCounter + currentNode.length }
      }
    },
    render() {
      if (this.isAbsoluteBlockClosingAnimationRendering) {
        return
      }
      if (!this.$refs['hidden-text']) return
      this.$refs['hidden-text'].innerHTML = this.$refs['full-text']?.innerHTML || ''

      const endPoint = this.findPointToCutOverflow()
      if (!endPoint) {
        this.isTextOverflowed = false
        return
      }
      try {
        this.isTextOverflowed = true
        this.cutTextOverflow(endPoint)
        this.placeButton()
      } catch (e) {
        console.log(e)
      }
    },
    cutTextOverflow(endPoint) {
      const rangeToCut = new Range()
      rangeToCut.setStart(endPoint.node, endPoint.offset)
      rangeToCut.setEndAfter(this.$refs['hidden-text'])
      rangeToCut.deleteContents()
    },
    placeButton() {
      const textRange = new Range()
      const startPoint = this.findPoint(0)
      const textLength = this.$refs['hidden-text'].textContent?.length
      const endPoint = this.findPoint(textLength)

      textRange.setStart(startPoint.node, startPoint.offset)
      textRange.setEnd(endPoint.node, endPoint.offset)
      textRange.collapse(false)

      const newSpan = document.createElement('span')
      const showMoreButton = document.createElement('button')
      showMoreButton.innerHTML = this.$t('text_hider.show_more')
      showMoreButton.classList.add('TextHider__show-more')
      showMoreButton.addEventListener('click', (e) => {
        this.isFullTextVisible = true
        if (this.absoluteBlock) {
          window.addEventListener('click', this.hide)
        }
        e.stopPropagation()
      })

      newSpan.appendChild(document.createTextNode(''))
      newSpan.appendChild(showMoreButton)

      if (
        textRange.endContainer.parentElement?.classList?.contains &&
        textRange.endContainer.parentElement?.classList?.contains('emoji-span')
      ) {
        if (textRange.endContainer?.parentElement?.parentElement) {
          textRange.endContainer?.parentElement?.parentElement.appendChild(newSpan)
        } else {
          console.warn(`Can't insert "Show more" button to text here: `, textRange)
        }
      } else {
        textRange.insertNode(newSpan)
      }
      this.cutTextToFitButton(textLength, newSpan)
    },
    // TODO: make remove spaces WITH punctiation
    cutTextToFitButton(textLength, span) {
      let currentLetter = textLength
      try {
        let startPoint, endPoint
        while (currentLetter > 0) {
          startPoint = this.findPoint(currentLetter - 1)
          endPoint = this.findPoint(currentLetter)
          const textRange = new Range()
          textRange.setStart(startPoint.node, startPoint.offset)
          textRange.setEnd(endPoint.node, endPoint.offset)
          textRange.deleteContents()
          const referenceBlockBounding = this.$refs['line-helper'].getBoundingClientRect()
          const bounding = span.getBoundingClientRect()
          if (bounding.bottom < referenceBlockBounding.bottom) {
            return startPoint
          }
          currentLetter--
        }
      } catch (e) {
        console.log(e)
      }
    },
    findPointToCutOverflow() {
      let currentLetter = 0
      const referenceBlockBounding = this.$refs['line-helper'].getBoundingClientRect()
      try {
        let startPoint, endPoint
        while (
          (startPoint = endPoint || this.findPoint(0)) &&
          (endPoint = this.findPoint(currentLetter + 1))
        ) {
          const textRange = new Range()
          textRange.setStart(startPoint.node, startPoint.offset)
          textRange.setEnd(endPoint.node, endPoint.offset)
          const bounding = textRange.getBoundingClientRect()
          if (bounding.bottom > referenceBlockBounding.bottom) {
            return startPoint
          }
          currentLetter++
        }
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.TextHider {
  $root: &;
  @apply relative break-words;
  word-break: break-word;
  &__hidden-text {
    @apply overflow-hidden;
  }

  &__lines-helper {
    @apply absolute top-0 left-0;
  }

  &__hide-more {
    @apply text-accent-01 font-bold;
  }

  &AbsoluteBlock {
    @apply absolute bg-white border-neutral-01-25 shadow-dropdown rounded flex;
    width: calc(100% + 20px);
    max-height: calc(100% + 100px);
    left: -20px;
    top: -20px;
    z-index: 9999;
    &__close-button {
      @apply absolute right-0 top-0 -mr-3 -mt-3 shadow-dropdown;
      &-icon {
        @apply w-4 h-4;
      }
      width: 24px !important;
      height: 24px !important;
    }
    &__content {
      @apply overflow-y-auto py-4 px-8 w-full;
      max-height: 100%;
    }
  }

  .full-text-enter-active,
  .full-text-leave-active {
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.98, 0.18, 0, 1);
  }
  .full-text-enter, .full-text-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: scale(0.95);
  }

  ::v-deep {
    #{$root}__show-more {
      @apply text-accent-01 font-bold;
      &:focus-within {
        @apply outline-none;
      }
      &:before {
        content: '... ';
        @apply text-black;
      }
    }
  }
}
</style>
