<template>
  <div class="ChatCard">
    <div class="ChatCard__card-head">
      <span>{{ $t('dashboard.chat_card.title') }}</span>
    </div>
    <div class="ChatCard__card-content">
      <h3 class="ChatCard__title">
        {{ chat.name }}
      </h3>
      <div class="ChatCard__body">
        <div v-if="chat.image" class="ChatCard__image-wrapper">
          <ImageLoader :file="chat.image" large class="ChatCard__image" />
        </div>
        <div class="ChatCard__info">
          <div class="ChatCard__meta">
            <div class="ChatCard__meta-value">
              <LLMetaIconInfo
                :text="$tc('common_amount_no_digits_members', chat.meta.memberCount)"
                :value="chat.meta.memberCount"
              >
                <ParticipantsIcon />
              </LLMetaIconInfo>
            </div>
            <div class="ChatCard__meta-value">
              <LLMetaIconInfo
                :text="$tc('common_amount_no_digits_threads', chat.meta.threadCount)"
                :value="chat.meta.threadCount"
              >
                <TopicRedIcon />
              </LLMetaIconInfo>
            </div>
          </div>
          <div class="ChatCardDescription">
            <span class="ChatCardDescription__title">{{ $t('dashboard.chat_card.description') }}</span>
            <span class="ChatCardDescription__value">
              <TextHider :lines="3" absolute-block>
                <LLWysiwygOutput :text="chat.description"></LLWysiwygOutput>
              </TextHider>
            </span>
          </div>
          <router-link tag="a" :to="chatLink" class="ChatCard__enter-link">
            <LLButton class="ChatCard__enter-button">{{ $t('dashboard.chat_card.enter_button') }}</LLButton>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ForumChatModel } from '@/models/forum/forumChat'
import LLMetaIconInfo from '@/components/atoms/LLMetaIconInfo'
import TextHider from '@/components/common/TextHider'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'
import LLButton from '@/components/common/ui-components/LLButton'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'

export default {
  name: 'ChatCard',
  components: { ImageLoader, LLButton, LLWysiwygOutput, TextHider, LLMetaIconInfo },
  props: {
    chat: { type: ForumChatModel, required: true }
  },
  computed: {
    chatLink() {
      return `/chats/${this.chat.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.ChatCard {
  @apply flex flex-col;
  &__card-head {
    @apply w-full px-4 py-2 font-bold text-2xl text-center font-serif rounded-tl rounded-tr bg-primary-01-400 text-white;
  }
  &__card-content {
    @apply px-5 pt-6 pb-7 mob:px-4 mob:pt-5 mob:pb-5 border-neutral-01-50 border rounded-bl rounded-br flex-1 flex flex-col;
  }
  &__title {
    @apply font-serif text-primary-01 font-bold leading-none break-words;
    font-size: 32px;
    @screen mob {
      font-size: 28px;
      line-height: 32px;
    }
  }
  &__body {
    @apply flex mob:flex-col mt-6 mob:mt-5 flex-1;
  }
  &__info {
    @apply flex-col flex items-start h-full;
  }
  &__image-wrapper {
    @apply flex-shrink-0 lg:mr-6 mob:mb-5 flex;
  }
  &__image {
    @apply w-48 h-48 object-cover mob:w-full mob:h-56;
  }
  &__meta {
    @apply flex flex-shrink-0;
    &-value {
      @apply ml-4;
      &:first-child {
        @apply ml-0;
      }
    }
  }
  &Description {
    @apply mt-4 flex-1;
    &__title {
      @apply text-body-02;
    }
    &__value {
      @apply text-body-03;
    }
  }
  &__enter-link {
    @apply mob:w-full;
  }
  &__enter-button {
    @apply mt-4 flex-shrink-0 mob:w-full;
  }
}
</style>
