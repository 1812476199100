import Network from '../network.js'
import store from '../../../store'

export default {
  /**
   * @param request
   * @param.query user input
   * @param.language - user language
   * @returns {Promise | Promise<unknown>}
   */
  placeSearch(request) {
    return Network.get('/system/place/search', request, true)
  },
  /**
   * @param request
   * @param.id place_id
   * @param.language user language
   * @returns {Promise | Promise<unknown>}
   */
  placeDetails(request) {
    return Network.get('/system/place/details', request, true)
  },
  /**
   * @param request
   * @param.error error
   */
  sendError({ message, stack, e }) {
    let request = {
      message: message,
      stack: stack,
      url: window.location.href,
      token: store.state?.auth?.token
    }
    if (e?.name === 'NetworkError' && e?.response?.data?.message) {
      request.message = 'Server message: ' + e?.response?.data?.message
    }
    Network.post('/system/log', request).catch(() => {
      console.error('Error while sending error')
    })
  }
}
