import { FileModel } from '@/models/file'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import { BaseModel } from '@/models/baseModel'
import { ForumReactionModel } from '@/models/forum/forumReaction'

export class ForumResponseModel extends BaseModel {
  constructor(responseData = {}) {
    super()
    const {
      id,
      text,
      files,
      user,
      prospect,
      createdAt,
      updatedAt,
      deletedAt,
      parentResponseId,
      replyToId,
      chatId,
      threadId,
      prospectId,
      userId,
      isAnonymous,
      anonymousState,
      repliesCount,
      reactions,
      replies,
      isRead,
      isOwnerModerator
    } = responseData || {}

    this.id = id || null
    this.text = text || ''
    this.files = Array.isArray(files) ? files.map((file) => new FileModel(file)) : []
    this.user = user ? new UserModel(user) : null
    this.prospect = prospect ? new ProspectModel(prospect) : null
    this.createdAt = createdAt || null
    this.updatedAt = updatedAt || null
    this.deletedAt = deletedAt || null

    this.chatId = chatId || null
    this.parentResponseId = parentResponseId || null
    this.replyToId = replyToId || null
    this.threadId = threadId || null
    this.prospectId = prospectId || null
    this.userId = userId || null
    this.isAnonymous = isAnonymous || false
    this.anonymousState = anonymousState || false
    this.repliesCount = repliesCount ?? null
    this.reactions = reactions?.map((reaction) => new ForumReactionModel(reaction)) || []
    this.replies = Array.isArray(replies) ? replies.map((reply) => new ForumResponseModel(reply)) : []
    this.isRead = isRead ?? true
    this.isOwnerModerator = isOwnerModerator ?? false
  }

  static parseFromApi({ response, chatId, threadId, user, prospect }) {
    return new ForumResponseModel({
      id: response?.id,
      text: response?.text,
      chatId,
      parentResponseId: response?.parentResponseId,
      replyToId: response?.replyToId,
      threadId,
      userId: user?.id || response?.userId || null,
      prospectId: prospect?.id || response?.prospectId || null,
      files:
        response?.files
          ?.map((file) => FileModel.parseFromApi({ file }))
          ?.sort((a, b) => a?.order - b?.order) || [],
      createdAt: response?.createdAt,
      updatedAt: response?.updatedAt,
      deletedAt: response?.deletedAt,
      user: user ? UserModel.parseFromApi({ user }) : null,
      prospect: prospect ? ProspectModel.parseFromApi({ prospect }) : null,
      isAnonymous: response?.isAnonymous,
      anonymousState: response?.anonymousState,
      repliesCount: response?.repliesCount ?? null,
      reactions: response?.reactions?.map((reaction) => ForumReactionModel.parseFromApi({ reaction })) || [],
      replies:
        response?.replies?.map((reply) =>
          ForumResponseModel.parseFromApi({ response: reply, chatId, threadId })
        ) || [],
      isRead: response?.isRead ?? true,
      isOwnerModerator: response?.isOwnerModerator
    })
  }

  convertToApi() {
    const { id, text, isAnonymous } = this
    return {
      id: id || undefined,
      text,
      parentResponseId: this.parentResponseId,
      replyToId: this.replyToId,
      fileIdList: this.files?.map((file) => file.id) || undefined,
      isAnonymous
    }
  }
}
