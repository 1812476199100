<template>
  <div class="ForumChatThreadCardHeaderAvatar">
    <div v-if="isOwnerVisible" class="ForumChatThreadCardHeaderAvatar__user">
      <ProspectAvatar v-if="prospect" small :prospect="prospect"></ProspectAvatar>
      <UserAvatar v-else-if="user" small :user="user"></UserAvatar>
    </div>
    <div v-else-if="isOwnerHidden" class="ForumChatThreadCardHeaderAvatar__anonymous">
      {{ anonymousReplyLetter }}
    </div>
    <LogoRound v-else-if="isOwnerModerator" class="ForumChatThreadCardHeaderAvatar__moderator" />
  </div>
</template>

<script>
import LogoRound from '@/assets/icons/LogoRound.vue'
import ProspectAvatar from '@/components/modules/prospects/ProspectAvatar.vue'
import UserAvatar from '@/components/modules/users/UserAvatar.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'ForumChatThreadCardHeaderAvatar',
  components: { UserAvatar, ProspectAvatar, LogoRound },
  props: {
    thread: { type: ForumThreadModel, required: true }
  },
  computed: {
    isOwnerVisible() {
      return !this.isOwnerHidden && !this.isOwnerModerator
    },
    isOwnerHidden() {
      return (
        (this.thread.anonymousState || this.hasNoOwner) &&
        this.thread.prospect?.id !== this.$store.getters['user/user']?.id &&
        !this.thread.isOwnerModerator
      )
    },
    isOwnerModerator() {
      return (
        (this.thread.anonymousState || this.hasNoOwner) &&
        this.thread.prospect?.id !== this.$store.getters['user/user']?.id &&
        this.thread.isOwnerModerator
      )
    },
    anonymousReplyLetter() {
      return this.$t('forum.response.anonymous_reply_user_name').slice(0, 1)
    },
    prospect() {
      return this.thread?.prospect
    },
    user() {
      return this.thread?.user
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeaderAvatar {
  &__user {
    @apply w-4 h-4 flex-shrink-0;
  }
  &__anonymous {
    @apply w-4 h-4 rounded-full object-cover bg-neutral-01-400 text-button-02 text-white flex items-center justify-center;
  }
  &__moderator {
    @apply w-4 h-4 rounded-full text-caption-01 text-white flex items-center justify-center;
  }
}
</style>
