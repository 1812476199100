<template>
  <div class="StarBlock cursor-pointer" @mouseover="mouseOver" @mouseleave="mouseLeave" @click="click">
    <div class="relative">
      <div class="relative">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99993 0.655457C10.2664 0.655457 10.5097 0.806707 10.6276 1.04562L13.2738 6.40648L19.1914 7.27143C19.455 7.30996 19.6739 7.49476 19.7561 7.74817C19.8382 8.00157 19.7694 8.27965 19.5786 8.46552L15.2972 12.6356L16.3076 18.5268C16.3527 18.7894 16.2447 19.0548 16.0291 19.2115C15.8135 19.3681 15.5277 19.3887 15.2919 19.2647L9.99993 16.4817L4.70797 19.2647C4.47213 19.3887 4.18632 19.3681 3.97074 19.2115C3.75515 19.0548 3.64718 18.7894 3.69223 18.5268L4.70264 12.6356L0.421263 8.46552C0.230431 8.27965 0.16164 8.00157 0.243804 7.74817C0.325968 7.49476 0.544844 7.30996 0.808436 7.27143L6.72606 6.40648L9.37224 1.04562C9.49016 0.806707 9.7335 0.655457 9.99993 0.655457ZM9.99993 2.93693L7.81874 7.35579C7.71686 7.56218 7.52003 7.70531 7.29228 7.73859L2.41356 8.45169L5.94322 11.8896C6.10833 12.0504 6.18369 12.2822 6.14473 12.5094L5.31188 17.3653L9.67411 15.0712C9.87808 14.964 10.1218 14.964 10.3257 15.0712L14.688 17.3653L13.8551 12.5094C13.8162 12.2822 13.8915 12.0504 14.0566 11.8896L17.5863 8.45169L12.7076 7.73859C12.4798 7.70531 12.283 7.56218 12.1811 7.35579L9.99993 2.93693Z"
            fill="#7990A2"
          />
        </svg>
      </div>
      <div
        :class="{ 'star-fill_visible': filled || currentFilled }"
        class="star-fill opacity-0 absolute top-px left-px"
      >
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 0C9.30005 0 9.57408 0.1684 9.70689 0.434402L12.0611 5.14953L17.3257 5.91029C17.6225 5.95319 17.869 6.15895 17.9615 6.44109C18.0541 6.72322 17.9766 7.03283 17.7617 7.23978L13.9529 10.9074L14.8518 16.0889C14.9025 16.3813 14.7809 16.6768 14.5381 16.8512C14.2953 17.0255 13.9735 17.0485 13.7079 16.9104L9 14.4627L4.29212 16.9104C4.02652 17.0485 3.70466 17.0255 3.46187 16.8512C3.21909 16.6768 3.09749 16.3813 3.14822 16.0889L4.0471 10.9074L0.238306 7.23978C0.0233959 7.03283 -0.0540744 6.72322 0.0384566 6.44109C0.130988 6.15895 0.377479 5.95319 0.674329 5.91029L5.93895 5.14953L8.29311 0.434402C8.42592 0.1684 8.69995 0 9 0Z"
            fill="#1F4E6E"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StarBlock',
  props: {
    filled: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentFilled: false
    }
  },
  methods: {
    mouseOver() {
      if (this.locked) return
      this.currentFilled = true
      this.$emit('over')
    },
    mouseLeave() {
      if (this.locked) return
      this.currentFilled = false
      this.$emit('leave')
    },
    click() {
      if (this.locked) return
      this.$emit('set')
    }
  }
}
</script>

<style lang="scss" scoped>
.StarBlock {
  .star-fill {
    @apply transition transition-opacity transition duration-200;
    &.star-fill_visible {
      @apply opacity-100;
    }
  }
}
</style>
