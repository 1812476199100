<template>
  <svg
    class="StrokeArrowIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.417 5.38672L5.63574 11.1836C5.45215 11.3672 5.45215 11.6641 5.64355 11.8477L6.40918 12.6133C6.59277 12.7969 6.89355 12.793 7.07715 12.6055L10.7373 8.8125V18.5312C10.7373 18.7891 10.9482 19 11.2061 19H12.2998C12.5576 19 12.7686 18.7891 12.7686 18.5312V8.8125L16.4287 12.6055C16.6084 12.7969 16.9092 12.8008 17.0967 12.6133L17.8623 11.8477C18.0459 11.6641 18.0459 11.3672 17.8623 11.1836L12.0811 5.38672C11.8975 5.20312 11.6006 5.20312 11.417 5.38672Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'StrokeArrowIcon',
  props: {
    left: { type: Boolean, default: true },
    right: { type: Boolean, default: true },
    top: { type: Boolean, default: true },
    bottom: { type: Boolean, default: true }
  },
  computed: {
    classList() {
      const classes = []
      const rootClass = 'StrokeArrowIcon'
      if (this.left) {
        classes.push(`${rootClass}_left`)
      }
      if (this.right) {
        classes.push(`${rootClass}_right`)
      }
      if (this.top) {
        classes.push(`${rootClass}_top`)
      }
      if (this.bottom) {
        classes.push(`${rootClass}_bottom`)
      }
      return classes
    }
  }
}
</script>

<style scoped lang="scss">
.StrokeArrowIcon {
  @apply fill-current;
  &_left {
    @apply transform rotate-180;
  }
  &_right {
    @apply transform rotate-0;
  }
  &_top {
    @apply transform -rotate-90;
  }
  &_bottom {
    @apply transform rotate-90;
  }
}
</style>
