/* eslint-disable camelcase */

/**
 * See: https://logaretm.github.io/vee-validate/guide/rules.html#rules
 * TODO: i18n
 */
import i18n from '@/i18n'

import { extend } from 'vee-validate'
import { required, regex, email, min, max, min_value, max_value, excluded } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: () => i18n.t('vee-validate_error_required')
})

extend('not-duplicated', {
  ...excluded,
  message: i18n.t('vee-validate_values-must-be-different')
})

extend('email', {
  ...email,
  message: i18n.t('vee-validate_error_email')
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('vee-validate_error_password-match')
})

extend('min', {
  ...min,
  message: i18n.t('vee-validate_error_min')
})

extend('max', {
  ...max,
  message: i18n.t('vee-validate_error_max')
})

extend('min_value', {
  ...min_value,
  message: i18n.t('vee-validate_error_min-value')
})

extend('max_value', {
  ...max_value,
  message: i18n.t('vee-validate_error_max-value')
})
extend('normal-regex', {
  ...regex,
  message: i18n.t('vee-validate_error_regex')
})
