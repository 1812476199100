<template>
  <div class="QuestionsStars flex">
    <StarBlock
      v-for="i in amount"
      :key="i"
      :locked="locked"
      :filled="
        (currentStarsValue >= i || tempStarsValue >= i) && (i <= tempStarsValue || tempStarsValue === 0)
      "
      data-e2e="questionStarBlockBtn"
      @over="setTempStars(i)"
      @leave="setTempStars(0)"
      @set="setCurrentStars(i)"
    >
    </StarBlock>
  </div>
</template>

<script>
import StarBlock from '@/pages/Conversation/Question/QuestionAnswer/QuestionStars/StarBlock'
export default {
  name: 'QuestionsStars',
  components: { StarBlock },
  props: {
    value: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 5
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStarsValue: 3,
      tempStarsValue: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.currentStarsValue = this.value
      }
    }
  },
  methods: {
    setTempStars(value) {
      this.tempStarsValue = value
    },
    setCurrentStars(value) {
      this.currentStarsValue = value
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.QuestionsStars {
}
</style>
