<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M14.5 17L9.5 12L14.5 7" stroke="black" stroke-width="2" />
      <path
        d="M12 0.5C5.64873 0.499999 0.5 5.64872 0.499999 12C0.499999 18.3513 5.64872 23.5 12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.500001 12 0.5Z"
        stroke="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeft'
}
</script>

<style lang="scss" scoped>
.ArrowLeft {
}
</style>
