<template>
  <div class="ForumChatThreadCardHeaderMeta">
    <ForumChatThreadCardHeaderProfile :thread="thread" :dark="dark" />
    <ForumChatThreadCardHeaderTimestamp
      class="ForumChatThreadCardHeaderMeta__timestamp"
      :thread="thread"
      :compact="compact"
      :mobile="mobile"
      :dark="dark"
    />
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatThreadCardHeaderProfile from '@/components/modules/forum-chat/new-thread-card/header/ForumChatThreadCardHeaderProfile.vue'
import ForumChatThreadCardHeaderTimestamp from '@/components/modules/forum-chat/new-thread-card/header/ForumChatThreadCardHeaderTimestamp.vue'

export default {
  name: 'ForumChatThreadCardHeaderMeta',
  components: { ForumChatThreadCardHeaderTimestamp, ForumChatThreadCardHeaderProfile },
  props: {
    thread: { type: ForumThreadModel, required: true },
    dark: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeaderMeta {
  @apply flex items-end justify-between w-full flex-row;
  &__timestamp {
    @apply flex-shrink-0 ml-2;
  }
}
</style>
