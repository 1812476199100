<template>
  <div class="ForumReactionsItem">
    <LLPopper
      :offset="[0, 8]"
      placement="top"
      trigger="none"
      :show="isHovered && (!isAnimatedReaction || !showAnimation)"
      tooltip-dark
      tooltip-with-arrow
    >
      <template #trigger>
        <button
          ref="reaction-button"
          :disabled="disabled"
          class="ForumReactionsItemReaction"
          :class="{ ForumReactionsItemReaction_own: reaction.isOwnReaction }"
          @click="onSelectReaction(reaction)"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          @touchstart="onTouchStart"
          @touchend="onTouchEnd"
        >
          <span
            v-if="!isAnimatedReaction"
            class="ForumReactionsItemReaction__emoji"
            v-html="reaction.emojiHtml"
          ></span>
          <span v-else class="ForumReactionsItemReaction__emoji">
            <img class="ForumReactionsItemReaction__emoji" :src="getAnimatedReactionUrl" />
          </span>
          <span class="ForumReactionsItemReaction__count"> {{ reaction.count }}</span>
        </button>
      </template>
      <template #tooltip>
        <div class="ForumReactionsItem__tooltip">
          <div
            v-if="!isAnimatedReaction"
            class="ForumReactionsItem__tooltip-emoji"
            v-html="reactionTooltipEmoji"
          ></div>
          <div v-else class="ForumReactionsItem__tooltip-emoji">
            <img :src="getAnimatedReactionUrl" />
          </div>
          <div class="ForumReactionsItem__tooltip-text">
            <i18n :path="`${reactionTooltipText}.text`" tag="p">
              <template #bold>
                <b>{{ $t(`${reactionTooltipText}.bold`, reactionTooltipData) }}</b>
              </template>
              <template #emoji>{{ reactionTooltipEmojiName }}</template>
            </i18n>
          </div>
        </div>
      </template>
    </LLPopper>
    <InfiniteScrollObserver @intersect="onReactionIntersect"></InfiniteScrollObserver>
    <ReactionAnimation
      v-if="isAnimationActive"
      ref="animation"
      :animation-name="animationName"
      @complete="onAnimationComplete"
    ></ReactionAnimation>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import LLPopper from '@/components/utils/LLPopper.vue'
import emojiToHtml from '@/utils/emojiToHtml'
import { ReactionsEnums } from '@/common/reactionsEnums'
import emojiList from '@/common/emojiList'
import { screenCatcher } from '@/mixins/screenCatcher'
import ReactionAnimation from '@/components/common/ReactionAnimation.vue'
import InfiniteScrollObserver from '@/components/utils/InfiniteScrollObserver.vue'
import reactionAnimationStack from '@/utils/modules/forum/reactionAnimationStack'

export default {
  name: 'ForumReactionsItem',
  components: { InfiniteScrollObserver, ReactionAnimation, LLPopper },
  mixins: [screenCatcher],
  props: {
    reaction: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    showAnimation: { type: Boolean, default: false },
    disableReactanimation: { type: Boolean, default: false }
  },
  data() {
    return {
      isHovered: false,
      isTouched: false,
      isAnimationActive: false,
      onStartHover: null,
      isAnimationDeactivatedByDelay: false
    }
  },
  computed: {
    isAnimatedReaction() {
      return !!this.animatedReactionKey
    },
    /**
     *
     * @param {ForumReactionModel} reaction
     */
    reactionTooltipText() {
      const modifiers = []
      const membersLength = this.reaction?.members?.length > 2 ? 2 : this.reaction?.members?.length
      const remaining = this.reaction?.count - (this.reaction?.isOwnReaction ? 1 : 0) - membersLength
      if (this.reaction?.isOwnReaction) {
        modifiers.push('you')
      }
      if (membersLength) {
        modifiers.push(membersLength)
      }
      if (remaining) {
        modifiers.push('more')
      }
      return `forum.response.reactions.reaction-tooltip_${modifiers.join('-')}`
    },
    reactionTooltipEmojiName() {
      const emojiAlias = this.emojiListMapped.find((emoji) => emoji.emoji === this.reaction.emoji)
        ?.aliases?.[0]
      return emojiAlias || this.reaction?.emoji
    },
    reactionTooltipData() {
      const membersLength = this.reaction?.members?.length > 2 ? 2 : this.reaction?.members?.length
      const remaining = this.reaction?.count - (this.reaction?.isOwnReaction ? 1 : 0) - membersLength

      return {
        count: remaining,
        user1: this.reaction?.members?.[0],
        user2: this.reaction?.members?.[1]
      }
    },
    reactionTooltipEmoji() {
      return emojiToHtml.img(this.reaction.emoji)
    },

    reactionsEnums() {
      return ReactionsEnums
    },
    animationName() {
      return this.reactionsEnums[this.animatedReactionKey]
    },
    getAnimatedReactionUrl() {
      return require(`@/assets/lottie-reactions/preview/${this.animationName}.svg`)
    },
    animatedReactionKey() {
      return Object.keys(this.reactionsEnums).find(
        (reactionKey) => this.reactionsEnums[reactionKey] === this.reaction?.emoji
      )
    },
    emojiListMapped() {
      return emojiList.reduce(
        (acc, category) => [
          ...acc,
          ...category.emojiList.map((emoji) => ({ emoji: emoji.emoji, aliases: emoji.aliases }))
        ],
        []
      )
    }
  },
  watch: {
    isHovered(to) {
      if (to) {
        this.onCheckHoverAnimation()
      }
    }
  },
  mounted() {
    this.initOnStartHover()
  },
  methods: {
    onAnimationComplete() {
      this.isAnimationActive = false
      reactionAnimationStack.animationFinished()
      if (this.reaction.isViewed === false) {
        this.$emit('animationComplete')
      }
      this.isAnimationDeactivatedByDelay = true
      setTimeout(() => {
        this.isAnimationDeactivatedByDelay = false
      }, 1000)
    },
    onReactionIntersect() {
      if (
        !this.disableReactanimation &&
        this.isAnimatedReaction &&
        this.showAnimation &&
        this.reaction.isViewed === false
      ) {
        reactionAnimationStack.push(this.onCheckHoverAnimation)
      }
    },
    onCheckHoverAnimation() {
      /* const smallElement = this.$refs['reaction-button']
      const coordinates = smallElement.getBoundingClientRect() */

      if (this.isAnimatedReaction && this.showAnimation && !this.isAnimationDeactivatedByDelay) {
        this.isAnimationActive = true
        this.$nextTick(() => {
          this.$refs.animation.start({
            element: this.$refs['reaction-button']
            /* startPoint: {
              top: coordinates.top + coordinates.height / 2,
              left: coordinates.left + coordinates.width / 2
            } */
          })
        })
      }
    },
    initOnStartHover() {
      this.onStartHover = _debounce(function () {
        this.isHovered = true
      }, 1000)
    },
    onMouseEnter() {
      if (this.isTouched) {
        return
      }
      this.onStartHover()
    },
    onMouseLeave() {
      if (this.onStartHover?.cancel) {
        this.onStartHover.cancel()
      }
      this.isHovered = false
    },
    onTouchStart() {
      console.log('touch to')
      this.isTouched = true
      this.onStartHover()
    },
    onTouchEnd() {
      console.log('touch from')
      setTimeout(() => {
        this.isTouched = false
      }, 1000)
      if (this.onStartHover?.cancel) {
        this.onStartHover.cancel()
      }
      this.isHovered = false
    },

    onSelectReaction() {
      this.isHovered = false
      if (this.onStartHover?.cancel) {
        this.onStartHover.cancel()
      }
      this.$emit('select', this.reaction)
    }
  }
}
</script>

<style scoped lang="scss">
.ForumReactionsItemReaction {
  @apply flex items-center justify-between rounded bg-neutral-01-25 transition duration-200 cursor-pointer relative outline-none select-none;
  min-width: 44px;
  height: 24px;

  &:disabled,
  &[disabled] {
    @apply cursor-default pointer-events-none;
  }

  &:focus-visible {
    @apply outline-none bg-neutral-01-50;
  }

  &:hover {
    @apply bg-neutral-01-50;
  }

  &:active {
    @apply bg-neutral-01-75;
  }

  &_own {
    @apply bg-primary-01-25;
    &:before {
      @apply w-full h-full absolute border border-primary-01-200 rounded;
      content: '';
    }

    &:hover {
      @apply bg-white;
    }

    &:focus-visible {
      @apply bg-white;
    }

    &:active {
      @apply bg-primary-01-50;
    }
  }

  &__count {
    @apply ml-1 text-button-02 text-primary-01-400 mr-1.5;
    min-width: 6px;
  }

  &__emoji {
    @apply inline-flex w-5 justify-center items-center h-full ml-1;
    ::v-deep & > span {
      @apply flex h-full items-center;
    }
  }
}
.ForumReactionsItem {
  &__tooltip {
    @apply flex flex-col items-center;
    &-emoji {
      ::v-deep img {
        @apply w-8 h-8;
      }
    }
    &-text {
      max-width: 190px;
      @apply text-center text-neutral-01-50 mt-1 break-words;
      b {
        @apply text-white;
      }
    }
  }
}
</style>
