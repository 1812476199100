<template>
  <div v-if="reactions.length" class="ForumReactions">
    <transition-group
      v-if="reactions.length"
      class="ForumReactions__transition"
      :name="animationReady ? 'flip-list' : null"
      tag="div"
    >
      <div v-for="reaction in reactions" :key="reaction.emoji" class="ForumReactions__reaction-outer">
        <ForumReactionsItem
          :show-animation="showAnimation"
          :reaction="reaction"
          :disabled="disabled"
          :disable-reactanimation="disableReactanimation"
          @select="onSelectReaction"
          @animationComplete="onAnimationComplete(reaction)"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import ForumReactionsItem from '@/components/pages/forum/reactions/ForumReactionsItem.vue'
export default {
  name: 'ForumReactions',
  components: { ForumReactionsItem },
  props: {
    reactions: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    showAnimation: { type: Boolean, default: false },
    disableReactanimation: { type: Boolean, default: false }
  },
  data() {
    return {
      isAnimationActive: false,
      animationReady: false
    }
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.animationReady = true
    }, 300)
  },
  methods: {
    onSelectReaction(reaction) {
      this.$emit('select', reaction)
    },
    onAnimationComplete(reaction) {
      this.$emit('viewReaction', reaction)
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumReactions {
  @apply overflow-hidden;
  &__transition {
    @apply flex flex-wrap -mt-2;
  }
  &__reaction-outer {
    @apply mr-2 mt-2;
  }
  .flip-list-move {
    transition: transform 0.3s;
  }
}
</style>
