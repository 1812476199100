<template>
  <div class="ForumChatThreadList">
    <div
      v-if="threadList.length"
      class="ForumChatThreadList__list"
      :class="{ ForumChatThreadList__list_fetching: isFetching && !silentFetching }"
    >
      <ForumChatThreadCard
        v-for="thread in threadList"
        :key="thread.id"
        :thread="thread"
        :chat="chat"
        :is-reaction-animations-enabled="false"
        class="ForumChatThreadList__thread"
        @update="onThreadUpdate"
        @remove="fetchThreads"
      />
    </div>
    <div v-else-if="isFetching" class="ForumChatThreadList__loading">
      <LLLoader>{{ $t('forum.chat_page.threads.loader') }}</LLLoader>
    </div>
    <LLLoaderError
      v-else-if="isError"
      class="ForumChatThreadList__error"
      :has-cancel="false"
      @retry="onRetry"
    >
      {{ $t('forum.chat_page.threads.fetch_error') }}
    </LLLoaderError>
    <div v-else class="ForumChatThreadList__empty">
      {{ $tc('common_amount_threads', 0) }}
    </div>
    <div v-if="!isFetching && total > perPage && !isError" class="ForumChatThreadList__pagination-container">
      <LLPagination
        v-if="!isFetching"
        :per-page="perPage"
        :total="total"
        :current="page"
        @goToPage="changePage"
      ></LLPagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ForumChatModel } from '@/models/forum/forumChat'
import LLLoader from '@/components/common/LLLoader'
import LLLoaderError from '@/components/common/LLLoaderError'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLPagination from '@/components/common/LLPagination'
import { ForumThreadState } from '@/common/enums'
import chatEditor from '@/utils/modules/forum/chatEditor'
import ForumChatThreadCard from '@/components/modules/forum-chat/ForumChatThreadCard.vue'
const CancelToken = axios.CancelToken

export default {
  name: 'ForumChatThreadList',
  components: { ForumChatThreadCard, LLPagination, LLLoaderError, LLLoader },
  props: {
    chat: { type: ForumChatModel, default: null },
    searchQuery: { type: String, default: '' },
    state: { type: Number, default: ForumThreadState.ACTIVE },
    sort: { type: String, required: true }
  },
  data() {
    return {
      page: 1,
      threadList: [],
      total: 0,
      isFetching: true,
      perPage: 10,
      isError: false,
      paginationFinished: false,
      cancelTokenSource: null,
      silentFetching: false
    }
  },
  watch: {
    searchQuery: {
      handler() {
        this.resetPagination()
        this.fetchThreads()
      }
    },
    sort: {
      handler() {
        this.resetPagination()
        this.fetchThreads()
      }
    },
    state: {
      handler() {
        this.resetPagination()
        this.fetchThreads()
      }
    }
  },
  mounted() {
    this.fetchThreads()
    this.$nextTick(() => {
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_CREATE, this.createThreadFromSocket)
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_UPDATE, this.updateThreadFromSocket)
      chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_META_UPDATE, this.updateThreadMetaFromSocket)
      // chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_DELETE, this.deleteThreadFromSocket)
    })
  },
  beforeDestroy() {
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_CREATE, this.createThreadFromSocket)
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_UPDATE, this.updateThreadFromSocket)
    chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_META_UPDATE, this.updateThreadMetaFromSocket)
    // chatEditor.removeEvent(chatEditor.enums.CHAT_THREAD_DELETE, this.deleteThreadFromSocket)
  },
  methods: {
    onThreadUpdate({ thread }) {
      const oldThread = this.threadList.find((_thread) => _thread.id === thread.id)
      if (oldThread?.state !== thread?.state || oldThread?.isPinned !== thread?.isPinned) {
        this.threadList.splice(this.threadList.indexOf(oldThread), 1, new ForumThreadModel(thread))
        this.fetchThreads()
      } else {
        this.threadList.splice(this.threadList.indexOf(oldThread), 1, new ForumThreadModel(thread))
      }
    },
    createThreadFromSocket() {
      if (this.page === 1) {
        this.fetchSilent()
      }
    },
    deleteThreadFromSocket({ threadId }) {
      if (this.threadList.find((thread) => thread.id === threadId)) {
        this.fetchSilent()
      }
    },
    updateThreadFromSocket({ thread }) {
      this.onThreadUpdate({ thread })
      /* this.threadList = this.threadList.map((oldThread) =>
        thread.id !== oldThread.id ? oldThread : new ForumThreadModel(thread)
      ) */
    },
    updateThreadMetaFromSocket({ threadId, meta }) {
      const oldThread = this.threadList.find((_thread) => _thread.id === threadId)
      if (oldThread) {
        oldThread.setMetaFromApi({ meta })
      }
    },
    fetchSilent() {
      if (this.isFetching) {
        return
      }
      this.silentFetching = true
      this.fetchThreads()
    },
    resetPagination() {
      this.threadList = []
      this.paginationFinished = false
      this.page = 1
    },
    onRetry() {
      this.resetPagination()
      this.fetchThreads()
    },
    changePage(page) {
      this.resetPagination()
      this.page = page
      this.fetchThreads()
    },
    async fetchThreads() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.cancelTokenSource = CancelToken.source()

      const page = this.page
      this.isError = false
      this.total = 0

      try {
        this.isFetching = true
        const { threads, totalRecords } = await this.$api.forum.threads.getAll({
          chatId: this.chat.id,
          page,
          cancelToken: this.cancelTokenSource.token,
          state: this.state,
          perPage: this.perPage,
          sort: this.sort,
          searchQuery: this.searchQuery,
          teamId: this.selectedTeam?.id
        })
        if (!threads?.length) {
          this.paginationFinished = true
        }
        this.total = totalRecords
        this.threadList = [
          ...threads.map((thread) => ForumThreadModel.parseFromApi({ thread, chatId: this.chat.id }))
        ]
        this.isFetching = false
        this.silentFetching = false
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e)
          this.isError = true
          this.$toast.error(this.$getErrorMessage(e))
          this.isFetching = false
          this.silentFetching = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatThreadList {
  &__list {
    @apply transition duration-200;
    &_fetching {
      @apply opacity-60 pointer-events-none select-none;
    }
  }
  &__thread {
    @apply mt-4;
  }
  &__empty {
    @apply text-body-02 text-neutral-01-400 text-center py-20;
  }
  &__loading {
    @apply py-20;
  }
  &__error {
    @apply py-20;
  }
  &__pagination-container {
    @apply mt-6 flex justify-center;
  }
}
</style>
