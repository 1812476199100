<template>
  <div class="PollChoiceListOptions">
    <div class="PollChoiceListOptions__title">{{ $t('forum.poll.editor.options.title') }}</div>
    <div class="PollChoiceListOptions__checkbox-list">
      <div
        v-if="localPoll.type === PollTypeEnum.MATRIX || localPoll.type === PollTypeEnum.MULTIPLE_CHOICE"
        class="PollChoiceListOptions__item-wrapper"
      >
        <LLCheckbox v-model="localPoll.isAnswerLimitEnabled" items-start :disabled="isPollCreated">
          <div class="PollChoiceListOptionsLimit">
            <template v-if="localPoll.type !== PollTypeEnum.MATRIX">
              {{ $t('forum.poll.editor.options.limit') }}
            </template>
            <template v-if="localPoll.type === PollTypeEnum.MATRIX">
              {{ $t('forum.poll.editor.options.limit_matrix') }}
            </template>
            <div class="PollChoiceListOptionsLimit__input-wrapper">
              <ValidationProvider
                ref="limit-validation-provider"
                v-slot="{ errors }"
                tag="div"
                name="max-limit"
                :rules="limitRule"
              >
                <LLInputSelect
                  v-model="localPoll.answerLimitAmount"
                  class="PollChoiceListOptionsLimit__input"
                  small
                  :errors="errors"
                  pattern="\d*"
                  inputmode="numeric"
                  :values="limitValues"
                  :disabled="!localPoll.isAnswerLimitEnabled || isPollCreated"
                  :filter-mask="{ mask: '9{1,2}', autoUnmask: true }"
                  filterable
                  show-error-in-tooltip
                  @click.native.prevent
                  @blur="onLimitBlur"
                >
                </LLInputSelect>
              </ValidationProvider>
            </div>
          </div>
        </LLCheckbox>
      </div>
      <div
        v-if="localPoll.type === PollTypeEnum.RANKED_LIST || localPoll.type === PollTypeEnum.MULTIPLE_CHOICE"
        class="PollChoiceListOptions__item-wrapper"
      >
        <LLCheckbox v-model="localPoll.hasRandomRowsOrder" items-start>{{
          $t('forum.poll.editor.options.randomize')
        }}</LLCheckbox>
      </div>
      <LLPopper
        v-if="
          localPoll.type === PollTypeEnum.RANKED_LIST ||
          localPoll.type === PollTypeEnum.MULTIPLE_CHOICE ||
          localPoll.type === PollTypeEnum.MATRIX
        "
        :offset="[5, 0]"
        placement="top-start"
        :disabled="!hasChoiceOptionsWithoutImage"
        trigger="hover"
        tooltip-dark
        tooltip-with-arrow
        class="PollChoiceListOptions__item-wrapper"
      >
        <template #trigger>
          <LLCheckbox v-model="localPoll.isLabelsHidden" items-start :disabled="hasChoiceOptionsWithoutImage">
            {{ $t('forum.poll.editor.options.hide_labels') }}
          </LLCheckbox>
        </template>
        <template #tooltip>
          <span>{{ $t('forum.poll.editor.options.hide_labels_warning') }}</span>
        </template>
      </LLPopper>
      <LLPopper
        v-if="
          localPoll.type === PollTypeEnum.RANKED_LIST ||
          localPoll.type === PollTypeEnum.MULTIPLE_CHOICE ||
          localPoll.type === PollTypeEnum.MATRIX
        "
        :offset="[5, 0]"
        placement="top-start"
        :disabled="hasImageAttachedToChoiceOptions"
        trigger="hover"
        tooltip-dark
        tooltip-with-arrow
        class="PollChoiceListOptions__item-wrapper"
      >
        <template #trigger>
          <LLCheckbox
            v-model="localPoll.isThumbnailsLarge"
            items-start
            :disabled="!hasImageAttachedToChoiceOptions"
          >
            {{ $t('forum.poll.editor.options.large_thumbnails') }}
          </LLCheckbox>
        </template>
        <template #tooltip>
          <span>{{ $t('forum.poll.editor.options.large_thumbnails_warning') }}</span>
        </template>
      </LLPopper>
      <div v-if="localPoll.type === PollTypeEnum.MATRIX" class="PollChoiceListOptions__item-wrapper">
        <LLCheckbox v-model="localPoll.hasRandomRowsOrder" items-start>{{
          $t('forum.poll.editor.options.randomize_martix_row')
        }}</LLCheckbox>
      </div>
      <div v-if="localPoll.type === PollTypeEnum.MATRIX" class="PollChoiceListOptions__item-wrapper">
        <LLCheckbox v-model="localPoll.hasRandomColumnsOrder" items-start>{{
          $t('forum.poll.editor.options.randomize_martix_column')
        }}</LLCheckbox>
      </div>
      <div class="PollChoiceListOptions__item-wrapper">
        <LLCheckbox v-model="localPoll.isRequiredResponse" items-start>{{
          $t('forum.poll.editor.options.require_vote_to_respond')
        }}</LLCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { PollModel } from '@/models/poll'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'
import { PollType } from '@/common/enums'
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'
import LLPopper from '@/components/utils/LLPopper.vue'

export default {
  name: 'PollChoiceListOptions',
  components: {
    LLPopper,
    LLInputSelect,
    LLCheckbox,
    ValidationProvider
  },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      localPoll: new PollModel()
    }
  },
  computed: {
    hasImageAttachedToChoiceOptions() {
      return !!this.localPoll.rows?.find((choiceOption) => choiceOption?.image)
    },
    hasChoiceOptionsWithoutImage() {
      return (
        !!this.localPoll.rows?.filter((choiceOption) => !choiceOption?.image)?.length ||
        !this.localPoll.rows?.length
      )
    },
    isPollCreated() {
      return !!this.poll?.id
    },
    limitValues() {
      const valuesArray = []
      for (let i = 1; i < this.maxLimit; i++) {
        valuesArray.push(i)
      }
      return valuesArray
    },
    PollTypeEnum() {
      return PollType
    },
    maxLimit() {
      return this.localPoll.type === PollType.MATRIX
        ? this.localPoll.columns?.length
        : this.localPoll.rows?.length
    },
    limitRule() {
      if (this.localPoll.isAnswerLimitEnabled) {
        return {
          required: true,
          max_value: this.maxLimit
        }
      }
      return {}
    }
  },
  watch: {
    maxLimit: {
      handler() {
        if (this.localPoll.answerLimitAmount && this.localPoll.answerLimitAmount >= this.maxLimit - 1) {
          if (this.maxLimit) {
            this.localPoll.answerLimitAmount = this.maxLimit - 1
          } else {
            this.localPoll.answerLimitAmount = null
          }
        }
      },
      immediate: true
    },
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        if (!this.localPoll.isAnswerLimitEnabled) {
          this.localPoll.answerLimitAmount = null
          if (this.$refs['limit-validation-provider']) {
            this.$nextTick(() => {
              this.$refs['limit-validation-provider'].reset()
            })
          }
        }
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    },
    hasChoiceOptionsWithoutImage(to) {
      if (to) {
        this.disableIsLabelHiddenWithMessage()
      }
    },
    hasImageAttachedToChoiceOptions: {
      handler() {
        if (!this.hasImageAttachedToChoiceOptions) {
          this.disableIsLabelHiddenWithMessage()
          this.localPoll.isThumbnailsLarge = false
        }
      }
    }
  },
  methods: {
    disableIsLabelHiddenWithMessage() {
      if (this.localPoll.isLabelsHidden) {
        this.localPoll.isLabelsHidden = false
        this.$errorAlertOfText({
          text: this.$t('forum.poll.editor.options.hide_text_labels_disable_warning'),
          title: this.$t('toast.title.warning')
        })
      }
    },
    onLimitBlur() {
      this.$refs['limit-validation-provider'].validateSilent()
    }
  }
}
</script>

<style scoped lang="scss">
.PollChoiceListOptions {
  &Limit {
    @apply flex items-start;
    &__input-wrapper {
      height: 22px;
    }
    &__input {
      @apply ml-1 w-24 -mt-1;
    }
  }
  &__checkbox-list {
    @apply mt-2;
  }
  &__title {
    @apply text-body-02;
  }
  &__item-wrapper {
    @apply flex items-center mt-2;
    &:first-child {
      @apply mt-0;
    }
  }
}
</style>
