<template>
  <div class="LLModalConditional">
    <LLModal v-if="localOpened" :name="name" @closed="hideModal">
      <slot></slot>
    </LLModal>
  </div>
</template>

<script>
export default {
  name: 'LLModalConditional',
  props: {
    opened: { type: Boolean, default: false },
    name: { type: String, default: 'conditional-modal' }
  },
  data() {
    return {
      localOpened: false
    }
  },
  watch: {
    opened: {
      handler(to) {
        if (to && !this.localOpened) {
          this.showModal()
        } else if (!to && this.localOpened) {
          this.hideModal()
        }
      },
      immediate: true
    },
    localOpened: {
      handler() {
        this.$emit('update:opened', this.localOpened)
      }
    }
  },
  methods: {
    hideModal() {
      if (this.localOpened) {
        this.$nextTick(() => {
          this.$modal.hide(this.name)
        })
      }
      this.localOpened = false
    },
    showModal() {
      if (!this.localOpened) {
        this.$nextTick(() => {
          this.$modal.show(this.name)
        })
        this.localOpened = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLModalConditional {
}
</style>
