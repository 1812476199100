<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.14286 1.4C2.46088 1.4 1.9 1.96088 1.9 2.64286V9.44286C1.9 10.1293 2.45645 10.6857 3.14286 10.6857H8.97143C9.15708 10.6857 9.33513 10.7595 9.4664 10.8907L11.1857 12.6101V11.3857C11.1857 10.9991 11.4991 10.6857 11.8857 10.6857H12.8571C13.5436 10.6857 14.1 10.1293 14.1 9.44286V2.64286C14.1 1.95645 13.5436 1.4 12.8571 1.4H3.14286ZM0.5 2.64286C0.5 1.18769 1.68769 0 3.14286 0H12.8571C14.3168 0 15.5 1.18325 15.5 2.64286V9.44286C15.5 10.9025 14.3168 12.0857 12.8571 12.0857H12.5857V14.3C12.5857 14.5831 12.4152 14.8384 12.1536 14.9467C11.892 15.0551 11.5909 14.9952 11.3907 14.795L8.68148 12.0857H3.14286C1.68325 12.0857 0.5 10.9025 0.5 9.44286V2.64286Z"
      fill="#7990A2"
    />
  </svg>
</template>

<script>
export default {
  name: 'NewMessageFalseIcon'
}
</script>

<style lang="scss" scoped>
.NewMessageFalseIcon {
}
</style>
