<template>
  <div class="LLEmptyState">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LLEmptyState',
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.LLEmptyState {
  @apply px-4 py-4 w-full text-neutral-01-400 text-body-03 text-center flex items-center justify-center;
}
</style>
