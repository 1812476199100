import Network from '../network.js'

export default {
  fetch(request) {
    return Network.get('/studies', request, true)
  },
  get({ studyId }) {
    return Network.get(`/studies/${studyId}`, {}, true)
  },
  accept(request) {
    return Network.post('/studies/' + request.studyId + '/accept-invitation', request, true)
  },
  reject(request) {
    return Network.post('/studies/' + request.studyId + '/reject-invitation', request, true)
  },
  getConversationStream(request) {
    return Network.get('/studies/' + request.studyId + '/conversation-streams', null, true)
  },
  sendConversationStream(request) {
    return Network.post(
      '/studies/' + request.studyId + '/conversation-streams/' + request.questionId,
      request.message,
      true
    )
  },
  updateConversationStream(request) {
    const { studyId, questionId, messageId } = request
    return Network.put(
      '/studies/' + studyId + '/conversation-streams/' + questionId + '/' + messageId,
      request.message,
      true
    )
  },
  removeConversationStream(request) {
    const { studyId, questionId, messageId } = request
    return Network.delete(
      '/studies/' + studyId + '/conversation-streams/' + questionId + '/' + messageId,
      null,
      true
    )
  },
  getScreeningQuestionsReplies(request) {
    return Network.get('/studies/' + request.studyId + '/screening-question-replies', null, true)
  },
  sendScreeningQuestionsReplies(request) {
    return Network.post(
      '/studies/' + request.studyId + '/screening-question-replies/' + request.questionId,
      request.reply,
      true
    )
  },
  submit(request) {
    return Network.post('/studies/' + request.studyId + '/screening-question-replies/submit', {}, true)
  },
  upload(request, config) {
    return Network.post('/upload', request, true, 'multipart/form-data', config)
  },
  uploadFile(fileId, request, config) {
    return Network.post(`/upload/${fileId}`, request, true, 'multipart/form-data', config)
  },
  prepareUpload(request, config) {
    return Network.post('/upload/prepare', request, true, undefined, config)
  },
  getStudyTopicsQuestions({ topicId, studyId }) {
    return Network.get(`/studies/${studyId}/topics/${topicId}/questions`, null, true)
  },
  getStudyTopicsQuestion({ topicId, studyId, questionId }) {
    return Network.get(`/studies/${studyId}/topics/${topicId}/questions/${questionId}`, null, true)
  },
  getStudyMeta({ studyId }) {
    return Network.get(`/studies/${studyId}/meta`, null, true)
  }
}
