<template>
  <div v-if="scrolled" class="ScrollToTopButton cursor-pointer" @click="goToTop">
    <ScrollToTopIcon class=""></ScrollToTopIcon>
  </div>
</template>

<script>
import ScrollToTopIcon from '@/assets/icons/ScrollToTopIcon'
export default {
  name: 'ScrollToTopButton',
  components: { ScrollToTopIcon },
  data() {
    return {
      scrolled: false
    }
  },
  mounted() {
    this.scrollChecker()
    window.addEventListener('scroll', this.scrollChecker)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollChecker)
  },
  methods: {
    goToTop() {
      window.scrollTo(0, 0)
    },
    scrollChecker() {
      this.scrolled = window.scrollY > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.ScrollToTopButton {
  @apply fixed bg-neutral-01-15 rounded-full;
  right: 2.25rem;
  bottom: 2.25rem;
  width: 2.75rem;
  height: 2.75rem;
  @screen mob {
    right: 1rem;
  }
}
</style>
