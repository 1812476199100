export const getAge = (birthDate) => {
  return new Date().getUTCFullYear() - new Date(birthDate).getUTCFullYear()
}

export const getBirthYearsArray = () => {
  const currentYear = new Date().getUTCFullYear()
  const years = []
  const minimumAge = 13
  for (let i = currentYear - minimumAge; i > currentYear - 100 - minimumAge; i--) {
    years.push(i)
  }
  return years
}
