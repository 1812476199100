import Network from '../network.js'

export default {
  authGoogle(request) {
    return Network.post('/auth/login/google/', request)
  },
  login(request) {
    return Network.post('auth/login', request)
  },
  resetPassword(request) {
    return Network.post('prospects/self/password/send', request)
  },
  resetPasswordComplete(request) {
    return Network.post('prospects/self/password/reset', request)
  },
  checkResetPasswordToken(request) {
    return Network.post('prospects/self/password/check', request)
  },
  invite(request) {
    return Network.post('auth/invite', request)
  },
  info(request) {
    return Network.post('auth/invite/info', request)
  },
  infoLegacy(request) {
    return Network.post('auth/invite/info', request)
  },
  checkEmail(request) {
    return Network.post('auth/common/check-email', request)
  }
}
