const animationStack = []
let isAnimating = false

export default {
  push(callback) {
    if (!isAnimating) {
      isAnimating = true
      callback()
    } else {
      animationStack.push(callback)
    }
  },
  animationFinished() {
    if (animationStack.length) {
      animationStack[0]()
      animationStack.shift()
    } else {
      isAnimating = false
    }
  }
}
