<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="select-input"
    :class="{
      'with-prefix': $slots.prefix,
      'is-underlined': underlined
    }"
    tag="div"
    :rules="rules"
    :name="name"
    data-e2e="questionTitleInput"
  >
    <label v-if="caption" class="select-input__caption" @click.prevent="onCaptionClick">
      {{ caption }}
    </label>

    <div class="select-input__control">
      <div v-if="$slots.prefix" class="select-input__prefix">
        <slot name="prefix" />
      </div>

      <VSelect
        :value="value"
        :label="label"
        :options="options"
        :reduce="reduce"
        :searchable="searchable"
        :multiple="multiple"
        :disabled="disabled"
        :clearable="clearable"
        :placeholder="placeholder"
        :class="{ 'has-errors': parentError || errors[0] }"
        :components="{ Deselect, OpenIndicator }"
        append-to-body
        push-options
        @input="onInput"
      >
        <template #list-header>
          <slot name="list-header"></slot>
        </template>
        <template #search="{ attributes, events }">
          <input
            ref="input"
            class="vs__search"
            v-bind="attributes"
            v-on="events"
            @input="$emit('search', attributes.value)"
          />
        </template>
      </VSelect>
    </div>

    <div v-if="errors[0]" class="text-error-01 text-xs mt-2">{{ errors[0] }}</div>
    <div v-else-if="showOptionalLabel" class="text-neutral-01 text-xs mt-2">
      {{ $t('main_optional') }}
    </div>
  </ValidationProvider>
</template>

<script>
/** See: https://vue-select.org/api/props.html */

import { ValidationProvider } from 'vee-validate'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const openIconSvg = `<svg class="fill-current" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.43431 4.5657L2.56569 3.43433L6 6.86864L9.43431 3.43433L10.5657 4.5657L6 9.13138L1.43431 4.5657Z" /></svg>`

export default {
  name: 'LLSelectInput',
  components: { ValidationProvider, VSelect },
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    underLabel: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String, Object],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'label'
    },
    reduce: {
      type: Function,
      default: (option) => option
    },
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    underlined: {
      type: Boolean,
      default: false
    },
    showOptionalLabel: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    parentError: { type: Boolean, default: false }
  },
  data: () => ({
    Deselect: {
      render: (createElement) => createElement('span', '✖')
    },
    OpenIndicator: {
      render: (createElement) =>
        createElement('span', {
          class: { toggle: true },
          domProps: {
            innerHTML: openIconSvg
          }
        })
    }
  }),
  methods: {
    onCaptionClick() {
      this.$refs.input.focus()
    },
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-input {
  $root: &;

  &__caption {
    @apply inline-block mb-1 font-semibold;
  }

  &__control {
    @apply relative;
  }

  &__prefix {
    @apply absolute inset-y-0 left-0 px-3 flex items-center text-base text-neutral-01;
    /deep/ .icon {
      width: 1em;
      height: 1em;
    }
  }

  &.is-underlined {
    #{$root}__prefix {
      @apply pl-0;
    }
  }
}

/deep/ .vs {
  &__dropdown-toggle {
    @apply border-neutral-01-50;
    .has-errors & {
      @apply border-error-01;
    }
    .is-underlined & {
      @apply rounded-none;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    .with-prefix & {
      @apply pl-6;
    }
  }

  &__search {
    @apply px-0;
    min-height: 34px;
    &::placeholder {
      @apply text-neutral-01;
    }
    .is-underlined & {
      @apply px-0;
    }
  }

  &__actions {
    @apply text-neutral-01;
  }

  &__selected-options {
    @apply px-3;
    .is-underlined & {
      @apply px-0;
    }
  }

  &__selected {
    @apply p-0 mx-0 h-full;
  }
}
</style>
