<template>
  <div class="ForumPollResults" :class="{ ForumPollResults_pending: !firstInit && isPending }">
    <div v-if="isPending && firstInit" class="ForumPollResults__loading">
      <LLLoader
        ><slot>{{ $t('forum.poll.results.loading') }}</slot></LLLoader
      >
    </div>
    <div v-else-if="error" class="ForumPollResults__error">
      <LLLoaderError @retry="fetchResults"></LLLoaderError>
    </div>
    <div v-else class="ForumPollResults__content">
      <div class="ForumPollResults__main">
        <div
          v-if="slotsVisibility.average"
          class="ForumPollResults__average"
          :class="{ ForumPollResults__average_full: poll.type === PollType.NUMBER_ENTRY }"
        >
          <slot name="average"></slot>
        </div>
        <div v-if="slotsVisibility.chart" class="ForumPollResults__chart">
          <div class="ForumPollResults__chart-inner">
            <slot name="chart"></slot>
          </div>
        </div>
        <div v-if="false" class="ForumPollResults__filters">
          <slot name="filters"></slot>
        </div>
      </div>
      <div v-if="poll.isResponded" class="ForumPollResults__footer">
        <div class="ForumPollResults__footer-spoiler">
          <LLSpoilerWrapper :expanded.sync="isTableExpanded">
            <template #toggler="{ expanded }">
              <div class="ForumPollResultsTableSpoiler">
                <LLSpoilerTogglerCircleArrow type="borderless-small" :expanded="expanded">
                  <template #title>{{ $t('forum.poll.results.table.spoiler_title') }}</template>
                </LLSpoilerTogglerCircleArrow>
              </div>
            </template>
          </LLSpoilerWrapper>
        </div>
        <div v-if="false" class="ForumPollResults__export-wrapper">
          <LLButton class="ForumPollResults__export-button" tertiary small @click="exportChart">
            <template #icon-left> <CSVIcon /> </template>
            <template #default>Export Chart</template>
          </LLButton>
        </div>
      </div>
      <div v-if="isTableExpanded && poll.isResponded" class="ForumPollResults__table">
        <div class="ForumPollResults__table-content">
          <PollResultsTable
            :poll-responses="pollResponses"
            :thread="thread"
            :poll="poll"
            @updatePollResponse="fetchResults"
          />
        </div>
      </div>
    </div>
    <transition appear name="transition">
      <div v-if="isPending && !firstInit" class="ForumPollResults__overlay"></div>
    </transition>
  </div>
</template>

<script>
import { ForumPollModel } from '@/models/forum/forumPoll'
import LLLoader from '@/components/common/LLLoader.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'
import LLSpoilerWrapper from '@/components/common/ui-components/LLSpoilerWrapper.vue'
import LLSpoilerTogglerCircleArrow from '@/components/common/ui-components/LLSpoiler/Togglers/LLSpoilerTogglerCircleArrow.vue'
import PollResultsTable from '@/components/modules/forum-chat/poll/results/PollResultsTable.vue'
import { PollType } from '@/common/enums'
import _ from 'lodash'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'ForumPollResults',
  components: {
    PollResultsTable,
    LLSpoilerTogglerCircleArrow,
    LLSpoilerWrapper,
    LLLoaderError,
    LLLoader
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    filterType: { type: String, default: null },
    filterValues: { type: Array, default: null },
    poll: { type: ForumPollModel, required: true }
  },
  data() {
    return {
      isPending: true,
      error: null,
      firstInit: true,
      isTableExpanded: true,
      slotsVisibility: {
        average: false,
        chart: false,
        filters: false
      },
      pollResponses: []
    }
  },
  computed: {
    PollType() {
      return PollType
    }
  },
  watch: {
    filterType: {
      handler() {
        this.fetchResults()
      },
      deep: true
    },
    filterValues: {
      handler() {
        this.fetchResults()
      },
      deep: true
    }
  },
  mounted() {
    this.checkSlots()
    this.sendResultsRequest()
  },
  beforeUpdate() {
    this.checkSlots()
  },
  methods: {
    fetchResults() {
      if (this.isPending) {
        return
      }
      this.isPending = true
      this.sendResultsRequestDebounced()
    },
    sendResultsRequestDebounced: _.debounce(function (payload) {
      this.sendResultsRequest(payload)
    }, 50),
    async sendResultsRequest() {
      try {
        this.isPending = true
        const result = await this.$api.forum.poll.getResults({
          chatId: this.poll.chatId,
          threadId: this.poll.threadId,
          filter: this.filterType,
          filterValues: this.filterValues
        })
        this.pollResponses = result?.pollResponses || []
        this.error = null
        this.$emit('updateResult', { result })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.isPending = false
        this.firstInit = false
      }
    },
    checkSlots() {
      this.$nextTick(() => {
        this.slotsVisibility.average = !!this.$slots?.average?.[0]
        this.slotsVisibility.chart = !!this.$slots?.chart?.[0]
        this.slotsVisibility.filters = !!this.$slots?.filters?.[0]
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ForumPollResults {
  @apply relative;
  $root: &;

  &__loading {
    @apply py-6;
  }
  &__overlay {
    @apply w-full h-full bg-black absolute left-0 top-0 opacity-10;
    transition-duration: 0.2s;
  }

  &_pending {
    @apply pointer-events-none;
    #{$root}__error,
    #{$root}__main {
      filter: blur(2px);
    }
  }

  &__error {
    @apply transition duration-200 px-6 py-6;
  }

  &__main {
    @apply flex flex-col justify-center w-full items-stretch transition duration-200;
    @screen lg {
      @apply flex-row justify-around;
    }
  }

  &__average {
    @apply flex flex-col p-4 bg-neutral-01-15;
    @screen lg {
      @apply p-6;
    }
    &_full {
      @apply w-full;
    }
  }

  &__chart {
    min-height: 240px;
    min-width: 270px;
    @apply flex-1 p-4;
    &-inner {
      @apply w-full h-full flex items-start justify-center;
    }
  }

  &__filters {
    width: 100%;
    @screen lg {
      @apply border-l border-neutral-01-50;
      max-width: 250px;
    }
    @screen xl {
      max-width: 320px;
    }
  }

  &__table {
  }

  &__table-content {
    @apply rounded border-neutral-01-50 border-t;
  }

  &TableSpoiler {
    @apply w-full border-neutral-01-50 border-t rounded-b py-3 px-4;
  }

  .transition-enter-active {
    transition-property: opacity;
  }

  .transition-leave-active {
    transition-property: opacity;
  }

  .transition-enter,
  .transition-leave-to {
    opacity: 0;
  }
}
</style>
