<template>
  <div class="PollResultsTableColumn">
    <div v-if="isMultiple" class="PollResultsTableColumn__multiple-index-helper">
      <LLPopper tooltip-white trigger="hover" placement="top-end">
        <template #trigger>
          <div class="PollResultsTableColumn__multiple-index-wrapper">
            <div class="PollResultsTableColumn__multiple-index">
              {{ multipleRowIndex + 1 }}
            </div>
          </div>
        </template>
        <template #tooltip>
          <div class="PollResultsTableColumn__multiple-tooltip">
            <div class="PollResultsTableColumn__multiple-index">
              {{ multipleRowIndex + 1 }}
            </div>
            <div class="PollResultsTableColumn__multiple-text">
              {{ multipleText }}
            </div>
          </div>
        </template>
      </LLPopper>
    </div>
    <div v-else>
      {{ label }}
    </div>
  </div>
</template>

<script>
import LLPopper from '@/components/utils/LLPopper.vue'
import { PollModel } from '@/models/poll'

export default {
  name: 'PollResultsTableColumn',
  components: { LLPopper },
  props: {
    columnProps: { type: Object, default: null },
    poll: { type: PollModel, required: true }
  },
  computed: {
    multipleText() {
      return this.poll.rows.find((row) => row.id === this.columnProps.column?.multipleRowId)?.text
    },
    label() {
      return this.columnProps.column.label
    },
    field() {
      return this.columnProps.column.field
    },
    isMultiple() {
      return !!this.columnProps.column?.multipleRowId
    },
    multipleRowIndex() {
      return this.columnProps.column?.multipleRowIndex
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsTableColumn {
  &__multiple-index-wrapper {
    @apply flex w-full justify-center;
  }
  &__multiple-index-helper {
    @apply flex justify-center;
  }
  &__multiple-index {
    @apply flex items-center justify-center text-xs w-7 h-7 rounded-full font-bold text-primary-01-400 border;
  }
  &__multiple-text {
    @apply text-body-02 break-words ml-4 py-1;
    text-wrap: wrap;
    max-width: 200px;
  }
  &__multiple-tooltip {
    @apply flex items-start p-4;
  }
}
</style>
