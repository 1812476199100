<template>
  <div class="TermsOfServiceOtherLanguages">
    <h3>{{ $t('terms_of_service_introduction') }}</h3>
    <p>{{ $t('terms_of_service_introduction_desc') }}</p>
    <p class="font-bold">{{ $t('terms_of_service_introduction_notice') }}</p>
    <p>{{ $t('terms_of_service_introduction_offering') }}</p>

    <h3>{{ $t('terms_of_service_intellectual_property_title') }}</h3>
    <p>{{ $t('terms_of_service_intellectual_property_desc_1') }}</p>
    <p>{{ $t('terms_of_service_intellectual_property_desc_2') }}</p>
    <p>{{ $t('terms_of_service_intellectual_property_desc_3') }}</p>

    <h3>{{ $t('terms_of_service_user_content_title') }}</h3>
    <p>{{ $t('terms_of_service_user_content_desc_1') }}</p>
    <p>{{ $t('terms_of_service_user_content_desc_2') }}</p>
    <p>{{ $t('terms_of_service_user_content_desc_3') }}</p>
    <p>{{ $t('terms_of_service_user_content_desc_4') }}</p>
    <p>{{ $t('terms_of_service_user_content_desc_5') }}</p>

    <h3>{{ $t('terms_of_service_external_links_title') }}</h3>
    <p>{{ $t('terms_of_service_external_links_desc') }}</p>

    <h3>{{ $t('terms_of_service_no_framing_title') }}</h3>
    <p>{{ $t('terms_of_service_no_framing_desc') }}</p>
    <h3>{{ $t('terms_of_service_registration_title') }}</h3>
    <p>{{ $t('terms_of_service_registration_desc_1') }}</p>
    <p>{{ $t('terms_of_service_registration_desc_2') }}</p>
    <p>{{ $t('terms_of_service_registration_desc_3') }}</p>

    <h3>{{ $t('terms_of_service_user_conduct_title') }}</h3>
    <p>{{ $t('terms_of_service_user_conduct_desc_1') }}</p>

    <ul>
      <li>{{ $t('terms_of_service_user_conduct_list_1') }}</li>
      <li>{{ $t('terms_of_service_user_conduct_list_2') }}</li>
      <li>{{ $t('terms_of_service_user_conduct_list_3') }}</li>
      <li>{{ $t('terms_of_service_user_conduct_list_4') }}</li>
      <li>{{ $t('terms_of_service_user_conduct_list_5') }}</li>
    </ul>
    <p>{{ $t('terms_of_service_user_conduct_desc_2') }}</p>
    <p>{{ $t('terms_of_service_user_conduct_desc_3') }}</p>

    <h3>{{ $t('terms_of_service_age_limitation_title') }}</h3>
    <p>{{ $t('terms_of_service_age_limitation_desc') }}</p>
    <h3>{{ $t('terms_of_service_privacy_title') }}</h3>
    <p>{{ $t('terms_of_service_privacy_desc') }}</p>

    <h3>{{ $t('terms_of_service_notice_of_claims_title') }}</h3>
    <p>{{ $t('terms_of_service_notice_of_claims_desc_1') }}</p>
    <p>
      {{ $t('terms_of_service_notice_of_claims_address_1') }}<br />
      {{ $t('terms_of_service_notice_of_claims_address_2') }}<br />
      {{ $t('terms_of_service_notice_of_claims_address_3') }}<br />
      {{ $t('terms_of_service_notice_of_claims_address_4') }}<br />
      {{ $t('terms_of_service_notice_of_claims_address_5') }}
    </p>

    <p>{{ $t('terms_of_service_notice_of_claims_desc_2') }}</p>

    <ul>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_1') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_2') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_3') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_4') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_5') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_request_list_6') }}</li>
    </ul>
    <p>{{ $t('terms_of_service_notice_of_claims_desc_3') }}</p>
    <p>{{ $t('terms_of_service_notice_of_claims_desc_4') }}</p>

    <ul>
      <li>{{ $t('terms_of_service_notice_of_claims_counter_notification_list_1') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_counter_notification_list_2') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_counter_notification_list_3') }}</li>
      <li>{{ $t('terms_of_service_notice_of_claims_counter_notification_list_4') }}</li>
    </ul>

    <h3>{{ $t('terms_of_service_warranty_and_liability_title') }}</h3>
    <p>{{ $t('terms_of_service_warranty_and_liability_desc_1') }}</p>
    <p>{{ $t('terms_of_service_warranty_and_liability_desc_2') }}</p>
    <p>{{ $t('terms_of_service_warranty_and_liability_desc_3') }}</p>
    <p>{{ $t('terms_of_service_warranty_and_liability_desc_4') }}</p>

    <h3>{{ $t('terms_of_service_right_to_restrict_access_title') }}</h3>
    <p>{{ $t('terms_of_service_right_to_restrict_access_desc') }}</p>
    <h3>{{ $t('terms_of_service_dispute_resolution_title') }}</h3>
    <p>{{ $t('terms_of_service_dispute_resolution_desc_1') }}</p>
    <p>
      {{ $t('terms_of_service_dispute_resolution_address_1') }}<br />
      {{ $t('terms_of_service_dispute_resolution_address_2') }}<br />
      {{ $t('terms_of_service_dispute_resolution_address_3') }}<br />
    </p>
    <p>{{ $t('terms_of_service_dispute_resolution_desc_2') }}</p>
    <p>{{ $t('terms_of_service_dispute_resolution_desc_3') }}</p>

    <h3>{{ $t('terms_of_service_governing_law_title') }}</h3>
    <p>{{ $t('terms_of_service_governing_law_desc_1') }}</p>
    <p>{{ $t('terms_of_service_governing_law_desc_2') }}</p>

    <h3>{{ $t('terms_of_service_severability_title') }}</h3>
    <p>{{ $t('terms_of_service_severability_desc') }}</p>

    <h3>{{ $t('terms_of_service_termination_title') }}</h3>
    <p>{{ $t('terms_of_service_termination_desc') }}</p>

    <h3>{{ $t('terms_of_service_assigment_title') }}</h3>
    <p>{{ $t('terms_of_service_assigment_desc') }}</p>
    <h3>{{ $t('terms_of_service_headings_title') }}</h3>
    <p>{{ $t('terms_of_service_headings_desc') }}</p>

    <h3>{{ $t('terms_of_service_contacts_information_title') }}</h3>
    <p>{{ $t('terms_of_service_contacts_information_desc') }}</p>
    <p>
      {{ $t('terms_of_service_contacts_information_address_1') }}<br />
      {{ $t('terms_of_service_contacts_information_address_2') }}<br />
      {{ $t('terms_of_service_contacts_information_address_3') }}
    </p>
    <p>
      {{ $t('terms_of_service_contacts_information_phone_title') }}<br />
      {{ $t('terms_of_service_contacts_information_phone') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfServiceOtherLanguages'
}
</script>

<style lang="scss" scoped>
.TermsOfServiceOtherLanguages {
  @apply text-primary-01;
  max-width: 730px;
  padding-bottom: 60px;
  /deep/ {
    h2 {
      font-family: var(--font-main) !important;
      @apply mt-10 font-semibold text-2xl;
    }

    h3 {
      font-family: var(--font-main);
      @apply mt-8 text-base font-semibold;
    }

    p {
      font-family: var(--font-main);
      @apply my-3;
    }

    li {
      @apply ml-10;
    }
  }
}
</style>
