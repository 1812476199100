import Network from '../../network.js'

export default () => ({
  updateReadState({ chatId, threadId, responseIdList }) {
    return Network.post(
      `/forum-chats/${chatId}/threads/${threadId}/responses/read`,
      {
        responseIdList
      },
      true
    )
  },
  getList({ chatId, threadId, perPage, cancelToken, page, parentResponseId, responseId }) {
    return Network.getWithConfig(
      `/forum-chats/${chatId}/threads/${threadId}/responses`,
      {
        cancelToken,
        params: {
          page,
          perPage,
          parentResponseId,
          responseId
        }
      },
      true
    )
  },
  getResponsePath({ chatId, threadId, responseId }) {
    return Network.get(
      `/forum-chats/${chatId}/threads/${threadId}/responses/${responseId}/response-path`,
      null,
      true
    )
  },
  getRepliesList({ chatId, threadId, timestampOffset, perPage, cancelToken, parentResponseId, replyId }) {
    return Network.getWithConfig(
      `/forum-chats/${chatId}/threads/${threadId}/responses/${parentResponseId}/replies`,
      {
        cancelToken,
        params: {
          timestampOffset,
          limit: perPage,
          parentResponseId,
          replyId
        }
      },
      true
    )
  },
  create({ chatId, threadId, response }) {
    return Network.post(
      `/forum-chats/${chatId}/threads/${threadId}/responses`,
      {
        ...response
      },
      true
    )
  },
  update({ chatId, threadId, response }) {
    return Network.put(
      `/forum-chats/${chatId}/threads/${threadId}/responses/${response.id}`,
      {
        ...response
      },
      true
    )
  },
  delete({ chatId, threadId, responseId }) {
    return Network.delete(`/forum-chats/${chatId}/threads/${threadId}/responses/${responseId}`, null, true)
  },
  updateReactions({ chatId, threadId, responseId, emojiList }) {
    return Network.put(
      `/forum-chats/${chatId}/threads/${threadId}/responses/${responseId}/reactions`,
      {
        reactionList: emojiList
      },
      true
    )
  },
  viewReaction({ chatId, threadId, responseId, emoji }) {
    return Network.post(
      `/forum-chats/${chatId}/threads/${threadId}/responses/${responseId}/reactions/view`,
      {
        emoji
      },
      true
    )
  }
})
