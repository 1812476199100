<template>
  <div class="LLFilterChevron" :class="classes">
    <div class="LLFilterChevron__chevron" @click="onClick">
      <div class="LLFilterChevron__label">
        <slot name="label"></slot>
      </div>
      <div class="LLFilterChevron__arrow-wrapper">
        <ArrowMiniIcon class="LLChevron__arrow"></ArrowMiniIcon>
      </div>
    </div>
    <div v-if="opened" class="LLFilterChevron__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
export default {
  name: 'LLFilterChevron',
  components: { ArrowMiniIcon },
  props: {
    inactive: { type: Boolean, default: false }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLFilterChevron'
      if (this.opened) {
        classes.push(`${rootClass}_opened`)
      }
      if (this.inactive) {
        classes.push(`${rootClass}_inactive`)
      }
      return classes
    }
  },
  watch: {
    inactive: {
      handler(value) {
        if (value) {
          this.opened = false
        }
      },
      immediate: true
    }
  },
  methods: {
    onClick() {
      this.opened = !this.opened
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFilterChevron {
  $root: &;
  &__chevron {
    @apply flex min-w-0 items-center cursor-pointer select-none text-body-02;
  }
  &__label {
    @apply flex-1;
  }
  &__arrow-wrapper {
    @apply ml-2 transition duration-200;
    transform: rotate(90deg);
  }
  &_opened {
    #{$root}__arrow-wrapper {
      transform: rotate(270deg);
    }
  }
  &__content {
    @apply mt-2.5;
  }
  &_inactive {
    @apply opacity-50 pointer-events-none;
  }
}
</style>
