<template>
  <div class="PollMultipleResults">
    <PollResults
      :filter-type="filterTypeToSend"
      :filter-values="filterValues"
      :poll="poll"
      :thread="thread"
      :export-data="exportData"
      @updateResult="onResultUpdate"
      @updateLocations="onLocationsUpdate"
    >
      <template #average>
        <PollResultsPercentage v-if="result" :list="choiceListPercentage" />
      </template>
      <template #chart>
        <PollMultipleResultsBar
          v-if="poll.answerLimitAmount !== 1"
          class="PollMultipleResults__bar"
          :data="choiceListFilteredPercentage"
        />
        <PollMultipleResultsPie
          v-else
          class="PollMultipleResults__pie"
          :data="choiceListFilteredPercentage"
        />
      </template>
      <template #filters>
        <PollResultsFilterAdvanced
          :filter-type.sync="filterType"
          :filter-values.sync="filterValues"
          :location-groups="filterLocationGroups"
        />
      </template>
    </PollResults>
  </div>
</template>

<script>
import PollResults from '@/components/modules/forum-chat/poll/ForumPollResults.vue'
import { PollModel } from '@/models/poll'
import PollResultsPercentage from '@/components/modules/forum-chat/poll/results/PollResultsPercentage.vue'
import PollResultsFilterAdvanced from '@/components/modules/forum-chat/poll/results/PollResultsFilterAdvanced.vue'
import PollMultipleResultsBar from '@/components/modules/poll/multiple/PollMultipleResultsBar.vue'
import PollMultipleResultsPie from '@/components/modules/poll/multiple/PollMultipleResultsPie.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollMultipleResults',
  components: {
    PollMultipleResultsPie,
    PollMultipleResultsBar,
    PollResultsFilterAdvanced,
    PollResultsPercentage,
    PollResults
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      filterType: null,
      filterValues: [],
      filteredResult: null,
      result: null,
      filterLocationGroups: []
    }
  },
  computed: {
    sortedRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    sortedRowsByPercentage() {
      return [...(this.poll?.rows || [])]?.sort(
        (a, b) => (this.resultFilteredPercentage?.[b.id] ?? 0) - (this.resultFilteredPercentage?.[a.id] ?? 0)
      )
    },
    exportData() {
      return this.choiceListFilteredPercentage.map((result) => ({
        category: result.text,
        percentage: result.value
      }))
    },
    resultPercentage() {
      const total = this.result?.totalCount ?? 0
      const rows = {}
      this.result?.results?.forEach((result) => {
        const percentage = (result?.count / total) * 100
        rows[result?.rowId] = Math.round((percentage + Number.EPSILON) * 10) / 10
      })
      return rows
    },
    resultFilteredPercentage() {
      const total = this.filteredResult?.totalCount ?? 0
      const rows = {}
      this.filteredResult?.results?.forEach((result) => {
        const percentage = (result?.count / total) * 100
        rows[result?.rowId] = Math.round((percentage + Number.EPSILON) * 10) / 10
      })
      return rows
    },
    choiceListPercentage() {
      return this.sortedRows.map((row) => ({
        text: row.text,
        id: row.id,
        value: this.resultPercentage?.[row.id] ?? 0
      }))
    },
    choiceListFilteredPercentage() {
      return this.sortedRowsByPercentage.map((row) => ({
        text: row.text,
        id: row.id,
        value: this.resultFilteredPercentage?.[row.id] ?? 0
      }))
    },
    filterTypeToSend() {
      return this.filterValues?.length ? this.filterType : null
    }
  },
  methods: {
    onResultUpdate({ result }) {
      if (!this.result && !this.filterType) {
        this.result = result
      }

      this.filteredResult = result
    },
    onLocationsUpdate({ locations }) {
      this.filterLocationGroups = locations
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultipleResults {
  &__pie {
    @apply w-full;
    max-width: 280px;
  }
  &__bar {
    @apply w-full;
  }
}
</style>
