<template>
  <LLModalLayout w-paddings w-actions class="UserEditModalContent">
    <template #content>
      <UserEditModalHeader
        class="UserEditModalContent__header"
        :permanent-user.sync="user"
        :user.sync="localUser"
      ></UserEditModalHeader>
      <ValidationObserver ref="validation-observer">
        <UserEditModalProfile
          class="UserEditModalContent__profile"
          :user.sync="localUser"
        ></UserEditModalProfile>
      </ValidationObserver>
      <UserEditModalInterestsList
        class="UserEditModalContent__interests"
        :user-interests-list.sync="localUser.interestIdList"
        data-e2e="userInterestsList"
      ></UserEditModalInterestsList>
    </template>
    <template #actions>
      <LLButton :action="onSave" :loading="savePending" :disabled="!valid" data-e2e="saveBtn">Save</LLButton>
    </template>
  </LLModalLayout>
</template>

<script>
import LLModalLayout from '@/components/common/LLModal/LLModalLayout'
import LLButton from '@/components/common/ui-components/LLButton'
import { ProspectModel } from '@/models/prospect'
import UserEditModalHeader from '@/components/user/UserEditModal/UserEditModalContent/UserEditModalHeader'
import UserEditModalProfile from '@/components/user/UserEditModal/UserEditModalContent/UserEditModalProfile'
import UserEditModalInterestsList from '@/components/user/UserEditModal/UserEditModalInterestsList.vue'
import Api from '@/services/api'
import { localeChanged } from 'vee-validate'
export default {
  name: 'UserEditModalContent',
  components: {
    UserEditModalInterestsList,
    UserEditModalProfile,
    UserEditModalHeader,
    LLButton,
    LLModalLayout
  },
  data() {
    return {
      localUser: null,
      valid: false,
      savePending: false
    }
  },
  computed: {
    userInStore() {
      return this.$store.state.user.user
    },
    user() {
      return ProspectModel.parseFromApi({ prospect: this.userInStore })
    }
  },
  watch: {
    localUser: {
      async handler() {
        this.valid = await this.validate()
      },
      deep: true
    },
    user: {
      handler() {
        if (!_.isEqual(this.user, this.localUser)) {
          this.localUser = new ProspectModel(this.user)
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      this.valid = await this.validate()
    })
  },
  methods: {
    async onSave() {
      this.savePending = true
      try {
        const {
          phone,
          firstName,
          lastName,
          image,
          gender,
          birthday,
          language,
          region,
          city,
          country,
          placeId,
          interestIdList,
          smsConsentAccepted,
          aiWarningAccepted
        } = this.localUser
        const newUserData = {
          phone,
          gender,
          firstName,
          lastName,
          birthday,
          preferredLanguage: language,
          region,
          city,
          country,
          placeId: placeId || undefined,
          interestIdList,
          imageId: image?.id,
          smsConsentAccepted: smsConsentAccepted || false,
          aiWarningAccepted: aiWarningAccepted || false
        }
        const { prospectAccount } = await Api.user.sync({ ...newUserData })
        this.$store.commit('user/UPDATE_USER', { ...prospectAccount })

        this.$emit('close')

        let locale = this.localUser?.language?.toLowerCase()
        if (locale) {
          this.$i18n.locale = locale
          this.$store.commit('ui/SET_LOCALE', locale)
          localeChanged()
        }
        this.$notify({
          group: 'main',
          type: 'success',
          title: this.$t('profile_updated')
        })
      } catch (e) {
        this.$errorAlert(e)
      }
      this.savePending = false
    },
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver) {
        return await validationObserver.validate()
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.UserEditModalContent {
  &__header {
    @apply pb-8;
    @apply border-b border-neutral-01-50;

    @screen md-max {
      @apply pb-4;
    }
  }
  &__profile {
    @apply pt-8;
    @screen md-max {
      @apply pt-4;
    }
  }
  &__interests {
    @apply mt-8;
    @screen md-max {
      @apply mt-8;
    }
  }
}
</style>
