<template>
  <LLWordTextContainer class="PrivacyPolicyZH">
    <p>
      保护您的个人信息是我们的首要任务。本隐私政策适用于由 Spark Ideas,
      LLC（“Spark”、“我们”或“我们的”）控制的网站、应用程序和服务，包括
      admin.looklook.app、looklook.app、www.spark -nyc.com、www.looklook-app.com 和 www.looklook-analysis.com,
      myLookLook,（统称为“网站”）和 LookLook
      移动应用程序（“移动应用程序”）。请仔细阅读本隐私政策，因为使用网站或移动应用程序，即表示您同意本声明中描述的数据做法。
    </p>
    <p>
      您对网站或移动应用程序的使用也受服务条款的约束，可在<a href="/terms-of-service#/terms-of-service"
        >此处</a
      >查看。
    </p>
    <p>
      <span class="font-bold underline">重要通知：</span
      >请注意以下仲裁条款，要求您（除法律禁止的地点和范围外）以个人名义向 SPARK
      提起仲裁索赔。个人提出的仲裁意味着您将没有，并且您放弃由法官或陪审团决定您的索赔的权利，并且您可能不会以集体、联合或代表身份进行诉讼。
    </p>
    <h3>收集和使用个人信息</h3>
    <p>“个人信息”是与已识别或可识别身份的自然人相关任何信息。我们从您那里收集您的相关以下信息：</p>
    <ul>
      <li>
        我们收集您通过网站和/或移动应用程序向我们提供的信息，例如您的姓名、电子邮件地址以及您选择通过图像、对话和视频与我们分享的观点。
      </li>
      <li>
        当您向我们发送电子邮件或以其他方式与我们通信，比如填写“联系我们”表格，询问关于我们、我们的网站或移动应用程序时，我们会收集您的姓名和电子邮件地址。
      </li>
      <li>
        当您使用我们的网站或移动应用程序时，我们可能会通过 cookies
        和其他类似技术自动收集信息，例如您访问的日期和时间、您查看的页面、您的浏览器类型和 IP
        地址。有关更多信息，请参阅下面的 Cookies 和其他相似技术。
      </li>
    </ul>
    <p>我们将为下列目的使用您的个人信息：</p>
    <ul>
      <li>提供和改善网站和/或移动应用程序；</li>
      <li>履行订单；</li>
      <li>回复问询；</li>
      <li>为客户营销和调查研究编制用户信息；</li>
      <li>进行调查；和</li>
      <li>联系您参加后期营销和研究调查。</li>
    </ul>
    <h3>信息处理法律依据</h3>
    <p>我们根据适用法律，以透明和公平的方式处理个人信息。上述各项处理活动的法律依据如下：</p>
    <ul>
      <li>为了与您联系以参与未来的营销和研究，处理是基于您的同意</li>
      <li>为您提供我们的服务</li>
      <li>为客户完成订单、回复查询并编制用户信息</li>
      <li>保护我们的服务。（例如，用于欺诈监控和预防）。</li>
      <li>执行我们的服务条款和隐私政策</li>
      <li>遵守法律和法规要求</li>
      <li>回应法律查询并防止损害</li>
      <li>回应用户查询/提供支持</li>
      <li>管理抽奖和竞赛。</li>
      <li>向您发送营销和促销信息。</li>
      <li>
        用于其他商业目的。（例如，数据分析、衡量我们促销活动的有效性以及分析和改进我们的网站、服务、营销和您的体验。
      </li>
    </ul>
    <h3>Cookie 和其他类似技术</h3>
    <p>
      我们在我们的网站和移动应用程序上使用 cookie 和其他类似技术辅助收集信息。我们可能会使用会话 cookie 或持久
      cookie。会话 cookie 仅在您访问的特定持续时间内有效，并在您关闭浏览器时被删除。持久 cookies
      会保留在您设备的硬盘上，直到您主动删除或过期。我们使用以下类型的 cookies：
    </p>
    <ul>
      <li>
        <span class="underline">基本型。</span
        >为了使您能够在我们的网站和/或移动应用程序中浏览、使用其功能，例如访问网站和/或移动应用程序的安全区域，一些
        cookie 是必不可少的。如果没有这些 cookie，我们将无法根据您使用的设备类型启用适当的内容。
      </li>
      <li>
        <span class="underline">浏览和功能。</span>我们使用这些 cookie
        来记住您在网站和/或移动应用程序上所做的选择，例如您的首选语言和您访问的国家/地区，并提供更强大、更个性化的功能。
      </li>
      <li>
        <span class="underline">性能和分析。</span>我们使用 cookie
        来了解您如何使用我们的网站和/或移动应用程序，以提高性能并根据您的喜好进行开发。
      </li>
      <li>
        <span class="font-bold">Google Analytics。</span>为了促进相关内容的交付，我们采用 Google
        Analytics。Google Analytics 使用 cookie
        报告我们的和其他的网站上的用户交互。利用收集到的数据优化营销、调整广告和/或编程策略，以及整体改善用户体验。有关
        Google Analytics
        及其如何收集和处理数据的更多信息，请访问：https://policies.google.com/technologies/partner-sites。有关使用特定插件选择退出
        Google Analytics
        的说明，请访问以下链接：https://tools.google.com/dlpage/gaoptout。请注意，此选择退出限于 Google
        活动，不会影响我们可能使用的其他分析服务提供商的活动。
      </li>
      <!-- <li>
        {{ $t('privacy_policy_cookies_third') }}
        <ul>
          <li>
            <b>{{ $t('privacy_policy_cookies_google_analytics_title') }}.</b>
            {{ $t('privacy_policy_cookies_google_analytics_description') }}
          </li>
        </ul>
      </li> -->
    </ul>
    <p>
      目前有多种方式适于管理 cookie。您可以在浏览器上选择接受 cookie、禁用 cookie 或在收到新 cookie
      时发出通知，以控制 cookie 的使用。请注意，如果您拒绝
      cookie，您仍然可以使用我们的网站和/或移动应用程序，但在使用我们网站或移动应用程序的某些功能或区域时可能会受到限制。
    </p>
    <h3>共享和披露个人信息</h3>
    <p>我们会在以下情况下向下列各方共享或披露您的个人信息：</p>
    <ul>
      <li>我们会为客户的营销、研究调查和商业目的，与客户共享通过网站和移动应用程序提供的信息。</li>
      <li>我们会为管理研究和向客户提供报告，与第三方服务提供商共享通过网站和移动应用程序提供的信息。</li>
      <li>
        我们可能会在任何直接或间接重组过程中共享或转移您的信息，包括但不限于我们全部或部分资产的合并、收购、剥离、破产以及出售。请参阅下面的转让条款以了解更多信息。
      </li>
      <li>
        我们将按适用法律规定披露或在善意认为该披露是必要的情况下披露您的个人信息，以符合法律要求或遵守送达我们的法律程序，以保护和捍卫我们的权利或财产，或在紧急情况下采取行动保护我们用户的人身安全。
      </li>
      <li>
        我们可能会与代表我们提供服务并有权访问您的个人信息的第三方服务提供商、承包商、供应商或代理共享您的个人信息。例如，我们可能会将您的数据存储在安全的第三方拥有的云数据库中。我们的服务提供商有合同义务保护您的个人信息。您可以在附录1中查看我们当前的服务提供商列表。
      </li>
    </ul>
    <h3>儿童信息的收集和使用</h3>
    <p>
      我们不会有意收集美国 13 岁以下儿童或欧盟 16 岁以下儿童的信息。在我们获悉未满 13 岁（美国）或未满 16
      岁（欧盟）的个人向我们提供其个人信息后，我们将会删除上述个人信息。
    </p>

    <h3>访问和选择退出</h3>
    <p>如果您已变更信息并且希望更新存档信息，请通过以下联系信息与我们联系。</p>
    <p>
      如果您不希望继续接收我们的营销讯息，您可以按营销讯息中提供的退订说明或通过以下地址联系我们选择退出。我们将遵守适用法律尽快处理您的退订，但请注意，在某些情况下，您可能会在退订处理完成前继续收到更多消息。
    </p>
    <p>
      请注意，即使您选择不再接收营销讯息，我们仍会向您发送您个人订单和网站和/或移动应用程序的管理消息，包括网站和/或移动应用程序的相关重要更新。
    </p>

    <h3>您的权利</h3>
    <p>除了访问权之外，如果您居住在 EEA（欧盟、冰岛、挪威、列支敦士登）、英国或瑞士，您还有权：</p>
    <ul>
      <li>确认是否正处理您的个人信息并获悉其内容和来源，确认信息是否准确并要求信息完整、更新或修改。</li>
      <li>请求删除、匿名化或限制处理您的个人信息。</li>
      <li>在任何情况下均可反对为合法理由处理或限制处理您的个人信息。</li>
      <li>撤回同意，个人信息的处理要求征得您的同意。</li>
      <li>如果您想要自己的个人信息副本或将您的个人信息移交到其他提供商，请接收个人信息的电子版副本。</li>
      <li>无须遵从仅基于自动处理做出的决定（包括分析），但当地法律特别规定的例外情形除外。</li>
      <li>向相关数据保护监督机构提出投诉。</li>
    </ul>
    <h3>第三方链接</h3>
    <p>
      Spark
      鼓励您查看与网站和/或移动应用程序链接的网站的隐私声明，以便您了解这些网站如何收集、使用和共享您的信息。Spark
      不对网站和移动应用程序之外的这些网站上的隐私声明或任何内容负责。
    </p>
    <h3>您个人信息的安全</h3>
    <p>
      Spark
      采取合理措施保护您的个人信息，避免未经授权的访问、使用或披露。也就是说，请理解通过互联网传输的任何数据都不能保证
      100%
      安全。因此，我们无法确保或保证您传输给我们的任何信息的安全性，并且您了解您传输给我们的任何信息均由您自行承担风险。如果我们发现安全系统遭到破坏，我们可能会尝试以电子方式通知您，以便您采取适当的保护措施。通过使用网站和/或移动应用程序或向我们提供个人信息，您同意我们可以电子方式与您沟通使用网站和/或移动应用程序相关的安全、隐私和管理问题。如果出现安全漏洞，我们可能会通过网站和移动应用程序发布通知。在这些情况下，我们也可能会通过您提供给我们的电子邮件地址向您发送电子邮件。您可能有合法权利接收安全漏洞书面通知，具体取决于您的居住地。
    </p>
    <h3>数据保留</h3>
    <p>
      我们会为实现本隐私政策列明的目的在必要时限内保留您的个人信息，法律另有要求或批准的更长保留期限除外。
    </p>
    <h3>国际数据传输</h3>
    <p>
      如果您选择向我们提供信息，我们可能会将该信息传输给我们的附属公司和子公司或其他第三方，跨国，并且甚至从您所在国家或司法管辖区传输到世界各地的其他国家或司法管辖区。如果您从欧盟或其他关于数据收集和使用的适用法律可能与美国法律不同的地区访问，请注意您正在将您的个人信息传输到美国和其他司法管辖区，而这些司法管辖区可能与您居住国家的数据保护法不同。您确认通过提供个人信息，您理解：
    </p>
    <ul>
      <li>您的个人信息将根据本隐私政策用于上述用途；及</li>
      <li>您的个人信息可能会根据上述适用法律传输到美国和其他司法管辖区。</li>
    </ul>
    <h3>转让</h3>
    <p>
      如果另一方出售或收购我们的全部或部分资产，或者在任何直接或间接重组过程的情况下，包括但不限于合并、收购、剥离、破产和出售全部或部分资产，您授予我们在此类交易完成后和/或在待转移的评估过程中转让通过网站和/或移动应用程序收集的个人信息的权利。如果在上述情况下转让，您的信息将仍受本隐私政策或至少与本隐私政策保护程度相当的隐私政策保护。
    </p>
    <h3>争议解决和同意仲裁</h3>
    <p>
      除法律禁止外，使用本网站和/或移动应用程序，则表示您和 Spark
      同意，如果因您使用本网站和/或移动应用程序或本隐私政策或其任何部分的违反、执行、解释或有效性而引起或与之相关的任何争议、索赔、诉讼或争议（“争议”），双方应首先尝试善意解决此类争议，向另一方提供书面通知，描述争议事实和情况，并允许接收方在三十
      (30) 天内回应或解决争议。通知应发送至我们下列地址处：
    </p>
    <p>
      Spark Ideas, LLC<br />
      1328 Ridge Road<br />
      Syosset, New York 11791
    </p>
    <p>
      您和 Spark
      均同意，此争议解决程序是在对另一方提起任何诉讼或提出任何索赔之前必须满足的先决条件。如果任何争议无法通过上述争议解决程序解决，您同意此类争议的唯一专属管辖权将由个人提出的具有约束性的仲裁决定。个人提出的仲裁意味着您将没有，并且您放弃由法官或陪审团决定您的索赔的权利，并且您可能不会以集体、联合或代表身份进行诉讼。您和我们本应在法庭上拥有的其他权利将不可用或将在仲裁中受到更多限制，包括发现权和上诉权。所有此类争议均应根据其当时有效的规则专门提交给
      JAMS (www.jamsadr.com)
      ，双方共同商定一名仲裁员进行仲裁，结果约束双方。仲裁员将通过电话会议或视频会议参加听证会（如果有），而不是亲自出庭，除非仲裁员根据您或我们的要求确定适合亲自听证会。任何当庭仲裁都将在双方都合理方便的地点举行，并适当考虑他们的差旅能力和其他相关情况。如果双方无法就地点达成一致，则由
      JAMS 或仲裁员做出此类决定。
    </p>
    <p>
      仲裁员，而不是任何联邦、州或地方法院或机构，应该拥有解决因本隐私政策的解释、适用性、可执行性或制定而引起或与之相关的任何争议的专属权力，包括本隐私政策任何一部分无效或可作废的主张。
    </p>

    <h3>法律选择</h3>
    <p>
      本隐私政策是根据纽约州法律制定的，并应根据纽约州法律进行解释，不适用任何法律冲突原则。双方承认本隐私政策证明交易涉及跨州贸易。尽管前一段中有关于适用实体法的规定，但根据本隐私政策条款进行的任何仲裁均应受《联邦仲裁法》（9
      U.S.C. §§ 1-16）的管辖。
    </p>

    <h3>您的加州隐私权以及我们如何应对“请勿追踪”信号</h3>
    <p>
      加利福尼亚州《民法典》第 1798.83
      条规定站点和/或移动应用程序访问者如果是加利福尼亚州居民，允许请求提供我们为第三方直接营销目的披露的个人信息相关特定信息。我们不会为第三方直接营销目的与其共享您的个人信息。
    </p>
    <p>目前，我们的网站无法识别有关跟踪机制的自动浏览器信号，其中可能包括“请勿跟踪”说明。</p>
    <h3>联系信息</h3>
    <p>Spark 欢迎您对本隐私政策和我们的隐私实践提出问题或意见。请联系我们：</p>
    <p>
      Spark Ideas, LLC<br />
      1328 Ridge Road<br />
      Syosset, New York 11791<br />
      United States of America
    </p>
    <p>
      电子邮件地址：<br />
      privacy@spark-nyc.com
    </p>
    <p>
      电话号码：<br />
      (917) 991-0477
    </p>
    <h3>隐私政策变更</h3>
    <p>
      Spark
      会不时更新本隐私政策，体现公司和客户的反馈意见。如有变更，我们还将修改本隐私政策顶部的“生效”日期。如果对隐私政策进行了重大更改，我们会在网站和移动应用程序的显眼处发布通知。我们鼓励您定期查看本隐私政策，以了解
      Spark 如何处理您的信息。
    </p>
    <p class="text-xl pt-8">附录1 居住在中华人民共和国境内的用户</p>
    <p>
      本节将解释 Spark Ideas, LLC (dba LookLook) 如何保护其居住在中华人民共和国境内的用户的个人信息和权益。
    </p>
    <p>
      Spark Ideas, LLC 旨在按照合法、正当、必要和诚实的原则，规范个人信息处理活动，促进个人信息的合理使用。
    </p>
    <p>
      在您使用我们的网站之前，您需要通读并接受本政策。用户有权随时撤回其同意。同意撤回不会影响同意撤回前基于用户同意而进行的个人信息处理活动的有效性。
    </p>
    <p>以下是 Spark Ideas, LLC 从 MyLookLook 微信小程序用户处收集的个人信息列表：</p>
    <p class="underline">1. 微信公开信息——用户同意后自动收集:</p>
    <p>
      信息类型：微信昵称，头像链接，语言，城市，国家<br />
      收集目的：经用户同意后参与 SPARK 的调研<br />
      存储地点：中国，美国<br />
      存储时限：经调研参与者同意后，为了将来调研的需要，调研参与者的联系信息会被保存，并在 2
      年后或不再需要时删除。<br />
      受托方隐私与安全声明页：<br />
      - 腾讯云（中国）：https://cloud.tencent.com/act/event/compliance <br />
      - 亚马逊网络服务（美国）：https://aws.amazon.com/privacy
    </p>
    <p class="underline">2. 用户在与 LookLook 主持人对话期间手动收集的信息:</p>
    <p>
      信息类型：昵称，性别，出生年，兴趣，调研问题的文字答案，对话中上传的图片，对话中上传的视频，
      奢侈品和品牌偏好<br />
      收集目的：经用户同意后参与 SPARK 的调研<br />
      存储地点：美国<br />
      存储时限：经调研参与者同意后，为了将来调研的需要，调研参与者的联系信息会被保存，并在 2
      年后或不再需要时删除。 调研的内容（文本文件、图片、视频文件）会在调研期间保留，并在 2
      年后或调研完成后删除。<br />
      受托方隐私与安全声明页：<br />
      - 亚马逊网络服务（美国）：https://aws.amazon.com/privacy
    </p>
    <p class="underline">3. 自动收集的应用数据:</p>
    <p>
      信息类型：用户是否接受 Looklook 的服务条款和隐私政策，LookLook小程序当前是否在前台启动<br />
      收集目的：记录用户许可，提升用户体验<br />
      存储地点：中国<br />
      存储时限：经调研参与者同意后，为了将来调研的需要，调研参与者的联系信息会被保存，并在 2
      年后或不再需要时删除。<br />
      受托方隐私与安全声明页：<br />
      - 腾讯云（中国）：https://cloud.tencent.com/act/event/compliance
    </p>
    <p>
      Spark Ideas, LLC
      可以将您的个人信息委托给受托方（如云存储提供商），如果他们同意按照书面协议和中国个人信息保护法（PIPL）的规定处理您的个人信息。
    </p>
    <p>
      可以在上文找到我们当前受托方（数据处理者）的列表。如果我们决定更改数据处理者，我们将在更改发生之前通知您。请注意，如果您不同意我们使用数据处理者，或者您不希望与外国实体共享您的个人信息，您可以随时撤回您的许可。Spark
      Ideas, LLC 将与中国国家网信部门制定的附加标准合同与其数据处理者签署一份附加标准合同。
    </p>
    <h3>用户的选择和权利</h3>
    <p>中华人民共和国用户依法享有以下权利：</p>
    <ul>
      <li>知道 Spark Ideas, LLC 拥有哪些个人信息的权利</li>
      <li>访问和复制个人信息的权利</li>
      <li>数据可移植性的权利</li>
      <li>纠正不准确信息的权利</li>
      <li>补充不完整信息的权利</li>
      <li>
        删除权如果<br />
        - 不再需要个人信息来达到处理目的<br />
        - 处理目的已经达到或不可能达到<br />
        - Spark Ideas, LLC 不再提供其服务，或保留期已过<br />
        - 用户撤回同意；<br />
        - Spark Ideas, LLC 处理个人信息违反法律、行政法规或协议<br />
        - 法律、行政法规规定的其他情形
      </li>
      <li>要求 Spark Ideas, LLC 解释个人信息处理规则的权利</li>
      <li>私人诉讼权</li>
    </ul>
    <p>
      用户死亡时，其近亲属可以为了自身的合法、正当利益，行使本章规定的权利，查阅、复制、更正、删除死者的个人信息，但符合以下条件的除外：死者在死前另有安排。
    </p>
    <p>如果您希望行使您的权利，请将您的请求通过电子邮件发送至 privacy@Spark-nyc.com。</p>
    <p>如果我们不能满足您的要求或者您对我们处理您的要求的方式不满意，您可以依法向人民法院提起诉讼。</p>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyZH',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyZH {
  max-width: 730px;
  /deep/ {
    ul li {
      &::marker {
        content: '\0025cf   ';
      }
    }
  }
}
</style>
