<template>
  <div class="StudyConversationMessageEditorTextMobile" :class="classList">
    <div class="StudyConversationMessageEditorTextMobile__content">
      <div class="StudyConversationMessageEditorTextMobile__user-content">
        <div class="StudyConversationMessageEditorTextMobile__textarea-wrapper">
          <ValidationProvider name="messageText" rules="required" immediate>
            <LLTextarea
              v-model="localMessage.text"
              size="compact"
              dynamic-size
              :max-height="200"
              :placeholder="localMessage.files.length > 0 ? $t('add_comment_or_send') : $t('type_here')"
              data-e2e="questionInput"
              class="StudyConversationMessageEditorTextMobile__textarea"
            >
              <template v-if="localMessage.files.length" #bottom-content>
                <LLFileSlider
                  class="StudyConversationMessageEditorTextMobile__files-slider"
                  :files.sync="localMessage.files"
                  :editable="true"
                ></LLFileSlider>
              </template>
            </LLTextarea>
          </ValidationProvider>
        </div>
        <div
          v-if="isFileUploaderVisible || isRoundSendButtonVisible"
          class="StudyConversationMessageEditorTextMobile__actions"
        >
          <div v-if="isFileUploaderVisible" class="StudyConversationMessageEditorTextMobile__action-wrapper">
            <LLFileUploaderWrapper
              :with-promise="true"
              class="QuestionTextBoxMobile__file-uploader-wrapper"
              :preparable="!localMessage.id"
              :study-id="localMessage.studyId"
              file-mime-type="image/*,image/heic,video/*"
              @upload="onUpload"
              @startUpload="onStartUpload"
              @prepare="onPrepare"
            >
              <LLButton round tertiary>
                <template #icon-left> <PhotoInvertedIcon /> </template>
              </LLButton>
            </LLFileUploaderWrapper>
          </div>
          <div
            v-if="isRoundSendButtonVisible"
            class="StudyConversationMessageEditorTextMobile__action-wrapper"
          >
            <LLButton round primary :loading="isSendPending" :disabled="!isValid" @click="onSendClick">
              <template #icon-left> <SendIcon /> </template>
            </LLButton>
          </div>
        </div>
      </div>
      <LLUploadProgress
        class="StudyConversationMessageEditorTextMobile__file-uploader-progress"
        :uploading-files-uuids.sync="uploadingFilesUuids"
      ></LLUploadProgress>
      <div
        v-if="isAbleToCancel && isActionsVisible"
        class="StudyConversationMessageEditorTextMobile__footer-actions"
      >
        <LLButton
          small
          :loading="isSendPending"
          :disabled="!isValid"
          primary
          class="StudyConversationMessageEditorTextMobile__footer-action"
          @click="onSendClick"
        >
          <template v-if="message.id">{{ $t('button_save') }}</template>
          <template v-else>{{ $t('button_send') }}</template>
        </LLButton>

        <LLButton
          v-if="isAbleToCancel"
          class="StudyConversationMessageEditorTextMobile__footer-action"
          small
          secondary
          :disabled="isSendPending"
          @click="onCancelClick"
          >{{ $t('button_cancel') }}</LLButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { QuestionModel } from '@/models/question'
import LLTextarea from '@/components/common/LLTextarea.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import SendIcon from '@/assets/icons/SendIcon.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import PhotoInvertedIcon from '@/assets/icons/PhotoInvertedIcon.vue'
import LLFileUploaderWrapper from '@/components/common/LLFileUploader/LLFileUploaderWrapper.vue'
import { ConversationMessageFileModel } from '@/models/conversationMessageFile'
import LLUploadProgress from '@/components/common/LLUploadProgress.vue'

export default {
  name: 'StudyConversationMessageEditorTextMobile',
  components: {
    LLUploadProgress,
    LLFileUploaderWrapper,
    PhotoInvertedIcon,
    LLButton,
    SendIcon,
    LLFileSlider,
    LLTextarea,
    ValidationProvider
  },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true },
    question: { type: QuestionModel, required: true },
    isSendPending: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false },
    isAbleToCancel: { type: Boolean, default: false }
  },
  data() {
    return {
      localMessage: null,
      uploadingFilesUuids: [],
      StudyConversationMessageType
    }
  },
  computed: {
    isRoundSendButtonVisible() {
      return !this.isAbleToCancel
    },
    classList() {
      return {
        [`${this.$options.name}_old`]: this.message.id,
        [`${this.$options.name}_new`]: !this.message.id
      }
    },
    isActionsVisible() {
      return this.options.type !== StudyConversationMessageType.SCREENING
    },
    isFileUploaderVisible() {
      return this.options.type !== StudyConversationMessageType.SCREENING
    }
  },
  watch: {
    message: {
      handler() {
        if (!this.$_.isEqual(this.message, this.localMessage)) {
          this.localMessage = new StudyConversationMessageModel(this.message)
        }
      },
      deep: true,
      immediate: true
    },
    localMessage: {
      handler() {
        this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
      },
      deep: true
    }
  },
  methods: {
    onSendClick() {
      this.$emit('sendClick')
    },
    onCancelClick() {
      this.$emit('cancelClick')
    },
    onPrepare(file) {
      this.localMessage.files = [
        ...this.localMessage.files,
        new ConversationMessageFileModel({
          ...file,
          prospectId: this.message.prospectId,
          messageId: this.message.id,
          studyId: this.message.studyId
        })
      ]
    },
    onStartUpload(uploadingFilesUuids) {
      this.uploadingFilesUuids = uploadingFilesUuids
    },
    onUpload(fileList) {
      const convertedFileList = fileList.map(
        (file) =>
          new ConversationMessageFileModel({
            ...file,
            questionId: this.message.questionId,
            prospectId: this.message.prospectId,
            messageId: this.message.id,
            studyId: this.message.studyId
          })
      )
      this.localMessage.files = [...this.localMessage.files, ...convertedFileList]
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageEditorTextMobile {
  @apply relative;
  &__content {
    @apply relative;
  }
  &__user-content {
    @apply flex items-end;
  }
  &__textarea-wrapper {
    @apply flex-1;
  }
  &__textarea {
    @apply w-full;
  }
  &__actions {
    @apply flex items-center ml-2;
    height: 46px;
  }
  &__file-uploader-progress {
    @apply mt-4;
  }
  &__action-wrapper {
    @apply mr-2;
    &:last-child {
      @apply mr-0;
    }
  }
  &__footer-actions {
    @apply mt-4;
  }
  &__footer-action {
    @apply ml-2;
    &:first-child {
      @apply ml-0;
    }
  }
  &_old {
    @apply pr-4 pl-5 py-4 bg-neutral-01-25 rounded relative;

    &:before {
      @apply w-1 h-full absolute bg-neutral-01-100 top-0 left-0;
      content: '';
    }
  }
  &_new {
    @apply bg-neutral-01-25 rounded mt-4;
    &:before {
      @apply absolute bg-neutral-01-25 top-0 left-0;
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-top: -16px;
      height: calc(100% + 32px);
      content: '';
    }
  }
}
</style>
