import { TopicModel } from '@/models/topic'
import { QuestionModel } from '@/models/question'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'

export class ConversationStreamModel {
  constructor(conversationStream = {}) {
    const { id, questionId, studyId, state, meta, messages, topic, question, pollType } =
      conversationStream || {}

    const { tagCounter } = {
      meta
    }

    this.id = id
    this.questionId = questionId
    this.studyId = studyId
    this.state = state
    this.messages = messages?.map((message) => new StudyConversationMessageModel(message)) || []
    this.meta = {
      tagCounter
    }
    this.topic = topic ? new TopicModel(topic) : undefined
    this.question = question ? new QuestionModel(question) : undefined
    this.pollType = pollType
  }

  static parseFromApi({ conversationStream, questionId, studyId, pollType }) {
    return new ConversationStreamModel({
      questionId: questionId || conversationStream?.questionId,
      studyId,
      id: conversationStream?.id,
      state: conversationStream?.state,
      pollType: pollType || conversationStream?.pollType,
      messages: Array.isArray(conversationStream?.messages)
        ? conversationStream.messages?.map((message) =>
            StudyConversationMessageModel.parseFromApi({
              message,
              questionId: questionId || conversationStream?.questionId,
              studyId,
              pollType: pollType || conversationStream?.pollType
            })
          )
        : [],
      meta: {
        tagCounter: conversationStream?.meta?.tagCounter
      }
    })
  }

  get hasNewMessage() {
    const messages = this.messages.filter((message) => !message.deletedAt)
    return messages[messages.length - 1]?.isOwnerUser
  }
}
