<template>
  <div class="StudyNewPageScreeningQuestions">
    <StudyNewPageScreeningQuestion
      v-for="(question, index) in orderedQuestions"
      :key="question.id"
      class="StudyNewPageScreeningQuestions__question"
      :question="question"
      :index="index"
      :message="reply({ questionId: question.id })"
      @updateTempMessage="onUpdateTempMessage"
    />
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import StudyNewPageScreeningQuestion from '@/components/pages/study-new/screening/StudyNewPageScreeningQuestion.vue'

export default {
  name: 'StudyNewPageScreeningQuestions',
  components: { StudyNewPageScreeningQuestion },
  props: {
    replies: { type: Array, default: () => {} },
    study: { type: StudyModel, required: true },
    /**
     * @type {QuestionModel[]}
     */
    screeningQuestions: { type: Array, default: () => [] }
  },
  computed: {
    orderedQuestions() {
      return [...this.screeningQuestions].sort((a, b) => a?.order - b?.order)
    }
  },
  methods: {
    reply({ questionId }) {
      return this.replies.find((reply) => reply.questionId === questionId) || null
    },
    onUpdateTempMessage({ message }) {
      this.$emit('updateTempMessage', { message })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageScreeningQuestions {
  &__question {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
}
</style>
