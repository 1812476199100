<template>
  <th class="LLTableColumn" :class="styleHeaderSorting" :style="columnWidth" :data-e2e="columnField">
    <div class="LLTableColumn-inner">
      <slot name="table-column" :column="column">
        {{ columnLabel }}
      </slot>
    </div>
  </th>
</template>

<script>
export default {
  name: 'LLTableColumn',
  props: {
    column: { type: Object, default: null },
    sorting: { type: Boolean, default: false },
    sortingType: { type: String, default: null },
    noPadding: { type: Boolean, default: false }
  },
  computed: {
    sortable() {
      return this?.column?.sortable
    },
    styleHeaderSorting() {
      const classes = []
      if (this.sortable) {
        classes.push('LLTableColumn_sortable')
      }
      if (this.sorting) {
        if (this.sortingType === 'asc') {
          classes.push('LLTableColumn_sorting-asc')
        } else if (this.sortingType === 'desc') {
          classes.push('LLTableColumn_sorting-desc')
        }
      }
      if (this.noPadding) {
        classes.push('LLTableColumn_no-padding')
      }
      return classes
    },
    columnLabel() {
      return this.column?.label
    },
    columnField() {
      return this.column?.field
    },
    columnWidth() {
      const width = this.column?.width ? `width: ${this.column.width}px;` : ''
      return width
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTableColumn {
  @apply select-none p-0;
  @apply bg-white;
  &-inner {
    @apply bg-white whitespace-nowrap text-primary-01-400 text-button-02 uppercase text-left px-5 py-4;
  }
  &:after {
    @apply w-full bg-neutral-01-50 h-px absolute bottom-0 left-0;
    content: '';
  }
  $headItem: &;
  &_sortable {
    @apply cursor-pointer;
    #{$headItem}-inner {
      @apply flex items-center;
      &:after {
        content: url(../../../../assets/images/table-sort/table-sort-default.svg);
        font-size: 0;
        @apply ml-3;
      }
    }
    &#{$headItem}_sorting-asc {
      #{$headItem}-inner {
        &:after {
          content: url(../../../../assets/images/table-sort/table-sort-asc.svg);
        }
      }
    }
    &#{$headItem}_sorting-desc {
      #{$headItem}-inner {
        &:after {
          content: url(../../../../assets/images/table-sort/table-sort-desc.svg);
        }
      }
    }
  }
  &_no-padding {
    #{$headItem}-inner {
      @apply px-0 py-0;
    }
  }
}
</style>
