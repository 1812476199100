import { Node, nodeInputRule, nodePasteRule } from '@tiptap/core'
import unicode from '@/utils/unicode'

const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/g
const Image = Node.create({
  name: 'image',
  atom: true,
  selectable: false,
  draggble: false,
  content: 'text*',
  isolating: true,
  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {}
    }
  },
  inline() {
    return this.options.inline
  },
  group() {
    return this.options.inline ? 'inline' : 'block'
  },
  draggable: false,
  addAttributes() {
    return {
      emoji: {
        default: null
      },
      emojiCode: {
        default: null
      },
      src: {
        default: null
      }
    }
  },
  renderText({ node }) {
    return `${node.attrs.emoji}`
  },
  parseHTML() {
    return [
      {
        tag: 'img[src]',
        getAttrs: (element) => {
          if (!element.classList.contains('emoji-img') || !element.hasAttribute('emojiCode')) {
            return false
          } else {
            const emojiCode = element.getAttribute('emojiCode')
            const emojiCodeList = emojiCode?.toString()?.split('+')
            return {
              emoji: String.fromCodePoint(...emojiCodeList.map((code) => parseInt(code, 16))),
              emojiCode: emojiCode
            }
          }
        }
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    let emojiCodeList
    if (!HTMLAttributes.emoji && HTMLAttributes.emojiCode) {
      emojiCodeList = HTMLAttributes.emojiCode?.toString()?.split('+')
      HTMLAttributes.emoji = String.fromCodePoint(...emojiCodeList.map((code) => parseInt(code, 16)))
    } else {
      emojiCodeList = unicode.parseEmoji(HTMLAttributes.emoji)
    }
    return [
      'img',
      {
        src: `/images/emoji/${emojiCodeList.join('_')}.png`,
        class: 'emoji-img',
        emojiCode: emojiCodeList.join('+')
      }
    ]
  },
  addCommands() {
    return {
      setEmoji:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , src] = match
          return { src }
        }
      })
    ]
  },
  addPasteRules() {
    return [
      nodePasteRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , src, emojicode] = match
          return { src, emojicode }
        }
      })
    ]
  }
})

export { Image, Image as default, inputRegex }
// # sourceMappingURL=index.js.map
