<template>
  <div class="PollNumberResultsBar">
    <PollResultsChartBar
      :chart-bar-data="chartData"
      :min="minValue"
      :max="maxValue"
      :data-label-callback="dataLabel"
      :tooltip-label-callback="tooltipLabel"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PollResultsChartBar from '@/components/modules/forum-chat/poll/results/PollResultsChartBar.vue'
import { PollModel } from '@/models/poll'
import { PollNumberStyle } from '@/common/enums'

export default {
  name: 'PollNumberResultsBar',
  components: { PollResultsChartBar },
  props: {
    data: { type: Array, required: true },
    poll: { type: PollModel, required: true },
    filterType: { type: String, default: null },
    locationGroups: { type: Array, default: () => [] }
  },
  computed: {
    ...mapState(['appData']),
    exportData() {
      return this.labels.map((label, index) => ({ category: label, average: this.bars[index] }))
    },
    bars() {
      return this.chartGroups?.[this.filterType].map(
        (filter) =>
          this.data?.find(
            (result) =>
              (result?.[this.resultServerName]?.toLowerCase
                ? result?.[this.resultServerName]?.toLowerCase()
                : result?.[this.resultServerName]) === filter.code
          )?.average ?? 0
      )
    },
    labels() {
      return this.chartGroups?.[this.filterType].map((filter) => filter.label)
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            barThickness: 18,
            backgroundColor: new Array(this.data?.length).fill('#508BBA'),
            data: this.bars
          }
        ]
      }
    },
    minValue() {
      switch (this.poll.numberStyle) {
        case PollNumberStyle.SLIDER:
          return this.poll.sliderFrom
        case PollNumberStyle.STARS:
          return 0
        case PollNumberStyle.NUMBER_INPUT:
          return 0
        default:
          return null
      }
    },
    maxValue() {
      switch (this.poll.numberStyle) {
        case PollNumberStyle.SLIDER:
          return this.poll.sliderTo
        case PollNumberStyle.STARS:
          return this.poll.starsAmount
        case PollNumberStyle.NUMBER_INPUT:
          return Math.max(...this.bars)
        default:
          return null
      }
    },
    resultServerName() {
      const filters = [
        {
          type: 'gender',
          serverName: 'gender'
        },
        {
          type: 'age',
          serverName: 'ageGroup'
        },
        {
          type: 'ethnicity',
          serverName: 'ethnicity'
        },
        {
          type: 'location',
          serverName: 'locationGroup'
        }
      ]
      return filters.find((type) => type.type === this.filterType)?.serverName
    },
    chartGroups() {
      return {
        gender: this.genderArray,
        ethnicity: this.ethnicityArray,
        location: this.locationsArray,
        age: this.ageArray
      }
    },
    locationsArray() {
      const baseLocations = Object.keys(this.appData.quantRegionLocationGroups).map((item) => ({
        label: 'US: ' + item[0].toUpperCase() + item.slice(1),
        code: item
      }))
      return [
        ...baseLocations,
        ...this.locationGroups
          .filter(
            (group) =>
              !baseLocations.find((baseLocation) => group?.toLowerCase() === baseLocation.code.toLowerCase())
          )
          .map((item) => ({
            label: item,
            code: item?.toLowerCase()
          }))
      ]
    },
    ageArray() {
      return Object.keys(this.appData.quantAgeGroups).map((key) => ({
        label: key,
        code: key
      }))
    },
    genderArray() {
      return this.$store.getters.gendersAsArray.map((gender) => ({
        label: this.$t(`gender_${gender.label}`),
        code: gender.code
      }))
    },
    ethnicityArray() {
      return this.$store.getters.ethnicitiesArray.map((ethnicity) => ({
        label: this.$t(`ethnicity_${ethnicity.label}`),
        code: ethnicity.value
      }))
    }
  },
  watch: {
    exportData: {
      handler() {
        console.log(this.exportData)
        this.$emit('updateExport', { exportData: this.exportData })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    tooltipLabel(ctx) {
      return this.$tc('forum.poll.results.average_label', ctx.parsed.x)
    },
    dataLabel(value) {
      return Math.round((Number(value) + Number.EPSILON) * 100) / 100
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberResultsBar {
}
</style>
