import Vue from 'vue'
import i18n from '@/i18n'
import Api from '../services/api'
const app = new Vue()

Vue.prototype.$getErrorMessage = (e) => {
  console.log(e)
  let message = 'error_unexpected'
  let responseMessage = ''

  if (e && e.response && e.response.data && e.response.data.key) {
    message = `error_${e.response.data.key.toLowerCase()}`
    responseMessage = e.response.data.message
  }

  return responseMessage && i18n.t(message) === message ? responseMessage : i18n.t(message)
}

Vue.prototype.$errorAlert = (e) => {
  console.log(e)
  try {
    Api.system.sendError({ message: e?.message, stack: e?.stack, e })
  } catch {}
  app.$notify({
    group: 'main',
    type: 'error',
    title: 'Error',
    text: app.$getErrorMessage(e)
  })
}

Vue.prototype.$errorAlertOfText = ({ text, title = i18n.t('toast.title.warning') }) => {
  app.$notify({
    group: 'main',
    type: 'error',
    title: title,
    text: text
  })
}

Vue.prototype.$toast = {
  error: (error) => {
    app.$notify({
      group: 'main',
      type: 'error',
      title: i18n.t('toast.title.warning'),
      text: error
    })
  }
}

Vue.prototype.$getSignInErrorMessage = (e) => {
  let key = e?.response?.data?.key
  if (key) {
    return i18n.exists(`login_page_error_${key}`)
      ? i18n.t(`login_page_error_${key}`)
      : i18n.t(`login_page_error_unexpected`)
  }
}

// Vue.prototype.$getErrorMessage = (e) => {
//   let message = 'error_unexpected'
//   let responseMessage = ''
//
//   if (e && e.response && e.response.data && e.response.data.key) {
//     message = `error_${e.response.data.key}`
//     responseMessage = e.response.data.message
//   }
//
//   return responseMessage && i18n.t(message) === message ? responseMessage : i18n.t(message)
// }
