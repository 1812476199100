<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.69531 4.25C1.00496 4.25 0.445312 3.69036 0.445312 3C0.445312 2.30964 1.00496 1.75 1.69531 1.75C2.38567 1.75 2.94531 2.30964 2.94531 3C2.94531 3.69036 2.38567 4.25 1.69531 4.25ZM14.655 2.09961H6.02476C5.5277 2.09961 5.12476 2.50255 5.12476 2.99961C5.12476 3.49667 5.5277 3.89961 6.02476 3.89961H14.655C15.1521 3.89961 15.555 3.49667 15.555 2.99961C15.555 2.50255 15.1521 2.09961 14.655 2.09961ZM6.02476 7.09961H14.655C15.1521 7.09961 15.555 7.50255 15.555 7.99961C15.555 8.49667 15.1521 8.89961 14.655 8.89961H6.02476C5.5277 8.89961 5.12476 8.49667 5.12476 7.99961C5.12476 7.50255 5.5277 7.09961 6.02476 7.09961ZM6.02476 12.0996H14.655C15.1521 12.0996 15.555 12.5026 15.555 12.9996C15.555 13.4967 15.1521 13.8996 14.655 13.8996H6.02476C5.5277 13.8996 5.12476 13.4967 5.12476 12.9996C5.12476 12.5026 5.5277 12.0996 6.02476 12.0996ZM1.69482 14.25C1.00447 14.25 0.444824 13.6904 0.444824 13C0.444824 12.3096 1.00447 11.75 1.69482 11.75C2.38518 11.75 2.94482 12.3096 2.94482 13C2.94482 13.6904 2.38518 14.25 1.69482 14.25ZM0.444824 8C0.444824 8.69036 1.00447 9.25 1.69482 9.25C2.38518 9.25 2.94482 8.69036 2.94482 8C2.94482 7.30964 2.38518 6.75 1.69482 6.75C1.00447 6.75 0.444824 7.30964 0.444824 8Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BulletListIcon'
}
</script>
