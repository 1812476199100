<template>
  <div class="SearchResponsesItem">
    <div class="SearchResponsesItem__checkbox-wrapper">
      <LLCheckbox v-model="checkboxSelected"></LLCheckbox>
    </div>
    <div class="SearchResponsesItem__content">
      <SearchResponsesItemStudy
        v-if="response.type === 30"
        :response="response"
        :with-demographics="withDemographics"
      />
      <SearchResponsesItemChat
        v-if="response.type === 160"
        :response="response"
        :with-demographics="withDemographics"
      />
    </div>
  </div>
</template>

<script>
import SearchResponsesItemStudy from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/SearchResponsesItemStudy'
import SearchResponsesItemChat from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem/SearchResponsesItemChat'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'
export default {
  name: 'SearchResponsesItem',
  components: {
    LLCheckbox,
    SearchResponsesItemChat,
    SearchResponsesItemStudy
  },
  props: {
    selected: { type: Boolean, default: false },
    response: { type: Object, required: true },
    withDemographics: { type: Boolean, default: false }
  },
  computed: {
    checkboxSelected: {
      set(value) {
        this.$emit('update:selected', value)
      },
      get() {
        return this.selected
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesItem {
  @apply flex;
  &__checkbox-wrapper {
    @apply w-10 flex-shrink-0;
  }
  &__content {
    @apply flex-1 w-full;
  }
}
</style>
