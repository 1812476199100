<template>
  <div class="DashboardWelcome" data-e2e="userProfileDashboard">
    <h3 class="DashboardWelcome__title" data-e2e="userFirstNameWelcomeBack">
      {{ $t('dashboard_profile_welcome_back', { name: userObject.firstNameUppercase || userObject.email }) }}
    </h3>
    <div class="DashboardWelcomeUserCard">
      <div class="DashboardWelcomeUserCard__image-wrapper">
        <UserImageUploader
          :editable="false"
          :string="userObject.fullName"
          medium
          :image="userObject.image"
          class="DashboardWelcomeUserCard__image"
        />
      </div>
      <div class="DashboardWelcomeUserCard__info">
        <span class="DashboardWelcomeUserCard__full-name" data-e2e="userFirstName">{{
          userObject.fullName
        }}</span>
        <span class="DashboardWelcomeUserCard__contact" data-e2e="userEmailDashboard">{{
          userObject.email
        }}</span>
        <span class="DashboardWelcomeUserCard__contact" data-e2e="userPhoneDashboard">{{
          userObject.phone
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ProspectModel } from '@/models/prospect'
import UserImageUploader from '@/components/common/UserImageUploader'

export default {
  name: 'DashboardWelcome',
  components: { UserImageUploader },
  computed: {
    ...mapState('user', ['user']),
    userObject() {
      return ProspectModel.parseFromApi({ prospect: this.user })
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardWelcome {
  @apply flex flex-col items-center;
  &__title {
    @apply mb-4;
  }
  &UserCard {
    @apply bg-neutral-01-25 rounded py-3 px-6 flex min-w-0 items-center;
    max-width: 100%;
    &__image-wrapper {
      @apply flex-shrink-0;
    }
    &__image {
      @apply w-14 h-14 rounded-full object-cover;
    }
    &__info {
      @apply min-w-0 flex flex-col ml-4;
    }
    &__full-name {
      @apply text-button-01 text-primary-01 mb-1 truncate;
    }
    &__contact {
      @apply text-neutral-01-400 text-caption-01;
    }
  }
}
</style>
