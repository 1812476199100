<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="`choice-values-${index}`"
    :rules="{ required: true, 'not-duplicated': excludeRule }"
    class="PollChoiceListEditorItem"
  >
    <div class="PollChoiceListEditorItem__body">
      <span class="PollChoiceListEditorItem__handler handler">
        <DragIcon></DragIcon>
      </span>
      <LLInputText
        ref="input"
        v-model="localChoice.text"
        :errors="errors"
        class="PollChoiceListEditorItem__input"
        :placeholder="placeholder"
      />
      <PollChoiceListEditorItemFileUploader
        v-if="isFileUploaderVisible"
        class="PollChoiceListEditorItem__image-uploader-area"
        :choice-option.sync="localChoice"
      ></PollChoiceListEditorItemFileUploader>
      <LLPopper trigger="hover" tooltip-dark tooltip-with-arrow placement="top" :hover-delay="500">
        <template #trigger>
          <LLButton
            v-if="isAbleToFix"
            class="PollChoiceListEditorItem__fix-order"
            tertiary
            :active="localChoice.isOrderFixed"
            small
            @click="localChoice.isOrderFixed = !localChoice.isOrderFixed"
          >
            <template #icon-left>
              <PinIcon class="PollChoiceListEditorItem__fix-order-icon" />
            </template>
          </LLButton>
        </template>
        <template #tooltip> {{ $t('forum.poll.editor.hints.fix_order_value') }} </template>
      </LLPopper>
      <LLButton
        :disabled="!isAbleToRemove"
        class="PollChoiceListEditorItem__remove"
        tertiary
        small
        @click="remove"
      >
        <template #icon-left>
          <ClearIcon />
        </template>
      </LLButton>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import { PollChoiceModel } from '@/models/pollChoice'
import DragIcon from '@/assets/icons/DragIcon.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLInputText from '@/components/common/LLInput/LLInputText.vue'
import ClearIcon from '@/assets/icons/ClearIcon.vue'
import PollChoiceListEditorItemFileUploader from '@/components/pages/forum/thread/poll/PollChoiceListEditorItemFileUploader.vue'
import PinIcon from '@/assets/icons/PinIcon.vue'
import LLPopper from '@/components/utils/LLPopper.vue'

export default {
  name: 'PollChoiceListEditorItem',
  components: {
    LLPopper,
    PinIcon,
    PollChoiceListEditorItemFileUploader,
    ClearIcon,
    LLInputText,
    LLButton,
    DragIcon,
    ValidationProvider
  },
  props: {
    choice: { type: PollChoiceModel, default: null },
    index: { type: Number, default: 0 },
    isFileUploaderVisible: { type: Boolean, default: true },
    placeholderFn: { type: Function, default: null },
    isAbleToRemove: { type: Boolean, default: true },
    excludeRule: { type: Array, required: true },
    isAbleToFix: { type: Boolean, default: false }
  },
  data() {
    return {
      localChoice: new PollChoiceModel()
    }
  },
  computed: {
    placeholder() {
      if (this.placeholderFn) {
        return this.placeholderFn(this.index)
      } else {
        return this.$tc('forum.poll.editor.matrix.answer', this.index + 1)
      }
    }
  },
  watch: {
    choice: {
      deep: true,
      immediate: true,
      handler() {
        this.localChoice = new PollChoiceModel(this.choice)
      }
    },
    localChoice: {
      deep: true,
      handler() {
        if (!this.$_.isEqual(this.localChoice, this.choice)) {
          this.$emit('update:choice', new PollChoiceModel(this.localChoice))
        }
      }
    },
    isAbleToFix: {
      handler(to) {
        if (!to) {
          this.localChoice.isOrderFixed = false
        }
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    },
    focus() {
      if (typeof this.$refs.input.focus === 'function') this.$refs.input.focus()
    }
  }
}
</script>

<style scoped lang="scss">
.PollChoiceListEditorItem {
  @apply w-full;
  &__body {
    @apply flex w-full;
  }
  &__input {
    @apply flex-1;
  }
  &__remove {
    @apply ml-2 mt-1.5 flex-shrink-0;
  }
  &__fix-order {
    @apply ml-2 mt-1.5 flex-shrink-0;
  }
  &__fix-order-icon {
    @apply w-4 h-4;
  }
  &__handler {
    @apply mr-2 text-neutral-01-400 select-none cursor-move;
    margin-top: 14px;
  }
  &__image-uploader-area {
    @apply w-12 ml-2 mt-1.5 h-8 flex-shrink-0 flex justify-center;
  }
}
</style>
