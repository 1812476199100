<template>
  <div v-if="studyState.isInvited || study.isInvited" class="StudyButtons">
    <div class="flex flex-wrap mt-2 mob:flex-nowrap">
      <router-link
        v-if="study.hasScreeningQuestions && descriptionButton"
        class="StudyButtons__what-this-about-button mr-5 mt-5"
        :to="`/study/${study.id}`"
      >
        <LLButton class="w-full" small data-e2e="openDescriptionInvitedStudyBtn"
          >{{ $t('conversation_what_this_about_button') }}
        </LLButton>
      </router-link>
      <router-link
        v-if="study.hasScreeningQuestions"
        class="StudyButtons__screening-button mr-5 mt-5"
        :to="`/study/${study.id}`"
      >
        <LLButton class="w-full" :small="tailwindScreens.mob" data-e2e="goToScreenInvitedStudyBtn">
          {{ $t('conversation_screening_button') }}
        </LLButton>
      </router-link>
      <LLButton
        v-else
        class="StudyButtons__accept-button mr-5 mt-5"
        :small="tailwindScreens.mob"
        :loading="isAcceptingLoading"
        data-e2e="acceptInvitedStudyBtn"
        @click="accept"
      >
        {{ $t('conversation_im_in_button') }}
      </LLButton>
      <LLButton
        class="StudyButtons__decline-button mt-5"
        :small="tailwindScreens.mob"
        secondary
        :loading="isRejectingLoading"
        data-e2e="rejectInvitedStudyBtn"
        @click="reject"
      >
        {{ $t('conversation_not_this_time_button') }}
      </LLButton>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StudyStates } from '@/mixins/studyStates'
import LLButton from '@/components/common/ui-components/LLButton'

export default {
  name: 'StudyButtons',
  components: { LLButton },
  mixins: [screenCatcher, StudyStates],
  props: {
    study: {
      type: Object,
      required: true
    },
    descriptionButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAcceptingLoading: false,
      isRejectingLoading: false
    }
  },
  methods: {
    async accept() {
      this.isAcceptingLoading = true
      try {
        const response = await Api.studies.accept({
          studyId: this.study.id
        })
        this.$emit('studyUpdate', { study: { ...this.study, ...response } })
      } catch (e) {
        console.log(e)
      } finally {
        this.isAcceptingLoading = false
      }
    },
    async reject() {
      this.isRejectingLoading = true
      try {
        const response = await Api.studies.reject({
          studyId: this.study.id
        })
        this.$emit('studyUpdate', { study: { ...this.study, ...response } })
      } catch (e) {
        console.log(e)
      } finally {
        this.isRejectingLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.StudyButtons {
  &__what-this-about-button {
    min-width: 160px;
    @screen mob {
      min-width: 0;
      @apply w-1/2;
    }
  }
  &__screening-button {
    min-width: 128px;
    @screen mob {
      min-width: 0;
      @apply w-1/2;
    }
  }
  &__accept-button {
    min-width: 160px;
    @screen mob {
      min-width: 0;
      @apply w-1/2;
    }
  }
  &__decline-button {
    min-width: 160px;
    @screen mob {
      min-width: 0;
      @apply w-1/2;
    }
  }
}
</style>
