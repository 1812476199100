<template>
  <div class="PollResultsTableRow">
    <div v-if="field === 'name'">
      <div v-if="isUser">
        <NuxtLink :to="userLink">
          <div class="PollResultsTableRowUser">
            <div class="PollResultsTableRowUser__image-wrapper">
              <ImageLoader
                small
                class="PollResultsTableRowUser__image"
                empty-picture="profile"
                :file="user.image"
                reload
              />
            </div>
            <span class="PollResultsTableRowUser__full-name"> {{ user.fullName }}</span>
          </div>
        </NuxtLink>
      </div>
      <div v-if="isProspect">
        <ParticipantImageName
          v-if="prospect"
          :full-last-name="showFullLastName"
          :click-to-show-profile="true"
          :participant="prospect"
        />
      </div>
    </div>
    <div v-else-if="field === 'age'">
      {{ age }}
    </div>
    <div v-else-if="field === 'ethnicity'">
      {{ ethnicity }}
    </div>
    <div v-else-if="field === 'location'">
      {{ location }}
    </div>
    <div v-else-if="field === 'gender'">
      {{ gender }}
    </div>
    <div v-else-if="field === 'numberValue'" class="PollResultsTableRow__number-value">
      {{ pollResponse.numberValue || $t('not-available') }}
    </div>
    <div v-else-if="field === 'matrix'">
      <div v-for="item in matrixAnswer" :key="item" class="PollResultsTableRow__ranked-item">
        {{ item }}
      </div>
    </div>
    <div v-else-if="field === 'ranked'">
      <div v-for="item in rankedListAnswer" :key="item" class="PollResultsTableRow__ranked-item">
        {{ item }}
      </div>
    </div>
    <div v-else-if="isMultiple" class="PollResultsTableRow__multiple-checkbox">
      <LLCheckboxIcon :is-checked="isCheckboxChecked" disabled></LLCheckboxIcon>
    </div>
    <div v-else-if="field === 'actions'">
      <EditPollResponseModal
        :opened.sync="editPollResponseModalOpened"
        :poll="poll"
        :response="pollResponse"
        :prospect="prospect"
        :user="user"
        @updatePollResponse="$emit('updatePollResponse')"
      />
      <DeletePollResponseModal
        :opened.sync="deletePollResponseModalOpened"
        :poll="poll"
        :prospect="prospect"
        :user="user"
        @deletePollResponse="$emit('updatePollResponse')"
      />
      <LLOptionsList
        v-if="thread?.isActive"
        class="PollResultsTableRow__actions"
        :offset="[0, 0]"
        placement="bottom-end"
      >
        <template #trigger="{ opened }">
          <LLButton :active="opened" tiny tertiary>
            <template #icon-left> <OptionsIcon /></template>
          </LLButton>
        </template>
        <template #buttons>
          <LLOptionsListButton v-popper-close small @click.native="editPollResponseModalOpened = true"
            >{{ $t('button_edit') }}
          </LLOptionsListButton>
          <LLOptionsListButton
            v-if="false"
            v-popper-close
            small
            @click.native="deletePollResponseModalOpened = true"
            >{{ $t('button_delete') }}
          </LLOptionsListButton>
        </template>
      </LLOptionsList>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
import ParticipantImageName from '@/components/modules/participants/common/ParticipantImageName.vue'
import { PollModel } from '@/models/poll'
import LLCheckboxIcon from '@/components/common/LLCheckboxIcon.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
import OptionsIcon from '@/assets/icons/OptionsIcon.vue'
import EditPollResponseModal from '@/components/modules/forum-chat/poll/results/modal/EditPollResponseModal.vue'
import DeletePollResponseModal from '@/components/modules/forum-chat/poll/results/modal/DeletePollResponseModal.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollResultsTableRow',
  components: {
    LLCheckboxIcon,
    ParticipantImageName,
    ImageLoader,
    LLButton,
    LLOptionsListButton,
    LLOptionsList,
    OptionsIcon,
    EditPollResponseModal,
    DeletePollResponseModal
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    rowProps: { type: Object, required: true },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      editPollResponseModalOpened: false,
      deletePollResponseModalOpened: false
    }
  },
  computed: {
    ...mapState(['appData']),
    user() {
      return this.rowProps.row?.user ? new UserModel(this.rowProps.row?.user) : null
    },
    prospect() {
      return this.rowProps.row?.prospect ? new ProspectModel(this.rowProps.row?.prospect) : null
    },
    isUser() {
      return !!this.user
    },
    age() {
      const currentAge = this.prospect?.age
      const ageGroup = this.ageGroupsArray.find(
        (group) =>
          (group.code[0] <= currentAge || !group.code[0]) && (group.code[1] >= currentAge || !group.code[1])
      )
      return ageGroup ? ageGroup?.label : '-'
    },
    pollResponse() {
      return this.rowProps.row.pollResponse
    },
    location() {
      const baseLocations = Object.keys(this.appData.quantRegionLocationGroups).map((item) => ({
        label: 'US: ' + item[0].toUpperCase() + item.slice(1),
        code: item
      }))
      const location = this.rowProps.row?.location
      if (!location) return '-'
      const foundBaseLocation = baseLocations.find((baseLocation) => baseLocation.code === location)
      return foundBaseLocation ? foundBaseLocation.label : location
    },
    ethnicity() {
      const ethnicityLablel = this.$store.getters?.ethnicitiesArray?.find(
        (e) => e.value === this.prospect?.ethnicity
      )?.label
      if (ethnicityLablel) {
        return this.$t(`ethnicity_${ethnicityLablel}`)
      } else {
        return '-'
      }
    },
    gender() {
      const genderLabel = this.$store.getters?.gendersArray?.find(
        (g) => g.value === this.prospect?.gender
      )?.label
      if (genderLabel) {
        return this.$t(`gender_${genderLabel}`)
      } else {
        return '-'
      }
    },
    isCheckboxChecked() {
      return !!this.rowProps.row.pollResponse.choiceList.find(
        (rowId) => rowId === this.rowProps.column.multipleRowId
      )
    },
    ageGroupsArray() {
      return Object.keys(this.appData.quantAgeGroups).map((key) => ({
        label: key,
        code: this.appData.quantAgeGroups[key]
      }))
    },
    userLink() {
      return `/user-details/${this.user.id}`
    },
    isProspect() {
      return !!this.prospect
    },
    field() {
      return this.rowProps.column.field
    },
    isMultiple() {
      return this.rowProps.column.multipleRowId
    },
    rowsOrder() {
      const rows = {}
      this.poll.rows.forEach((row) => {
        rows[row.id] = row.randomOrder
      })
      return rows
    },
    columnsOrder() {
      const columns = {}
      this.poll.columns.forEach((column) => {
        columns[column.id] = column.randomOrder
      })
      return columns
    },
    matrixAnswer() {
      return [...this.pollResponse.matrixList]
        .sort((a, b) => this.rowsOrder[a.id] - this.rowsOrder[b.id])
        .map((matrixRow) => {
          const row = this.poll.rows.find((row) => matrixRow.id === row.id)
          const columns = [...matrixRow.columnIdList]
            .sort((a, b) => this.columnsOrder[a] - this.columnsOrder[b])
            .map((columnId) => this.poll.columns.find((column) => column.id === columnId).text)
          return `${row.text}: ${columns.join(', ')};`
        })
    },
    rankedListAnswer() {
      return this.pollResponse.choiceList.map((rowId, index) => {
        const row = this.poll.rows.find((row) => rowId === row.id)
        return `${index + 1}: ${row.text};`
      })
    },
    showFullLastName() {
      return false
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsTableRow {
  &User {
    @apply flex items-center;
    &__image-wrapper {
      @apply w-7 h-7 rounded-full overflow-hidden;
    }
    &__image {
      @apply w-full h-full object-cover;
    }
    &__full-name {
      @apply text-subheading-01 ml-2 text-primary-01 truncate;
      max-width: 250px;
    }
  }
  &__multiple-checkbox {
    @apply w-full flex items-center justify-center;
  }
  &__ranked-item {
    @apply text-button-02 break-words mt-0.5;
    max-width: 250px;
  }
  &__number-value {
    @apply text-button-01;
  }
  &__actions {
    @apply flex justify-end;
  }
}
</style>
