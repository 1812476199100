<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.19958 1.66719C4.19958 1.22536 4.55776 0.867188 4.99959 0.867188H14.9996C15.4414 0.867188 15.7996 1.22536 15.7996 1.66719V6.70117H16.6662C17.3204 6.70117 17.9478 6.96105 18.4104 7.42364C18.873 7.88623 19.1329 8.51364 19.1329 9.16784V13.3345C19.1329 13.9887 18.873 14.6161 18.4104 15.0787C17.9478 15.5413 17.3204 15.8012 16.6662 15.8012H15.7996V18.3339C15.7996 18.7757 15.4414 19.1339 14.9996 19.1339H4.99959C4.55776 19.1339 4.19958 18.7757 4.19958 18.3339V15.8012H3.33288C2.67868 15.8012 2.05127 15.5413 1.58868 15.0787C1.12609 14.6161 0.866211 13.9887 0.866211 13.3345V9.16784C0.866211 8.51364 1.12609 7.88623 1.58868 7.42364C2.05127 6.96105 2.67868 6.70117 3.33288 6.70117H4.19958V1.66719ZM4.19958 14.2012V11.6672C4.19958 11.2254 4.55776 10.8672 4.99959 10.8672H14.9996C15.4414 10.8672 15.7996 11.2254 15.7996 11.6672V14.2012H16.6662C16.8961 14.2012 17.1165 14.1099 17.279 13.9473C17.4416 13.7848 17.5329 13.5644 17.5329 13.3345V9.16784C17.5329 8.93798 17.4416 8.71755 17.279 8.55501C17.1165 8.39248 16.8961 8.30117 16.6662 8.30117H3.33288C3.10302 8.30117 2.88258 8.39248 2.72005 8.55501C2.55752 8.71754 2.46621 8.93798 2.46621 9.16784V13.3345C2.46621 13.5644 2.55752 13.7848 2.72005 13.9473C2.88258 14.1099 3.10302 14.2012 3.33288 14.2012H4.19958ZM14.1996 14.9931V12.4672H5.79958V17.5339H14.1996V15.0093C14.1996 15.0066 14.1995 15.0039 14.1995 15.0012C14.1995 14.9985 14.1996 14.9958 14.1996 14.9931ZM14.1996 2.46719V6.70117H5.79958V2.46719H14.1996Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PrintIcon'
}
</script>
