import { BaseModel } from '@/models/baseModel'

export class StudyConversationMessageStreamParametersModel extends BaseModel {
  constructor(params = {}) {
    super()
    const { question, prospect, participant, user, studyId } = params || {}

    this.studyId = studyId
    this.question = question
    this.prospect = prospect
    this.user = user
    this.participant = participant
  }
}
