import Network from '../../network.js'

export default () => ({
  get({ chatId, threadId }) {
    return Network.getWithConfig(`/forum-chats/${chatId}/threads/${threadId}`, null, true)
  },
  create({ chatId, thread }) {
    return Network.post(
      `/forum-chats/${chatId}/threads`,
      {
        ...thread
      },
      true
    )
  },
  getAll({ chatId, page, perPage, searchQuery, sort, state, prospectId, cancelToken }) {
    return Network.getWithConfig(
      `/forum-chats/${chatId}/threads`,
      {
        cancelToken,
        params: {
          page,
          perPage,
          sort,
          search: searchQuery,
          prospectId,
          stateList: [state]
        }
      },
      true
    )
  },
  update({ chatId, thread }) {
    return Network.put(
      `/forum-chats/${chatId}/threads/${thread.id}`,
      {
        ...thread
      },
      true
    )
  },
  delete({ chatId, threadId }) {
    return Network.delete(`/forum-chats/${chatId}/threads/${threadId}`, null, true)
  },
  updateReactions({ chatId, threadId, emojiList }) {
    return Network.put(
      `/forum-chats/${chatId}/threads/${threadId}/reactions`,
      {
        reactionList: emojiList
      },
      true
    )
  },
  viewReaction({ chatId, threadId, emoji }) {
    return Network.post(
      `/forum-chats/${chatId}/threads/${threadId}/reactions/view`,
      {
        emoji
      },
      true
    )
  },
  viewThread({ chatId, threadId }) {
    return Network.put(`/forum-chats/${chatId}/threads/${threadId}/view`, null, true)
  }
})
