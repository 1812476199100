<template>
  <div class="LLInputError">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LLInputError'
}
</script>

<style lang="scss" scoped>
.LLInputError {
  @apply text-status-03-600 mt-1 text-caption-02 px-1;
}
</style>
