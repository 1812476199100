<template>
  <div class="PollEditorMultiple">
    <PollChoiceListEditor
      :min-choice-amount="2"
      :is-able-to-fix="localPoll.hasRandomRowsOrder"
      :poll="localPoll"
      :choice-list.sync="localPoll.rows"
    >
      <template #caption>{{ $tc('forum.poll.editor.multiple.answer_amount', rowsAmount) }}</template>
      <template #add-label>{{ $t('forum.poll.editor.multiple.add_answer') }}</template>
    </PollChoiceListEditor>
    <PollChoiceListExclusiveSelector
      v-if="localPoll.type === PollType.MULTIPLE_CHOICE"
      class="PollEditorMultiple__exclusive-selector"
      :poll="localPoll"
      :choice-list.sync="localPoll.rows"
    />
    <PollChoiceListOptions
      class="PollEditorMultiple__options"
      :poll.sync="localPoll"
      @update:answer-limit="$emit('update:answer-limit')"
    />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import PollChoiceListEditor from '@/components/pages/forum/thread/poll/PollChoiceListEditor.vue'
import PollChoiceListOptions from '@/components/pages/forum/thread/poll/PollChoiceListOptions.vue'
import PollChoiceListExclusiveSelector from '@/components/pages/forum/thread/poll/PollChoiceListExclusiveSelector.vue'
import { PollType } from '@/common/enums'

export default {
  name: 'PollEditorMultiple',
  components: { PollChoiceListExclusiveSelector, PollChoiceListOptions, PollChoiceListEditor },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      localPoll: new PollModel(this.poll)
    }
  },
  computed: {
    PollType() {
      return PollType
    },
    rowsAmount() {
      return this.localPoll.rows?.length || 0
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.PollEditorMultiple {
  &__options {
    @apply mt-8;
  }
  &__exclusive-selector {
    @apply mt-8;
  }
}
</style>
