<template>
  <div class="PollMatrixEditorDesktop" :class="classList">
    <div class="PollMatrixEditorDesktop__table-wrapper" :style="tableWrapperStyle">
      <table ref="table" class="PollMatrixEditorDesktop__table">
        <tr>
          <th></th>
          <th v-for="column in sortedColumns" :key="column.id" class="PollMatrixEditorDesktop__head-cell">
            {{ column.text }}
          </th>
        </tr>
        <tr v-for="row in sortedRows" ref="rows" :key="row.id" class="PollMatrixEditorDesktop__row">
          <th ref="row-head" class="PollMatrixEditorDesktop__first-column-cell" :style="headCellStyle">
            <div class="PollMatrixEditorDesktopRowChoice">
              <div
                class="PollMatrixEditorDesktopRowChoice__head"
                :class="{ PollMatrixEditorDesktopRowChoice__head_success: isRowHeadSuccess(row.id) }"
              >
                <Success02Icon
                  v-if="isRowHeadSuccess(row.id)"
                  class="PollMatrixEditorDesktop__row-success"
                  :class="{ PollMatrixEditorDesktop__row_success_inline: isLabelVisible }"
                />
                <div v-if="isLabelVisible" class="PollMatrixEditorDesktopRowChoice__text">
                  {{ row.text }}
                </div>
              </div>
              <div v-if="row.image" class="PollMatrixEditorDesktopRowChoice__image-wrapper">
                <LLFileSlider
                  :pre-large="showLargeThumbnail"
                  :medium="showLargeThumbnail"
                  :files="[row.image]"
                  :small="!showLargeThumbnail"
                ></LLFileSlider>
              </div>
            </div>
          </th>
          <td v-for="column in sortedColumns" :key="column.id" class="PollMatrixEditorDesktop__column">
            <LLPopper tooltip-dark tooltip-with-arrow :offset="[0, 2]" placement="top" trigger="hover">
              <template #trigger>
                <div class="PollMatrixEditorDesktop__radio-wrapper">
                  <PollMatrixEditorItem
                    v-model="selectedValues"
                    :locked="locked"
                    :poll="poll"
                    :row-id="row.id"
                    :column-id="column.id"
                  >
                  </PollMatrixEditorItem>
                </div>
              </template>
              <template #tooltip>
                <div class="PollMatrixEditorDesktop__tooltip">
                  {{ column.text }}
                </div>
              </template>
            </LLPopper>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import PollMatrixEditorItem from '@/components/modules/poll/matrix/editor/PollMatrixEditorItem.vue'
import LLPopper from '@/components/utils/LLPopper'
import { PollModel } from '@/models/poll'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import Success02Icon from '@/assets/icons/Success02Icon.vue'
export default {
  name: 'PollMatrixEditorDesktop',
  components: { LLFileSlider, PollMatrixEditorItem, LLPopper, Success02Icon },
  props: {
    value: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: false },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      selectedValues: [],
      resizeObserver: false,
      tableRowHeadWidth: 150,
      isCalculatingMargin: false
    }
  },
  computed: {
    showLargeThumbnail() {
      return this.poll.isThumbnailsLarge
    },
    hasRowWithImage() {
      return !!this.poll.rows.find((row) => row.image)
    },
    rows() {
      return this.poll.rows
    },
    columns() {
      return this.poll.columns
    },
    sortedRows() {
      return [...this.rows].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    sortedColumns() {
      return [...this.columns].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    headCellStyle() {
      return {
        width: this.isCalculatingMargin ? undefined : `${this.tableRowHeadWidth}px`
      }
    },
    tableWrapperStyle() {
      return {
        marginLeft: `${this.tableRowHeadWidth}px`,
        width: `calc(100% - ${this.tableRowHeadWidth}px)`
      }
    },
    isLabelVisible() {
      return !this.poll.isLabelsHidden
    },
    classList() {
      return {
        [`${this.$options.name}_with-row-image`]: this.hasRowWithImage,
        [`${this.$options.name}_without-row-text`]: !this.isLabelVisible,
        [`${this.$options.name}_large-row-image`]: this.showLargeThumbnail
      }
    }
  },
  watch: {
    poll: {
      handler() {
        this.$nextTick(() => {
          this.rerenderMargin()
          this.rerenderHeights()
        })
      }
    },
    value: {
      handler() {
        if (!this.$_.isEqual(this.value, this.selectedValues)) {
          this.selectedValues = this.value.map((row) => {
            return {
              id: row.id,
              columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    selectedValues: {
      handler() {
        this.$emit(
          'input',
          this.selectedValues.map((row) => {
            return {
              id: row.id,
              columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
            }
          })
        )
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.rerenderMargin()
      this.rerenderHeights()
      this.initResizeObserver()
    })
  },
  beforeDestroy() {
    this.destroyResizeObserver()
  },
  updated() {
    this.rerenderHeights()
  },
  methods: {
    rerenderMargin() {
      this.isCalculatingMargin = true
      this.$nextTick(() => {
        const maxWidth = this.$refs['row-head'].reduce(
          (acc, element) => (acc > element.offsetWidth ? acc : element.offsetWidth),
          0
        )
        this.tableRowHeadWidth = maxWidth
        this.isCalculatingMargin = false
      })
    },
    rerenderHeights() {
      this.$refs.rows.forEach((row) => {
        const head = row.querySelector('th')
        const columns = row.querySelectorAll('td')
        const headHeight = head.offsetHeight
        columns.forEach((column) => (column.style.height = `${headHeight}px`))
      })
    },
    initResizeObserver() {
      const element = this.$refs.table
      const resizeObserver = new ResizeObserver(() => {
        this.rerenderMargin()
        this.rerenderHeights()
      })

      if (element) {
        resizeObserver.observe(element)
        this.resizeObserver = resizeObserver
      }
    },
    destroyResizeObserver() {
      this.$nextTick(() => {
        if (this.resizeObserver) {
          this.resizeObserver?.disconnect && this.resizeObserver.disconnect()
        }
      })
    },
    isSelected(rowId) {
      return this.selectedValues.find((selectedValue) => selectedValue.rowId === rowId)?.columnId
    },
    onChangeSelection(columnId, rowId) {
      const selectedValue = this.selectedValues.find((selectedValue) => selectedValue.rowId === rowId)
      if (selectedValue) {
        selectedValue.columnId = columnId
      } else {
        this.selectedValues.push({
          rowId,
          columnId
        })
      }
    },
    isRowHeadSuccess(rowId) {
      return (
        !!this.selectedValues.find((row) => row.id === rowId)?.columnIdList?.length && this.isFirstTimeFill
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.PollMatrixEditorDesktop {
  $root: &;
  @apply relative;
  overflow: clip;

  &__table-wrapper {
    @apply overflow-auto;
  }

  &__table {
    min-width: 100%;
  }

  &__first-column-cell {
    @apply py-2 pr-4 absolute text-body-01 break-words text-left;
    left: 0;
    max-width: 150px;

    #{$root}_with-row-image:not(#{$root}_without-row-text) & {
      max-width: 250px;
    }

    #{$root}_with-row-image#{$root}_large-row-image:not(#{$root}_without-row-text) & {
      max-width: 350px;
    }
  }

  &__head-cell {
    max-width: 200px;
    min-width: 110px;
    vertical-align: bottom;
    @apply break-words px-4 py-2 text-body-01 text-center;
  }

  &__radio-wrapper {
    @apply flex px-4 w-full justify-center items-center;
  }

  &__radio {
    @apply m-auto;
  }

  &RowChoice {
    @apply flex items-center justify-between w-full;
    &__text {
      @apply break-words min-w-0;
    }
    &__image-wrapper {
      @apply ml-2;
      &:first-child {
        @apply ml-0;
      }
    }
    &__head_success {
      @apply text-success-01;
      text-indent: 24px;
    }
  }
  &__row-success {
    @apply w-5 h-5 text-success-01;
  }
  &__row_success_inline {
    @apply absolute mt-0.5;
  }
  &__tooltip {
    @apply text-sm overflow-hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
