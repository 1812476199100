<template>
  <div
    class="LLCheckboxIcon"
    :class="{
      LLCheckboxIcon_checked: isChecked,
      LLCheckboxIcon_disabled: disabled,
      LLCheckboxIcon_hovered: isHovered
    }"
  >
    <div class="LLCheckboxIcon__hover"></div>
    <div class="LLCheckboxIcon__border"></div>
    <div class="LLCheckboxIcon__focus"></div>
    <svg
      v-if="isChecked"
      class="LLCheckboxIcon__icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.84738 14.0601L15.2402 6.66726C15.4913 6.41623 15.4913 6.00918 15.2402 5.75814L14.3311 4.84903C14.08 4.59799 13.6731 4.59796 13.422 4.84903L7.39279 10.8781L4.57797 8.06327C4.32687 7.81224 3.91989 7.81224 3.66879 8.06327L2.75974 8.97239C2.50864 9.22343 2.50864 9.63047 2.75974 9.88151L6.9382 14.0601C7.1893 14.3112 7.59628 14.3112 7.84738 14.0601Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LLCheckboxIcon',
  props: {
    isChecked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isHovered: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.LLCheckboxIcon {
  $checkbox: &;
  width: 18px;
  height: 18px;
  @apply relative;

  &__hover {
    @apply w-8 h-8 bg-primary-01-50 opacity-50 rounded-full absolute transform scale-0 transition duration-200;
    margin-left: -7px;
    margin-top: -7px;
  }

  &__border {
    width: 18px;
    height: 18px;
    @apply border-primary-01-400 border rounded transition duration-200 absolute;
  }

  &__icon {
    width: 18px;
    height: 18px;
    @apply absolute left-0 top-0 text-white fill-current;
  }

  &_hovered {
    #{$checkbox}__hover {
      @apply scale-100;
    }
  }

  &_disabled {
    #{$checkbox}__hover {
      @apply hidden;
    }

    #{$checkbox}__border {
      @apply bg-neutral-01-15 border-neutral-01-50;
    }

    #{$checkbox}_checked {
      #{$checkbox}__border {
        @apply bg-neutral-01-25;
      }

      #{$checkbox}__icon {
        @apply text-neutral-01-50;
      }
    }
  }

  &_checked {
    #{$checkbox}__border {
      @apply border-accent-01 bg-accent-01;
    }
  }
}
</style>
