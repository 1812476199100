import { FileModel } from '@/models/file'

export class TopicModel {
  constructor(topicData = {}) {
    const {
      id,
      name,
      questionIdList,
      order,
      instructions,
      studyId,
      hasRandomQuestionOrder,
      hasRandomTopicOrder,
      lockerIds,
      hideLockedTopics,
      selectedGroups,
      isVisible,
      files,
      isLocked
    } = topicData
    this.id = id
    this.name = name || ''
    this.order = order
    this.instructions = instructions || ''
    this.questionIdList = Array.isArray(questionIdList) ? [...questionIdList] : []
    this.studyId = studyId
    this.hasRandomQuestionOrder = hasRandomQuestionOrder || false
    this.hasRandomTopicOrder = hasRandomTopicOrder || false
    this.lockerIds = Array.isArray(lockerIds) ? [...lockerIds] : []
    this.hideLockedTopics = hideLockedTopics || false
    this.isVisible = isVisible !== false
    this.selectedGroups = Array.isArray(selectedGroups) ? [...selectedGroups] : []
    this.files = Array.isArray(files) ? files.map((file) => new FileModel(file)) : []
    this.isLocked = isLocked ?? false
  }

  static parseFromApi({ topic, studyId }) {
    return new TopicModel({
      id: topic?.id || null,
      name: topic?.name || '',
      order: topic?.order || 0,
      questionIdList: topic?.questions?.map((q) => q.id) || [],
      hasRandomQuestionOrder: topic?.hasRandomQuestionOrder || false,
      hasRandomTopicOrder: topic?.hasRandomTopicOrder || false,
      lockerIds: topic?.lockerIds || [],
      instructions: topic?.instructions || '',
      hideLockedTopics: topic?.hide || false,
      isVisible: topic?.isVisible !== false,
      selectedGroups: topic?.groupSpecificSettings?.map((g) => g.id) || [],
      studyId,
      files:
        topic?.files?.map((file) => FileModel.parseFromApi({ file }))?.sort((a, b) => a?.order - b?.order) ||
        []
    })
  }

  convertToApi() {
    const {
      id,
      name,
      order,
      hasRandomQuestionOrder,
      hasRandomTopicOrder,
      lockerIds,
      instructions,
      hideLockedTopics,
      files
    } = this
    return {
      id,
      name,
      order,
      hasRandomQuestionOrder,
      hasRandomTopicOrder,
      lockerIds,
      instructions,
      hide: hideLockedTopics,
      groupSpecificSettings: this.selectedGroups?.length
        ? this.selectedGroups.map((gId) => ({
            id: gId
          }))
        : null,
      fileIdList: files.map((f, i) => ({ id: f.id, order: i }))
    }
  }
}
