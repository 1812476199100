<template>
  <div class="StudyConversationMessageBody">
    <div class="StudyConversationMessageBody__content">
      <StudyConversationMessagePoll
        v-if="question.poll && options.type === StudyConversationMessageType.ANSWER"
        ref="poll"
        class="StudyConversationMessageBody__poll"
        :question="question"
        :message="message"
        :is-compact-layout="isCompactLayout"
        :is-mobile-layout="isMobileLayout"
        locked
      />
      <StudyConversationMessageBodyText
        v-if="isTextVisible"
        ref="text"
        class="StudyConversationMessageBody__text"
        :message="message"
        :options="options"
        :is-compact-layout="isCompactLayout"
        :is-mobile-layout="isMobileLayout"
      />
    </div>
  </div>
</template>

<script>
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import StudyConversationMessagePoll from '@/components/modules/study/study-conversation-message/StudyConversationMessagePoll.vue'
import StudyConversationMessageBodyText from '@/components/modules/study/study-conversation-message/body/StudyConversationMessageBodyText.vue'

export default {
  name: 'StudyConversationMessageBody',
  components: {
    StudyConversationMessagePoll,
    StudyConversationMessageBodyText
  },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true },
    message: { type: StudyConversationMessageModel, required: true }
  },
  data() {
    return {
      hasContent: true
    }
  },
  computed: {
    isTextVisible() {
      return (
        this.options.type === StudyConversationMessageType.COMMENT ||
        this.options.type === StudyConversationMessageType.NEW_COMMENT ||
        (this.options.type === StudyConversationMessageType.ANSWER &&
          this.streamParameters.question.requireTextOrMediaResponse) ||
        (this.options.type === StudyConversationMessageType.ANSWER && !this.streamParameters.question.poll)
      )
    },
    question() {
      return this.streamParameters.question
    },
    StudyConversationMessageType() {
      return StudyConversationMessageType
    },
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout
      }
    }
  },
  mounted() {
    this.checkContent()
  },
  beforeUpdate() {
    this.checkContent()
  },
  methods: {
    onUpdateMessage(message) {
      this.$emit('updateMessage', message)
    },
    checkContent() {
      this.$nextTick(() => {
        this.hasContent =
          !!this.$refs.poll?.$el?.children ||
          !!this.$refs.text?.$el?.children ||
          !!this.$refs.transcription?.$el?.children ||
          !!this.$refs.translation?.$el?.children
      })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageBody {
  $root: &;
  &__poll {
  }
  &__text {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
    + #{$root}__translation {
      @apply mt-1;
    }
  }
  &__translation {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
  }
  &__transcriptions {
    @apply mt-2;
  }
  &__no-content {
    @apply text-neutral-01-200 italic;
  }
  &__edited {
    @apply mt-2 inline-block;
  }
}
</style>
