import { PollModel } from '@/models/poll'

export class ForumPollModel extends PollModel {
  constructor(poll) {
    const { threadId, chatId } = poll
    super(poll)

    this.threadId = threadId || null
    this.chatId = chatId || null
  }

  static parseFromApi({ poll, threadId, chatId }) {
    return new ForumPollModel({
      ...PollModel.parseFromApi({ poll }),
      threadId,
      chatId
    })
  }
}
