<template>
  <svg
    class="icon stroke-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.5 7L14.5 12L9.5 17" stroke-width="2" />
    <circle cx="12" cy="12" r="11.5" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight'
}
</script>
