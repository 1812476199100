<template>
  <div class="PollRankedResultsBar">
    <PollResultsChartBar
      :chart-bar-data="chartData"
      :data-label-callback="dataLabel"
      :data-label-filter-callback="dataLabelFilter"
      :tooltip-label-callback="tooltipLabel"
      :tooltip-filter-callback="tooltipFilter"
      :legend-label-callback="legendLabelFilter"
      :max="max"
      stacked
      data-labels-anchor="center"
      show-legend
      data-labels-align="center"
      hide-x
    />
  </div>
</template>

<script>
import PollResultsChartBar from '@/components/modules/forum-chat/poll/results/PollResultsChartBar.vue'
import { getBgColor } from '@/utils/getRandomBackground'

export default {
  name: 'PollRankedResultsBar',
  components: { PollResultsChartBar },
  props: {
    data: { type: Array, required: true }
  },
  computed: {
    max() {
      const lastValue = this.data[this.data.length - 1]
      return lastValue.offset + lastValue.ranks.reduce((acc, rank) => acc + rank.percentage, 0) || 0
    },
    chartData() {
      const backgrounds = getBgColor(this.data?.length)
      return {
        labels: this.data.map((item) => item.text),
        datasets: [
          {
            label: 'Offset',
            barThickness: 18,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            hoverBackgroundColor: 'rgba(0, 0, 0, 0)',
            data: this.data.map((row) => row.offset)
          },
          ...this.data.map((row, index) => ({
            label: this.$tc('forum.poll.results.ranked.rank_index', index + 1),
            barThickness: 18,
            backgroundColor: backgrounds[index],
            data: this.data.map((row) => row.ranks[index].percentage)
          }))
        ]
      }
    }
  },
  methods: {
    tooltipFilter(item) {
      return item.dataset.label !== 'Offset'
    },
    legendLabelFilter(item) {
      return item.text !== 'Offset'
    },
    tooltipLabel(ctx) {
      return `${ctx.dataset.label}: ${ctx.parsed.x}%`
    },
    dataLabel(value) {
      return `${value}%`
    },
    dataLabelFilter(context) {
      return context.dataset.label !== 'Offset'
    }
  }
}
</script>

<style scoped lang="scss">
.PollRankedResultsBar {
}
</style>
