<template>
  <div class="LLTextareaMenuBar">
    <div class="LLTextareaMenuBar__menu">
      <LLButton
        :disabled="disabled"
        tertiary
        :active="editor.isActive('bold')"
        small
        @click="editor.chain().focus().toggleBold().run()"
      >
        <template #icon-left>
          <BoldIcon />
        </template>
      </LLButton>
      <LLButton
        :disabled="disabled"
        tertiary
        :active="editor.isActive('italic')"
        small
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <template #icon-left>
          <ItalicIcon />
        </template>
      </LLButton>

      <LLButton
        :disabled="disabled"
        tertiary
        :active="editor.isActive('underline')"
        small
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <template #icon-left>
          <UnderlineIcon />
        </template>
      </LLButton>
      <LLButton
        :disabled="disabled"
        tertiary
        :active="editor.isActive('bulletList')"
        small
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <template #icon-left>
          <BulletListIcon />
        </template>
      </LLButton>
      <LLButton
        :disabled="disabled"
        tertiary
        :active="editor.isActive('orderedList')"
        small
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <template #icon-left>
          <OrderedListIcon />
        </template>
      </LLButton>
      <LLButton :disabled="disabled" tertiary :active="editor.isActive('link')" small @click="setUrl">
        <template #icon-left>
          <LinkIcon />
        </template>
      </LLButton>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
export default {
  name: 'LLTextareaMenuBar',
  components: { LLButton },
  props: {
    editor: { type: Object, default: null },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    setUrl() {
      if (this.editor.isActive('link')) {
        this.editor.chain().focus().unsetMark('link').run()
      } else {
        const state = this.editor.state

        // get marks, if any from selected area
        const { from, to } = state.selection
        let marks = []
        state.doc.nodesBetween(from, to, (node) => {
          marks = [...marks, ...node.marks]
        })

        const mark = marks.find((markItem) => markItem.type.name === 'link')

        let urlSetting = ''

        if (mark && mark.attrs.href) {
          const presetURL = mark.attrs.href
          urlSetting = prompt(this.$t('textarea.link.add-caption'), presetURL) // let a user see the previously set URL
        } else {
          urlSetting = prompt(this.$t('textarea.link.update-caption'), '') // a clean prompt, has had no anchor
        }

        if (!urlSetting) {
          return
        }

        if (!/^http:\/\//g.test(urlSetting) && !/^https:\/\//g.test(urlSetting)) {
          urlSetting = `https://${urlSetting}`
        }

        this.editor.commands.setLink({ href: urlSetting })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTextareaMenuBar {
  @apply flex;
}
</style>
