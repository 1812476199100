<template>
  <LLPopper :offset="[0, 0]" placement="bottom-start" trigger="manual" :show="showPopper">
    <template #trigger>
      <div ref="input-wrapper" class="LLInputSelectValues__input">
        <slot></slot>
      </div>
    </template>
    <template #tooltip>
      <div
        :style="listStyle"
        class="LLInputSelectValues__list-wrapper tooltip tooltip_without-arrow tooltip_white"
      >
        <div
          ref="list"
          class="LLInputSelectValues__list tooltip-inner"
          :style="{ maxHeight: `${maxHeight}px` }"
        >
          <LLOptionsListButton
            v-for="v in values"
            ref="selected-values"
            :key="keyFn(v)"
            :preselected="preselectedValue === v"
            :unselected="preselectedValue !== v"
            small
            @click.prevent.native="selectValue(v)"
            @mousedown.prevent.native
            @mouseenter.native="preselectValue(v)"
          >
            <div class="LLInputSelectValues__value">
              <LLCheckbox
                v-if="multiple"
                :model-value="selectedValue"
                :value="reduce(v)"
                class="LLInputSelectValues__checkbox"
                not-inline
                without-margin
                @mousedown.stop.native
                @mouseup.stop.native
                @click.stop.native
                @onCheckboxChange="onCheckboxChange"
              ></LLCheckbox>
              <div class="LLInputSelectValues__value-caption">
                {{ captionFn(v) }}
              </div>
            </div>
          </LLOptionsListButton>
        </div>
      </div>
    </template>
  </LLPopper>
</template>

<script>
import LLCheckbox from '../../LLCheckbox'
import LLPopper from '@/components/utils/LLPopper.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
export default {
  name: 'LLInputSelectValues',
  components: { LLOptionsListButton, LLPopper, LLCheckbox },
  props: {
    showPopper: { type: Boolean, default: false },
    reduce: { type: Function, default: (value) => value },
    values: { type: Array, default: () => [] },
    value: { type: [String, Number, Array], default: '' },
    captionFn: { type: Function, default: (value) => value },
    keyFn: { type: Function, default: (value) => value.key },
    multiple: { type: Boolean, default: false },
    maxHeight: { type: Number, default: 150 }
  },
  data() {
    return {
      resizeObserver: null,
      inputWidth: null,
      selectedValue: null,
      preselectedValue: null
    }
  },
  computed: {
    listStyle() {
      return {
        width: this.inputWidth ? `${this.inputWidth}px` : '300px'
      }
    },
    preselectedValueIndex() {
      return this.values.indexOf(this.preselectedValue)
    },
    valuesLength() {
      return this.values?.length || 0
    },
    hasValues() {
      return !!this.valuesLength
    }
  },
  watch: {
    selectedValue: {
      handler() {
        if (Array.isArray(this.selectedValue)) {
          this.$emit('input', [...this.selectedValue])
        } else {
          this.$emit('input', this.selectedValue)
        }
      }
    },
    value: {
      handler() {
        if (Array.isArray(this.value)) {
          if (!this.$_.isEqual(this.selectedValue, this.value)) {
            this.selectedValue = [...this.value]
          }
        } else {
          this.selectedValue = this.value
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initInputEvents()
      this.initResizeObserver()
    })
  },
  beforeDestroy() {
    this.destroyInputEvents()
    this.destroyResizeObserver()
  },
  methods: {
    onCheckboxChange(v) {
      this.selectedValue = [...v]
    },
    selectValue(v) {
      const reducedValue = this.reduce(v)
      if (!this.multiple) {
        this.selectedValue = reducedValue
      } else if (!Array.isArray(this.selectedValue)) {
        this.selectedValue = [reducedValue]
      } else if (this.selectedValue.includes(reducedValue)) {
        this.selectedValue = this.selectedValue.filter((v) => v !== reducedValue)
      } else {
        this.selectedValue = [...this.selectedValue, reducedValue]
      }
      this.$emit('closePopper')
    },
    preselectValue(value) {
      this.preselectedValue = value
    },
    initResizeObserver() {
      const inputWrapper = this.$refs?.['input-wrapper']
      if (!inputWrapper) {
        return
      }
      const resizeObserver = new ResizeObserver(() => {
        this.inputWidth = inputWrapper?.offsetWidth
      })

      resizeObserver.observe(inputWrapper)
      this.resizeObserver = resizeObserver
    },
    destroyResizeObserver() {
      this.resizeObserver.disconnect()
    },
    getInputElement() {
      const treeWalker = document.createTreeWalker(
        this.$refs?.['input-wrapper'],
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode() {
            return NodeFilter.FILTER_ACCEPT
          }
        },
        false
      )

      let node
      while ((node = treeWalker.nextNode()) && node.tagName !== 'INPUT') {}
      return node
    },
    destroyInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.removeEventListener('keydown', this.inputOnKeyDown)
      element.removeEventListener('focus', this.inputOnFocus)
      element.removeEventListener('blur', this.inputOnBlur)
    },
    initInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.addEventListener('keydown', this.inputOnKeyDown)
      element.addEventListener('focus', this.inputOnFocus)
      element.addEventListener('blur', this.inputOnBlur)
    },
    selectNextValue() {
      const selectedValue = this.values.find((v) => this.reduce(v) === this.selectedValue)
      const selectedValueIndex = this.values.indexOf(selectedValue)
      const newIndex =
        !this.selectedValue || selectedValueIndex === this.values?.length - 1 ? 0 : selectedValueIndex + 1
      this.selectedValue = this.reduce(this.values?.[newIndex])
    },
    selectPreviousValue() {
      const selectedValue = this.values.find((v) => this.reduce(v) === this.selectedValue)
      const selectedValueIndex = this.values.indexOf(selectedValue)
      const newIndex =
        !selectedValue || selectedValueIndex === 0 ? this.values?.length - 1 : selectedValueIndex - 1
      this.selectedValue = this.reduce(this.values?.[newIndex])
    },
    preselectNextValue() {
      const newIndex =
        !this.preselectedValue || this.preselectedValueIndex === this.valuesLength - 1
          ? 0
          : this.preselectedValueIndex + 1
      this.preselectedValue = this.values?.[newIndex]
    },
    preselectPreviousValue() {
      const newIndex =
        !this.preselectedValue || this.preselectedValueIndex === 0
          ? this.valuesLength - 1
          : this.preselectedValueIndex - 1
      this.preselectedValue = this.values?.[newIndex]
    },
    scrollToValue() {
      if (this.$refs.list && this.$refs['selected-values']?.[this.preselectedValueIndex]?.$el) {
        this.$refs['selected-values'][this.preselectedValueIndex].$el.scrollIntoView({
          block: 'nearest'
        })
      }
    },
    inputOnKeyDown(e) {
      if (!this.hasValues) {
        return
      }
      if (e.keyCode === 38) {
        if (!this.multiple && !this.showPopper) {
          this.selectPreviousValue()
        } else if (this.showPopper) {
          this.preselectPreviousValue()
          this.scrollToValue()
        }
      } else if (e.keyCode === 40) {
        if (!this.multiple && !this.showPopper) {
          this.selectNextValue()
        } else if (this.showPopper) {
          this.preselectNextValue()
          this.scrollToValue()
        }
      } else if (e.keyCode === 13) {
        if (this.showPopper) {
          if (this.preselectedValue) {
            e.stopPropagation()
            e.preventDefault()
            this.selectValue(this.preselectedValue)
          }
        } else {
          this.$emit('openPopper')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSelectValues {
  @apply w-full;
  &__input {
    @apply w-full;
  }
  &__list-wrapper {
  }
  &__list {
    max-height: 150px;
    @apply overflow-y-auto;
  }
  &__value {
    @apply flex items-center min-w-0;
    &-caption {
      @apply truncate;
    }
  }
  &__checkbox {
    @apply mr-2;
  }
}
</style>
