import { FileModel } from '@/models/file'
import { ForumChatState, ForumChatType } from '@/common/enums'

export class ForumChatModel {
  constructor(chatData = {}) {
    const {
      id,
      name,
      type,
      image,
      isMemberAnonymous,
      isMembersAbleThreadCreate,
      state,
      teamId,
      description,
      cloneChatId,
      meta
    } = chatData

    const { memberCount, threadCount } = meta || {}

    this.id = id || null
    this.name = name || ''
    this.type = type || 10
    this.image = image || null
    this.teamId = teamId || null
    this.isMemberAnonymous = isMemberAnonymous || false
    this.isMembersAbleThreadCreate = isMembersAbleThreadCreate || false
    this.description = description || ''
    this.state = state || 10
    this.cloneChatId = cloneChatId || null
    this.meta = {
      memberCount: memberCount || 0,
      threadCount: threadCount || 0
    }
  }

  static parseFromApi({ chat }) {
    return new ForumChatModel({
      id: chat?.id,
      name: chat?.name,
      type: chat?.type,
      image: chat?.image ? FileModel.parseFromApi({ file: chat?.image }) : null,
      description: chat?.objective,
      teamId: chat?.team?.id,
      state: chat?.state,
      isMemberAnonymous: chat?.isMemberAnonymous,
      isMembersAbleThreadCreate: chat?.isMembersAbleThreadCreate,
      meta: {
        memberCount: chat?.meta?.memberCount,
        threadCount: chat?.meta?.threadCount
      }
    })
  }

  setMetaFromApi({ meta }) {
    this.meta = {
      memberCount: meta?.memberCount,
      threadCount: meta?.threadCount
    }
  }

  convertToApi() {
    const {
      id,
      name,
      type,
      description,
      image,
      isMemberAnonymous,
      isMembersAbleThreadCreate,
      team,
      teamId,
      cloneChatId
    } = this
    return {
      id,
      name,
      type,
      objective: description,
      isMemberAnonymous,
      isMembersAbleThreadCreate,
      teamId: teamId || team?.id,
      imageId: image?.id || null,
      cloneChatId: cloneChatId || undefined
    }
  }

  get isCompleted() {
    return this.state === ForumChatState.COMPLETED
  }

  get isDraft() {
    return this.state === ForumChatState.DRAFT
  }

  get isLive() {
    return this.state === ForumChatState.LIVE
  }

  get isArchived() {
    return this.state === ForumChatState.ARCHIVED
  }

  get isWechat() {
    return this.type === ForumChatType.WECHAT
  }
}
