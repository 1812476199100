<template>
  <svg
    class="PollIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.2365 19.9159C3.16207 19.8697 3.07867 19.7909 3.05117 19.7408C3.01311 19.6715 3.00107 19.1924 3.00074 17.7328C3.00032 15.8745 3.00278 15.812 3.08103 15.6937C3.24157 15.451 2.93809 15.4636 8.34292 15.4743L13.2462 15.4839L13.3831 15.6216L13.52 15.7593V17.7156C13.52 19.8877 13.525 19.845 13.2586 19.9476C13.1436 19.9919 12.3703 20 8.2472 20H3.37183L3.2365 19.9159ZM12.6 17.7512V16.3503H10.26H7.92V17.7512V19.1521H10.26H12.6V17.7512ZM3.20867 14.1914C2.99882 14.0559 3 14.0683 3 12.0001C3 9.93194 2.99882 9.94429 3.20867 9.80882C3.28878 9.75711 4.17902 9.75129 12 9.75129C19.821 9.75129 20.7112 9.75711 20.7913 9.80882C21.0012 9.94429 21 9.93194 21 12.0001C21 14.0683 21.0013 14.0559 20.7913 14.1914C20.6551 14.2794 3.34495 14.2794 3.20867 14.1914ZM20.08 12.0001V10.5992H14H7.92V12.0001V13.401H14H20.08V12.0001ZM5.9663 12.2928C6.66453 11.6209 6.77575 11.4834 6.73965 11.3365C6.70592 11.1993 6.53804 11.0785 6.38126 11.0785C6.26354 11.0785 6.1979 11.125 5.84866 11.4563C5.62958 11.6642 5.39122 11.8974 5.31897 11.9746L5.18762 12.115L4.93788 11.8732C4.71939 11.6617 4.66989 11.6314 4.54225 11.6314C4.27805 11.6314 4.09559 11.8898 4.21306 12.0975C4.24583 12.1555 4.44079 12.3648 4.64632 12.5628C5.18532 13.082 5.13553 13.0921 5.9663 12.2928ZM3.28 8.47014C2.99566 8.33922 3.00013 8.3749 3.00006 6.23798C3.00002 4.60308 3.00873 4.31278 3.06068 4.22019C3.19275 3.98481 2.72524 4.00021 9.74201 4.00021H16.128L16.264 4.12555L16.4 4.25089V6.22874C16.4 8.41524 16.4062 8.36052 16.1457 8.47444C16.02 8.52943 15.4565 8.53471 9.71396 8.53465C3.80196 8.53462 3.4115 8.53068 3.28 8.47014ZM15.4705 6.25824L15.46 4.86655L11.69 4.85715L7.92001 4.84774V6.24884V7.64993H11.7005H15.481L15.4705 6.25824Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PollIcon'
}
</script>

<style scoped lang="scss">
.PollIcon {
  @apply fill-current;
}
</style>
