<template>
  <div class="ForumThreadEditorPage">
    <ForumChatSmallDetails class="ForumThreadEditorPage__chat-details" :chat="chat" />
    <router-link :to="`/chats/${chat.id}`">
      <LLButton class="ForumThreadEditorPage__back-button" secondary small>
        <template #icon-left>
          <ArrowMiniIcon class="ForumThreadEditorPage__back-button-arrow"></ArrowMiniIcon>
        </template>
        {{ $t('forum.thread_page.back_button') }}
      </LLButton>
    </router-link>
    <div class="ForumThreadEditorPage__editor-wrapper">
      <ForumThreadEditorBody :chat="chat" :thread="thread" />
    </div>
  </div>
</template>

<script>
import { ForumChatModel } from '@/models/forum/forumChat'
import ForumChatSmallDetails from '@/components/modules/forum-chat/ForumChatSmallDetails'
import ForumThreadEditorBody from '@/components/pages/forum/thread/editor/ForumThreadEditorBody'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLButton from '@/components/common/ui-components/LLButton'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'

export default {
  name: 'ForumThreadEditorPage',
  components: { ArrowMiniIcon, LLButton, ForumThreadEditorBody, ForumChatSmallDetails },
  props: {
    chat: { type: ForumChatModel, required: true },
    thread: { type: ForumThreadModel, default: null }
  }
}
</script>

<style lang="scss" scoped>
.ForumThreadEditorPage {
  @apply w-full;
  &__editor-wrapper {
    @apply bg-neutral-01-15 p-6 mx-auto w-full relative;
    &:before {
      @apply w-1 bg-neutral-01-50 h-full absolute left-0 top-0;
      content: '';
    }
  }
  &__back-button {
    @apply my-4;
    &-arrow {
      @apply rotate-180 transform;
    }
  }
}
</style>
