<template>
  <div class="StudyConversationMessageAvatar" :class="classList">
    <div v-if="isOwnerVisible" class="StudyConversationMessageAvatar__user">
      <ProspectAvatar v-if="!message.isOwnerUser" small :prospect="prospect"></ProspectAvatar>
      <UserAvatar v-else small :user="user"></UserAvatar>
    </div>
    <div v-else-if="isOwnerModerator" class="StudyConversationMessageAvatar__moderator">
      {{ moderatorReplyLetter }}
    </div>
  </div>
</template>

<script>
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import ProspectAvatar from '@/components/modules/prospects/ProspectAvatar.vue'
import UserAvatar from '@/components/modules/users/UserAvatar.vue'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'

export default {
  name: 'StudyConversationMessageAvatar',
  components: { UserAvatar, ProspectAvatar },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    message: { type: StudyConversationMessageModel, required: true },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout
      }
    },
    prospect() {
      return this.streamParameters.prospect
    },
    user() {
      return this.message.user
    },
    isOwnerVisible() {
      return !this.message.isOwnerUser
    },
    isOwnerModerator() {
      return this.message.isOwnerUser
    },
    moderatorReplyLetter() {
      return this.$t('study_conversation_message.hidden_moderator_name').slice(0, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageAvatar {
  $root: &;
  &__user {
    @apply w-7 h-7 flex-shrink-0;

    @at-root #{$root}_mobile & {
      @apply w-6 h-6;
    }
  }
  &__moderator {
    @apply w-7 h-7 rounded-full text-body-02 bg-neutral-01-400 text-white flex items-center justify-center;

    @at-root #{$root}_mobile & {
      @apply w-6 h-6;
    }
  }
}
</style>
