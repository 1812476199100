<template>
  <div class="LLOptionsList" :class="optionsListClasses">
    <div v-if="hideTooltip" class="LLOptionsList__trigger">
      <slot name="trigger"></slot>
    </div>
    <LLPopper
      v-else
      :show="opened"
      :disabled="disabled"
      :strategy="strategy"
      tooltip-white
      :placement="placement"
      :offset="offset"
      @show="onShow"
      @hide="onHide"
    >
      <template #trigger>
        <div class="LLOptionsList__trigger">
          <slot :opened="opened" name="trigger"></slot>
        </div>
      </template>
      <template #tooltip>
        <div class="LLOptionsList__buttons" :class="popperClass" :style="contentStyle">
          <slot name="buttons"></slot>
        </div>
      </template>
    </LLPopper>
  </div>
</template>

<script>
import LLPopper from '@/components/utils/LLPopper'
export default {
  name: 'LLOptionsList',
  components: {
    LLPopper
  },
  props: {
    disabled: { type: Boolean, default: false },
    contentMaxHeight: { type: Number, default: null },
    triggerType: { type: String, default: 'clickToToggle' },
    withoutArrow: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    tooltipClass: { type: Array, default: () => [] },
    offset: { type: Array, default: () => [5, 5] },
    minWidth: { type: Number, default: 140 },
    maxWidth: { type: Number, default: null },
    placement: { type: String, default: 'bottom-end' },
    hideTooltip: { type: Boolean, default: false },
    withoutPadding: { type: Boolean, default: false },
    strategy: { type: String, default: 'fixed' }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    contentStyle() {
      const style = []
      if (this.contentMaxHeight) {
        style.push({ 'max-height': `${this.contentMaxHeight}px` })
        style.push({ 'overflow-y': `auto` })
      }
      if (this.maxWidth) {
        style.push({ 'max-width': `${this.maxWidth}px` })
      }
      style.push({ 'min-width': `${this.minWidth}px` })
      return style
    },
    popperClass() {
      const classes = []
      const rootClass = 'popper'
      if (this.withoutArrow) {
        classes.push(`${rootClass}_without-arrow`)
      }
      if (this.withoutPadding) {
        classes.push(`${rootClass}_without-padding]`)
      }
      return classes
    },
    optionsListClasses() {
      const classes = []
      const rootClass = 'LLOptionsList'
      if (this.hideTooltip) {
        classes.push(`${rootClass}_tooltip-hidden`)
      }
      return classes
    }
  },
  watch: {
    open: {
      immediate: true,
      handler() {
        this.opened = this.open
      }
    },
    opened: {
      immediate: true,
      handler() {
        this.$emit('update:open', this.opened)
      }
    }
  },
  methods: {
    onHide() {
      this.opened = false
      this.$emit('hide')
    },
    onShow() {
      this.opened = true
      this.$emit('show')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLOptionsList {
  $root: &;
  &_tooltip-hidden {
    #{$root}__trigger {
      @apply cursor-default;
    }
  }
  ::v-deep .trigger {
    display: block !important;
  }
  &__trigger {
    @apply cursor-pointer w-full;
  }
  &__buttons {
    @apply py-2 flex flex-col min-h-0;
  }
  &__without-padding {
    @apply py-0;
  }
}
</style>
