<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="white" />
    <path
      class="fill-current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM12.4336 13.565L10 11.1314L7.56641 13.565L6.43504 12.4336L8.86863 10L6.43359 7.56496L7.56496 6.43359L10 8.86863L12.435 6.43359L13.5664 7.56496L11.1314 10L13.565 12.4336L12.4336 13.565Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FileUploaderCancelIcon'
}
</script>
