<template>
  <div class="LLPage">
    <div class="LLPage__breadcrumbs">
      <slot name="breadcrumbs"></slot>
    </div>
    <div class="LLPage__container container">
      <div v-if="slots.title" class="LLPage__title">
        <slot name="title"></slot>
      </div>
      <div class="LLPage__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLPage',
  data() {
    return {
      slots: {
        title: false,
        breadCrumbs: false,
        content: false
      }
    }
  },
  mounted() {
    this.checkSlots()
  },
  beforeUpdate() {
    this.checkSlots()
  },
  methods: {
    checkSlots() {
      this.slots.title = !!this.$slots?.title?.[0]
      this.slots.breadCrumbs = !!this.$slots?.breadCrumbs?.[0]
      this.slots.content = !!this.$slots?.content?.[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.LLPage {
  @apply w-full;
  &__container {
    @apply pt-6 pb-8;
  }
  &__title {
    @apply mb-6 truncate min-w-0;
  }
  &__content {
  }
}
</style>
