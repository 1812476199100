<template>
  <div class="ForumChatMainPage">
    <ForumChatEditorDetailsContent
      v-if="chat"
      is-from-editor
      :chat="chat"
      class="ForumChatEditorDetails__content"
    />
    <ForumChatMainThreads v-if="chat && hasThreads" class="ForumChatMainPage__threads" :chat="chat" />
    <ForumChatMainEmpty v-if="chat && !hasThreads" class="ForumChatMainPage__empty" :chat="chat" />
  </div>
</template>

<script>
import { ForumChatModel } from '@/models/forum/forumChat'
import ForumChatEditorDetailsContent from '@/components/pages/forum/chat/editor/main/ForumChatEditorDetailsContent'
import ForumChatMainThreads from '@/components/pages/forum/chat/main/ForumChatMainThreads'
import ForumChatMainEmpty from '@/components/pages/forum/chat/main/ForumChatMainEmpty'

export default {
  name: 'ForumChatMainPage',
  components: {
    ForumChatMainEmpty,
    ForumChatMainThreads,
    ForumChatEditorDetailsContent
  },
  props: {
    chat: { type: ForumChatModel, default: null }
  },
  computed: {
    hasThreads() {
      return !!this.chat?.meta?.threadCount
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatMainPage {
  &__threads {
    @apply mt-6;
  }
  &__empty {
    @apply my-6;
  }
}
</style>
