<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1455 2.12053C17.5161 1.49112 16.7548 1.10148 16.0055 1.01756C15.1663 0.921647 14.3571 1.22137 13.7276 1.85078L13.4639 2.11454C13.4639 2.11454 13.4579 2.11454 13.4579 2.12053L11.8094 3.769L11.8034 3.77499L2.35023 13.2222C2.26031 13.3121 2.20636 13.426 2.17639 13.5399L1.01947 18.4673C0.965518 18.6891 1.03146 18.9169 1.19331 19.0787C1.31919 19.2046 1.48104 19.2706 1.65488 19.2706C1.70283 19.2706 1.75678 19.2646 1.80474 19.2526L6.72616 18.0957C6.83406 18.0717 6.94195 18.0177 7.03187 17.9338C7.03787 17.9278 7.04386 17.9218 7.04985 17.9158L16.509 8.45663C16.509 8.45663 16.515 8.45064 16.515 8.44464L18.1455 6.80817L18.4153 6.53842C19.0447 5.909 19.3384 5.09976 19.2485 4.26054C19.1646 3.50524 18.7749 2.74395 18.1455 2.12053ZM2.52407 17.742L3.17746 14.9726L5.29948 17.0886L2.52407 17.742ZM6.58229 16.5371L3.73494 13.6898L12.271 5.14771L15.1183 7.99506L6.58229 16.5371ZM16.0355 7.07792L13.1881 4.23057L13.9195 3.49925L16.7668 6.3466L16.0355 7.07792ZM14.8546 2.59409C15.1543 2.3663 15.49 2.2644 15.8617 2.30636C16.3172 2.35431 16.8028 2.61807 17.2224 3.03768C17.642 3.45729 17.8998 3.94283 17.9537 4.39841C17.9957 4.76407 17.8938 5.10575 17.666 5.40547L14.8546 2.59409Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon'
}
</script>
