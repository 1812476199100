import unicode from '@/utils/unicode'

// Object copied from emoji.json from folder json-parser
const emObject = {
  23: {
    fe0f: {
      '20e3': {
        emoji: '#️⃣'
      }
    }
  },
  30: {
    fe0f: {
      '20e3': {
        emoji: '0️⃣'
      }
    }
  },
  31: {
    fe0f: {
      '20e3': {
        emoji: '1️⃣'
      }
    }
  },
  32: {
    fe0f: {
      '20e3': {
        emoji: '2️⃣'
      }
    }
  },
  33: {
    fe0f: {
      '20e3': {
        emoji: '3️⃣'
      }
    }
  },
  34: {
    fe0f: {
      '20e3': {
        emoji: '4️⃣'
      }
    }
  },
  35: {
    fe0f: {
      '20e3': {
        emoji: '5️⃣'
      }
    }
  },
  36: {
    fe0f: {
      '20e3': {
        emoji: '6️⃣'
      }
    }
  },
  37: {
    fe0f: {
      '20e3': {
        emoji: '7️⃣'
      }
    }
  },
  38: {
    fe0f: {
      '20e3': {
        emoji: '8️⃣'
      }
    }
  },
  39: {
    fe0f: {
      '20e3': {
        emoji: '9️⃣'
      }
    }
  },
  2049: {
    fe0f: {
      emoji: '⁉️'
    }
  },
  2122: {
    fe0f: {
      emoji: '™️'
    }
  },
  2139: {
    fe0f: {
      emoji: 'ℹ️'
    }
  },
  2194: {
    fe0f: {
      emoji: '↔️'
    }
  },
  2195: {
    fe0f: {
      emoji: '↕️'
    }
  },
  2196: {
    fe0f: {
      emoji: '↖️'
    }
  },
  2197: {
    fe0f: {
      emoji: '↗️'
    }
  },
  2198: {
    fe0f: {
      emoji: '↘️'
    }
  },
  2199: {
    fe0f: {
      emoji: '↙️'
    }
  },
  2328: {
    fe0f: {
      emoji: '⌨️'
    }
  },
  2600: {
    fe0f: {
      emoji: '☀️'
    }
  },
  2601: {
    fe0f: {
      emoji: '☁️'
    }
  },
  2602: {
    fe0f: {
      emoji: '☂️'
    }
  },
  2603: {
    fe0f: {
      emoji: '☃️'
    }
  },
  2604: {
    fe0f: {
      emoji: '☄️'
    }
  },
  2611: {
    fe0f: {
      emoji: '☑️'
    }
  },
  2614: {
    emoji: '☔'
  },
  2615: {
    emoji: '☕'
  },
  2618: {
    fe0f: {
      emoji: '☘️'
    }
  },
  2620: {
    fe0f: {
      emoji: '☠️'
    }
  },
  2622: {
    fe0f: {
      emoji: '☢️'
    }
  },
  2623: {
    fe0f: {
      emoji: '☣️'
    }
  },
  2626: {
    fe0f: {
      emoji: '☦️'
    }
  },
  2638: {
    fe0f: {
      emoji: '☸️'
    }
  },
  2639: {
    fe0f: {
      emoji: '☹️'
    }
  },
  2640: {
    fe0f: {
      emoji: '♀️'
    }
  },
  2642: {
    fe0f: {
      emoji: '♂️'
    }
  },
  2648: {
    emoji: '♈'
  },
  2649: {
    emoji: '♉'
  },
  2650: {
    emoji: '♐'
  },
  2651: {
    emoji: '♑'
  },
  2652: {
    emoji: '♒'
  },
  2653: {
    emoji: '♓'
  },
  2660: {
    fe0f: {
      emoji: '♠️'
    }
  },
  2663: {
    fe0f: {
      emoji: '♣️'
    }
  },
  2665: {
    fe0f: {
      emoji: '♥️'
    }
  },
  2666: {
    fe0f: {
      emoji: '♦️'
    }
  },
  2668: {
    fe0f: {
      emoji: '♨️'
    }
  },
  2692: {
    fe0f: {
      emoji: '⚒️'
    }
  },
  2693: {
    emoji: '⚓'
  },
  2694: {
    fe0f: {
      emoji: '⚔️'
    }
  },
  2695: {
    fe0f: {
      emoji: '⚕️'
    }
  },
  2696: {
    fe0f: {
      emoji: '⚖️'
    }
  },
  2697: {
    fe0f: {
      emoji: '⚗️'
    }
  },
  2699: {
    fe0f: {
      emoji: '⚙️'
    }
  },
  2702: {
    fe0f: {
      emoji: '✂️'
    }
  },
  2705: {
    emoji: '✅'
  },
  2708: {
    fe0f: {
      emoji: '✈️'
    }
  },
  2709: {
    fe0f: {
      emoji: '✉️'
    }
  },
  2712: {
    fe0f: {
      emoji: '✒️'
    }
  },
  2714: {
    fe0f: {
      emoji: '✔️'
    }
  },
  2716: {
    fe0f: {
      emoji: '✖️'
    }
  },
  2721: {
    fe0f: {
      emoji: '✡️'
    }
  },
  2728: {
    emoji: '✨'
  },
  2733: {
    fe0f: {
      emoji: '✳️'
    }
  },
  2734: {
    fe0f: {
      emoji: '✴️'
    }
  },
  2744: {
    fe0f: {
      emoji: '❄️'
    }
  },
  2747: {
    fe0f: {
      emoji: '❇️'
    }
  },
  2753: {
    emoji: '❓'
  },
  2754: {
    emoji: '❔'
  },
  2755: {
    emoji: '❕'
  },
  2757: {
    emoji: '❗'
  },
  2763: {
    fe0f: {
      emoji: '❣️'
    }
  },
  2764: {
    fe0f: {
      '200d': {
        '1f525': {
          emoji: '❤️‍🔥'
        },
        '1fa79': {
          emoji: '❤️‍🩹'
        }
      },
      emoji: '❤️'
    }
  },
  2795: {
    emoji: '➕'
  },
  2796: {
    emoji: '➖'
  },
  2797: {
    emoji: '➗'
  },
  2934: {
    fe0f: {
      emoji: '⤴️'
    }
  },
  2935: {
    fe0f: {
      emoji: '⤵️'
    }
  },
  3030: {
    fe0f: {
      emoji: '〰️'
    }
  },
  3297: {
    fe0f: {
      emoji: '㊗️'
    }
  },
  3299: {
    fe0f: {
      emoji: '㊙️'
    }
  },
  '1f600': {
    emoji: '😀'
  },
  '1f603': {
    emoji: '😃'
  },
  '1f604': {
    emoji: '😄'
  },
  '1f601': {
    emoji: '😁'
  },
  '1f606': {
    emoji: '😆'
  },
  '1f605': {
    emoji: '😅'
  },
  '1f923': {
    emoji: '🤣'
  },
  '1f602': {
    emoji: '😂'
  },
  '1f642': {
    emoji: '🙂'
  },
  '1f643': {
    emoji: '🙃'
  },
  '1fae0': {
    emoji: '🫠'
  },
  '1f609': {
    emoji: '😉'
  },
  '1f60a': {
    emoji: '😊'
  },
  '1f607': {
    emoji: '😇'
  },
  '1f970': {
    emoji: '🥰'
  },
  '1f60d': {
    emoji: '😍'
  },
  '1f929': {
    emoji: '🤩'
  },
  '1f618': {
    emoji: '😘'
  },
  '1f617': {
    emoji: '😗'
  },
  '263a': {
    fe0f: {
      emoji: '☺️'
    }
  },
  '1f61a': {
    emoji: '😚'
  },
  '1f619': {
    emoji: '😙'
  },
  '1f972': {
    emoji: '🥲'
  },
  '1f60b': {
    emoji: '😋'
  },
  '1f61b': {
    emoji: '😛'
  },
  '1f61c': {
    emoji: '😜'
  },
  '1f92a': {
    emoji: '🤪'
  },
  '1f61d': {
    emoji: '😝'
  },
  '1f911': {
    emoji: '🤑'
  },
  '1f917': {
    emoji: '🤗'
  },
  '1f92d': {
    emoji: '🤭'
  },
  '1fae2': {
    emoji: '🫢'
  },
  '1fae3': {
    emoji: '🫣'
  },
  '1f92b': {
    emoji: '🤫'
  },
  '1f914': {
    emoji: '🤔'
  },
  '1fae1': {
    emoji: '🫡'
  },
  '1f910': {
    emoji: '🤐'
  },
  '1f928': {
    emoji: '🤨'
  },
  '1f610': {
    emoji: '😐'
  },
  '1f611': {
    emoji: '😑'
  },
  '1f636': {
    emoji: '😶',
    '200d': {
      '1f32b': {
        fe0f: {
          emoji: '😶‍🌫️'
        }
      }
    }
  },
  '1fae5': {
    emoji: '🫥'
  },
  '1f60f': {
    emoji: '😏'
  },
  '1f612': {
    emoji: '😒'
  },
  '1f644': {
    emoji: '🙄'
  },
  '1f62c': {
    emoji: '😬'
  },
  '1f62e': {
    '200d': {
      '1f4a8': {
        emoji: '😮‍💨'
      }
    },
    emoji: '😮'
  },
  '1f925': {
    emoji: '🤥'
  },
  '1f60c': {
    emoji: '😌'
  },
  '1f614': {
    emoji: '😔'
  },
  '1f62a': {
    emoji: '😪'
  },
  '1f924': {
    emoji: '🤤'
  },
  '1f634': {
    emoji: '😴'
  },
  '1f637': {
    emoji: '😷'
  },
  '1f912': {
    emoji: '🤒'
  },
  '1f915': {
    emoji: '🤕'
  },
  '1f922': {
    emoji: '🤢'
  },
  '1f92e': {
    emoji: '🤮'
  },
  '1f927': {
    emoji: '🤧'
  },
  '1f975': {
    emoji: '🥵'
  },
  '1f976': {
    emoji: '🥶'
  },
  '1f974': {
    emoji: '🥴'
  },
  '1f635': {
    emoji: '😵',
    '200d': {
      '1f4ab': {
        emoji: '😵‍💫'
      }
    }
  },
  '1f92f': {
    emoji: '🤯'
  },
  '1f920': {
    emoji: '🤠'
  },
  '1f973': {
    emoji: '🥳'
  },
  '1f978': {
    emoji: '🥸'
  },
  '1f60e': {
    emoji: '😎'
  },
  '1f913': {
    emoji: '🤓'
  },
  '1f9d0': {
    emoji: '🧐'
  },
  '1f615': {
    emoji: '😕'
  },
  '1fae4': {
    emoji: '🫤'
  },
  '1f61f': {
    emoji: '😟'
  },
  '1f641': {
    emoji: '🙁'
  },
  '1f62f': {
    emoji: '😯'
  },
  '1f632': {
    emoji: '😲'
  },
  '1f633': {
    emoji: '😳'
  },
  '1f97a': {
    emoji: '🥺'
  },
  '1f979': {
    emoji: '🥹'
  },
  '1f626': {
    emoji: '😦'
  },
  '1f627': {
    emoji: '😧'
  },
  '1f628': {
    emoji: '😨'
  },
  '1f630': {
    emoji: '😰'
  },
  '1f625': {
    emoji: '😥'
  },
  '1f622': {
    emoji: '😢'
  },
  '1f62d': {
    emoji: '😭'
  },
  '1f631': {
    emoji: '😱'
  },
  '1f616': {
    emoji: '😖'
  },
  '1f623': {
    emoji: '😣'
  },
  '1f61e': {
    emoji: '😞'
  },
  '1f613': {
    emoji: '😓'
  },
  '1f629': {
    emoji: '😩'
  },
  '1f62b': {
    emoji: '😫'
  },
  '1f971': {
    emoji: '🥱'
  },
  '1f624': {
    emoji: '😤'
  },
  '1f621': {
    emoji: '😡'
  },
  '1f620': {
    emoji: '😠'
  },
  '1f92c': {
    emoji: '🤬'
  },
  '1f608': {
    emoji: '😈'
  },
  '1f47f': {
    emoji: '👿'
  },
  '1f480': {
    emoji: '💀'
  },
  '1f4a9': {
    emoji: '💩'
  },
  '1f921': {
    emoji: '🤡'
  },
  '1f479': {
    emoji: '👹'
  },
  '1f47a': {
    emoji: '👺'
  },
  '1f47b': {
    emoji: '👻'
  },
  '1f47d': {
    emoji: '👽'
  },
  '1f47e': {
    emoji: '👾'
  },
  '1f916': {
    emoji: '🤖'
  },
  '1f63a': {
    emoji: '😺'
  },
  '1f638': {
    emoji: '😸'
  },
  '1f639': {
    emoji: '😹'
  },
  '1f63b': {
    emoji: '😻'
  },
  '1f63c': {
    emoji: '😼'
  },
  '1f63d': {
    emoji: '😽'
  },
  '1f640': {
    emoji: '🙀'
  },
  '1f63f': {
    emoji: '😿'
  },
  '1f63e': {
    emoji: '😾'
  },
  '1f648': {
    emoji: '🙈'
  },
  '1f649': {
    emoji: '🙉'
  },
  '1f64a': {
    emoji: '🙊'
  },
  '1f48b': {
    emoji: '💋'
  },
  '1f48c': {
    emoji: '💌'
  },
  '1f498': {
    emoji: '💘'
  },
  '1f49d': {
    emoji: '💝'
  },
  '1f496': {
    emoji: '💖'
  },
  '1f497': {
    emoji: '💗'
  },
  '1f493': {
    emoji: '💓'
  },
  '1f49e': {
    emoji: '💞'
  },
  '1f495': {
    emoji: '💕'
  },
  '1f49f': {
    emoji: '💟'
  },
  '1f494': {
    emoji: '💔'
  },
  '1f9e1': {
    emoji: '🧡'
  },
  '1f49b': {
    emoji: '💛'
  },
  '1f49a': {
    emoji: '💚'
  },
  '1f499': {
    emoji: '💙'
  },
  '1f49c': {
    emoji: '💜'
  },
  '1f90e': {
    emoji: '🤎'
  },
  '1f5a4': {
    emoji: '🖤'
  },
  '1f90d': {
    emoji: '🤍'
  },
  '1f4af': {
    emoji: '💯'
  },
  '1f4a2': {
    emoji: '💢'
  },
  '1f4a5': {
    emoji: '💥'
  },
  '1f4ab': {
    emoji: '💫'
  },
  '1f4a6': {
    emoji: '💦'
  },
  '1f4a8': {
    emoji: '💨'
  },
  '1f573': {
    fe0f: {
      emoji: '🕳️'
    }
  },
  '1f4a3': {
    emoji: '💣'
  },
  '1f4ac': {
    emoji: '💬'
  },
  '1f441': {
    fe0f: {
      '200d': {
        '1f5e8': {
          fe0f: {
            emoji: '👁️‍🗨️'
          }
        }
      },
      emoji: '👁️'
    }
  },
  '1f5e8': {
    fe0f: {
      emoji: '🗨️'
    }
  },
  '1f5ef': {
    fe0f: {
      emoji: '🗯️'
    }
  },
  '1f4ad': {
    emoji: '💭'
  },
  '1f4a4': {
    emoji: '💤'
  },
  '1f44b': {
    emoji: '👋'
  },
  '1f91a': {
    emoji: '🤚'
  },
  '1f590': {
    fe0f: {
      emoji: '🖐️'
    }
  },
  '270b': {
    emoji: '✋'
  },
  '1f596': {
    emoji: '🖖'
  },
  '1faf1': {
    emoji: '🫱'
  },
  '1faf2': {
    emoji: '🫲'
  },
  '1faf3': {
    emoji: '🫳'
  },
  '1faf4': {
    emoji: '🫴'
  },
  '1f44c': {
    emoji: '👌'
  },
  '1f90c': {
    emoji: '🤌'
  },
  '1f90f': {
    emoji: '🤏'
  },
  '270c': {
    fe0f: {
      emoji: '✌️'
    }
  },
  '1f91e': {
    emoji: '🤞'
  },
  '1faf0': {
    emoji: '🫰'
  },
  '1f91f': {
    emoji: '🤟'
  },
  '1f918': {
    emoji: '🤘'
  },
  '1f919': {
    emoji: '🤙'
  },
  '1f448': {
    emoji: '👈'
  },
  '1f449': {
    emoji: '👉'
  },
  '1f446': {
    emoji: '👆'
  },
  '1f595': {
    emoji: '🖕'
  },
  '1f447': {
    emoji: '👇'
  },
  '261d': {
    fe0f: {
      emoji: '☝️'
    }
  },
  '1faf5': {
    emoji: '🫵'
  },
  '1f44d': {
    emoji: '👍'
  },
  '1f44e': {
    emoji: '👎'
  },
  '270a': {
    emoji: '✊'
  },
  '1f44a': {
    emoji: '👊'
  },
  '1f91b': {
    emoji: '🤛'
  },
  '1f91c': {
    emoji: '🤜'
  },
  '1f44f': {
    emoji: '👏'
  },
  '1f64c': {
    emoji: '🙌'
  },
  '1faf6': {
    emoji: '🫶'
  },
  '1f450': {
    emoji: '👐'
  },
  '1f932': {
    emoji: '🤲'
  },
  '1f91d': {
    emoji: '🤝'
  },
  '1f64f': {
    emoji: '🙏'
  },
  '270d': {
    fe0f: {
      emoji: '✍️'
    }
  },
  '1f485': {
    emoji: '💅'
  },
  '1f933': {
    emoji: '🤳'
  },
  '1f4aa': {
    emoji: '💪'
  },
  '1f9be': {
    emoji: '🦾'
  },
  '1f9bf': {
    emoji: '🦿'
  },
  '1f9b5': {
    emoji: '🦵'
  },
  '1f9b6': {
    emoji: '🦶'
  },
  '1f442': {
    emoji: '👂'
  },
  '1f9bb': {
    emoji: '🦻'
  },
  '1f443': {
    emoji: '👃'
  },
  '1f9e0': {
    emoji: '🧠'
  },
  '1fac0': {
    emoji: '🫀'
  },
  '1fac1': {
    emoji: '🫁'
  },
  '1f9b7': {
    emoji: '🦷'
  },
  '1f9b4': {
    emoji: '🦴'
  },
  '1f440': {
    emoji: '👀'
  },
  '1f445': {
    emoji: '👅'
  },
  '1f444': {
    emoji: '👄'
  },
  '1fae6': {
    emoji: '🫦'
  },
  '1f476': {
    emoji: '👶'
  },
  '1f9d2': {
    emoji: '🧒'
  },
  '1f466': {
    emoji: '👦'
  },
  '1f467': {
    emoji: '👧'
  },
  '1f9d1': {
    emoji: '🧑',
    '200d': {
      2695: {
        fe0f: {
          emoji: '🧑‍⚕️'
        }
      },
      2696: {
        fe0f: {
          emoji: '🧑‍⚖️'
        }
      },
      2708: {
        fe0f: {
          emoji: '🧑‍✈️'
        }
      },
      '1f9b0': {
        emoji: '🧑‍🦰'
      },
      '1f9b1': {
        emoji: '🧑‍🦱'
      },
      '1f9b3': {
        emoji: '🧑‍🦳'
      },
      '1f9b2': {
        emoji: '🧑‍🦲'
      },
      '1f393': {
        emoji: '🧑‍🎓'
      },
      '1f3eb': {
        emoji: '🧑‍🏫'
      },
      '1f33e': {
        emoji: '🧑‍🌾'
      },
      '1f373': {
        emoji: '🧑‍🍳'
      },
      '1f527': {
        emoji: '🧑‍🔧'
      },
      '1f3ed': {
        emoji: '🧑‍🏭'
      },
      '1f4bc': {
        emoji: '🧑‍💼'
      },
      '1f52c': {
        emoji: '🧑‍🔬'
      },
      '1f4bb': {
        emoji: '🧑‍💻'
      },
      '1f3a4': {
        emoji: '🧑‍🎤'
      },
      '1f3a8': {
        emoji: '🧑‍🎨'
      },
      '1f680': {
        emoji: '🧑‍🚀'
      },
      '1f692': {
        emoji: '🧑‍🚒'
      },
      '1f37c': {
        emoji: '🧑‍🍼'
      },
      '1f384': {
        emoji: '🧑‍🎄'
      },
      '1f9af': {
        emoji: '🧑‍🦯'
      },
      '1f9bc': {
        emoji: '🧑‍🦼'
      },
      '1f9bd': {
        emoji: '🧑‍🦽'
      },
      '1f91d': {
        '200d': {
          '1f9d1': {
            emoji: '🧑‍🤝‍🧑'
          }
        }
      }
    }
  },
  '1f471': {
    emoji: '👱',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👱‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👱‍♂️'
        }
      }
    }
  },
  '1f468': {
    emoji: '👨',
    '200d': {
      2695: {
        fe0f: {
          emoji: '👨‍⚕️'
        }
      },
      2696: {
        fe0f: {
          emoji: '👨‍⚖️'
        }
      },
      2708: {
        fe0f: {
          emoji: '👨‍✈️'
        }
      },
      2764: {
        fe0f: {
          '200d': {
            '1f48b': {
              '200d': {
                '1f468': {
                  emoji: '👨‍❤️‍💋‍👨'
                }
              }
            },
            '1f468': {
              emoji: '👨‍❤️‍👨'
            }
          }
        }
      },
      '1f9b0': {
        emoji: '👨‍🦰'
      },
      '1f9b1': {
        emoji: '👨‍🦱'
      },
      '1f9b3': {
        emoji: '👨‍🦳'
      },
      '1f9b2': {
        emoji: '👨‍🦲'
      },
      '1f393': {
        emoji: '👨‍🎓'
      },
      '1f3eb': {
        emoji: '👨‍🏫'
      },
      '1f33e': {
        emoji: '👨‍🌾'
      },
      '1f373': {
        emoji: '👨‍🍳'
      },
      '1f527': {
        emoji: '👨‍🔧'
      },
      '1f3ed': {
        emoji: '👨‍🏭'
      },
      '1f4bc': {
        emoji: '👨‍💼'
      },
      '1f52c': {
        emoji: '👨‍🔬'
      },
      '1f4bb': {
        emoji: '👨‍💻'
      },
      '1f3a4': {
        emoji: '👨‍🎤'
      },
      '1f3a8': {
        emoji: '👨‍🎨'
      },
      '1f680': {
        emoji: '👨‍🚀'
      },
      '1f692': {
        emoji: '👨‍🚒'
      },
      '1f37c': {
        emoji: '👨‍🍼'
      },
      '1f9af': {
        emoji: '👨‍🦯'
      },
      '1f9bc': {
        emoji: '👨‍🦼'
      },
      '1f9bd': {
        emoji: '👨‍🦽'
      },
      '1f469': {
        '200d': {
          '1f466': {
            emoji: '👨‍👩‍👦',
            '200d': {
              '1f466': {
                emoji: '👨‍👩‍👦‍👦'
              }
            }
          },
          '1f467': {
            emoji: '👨‍👩‍👧',
            '200d': {
              '1f466': {
                emoji: '👨‍👩‍👧‍👦'
              },
              '1f467': {
                emoji: '👨‍👩‍👧‍👧'
              }
            }
          }
        }
      },
      '1f468': {
        '200d': {
          '1f466': {
            emoji: '👨‍👨‍👦',
            '200d': {
              '1f466': {
                emoji: '👨‍👨‍👦‍👦'
              }
            }
          },
          '1f467': {
            emoji: '👨‍👨‍👧',
            '200d': {
              '1f466': {
                emoji: '👨‍👨‍👧‍👦'
              },
              '1f467': {
                emoji: '👨‍👨‍👧‍👧'
              }
            }
          }
        }
      },
      '1f466': {
        emoji: '👨‍👦',
        '200d': {
          '1f466': {
            emoji: '👨‍👦‍👦'
          }
        }
      },
      '1f467': {
        emoji: '👨‍👧',
        '200d': {
          '1f466': {
            emoji: '👨‍👧‍👦'
          },
          '1f467': {
            emoji: '👨‍👧‍👧'
          }
        }
      }
    }
  },
  '1f9d4': {
    emoji: '🧔',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧔‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧔‍♂️'
        }
      }
    }
  },
  '1f469': {
    emoji: '👩',
    '200d': {
      2695: {
        fe0f: {
          emoji: '👩‍⚕️'
        }
      },
      2696: {
        fe0f: {
          emoji: '👩‍⚖️'
        }
      },
      2708: {
        fe0f: {
          emoji: '👩‍✈️'
        }
      },
      2764: {
        fe0f: {
          '200d': {
            '1f48b': {
              '200d': {
                '1f468': {
                  emoji: '👩‍❤️‍💋‍👨'
                },
                '1f469': {
                  emoji: '👩‍❤️‍💋‍👩'
                }
              }
            },
            '1f468': {
              emoji: '👩‍❤️‍👨'
            },
            '1f469': {
              emoji: '👩‍❤️‍👩'
            }
          }
        }
      },
      '1f9b0': {
        emoji: '👩‍🦰'
      },
      '1f9b1': {
        emoji: '👩‍🦱'
      },
      '1f9b3': {
        emoji: '👩‍🦳'
      },
      '1f9b2': {
        emoji: '👩‍🦲'
      },
      '1f393': {
        emoji: '👩‍🎓'
      },
      '1f3eb': {
        emoji: '👩‍🏫'
      },
      '1f33e': {
        emoji: '👩‍🌾'
      },
      '1f373': {
        emoji: '👩‍🍳'
      },
      '1f527': {
        emoji: '👩‍🔧'
      },
      '1f3ed': {
        emoji: '👩‍🏭'
      },
      '1f4bc': {
        emoji: '👩‍💼'
      },
      '1f52c': {
        emoji: '👩‍🔬'
      },
      '1f4bb': {
        emoji: '👩‍💻'
      },
      '1f3a4': {
        emoji: '👩‍🎤'
      },
      '1f3a8': {
        emoji: '👩‍🎨'
      },
      '1f680': {
        emoji: '👩‍🚀'
      },
      '1f692': {
        emoji: '👩‍🚒'
      },
      '1f37c': {
        emoji: '👩‍🍼'
      },
      '1f9af': {
        emoji: '👩‍🦯'
      },
      '1f9bc': {
        emoji: '👩‍🦼'
      },
      '1f9bd': {
        emoji: '👩‍🦽'
      },
      '1f469': {
        '200d': {
          '1f466': {
            emoji: '👩‍👩‍👦',
            '200d': {
              '1f466': {
                emoji: '👩‍👩‍👦‍👦'
              }
            }
          },
          '1f467': {
            emoji: '👩‍👩‍👧',
            '200d': {
              '1f466': {
                emoji: '👩‍👩‍👧‍👦'
              },
              '1f467': {
                emoji: '👩‍👩‍👧‍👧'
              }
            }
          }
        }
      },
      '1f466': {
        emoji: '👩‍👦',
        '200d': {
          '1f466': {
            emoji: '👩‍👦‍👦'
          }
        }
      },
      '1f467': {
        emoji: '👩‍👧',
        '200d': {
          '1f466': {
            emoji: '👩‍👧‍👦'
          },
          '1f467': {
            emoji: '👩‍👧‍👧'
          }
        }
      }
    }
  },
  '1f9d3': {
    emoji: '🧓'
  },
  '1f474': {
    emoji: '👴'
  },
  '1f475': {
    emoji: '👵'
  },
  '1f64d': {
    emoji: '🙍',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙍‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙍‍♂️'
        }
      }
    }
  },
  '1f64e': {
    emoji: '🙎',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙎‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙎‍♂️'
        }
      }
    }
  },
  '1f645': {
    emoji: '🙅',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙅‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙅‍♂️'
        }
      }
    }
  },
  '1f646': {
    emoji: '🙆',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙆‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙆‍♂️'
        }
      }
    }
  },
  '1f481': {
    emoji: '💁',
    '200d': {
      2640: {
        fe0f: {
          emoji: '💁‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '💁‍♂️'
        }
      }
    }
  },
  '1f64b': {
    emoji: '🙋',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙋‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙋‍♂️'
        }
      }
    }
  },
  '1f9cf': {
    emoji: '🧏',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧏‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧏‍♂️'
        }
      }
    }
  },
  '1f647': {
    emoji: '🙇',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🙇‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🙇‍♂️'
        }
      }
    }
  },
  '1f926': {
    emoji: '🤦',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤦‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤦‍♂️'
        }
      }
    }
  },
  '1f937': {
    emoji: '🤷',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤷‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤷‍♂️'
        }
      }
    }
  },
  '1f46e': {
    emoji: '👮',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👮‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👮‍♂️'
        }
      }
    }
  },
  '1f575': {
    fe0f: {
      emoji: '🕵️',
      '200d': {
        2640: {
          fe0f: {
            emoji: '🕵️‍♀️'
          }
        },
        2642: {
          fe0f: {
            emoji: '🕵️‍♂️'
          }
        }
      }
    }
  },
  '1f482': {
    emoji: '💂',
    '200d': {
      2640: {
        fe0f: {
          emoji: '💂‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '💂‍♂️'
        }
      }
    }
  },
  '1f977': {
    emoji: '🥷'
  },
  '1f477': {
    emoji: '👷',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👷‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👷‍♂️'
        }
      }
    }
  },
  '1fac5': {
    emoji: '🫅'
  },
  '1f934': {
    emoji: '🤴'
  },
  '1f478': {
    emoji: '👸'
  },
  '1f473': {
    emoji: '👳',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👳‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👳‍♂️'
        }
      }
    }
  },
  '1f472': {
    emoji: '👲'
  },
  '1f9d5': {
    emoji: '🧕'
  },
  '1f935': {
    emoji: '🤵',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤵‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤵‍♂️'
        }
      }
    }
  },
  '1f470': {
    emoji: '👰',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👰‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👰‍♂️'
        }
      }
    }
  },
  '1f930': {
    emoji: '🤰'
  },
  '1fac3': {
    emoji: '🫃'
  },
  '1fac4': {
    emoji: '🫄'
  },
  '1f931': {
    emoji: '🤱'
  },
  '1f47c': {
    emoji: '👼'
  },
  '1f385': {
    emoji: '🎅'
  },
  '1f936': {
    emoji: '🤶'
  },
  '1f9b8': {
    emoji: '🦸',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🦸‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🦸‍♂️'
        }
      }
    }
  },
  '1f9b9': {
    emoji: '🦹',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🦹‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🦹‍♂️'
        }
      }
    }
  },
  '1f9d9': {
    emoji: '🧙',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧙‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧙‍♂️'
        }
      }
    }
  },
  '1f9da': {
    emoji: '🧚',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧚‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧚‍♂️'
        }
      }
    }
  },
  '1f9db': {
    emoji: '🧛',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧛‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧛‍♂️'
        }
      }
    }
  },
  '1f9dc': {
    emoji: '🧜',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧜‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧜‍♂️'
        }
      }
    }
  },
  '1f9dd': {
    emoji: '🧝',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧝‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧝‍♂️'
        }
      }
    }
  },
  '1f9de': {
    emoji: '🧞',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧞‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧞‍♂️'
        }
      }
    }
  },
  '1f9df': {
    emoji: '🧟',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧟‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧟‍♂️'
        }
      }
    }
  },
  '1f9cc': {
    emoji: '🧌'
  },
  '1f486': {
    emoji: '💆',
    '200d': {
      2640: {
        fe0f: {
          emoji: '💆‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '💆‍♂️'
        }
      }
    }
  },
  '1f487': {
    emoji: '💇',
    '200d': {
      2640: {
        fe0f: {
          emoji: '💇‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '💇‍♂️'
        }
      }
    }
  },
  '1f6b6': {
    emoji: '🚶',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🚶‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🚶‍♂️'
        }
      }
    }
  },
  '1f9cd': {
    emoji: '🧍',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧍‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧍‍♂️'
        }
      }
    }
  },
  '1f9ce': {
    emoji: '🧎',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧎‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧎‍♂️'
        }
      }
    }
  },
  '1f3c3': {
    emoji: '🏃',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🏃‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🏃‍♂️'
        }
      }
    }
  },
  '1f483': {
    emoji: '💃'
  },
  '1f57a': {
    emoji: '🕺'
  },
  '1f574': {
    fe0f: {
      emoji: '🕴️'
    }
  },
  '1f46f': {
    emoji: '👯',
    '200d': {
      2640: {
        fe0f: {
          emoji: '👯‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '👯‍♂️'
        }
      }
    }
  },
  '1f9d6': {
    emoji: '🧖',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧖‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧖‍♂️'
        }
      }
    }
  },
  '1f9d7': {
    emoji: '🧗',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧗‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧗‍♂️'
        }
      }
    }
  },
  '1f93a': {
    emoji: '🤺'
  },
  '1f3c7': {
    emoji: '🏇'
  },
  '26f7': {
    fe0f: {
      emoji: '⛷️'
    }
  },
  '1f3c2': {
    emoji: '🏂'
  },
  '1f3cc': {
    fe0f: {
      emoji: '🏌️',
      '200d': {
        2640: {
          fe0f: {
            emoji: '🏌️‍♀️'
          }
        },
        2642: {
          fe0f: {
            emoji: '🏌️‍♂️'
          }
        }
      }
    }
  },
  '1f3c4': {
    emoji: '🏄',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🏄‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🏄‍♂️'
        }
      }
    }
  },
  '1f6a3': {
    emoji: '🚣',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🚣‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🚣‍♂️'
        }
      }
    }
  },
  '1f3ca': {
    emoji: '🏊',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🏊‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🏊‍♂️'
        }
      }
    }
  },
  '26f9': {
    fe0f: {
      emoji: '⛹️',
      '200d': {
        2640: {
          fe0f: {
            emoji: '⛹️‍♀️'
          }
        },
        2642: {
          fe0f: {
            emoji: '⛹️‍♂️'
          }
        }
      }
    }
  },
  '1f3cb': {
    fe0f: {
      emoji: '🏋️',
      '200d': {
        2640: {
          fe0f: {
            emoji: '🏋️‍♀️'
          }
        },
        2642: {
          fe0f: {
            emoji: '🏋️‍♂️'
          }
        }
      }
    }
  },
  '1f6b4': {
    emoji: '🚴',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🚴‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🚴‍♂️'
        }
      }
    }
  },
  '1f6b5': {
    emoji: '🚵',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🚵‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🚵‍♂️'
        }
      }
    }
  },
  '1f938': {
    emoji: '🤸',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤸‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤸‍♂️'
        }
      }
    }
  },
  '1f93c': {
    emoji: '🤼',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤼‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤼‍♂️'
        }
      }
    }
  },
  '1f93d': {
    emoji: '🤽',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤽‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤽‍♂️'
        }
      }
    }
  },
  '1f93e': {
    emoji: '🤾',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤾‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤾‍♂️'
        }
      }
    }
  },
  '1f939': {
    emoji: '🤹',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🤹‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🤹‍♂️'
        }
      }
    }
  },
  '1f9d8': {
    emoji: '🧘',
    '200d': {
      2640: {
        fe0f: {
          emoji: '🧘‍♀️'
        }
      },
      2642: {
        fe0f: {
          emoji: '🧘‍♂️'
        }
      }
    }
  },
  '1f6c0': {
    emoji: '🛀'
  },
  '1f6cc': {
    emoji: '🛌'
  },
  '1f46d': {
    emoji: '👭'
  },
  '1f46b': {
    emoji: '👫'
  },
  '1f46c': {
    emoji: '👬'
  },
  '1f48f': {
    emoji: '💏'
  },
  '1f491': {
    emoji: '💑'
  },
  '1f46a': {
    emoji: '👪'
  },
  '1f5e3': {
    fe0f: {
      emoji: '🗣️'
    }
  },
  '1f464': {
    emoji: '👤'
  },
  '1f465': {
    emoji: '👥'
  },
  '1fac2': {
    emoji: '🫂'
  },
  '1f463': {
    emoji: '👣'
  },
  '1f435': {
    emoji: '🐵'
  },
  '1f412': {
    emoji: '🐒'
  },
  '1f98d': {
    emoji: '🦍'
  },
  '1f9a7': {
    emoji: '🦧'
  },
  '1f436': {
    emoji: '🐶'
  },
  '1f415': {
    emoji: '🐕',
    '200d': {
      '1f9ba': {
        emoji: '🐕‍🦺'
      }
    }
  },
  '1f9ae': {
    emoji: '🦮'
  },
  '1f429': {
    emoji: '🐩'
  },
  '1f43a': {
    emoji: '🐺'
  },
  '1f98a': {
    emoji: '🦊'
  },
  '1f99d': {
    emoji: '🦝'
  },
  '1f431': {
    emoji: '🐱'
  },
  '1f408': {
    emoji: '🐈',
    '200d': {
      '2b1b': {
        emoji: '🐈‍⬛'
      }
    }
  },
  '1f981': {
    emoji: '🦁'
  },
  '1f42f': {
    emoji: '🐯'
  },
  '1f405': {
    emoji: '🐅'
  },
  '1f406': {
    emoji: '🐆'
  },
  '1f434': {
    emoji: '🐴'
  },
  '1f40e': {
    emoji: '🐎'
  },
  '1f984': {
    emoji: '🦄'
  },
  '1f993': {
    emoji: '🦓'
  },
  '1f98c': {
    emoji: '🦌'
  },
  '1f9ac': {
    emoji: '🦬'
  },
  '1f42e': {
    emoji: '🐮'
  },
  '1f402': {
    emoji: '🐂'
  },
  '1f403': {
    emoji: '🐃'
  },
  '1f404': {
    emoji: '🐄'
  },
  '1f437': {
    emoji: '🐷'
  },
  '1f416': {
    emoji: '🐖'
  },
  '1f417': {
    emoji: '🐗'
  },
  '1f43d': {
    emoji: '🐽'
  },
  '1f40f': {
    emoji: '🐏'
  },
  '1f411': {
    emoji: '🐑'
  },
  '1f410': {
    emoji: '🐐'
  },
  '1f42a': {
    emoji: '🐪'
  },
  '1f42b': {
    emoji: '🐫'
  },
  '1f999': {
    emoji: '🦙'
  },
  '1f992': {
    emoji: '🦒'
  },
  '1f418': {
    emoji: '🐘'
  },
  '1f9a3': {
    emoji: '🦣'
  },
  '1f98f': {
    emoji: '🦏'
  },
  '1f99b': {
    emoji: '🦛'
  },
  '1f42d': {
    emoji: '🐭'
  },
  '1f401': {
    emoji: '🐁'
  },
  '1f400': {
    emoji: '🐀'
  },
  '1f439': {
    emoji: '🐹'
  },
  '1f430': {
    emoji: '🐰'
  },
  '1f407': {
    emoji: '🐇'
  },
  '1f43f': {
    fe0f: {
      emoji: '🐿️'
    }
  },
  '1f9ab': {
    emoji: '🦫'
  },
  '1f994': {
    emoji: '🦔'
  },
  '1f987': {
    emoji: '🦇'
  },
  '1f43b': {
    emoji: '🐻',
    '200d': {
      2744: {
        fe0f: {
          emoji: '🐻‍❄️'
        }
      }
    }
  },
  '1f428': {
    emoji: '🐨'
  },
  '1f43c': {
    emoji: '🐼'
  },
  '1f9a5': {
    emoji: '🦥'
  },
  '1f9a6': {
    emoji: '🦦'
  },
  '1f9a8': {
    emoji: '🦨'
  },
  '1f998': {
    emoji: '🦘'
  },
  '1f9a1': {
    emoji: '🦡'
  },
  '1f43e': {
    emoji: '🐾'
  },
  '1f983': {
    emoji: '🦃'
  },
  '1f414': {
    emoji: '🐔'
  },
  '1f413': {
    emoji: '🐓'
  },
  '1f423': {
    emoji: '🐣'
  },
  '1f424': {
    emoji: '🐤'
  },
  '1f425': {
    emoji: '🐥'
  },
  '1f426': {
    emoji: '🐦'
  },
  '1f427': {
    emoji: '🐧'
  },
  '1f54a': {
    fe0f: {
      emoji: '🕊️'
    }
  },
  '1f985': {
    emoji: '🦅'
  },
  '1f986': {
    emoji: '🦆'
  },
  '1f9a2': {
    emoji: '🦢'
  },
  '1f989': {
    emoji: '🦉'
  },
  '1f9a4': {
    emoji: '🦤'
  },
  '1fab6': {
    emoji: '🪶'
  },
  '1f9a9': {
    emoji: '🦩'
  },
  '1f99a': {
    emoji: '🦚'
  },
  '1f99c': {
    emoji: '🦜'
  },
  '1f438': {
    emoji: '🐸'
  },
  '1f40a': {
    emoji: '🐊'
  },
  '1f422': {
    emoji: '🐢'
  },
  '1f98e': {
    emoji: '🦎'
  },
  '1f40d': {
    emoji: '🐍'
  },
  '1f432': {
    emoji: '🐲'
  },
  '1f409': {
    emoji: '🐉'
  },
  '1f995': {
    emoji: '🦕'
  },
  '1f996': {
    emoji: '🦖'
  },
  '1f433': {
    emoji: '🐳'
  },
  '1f40b': {
    emoji: '🐋'
  },
  '1f42c': {
    emoji: '🐬'
  },
  '1f9ad': {
    emoji: '🦭'
  },
  '1f41f': {
    emoji: '🐟'
  },
  '1f420': {
    emoji: '🐠'
  },
  '1f421': {
    emoji: '🐡'
  },
  '1f988': {
    emoji: '🦈'
  },
  '1f419': {
    emoji: '🐙'
  },
  '1f41a': {
    emoji: '🐚'
  },
  '1fab8': {
    emoji: '🪸'
  },
  '1f40c': {
    emoji: '🐌'
  },
  '1f98b': {
    emoji: '🦋'
  },
  '1f41b': {
    emoji: '🐛'
  },
  '1f41c': {
    emoji: '🐜'
  },
  '1f41d': {
    emoji: '🐝'
  },
  '1fab2': {
    emoji: '🪲'
  },
  '1f41e': {
    emoji: '🐞'
  },
  '1f997': {
    emoji: '🦗'
  },
  '1fab3': {
    emoji: '🪳'
  },
  '1f577': {
    fe0f: {
      emoji: '🕷️'
    }
  },
  '1f578': {
    fe0f: {
      emoji: '🕸️'
    }
  },
  '1f982': {
    emoji: '🦂'
  },
  '1f99f': {
    emoji: '🦟'
  },
  '1fab0': {
    emoji: '🪰'
  },
  '1fab1': {
    emoji: '🪱'
  },
  '1f9a0': {
    emoji: '🦠'
  },
  '1f490': {
    emoji: '💐'
  },
  '1f338': {
    emoji: '🌸'
  },
  '1f4ae': {
    emoji: '💮'
  },
  '1fab7': {
    emoji: '🪷'
  },
  '1f3f5': {
    fe0f: {
      emoji: '🏵️'
    }
  },
  '1f339': {
    emoji: '🌹'
  },
  '1f940': {
    emoji: '🥀'
  },
  '1f33a': {
    emoji: '🌺'
  },
  '1f33b': {
    emoji: '🌻'
  },
  '1f33c': {
    emoji: '🌼'
  },
  '1f337': {
    emoji: '🌷'
  },
  '1f331': {
    emoji: '🌱'
  },
  '1fab4': {
    emoji: '🪴'
  },
  '1f332': {
    emoji: '🌲'
  },
  '1f333': {
    emoji: '🌳'
  },
  '1f334': {
    emoji: '🌴'
  },
  '1f335': {
    emoji: '🌵'
  },
  '1f33e': {
    emoji: '🌾'
  },
  '1f33f': {
    emoji: '🌿'
  },
  '1f340': {
    emoji: '🍀'
  },
  '1f341': {
    emoji: '🍁'
  },
  '1f342': {
    emoji: '🍂'
  },
  '1f343': {
    emoji: '🍃'
  },
  '1fab9': {
    emoji: '🪹'
  },
  '1faba': {
    emoji: '🪺'
  },
  '1f347': {
    emoji: '🍇'
  },
  '1f348': {
    emoji: '🍈'
  },
  '1f349': {
    emoji: '🍉'
  },
  '1f34a': {
    emoji: '🍊'
  },
  '1f34b': {
    emoji: '🍋'
  },
  '1f34c': {
    emoji: '🍌'
  },
  '1f34d': {
    emoji: '🍍'
  },
  '1f96d': {
    emoji: '🥭'
  },
  '1f34e': {
    emoji: '🍎'
  },
  '1f34f': {
    emoji: '🍏'
  },
  '1f350': {
    emoji: '🍐'
  },
  '1f351': {
    emoji: '🍑'
  },
  '1f352': {
    emoji: '🍒'
  },
  '1f353': {
    emoji: '🍓'
  },
  '1fad0': {
    emoji: '🫐'
  },
  '1f95d': {
    emoji: '🥝'
  },
  '1f345': {
    emoji: '🍅'
  },
  '1fad2': {
    emoji: '🫒'
  },
  '1f965': {
    emoji: '🥥'
  },
  '1f951': {
    emoji: '🥑'
  },
  '1f346': {
    emoji: '🍆'
  },
  '1f954': {
    emoji: '🥔'
  },
  '1f955': {
    emoji: '🥕'
  },
  '1f33d': {
    emoji: '🌽'
  },
  '1f336': {
    fe0f: {
      emoji: '🌶️'
    }
  },
  '1fad1': {
    emoji: '🫑'
  },
  '1f952': {
    emoji: '🥒'
  },
  '1f96c': {
    emoji: '🥬'
  },
  '1f966': {
    emoji: '🥦'
  },
  '1f9c4': {
    emoji: '🧄'
  },
  '1f9c5': {
    emoji: '🧅'
  },
  '1f344': {
    emoji: '🍄'
  },
  '1f95c': {
    emoji: '🥜'
  },
  '1fad8': {
    emoji: '🫘'
  },
  '1f330': {
    emoji: '🌰'
  },
  '1f35e': {
    emoji: '🍞'
  },
  '1f950': {
    emoji: '🥐'
  },
  '1f956': {
    emoji: '🥖'
  },
  '1fad3': {
    emoji: '🫓'
  },
  '1f968': {
    emoji: '🥨'
  },
  '1f96f': {
    emoji: '🥯'
  },
  '1f95e': {
    emoji: '🥞'
  },
  '1f9c7': {
    emoji: '🧇'
  },
  '1f9c0': {
    emoji: '🧀'
  },
  '1f356': {
    emoji: '🍖'
  },
  '1f357': {
    emoji: '🍗'
  },
  '1f969': {
    emoji: '🥩'
  },
  '1f953': {
    emoji: '🥓'
  },
  '1f354': {
    emoji: '🍔'
  },
  '1f35f': {
    emoji: '🍟'
  },
  '1f355': {
    emoji: '🍕'
  },
  '1f32d': {
    emoji: '🌭'
  },
  '1f96a': {
    emoji: '🥪'
  },
  '1f32e': {
    emoji: '🌮'
  },
  '1f32f': {
    emoji: '🌯'
  },
  '1fad4': {
    emoji: '🫔'
  },
  '1f959': {
    emoji: '🥙'
  },
  '1f9c6': {
    emoji: '🧆'
  },
  '1f95a': {
    emoji: '🥚'
  },
  '1f373': {
    emoji: '🍳'
  },
  '1f958': {
    emoji: '🥘'
  },
  '1f372': {
    emoji: '🍲'
  },
  '1fad5': {
    emoji: '🫕'
  },
  '1f963': {
    emoji: '🥣'
  },
  '1f957': {
    emoji: '🥗'
  },
  '1f37f': {
    emoji: '🍿'
  },
  '1f9c8': {
    emoji: '🧈'
  },
  '1f9c2': {
    emoji: '🧂'
  },
  '1f96b': {
    emoji: '🥫'
  },
  '1f371': {
    emoji: '🍱'
  },
  '1f358': {
    emoji: '🍘'
  },
  '1f359': {
    emoji: '🍙'
  },
  '1f35a': {
    emoji: '🍚'
  },
  '1f35b': {
    emoji: '🍛'
  },
  '1f35c': {
    emoji: '🍜'
  },
  '1f35d': {
    emoji: '🍝'
  },
  '1f360': {
    emoji: '🍠'
  },
  '1f362': {
    emoji: '🍢'
  },
  '1f363': {
    emoji: '🍣'
  },
  '1f364': {
    emoji: '🍤'
  },
  '1f365': {
    emoji: '🍥'
  },
  '1f96e': {
    emoji: '🥮'
  },
  '1f361': {
    emoji: '🍡'
  },
  '1f95f': {
    emoji: '🥟'
  },
  '1f960': {
    emoji: '🥠'
  },
  '1f961': {
    emoji: '🥡'
  },
  '1f980': {
    emoji: '🦀'
  },
  '1f99e': {
    emoji: '🦞'
  },
  '1f990': {
    emoji: '🦐'
  },
  '1f991': {
    emoji: '🦑'
  },
  '1f9aa': {
    emoji: '🦪'
  },
  '1f366': {
    emoji: '🍦'
  },
  '1f367': {
    emoji: '🍧'
  },
  '1f368': {
    emoji: '🍨'
  },
  '1f369': {
    emoji: '🍩'
  },
  '1f36a': {
    emoji: '🍪'
  },
  '1f382': {
    emoji: '🎂'
  },
  '1f370': {
    emoji: '🍰'
  },
  '1f9c1': {
    emoji: '🧁'
  },
  '1f967': {
    emoji: '🥧'
  },
  '1f36b': {
    emoji: '🍫'
  },
  '1f36c': {
    emoji: '🍬'
  },
  '1f36d': {
    emoji: '🍭'
  },
  '1f36e': {
    emoji: '🍮'
  },
  '1f36f': {
    emoji: '🍯'
  },
  '1f37c': {
    emoji: '🍼'
  },
  '1f95b': {
    emoji: '🥛'
  },
  '1fad6': {
    emoji: '🫖'
  },
  '1f375': {
    emoji: '🍵'
  },
  '1f376': {
    emoji: '🍶'
  },
  '1f37e': {
    emoji: '🍾'
  },
  '1f377': {
    emoji: '🍷'
  },
  '1f378': {
    emoji: '🍸'
  },
  '1f379': {
    emoji: '🍹'
  },
  '1f37a': {
    emoji: '🍺'
  },
  '1f37b': {
    emoji: '🍻'
  },
  '1f942': {
    emoji: '🥂'
  },
  '1f943': {
    emoji: '🥃'
  },
  '1fad7': {
    emoji: '🫗'
  },
  '1f964': {
    emoji: '🥤'
  },
  '1f9cb': {
    emoji: '🧋'
  },
  '1f9c3': {
    emoji: '🧃'
  },
  '1f9c9': {
    emoji: '🧉'
  },
  '1f9ca': {
    emoji: '🧊'
  },
  '1f962': {
    emoji: '🥢'
  },
  '1f37d': {
    fe0f: {
      emoji: '🍽️'
    }
  },
  '1f374': {
    emoji: '🍴'
  },
  '1f944': {
    emoji: '🥄'
  },
  '1f52a': {
    emoji: '🔪'
  },
  '1fad9': {
    emoji: '🫙'
  },
  '1f3fa': {
    emoji: '🏺'
  },
  '1f30d': {
    emoji: '🌍'
  },
  '1f30e': {
    emoji: '🌎'
  },
  '1f30f': {
    emoji: '🌏'
  },
  '1f310': {
    emoji: '🌐'
  },
  '1f5fa': {
    fe0f: {
      emoji: '🗺️'
    }
  },
  '1f5fe': {
    emoji: '🗾'
  },
  '1f9ed': {
    emoji: '🧭'
  },
  '1f3d4': {
    fe0f: {
      emoji: '🏔️'
    }
  },
  '26f0': {
    fe0f: {
      emoji: '⛰️'
    }
  },
  '1f30b': {
    emoji: '🌋'
  },
  '1f5fb': {
    emoji: '🗻'
  },
  '1f3d5': {
    fe0f: {
      emoji: '🏕️'
    }
  },
  '1f3d6': {
    fe0f: {
      emoji: '🏖️'
    }
  },
  '1f3dc': {
    fe0f: {
      emoji: '🏜️'
    }
  },
  '1f3dd': {
    fe0f: {
      emoji: '🏝️'
    }
  },
  '1f3de': {
    fe0f: {
      emoji: '🏞️'
    }
  },
  '1f3df': {
    fe0f: {
      emoji: '🏟️'
    }
  },
  '1f3db': {
    fe0f: {
      emoji: '🏛️'
    }
  },
  '1f3d7': {
    fe0f: {
      emoji: '🏗️'
    }
  },
  '1f9f1': {
    emoji: '🧱'
  },
  '1faa8': {
    emoji: '🪨'
  },
  '1fab5': {
    emoji: '🪵'
  },
  '1f6d6': {
    emoji: '🛖'
  },
  '1f3d8': {
    fe0f: {
      emoji: '🏘️'
    }
  },
  '1f3da': {
    fe0f: {
      emoji: '🏚️'
    }
  },
  '1f3e0': {
    emoji: '🏠'
  },
  '1f3e1': {
    emoji: '🏡'
  },
  '1f3e2': {
    emoji: '🏢'
  },
  '1f3e3': {
    emoji: '🏣'
  },
  '1f3e4': {
    emoji: '🏤'
  },
  '1f3e5': {
    emoji: '🏥'
  },
  '1f3e6': {
    emoji: '🏦'
  },
  '1f3e8': {
    emoji: '🏨'
  },
  '1f3e9': {
    emoji: '🏩'
  },
  '1f3ea': {
    emoji: '🏪'
  },
  '1f3eb': {
    emoji: '🏫'
  },
  '1f3ec': {
    emoji: '🏬'
  },
  '1f3ed': {
    emoji: '🏭'
  },
  '1f3ef': {
    emoji: '🏯'
  },
  '1f3f0': {
    emoji: '🏰'
  },
  '1f492': {
    emoji: '💒'
  },
  '1f5fc': {
    emoji: '🗼'
  },
  '1f5fd': {
    emoji: '🗽'
  },
  '26ea': {
    emoji: '⛪'
  },
  '1f54c': {
    emoji: '🕌'
  },
  '1f6d5': {
    emoji: '🛕'
  },
  '1f54d': {
    emoji: '🕍'
  },
  '26e9': {
    fe0f: {
      emoji: '⛩️'
    }
  },
  '1f54b': {
    emoji: '🕋'
  },
  '26f2': {
    emoji: '⛲'
  },
  '26fa': {
    emoji: '⛺'
  },
  '1f301': {
    emoji: '🌁'
  },
  '1f303': {
    emoji: '🌃'
  },
  '1f3d9': {
    fe0f: {
      emoji: '🏙️'
    }
  },
  '1f304': {
    emoji: '🌄'
  },
  '1f305': {
    emoji: '🌅'
  },
  '1f306': {
    emoji: '🌆'
  },
  '1f307': {
    emoji: '🌇'
  },
  '1f309': {
    emoji: '🌉'
  },
  '1f3a0': {
    emoji: '🎠'
  },
  '1f6dd': {
    emoji: '🛝'
  },
  '1f3a1': {
    emoji: '🎡'
  },
  '1f3a2': {
    emoji: '🎢'
  },
  '1f488': {
    emoji: '💈'
  },
  '1f3aa': {
    emoji: '🎪'
  },
  '1f682': {
    emoji: '🚂'
  },
  '1f683': {
    emoji: '🚃'
  },
  '1f684': {
    emoji: '🚄'
  },
  '1f685': {
    emoji: '🚅'
  },
  '1f686': {
    emoji: '🚆'
  },
  '1f687': {
    emoji: '🚇'
  },
  '1f688': {
    emoji: '🚈'
  },
  '1f689': {
    emoji: '🚉'
  },
  '1f68a': {
    emoji: '🚊'
  },
  '1f69d': {
    emoji: '🚝'
  },
  '1f69e': {
    emoji: '🚞'
  },
  '1f68b': {
    emoji: '🚋'
  },
  '1f68c': {
    emoji: '🚌'
  },
  '1f68d': {
    emoji: '🚍'
  },
  '1f68e': {
    emoji: '🚎'
  },
  '1f690': {
    emoji: '🚐'
  },
  '1f691': {
    emoji: '🚑'
  },
  '1f692': {
    emoji: '🚒'
  },
  '1f693': {
    emoji: '🚓'
  },
  '1f694': {
    emoji: '🚔'
  },
  '1f695': {
    emoji: '🚕'
  },
  '1f696': {
    emoji: '🚖'
  },
  '1f697': {
    emoji: '🚗'
  },
  '1f698': {
    emoji: '🚘'
  },
  '1f699': {
    emoji: '🚙'
  },
  '1f6fb': {
    emoji: '🛻'
  },
  '1f69a': {
    emoji: '🚚'
  },
  '1f69b': {
    emoji: '🚛'
  },
  '1f69c': {
    emoji: '🚜'
  },
  '1f3ce': {
    fe0f: {
      emoji: '🏎️'
    }
  },
  '1f3cd': {
    fe0f: {
      emoji: '🏍️'
    }
  },
  '1f6f5': {
    emoji: '🛵'
  },
  '1f9bd': {
    emoji: '🦽'
  },
  '1f9bc': {
    emoji: '🦼'
  },
  '1f6fa': {
    emoji: '🛺'
  },
  '1f6b2': {
    emoji: '🚲'
  },
  '1f6f4': {
    emoji: '🛴'
  },
  '1f6f9': {
    emoji: '🛹'
  },
  '1f6fc': {
    emoji: '🛼'
  },
  '1f68f': {
    emoji: '🚏'
  },
  '1f6e3': {
    fe0f: {
      emoji: '🛣️'
    }
  },
  '1f6e4': {
    fe0f: {
      emoji: '🛤️'
    }
  },
  '1f6e2': {
    fe0f: {
      emoji: '🛢️'
    }
  },
  '26fd': {
    emoji: '⛽'
  },
  '1f6de': {
    emoji: '🛞'
  },
  '1f6a8': {
    emoji: '🚨'
  },
  '1f6a5': {
    emoji: '🚥'
  },
  '1f6a6': {
    emoji: '🚦'
  },
  '1f6d1': {
    emoji: '🛑'
  },
  '1f6a7': {
    emoji: '🚧'
  },
  '1f6df': {
    emoji: '🛟'
  },
  '26f5': {
    emoji: '⛵'
  },
  '1f6f6': {
    emoji: '🛶'
  },
  '1f6a4': {
    emoji: '🚤'
  },
  '1f6f3': {
    fe0f: {
      emoji: '🛳️'
    }
  },
  '26f4': {
    fe0f: {
      emoji: '⛴️'
    }
  },
  '1f6e5': {
    fe0f: {
      emoji: '🛥️'
    }
  },
  '1f6a2': {
    emoji: '🚢'
  },
  '1f6e9': {
    fe0f: {
      emoji: '🛩️'
    }
  },
  '1f6eb': {
    emoji: '🛫'
  },
  '1f6ec': {
    emoji: '🛬'
  },
  '1fa82': {
    emoji: '🪂'
  },
  '1f4ba': {
    emoji: '💺'
  },
  '1f681': {
    emoji: '🚁'
  },
  '1f69f': {
    emoji: '🚟'
  },
  '1f6a0': {
    emoji: '🚠'
  },
  '1f6a1': {
    emoji: '🚡'
  },
  '1f6f0': {
    fe0f: {
      emoji: '🛰️'
    }
  },
  '1f680': {
    emoji: '🚀'
  },
  '1f6f8': {
    emoji: '🛸'
  },
  '1f6ce': {
    fe0f: {
      emoji: '🛎️'
    }
  },
  '1f9f3': {
    emoji: '🧳'
  },
  '231b': {
    emoji: '⌛'
  },
  '23f3': {
    emoji: '⏳'
  },
  '231a': {
    emoji: '⌚'
  },
  '23f0': {
    emoji: '⏰'
  },
  '23f1': {
    fe0f: {
      emoji: '⏱️'
    }
  },
  '23f2': {
    fe0f: {
      emoji: '⏲️'
    }
  },
  '1f570': {
    fe0f: {
      emoji: '🕰️'
    }
  },
  '1f55b': {
    emoji: '🕛'
  },
  '1f567': {
    emoji: '🕧'
  },
  '1f550': {
    emoji: '🕐'
  },
  '1f55c': {
    emoji: '🕜'
  },
  '1f551': {
    emoji: '🕑'
  },
  '1f55d': {
    emoji: '🕝'
  },
  '1f552': {
    emoji: '🕒'
  },
  '1f55e': {
    emoji: '🕞'
  },
  '1f553': {
    emoji: '🕓'
  },
  '1f55f': {
    emoji: '🕟'
  },
  '1f554': {
    emoji: '🕔'
  },
  '1f560': {
    emoji: '🕠'
  },
  '1f555': {
    emoji: '🕕'
  },
  '1f561': {
    emoji: '🕡'
  },
  '1f556': {
    emoji: '🕖'
  },
  '1f562': {
    emoji: '🕢'
  },
  '1f557': {
    emoji: '🕗'
  },
  '1f563': {
    emoji: '🕣'
  },
  '1f558': {
    emoji: '🕘'
  },
  '1f564': {
    emoji: '🕤'
  },
  '1f559': {
    emoji: '🕙'
  },
  '1f565': {
    emoji: '🕥'
  },
  '1f55a': {
    emoji: '🕚'
  },
  '1f566': {
    emoji: '🕦'
  },
  '1f311': {
    emoji: '🌑'
  },
  '1f312': {
    emoji: '🌒'
  },
  '1f313': {
    emoji: '🌓'
  },
  '1f314': {
    emoji: '🌔'
  },
  '1f315': {
    emoji: '🌕'
  },
  '1f316': {
    emoji: '🌖'
  },
  '1f317': {
    emoji: '🌗'
  },
  '1f318': {
    emoji: '🌘'
  },
  '1f319': {
    emoji: '🌙'
  },
  '1f31a': {
    emoji: '🌚'
  },
  '1f31b': {
    emoji: '🌛'
  },
  '1f31c': {
    emoji: '🌜'
  },
  '1f321': {
    fe0f: {
      emoji: '🌡️'
    }
  },
  '1f31d': {
    emoji: '🌝'
  },
  '1f31e': {
    emoji: '🌞'
  },
  '1fa90': {
    emoji: '🪐'
  },
  '2b50': {
    emoji: '⭐'
  },
  '1f31f': {
    emoji: '🌟'
  },
  '1f320': {
    emoji: '🌠'
  },
  '1f30c': {
    emoji: '🌌'
  },
  '26c5': {
    emoji: '⛅'
  },
  '26c8': {
    fe0f: {
      emoji: '⛈️'
    }
  },
  '1f324': {
    fe0f: {
      emoji: '🌤️'
    }
  },
  '1f325': {
    fe0f: {
      emoji: '🌥️'
    }
  },
  '1f326': {
    fe0f: {
      emoji: '🌦️'
    }
  },
  '1f327': {
    fe0f: {
      emoji: '🌧️'
    }
  },
  '1f328': {
    fe0f: {
      emoji: '🌨️'
    }
  },
  '1f329': {
    fe0f: {
      emoji: '🌩️'
    }
  },
  '1f32a': {
    fe0f: {
      emoji: '🌪️'
    }
  },
  '1f32b': {
    fe0f: {
      emoji: '🌫️'
    }
  },
  '1f32c': {
    fe0f: {
      emoji: '🌬️'
    }
  },
  '1f300': {
    emoji: '🌀'
  },
  '1f308': {
    emoji: '🌈'
  },
  '1f302': {
    emoji: '🌂'
  },
  '26f1': {
    fe0f: {
      emoji: '⛱️'
    }
  },
  '26a1': {
    emoji: '⚡'
  },
  '26c4': {
    emoji: '⛄'
  },
  '1f525': {
    emoji: '🔥'
  },
  '1f4a7': {
    emoji: '💧'
  },
  '1f30a': {
    emoji: '🌊'
  },
  '1f383': {
    emoji: '🎃'
  },
  '1f384': {
    emoji: '🎄'
  },
  '1f386': {
    emoji: '🎆'
  },
  '1f387': {
    emoji: '🎇'
  },
  '1f9e8': {
    emoji: '🧨'
  },
  '1f388': {
    emoji: '🎈'
  },
  '1f389': {
    emoji: '🎉'
  },
  '1f38a': {
    emoji: '🎊'
  },
  '1f38b': {
    emoji: '🎋'
  },
  '1f38d': {
    emoji: '🎍'
  },
  '1f38e': {
    emoji: '🎎'
  },
  '1f38f': {
    emoji: '🎏'
  },
  '1f390': {
    emoji: '🎐'
  },
  '1f391': {
    emoji: '🎑'
  },
  '1f9e7': {
    emoji: '🧧'
  },
  '1f380': {
    emoji: '🎀'
  },
  '1f381': {
    emoji: '🎁'
  },
  '1f397': {
    fe0f: {
      emoji: '🎗️'
    }
  },
  '1f39f': {
    fe0f: {
      emoji: '🎟️'
    }
  },
  '1f3ab': {
    emoji: '🎫'
  },
  '1f396': {
    fe0f: {
      emoji: '🎖️'
    }
  },
  '1f3c6': {
    emoji: '🏆'
  },
  '1f3c5': {
    emoji: '🏅'
  },
  '1f947': {
    emoji: '🥇'
  },
  '1f948': {
    emoji: '🥈'
  },
  '1f949': {
    emoji: '🥉'
  },
  '26bd': {
    emoji: '⚽'
  },
  '26be': {
    emoji: '⚾'
  },
  '1f94e': {
    emoji: '🥎'
  },
  '1f3c0': {
    emoji: '🏀'
  },
  '1f3d0': {
    emoji: '🏐'
  },
  '1f3c8': {
    emoji: '🏈'
  },
  '1f3c9': {
    emoji: '🏉'
  },
  '1f3be': {
    emoji: '🎾'
  },
  '1f94f': {
    emoji: '🥏'
  },
  '1f3b3': {
    emoji: '🎳'
  },
  '1f3cf': {
    emoji: '🏏'
  },
  '1f3d1': {
    emoji: '🏑'
  },
  '1f3d2': {
    emoji: '🏒'
  },
  '1f94d': {
    emoji: '🥍'
  },
  '1f3d3': {
    emoji: '🏓'
  },
  '1f3f8': {
    emoji: '🏸'
  },
  '1f94a': {
    emoji: '🥊'
  },
  '1f94b': {
    emoji: '🥋'
  },
  '1f945': {
    emoji: '🥅'
  },
  '26f3': {
    emoji: '⛳'
  },
  '26f8': {
    fe0f: {
      emoji: '⛸️'
    }
  },
  '1f3a3': {
    emoji: '🎣'
  },
  '1f93f': {
    emoji: '🤿'
  },
  '1f3bd': {
    emoji: '🎽'
  },
  '1f3bf': {
    emoji: '🎿'
  },
  '1f6f7': {
    emoji: '🛷'
  },
  '1f94c': {
    emoji: '🥌'
  },
  '1f3af': {
    emoji: '🎯'
  },
  '1fa80': {
    emoji: '🪀'
  },
  '1fa81': {
    emoji: '🪁'
  },
  '1f3b1': {
    emoji: '🎱'
  },
  '1f52e': {
    emoji: '🔮'
  },
  '1fa84': {
    emoji: '🪄'
  },
  '1f9ff': {
    emoji: '🧿'
  },
  '1faac': {
    emoji: '🪬'
  },
  '1f3ae': {
    emoji: '🎮'
  },
  '1f579': {
    fe0f: {
      emoji: '🕹️'
    }
  },
  '1f3b0': {
    emoji: '🎰'
  },
  '1f3b2': {
    emoji: '🎲'
  },
  '1f9e9': {
    emoji: '🧩'
  },
  '1f9f8': {
    emoji: '🧸'
  },
  '1fa85': {
    emoji: '🪅'
  },
  '1faa9': {
    emoji: '🪩'
  },
  '1fa86': {
    emoji: '🪆'
  },
  '265f': {
    fe0f: {
      emoji: '♟️'
    }
  },
  '1f0cf': {
    emoji: '🃏'
  },
  '1f004': {
    emoji: '🀄'
  },
  '1f3b4': {
    emoji: '🎴'
  },
  '1f3ad': {
    emoji: '🎭'
  },
  '1f5bc': {
    fe0f: {
      emoji: '🖼️'
    }
  },
  '1f3a8': {
    emoji: '🎨'
  },
  '1f9f5': {
    emoji: '🧵'
  },
  '1faa1': {
    emoji: '🪡'
  },
  '1f9f6': {
    emoji: '🧶'
  },
  '1faa2': {
    emoji: '🪢'
  },
  '1f453': {
    emoji: '👓'
  },
  '1f576': {
    fe0f: {
      emoji: '🕶️'
    }
  },
  '1f97d': {
    emoji: '🥽'
  },
  '1f97c': {
    emoji: '🥼'
  },
  '1f9ba': {
    emoji: '🦺'
  },
  '1f454': {
    emoji: '👔'
  },
  '1f455': {
    emoji: '👕'
  },
  '1f456': {
    emoji: '👖'
  },
  '1f9e3': {
    emoji: '🧣'
  },
  '1f9e4': {
    emoji: '🧤'
  },
  '1f9e5': {
    emoji: '🧥'
  },
  '1f9e6': {
    emoji: '🧦'
  },
  '1f457': {
    emoji: '👗'
  },
  '1f458': {
    emoji: '👘'
  },
  '1f97b': {
    emoji: '🥻'
  },
  '1fa71': {
    emoji: '🩱'
  },
  '1fa72': {
    emoji: '🩲'
  },
  '1fa73': {
    emoji: '🩳'
  },
  '1f459': {
    emoji: '👙'
  },
  '1f45a': {
    emoji: '👚'
  },
  '1f45b': {
    emoji: '👛'
  },
  '1f45c': {
    emoji: '👜'
  },
  '1f45d': {
    emoji: '👝'
  },
  '1f6cd': {
    fe0f: {
      emoji: '🛍️'
    }
  },
  '1f392': {
    emoji: '🎒'
  },
  '1fa74': {
    emoji: '🩴'
  },
  '1f45e': {
    emoji: '👞'
  },
  '1f45f': {
    emoji: '👟'
  },
  '1f97e': {
    emoji: '🥾'
  },
  '1f97f': {
    emoji: '🥿'
  },
  '1f460': {
    emoji: '👠'
  },
  '1f461': {
    emoji: '👡'
  },
  '1fa70': {
    emoji: '🩰'
  },
  '1f462': {
    emoji: '👢'
  },
  '1f451': {
    emoji: '👑'
  },
  '1f452': {
    emoji: '👒'
  },
  '1f3a9': {
    emoji: '🎩'
  },
  '1f393': {
    emoji: '🎓'
  },
  '1f9e2': {
    emoji: '🧢'
  },
  '1fa96': {
    emoji: '🪖'
  },
  '26d1': {
    fe0f: {
      emoji: '⛑️'
    }
  },
  '1f4ff': {
    emoji: '📿'
  },
  '1f484': {
    emoji: '💄'
  },
  '1f48d': {
    emoji: '💍'
  },
  '1f48e': {
    emoji: '💎'
  },
  '1f507': {
    emoji: '🔇'
  },
  '1f508': {
    emoji: '🔈'
  },
  '1f509': {
    emoji: '🔉'
  },
  '1f50a': {
    emoji: '🔊'
  },
  '1f4e2': {
    emoji: '📢'
  },
  '1f4e3': {
    emoji: '📣'
  },
  '1f4ef': {
    emoji: '📯'
  },
  '1f514': {
    emoji: '🔔'
  },
  '1f515': {
    emoji: '🔕'
  },
  '1f3bc': {
    emoji: '🎼'
  },
  '1f3b5': {
    emoji: '🎵'
  },
  '1f3b6': {
    emoji: '🎶'
  },
  '1f399': {
    fe0f: {
      emoji: '🎙️'
    }
  },
  '1f39a': {
    fe0f: {
      emoji: '🎚️'
    }
  },
  '1f39b': {
    fe0f: {
      emoji: '🎛️'
    }
  },
  '1f3a4': {
    emoji: '🎤'
  },
  '1f3a7': {
    emoji: '🎧'
  },
  '1f4fb': {
    emoji: '📻'
  },
  '1f3b7': {
    emoji: '🎷'
  },
  '1fa97': {
    emoji: '🪗'
  },
  '1f3b8': {
    emoji: '🎸'
  },
  '1f3b9': {
    emoji: '🎹'
  },
  '1f3ba': {
    emoji: '🎺'
  },
  '1f3bb': {
    emoji: '🎻'
  },
  '1fa95': {
    emoji: '🪕'
  },
  '1f941': {
    emoji: '🥁'
  },
  '1fa98': {
    emoji: '🪘'
  },
  '1f4f1': {
    emoji: '📱'
  },
  '1f4f2': {
    emoji: '📲'
  },
  '260e': {
    fe0f: {
      emoji: '☎️'
    }
  },
  '1f4de': {
    emoji: '📞'
  },
  '1f4df': {
    emoji: '📟'
  },
  '1f4e0': {
    emoji: '📠'
  },
  '1f50b': {
    emoji: '🔋'
  },
  '1faab': {
    emoji: '🪫'
  },
  '1f50c': {
    emoji: '🔌'
  },
  '1f4bb': {
    emoji: '💻'
  },
  '1f5a5': {
    fe0f: {
      emoji: '🖥️'
    }
  },
  '1f5a8': {
    fe0f: {
      emoji: '🖨️'
    }
  },
  '1f5b1': {
    fe0f: {
      emoji: '🖱️'
    }
  },
  '1f5b2': {
    fe0f: {
      emoji: '🖲️'
    }
  },
  '1f4bd': {
    emoji: '💽'
  },
  '1f4be': {
    emoji: '💾'
  },
  '1f4bf': {
    emoji: '💿'
  },
  '1f4c0': {
    emoji: '📀'
  },
  '1f9ee': {
    emoji: '🧮'
  },
  '1f3a5': {
    emoji: '🎥'
  },
  '1f39e': {
    fe0f: {
      emoji: '🎞️'
    }
  },
  '1f4fd': {
    fe0f: {
      emoji: '📽️'
    }
  },
  '1f3ac': {
    emoji: '🎬'
  },
  '1f4fa': {
    emoji: '📺'
  },
  '1f4f7': {
    emoji: '📷'
  },
  '1f4f8': {
    emoji: '📸'
  },
  '1f4f9': {
    emoji: '📹'
  },
  '1f4fc': {
    emoji: '📼'
  },
  '1f50d': {
    emoji: '🔍'
  },
  '1f50e': {
    emoji: '🔎'
  },
  '1f56f': {
    fe0f: {
      emoji: '🕯️'
    }
  },
  '1f4a1': {
    emoji: '💡'
  },
  '1f526': {
    emoji: '🔦'
  },
  '1f3ee': {
    emoji: '🏮'
  },
  '1fa94': {
    emoji: '🪔'
  },
  '1f4d4': {
    emoji: '📔'
  },
  '1f4d5': {
    emoji: '📕'
  },
  '1f4d6': {
    emoji: '📖'
  },
  '1f4d7': {
    emoji: '📗'
  },
  '1f4d8': {
    emoji: '📘'
  },
  '1f4d9': {
    emoji: '📙'
  },
  '1f4da': {
    emoji: '📚'
  },
  '1f4d3': {
    emoji: '📓'
  },
  '1f4d2': {
    emoji: '📒'
  },
  '1f4c3': {
    emoji: '📃'
  },
  '1f4dc': {
    emoji: '📜'
  },
  '1f4c4': {
    emoji: '📄'
  },
  '1f4f0': {
    emoji: '📰'
  },
  '1f5de': {
    fe0f: {
      emoji: '🗞️'
    }
  },
  '1f4d1': {
    emoji: '📑'
  },
  '1f516': {
    emoji: '🔖'
  },
  '1f3f7': {
    fe0f: {
      emoji: '🏷️'
    }
  },
  '1f4b0': {
    emoji: '💰'
  },
  '1fa99': {
    emoji: '🪙'
  },
  '1f4b4': {
    emoji: '💴'
  },
  '1f4b5': {
    emoji: '💵'
  },
  '1f4b6': {
    emoji: '💶'
  },
  '1f4b7': {
    emoji: '💷'
  },
  '1f4b8': {
    emoji: '💸'
  },
  '1f4b3': {
    emoji: '💳'
  },
  '1f9fe': {
    emoji: '🧾'
  },
  '1f4b9': {
    emoji: '💹'
  },
  '1f4e7': {
    emoji: '📧'
  },
  '1f4e8': {
    emoji: '📨'
  },
  '1f4e9': {
    emoji: '📩'
  },
  '1f4e4': {
    emoji: '📤'
  },
  '1f4e5': {
    emoji: '📥'
  },
  '1f4e6': {
    emoji: '📦'
  },
  '1f4eb': {
    emoji: '📫'
  },
  '1f4ea': {
    emoji: '📪'
  },
  '1f4ec': {
    emoji: '📬'
  },
  '1f4ed': {
    emoji: '📭'
  },
  '1f4ee': {
    emoji: '📮'
  },
  '1f5f3': {
    fe0f: {
      emoji: '🗳️'
    }
  },
  '270f': {
    fe0f: {
      emoji: '✏️'
    }
  },
  '1f58b': {
    fe0f: {
      emoji: '🖋️'
    }
  },
  '1f58a': {
    fe0f: {
      emoji: '🖊️'
    }
  },
  '1f58c': {
    fe0f: {
      emoji: '🖌️'
    }
  },
  '1f58d': {
    fe0f: {
      emoji: '🖍️'
    }
  },
  '1f4dd': {
    emoji: '📝'
  },
  '1f4bc': {
    emoji: '💼'
  },
  '1f4c1': {
    emoji: '📁'
  },
  '1f4c2': {
    emoji: '📂'
  },
  '1f5c2': {
    fe0f: {
      emoji: '🗂️'
    }
  },
  '1f4c5': {
    emoji: '📅'
  },
  '1f4c6': {
    emoji: '📆'
  },
  '1f5d2': {
    fe0f: {
      emoji: '🗒️'
    }
  },
  '1f5d3': {
    fe0f: {
      emoji: '🗓️'
    }
  },
  '1f4c7': {
    emoji: '📇'
  },
  '1f4c8': {
    emoji: '📈'
  },
  '1f4c9': {
    emoji: '📉'
  },
  '1f4ca': {
    emoji: '📊'
  },
  '1f4cb': {
    emoji: '📋'
  },
  '1f4cc': {
    emoji: '📌'
  },
  '1f4cd': {
    emoji: '📍'
  },
  '1f4ce': {
    emoji: '📎'
  },
  '1f587': {
    fe0f: {
      emoji: '🖇️'
    }
  },
  '1f4cf': {
    emoji: '📏'
  },
  '1f4d0': {
    emoji: '📐'
  },
  '1f5c3': {
    fe0f: {
      emoji: '🗃️'
    }
  },
  '1f5c4': {
    fe0f: {
      emoji: '🗄️'
    }
  },
  '1f5d1': {
    fe0f: {
      emoji: '🗑️'
    }
  },
  '1f512': {
    emoji: '🔒'
  },
  '1f513': {
    emoji: '🔓'
  },
  '1f50f': {
    emoji: '🔏'
  },
  '1f510': {
    emoji: '🔐'
  },
  '1f511': {
    emoji: '🔑'
  },
  '1f5dd': {
    fe0f: {
      emoji: '🗝️'
    }
  },
  '1f528': {
    emoji: '🔨'
  },
  '1fa93': {
    emoji: '🪓'
  },
  '26cf': {
    fe0f: {
      emoji: '⛏️'
    }
  },
  '1f6e0': {
    fe0f: {
      emoji: '🛠️'
    }
  },
  '1f5e1': {
    fe0f: {
      emoji: '🗡️'
    }
  },
  '1f52b': {
    emoji: '🔫'
  },
  '1fa83': {
    emoji: '🪃'
  },
  '1f3f9': {
    emoji: '🏹'
  },
  '1f6e1': {
    fe0f: {
      emoji: '🛡️'
    }
  },
  '1fa9a': {
    emoji: '🪚'
  },
  '1f527': {
    emoji: '🔧'
  },
  '1fa9b': {
    emoji: '🪛'
  },
  '1f529': {
    emoji: '🔩'
  },
  '1f5dc': {
    fe0f: {
      emoji: '🗜️'
    }
  },
  '1f9af': {
    emoji: '🦯'
  },
  '1f517': {
    emoji: '🔗'
  },
  '26d3': {
    fe0f: {
      emoji: '⛓️'
    }
  },
  '1fa9d': {
    emoji: '🪝'
  },
  '1f9f0': {
    emoji: '🧰'
  },
  '1f9f2': {
    emoji: '🧲'
  },
  '1fa9c': {
    emoji: '🪜'
  },
  '1f9ea': {
    emoji: '🧪'
  },
  '1f9eb': {
    emoji: '🧫'
  },
  '1f9ec': {
    emoji: '🧬'
  },
  '1f52c': {
    emoji: '🔬'
  },
  '1f52d': {
    emoji: '🔭'
  },
  '1f4e1': {
    emoji: '📡'
  },
  '1f489': {
    emoji: '💉'
  },
  '1fa78': {
    emoji: '🩸'
  },
  '1f48a': {
    emoji: '💊'
  },
  '1fa79': {
    emoji: '🩹'
  },
  '1fa7c': {
    emoji: '🩼'
  },
  '1fa7a': {
    emoji: '🩺'
  },
  '1fa7b': {
    emoji: '🩻'
  },
  '1f6aa': {
    emoji: '🚪'
  },
  '1f6d7': {
    emoji: '🛗'
  },
  '1fa9e': {
    emoji: '🪞'
  },
  '1fa9f': {
    emoji: '🪟'
  },
  '1f6cf': {
    fe0f: {
      emoji: '🛏️'
    }
  },
  '1f6cb': {
    fe0f: {
      emoji: '🛋️'
    }
  },
  '1fa91': {
    emoji: '🪑'
  },
  '1f6bd': {
    emoji: '🚽'
  },
  '1faa0': {
    emoji: '🪠'
  },
  '1f6bf': {
    emoji: '🚿'
  },
  '1f6c1': {
    emoji: '🛁'
  },
  '1faa4': {
    emoji: '🪤'
  },
  '1fa92': {
    emoji: '🪒'
  },
  '1f9f4': {
    emoji: '🧴'
  },
  '1f9f7': {
    emoji: '🧷'
  },
  '1f9f9': {
    emoji: '🧹'
  },
  '1f9fa': {
    emoji: '🧺'
  },
  '1f9fb': {
    emoji: '🧻'
  },
  '1faa3': {
    emoji: '🪣'
  },
  '1f9fc': {
    emoji: '🧼'
  },
  '1fae7': {
    emoji: '🫧'
  },
  '1faa5': {
    emoji: '🪥'
  },
  '1f9fd': {
    emoji: '🧽'
  },
  '1f9ef': {
    emoji: '🧯'
  },
  '1f6d2': {
    emoji: '🛒'
  },
  '1f6ac': {
    emoji: '🚬'
  },
  '26b0': {
    fe0f: {
      emoji: '⚰️'
    }
  },
  '1faa6': {
    emoji: '🪦'
  },
  '26b1': {
    fe0f: {
      emoji: '⚱️'
    }
  },
  '1f5ff': {
    emoji: '🗿'
  },
  '1faa7': {
    emoji: '🪧'
  },
  '1faaa': {
    emoji: '🪪'
  },
  '1f3e7': {
    emoji: '🏧'
  },
  '1f6ae': {
    emoji: '🚮'
  },
  '1f6b0': {
    emoji: '🚰'
  },
  '267f': {
    emoji: '♿'
  },
  '1f6b9': {
    emoji: '🚹'
  },
  '1f6ba': {
    emoji: '🚺'
  },
  '1f6bb': {
    emoji: '🚻'
  },
  '1f6bc': {
    emoji: '🚼'
  },
  '1f6be': {
    emoji: '🚾'
  },
  '1f6c2': {
    emoji: '🛂'
  },
  '1f6c3': {
    emoji: '🛃'
  },
  '1f6c4': {
    emoji: '🛄'
  },
  '1f6c5': {
    emoji: '🛅'
  },
  '26a0': {
    fe0f: {
      emoji: '⚠️'
    }
  },
  '1f6b8': {
    emoji: '🚸'
  },
  '26d4': {
    emoji: '⛔'
  },
  '1f6ab': {
    emoji: '🚫'
  },
  '1f6b3': {
    emoji: '🚳'
  },
  '1f6ad': {
    emoji: '🚭'
  },
  '1f6af': {
    emoji: '🚯'
  },
  '1f6b1': {
    emoji: '🚱'
  },
  '1f6b7': {
    emoji: '🚷'
  },
  '1f4f5': {
    emoji: '📵'
  },
  '1f51e': {
    emoji: '🔞'
  },
  '2b06': {
    fe0f: {
      emoji: '⬆️'
    }
  },
  '27a1': {
    fe0f: {
      emoji: '➡️'
    }
  },
  '2b07': {
    fe0f: {
      emoji: '⬇️'
    }
  },
  '2b05': {
    fe0f: {
      emoji: '⬅️'
    }
  },
  '21a9': {
    fe0f: {
      emoji: '↩️'
    }
  },
  '21aa': {
    fe0f: {
      emoji: '↪️'
    }
  },
  '1f503': {
    emoji: '🔃'
  },
  '1f504': {
    emoji: '🔄'
  },
  '1f519': {
    emoji: '🔙'
  },
  '1f51a': {
    emoji: '🔚'
  },
  '1f51b': {
    emoji: '🔛'
  },
  '1f51c': {
    emoji: '🔜'
  },
  '1f51d': {
    emoji: '🔝'
  },
  '1f6d0': {
    emoji: '🛐'
  },
  '269b': {
    fe0f: {
      emoji: '⚛️'
    }
  },
  '1f549': {
    fe0f: {
      emoji: '🕉️'
    }
  },
  '262f': {
    fe0f: {
      emoji: '☯️'
    }
  },
  '271d': {
    fe0f: {
      emoji: '✝️'
    }
  },
  '262a': {
    fe0f: {
      emoji: '☪️'
    }
  },
  '262e': {
    fe0f: {
      emoji: '☮️'
    }
  },
  '1f54e': {
    emoji: '🕎'
  },
  '1f52f': {
    emoji: '🔯'
  },
  '264a': {
    emoji: '♊'
  },
  '264b': {
    emoji: '♋'
  },
  '264c': {
    emoji: '♌'
  },
  '264d': {
    emoji: '♍'
  },
  '264e': {
    emoji: '♎'
  },
  '264f': {
    emoji: '♏'
  },
  '26ce': {
    emoji: '⛎'
  },
  '1f500': {
    emoji: '🔀'
  },
  '1f501': {
    emoji: '🔁'
  },
  '1f502': {
    emoji: '🔂'
  },
  '25b6': {
    fe0f: {
      emoji: '▶️'
    }
  },
  '23e9': {
    emoji: '⏩'
  },
  '23ed': {
    fe0f: {
      emoji: '⏭️'
    }
  },
  '23ef': {
    fe0f: {
      emoji: '⏯️'
    }
  },
  '25c0': {
    fe0f: {
      emoji: '◀️'
    }
  },
  '23ea': {
    emoji: '⏪'
  },
  '23ee': {
    fe0f: {
      emoji: '⏮️'
    }
  },
  '1f53c': {
    emoji: '🔼'
  },
  '23eb': {
    emoji: '⏫'
  },
  '1f53d': {
    emoji: '🔽'
  },
  '23ec': {
    emoji: '⏬'
  },
  '23f8': {
    fe0f: {
      emoji: '⏸️'
    }
  },
  '23f9': {
    fe0f: {
      emoji: '⏹️'
    }
  },
  '23fa': {
    fe0f: {
      emoji: '⏺️'
    }
  },
  '23cf': {
    fe0f: {
      emoji: '⏏️'
    }
  },
  '1f3a6': {
    emoji: '🎦'
  },
  '1f505': {
    emoji: '🔅'
  },
  '1f506': {
    emoji: '🔆'
  },
  '1f4f6': {
    emoji: '📶'
  },
  '1f4f3': {
    emoji: '📳'
  },
  '1f4f4': {
    emoji: '📴'
  },
  '26a7': {
    fe0f: {
      emoji: '⚧️'
    }
  },
  '1f7f0': {
    emoji: '🟰'
  },
  '267e': {
    fe0f: {
      emoji: '♾️'
    }
  },
  '203c': {
    fe0f: {
      emoji: '‼️'
    }
  },
  '1f4b1': {
    emoji: '💱'
  },
  '1f4b2': {
    emoji: '💲'
  },
  '267b': {
    fe0f: {
      emoji: '♻️'
    }
  },
  '269c': {
    fe0f: {
      emoji: '⚜️'
    }
  },
  '1f531': {
    emoji: '🔱'
  },
  '1f4db': {
    emoji: '📛'
  },
  '1f530': {
    emoji: '🔰'
  },
  '2b55': {
    emoji: '⭕'
  },
  '274c': {
    emoji: '❌'
  },
  '274e': {
    emoji: '❎'
  },
  '27b0': {
    emoji: '➰'
  },
  '27bf': {
    emoji: '➿'
  },
  '303d': {
    fe0f: {
      emoji: '〽️'
    }
  },
  a9: {
    fe0f: {
      emoji: '©️'
    }
  },
  ae: {
    fe0f: {
      emoji: '®️'
    }
  },
  '2a': {
    fe0f: {
      '20e3': {
        emoji: '*️⃣'
      }
    }
  },
  '1f51f': {
    emoji: '🔟'
  },
  '1f520': {
    emoji: '🔠'
  },
  '1f521': {
    emoji: '🔡'
  },
  '1f522': {
    emoji: '🔢'
  },
  '1f523': {
    emoji: '🔣'
  },
  '1f524': {
    emoji: '🔤'
  },
  '1f170': {
    fe0f: {
      emoji: '🅰️'
    }
  },
  '1f18e': {
    emoji: '🆎'
  },
  '1f171': {
    fe0f: {
      emoji: '🅱️'
    }
  },
  '1f191': {
    emoji: '🆑'
  },
  '1f192': {
    emoji: '🆒'
  },
  '1f193': {
    emoji: '🆓'
  },
  '1f194': {
    emoji: '🆔'
  },
  '24c2': {
    fe0f: {
      emoji: 'Ⓜ️'
    }
  },
  '1f195': {
    emoji: '🆕'
  },
  '1f196': {
    emoji: '🆖'
  },
  '1f17e': {
    fe0f: {
      emoji: '🅾️'
    }
  },
  '1f197': {
    emoji: '🆗'
  },
  '1f17f': {
    fe0f: {
      emoji: '🅿️'
    }
  },
  '1f198': {
    emoji: '🆘'
  },
  '1f199': {
    emoji: '🆙'
  },
  '1f19a': {
    emoji: '🆚'
  },
  '1f201': {
    emoji: '🈁'
  },
  '1f202': {
    fe0f: {
      emoji: '🈂️'
    }
  },
  '1f237': {
    fe0f: {
      emoji: '🈷️'
    }
  },
  '1f236': {
    emoji: '🈶'
  },
  '1f22f': {
    emoji: '🈯'
  },
  '1f250': {
    emoji: '🉐'
  },
  '1f239': {
    emoji: '🈹'
  },
  '1f21a': {
    emoji: '🈚'
  },
  '1f232': {
    emoji: '🈲'
  },
  '1f251': {
    emoji: '🉑'
  },
  '1f238': {
    emoji: '🈸'
  },
  '1f234': {
    emoji: '🈴'
  },
  '1f233': {
    emoji: '🈳'
  },
  '1f23a': {
    emoji: '🈺'
  },
  '1f235': {
    emoji: '🈵'
  },
  '1f534': {
    emoji: '🔴'
  },
  '1f7e0': {
    emoji: '🟠'
  },
  '1f7e1': {
    emoji: '🟡'
  },
  '1f7e2': {
    emoji: '🟢'
  },
  '1f535': {
    emoji: '🔵'
  },
  '1f7e3': {
    emoji: '🟣'
  },
  '1f7e4': {
    emoji: '🟤'
  },
  '26ab': {
    emoji: '⚫'
  },
  '26aa': {
    emoji: '⚪'
  },
  '1f7e5': {
    emoji: '🟥'
  },
  '1f7e7': {
    emoji: '🟧'
  },
  '1f7e8': {
    emoji: '🟨'
  },
  '1f7e9': {
    emoji: '🟩'
  },
  '1f7e6': {
    emoji: '🟦'
  },
  '1f7ea': {
    emoji: '🟪'
  },
  '1f7eb': {
    emoji: '🟫'
  },
  '2b1b': {
    emoji: '⬛'
  },
  '2b1c': {
    emoji: '⬜'
  },
  '25fc': {
    fe0f: {
      emoji: '◼️'
    }
  },
  '25fb': {
    fe0f: {
      emoji: '◻️'
    }
  },
  '25fe': {
    emoji: '◾'
  },
  '25fd': {
    emoji: '◽'
  },
  '25aa': {
    fe0f: {
      emoji: '▪️'
    }
  },
  '25ab': {
    fe0f: {
      emoji: '▫️'
    }
  },
  '1f536': {
    emoji: '🔶'
  },
  '1f537': {
    emoji: '🔷'
  },
  '1f538': {
    emoji: '🔸'
  },
  '1f539': {
    emoji: '🔹'
  },
  '1f53a': {
    emoji: '🔺'
  },
  '1f53b': {
    emoji: '🔻'
  },
  '1f4a0': {
    emoji: '💠'
  },
  '1f518': {
    emoji: '🔘'
  },
  '1f533': {
    emoji: '🔳'
  },
  '1f532': {
    emoji: '🔲'
  },
  '1f3c1': {
    emoji: '🏁'
  },
  '1f6a9': {
    emoji: '🚩'
  },
  '1f38c': {
    emoji: '🎌'
  },
  '1f3f4': {
    emoji: '🏴',
    '200d': {
      2620: {
        fe0f: {
          emoji: '🏴‍☠️'
        }
      }
    },
    e0067: {
      e0062: {
        e0065: {
          e006e: {
            e0067: {
              e007f: {
                emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿'
              }
            }
          }
        },
        e0073: {
          e0063: {
            e0074: {
              e007f: {
                emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿'
              }
            }
          }
        },
        e0077: {
          e006c: {
            e0073: {
              e007f: {
                emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿'
              }
            }
          }
        }
      }
    }
  },
  '1f3f3': {
    fe0f: {
      emoji: '🏳️',
      '200d': {
        '1f308': {
          emoji: '🏳️‍🌈'
        },
        '26a7': {
          fe0f: {
            emoji: '🏳️‍⚧️'
          }
        }
      }
    }
  },
  '1f1e6': {
    '1f1e8': {
      emoji: '🇦🇨'
    },
    '1f1e9': {
      emoji: '🇦🇩'
    },
    '1f1ea': {
      emoji: '🇦🇪'
    },
    '1f1eb': {
      emoji: '🇦🇫'
    },
    '1f1ec': {
      emoji: '🇦🇬'
    },
    '1f1ee': {
      emoji: '🇦🇮'
    },
    '1f1f1': {
      emoji: '🇦🇱'
    },
    '1f1f2': {
      emoji: '🇦🇲'
    },
    '1f1f4': {
      emoji: '🇦🇴'
    },
    '1f1f6': {
      emoji: '🇦🇶'
    },
    '1f1f7': {
      emoji: '🇦🇷'
    },
    '1f1f8': {
      emoji: '🇦🇸'
    },
    '1f1f9': {
      emoji: '🇦🇹'
    },
    '1f1fa': {
      emoji: '🇦🇺'
    },
    '1f1fc': {
      emoji: '🇦🇼'
    },
    '1f1fd': {
      emoji: '🇦🇽'
    },
    '1f1ff': {
      emoji: '🇦🇿'
    }
  },
  '1f1e7': {
    '1f1e6': {
      emoji: '🇧🇦'
    },
    '1f1e7': {
      emoji: '🇧🇧'
    },
    '1f1e9': {
      emoji: '🇧🇩'
    },
    '1f1ea': {
      emoji: '🇧🇪'
    },
    '1f1eb': {
      emoji: '🇧🇫'
    },
    '1f1ec': {
      emoji: '🇧🇬'
    },
    '1f1ed': {
      emoji: '🇧🇭'
    },
    '1f1ee': {
      emoji: '🇧🇮'
    },
    '1f1ef': {
      emoji: '🇧🇯'
    },
    '1f1f1': {
      emoji: '🇧🇱'
    },
    '1f1f2': {
      emoji: '🇧🇲'
    },
    '1f1f3': {
      emoji: '🇧🇳'
    },
    '1f1f4': {
      emoji: '🇧🇴'
    },
    '1f1f6': {
      emoji: '🇧🇶'
    },
    '1f1f7': {
      emoji: '🇧🇷'
    },
    '1f1f8': {
      emoji: '🇧🇸'
    },
    '1f1f9': {
      emoji: '🇧🇹'
    },
    '1f1fb': {
      emoji: '🇧🇻'
    },
    '1f1fc': {
      emoji: '🇧🇼'
    },
    '1f1fe': {
      emoji: '🇧🇾'
    },
    '1f1ff': {
      emoji: '🇧🇿'
    }
  },
  '1f1e8': {
    '1f1e6': {
      emoji: '🇨🇦'
    },
    '1f1e8': {
      emoji: '🇨🇨'
    },
    '1f1e9': {
      emoji: '🇨🇩'
    },
    '1f1eb': {
      emoji: '🇨🇫'
    },
    '1f1ec': {
      emoji: '🇨🇬'
    },
    '1f1ed': {
      emoji: '🇨🇭'
    },
    '1f1ee': {
      emoji: '🇨🇮'
    },
    '1f1f0': {
      emoji: '🇨🇰'
    },
    '1f1f1': {
      emoji: '🇨🇱'
    },
    '1f1f2': {
      emoji: '🇨🇲'
    },
    '1f1f3': {
      emoji: '🇨🇳'
    },
    '1f1f4': {
      emoji: '🇨🇴'
    },
    '1f1f5': {
      emoji: '🇨🇵'
    },
    '1f1f7': {
      emoji: '🇨🇷'
    },
    '1f1fa': {
      emoji: '🇨🇺'
    },
    '1f1fb': {
      emoji: '🇨🇻'
    },
    '1f1fc': {
      emoji: '🇨🇼'
    },
    '1f1fd': {
      emoji: '🇨🇽'
    },
    '1f1fe': {
      emoji: '🇨🇾'
    },
    '1f1ff': {
      emoji: '🇨🇿'
    }
  },
  '1f1e9': {
    '1f1ea': {
      emoji: '🇩🇪'
    },
    '1f1ec': {
      emoji: '🇩🇬'
    },
    '1f1ef': {
      emoji: '🇩🇯'
    },
    '1f1f0': {
      emoji: '🇩🇰'
    },
    '1f1f2': {
      emoji: '🇩🇲'
    },
    '1f1f4': {
      emoji: '🇩🇴'
    },
    '1f1ff': {
      emoji: '🇩🇿'
    }
  },
  '1f1ea': {
    '1f1e6': {
      emoji: '🇪🇦'
    },
    '1f1e8': {
      emoji: '🇪🇨'
    },
    '1f1ea': {
      emoji: '🇪🇪'
    },
    '1f1ec': {
      emoji: '🇪🇬'
    },
    '1f1ed': {
      emoji: '🇪🇭'
    },
    '1f1f7': {
      emoji: '🇪🇷'
    },
    '1f1f8': {
      emoji: '🇪🇸'
    },
    '1f1f9': {
      emoji: '🇪🇹'
    },
    '1f1fa': {
      emoji: '🇪🇺'
    }
  },
  '1f1eb': {
    '1f1ee': {
      emoji: '🇫🇮'
    },
    '1f1ef': {
      emoji: '🇫🇯'
    },
    '1f1f0': {
      emoji: '🇫🇰'
    },
    '1f1f2': {
      emoji: '🇫🇲'
    },
    '1f1f4': {
      emoji: '🇫🇴'
    },
    '1f1f7': {
      emoji: '🇫🇷'
    }
  },
  '1f1ec': {
    '1f1e6': {
      emoji: '🇬🇦'
    },
    '1f1e7': {
      emoji: '🇬🇧'
    },
    '1f1e9': {
      emoji: '🇬🇩'
    },
    '1f1ea': {
      emoji: '🇬🇪'
    },
    '1f1eb': {
      emoji: '🇬🇫'
    },
    '1f1ec': {
      emoji: '🇬🇬'
    },
    '1f1ed': {
      emoji: '🇬🇭'
    },
    '1f1ee': {
      emoji: '🇬🇮'
    },
    '1f1f1': {
      emoji: '🇬🇱'
    },
    '1f1f2': {
      emoji: '🇬🇲'
    },
    '1f1f3': {
      emoji: '🇬🇳'
    },
    '1f1f5': {
      emoji: '🇬🇵'
    },
    '1f1f6': {
      emoji: '🇬🇶'
    },
    '1f1f7': {
      emoji: '🇬🇷'
    },
    '1f1f8': {
      emoji: '🇬🇸'
    },
    '1f1f9': {
      emoji: '🇬🇹'
    },
    '1f1fa': {
      emoji: '🇬🇺'
    },
    '1f1fc': {
      emoji: '🇬🇼'
    },
    '1f1fe': {
      emoji: '🇬🇾'
    }
  },
  '1f1ed': {
    '1f1f0': {
      emoji: '🇭🇰'
    },
    '1f1f2': {
      emoji: '🇭🇲'
    },
    '1f1f3': {
      emoji: '🇭🇳'
    },
    '1f1f7': {
      emoji: '🇭🇷'
    },
    '1f1f9': {
      emoji: '🇭🇹'
    },
    '1f1fa': {
      emoji: '🇭🇺'
    }
  },
  '1f1ee': {
    '1f1e8': {
      emoji: '🇮🇨'
    },
    '1f1e9': {
      emoji: '🇮🇩'
    },
    '1f1ea': {
      emoji: '🇮🇪'
    },
    '1f1f1': {
      emoji: '🇮🇱'
    },
    '1f1f2': {
      emoji: '🇮🇲'
    },
    '1f1f3': {
      emoji: '🇮🇳'
    },
    '1f1f4': {
      emoji: '🇮🇴'
    },
    '1f1f6': {
      emoji: '🇮🇶'
    },
    '1f1f7': {
      emoji: '🇮🇷'
    },
    '1f1f8': {
      emoji: '🇮🇸'
    },
    '1f1f9': {
      emoji: '🇮🇹'
    }
  },
  '1f1ef': {
    '1f1ea': {
      emoji: '🇯🇪'
    },
    '1f1f2': {
      emoji: '🇯🇲'
    },
    '1f1f4': {
      emoji: '🇯🇴'
    },
    '1f1f5': {
      emoji: '🇯🇵'
    }
  },
  '1f1f0': {
    '1f1ea': {
      emoji: '🇰🇪'
    },
    '1f1ec': {
      emoji: '🇰🇬'
    },
    '1f1ed': {
      emoji: '🇰🇭'
    },
    '1f1ee': {
      emoji: '🇰🇮'
    },
    '1f1f2': {
      emoji: '🇰🇲'
    },
    '1f1f3': {
      emoji: '🇰🇳'
    },
    '1f1f5': {
      emoji: '🇰🇵'
    },
    '1f1f7': {
      emoji: '🇰🇷'
    },
    '1f1fc': {
      emoji: '🇰🇼'
    },
    '1f1fe': {
      emoji: '🇰🇾'
    },
    '1f1ff': {
      emoji: '🇰🇿'
    }
  },
  '1f1f1': {
    '1f1e6': {
      emoji: '🇱🇦'
    },
    '1f1e7': {
      emoji: '🇱🇧'
    },
    '1f1e8': {
      emoji: '🇱🇨'
    },
    '1f1ee': {
      emoji: '🇱🇮'
    },
    '1f1f0': {
      emoji: '🇱🇰'
    },
    '1f1f7': {
      emoji: '🇱🇷'
    },
    '1f1f8': {
      emoji: '🇱🇸'
    },
    '1f1f9': {
      emoji: '🇱🇹'
    },
    '1f1fa': {
      emoji: '🇱🇺'
    },
    '1f1fb': {
      emoji: '🇱🇻'
    },
    '1f1fe': {
      emoji: '🇱🇾'
    }
  },
  '1f1f2': {
    '1f1e6': {
      emoji: '🇲🇦'
    },
    '1f1e8': {
      emoji: '🇲🇨'
    },
    '1f1e9': {
      emoji: '🇲🇩'
    },
    '1f1ea': {
      emoji: '🇲🇪'
    },
    '1f1eb': {
      emoji: '🇲🇫'
    },
    '1f1ec': {
      emoji: '🇲🇬'
    },
    '1f1ed': {
      emoji: '🇲🇭'
    },
    '1f1f0': {
      emoji: '🇲🇰'
    },
    '1f1f1': {
      emoji: '🇲🇱'
    },
    '1f1f2': {
      emoji: '🇲🇲'
    },
    '1f1f3': {
      emoji: '🇲🇳'
    },
    '1f1f4': {
      emoji: '🇲🇴'
    },
    '1f1f5': {
      emoji: '🇲🇵'
    },
    '1f1f6': {
      emoji: '🇲🇶'
    },
    '1f1f7': {
      emoji: '🇲🇷'
    },
    '1f1f8': {
      emoji: '🇲🇸'
    },
    '1f1f9': {
      emoji: '🇲🇹'
    },
    '1f1fa': {
      emoji: '🇲🇺'
    },
    '1f1fb': {
      emoji: '🇲🇻'
    },
    '1f1fc': {
      emoji: '🇲🇼'
    },
    '1f1fd': {
      emoji: '🇲🇽'
    },
    '1f1fe': {
      emoji: '🇲🇾'
    },
    '1f1ff': {
      emoji: '🇲🇿'
    }
  },
  '1f1f3': {
    '1f1e6': {
      emoji: '🇳🇦'
    },
    '1f1e8': {
      emoji: '🇳🇨'
    },
    '1f1ea': {
      emoji: '🇳🇪'
    },
    '1f1eb': {
      emoji: '🇳🇫'
    },
    '1f1ec': {
      emoji: '🇳🇬'
    },
    '1f1ee': {
      emoji: '🇳🇮'
    },
    '1f1f1': {
      emoji: '🇳🇱'
    },
    '1f1f4': {
      emoji: '🇳🇴'
    },
    '1f1f5': {
      emoji: '🇳🇵'
    },
    '1f1f7': {
      emoji: '🇳🇷'
    },
    '1f1fa': {
      emoji: '🇳🇺'
    },
    '1f1ff': {
      emoji: '🇳🇿'
    }
  },
  '1f1f4': {
    '1f1f2': {
      emoji: '🇴🇲'
    }
  },
  '1f1f5': {
    '1f1e6': {
      emoji: '🇵🇦'
    },
    '1f1ea': {
      emoji: '🇵🇪'
    },
    '1f1eb': {
      emoji: '🇵🇫'
    },
    '1f1ec': {
      emoji: '🇵🇬'
    },
    '1f1ed': {
      emoji: '🇵🇭'
    },
    '1f1f0': {
      emoji: '🇵🇰'
    },
    '1f1f1': {
      emoji: '🇵🇱'
    },
    '1f1f2': {
      emoji: '🇵🇲'
    },
    '1f1f3': {
      emoji: '🇵🇳'
    },
    '1f1f7': {
      emoji: '🇵🇷'
    },
    '1f1f8': {
      emoji: '🇵🇸'
    },
    '1f1f9': {
      emoji: '🇵🇹'
    },
    '1f1fc': {
      emoji: '🇵🇼'
    },
    '1f1fe': {
      emoji: '🇵🇾'
    }
  },
  '1f1f6': {
    '1f1e6': {
      emoji: '🇶🇦'
    }
  },
  '1f1f7': {
    '1f1ea': {
      emoji: '🇷🇪'
    },
    '1f1f4': {
      emoji: '🇷🇴'
    },
    '1f1f8': {
      emoji: '🇷🇸'
    },
    '1f1fa': {
      emoji: '🇷🇺'
    },
    '1f1fc': {
      emoji: '🇷🇼'
    }
  },
  '1f1f8': {
    '1f1e6': {
      emoji: '🇸🇦'
    },
    '1f1e7': {
      emoji: '🇸🇧'
    },
    '1f1e8': {
      emoji: '🇸🇨'
    },
    '1f1e9': {
      emoji: '🇸🇩'
    },
    '1f1ea': {
      emoji: '🇸🇪'
    },
    '1f1ec': {
      emoji: '🇸🇬'
    },
    '1f1ed': {
      emoji: '🇸🇭'
    },
    '1f1ee': {
      emoji: '🇸🇮'
    },
    '1f1ef': {
      emoji: '🇸🇯'
    },
    '1f1f0': {
      emoji: '🇸🇰'
    },
    '1f1f1': {
      emoji: '🇸🇱'
    },
    '1f1f2': {
      emoji: '🇸🇲'
    },
    '1f1f3': {
      emoji: '🇸🇳'
    },
    '1f1f4': {
      emoji: '🇸🇴'
    },
    '1f1f7': {
      emoji: '🇸🇷'
    },
    '1f1f8': {
      emoji: '🇸🇸'
    },
    '1f1f9': {
      emoji: '🇸🇹'
    },
    '1f1fb': {
      emoji: '🇸🇻'
    },
    '1f1fd': {
      emoji: '🇸🇽'
    },
    '1f1fe': {
      emoji: '🇸🇾'
    },
    '1f1ff': {
      emoji: '🇸🇿'
    }
  },
  '1f1f9': {
    '1f1e6': {
      emoji: '🇹🇦'
    },
    '1f1e8': {
      emoji: '🇹🇨'
    },
    '1f1e9': {
      emoji: '🇹🇩'
    },
    '1f1eb': {
      emoji: '🇹🇫'
    },
    '1f1ec': {
      emoji: '🇹🇬'
    },
    '1f1ed': {
      emoji: '🇹🇭'
    },
    '1f1ef': {
      emoji: '🇹🇯'
    },
    '1f1f0': {
      emoji: '🇹🇰'
    },
    '1f1f1': {
      emoji: '🇹🇱'
    },
    '1f1f2': {
      emoji: '🇹🇲'
    },
    '1f1f3': {
      emoji: '🇹🇳'
    },
    '1f1f4': {
      emoji: '🇹🇴'
    },
    '1f1f7': {
      emoji: '🇹🇷'
    },
    '1f1f9': {
      emoji: '🇹🇹'
    },
    '1f1fb': {
      emoji: '🇹🇻'
    },
    '1f1fc': {
      emoji: '🇹🇼'
    },
    '1f1ff': {
      emoji: '🇹🇿'
    }
  },
  '1f1fa': {
    '1f1e6': {
      emoji: '🇺🇦'
    },
    '1f1ec': {
      emoji: '🇺🇬'
    },
    '1f1f2': {
      emoji: '🇺🇲'
    },
    '1f1f3': {
      emoji: '🇺🇳'
    },
    '1f1f8': {
      emoji: '🇺🇸'
    },
    '1f1fe': {
      emoji: '🇺🇾'
    },
    '1f1ff': {
      emoji: '🇺🇿'
    }
  },
  '1f1fb': {
    '1f1e6': {
      emoji: '🇻🇦'
    },
    '1f1e8': {
      emoji: '🇻🇨'
    },
    '1f1ea': {
      emoji: '🇻🇪'
    },
    '1f1ec': {
      emoji: '🇻🇬'
    },
    '1f1ee': {
      emoji: '🇻🇮'
    },
    '1f1f3': {
      emoji: '🇻🇳'
    },
    '1f1fa': {
      emoji: '🇻🇺'
    }
  },
  '1f1fc': {
    '1f1eb': {
      emoji: '🇼🇫'
    },
    '1f1f8': {
      emoji: '🇼🇸'
    }
  },
  '1f1fd': {
    '1f1f0': {
      emoji: '🇽🇰'
    }
  },
  '1f1fe': {
    '1f1ea': {
      emoji: '🇾🇪'
    },
    '1f1f9': {
      emoji: '🇾🇹'
    }
  },
  '1f1ff': {
    '1f1e6': {
      emoji: '🇿🇦'
    },
    '1f1f2': {
      emoji: '🇿🇲'
    },
    '1f1fc': {
      emoji: '🇿🇼'
    }
  }
}

const replace = (text, callback) => {
  let newText = ''
  let currentEmObjectLevel = emObject
  let replaceLetterCounter = 0

  const textArray = [...text]

  for (let letterCounter = 0; letterCounter < textArray.length; letterCounter++) {
    const letter = textArray[letterCounter]
    const nextLetter = textArray[letterCounter + 1]
    const codePoint = letter.codePointAt(0).toString(16)
    if (currentEmObjectLevel[codePoint] && !currentEmObjectLevel[codePoint]?.emoji) {
      currentEmObjectLevel = currentEmObjectLevel[codePoint]
      replaceLetterCounter++
    } else if (currentEmObjectLevel[codePoint]?.emoji) {
      const nextLetterCodePoint = nextLetter?.codePointAt(0)?.toString(16)
      if (nextLetterCodePoint && currentEmObjectLevel?.[codePoint]?.[nextLetterCodePoint]) {
        currentEmObjectLevel = currentEmObjectLevel[codePoint]
        replaceLetterCounter++
      } else {
        // Replace text
        newText = newText + callback(currentEmObjectLevel[codePoint]?.emoji)
        replaceLetterCounter = 0
        currentEmObjectLevel = emObject
        continue
      }
    } else {
      currentEmObjectLevel = emObject
      if (replaceLetterCounter > 0) {
        newText = newText + textArray.slice(letterCounter - replaceLetterCounter, letterCounter + 1).join('')
      } else {
        newText = newText + textArray[letterCounter]
      }
      replaceLetterCounter = 0
    }
  }
  return newText
}

const img = (plainText) => {
  const result =
    replace(plainText, (match) => {
      const emojiCodeList = unicode.parseEmoji(match)
      if (emojiCodeList.length) {
        return `<img src="/images/emoji/${emojiCodeList.join(
          '_'
        )}.png" class="emoji-img" emojicode="${emojiCodeList.join('+')}">`
      } else {
        return ''
      }
    }) || ''
  return result
}

const span = (plainText) => {
  const result =
    replace(plainText, (match) => {
      const emojiCodeList = unicode.parseEmoji(match)
      return `<span><span style="background-image:url('/images/emoji/${emojiCodeList.join(
        '_'
      )}.png')" class="emoji-span" emojicode="${emojiCodeList.join('+')}">${match}</span></span>`
    }) || ''
  return result
}

export default { img, span }
