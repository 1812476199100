import axios from 'axios'
import Api from '@/services/api'
import { FileModel } from '@/models/file'
import _ from 'lodash'

const initialState = () => ({
  uploadingFiles: []
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    uploadingFiles: (state) => {
      return state.uploadingFiles
    },
    uploadingFileByUUID: (state) => (uuid) => {
      return state.uploadingFiles.find((f) => f.uuid === uuid)
    },
    uploadingFilesByUUID: (state) => (uuids) => {
      return state.uploadingFiles.filter((f) => {
        return !!uuids.find((uuid) => uuid === f.uuid)
      })
    },
    filesTotalSize: (state, getters) => (uuids) => {
      return getters.uploadingFilesByUUID(uuids).reduce((acc, file) => {
        return acc + file.fileSize
      }, 0)
    },
    filesLoadedSize: (state, getters) => (uuids) => {
      return getters.uploadingFilesByUUID(uuids).reduce((acc, file) => {
        return acc + file.loadedSize
      }, 0)
    },
    filesLoadedPercentage: (state, getters) => (uuids) => {
      let totalSize = getters.filesTotalSize(uuids)
      let loadedSize = getters.filesLoadedSize(uuids)
      let percentage = Math.floor((loadedSize * 100) / totalSize)
      if (percentage < 100) {
        return percentage
      } else {
        return 100
      }
    }
  },
  mutations: {
    REMOVE_FILE_DATA(state, uuids) {
      uuids.forEach((uuid) => {
        let stateFile = state.uploadingFiles.find((uf) => uf.uuid === uuid)
        if (stateFile) {
          state.uploadingFiles.splice(state.uploadingFiles.indexOf(stateFile), 1)
        }
      })
    },
    SET_FILE_DATA(state, data) {
      let stateFile = state.uploadingFiles.find((uf) => uf.uuid === data.uuid)
      if (stateFile) {
        stateFile.loadedSize = typeof data.loadedSize !== 'undefined' ? data.loadedSize : stateFile.loadedSize
        stateFile.uploading = data.uploading
      } else {
        state.uploadingFiles.push(data)
      }
    },
    CANCEL_FILE_DATA(state, uuid) {
      let stateFile = state.uploadingFiles.find((uf) => uf.uuid === uuid)
      stateFile.canceled = true
    },
    REMOVE_ALL(state) {
      const newState = initialState()
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key]
      })
    }
  },
  actions: {
    prepareAndUpload({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        let fileType = null
        const isHeic = /\.heic$/.test(data.file.name) || /\.heif$/.test(data.file.name)
        fileType = data.file.type || (isHeic ? 'image/heic' : null)

        let cancel = null
        const cancelToken = new axios.CancelToken((c) => {
          cancel = c
        })
        commit('SET_FILE_DATA', {
          uuid: data.uuid,
          mime: fileType,
          loadedSize: 0,
          fileSize: data.file.size,
          cancel,
          uploading: true,
          canceled: false
        })

        Api.studies
          .prepareUpload(
            {
              originalName: data.file.name,
              size: data.file.size,
              mimetype: fileType,
              studyId: data.studyId
            },
            {
              cancelToken,
              maxRedirects: 0
            }
          )
          .then((response) => {
            const { file } = response
            const { uploadingInfo } = file

            // Prepare FormData:
            const formData = new FormData()
            _.each(uploadingInfo.fields, (value, key) => {
              formData.append(key, value)
            })
            formData.append('file', data.file)

            axios
              .post(uploadingInfo.url, formData, {
                cancelToken,
                maxRedirects: 0,
                onUploadProgress: (e) => {
                  commit('SET_FILE_DATA', {
                    uuid: data.uuid,
                    loadedSize: e.loaded,
                    fileSize: data.file.size,
                    uploading: true
                  })
                }
              })
              .then(
                async () =>
                  await axios.get(uploadingInfo.fields.success_action_redirect, {
                    params: {
                      key: uploadingInfo.fields.key,
                      bucket: uploadingInfo.fields.bucket
                    }
                  })
              )
              .then((response) => {
                setTimeout(() => {
                  const { file } = response.data
                  commit('SET_FILE_DATA', {
                    uuid: data.uuid,
                    uploading: false
                  })
                  resolve(file)
                }, 1000)
              })
              .catch((e) => {
                if (getters.uploadingFileByUUID(data.uuid).canceled) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject(new Error('canceled'))
                } else {
                  reject(e)
                }
                commit('SET_FILE_DATA', {
                  uuid: data.uuid,
                  uploading: false
                })
              })
          })
          .catch((e) => {
            if (getters.uploadingFileByUUID(data.uuid).canceled) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(new Error('canceled'))
            } else {
              reject(e)
            }
            commit('SET_FILE_DATA', {
              uuid: data.uuid,
              uploading: false
            })
          })
      })
    },
    upload({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        let cancel = null
        let cancelToken = new axios.CancelToken((c) => {
          cancel = c
        })
        commit('SET_FILE_DATA', {
          uuid: data.uuid,
          mime: data.file.type,
          loadedSize: 0,
          fileSize: data.file.size,
          cancel,
          uploading: true,
          canceled: false
        })
        let queryConfig = {
          onUploadProgress: (e) => {
            commit('SET_FILE_DATA', {
              uuid: data.uuid,
              loadedSize: e.loaded,
              fileSize: data.file.size,
              uploading: true
            })
          },
          cancelToken: cancelToken
        }
        let uploadPromise
        if (data.uploadingUrl && data.uploadingFields) {
          const formData = new FormData()
          Object.keys(data.uploadingFields).forEach((key) => {
            formData.append(key, data.uploadingFields[key])
          })
          formData.append('file', data.file)
          uploadPromise = axios.post(data.uploadingUrl, formData, queryConfig)

          /*
          uploadPromise = Api.studies.uploadFile(data.uuid, formData, queryConfig)*/
        } else {
          const formData = new FormData()
          formData.append('file', data.file)
          uploadPromise = Api.studies.upload(formData, queryConfig)
        }
        uploadPromise
          .then((response) => {
            /*this.uploadedFiles.push(response.file)*/
            commit('SET_FILE_DATA', {
              uuid: data.uuid,
              uploading: false
            })
            resolve(response.file || response.data.file)
          })
          .catch((e) => {
            if (getters.uploadingFileByUUID(data.uuid).canceled) {
              reject('canceled')
            } else {
              reject(e)
            }
            commit('SET_FILE_DATA', {
              uuid: data.uuid,
              uploading: false
            })
          })
      })
    },
    prepare({}, { file, fileType, studyId }) {
      return new Promise((resolve) => {
        Api.studies
          .prepareUpload({
            originalName: file.name,
            mimetype: fileType || file.type,
            size: file.size,
            studyId: studyId
          })
          .then((serverFile) => {
            const newFile = FileModel.parseFromApi({
              file: serverFile.file,
              isTemporary: true,
              temporaryData: {
                browserFile: file
              }
            })
            resolve(newFile)
          })
      })
    },
    cancel({ commit, getters }, uuids) {
      let uploadingFiles = getters['uploadingFilesByUUID'](uuids)
      uploadingFiles.forEach((uf) => {
        commit('CANCEL_FILE_DATA', uf.uuid)
        uf.cancel()
      })
    },
    reset({ commit }) {
      commit('REMOVE_ALL')
    }
  }
}
