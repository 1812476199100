import { BaseModel } from '@/models/baseModel'
import { PollChoiceModel } from '@/models/pollChoice'
import { PollNumberStyle, PollType } from '@/common/enums'

export class PollModel extends BaseModel {
  constructor(pollData = {}) {
    super()
    const {
      id,
      type,
      isAnswerLimitEnabled,
      answerLimitAmount,
      hasRandomRowsOrder,
      hasRandomColumnsOrder,
      isThumbnailsLarge,
      isLabelsHidden,
      numberStyle,
      columns,
      rows,
      starsAmount,
      sliderFrom,
      sliderTo,
      sliderFromLabel,
      sliderToLabel,
      isRequiredResponse,
      isResponded
    } = pollData || {}

    this.id = id || null

    this.isResponded = isResponded || false

    this.type = type || null

    this.numberStyle = numberStyle || null

    this.isAnswerLimitEnabled = isAnswerLimitEnabled ?? false
    this.answerLimitAmount = answerLimitAmount ?? null
    this.hasRandomRowsOrder = hasRandomRowsOrder ?? false
    this.hasRandomColumnsOrder = hasRandomColumnsOrder ?? false

    this.isThumbnailsLarge = isThumbnailsLarge ?? false
    this.isLabelsHidden = isLabelsHidden ?? false

    this.columns = Array.isArray(columns) ? columns.map((column) => new PollChoiceModel(column)) : []

    this.rows = Array.isArray(rows) ? rows.map((row) => new PollChoiceModel(row)) : []

    this.starsAmount = starsAmount
    this.sliderFrom = sliderFrom
    this.sliderTo = sliderTo
    this.sliderFromLabel = sliderFromLabel
    this.sliderToLabel = sliderToLabel

    this.isRequiredResponse = isRequiredResponse ?? false
  }

  static parseFromApi({ poll }) {
    return new PollModel({
      id: poll?.id,

      type: poll?.type,

      numberStyle: poll?.style,

      isAnswerLimitEnabled: !!poll?.limitAnswers,
      answerLimitAmount: poll?.limitAnswers,
      hasRandomRowsOrder: poll?.hasRandomRowsOrder,
      hasRandomColumnsOrder: poll?.hasRandomColumnsOrder,
      isThumbnailsLarge: poll?.largeThumbnails,
      isLabelsHidden: poll?.hideText,

      isResponded: poll?.isResponded,

      columns: Array.isArray(poll?.columns)
        ? poll.columns
            .map((column) => PollChoiceModel.parseFromApi({ choice: column }))
            .sort((a, b) => a.order - b.order)
        : [],
      rows: Array.isArray(poll?.rows)
        ? poll.rows
            .map((row) => PollChoiceModel.parseFromApi({ choice: row }))
            .sort((a, b) => a.order - b.order)
        : [],

      starsAmount: poll?.starsAmount,
      sliderFrom: poll?.sliderFrom,
      sliderTo: poll?.sliderTo,
      sliderFromLabel: poll?.sliderFromLabel,
      sliderToLabel: poll?.sliderToLabel,
      isRequiredResponse: poll?.isRequiredResponse
    })
  }

  convertToApi() {
    if (!this.type) {
      return null
    }
    const apiObject = {
      id: this.id || undefined,
      type: this.type || undefined,
      isRequiredResponse: this.isRequiredResponse
    }
    if (this.type === PollType.NUMBER_ENTRY) {
      apiObject.numberStyle = this.numberStyle
      if (this.numberStyle === PollNumberStyle.SLIDER) {
        apiObject.sliderFrom = this.sliderFrom
        apiObject.sliderTo = this.sliderTo
        apiObject.sliderFromLabel = this.sliderFromLabel
        apiObject.sliderToLabel = this.sliderToLabel
      }
      if (this.numberStyle === PollNumberStyle.STARS) {
        apiObject.starsAmount = this.starsAmount
      }
    }

    if (
      this.type === PollType.RANKED_LIST ||
      this.type === PollType.MULTIPLE_CHOICE ||
      this.type === PollType.MATRIX
    ) {
      apiObject.limitAnswers = this.isAnswerLimitEnabled ? this.answerLimitAmount : undefined
      apiObject.hasRandomRowsOrder = this.hasRandomRowsOrder
      apiObject.hasRandomColumnsOrder = this.type === PollType.MATRIX ? this.hasRandomColumnsOrder : undefined
      apiObject.largeThumbnails = this.isThumbnailsLarge
      apiObject.hideText = this.isLabelsHidden
      apiObject.columns =
        this.type === PollType.MATRIX
          ? this.columns.map((column, order) => ({ ...column.convertToApi(), order: order + 1 }))
          : undefined
      apiObject.rows = this.rows.map((row, order) => ({ ...row.convertToApi(), order: order + 1 }))
    }
    return apiObject
  }
}
