<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.9994" cy="10.9994" r="10.3158" fill="white" />
    <path d="M14.6836 11.0006L9.15728 14.1912L9.15728 7.81L14.6836 11.0006Z" fill="#25313D" />
  </svg>
</template>

<script>
export default {
  name: 'PlayIcon'
}
</script>

<style lang="scss" scoped>
.PlayIcon {
}
</style>
