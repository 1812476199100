<template>
  <div class="PollNumberSlider">
    <PollNumberEditorSlider
      v-if="(!poll.isResponded && !showResults) || editMode"
      :response.sync="localResponse"
      :poll="poll"
      :is-first-time-fill="false"
      @update:isValid="onValidUpdate"
    />
    <div v-else>
      <PollNumberResults :thread="thread" :poll="poll" />
    </div>
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'
import PollNumberResults from '@/components/modules/forum-chat/poll/number/PollNumberResults.vue'
import PollNumberEditorSlider from '@/components/modules/poll/number/editor/PollNumberEditorSlider.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollNumberSlider',
  components: { PollNumberEditorSlider, PollNumberResults },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    showResults: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
      },
      deep: true
    }
  },
  methods: {
    onValidUpdate(isValid) {
      this.$emit('update:isValid', isValid)
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberSlider {
}
</style>
