<template>
  <LLConfirmModalTransition>
    <div class="StudyNewPageUnfinished">
      <StudyNewPageUnfinishedError
        v-if="isErrorModalVisible"
        :sending-states="questionSendingStates"
        :questions="startedQuestions"
        :question-indexes="questionIndexes"
        @cancel="$emit('close')"
      />
      <StudyNewPageUnfinishedContent
        v-if="!isErrorModalVisible"
        :sending-states="questionSendingStates"
        :questions="startedQuestions"
        :question-indexes="questionIndexes"
        @cancel="$emit('close')"
        @submit="submit"
        @skip="onSkip"
      />
      <div class="StudyNewPageUnfinished__messages">
        <StudyConversationMessage
          v-for="question in startedQuestions"
          ref="message"
          :key="question.id"
          :message="savedMessage(question.id)"
          :options="messageOptions(question.id)"
          send-using-store
          :stream-parameters="streamParameters(question.id)"
        />
      </div>
    </div>
  </LLConfirmModalTransition>
</template>

<script>
import { StudyModel } from '@/models/study'
import StudyNewPageUnfinishedContent from '@/components/pages/study-new/unfinished/StudyNewPageUnfinishedContent.vue'
import StudyNewPageUnfinishedError from '@/components/pages/study-new/unfinished/StudyNewPageUnfinishedError.vue'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import StudyConversationMessage from '@/components/modules/study/StudyConversationMessage.vue'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'

export default {
  name: 'StudyNewPageUnfinished',
  components: {
    StudyConversationMessage,
    LLConfirmModalTransition,
    StudyNewPageUnfinishedError,
    StudyNewPageUnfinishedContent
  },
  props: {
    study: { type: StudyModel, required: true },
    /**
     * @type {QuestionModel[]}
     */
    questions: { type: Array, required: true, default: () => [] },
    conversationStreams: { type: Array, required: true, default: () => [] },
    questionIndexes: { type: Object, default: () => {} }
  },
  data() {
    return {
      questionSendingStates: {},
      isPending: false,
      completedQuestions: 0,
      startedQuestions: [],
      isErrorModalVisible: false
    }
  },
  mounted() {
    this.startedQuestions = [...this.questions]
  },
  methods: {
    savedMessage(questionId) {
      return this.$store.getters['studyMessages/savedMessage']({ questionId: questionId })
    },
    streamParameters(questionId) {
      return new StudyConversationMessageStreamParametersModel({
        question: this.startedQuestions.find((question) => question.id === questionId),
        prospect: this.$store.getters['user/userObject'],
        studyId: this.study.id
      })
    },
    messageOptions(questionId) {
      const conversationStream = this.conversationStreams.find(
        (conversationStream) => conversationStream.questionId === questionId
      )
      return new StudyConversationMessageOptionsModel({
        type:
          conversationStream?.messages?.filter((message) => !message.deletedAt)?.length === 0
            ? StudyConversationMessageType.ANSWER
            : StudyConversationMessageType.NEW_COMMENT
      })
    },
    onCompleteQuestion() {
      this.completedQuestions++
      if (this.completedQuestions === this.startedQuestions.length) {
        this.complete()
      }
    },
    onSkip() {
      this.$emit('close', { withCallback: true })
    },
    complete() {
      if (Object.values(this.questionSendingStates).includes(30)) {
        this.isErrorModalVisible = true
        return
      }
      this.$emit('close', { withCallback: true })
    },
    submit() {
      this.isPending = true
      this.$refs.message.forEach((messageComponent) => {
        const questionId = messageComponent.message.questionId
        if (!messageComponent.$refs.editor.isValid) {
          this.$set(this.questionSendingStates, questionId, 30)
        }
        this.$set(this.questionSendingStates, questionId, 10)
        try {
          messageComponent
            .send({ throwError: true })
            .then(() => {
              this.$set(this.questionSendingStates, questionId, 20)
              this.onCompleteQuestion()
            })
            .catch((e) => {
              console.log(e)
              this.$set(this.questionSendingStates, questionId, 30)
              this.onCompleteQuestion()
            })
        } catch (e) {
          console.log(e)
          this.$set(this.questionSendingStates, questionId, 30)
          this.onCompleteQuestion()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageUnfinished {
  &__messages {
    @apply hidden;
  }
}
</style>
