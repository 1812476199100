<template>
  <svg width="20" height="20" class="fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" />
    <path
      d="M6.18335 10.4389L8.98335 13.1312L14.0671 7.15625"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CompletedIcon'
}
</script>

<style lang="scss" scoped>
.Completed {
}
</style>
