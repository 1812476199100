export class FilterHelper {
  constructor() {
    this.labels = []
    this.checkboxes = []
    this.checkboxLists = []
    this.ranges = []
    this.strings = []
  }

  addCheckbox(filterName, label, clearCallback) {
    this.checkboxes.push({ filterName, label, clearCallback })
  }

  addCheckboxList(filterName, values, clearCallback, options = {}) {
    const defaultOptions = {
      allowToRemove: true
    }
    const mergedOptions = {
      ...defaultOptions,
      ...options
    }
    this.checkboxLists.push({
      filterName,
      values,
      clearCallback,
      options: mergedOptions
    })
  }

  addString(filterName, clearCallback) {
    this.strings.push({
      filterName,
      clearCallback
    })
  }

  addStringArray(filterName, clearCallback) {
    this.stringsArray.push({
      filterName,
      clearCallback
    })
  }

  addRange(filterName, label, clearCallback) {
    this.ranges.push({
      filterName,
      label,
      clearCallback
    })
  }

  getLabels({ filterValues }) {
    const labels = []
    this.checkboxes.forEach((checkbox) => {
      if (filterValues[checkbox.filterName]) {
        labels.push({
          label: checkbox.label,
          clear() {
            filterValues[checkbox.filterName] = false
            if (checkbox.clearCallback) {
              checkbox.clearCallback()
            }
          }
        })
      }
    })
    this.checkboxLists.forEach((checkboxList) => {
      if (!filterValues[checkboxList.filterName]) return
      filterValues[checkboxList.filterName].forEach((id) => {
        const checkbox = checkboxList?.values?.find((checkbox) => checkbox?.id === id)
        if (!checkbox) {
          return
        }
        labels.push({
          label: checkbox.label,
          clear: checkboxList?.options?.allowToRemove
            ? () => {
                filterValues[checkboxList.filterName] = filterValues[checkboxList.filterName].filter(
                  (_id) => _id !== id
                )
                if (checkboxList.clearCallback) {
                  checkboxList.clearCallback()
                }
              }
            : null
        })
      })
    })
    this.ranges.forEach((range) => {
      if (!filterValues[range.filterName]) return
      const values = filterValues[range.filterName]
      let valuePartOfLabel = null
      if (values[0] !== null && values[1] !== null) {
        if (values[0] === values[1]) {
          valuePartOfLabel = `${values[0]}`
        } else {
          valuePartOfLabel = `${values[0]} - ${values[1]}`
        }
      } else if (values[0] !== null) {
        valuePartOfLabel = `> ${values[0]}`
      } else if (values[1] !== null) {
        valuePartOfLabel = `< ${values[1]}`
      }
      if (valuePartOfLabel) {
        labels.push({
          label: `${range.label}: ${valuePartOfLabel}`,
          clear() {
            filterValues[range.filterName] = [null, null]
            if (range.clearCallback) {
              range.clearCallback()
            }
          }
        })
      }
    })
    this.strings.forEach((str) => {
      if (!filterValues[str.filterName]) return
      if (filterValues[str.filterName]) {
        labels.push({
          label: filterValues[str.filterName],
          clear() {
            filterValues[str.filterName] = null
            if (str.clearCallback) {
              str.clearCallback()
            }
          }
        })
      }
    })
    return labels
  }
}
