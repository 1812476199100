<template>
  <div class="PollRanked">
    <PollRankedEditor
      v-if="(!poll.isResponded && !showResults) || editMode"
      ref="editor"
      :poll="poll"
      :response.sync="localResponse"
      @update:isValid="onIsValidUpdate"
      @confirm-send="onConfirm"
    />
    <PollRankedResults v-else :thread="thread" :poll="poll" />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollType } from '@/common/enums'
import PollRankedResults from '@/components/modules/forum-chat/poll/ranked/PollRankedResults.vue'
import PollRankedEditor from '@/components/modules/poll/ranked/PollRankedEditor.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollRanked',
  components: { PollRankedEditor, PollRankedResults },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    showResults: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    sortedRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    PollTypeEnum() {
      return PollType
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
      },
      deep: true
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm-send')
    },
    send() {
      this.$refs?.editor && this.$refs.editor.send()
    },
    onIsValidUpdate(isValid) {
      this.$emit('update:isValid', isValid)
    }
  }
}
</script>

<style scoped lang="scss">
.PollRanked {
}
</style>
