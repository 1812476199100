<template>
  <div class="LLListSelector">
    <LLCheckbox
      v-model="localSelectedAllVisible"
      class="LLListSelector__checkbox"
      @change="onSelectAllVisibleChange"
      >{{ $t('table_selected_select-all-visible') }}</LLCheckbox
    >
    <LLCheckbox v-model="localSelectedAll" class="LLListSelector__checkbox" @change="onSelectAllChange">{{
      $t('table_selected_select-all-without-number')
    }}</LLCheckbox>
  </div>
</template>

<script>
import LLCheckbox from '@/components/common/LLCheckbox'
export default {
  name: 'LLListSelector',
  components: { LLCheckbox },
  props: {
    idAmount: { type: Number, default: 0 },
    idList: { type: Array, default: () => [] },
    selectedIdList: { type: Array, default: () => [] },
    selectedAll: { type: Boolean, default: false },
    selectedAllVisible: { type: Boolean, default: false }
  },
  data() {
    return {
      localIdList: [],
      localSelectedIdList: [],
      localSelectedAll: false,
      localSelectedAllVisible: false
    }
  },
  computed: {},
  watch: {
    idList: {
      handler() {
        this.localIdList = [...this.idList]
      },
      immediate: true
    },
    localIdList: {
      handler() {
        /* if (this.localSelectedAll) {
          this.localSelectedIdList = [...this.idList]
        } else {
        } */
        this.localSelectedIdList = this.localSelectedIdList.filter((sId) =>
          this.idList.find((id) => id === sId)
        )
        this.$emit('update:selectedIdList', [...this.localSelectedIdList])
      }
    },
    localSelectedIdList: {
      handler() {
        this.localSelectedAllVisible =
          this.localSelectedIdList.length === this.localIdList.length &&
          this.localIdList?.length !== 0 &&
          !this.localSelectedAll
        if (!this.localSelectedAll && this.localIdList?.length !== 0) {
          this.localSelectedAll = this.localSelectedIdList.length === this.idAmount
        }
        if (this.localSelectedIdList.length !== this.localIdList.length) {
          this.localSelectedAll = false
        }
      }
    },
    localSelectedAll: {
      handler(value) {
        this.$emit('update:selectedAll', value)
      }
    },
    localSelectedAllVisible: {
      handler(value) {
        this.$emit('update:selectedAllVisible', value)
      }
    },
    selectedIdList: {
      handler() {
        this.localSelectedIdList = [...this.selectedIdList]
      },
      immediate: true
    },
    selectedAllVisible: {
      handler() {
        this.localSelectedAllVisible = this.selectedAllVisible
      }
    },
    selectedAll: {
      handler() {
        this.localSelectedAll = this.selectedAll
      }
    }
  },
  methods: {
    onSelectAllChange(value) {
      this.localSelectedAll = value
      if (value) {
        this.localSelectedIdList = [...this.idList]
      } else {
        this.localSelectedIdList = []
      }
      this.$emit('update:selectedIdList', [...this.localSelectedIdList])
    },
    onSelectAllVisibleChange(value) {
      this.localSelectedAllVisible = value
      if (value) {
        this.localSelectedIdList = [...this.idList]
      } else {
        this.localSelectedIdList = []
      }
      this.$emit('update:selectedIdList', [...this.localSelectedIdList])
    }
  }
}
</script>

<style lang="scss" scoped>
.LLListSelector {
  @apply flex;
  &__checkbox {
    & + & {
      @apply ml-4;
    }
  }
}
</style>
