<template>
  <svg
    class="fill-current"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33333 0.980469H16.6667C18.5076 0.980469 20 2.47285 20 4.3138V13.8716L15.3201 9.10662C15.1697 8.95346 14.964 8.86719 14.7493 8.86719C14.5347 8.86719 14.329 8.95346 14.1786 9.10662L2.59693 20.8989C1.11031 20.5636 0 19.235 0 17.6471V4.3138C0 2.47285 1.49238 0.980469 3.33333 0.980469ZM4.7594 20.9805H16.6667C18.5076 20.9805 20 19.4881 20 17.6471V16.155L14.7493 10.8089L4.7594 20.9805ZM5.99934 5.77969C5.3366 5.77969 4.79934 6.31695 4.79934 6.97969C4.79934 7.64243 5.3366 8.17969 5.99934 8.17969C6.66208 8.17969 7.19934 7.64243 7.19934 6.97969C7.19934 6.31695 6.66208 5.77969 5.99934 5.77969ZM3.19934 6.97969C3.19934 5.43329 4.45294 4.17969 5.99934 4.17969C7.54574 4.17969 8.79934 5.43329 8.79934 6.97969C8.79934 8.52608 7.54574 9.77969 5.99934 9.77969C4.45294 9.77969 3.19934 8.52608 3.19934 6.97969Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhotoInvertedIcon'
}
</script>

<style scoped></style>
