<template>
  <span v-if="loading" class="LLUploadProgress flex text-sm" :class="{ 'w-full': compact, compact: compact }">
    <div>
      <UploadingIcon class="LLUploadProgress__icon text-warning-01"></UploadingIcon>
    </div>
    <div class="ml-4 text-primary-01-400 font-semibold flex" :class="{ flex: compact }">
      <span class="file-uploader__uploading-label">
        <template v-if="!decoding">
          <slot name="uploading-label">{{ $t('file_uploader_uploading') }}</slot>
        </template>
      </span>
      <span v-if="decoding">
        <span class="inline-block">{{ $t('file_uploader_decoding') }}</span>
        <span class="inline-block ml-1">{{ $t('file_uploader_please_wait') }}</span>
      </span>
      <template v-if="!decoding">{{ loadedPercentage }}%</template>
      <template v-if="uploadingFilesCount > 1">{{ uploadedFilesCount }}/{{ uploadingFilesCount }}</template>
    </div>
    <FileUploaderCancelIcon
      class="cursor-pointer text-neutral-01-200 ml-3"
      @click.native="cancel"
    ></FileUploaderCancelIcon>
  </span>
</template>

<script>
import UploadingIcon from '@/assets/icons/UploadingIcon'
import FileUploaderCancelIcon from '@/assets/icons/FileUploaderCancelIcon'
export default {
  name: 'LLUploadProgress',
  components: { FileUploaderCancelIcon, UploadingIcon },
  props: {
    uploadingFilesUuids: {
      type: Array,
      default() {
        return []
      }
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    decoding() {
      return this.isVideoUploading && this.allFilesSent
    },
    allFilesSent() {
      return !this.uploadingFiles.filter((f) => f.loadedSize < f.fileSize).length
    },
    isVideoUploading() {
      return !!this.uploadingFiles.find((f) => f.mime.substr(0, 4) === 'vide')
    },
    uploadingFiles() {
      return this.$store.getters['fileUpload/uploadingFilesByUUID'](this.uploadingFilesUuids)
    },
    loading() {
      return !!this.uploadingFiles.length
    },
    uploadingFilesCount() {
      return this.uploadingFiles.filter((f) => f.uploading).length
    },
    uploadedFilesCount() {
      return this.uploadingFiles.filter((f) => f.loadedSize >= f.fileSize).length
    },
    loadedPercentage() {
      return this.$store.getters['fileUpload/filesLoadedPercentage'](this.uploadingFilesUuids)
    }
  },
  watch: {
    uploadingFiles: {
      deep: true,
      handler() {
        const notUploadingUuids = this.uploadingFilesUuids.filter(
          (uuid) => !this.uploadingFiles.find((file) => file.uuid === uuid)
        )
        if (notUploadingUuids.length) {
          const newUploadingFilesUUids = this.uploadingFilesUuids.filter(
            (uuid) => !notUploadingUuids.includes(uuid)
          )
          this.$emit('update:uploadingFilesUuids', newUploadingFilesUUids)
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch('fileUpload/cancel', this.uploadingFilesUuids)
    }
  }
}
</script>

<style lang="scss" scoped>
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.LLUploadProgress {
  .LLUploadProgress__icon {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
}
.compact {
  .file-uploader__uploading-label {
    @apply block;
  }
}
</style>
