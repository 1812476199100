<template>
  <LLInputLocked dark-caption class="PollChoiceListEditor" data-e2e="modalQuestionMultiple">
    <template #caption>
      <slot name="caption"></slot>
    </template>
    <div class="PollChoiceListEditor__values">
      <draggable
        v-model="localChoiceList"
        v-bind="dragOptions"
        handle=".handler"
        @start="onDragStart"
        @end="onDragEnd"
      >
        <PollChoiceListEditorItem
          v-for="(value, $index) in localChoiceList"
          :key="value.id || value.tempId"
          :ref="`editor-item_${$index}`"
          :exclude-rule="excludeValues(value, $index)"
          :index="$index"
          :is-file-uploader-visible="isFileUploaderVisible"
          :is-able-to-remove="isAbleToRemove"
          :is-able-to-fix="isAbleToFix"
          :placeholder-fn="placeholderFn"
          :choice.sync="localChoiceList[$index]"
          class="PollChoiceListEditor__value"
          @keydown.enter.native="addValue($index)"
          @remove="remove($index)"
        ></PollChoiceListEditorItem>
      </draggable>
      <LLAddLabel
        v-if="isAbleToAddValue"
        class="PollChoiceListEditor__add"
        blue
        small
        @click.native="addValue(localChoiceList.length - 1)"
      >
        <slot name="add-label">
          {{ $t('button_add') }}
        </slot>
      </LLAddLabel>
    </div>
  </LLInputLocked>
</template>

<script>
import draggable from 'vuedraggable'
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLAddLabel from '@/components/atoms/LLAddLabel.vue'
import { PollChoiceModel } from '@/models/pollChoice'
import PollChoiceListEditorItem from '@/components/pages/forum/thread/poll/PollChoiceListEditorItem.vue'
import { PollModel } from '@/models/poll'

export default {
  name: 'PollChoiceListEditor',
  components: {
    PollChoiceListEditorItem,
    LLAddLabel,
    LLInputLocked,
    draggable
  },
  props: {
    poll: { type: PollModel, required: true },
    choiceList: { type: Array, required: true },
    caption: { type: String, default: null },
    placeholderFn: { type: Function, default: null },
    addButtonCaption: { type: String, default: 'Add' },
    minChoiceAmount: { type: Number, default: 0 },
    isFileUploaderVisible: { type: Boolean, default: true },
    isAbleToFix: { type: Boolean, default: false }
  },
  data() {
    return {
      localChoiceList: []
    }
  },
  computed: {
    isAbleToAddValue() {
      return !this.isPollCreated
    },
    isPollCreated() {
      return !!this.poll?.id
    },
    isAbleToRemove() {
      return this.localChoiceList.length > this.minChoiceAmount && !this.isPollCreated
    },
    dragOptions() {
      return {
        animation: 200,
        ghostClass: 'ghost'
      }
    },
    valuesTextList() {
      return this.localChoiceList?.map((v) => v?.text) || []
    }
  },
  watch: {
    choiceList: {
      deep: true,
      immediate: true,
      handler() {
        this.localChoiceList = this.choiceList.map((choice) => new PollChoiceModel(choice))
        if (this.localChoiceList.length < this.minChoiceAmount) {
          for (let i = 0; i <= this.minChoiceAmount - this.localChoiceList.length; i++) {
            this.localChoiceList.push(new PollChoiceModel())
          }
          this.$emit(
            'update:choiceList',
            this.localChoiceList.map((choice) => new PollChoiceModel(choice))
          )
        }
      }
    },
    localChoiceList: {
      deep: true,
      handler() {
        if (!this.$_.isEqual(this.localChoiceList, this.choiceList)) {
          this.$emit(
            'update:choiceList',
            this.localChoiceList.map((choice) => new PollChoiceModel(choice))
          )
        }
      }
    }
  },
  methods: {
    remove(index) {
      this.localChoiceList.splice(index, 1)
    },
    onDragStart() {
      this.drag = true
    },
    onDragEnd() {
      this.drag = false
    },
    excludeValues(value, index) {
      return this.valuesTextList.filter((v, i) => index !== i)
    },
    addValue(index) {
      if (!this.isAbleToAddValue) return
      const newPollChoice = new PollChoiceModel()
      this.localChoiceList.splice(index + 1, 0, newPollChoice)
      this.$nextTick(() => {
        const element = this.$refs[`editor-item_${index + 1}`][0]
        if (typeof element?.focus === 'function') element?.focus()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.PollChoiceListEditor {
  &__values {
    @apply mt-4;
  }
  &__value {
    @apply mt-4 flex flex-shrink-0 w-full;
    &:first-child {
      @apply mt-0;
    }
    max-width: 500px;
  }
  &__add {
    @apply mt-4;
  }
}
</style>
