<template>
  <div class="PollEditorMatrix">
    <PollChoiceListEditor
      class="PollEditorMatrix__choice-list"
      :min-choice-amount="1"
      :poll="localPoll"
      :choice-list.sync="localPoll.rows"
      :is-able-to-fix="localPoll.hasRandomRowsOrder"
      is-file-uploader-visible
      :placeholder-fn="rowPlaceholder"
    >
      <template #caption>{{ $tc('forum.poll.editor.matrix.rows_amount', rowsAmount) }}</template>
      <template #add-label>{{ $t('forum.poll.editor.matrix.add_row') }}</template>
    </PollChoiceListEditor>
    <PollChoiceListEditor
      class="PollEditorMatrix__choice-list"
      :min-choice-amount="2"
      :poll="localPoll"
      :is-file-uploader-visible="false"
      :is-able-to-fix="localPoll.hasRandomColumnsOrder"
      :choice-list.sync="localPoll.columns"
      :placeholder-fn="columnPlaceholder"
    >
      <template #caption>{{ $tc('forum.poll.editor.matrix.columns_amount', columnsAmount) }}</template>
      <template #add-label>{{ $t('forum.poll.editor.matrix.add_column') }}</template>
    </PollChoiceListEditor>
    <PollChoiceListExclusiveSelector
      class="PollEditorMatrix__exclusive-selector"
      :poll="localPoll"
      :choice-list.sync="localPoll.columns"
    />
    <PollChoiceListOptions class="PollEditorMatrix__options" :poll.sync="localPoll" />
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import PollChoiceListEditor from '@/components/pages/forum/thread/poll/PollChoiceListEditor.vue'
import PollChoiceListOptions from '@/components/pages/forum/thread/poll/PollChoiceListOptions.vue'
import PollChoiceListExclusiveSelector from '@/components/pages/forum/thread/poll/PollChoiceListExclusiveSelector.vue'

export default {
  name: 'PollEditorMatrix',
  components: { PollChoiceListExclusiveSelector, PollChoiceListOptions, PollChoiceListEditor },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      localPoll: new PollModel(this.poll)
    }
  },
  computed: {
    rowsAmount() {
      return this.localPoll.rows?.length || 0
    },
    columnsAmount() {
      return this.localPoll.columns?.length || 0
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    }
  },
  methods: {
    rowPlaceholder(index) {
      return this.$tc('forum.poll.editor.matrix.row', index + 1)
    },
    columnPlaceholder(index) {
      return this.$tc('forum.poll.editor.matrix.column', index + 1)
    }
  }
}
</script>

<style scoped lang="scss">
.PollEditorMatrix {
  &__options {
    @apply mt-8;
  }
  &__choice-list {
    @apply mt-8;
    &:first-child {
      @apply mt-0;
    }
  }
  &__exclusive-selector {
    @apply mt-8;
  }
}
</style>
