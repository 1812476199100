<template>
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44" cy="44" r="44" fill="#F0F3F5" />
    <path
      d="M62.0626 56.3954L57.3473 51.6812C58.3837 50.1562 58.8139 48.2994 58.554 46.474C58.2942 44.6485 57.3628 42.9856 55.9421 41.8103C54.5213 40.635 52.7133 40.0319 50.8715 40.1188C49.0297 40.2057 47.2865 40.9763 45.9827 42.2801C44.6789 43.5839 43.9082 45.3271 43.8213 47.1689C43.7345 49.0107 44.3376 50.8188 45.5129 52.2395C46.6882 53.6602 48.3511 54.5916 50.1765 54.8515C52.002 55.1114 53.8587 54.6811 55.3838 53.6448L60.0991 58.3589C60.2727 58.5324 60.5081 58.6299 60.7536 58.6299C60.9991 58.6299 61.2345 58.5324 61.4081 58.3589L62.0626 57.7038C62.236 57.5302 62.3333 57.2949 62.3333 57.0496C62.3333 56.8043 62.236 56.569 62.0626 56.3954ZM51.2223 52.1482C50.3067 52.1482 49.4116 51.8767 48.6502 51.368C47.8889 50.8593 47.2955 50.1362 46.9451 49.2903C46.5947 48.4443 46.503 47.5135 46.6816 46.6154C46.8603 45.7173 47.3012 44.8924 47.9487 44.2449C48.5961 43.5975 49.421 43.1565 50.3191 42.9779C51.2172 42.7993 52.148 42.891 52.994 43.2414C53.84 43.5918 54.563 44.1852 55.0717 44.9465C55.5804 45.7078 55.852 46.6029 55.852 47.5186C55.8506 48.746 55.3624 49.9228 54.4944 50.7907C53.6265 51.6587 52.4497 52.1469 51.2223 52.1482ZM31.7778 55.852V31.783H41.0371V37.8015C41.0366 37.9841 41.0721 38.1649 41.1417 38.3337C41.2113 38.5024 41.3136 38.6558 41.4427 38.7848C41.5718 38.9139 41.7251 39.0162 41.8938 39.0858C42.0626 39.1554 42.2434 39.191 42.426 39.1904H47.246C48.4549 38.6117 49.7923 38.261 51.2223 38.261V36.6389C51.2211 35.901 50.9277 35.1935 50.4063 34.6713L45.551 29.816C45.031 29.2947 44.3254 29.0013 43.5892 29H31.7778C31.0409 29.0024 30.335 29.2966 29.8145 29.8181C29.2939 30.3397 29.0011 31.0461 29 31.783V55.852C29 56.5887 29.2927 57.2952 29.8136 57.8162C30.3345 58.3371 31.0411 58.6298 31.7778 58.6298H48.4445C49.0202 58.6285 49.5812 58.4478 50.0495 58.1129C50.5178 57.778 50.8701 57.3055 51.0574 56.7611C49.739 56.735 48.4418 56.4249 47.2541 55.852H31.7778ZM43.8149 32.0093L48.2188 36.4121H43.8149V32.0093Z"
      fill="#7990A2"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoDataIcon'
}
</script>
