<template>
  <div class="LLConfirmModal">
    <div class="LLConfirmModal__background" @click="onBackplateClick"></div>
    <div class="LLConfirmModal__block" :style="confirmModalStyle">
      <h3 v-if="slotsVisibility.title" class="LLConfirmModal__title">
        <slot name="title"></slot>
      </h3>
      <div class="LLConfirmModal__content">
        <slot name="default"></slot>
      </div>
      <div v-if="slotsVisibility.actions" class="LLConfirmModal__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLConfirmModal',
  props: {
    opened: { type: Boolean, default: false },
    maxWidth: { type: Number, default: null },
    minWidth: { type: Number, default: null },
    closeOnBackdrop: { type: Boolean, default: false }
  },
  data() {
    return {
      animationOpened: false,
      stateOpened: false,
      closing: false,
      slotsVisibility: {
        title: false,
        actions: false
      }
    }
  },
  computed: {
    confirmModalStyle() {
      return {
        maxWidth: this.maxWidth ? `${this.maxWidth}px` : undefined,
        minWidth: this.minWidth ? `${this.minWidth}px` : undefined
      }
    }
  },
  watch: {
    opened: {
      immediate: true,
      handler(to) {
        if (to) {
          this.open()
        } else {
          this.close()
        }
      }
    },
    stateOpened: {
      handler(to) {
        if (!to) {
          this.$emit('update:opened', false)
        }
      }
    }
  },
  created() {
    this.checkSlotsVisibility()
  },
  beforeUpdate() {
    this.checkSlotsVisibility()
  },
  mounted() {
    document.body.classList.add('block-scroll')
  },
  destroyed() {
    document.body.classList.remove('block-scroll')
  },
  methods: {
    onBackplateClick() {
      if (this.closeOnBackdrop) {
        this.close()
      }
    },
    checkSlotsVisibility() {
      this.$nextTick(() => {
        this.slotsVisibility.title = !!this.$slots?.title?.[0]
        this.slotsVisibility.actions = !!this.$slots?.actions?.[0]
      })
    },
    open() {
      this.stateOpened = true
      this.$nextTick(() => {
        this.animationOpened = true
      })
    },
    close() {
      if (this.closing) {
        return
      }
      this.animationOpened = false
      setTimeout(() => {
        this.stateOpened = false
      }, 310)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLConfirmModal {
  z-index: 9999;
  @apply w-full h-full fixed left-0 top-0 p-8 flex items-center justify-center overflow-auto;
  &__container {
  }
  &__background {
    @apply w-full h-full fixed bg-neutral-01-800 opacity-20;
  }
  &__block {
    @apply p-6 bg-white shadow-dropdown m-auto z-50 relative;
    @apply w-full;
    @screen md {
      @apply w-auto;
    }
  }
  &__title {
    @apply mb-4 truncate;
  }
  &__actions {
    @apply mt-4 flex flex-wrap justify-end;
    ::v-deep > * {
      @apply ml-4;
      &:first-child {
        @apply ml-0;
      }
    }
  }
  &__content {
  }
  &__transition {
  }
}
</style>
