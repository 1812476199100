<template>
  <svg
    width="21"
    height="20"
    class="icon fill-current"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00002 1.7C8.34436 1.7 8.65007 1.92034 8.75896 2.24701L13 14.9702L14.7411 9.74701C14.85 9.42034 15.1557 9.2 15.5 9.2H18.8334C19.2752 9.2 19.6334 9.55817 19.6334 10C19.6334 10.4418 19.2752 10.8 18.8334 10.8H16.0766L13.759 17.753C13.6501 18.0797 13.3444 18.3 13 18.3C12.6557 18.3 12.35 18.0797 12.2411 17.753L8.00002 5.02982L6.25896 10.253C6.15007 10.5797 5.84436 10.8 5.50002 10.8H2.16668C1.72486 10.8 1.36668 10.4418 1.36668 10C1.36668 9.55817 1.72486 9.2 2.16668 9.2H4.92341L7.24107 2.24701C7.34996 1.92034 7.65567 1.7 8.00002 1.7Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnalyzeIcon'
}
</script>
