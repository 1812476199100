<template>
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="DropdownArrow fill-current"
    :class="{ DropdownArrow_opened: opened }"
  >
    <path
      d="M3.57513 6.82967L0.176195 3.53809C-0.0587313 3.31058 -0.0587313 2.9427 0.176195 2.71761L0.741019 2.17063C0.975946 1.94312 1.35583 1.94312 1.58825 2.17063L3.9975 4.50378L6.40675 2.17063C6.64167 1.94312 7.02156 1.94312 7.25398 2.17063L7.82381 2.71519C8.05873 2.9427 8.05873 3.31058 7.82381 3.53567L4.42487 6.82725C4.18994 7.05718 3.81006 7.05718 3.57513 6.82967V6.82967Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DropdownArrow',
  props: {
    opened: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.DropdownArrow {
  @apply transform transition duration-200;
  transition-property: transform;
  &_opened {
    @apply rotate-180;
  }
}
</style>
