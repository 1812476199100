import Vue from 'vue'

const app = new Vue()

export default {
  namespaced: true,
  state: {
    userError: '',
    userLoading: false,
    emailSent: false,
    emailSendingError: '',
    setNewPasswordLoading: false,
    passwordSent: false,
    setNewPasswordError: '',
    token: null
  },
  getters: {
    isAuthorised(state) {
      return !!state.token
    }
  },
  mutations: {
    USER_LOADING(state) {
      state.userError = ''
      state.userLoading = true
    },
    USER_LOADED(state) {
      state.userLoading = false
    },
    USER_ERROR(state, error) {
      state.userLoading = false
      state.userError = error
    },
    USER_CLEAR_ERROR(state) {
      state.userError = ''
    },
    SET_USER_TOKEN(state, token) {
      state.token = token
    }
  },
  actions: {
    async resetPassword({}, payload) {
      try {
        await app.$api.auth.resetPassword(payload)
      } catch (error) {
        throw error
        // commit('USER_ERROR', app.$getErrorMessage(error))
      }
    },
    async resetPasswordComplete({}, payload) {
      try {
        return await app.$api.auth.resetPasswordComplete(payload)
      } catch (error) {
        throw error
        // commit('USER_ERROR', app.$getErrorMessage(error))
      }
    },
    async signIn({ commit, dispatch }, payload) {
      commit('USER_LOADING')
      try {
        const response = await app.$api.auth.login(payload)
        const { token } = response
        commit('SET_USER_TOKEN', token)
        commit('USER_LOADED')
        await dispatch('getAppData', {}, { root: true })
        await dispatch('user/fetchUser', {}, { root: true })
      } catch (error) {
        throw error
        // commit('USER_ERROR', app.$getErrorMessage(error))
      }
    },
    async setToken({ commit, dispatch }, { token }) {
      commit('USER_LOADING')
      try {
        commit('SET_USER_TOKEN', token)
        commit('USER_LOADED')
        await dispatch('getAppData', {}, { root: true })
        await dispatch('user/fetchUser', {}, { root: true })
      } catch (error) {
        throw error
        // commit('USER_ERROR', app.$getErrorMessage(error))
      }
    },
    async invite({ commit, dispatch }, payload) {
      commit('USER_LOADING')
      try {
        const response = await app.$api.auth.invite(payload)
        const { token } = response
        commit('SET_USER_TOKEN', token)
        commit('USER_LOADED')
        await dispatch('getAppData', {}, { root: true })
        await dispatch('user/fetchUser', {}, { root: true })
      } catch (error) {
        // commit('USER_ERROR', app.$getErrorMessage(error))
        throw error
      }
    },
    logOut({ commit, dispatch }) {
      commit('SET_USER_TOKEN', null)
      dispatch('user/reset', {}, { root: true })
      dispatch('study/reset', {}, { root: true })
      dispatch('chats/reset', {}, { root: true })
      dispatch('studies/reset', {}, { root: true })
      dispatch('fileUpload/reset', {}, { root: true })
      dispatch('completeRegistration/reset', {}, { root: true })
    },
    async checkInvitationToken({}, { email, token }) {
      let response = await app.$api.auth.info({
        email,
        invitationLink: token
      })
      return {
        studyId: response.studyId,
        isPasswordSet: response.isPasswordSet
      }
    }
  }
}
