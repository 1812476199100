<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle r="22" transform="matrix(1.19249e-08 1 1 -1.19249e-08 22 22)" fill="#1F4E6E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0021 18.8211L14.1318 26.6914L11.5391 24.0987L22.0021 13.6357L32.4651 24.0987L29.8724 26.6914L22.0021 18.8211Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="44" height="44" fill="white" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ScrollToTopIcon'
}
</script>

<style lang="scss" scoped>
.ScrollToTopIcon {
}
</style>
