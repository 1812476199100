<template>
  <LLConfirmModalTransition>
    <LLConfirmModal v-if="localOpened" :max-width="600">
      <LLDialog class="EditPollResponseModal">
        <template #content>
          <h3 class="EditPollResponseModal__title">
            {{ confirmMessage }}
          </h3>
          <div class="EditPollResponseModal__content">
            <PollMultiple
              v-if="poll.type === PollTypeEnum.MULTIPLE_CHOICE"
              ref="module"
              :response.sync="localResponse"
              :poll="poll"
              edit-mode
              :is-valid.sync="isValid"
            ></PollMultiple>
            <PollMatrix
              v-else-if="poll.type === PollTypeEnum.MATRIX"
              ref="module"
              :poll="poll"
              :response.sync="localResponse"
              edit-mode
              :is-valid.sync="isValid"
              :is-first-time-fill="false"
            ></PollMatrix>
            <PollNumber
              v-else-if="poll.type === PollTypeEnum.NUMBER_ENTRY"
              ref="module"
              :poll="poll"
              :response.sync="localResponse"
              :is-first-time-fill="false"
              edit-mode
              :is-valid.sync="isValid"
            ></PollNumber>
            <PollRanked
              v-else-if="poll.type === PollTypeEnum.RANKED_LIST"
              ref="module"
              :poll="poll"
              :response.sync="localResponse"
              edit-mode
              :is-valid.sync="isValid"
            ></PollRanked>
          </div>
        </template>
        <template #actions>
          <LLButton :loading="pending" :disabled="!isValid" small @click="updatePollResponse">{{
            $t('button_update')
          }}</LLButton>
          <LLButton v-popper-close :disabled="pending" secondary small @click="closeModal">
            {{ $t('button_cancel') }}
          </LLButton>
        </template>
      </LLDialog>
    </LLConfirmModal>
  </LLConfirmModalTransition>
</template>

<script>
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import { ProspectModel } from '@/models/prospect'
import { PollModel } from '@/models/poll'
import { UserModel } from '@/models/user'
import { PollResponseModel } from '@/models/pollResponse'
import { PollType } from '@/common/enums'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import LLDialog from '@/components/common/LLDialog.vue'

export default {
  name: 'EditPollResponseModal',
  components: {
    LLDialog,
    LLConfirmModalTransition,
    LLButton,
    LLConfirmModal,
    PollMatrix: () => import('@/components/modules/forum-chat/poll/ForumPollMatrix.vue'),
    PollNumber: () => import('@/components/modules/forum-chat/poll/ForumPollNumber.vue'),
    PollRanked: () => import('@/components/modules/forum-chat/poll/ForumPollRanked.vue'),
    PollMultiple: () => import('@/components/modules/forum-chat/poll/ForumPollMultiple.vue')
  },
  props: {
    opened: { type: Boolean, default: false },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    prospect: { type: ProspectModel, default: null },
    user: { type: UserModel, default: null }
  },
  data() {
    return {
      localResponse: new PollResponseModel(),
      pending: false,
      isValid: false,
      localOpened: false
    }
  },
  computed: {
    confirmMessage() {
      return this.$t('forum.poll.results.table.edit_modal_title', {
        name: this.submitter?.fullName
      })
    },
    PollTypeEnum() {
      return PollType
    }
  },
  watch: {
    opened: {
      immediate: true,
      handler() {
        this.localOpened = this.opened
      }
    },
    localOpened() {
      this.$emit('update:opened', this.localOpened)
    }
  },
  mounted() {
    this.localResponse = new PollResponseModel(this.response)
  },
  methods: {
    closeModal() {
      this.localOpened = false
    },
    async updatePollResponse() {
      this.pending = true
      try {
        // await this.$axios.$delete(`/api/studies/${this.study.id}/${this.submitter.id}`)
        // await this.$axios.$delete(`/api/studies/${this.studyId}/participants`, {
        //   data: {
        //     submitterIdList: [this.submitter.id]
        //   }
        // })
        // this.$nuxt.$emit('user-recruiting-modify-study-list')
        // console.log('submitter type', this.submitter.constructor.name)
        // const submitterType = this.submitter.constructor.name
        const updateBody = { response: this.localResponse.convertToApi() }
        // if (submitterType === 'ProspectModel') updateBody.accountId = this.submitter.id
        // else updateBody.userId = this.submitter.id
        await this.$api.forum.poll.upateResult({
          chatId: this.poll.chatId,
          threadId: this.poll.threadId,
          prospectId: this.prospect?.id,
          userId: this.user?.id,
          updateBody
        })
        this.$emit('updatePollResponse')
        this.$notify({
          group: 'main',
          type: 'success',
          title: 'Response updated'
        })
        this.closeModal()
      } catch (e) {
        this.$toast.error(this.$getErrorMessage(e))
      } finally {
        this.pending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.EditPollResponseModal {
  @screen md {
    width: 480px;
  }
  max-width: 100vw;
  &__title {
    @apply break-words;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__content {
    @apply p-4;
    @screen lg {
      @apply p-6;
    }
  }
  &__collections-list {
    @apply border border-neutral-01-50 rounded p-4 mt-4 overflow-y-auto;
    max-height: 250px;
    &-wrapper {
      @apply flex flex-wrap items-start;
      @apply -mt-2;
    }
  }
  &__collections-tag-wrapper {
    max-width: 100%;
    @apply flex-shrink-0 mr-2 mt-2;
  }
  &__collections-tag {
  }
  &__warning {
    @apply mt-4 text-status-03-600 text-subheading-01;
  }
  &__total-prospects-amount {
    @apply text-caption-01 text-neutral-01-400;
  }
}
</style>
