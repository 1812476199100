<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6.29641L4.8413 9L10 3"
      stroke="#82B757"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'QuestionResolved'
}
</script>

<style lang="scss" scoped>
.QuestionResolved {
}
</style>
