import Network from '../network.js'

export default {
  get({ type, query, perPage, page, filters, sort }) {
    const chatId = filters?.chatId || null
    return Network.get(
      `/forum-chats/${chatId[0]}/search`,
      {
        type,
        search: query,
        perPage,
        page,
        sort,
        ...(filters || {})
      },
      true
    )
  },
  getResponseFilters({ chatId } = {}) {
    return Network.get(`/forum-chats/${chatId}/search/filters`, {}, true)
  },
  prepareExportResponses({ messageIds, responseIds, chatId } = {}) {
    return Network.post(
      `/forum-chats/${chatId}/search/export`,
      {
        messageIds,
        responseIds,
        chatId
      },
      true
    )
  },
  prepareExportAllResponses({ type, query, filters, sort, chatId } = {}) {
    return Network.postWithConfig(
      `/forum-chats/${chatId}/search/export/all`,
      {},
      {
        params: {
          type,
          search: query,
          sort,
          chatId,
          ...(filters || {})
        }
      },
      true
    )
  }
}
