import Chats from '../forum/chats'
import Threads from '../forum/threads'
import Responses from '../forum/responses'
import Poll from '../forum/poll'

export default () => ({
  chats: Chats(),
  threads: Threads(),
  responses: Responses(),
  poll: Poll()
})
