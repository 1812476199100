import { BaseModel } from '@/models/baseModel'
import { FileModel } from '@/models/file'

export class StudyModel extends BaseModel {
  constructor(userData = {}) {
    super()
    const {
      id,
      name,
      image,
      objective,
      screeningQuestionsInstructions,
      screeningQuestions,
      state,
      startDate,
      endDate,
      invitationState,
      approvalState,
      allResponsesSubmittedMessage,
      allowResponseEditing
    } = userData

    this.id = id || null
    this.name = name || ''
    this.image = image ? new FileModel(image) : null
    this.objective = objective || ''
    this.screeningQuestionsInstructions = screeningQuestionsInstructions || ''
    this.screeningQuestions = [...screeningQuestions] || []
    this.state = state || null
    this.startDate = startDate || null
    this.endDate = endDate || null
    this.invitationState = invitationState || null
    this.approvalState = approvalState || null
    this.allResponsesSubmittedMessage = allResponsesSubmittedMessage || null
    this.allowResponseEditing = allowResponseEditing ?? true
  }

  static parseFromApi({ study }) {
    return new StudyModel({
      id: study?.id,
      name: study?.name || null,
      image: study?.image ? FileModel.parseFromApi({ file: study?.image }) : null,
      objective: study?.studyInstructions,
      startDate: study?.startDate,
      endDate: study?.endDate,
      screeningQuestionsInstructions: study?.screeningQuestionsInstructions,
      screeningQuestions: study?.screeningQuestions ? [...study?.screeningQuestions] : [],
      state: study?.state,
      invitationState: study?.participantInvitationState,
      approvalState: study?.participantApprovalState,
      allResponsesSubmittedMessage: study?.allResponsesSubmittedMessage,
      allowResponseEditing: study?.allowResponseEditing
    })
  }

  get isLive() {
    return this.approvalState === 20 && this.state === 20
  }

  get isPendingApproval() {
    return this.approvalState === 10 && this.invitationState === 40
  }

  get isWaiting() {
    return this.approvalState === 20 && this.state === 10
  }

  get isInvited() {
    return (
      this.approvalState === 10 &&
      (this.invitationState === 10 ||
        this.invitationState === 20 ||
        this.invitationState === 30 ||
        this.invitationState === 60)
    )
  }
}
