<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.3125C6.64844 2.3125 2.3125 6.64844 2.3125 12C2.3125 17.3516 6.64844 21.6875 12 21.6875C17.3516 21.6875 21.6875 17.3516 21.6875 12C21.6875 6.64844 17.3516 2.3125 12 2.3125ZM12 19.8125C7.68359 19.8125 4.1875 16.3164 4.1875 12C4.1875 7.68359 7.68359 4.1875 12 4.1875C16.3164 4.1875 19.8125 7.68359 19.8125 12C19.8125 16.3164 16.3164 19.8125 12 19.8125ZM15.9766 9.57031L13.5469 12L15.9766 14.4297C16.1602 14.6133 16.1602 14.9102 15.9766 15.0938L15.0938 15.9766C14.9102 16.1602 14.6133 16.1602 14.4297 15.9766L12 13.5469L9.57031 15.9766C9.38672 16.1602 9.08984 16.1602 8.90625 15.9766L8.02344 15.0938C7.83984 14.9102 7.83984 14.6133 8.02344 14.4297L10.4531 12L8.02344 9.57031C7.83984 9.38672 7.83984 9.08984 8.02344 8.90625L8.90625 8.02344C9.08984 7.83984 9.38672 7.83984 9.57031 8.02344L12 10.4531L14.4297 8.02344C14.6133 7.83984 14.9102 7.83984 15.0938 8.02344L15.9766 8.90625C16.1602 9.08984 16.1602 9.38672 15.9766 9.57031V9.57031Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClearIcon'
}
</script>
