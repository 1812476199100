<template>
  <div class="MobileTopicInfo">
    <div class="text-xs text-neutral-01 uppercase font-bold leading-none" data-e2e="topicIndex">
      {{ $t('topic') }} {{ index }}
    </div>
    <div class="text-2xl text-primary-01 mt-2 font-semibold" data-e2e="topicName">
      {{ topic.name }}
    </div>
    <div class="font-semibold mt-4 text-sm text-primary-01 leading-none" data-e2e="topicQuestionNum">
      {{ questionsNum }} {{ $tc('question', questionsNum) }}
    </div>
    <div class="mt-4">
      <LLLineProgress class="cursor-pointer" :progress-list="lineTopicsProgress"></LLLineProgress>
    </div>
    <div class="flex items-center">
      <div class="flex items-center mt-3">
        <AnsweredIcon class="text-answered-01 w-5 h-5"></AnsweredIcon>
        <div class="font-semibold text-primary-01 ml-2" data-e2e="inProgressCount">{{ inProgressCount }}</div>
      </div>
      <div class="flex items-center mt-3 ml-10">
        <CompletedIcon class="text-success-01 w-5 h-5"></CompletedIcon>
        <div class="font-semibold text-primary-01 ml-2" data-e2e="completedCount">{{ completedCount }}</div>
      </div>
      <div class="flex items-center mt-3 ml-10">
        <NotStartedIcon class="text-answered-01 w-5 h-5"></NotStartedIcon>
        <div class="font-semibold text-primary-01 ml-2" data-e2e="notStartedCount">{{ notStartedCount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LLLineProgress from '@/components/common/ui-components/LLLineProgress'
import { TopicModel } from '@/models/topic'
import { StreamState } from '@/common/enums'

export default {
  name: 'MobileTopicInfo',
  components: { LLLineProgress },
  props: {
    topic: { type: TopicModel, required: true },
    index: { type: Number, default: null },
    conversationStreams: { type: Array, required: true, default: () => [] }
  },
  computed: {
    selectedTopicIndex() {
      return this.topics.indexOf(this.selectedTopic) + 1
    },
    questionsNum() {
      return this.topic.questionIdList?.length
    },
    isNewMessage() {
      return this.topic.questionIdList.find(
        (questionId) => this.conversationStreamsObject?.[questionId]?.hasNewMessage
      )
    },
    inactive() {
      return false
    },
    inProgressPercentage() {
      return Math.round(((this.inProgressCount + this.completedCount) / this.questionsNum) * 100)
    },
    notStartedCount() {
      return this.questionsNum - this.inProgressCount
    },
    inProgressCount() {
      return this.topic.questionIdList.filter((questionId) => {
        return this.conversationStreamsObject[questionId]?.state === StreamState.ANSWERED
      }).length
    },
    completedCount() {
      return this.topic.questionIdList.filter((questionId) => {
        return this.conversationStreamsObject[questionId]?.state === StreamState.RESOLVED
      }).length
    },
    conversationStreamsObject() {
      return this.conversationStreams?.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    completedPercentage() {
      return Math.ceil((this.completedCount / this.questionsNum) * 100)
    },
    isLocked() {
      return this.topic.isLocked
    },
    lineTopicsProgress() {
      return [
        {
          color: 'answered-01',
          progress: this.inProgressPercentage,
          name: 'inProgress'
        },
        {
          color: 'success-01',
          progress: this.completedPercentage,
          name: 'completed'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.MobileTopicInfo {
}
</style>
