<template>
  <div class="ForumChatThreadCardFooter" :class="classList">
    <div class="ForumChatThreadCardFooter__row">
      <div class="ForumChatThreadCardFooter__tags-container">
        <LLTags
          v-if="thread.themes.length"
          :with-remove="false"
          :with-input="false"
          :values="thread.themes"
          overflow-hidden
          :overflow-lines="compact ? 1 : 4"
        ></LLTags>
      </div>
      <div class="ForumChatThreadCardFooter__counters">
        <div class="ForumChatThreadCardFooter__counter">
          <LLMetaIconInfo
            :value="thread.meta.responseCount"
            :text="$tc('common_amount_no_digits_responses', thread.meta.responseCount)"
          >
            <TopicRedIcon></TopicRedIcon>
          </LLMetaIconInfo>
          <div v-if="thread.meta.newResponseCount" class="ForumChatThreadCardFooter__responses-new-counter">
            {{ $tc('forum.thread.new_responses_counter', thread.meta.newResponseCount) }}
          </div>
        </div>
        <LLMetaIconInfo
          v-if="thread.hasPoll"
          class="ForumChatThreadCardFooter__counter"
          :value="thread.meta.pollResponseCount"
          :text="$tc('common_amount_no_digits_poll-answers', thread.meta.pollResponseCount)"
        >
          <PollIcon></PollIcon>
        </LLMetaIconInfo>
      </div>
    </div>
    <div v-if="hasLink && hasLinkButton" class="ForumChatThreadCardFooter__bottom-link-wrapper">
      <router-link tag="a" :to="link" class="ForumChatThreadCardFooter__bottom-link">
        <LLButton small secondary>
          {{ $t('forum.thread.secondary_link') }}
          <template #icon-right>
            <ArrowMiniIcon class="ForumChatThreadCardFooter__bottom-link-arrow" />
          </template>
        </LLButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import PollIcon from '@/assets/icons/PollIcon.vue'
import TopicRedIcon from '@/assets/icons/TopicRedIcon.vue'
import LLTags from '@/components/common/ui-components/LLTags.vue'
import LLMetaIconInfo from '@/components/atoms/LLMetaIconInfo.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'

export default {
  name: 'ForumChatThreadCardFooter',
  components: { ArrowMiniIcon, LLButton, LLMetaIconInfo, LLTags, TopicRedIcon, PollIcon },
  props: {
    thread: { type: ForumThreadModel, required: true },
    hasLink: { type: Boolean, default: true },
    hasLinkButton: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false }
  },
  computed: {
    link() {
      return `/chats/${this.thread.chatId}/threads/${this.thread.id}`
    },
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardFooter {
  $root: &;
  @apply px-6 w-full;
  &_compact,
  &_mobile {
    @apply px-4;
  }
  &__row {
    @apply flex justify-between items-end;
    @at-root #{$root}_compact & {
      @apply mt-2;
    }
  }
  &__tags-container {
    @apply flex-1;
  }
  &__counters {
    @apply flex-shrink-0 flex flex-col;
  }
  &__counter {
    @apply mt-2 flex flex-nowrap items-center;
    &:first-child {
      @apply mt-0;
    }
  }
  &__responses-new-counter {
    @apply text-accent-01 text-body-02 ml-1;
  }
  &__bottom-link-wrapper {
    @apply flex justify-start mt-4;
  }
}
</style>
