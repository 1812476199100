<template>
  <LLPage class="ThreadPage">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #content>
      <ForumThreadPageContent
        v-if="chat && thread"
        :thread="thread"
        :chat="chat"
        @updateThread="onUpdateThread"
      />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'

import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import ForumThreadPageContent from '@/components/pages/forum/thread/ForumThreadPageContent'
import { ForumThreadModel } from '@/models/forum/forumThread'
import { ForumChatModel } from '@/models/forum/forumChat'
import chatEditor from '@/utils/modules/forum/chatEditor'
import threadEditor from '@/utils/modules/forum/threadEditor'
export default {
  name: 'ThreadPage',
  components: { LLPage, ForumThreadPageContent, LLBreadCrumbs },
  data() {
    return {
      serverChat: null,
      serverThread: null,
      isPageVisible: true
    }
  },
  computed: {
    chatsLink() {
      return `/chats/${this.chat?.id}`
    },
    chatId() {
      return this.chat?.id || this.$route.params.chatId
    },
    threadId() {
      return this.thread?.id || this.$route.params.threadId
    },
    chat() {
      return this.serverChat ? new ForumChatModel(this.serverChat) : null
    },
    thread() {
      return this.serverThread ? new ForumThreadModel(this.serverThread) : null
    },
    crumbs() {
      return [
        { name: this.$t('main_home_label'), path: '/' },
        {
          name: this.$t('forum.chat_list_page.title', { teamName: this.selectedTeam?.name }),
          path: '/chats'
        },
        {
          name: this.chat?.name || this.$t('forum.breadcrumbs.chat_placeholder'),
          path: `/chats/${this.chatId}`
        },
        {
          name: this.thread?.title || this.$t('forum.breadcrumbs.thread_placeholder')
        }
      ]
    }
  },
  watch: {
    isPageVisible(to) {
      if (to) {
        this.sendThreadViewState()
      }
    }
  },
  async mounted() {
    const { chat } = await this.fetchChat()
    const { thread } = await this.fetchThread()
    this.serverChat = { ...chat }
    this.serverThread = { ...thread }

    threadEditor.connect({
      token: this.$store.state.auth.token.replace('Bearer ', ''),
      threadId: this.threadId,
      chatId: this.chatId
    })
    chatEditor.connect({
      token: this.$store.state.auth.token.replace('Bearer ', ''),
      chatId: this.chatId
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_UPDATE, ({ chat }) => {
      this.serverChat = chat
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_META_UPDATE, ({ chatMeta }) => {
      this.serverChat = new ForumChatModel(this.serverChat)
      this.serverChat.setMetaFromApi({ meta: chatMeta })
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_META_UPDATE, ({ threadId, meta }) => {
      if (this.serverThread.id !== threadId) return
      this.serverThread = new ForumThreadModel(this.serverThread)
      this.serverThread.setMetaFromApi({ meta })
      this.sendThreadViewState()
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_MEMBERS_UPDATE, ({ memberCount } = {}) => {
      this.serverChat.meta.memberCount = memberCount ?? this.serverChat.meta.memberCount
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_META_MEMBERS_UPDATE, ({ memberCount } = {}) => {
      this.serverChat.meta.memberCount = memberCount ?? this.serverChat.meta.memberCount
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_UPDATE, ({ thread }) => {
      if (this.serverThread.id === thread.id) {
        this.serverThread = new ForumThreadModel(thread)
      }
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_THREAD_DELETE, ({ threadId }) => {
      if (this.serverThread.id === threadId) {
        this.$router.replace(this.chatsLink)
      }
    })
    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', this.checkTabActive)
    }
    this.sendThreadViewState()
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.checkTabActive)
    chatEditor.destroy()
    threadEditor.destroy()
  },
  methods: {
    checkTabActive() {
      this.isPageVisible = !document.hidden
    },
    onUpdateThread({ thread }) {
      this.serverThread = thread
    },
    async fetchChat() {
      try {
        const { chat: forumChat } = await this.$api.forum.chats.get({ chatId: this.chatId })
        const chat = ForumChatModel.parseFromApi({ chat: forumChat })
        return { chat }
      } catch (e) {
        console.log(e)
        if (e?.response?.status === 404) {
          this.$router.replace('/404')
        } else if (e?.response?.status) {
          throw error({ statusCode: e.response.status, message: 'Something went wrong' })
        } else {
          throw error({ statusCode: 500, message: 'Server error' })
        }
      }
    },

    async fetchThread() {
      try {
        const { thread: apiThread } = await this.$api.forum.threads.get({
          chatId: this.chatId,
          threadId: this.threadId
        })
        const thread = ForumThreadModel.parseFromApi({ thread: apiThread, chatId: this.chatId })
        return { thread }
      } catch (e) {
        console.log(e)
        if (e?.response?.status === 404) {
          this.$router.replace('/404')
        } else if (e?.response?.status) {
          throw error({ statusCode: e.response.status, message: 'Something went wrong' })
        } else {
          throw error({ statusCode: 500, message: 'Server error' })
        }
      }
    },
    async sendThreadViewState() {
      if (!this.isPageVisible) return
      try {
        this.serverThread.isNew = false
        this.serverThread.meta.newResponseCount = 0
        await this.$api.forum.threads.viewThread({ chatId: this.chatId, threadId: this.threadId })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ThreadPage {
  /*// Fix for main mayout
  margin-bottom: -40px;
  @screen lg {
    margin-bottom: -96px;
  }*/
}
</style>
