<template>
  <input
    v-if="mask"
    ref="input"
    v-model="filterValue"
    v-mask="mask"
    :pattern="pattern"
    :inputmode="inputmode"
    type="text"
    class="LLInputSelectFilteredTextInput"
    @focus="onFocus"
    @blur="onBlur"
  />
  <input
    v-else
    ref="input"
    v-model="filterValue"
    type="text"
    :pattern="pattern"
    :inputmode="inputmode"
    class="LLInputSelectFilteredTextInput"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
export default {
  name: 'LLInputSelectFilteredTextInput',
  props: {
    focused: { type: Boolean, default: false },
    value: { type: String, default: '' },
    mask: { type: Object, default: null },
    pattern: { type: String, default: null },
    inputmode: { type: String, default: null }
  },
  data() {
    return {
      localFocused: false,
      filterValue: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.filterValue = this.value
      },
      immediate: true
    },
    filterValue() {
      this.$emit('input', this.filterValue)
    },
    focused: {
      handler() {
        this.localFocused = this.focused
      },
      immediate: true
    },
    localFocused: {
      handler() {
        if (this.localFocused && this.$refs.input) {
          this.$refs.input.focus()
        }
        this.$emit('update:focused', this.localFocused)
      },
      immediate: true
    }
  },
  mounted() {
    if (this.localFocused && this.$refs.input) {
      this.$refs.input.focus()
    }
    this.$emit('update:focused', this.localFocused)
  },
  beforeDestroy() {
    this.$emit('input', '')
  },
  methods: {
    onFocus() {
      this.localFocused = true
      this.$emit('focus')
    },
    onBlur() {
      this.filterValue = ''
      this.localFocused = false
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSelectFilteredTextInput {
  @apply bg-transparent outline-none w-full h-full px-4;
  font-size: inherit;
  color: inherit;
}
</style>
