<template>
  <div class="PollMatrixEditorMobile">
    <LLSpoilerWrapper
      v-for="row in sortedRows"
      :key="row.id"
      :expanded.sync="rowsExpandStates[row.id]"
      class="PollMatrixEditorMobileRow"
    >
      <template #toggler="spoilerOptions">
        <button
          class="PollMatrixEditorMobileRow__head"
          :class="{ PollMatrixEditorMobileRow__head_success: isRowHeadSuccess(row.id) }"
        >
          <div class="PollMatrixEditorMobileRow__head-content">
            <Success02Icon v-if="isRowHeadSuccess(row.id)" class="PollMatrixEditorMobileRow__head-success" />
            <span v-if="isLabelVisible" class="PollMatrixEditorMobileRow__text">
              {{ row.text }}
            </span>
            <div v-if="row.image" class="PollMatrixEditorMobileRow__head-image-wrapper" @click.stop>
              <LLFileSlider
                :pre-large="showLargeThumbnail"
                :medium="showLargeThumbnail"
                :files="[row.image]"
                :small="!showLargeThumbnail"
              ></LLFileSlider>
            </div>
          </div>
          <ChevronDownIcon
            class="PollMatrixEditorMobileRow__arrow"
            :class="{ PollMatrixEditorMobileRow__arrow_expanded: spoilerOptions.expanded }"
          />
        </button>
      </template>
      <template #content>
        <div class="PollMatrixEditorMobileRow__columns">
          <div v-for="column in sortedColumns" :key="column.id" class="PollMatrixEditorMobileColumn">
            <PollMatrixEditorItem
              v-model="selectedValues"
              :poll="poll"
              :row-id="row.id"
              :locked="locked"
              :column-id="column.id"
              show-text
              @change="onColumnClick(column.id, row.id)"
            />
          </div>
        </div>
      </template>
    </LLSpoilerWrapper>
  </div>
</template>

<script>
import LLSpoilerWrapper from '@/components/common/ui-components/LLSpoilerWrapper.vue'
import ChevronDownIcon from '@/assets/icons/ChevronDownIcon.vue'
import Success02Icon from '@/assets/icons/Success02Icon.vue'
import { PollModel } from '@/models/poll'
import PollMatrixEditorItem from '@/components/modules/poll/matrix/editor/PollMatrixEditorItem.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
export default {
  name: 'PollMatrixEditorMobile',
  components: { LLFileSlider, PollMatrixEditorItem, Success02Icon, ChevronDownIcon, LLSpoilerWrapper },
  props: {
    value: { type: Array, default: () => [] },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: false },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      selectedValues: [],
      rowsExpandStates: {}
    }
  },
  computed: {
    isFromStudy() {
      return !!this.poll?.studyId
    },
    isLabelVisible() {
      return !this.poll.isLabelsHidden
    },
    showLargeThumbnail() {
      return this.poll.isThumbnailsLarge
    },
    rows() {
      return this.poll.rows
    },
    columns() {
      return this.poll.columns
    },
    isRadio() {
      return this.poll.isAnswerLimitEnabled && this.poll.answerLimitAmount === 1
    },
    sortedRows() {
      return [...this.rows].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    sortedColumns() {
      return [...this.columns].sort((a, b) => a.randomOrder - b.randomOrder)
    }
  },
  watch: {
    value: {
      handler() {
        if (!this.$_.isEqual(this.value, this.selectedValues)) {
          this.selectedValues = this.value.map((row) => {
            return {
              id: row.id,
              columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    selectedValues: {
      handler() {
        this.$emit(
          'input',
          this.selectedValues.map((row) => {
            return {
              id: row.id,
              columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
            }
          })
        )
      },
      deep: true
    },
    rows() {
      this.fillRowsExpandStates()
    }
  },
  created() {
    this.fillRowsExpandStates({ openFirst: this.isFirstTimeFill })
  },
  methods: {
    isRowHeadSuccess(rowId) {
      return (
        !!this.selectedValues.find((row) => row.id === rowId)?.columnIdList?.length && this.isFirstTimeFill
      )
    },
    fillRowsExpandStates({ openFirst = false } = {}) {
      const newStates = {}
      this.sortedRows.forEach((row) => {
        if (this.rowsExpandStates[row.id]) {
          newStates[row.id] = this.rowsExpandStates[row.id]
        } else {
          newStates[row.id] = false
        }
      })
      if (openFirst && this.sortedRows?.length) {
        newStates[this.sortedRows[0]?.id] = true
      }
      this.rowsExpandStates = newStates
    },
    onColumnClick(columnId, rowId) {
      const row = this.selectedValues.find((row) => row.id === rowId)
      if (
        this.isFirstTimeFill &&
        !this.locked &&
        (this.isRadio ||
          (this.poll.isAnswerLimitEnabled && row?.columnIdList?.length >= this.poll.answerLimitAmount))
      ) {
        this.$nextTick(() => {
          this.closeRowAndOpenNextRow(rowId)
        })
      }
    },
    closeRowAndOpenNextRow(currentRowId) {
      let rowIndex = this.sortedRows.findIndex((row) => row.id === currentRowId)
      this.rowsExpandStates[this.sortedRows[rowIndex]?.id] = false

      while (this.sortedRows[rowIndex + 1]) {
        if (!this.selectedValues.find((row) => row.id === this.sortedRows[rowIndex + 1]?.id)?.columnId) {
          this.rowsExpandStates[this.sortedRows[rowIndex + 1]?.id] = true
          break
        }
        rowIndex++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.PollMatrixEditorMobile {
  &Row {
    @apply border-b border-neutral-01-100;
    &:first-child {
      @apply mt-0;
    }
    &:last-child {
      @apply border-b-0;
    }
    &__head {
      @apply flex justify-between min-w-0 items-start text-button-01 w-full py-4;
      &:focus {
        @apply outline-none;
      }
      &_success {
        .PollMatrixEditorMobileRow__text {
          @apply text-success-01;
          text-indent: 24px;
        }

        .PollMatrixEditorMobileRow__head-image-wrapper:nth-child(2) {
          @apply mt-0 ml-6;
        }
      }
    }
    &__head-content {
      @apply text-left break-words min-w-0;
    }
    &__head-success {
      @apply w-5 h-5 text-success-01 absolute mt-0.5;
    }
    &__text {
      @apply text-left break-words block;
    }
    &__head-image-wrapper {
      @apply mt-2;
      &:nth-child(1) {
        @apply mt-0;
      }
    }
    &__columns {
      @apply pb-6 pl-2;
    }
    &__arrow {
      @apply transition duration-200 ml-4 mt-2 text-neutral-01-400 flex-shrink-0;
      &_expanded {
        @apply transform rotate-180;
      }
    }
  }
  &Column {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
    &__text {
      @apply break-words;
    }
  }
}
</style>
