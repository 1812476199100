<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_966_10)">
      <circle cx="15" cy="15" r="14.625" fill="#191A2E" stroke="white" stroke-width="0.75" />
      <path
        d="M7.5 22.8125H22.274L22.5 19.0026H21.9068L21.7655 19.5755C21.3983 21.0937 21.0876 21.7526 18.517 21.7526H15.1271C12.7825 21.7526 12.4435 21.2942 12.4435 18.4297V9.54947C12.4435 6.68489 12.7825 6.22656 15.1271 6.22656V5.625H7.5V6.22656C9.84463 6.22656 10.1836 6.68489 10.1836 9.54947V18.8594C10.1836 21.7239 9.84463 22.2109 7.5 22.2109V22.8125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_966_10">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoRound'
}
</script>
