<template>
  <div class="LLPopper">
    <div
      ref="trigger"
      class="LLPopper__trigger"
      :class="triggerClass"
      @mouseenter="onTriggerMouseOver"
      @mouseleave="onTriggerMouseLeave"
      @click="onTriggerClick"
    >
      <slot name="trigger" :opened="visible"></slot>
    </div>
    <transition name="popper-fade">
      <div
        v-if="visible"
        ref="tooltip"
        :class="tooltipClassList"
        class="LLPopperTooltip"
        :style="tooltipStyle"
      >
        <div class="LLPopperTooltip__inner">
          <slot name="tooltip"></slot>
        </div>
        <div v-if="tooltipWithArrow" class="LLPopperTooltip__arrow"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
export default {
  name: 'LLPopper',
  props: {
    trigger: { type: String, default: 'click' },
    triggerClass: { type: [Array, String], default: '' },
    show: { type: Boolean, default: false },
    placement: { type: String, default: 'bottom' },
    offset: { type: Array, default: () => [5, 5] },
    disabled: { type: Boolean, default: false },
    tooltipWhite: { type: Boolean, default: false },
    tooltipDark: { type: Boolean, default: false },
    tooltipYellow: { type: Boolean, default: false },
    tooltipGreen: { type: Boolean, default: false },
    tooltipRed: { type: Boolean, default: false },
    tooltipWithArrow: { type: Boolean, default: false },
    strategy: { type: String, default: 'fixed' },
    hoverDelay: { type: Number, default: null },
    zIndex: { type: Number, default: null }
  },
  data() {
    return {
      visible: false,
      popperInstance: null,
      resizeObserver: null,
      isLLPopper: true,
      mouseOver: false
    }
  },
  computed: {
    isClickTrigger() {
      return this.trigger === 'click'
    },
    isHoverTrigger() {
      return this.trigger === 'hover'
    },
    tooltipClassList() {
      const classes = []
      const rootClass = 'LLPopperTooltip'
      if (this.tooltipWhite) {
        classes.push(`${rootClass}_white`)
      }
      if (this.tooltipDark) {
        classes.push(`${rootClass}_dark`)
      }
      if (this.tooltipYellow) {
        classes.push(`${rootClass}_yellow`)
      }
      if (this.tooltipGreen) {
        classes.push(`${rootClass}_green`)
      }
      if (this.tooltipRed) {
        classes.push(`${rootClass}_red`)
      }
      if (this.tooltipWithArrow) {
        classes.push(`${rootClass}_with-arrow`)
      }
      return classes
    },
    tooltipStyle() {
      return {
        'z-index': this.zIndex || undefined
      }
    }
  },
  watch: {
    disabled: {
      handler() {
        if (this.disabled && this.visible) {
          this.hideAction()
        }
      }
    },
    show: {
      handler(to) {
        to ? this.showAction() : this.hideAction()
      }
    },
    visible: {
      handler() {
        this.$emit('update:show', this.visible)
      }
    }
  },
  mounted() {
    if (this.show) {
      this.showAction()
    }
  },
  beforeDestroy() {
    if (this.visible) {
      this.hideAction()
    }
  },
  methods: {
    onTriggerMouseOver() {
      this.mouseOver = true
      if (this.isHoverTrigger) {
        if (!this.hoverDelay) {
          this.showAction()
        } else {
          setTimeout(() => {
            if (this.mouseOver) {
              this.showAction()
            }
          }, this.hoverDelay)
        }
      }
    },
    onTriggerMouseLeave() {
      this.mouseOver = false
      if (this.isHoverTrigger) {
        this.hideAction()
      }
    },
    onTriggerClick() {
      if (this.isClickTrigger) {
        this.toggleVisible()
      }
    },
    toggleVisible() {
      !this.visible ? this.showAction() : this.hideAction()
    },
    initPopper() {
      const trigger = this.$refs.trigger
      const tooltip = this.$refs.tooltip

      this.popperInstance = createPopper(trigger, tooltip, {
        placement: this.placement,
        strategy: this.strategy,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: this.offset
            }
          },
          {
            name: 'computeStyles',
            options: {
              roundOffsets: true,
              adaptive: false,
              gpuAcceleration: true // true by default
            }
          },
          {
            name: 'flip',
            enabled: true
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: window.document,
              padding: {
                top: 68
              },
              altBoundary: true,
              altAxis: true // false by default
            }
          }
        ]
      })
    },
    initObserver() {
      const trigger = this.$refs.trigger
      const tooltip = this.$refs.tooltip
      const resizeObserver = new ResizeObserver(() => {
        this.update()
      })

      if (!trigger || !tooltip) {
        return
      }
      resizeObserver.observe(trigger)
      resizeObserver.observe(tooltip)
      this.resizeObserver = resizeObserver
    },
    clickEventListener(e) {
      if (!e.composedPath().includes(this.$refs.trigger) && !e.composedPath().includes(this.$refs.tooltip)) {
        this.hideAction()
      }
    },
    initEventListener() {
      document.addEventListener('click', this.clickEventListener)
    },
    showAction() {
      if (this.visible || this.disabled) {
        return
      }
      this.visible = true
      this.$nextTick(() => {
        if (this.isClickTrigger) {
          this.initEventListener()
        }
        this.initPopper()
        this.initObserver()
        this.$emit('show')
      })
    },
    hideAction() {
      if (this.isClickTrigger) {
        document.removeEventListener('click', this.clickEventListener)
      }
      if (!this.visible) {
        return
      }
      this.visible = false
      const popperInstance = this.popperInstance
      this.$nextTick(() => {
        this.resizeObserver.disconnect()
        setTimeout(() => {
          popperInstance.destroy()
        }, 1000)
      })
      this.$emit('hide')
    },
    update() {
      this.popperInstance.update()
    }
  }
}
</script>

<style lang="scss" scoped>
.LLPopper {
  &__trigger {
    @apply flex;
  }
}

.LLPopperTooltip {
  $root: &;
  $inner: #{$root}__inner;
  $arrow: #{$root}__arrow;
  position: absolute;
  z-index: 40;
  max-width: 100vw;
  &.popper-fade-enter-active {
    transition-property: opacity;
    transition-duration: 0.1s;
  }
  &.popper-fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.1s;
  }
  &.popper-fade-enter,
  &.popper-fade-leave-to {
    opacity: 0;
  }

  &[data-popper-placement^='top'],
  &[data-popper-placement^='bottom'] {
    &[data-popper-placement$='start'] {
      #{$arrow} {
        left: 0;
        rigth: initial;
      }
    }
    &[data-popper-placement$='end'] {
      #{$arrow} {
        right: 0;
        left: initial;
      }
    }
  }

  &_white {
    #{$inner} {
      @apply bg-white text-primary-01 p-0 rounded border-neutral-01-50 border;
      font-size: 12px;
      box-shadow: 0 4px 14px rgba(37, 44, 49, 0.16);
    }
    &[data-popper-placement^='top'] {
      #{$arrow} {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        margin-top: 0;
        margin-bottom: 0;
        left: calc(50% - 5px);

        &:after {
          left: -5px;
          bottom: 2px;
          border-width: 5px 5px 0 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
        }
      }
    }

    &[data-popper-placement^='bottom'] {
      #{$arrow} {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;

        &:after {
          left: -5px;
          top: 2px;
          border-width: 0 5px 5px 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
        }
      }
    }

    &[data-popper-placement^='right'] {
      #{$arrow} {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;

        &:after {
          top: -5px;
          left: 2px;
          border-width: 5px 5px 5px 0;
          border-bottom-color: transparent !important;
          border-left-color: transparent !important;
          border-top-color: transparent !important;
        }
      }
    }

    &[data-popper-placement^='left'] {
      #{$arrow} {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;

        &:after {
          top: -5px;
          right: 2px;
          border-width: 5px 0 5px 5px;
          border-bottom-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
        }
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition:
        opacity 0.15s,
        visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
    #{$arrow} {
      @apply border-black;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
      @apply border-neutral-01-50;

      #{$arrow} {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        @apply border-white;
        content: '';
      }
    }
  }

  &_green {
    @apply bg-success-01 text-white rounded px-3 py-2;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(37, 44, 49, 0.16);

    #{$arrow} {
      @apply text-success-01;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
      content: '';
    }

    &[data-popper-placement^='top'] {
      &#{$root}_with-arrow {
        margin-top: -5px !important;
      }
      #{$arrow} {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='bottom'] {
      #{$arrow} {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='right'] {
      #{$arrow} {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[data-popper-placement^='left'] {
      #{$arrow} {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      .popper_dark {
        visibility: hidden;
        opacity: 0;
        transition:
          opacity 0.15s,
          visibility 0.15s;
      }
    }

    &[aria-hidden='false'] {
      .popper_dark {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
      }
    }
  }
  &_yellow {
    @apply bg-answered-01 text-white rounded px-3 py-2;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(37, 44, 49, 0.16);

    #{$arrow} {
      @apply text-answered-01;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
      content: '';
    }

    &[data-popper-placement^='top'] {
      &#{$root}_with-arrow {
        margin-top: -5px !important;
      }
      #{$arrow} {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='bottom'] {
      #{$arrow} {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='right'] {
      #{$arrow} {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[data-popper-placement^='left'] {
      #{$arrow} {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      .popper_dark {
        visibility: hidden;
        opacity: 0;
        transition:
          opacity 0.15s,
          visibility 0.15s;
      }
    }

    &[aria-hidden='false'] {
      .popper_dark {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
      }
    }
  }
  &_dark {
    @apply bg-neutral-01 text-white rounded px-3 py-2;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(37, 44, 49, 0.16);

    #{$arrow} {
      @apply border-neutral-01;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
      content: '';
    }

    &[data-popper-placement^='top'] {
      &#{$root}_with-arrow {
        margin-top: -5px !important;
      }
      #{$arrow} {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='bottom'] {
      #{$arrow} {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='right'] {
      #{$arrow} {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[data-popper-placement^='left'] {
      #{$arrow} {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      .popper_dark {
        visibility: hidden;
        opacity: 0;
        transition:
          opacity 0.15s,
          visibility 0.15s;
      }
    }

    &[aria-hidden='false'] {
      .popper_dark {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
      }
    }
  }
  &_red {
    @apply bg-status-03-400 text-white rounded px-3 py-2;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(37, 44, 49, 0.16);

    #{$arrow} {
      @apply text-status-03-400;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
      content: '';
    }

    &[data-popper-placement^='top'] {
      &#{$root}_with-arrow {
        margin-top: -5px !important;
      }
      #{$arrow} {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='bottom'] {
      #{$arrow} {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-popper-placement^='right'] {
      #{$arrow} {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[data-popper-placement^='left'] {
      #{$arrow} {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      .popper_dark {
        visibility: hidden;
        opacity: 0;
        transition:
          opacity 0.15s,
          visibility 0.15s;
      }
    }

    &[aria-hidden='false'] {
      .popper_dark {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
      }
    }
  }
}
</style>
