<template>
  <div class="ForumResponseAvatar">
    <div v-if="isOwnerVisible" class="ForumResponseAvatar__user">
      <ProspectAvatar v-if="prospect" disable-tooltip small :prospect="prospect"></ProspectAvatar>
      <UserAvatar v-else-if="user" small :user="user"></UserAvatar>
    </div>
    <div v-else-if="isOwnerHidden" class="ForumResponseAvatar__anonymous">
      {{ anonymousReplyLetter }}
    </div>
    <LogoRound v-else-if="isOwnerModerator" class="ForumResponseAvatar__moderator" />
  </div>
</template>

<script>
import ProspectAvatar from '@/components/modules/prospects/ProspectAvatar.vue'
import UserAvatar from '@/components/modules/users/UserAvatar.vue'
import LogoRound from '@/assets/icons/LogoRound.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseAvatar',
  components: { LogoRound, UserAvatar, ProspectAvatar },
  props: {
    response: { type: ForumResponseModel, required: true }
  },
  computed: {
    isOwnerVisible() {
      return !this.isOwnerHidden && !this.isOwnerModerator
    },
    isOwnerHidden() {
      return (
        this.response.anonymousState &&
        this.response.prospect?.id !== this.$store.getters['user/user']?.id &&
        !this.response.isOwnerModerator
      )
    },
    isOwnerModerator() {
      return (
        this.response.anonymousState &&
        this.response.prospect?.id !== this.$store.getters['user/user']?.id &&
        this.response.isOwnerModerator
      )
    },
    anonymousReplyLetter() {
      return this.$t('forum.response.anonymous_reply_user_name').slice(0, 1)
    },
    prospect() {
      return this.response?.prospect
    },
    user() {
      return this.response?.user
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseAvatar {
  &__user {
    @apply w-6 h-6 flex-shrink-0;
    @screen md {
      @apply w-8 h-8;
    }
  }
  &__anonymous {
    @apply w-6 h-6 rounded-full object-cover bg-neutral-01-400 text-body-02 text-white flex items-center justify-center;
    @screen md {
      @apply w-8 h-8;
    }
  }
  &__moderator {
    @apply w-6 h-6 rounded-full text-body-02 text-white flex items-center justify-center;

    @screen md {
      @apply w-8 h-8;
    }
  }
}
</style>
