<template>
  <div class="LLInputSelect">
    <LLInputCaption v-if="showSlots.caption"><slot name="caption"></slot></LLInputCaption>
    <LLInputSelectValues
      ref="input-select"
      :value="selectedValue"
      :values="filteredValues"
      :max-height="maxHeight"
      :reduce="reduce"
      :key-fn="keyFn"
      :loading="loading"
      :enable-pagination="enablePagination"
      :pagination-loading="paginationLoading"
      :show-popper="showPopper"
      :caption-fn="captionFn"
      :multiple="multiple"
      :prevent-selection="preventSelection"
      @mousedown.native="onInputClick"
      @closePopper="onClosePopper"
      @openPopper="onOpenPopper"
      @input="onInput"
      @infiniteScrollObserverIntersect="onInfiniteScrollObserverIntersect"
    >
      <LLInputContainer
        ref="input-container"
        :with-cleaner="showCleaner"
        :focused="focused"
        :disabled="disabled"
        :error="hasError"
        :small="small"
        :tiny="tiny"
        @click.native="onClick"
        @clear="cleanerFunction"
      >
        <template v-if="showPlaceholder" #placeholder>
          <div class="LLInputSelect__placeholder">
            {{ placeholder }}
          </div>
        </template>
        <template #text-input>
          <div class="LLInputSelect__input">
            <LLInputSelectFilteredTextInput
              v-if="filterable && showPopper"
              v-model="filterText"
              :focused.sync="focused"
              :pattern="pattern"
              :inputmode="inputmode"
              :mask="filterMask"
              @focus="showPopper = true"
              @blur="showPopper = false"
            />
            <LLInputSelectHiddenInput
              v-if="false && (!filterable || (filterable && !showPopper && !disabled))"
              :values="values"
              :reduce="reduce"
              :filter="hiddenFilterFn"
              :caption-fn="captionFn"
              :focused.sync="focused"
              @blur="showPopper = false"
              @updateSelectedValue="onUpdateSelectedValue"
            />
          </div>
          <div v-if="showCaption" class="LLInputSelect__caption">
            <div class="LLInputSelect__caption-inner">
              <slot name="selected-caption" :value="selectedValueObject"> {{ selectedValueCaption }}</slot>
            </div>
          </div>
        </template>
        <template #right-icon>
          <ArrowMiniIcon :class="arrowClasses" class="LLInputSelect__arrow" />
        </template>
      </LLInputContainer>
      <template #value-caption="{ value: valueToPass }">
        <slot name="value-caption" :value="valueToPass"></slot>
      </template>
    </LLInputSelectValues>
    <LLPopper
      v-if="(hasError || showSlots.error) && showErrorInTooltip"
      ref="input-popper"
      :show="focused"
      tooltip-red
      tooltip-with-arrow
      placement="right-end"
      :offset="[0, 5]"
      trigger="none"
    >
      <template #trigger></template>
      <template #tooltip>
        <slot name="error"></slot>
        <template v-if="hasError">{{ errorsString }}</template>
      </template>
    </LLPopper>
    <LLInputError v-if="(hasError || showSlots.error) && !showErrorInTooltip">
      <div v-if="hasError" class="LLTextInputNew__error LLTextInputNew__styling-error-color">
        <slot name="error"></slot>
        <template v-if="hasError">{{ errorsString }}</template>
      </div>
    </LLInputError>
  </div>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'
import LLInputContainer from './LLInputContainer'
import LLInputCaption from './LLInputCaption'
import LLInputError from './LLInputError'
import LLInputSelectFilteredTextInput from './LLInputSelect/LLInputSelectFilteredTextInput'
import LLInputSelectHiddenInput from './LLInputSelect/LLInputSelectHiddenInput'
import LLInputSelectValues from './LLInputSelect/LLInputSelectValues'
import LLPopper from '@/components/utils/LLPopper.vue'
export default {
  name: 'LLInputSelect',
  components: {
    LLPopper,
    LLInputSelectValues,
    ArrowMiniIcon,
    LLInputSelectHiddenInput,
    LLInputSelectFilteredTextInput,
    LLInputError,
    LLInputCaption,
    LLInputContainer
  },
  props: {
    filterable: { type: Boolean, default: false },
    keyFn: { type: Function, default: (value) => value.key },
    reduce: { type: Function, default: (value) => value },
    values: { type: Array, default: () => [] },
    hiddenFilterFn: { type: Function, default: null },
    filterFn: { type: Function, default: null },
    captionFn: { type: Function, default: (value) => String(value) },
    withFilter: { type: Boolean, default: false },
    filterMask: { type: [String, Object], default: null },
    loading: { type: Boolean, default: false },
    paginationLoading: { type: Boolean, default: false },
    enablePagination: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    value: { type: [String, Number, Array], default: null },
    mask: { type: [String, Object], default: null },
    withCleaner: { type: Boolean, default: false },
    withCleanerWithValue: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    maxHeight: { type: Number, default: 150 },
    preventSelection: { type: Boolean, default: false },
    showErrorInTooltip: { type: Boolean, default: false },
    pattern: { type: String, default: null },
    inputmode: { type: String, default: null }
  },
  data() {
    return {
      focused: false,
      showSlots: {
        caption: false
      },
      selectedValue: null,
      showPopper: false,
      filterText: ''
    }
  },
  computed: {
    errorsString() {
      return this.hasError ? this.errors.join(', ') : null
    },
    defaultFilterFn() {
      if (!this.filterFn) {
        return (value, text) => this.captionFn(value).toUpperCase().includes(text.toUpperCase())
      } else {
        return this.filterFn
      }
    },
    filteredValues() {
      return this.values.filter((v) => this.defaultFilterFn(v, this.filterText))
    },
    arrowClasses() {
      const classes = []
      const rootClass = 'LLInputSelect__arrow'
      if (this.showPopper) {
        classes.push(`${rootClass}_opened`)
      }
      return classes
    },
    selectedValueObject() {
      return this.values.find((v) => this.selectedValue === this.reduce(v))
    },
    selectedValueCaption() {
      if (!Array.isArray(this.selectedValue)) {
        return this.selectedValue !== undefined && this.selectedValue !== null
          ? this.captionFn(this.selectedValueObject)
          : null
      } else {
        return JSON.stringify(this.selectedValue)
      }
    },
    hasError() {
      return !!this.errors?.length
    },
    showCleaner() {
      return !!(
        this.withCleaner ||
        (this.withCleanerWithValue && this.selectedValue !== undefined && this.selectedValue !== null)
      )
    },
    showCaption() {
      return (
        (!this.filterable && this.selectedValue !== undefined && this.selectedValue !== null) ||
        (this.filterable && !this.showPopper)
      )
    },
    showPlaceholder() {
      return (
        !(this.selectedValue !== undefined && this.selectedValue !== null) &&
        ((this.filterable && !this.showPopper) || !this.filterable)
      )
    }
  },
  watch: {
    focused(to) {
      if (to) {
        this.$emit('focus')
      } else {
        this.$emit('blur')
      }
    },
    filterText() {
      this.$emit('updateFilterText', this.filterText)
    },
    selectedValue: {
      handler() {
        if (Array.isArray(this.selectedValue)) {
          this.$emit('input', [...this.selectedValue])
        } else {
          this.$emit('input', this.selectedValue)
        }
      }
    },
    value: {
      handler() {
        if (Array.isArray(this.value)) {
          if (!this.$_.isEqual(this.selectedValue, this.value)) {
            this.selectedValue = [...this.value]
          }
        } else if (this.multiple) {
          this.selectedValue = []
        } else {
          this.selectedValue = this.value
        }
      },
      immediate: true
    },
    showPopper() {
      if (this.showPopper) {
        this.$emit('listOpen')
      } else {
        this.focused = false
        this.$emit('listClose')
      }
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  beforeDestroy() {
    this.removeEmentListeners()
  },
  mounted() {
    this.addEventListeners()
  },
  methods: {
    addEventListeners() {
      window.addEventListener('click', this.onWindowClickListener)
    },
    removeEmentListeners() {
      window.removeEventListener('click', this.onWindowClickListener)
    },
    onWindowClickListener(e) {
      if (
        !e.composedPath().includes(this.$refs['input-select']?.$el) &&
        !e.composedPath().includes(this.$refs['input-popper']?.$el)
      ) {
        this.showPopper = false
        this.focused = false
      }
    },
    onInfiniteScrollObserverIntersect() {
      this.$emit('paginate')
    },
    cleanerFunction() {
      this.selectedValue = null
    },
    onInput(value) {
      if (!this.preventSelection) {
        this.selectedValue = value
      } else if (Array.isArray(value)) {
        this.$emit('input', [...value])
      } else {
        this.$emit('input', value)
      }
    },
    onClosePopper() {
      this.showPopper = false
      this.$nextTick(() => {
        this.focused = true
      })
    },
    hidePopper() {
      this.showPopper = false
    },
    onOpenPopper() {
      this.showPopper = true
    },
    onUpdateSelectedValue(selectedValue) {
      if (!this.preventSelection) {
        this.selectedValue = selectedValue
      }
    },
    onClick() {
      if (!this.disabled) {
        if (!this.showPopper || this.filterable) {
          this.showPopper = true
        } else {
          this.showPopper = false
        }
        this.$nextTick(() => {
          this.focused = true
        })
      }
    },
    onInputClick() {},
    setShowSlots() {
      this.$nextTick(() => {
        this.showSlots.caption = !!this.$slots?.caption?.[0]
        this.showSlots.error = !!this.$slots?.error?.[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSelect {
  &__hidden-input {
    @apply w-0 h-0;
  }

  &__placeholder {
    @apply px-4;
  }

  &__arrow {
    @apply transition duration-200;
    transform: rotate(90deg);
    &_opened {
      transform: rotate(270deg);
    }
  }

  &__input {
    @apply absolute w-full h-full left-0 top-0;
  }
  &__caption {
    @apply pl-4 absolute w-full h-full left-0 top-0 flex items-center select-none min-w-0;
    &-inner {
      @apply truncate;
    }
  }

  &__filter-input {
    @apply bg-transparent outline-none w-full h-full px-4;
    font-size: inherit;
    color: inherit;
  }
}
</style>
