<template>
  <LLPage class="SubProcessors">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #title>
      <h1 class="SubProcessors__title">{{ $t('sub_processors_title') }}</h1>
    </template>
    <template #content>
      <p class="SubProcessors__date">{{ $t('sub_processors_updated_at') }}</p>
      <SubProcessorsEN />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import SubProcessorsEN from '@/components/pages/sub-processors/languages/SubProcessorsEN'
export default {
  name: 'SubProcessors',
  components: { SubProcessorsEN, LLBreadCrumbs, LLPage },
  data() {
    return {}
  },
  computed: {
    crumbs() {
      return [{ name: this.$t('main_home_label'), path: '/' }, { name: this.$t('sub_processors_title') }]
    },
    currentLanguage() {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss" scoped>
.SubProcessors {
  &__date {
    @apply text-neutral-01 text-sm font-semibold mb-10;
  }
}
</style>
