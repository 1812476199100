export const eventNameEnums = {
  CONVERSATION_STREAM_MESSAGE_UPDATE: 'conversationStreamMessage',
  CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED: 'conversationStreamMessageParsed',
  CONVERSATION_STREAM_STATE: 'conversationStreamState',
  QUESTION_VISIBILITY_UPDATE: 'questionVisibilityUpdate',
  NEW_ANSWER_SEND: 'newAnswerSend',
  QUESTION_DELETE: 'questionDelete',
  QUESTION_UPDATE: 'questionUpdate',
  TOPIC_UPDATE: 'topicUpdate',
  TOPIC_DELETE: 'topicDelete',
  SCREENING_QUESTIONS_UPDATE: 'screeningQuestionsUpdate',
  STUDY_UPDATE: 'studyUpdate',
  PROSPECT_ACCOUNT_UPDATE: 'prospectAccountUpdate'
}
