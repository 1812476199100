<template>
  <LLWordTextContainer class="PrivacyPolicyFR">
    <p class="c19">
      <span class="c3"
        >La protection de vos donn&eacute;es est notre priorit&eacute;. &nbsp;La pr&eacute;sente politique de
        confidentialit&eacute; s&#39;applique aux sites web, applications et services contr&ocirc;l&eacute;s
        par Spark Ideas, LLC (&quot;Spark&quot;, &quot;nous&quot; ou &quot;notre&quot;), notamment
        spark-nyc.com et looklook.app, (collectivement, les &quot;sites&quot;) et l&#39;application mobile
        LookLook (l&#39;&quot;application mobile&quot;). Veuillez lire attentivement cette politique de
        confidentialit&eacute;, car en utilisant les sites ou l&#39;application mobile, vous acceptez de vous
        conformer aux pratiques d&eacute;crites concernant les donn&eacute;es dans cette politique.</span
      >
    </p>
    <p class="c19 c12"><span class="c3"></span></p>
    <p class="c19">
      <span class="c21"
        >Votre utilisation des sites ou de l&#39;application mobile est &eacute;galement r&eacute;gie par les
        conditions de service, qui peuvent &ecirc;tre consult&eacute;es
      </span>
    </p>
    <p class="c10">
      <span class="c34">AVIS IMPORTANT :</span><span class="c41">&nbsp;</span
      ><span class="c3"
        >VEUILLEZ NOTER LA DISPOSITION D&#39;ARBITRAGE &Eacute;NONC&Eacute;E CI-DESSOUS, QUI VOUS OBLIGE, SAUF
        DANS LES CAS ET DANS LA MESURE O&Ugrave; LA LOI L&#39;INTERDIT, D&rsquo;ARBITRER TOUTE
        R&Eacute;CLAMATION QUE VOUS POURRIEZ AVOIR CONTRE SPARK SUR UNE BASE INDIVIDUELLE. L&#39;ARBITRAGE SUR
        UNE BASE INDIVIDUELLE SIGNIFIE QUE VOUS N&#39;OBTIENDREZPAS, ET QUE VOUS RENONCEZ, AU DROIT DE RECOURS
        AUPR&Egrave;S D&rsquo;UN JUGE OU AUPR&Egrave;S D&rsquo;UN JURY POUR D&Eacute;CIDER DE VOS
        R&Eacute;CLAMATIONS, ET QUE VOUS NE POUVEZ PAS INTENTER UNE PROC&Eacute;DURE EN TANT QUE GROUPE,
        ENTIT&Eacute; CONSOLID&Eacute;E OU REPR&Eacute;SENTANT.</span
      >
    </p>
    <p class="c10">
      <span class="c2">La collecte et l&rsquo;utilisation de vos informations personnelles<br /></span>
    </p>
    <p id="h.gjdgxs" class="c22">
      <span class="c3"
        >&quot;Information personnelle&quot; : toute information relative &agrave; une personne physique
        identifi&eacute;e ou identifiable. Nous recueillons les informations suivantes qui vous concernent
        aupr&egrave;s de vous :</span
      >
    </p>
    <p class="c22 c12"><span class="c3"></span></p>
    <a id="t.7b10ce5b6c90476b6336f01e8766b18b3ce8c88b"></a><a id="t.0"></a>
    <table class="c43">
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">Donn&eacute;es d&eacute;mographiques</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">Nous utilisons ces donn&eacute;es pour</span></p>
        </td>
      </tr>
      <tr class="c5">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9">
            <span class="c3"
              >Nom<br />Num&eacute;ro de t&eacute;l&eacute;phone<br />Ville, &eacute;tat et pays<br />Adresse
              &eacute;lectronique<br />Titre du poste<br />Entreprise<br />Formation <br />Tranche de
              revenus<br />&Eacute;tat civil</span
            >
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <ul class="c17 lst-kix_list_9-0 start">
            <li class="c0 li-bullet-0"><span class="c3">R&eacute;aliser des &eacute;tudes</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >Communiquer avec les clients et les prospects (e-mail, formulaires de contact, WeChat)</span
              >
            </li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >Permettre aux clients d&#39;utiliser notre plateforme d&#39;&eacute;tudes
              </span>
            </li>
            <li class="c16 li-bullet-0"><span class="c3">R&eacute;soudre les probl&egrave;mes</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >Offrir une assistance aux clients<br />
                <br />
                <br />Base l&eacute;gale : consentement, contrat, int&eacute;r&ecirc;t l&eacute;gitime</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">Pr&eacute;f&eacute;rences</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c9"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c36">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9">
            <span class="c3"
              >Hobbies<br />Int&eacute;r&ecirc;ts<br />Habitudes d&#39;achat<br />Pr&eacute;f&eacute;rence
              pour une marque<br />Historique des achats<br />Autres sujets pertinents pour
              l&#39;&eacute;tude</span
            >
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <ul class="c17 lst-kix_list_10-0 start">
            <li class="c0 li-bullet-0">
              <span class="c3"
                >R&eacute;alisation d&#39;&eacute;tudes<br /><br />Base l&eacute;gale : consentement</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">M&eacute;dias</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c9"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c37">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9">
            <span class="c3">Photographies<br />Vid&eacute;os <br />Voix</span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <ul class="c17 lst-kix_list_10-0">
            <li class="c0 li-bullet-0">
              <span class="c3"
                >R&eacute;alisation d&#39;&eacute;tudes<br /><br />Base l&eacute;gale : consentement</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">Communication</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c9"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c35">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9">
            <span class="c3"
              >Entretiens avec les participants<br />Demandes g&eacute;n&eacute;rales (courriel ou contact
              pour les demandes des clients)<br />Service client&egrave;le</span
            >
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <ul class="c17 lst-kix_list_10-0">
            <li class="c0 li-bullet-0"><span class="c3">R&eacute;aliser des &eacute;tudes</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >Communiquer avec les clients et prospects (email, formulaires de contact, WeChat)</span
              >
            </li>
            <li class="c16 li-bullet-0"><span class="c3">Offrir un support client</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >Distribuer des r&eacute;compenses<br /><br />Base l&eacute;gale : consentement,
                int&eacute;r&ecirc;t l&eacute;gitime</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9"><span class="c2">Technologie</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c9"><span class="c3">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c35">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c9">
            <span class="c3"
              >G&eacute;olocalisation de la ville<br />Adresse IP<br />Donn&eacute;es relatives &agrave;
              l&#39;appareil et au navigateur</span
            >
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <ul class="c17 lst-kix_list_11-0 start">
            <li class="c0 li-bullet-0"><span class="c3">Mener des &eacute;tudes</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3">Permettre aux clients d&#39;utiliser notre plateforme de recherche </span>
            </li>
            <li class="c16 li-bullet-0">
              <span class="c3">Am&eacute;liorer nos produits et services </span>
            </li>
            <li class="c16 li-bullet-0"><span class="c3">R&eacute;soudre des probl&egrave;mes</span></li>
            <li class="c16 li-bullet-0">
              <span class="c3"
                >S&eacute;curiser la plateforme<br /><br />Base l&eacute;gale : consentement,
                int&eacute;r&ecirc;t l&eacute;gitime</span
              >
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <p class="c9"><span class="c2">Les cookies et autres technologies similaires</span></p>
    <p class="c9">
      <span class="c3"
        >Nous utilisons des cookies n&eacute;cessaires et anonymes et d&#39;autres technologies similaires sur
        nos sites et l&#39;application mobile pour faciliter la collecte d&#39;informations. Nous pouvons
        utiliser des cookies de session ou des cookies persistants. Les cookies de session ne durent que pour
        la dur&eacute;e sp&eacute;cifique de votre visite et sont supprim&eacute;s lorsque vous fermez votre
        navigateur. Les cookies persistants restent sur le disque dur de votre appareil jusqu&#39;&agrave; ce
        que vous les supprimiez ou lorsqu&#39;ils expirent. Nous utilisons les types de cookies suivants
        :</span
      >
    </p>
    <ul class="c17 lst-kix_list_5-0 start">
      <li class="c0 li-bullet-0">
        <span class="c8">Essentiel</span
        ><span class="c3"
          >. Certains cookies sont essentiels pour vous permettre de vous d&eacute;placer sur nos sites et/ou
          sur l&#39;application mobile et d&#39;utiliser leurs fonctionnalit&eacute;s, comme
          l&#39;acc&egrave;s &agrave; des zones s&eacute;curis&eacute;es des sites et/ou de l&#39;application
          mobile. Sans ces cookies, nous ne pouvons pas activer le contenu appropri&eacute; en fonction du
          type d&#39;appareil que vous utilisez.</span
        >
      </li>
      <li class="c16 li-bullet-0">
        <span class="c8">Performance et analyse</span
        ><span class="c3"
          >. Nous utilisons des cookies pour savoir comment vous utilisez nos sites et/ou l&#39;application
          mobile afin d&#39;am&eacute;liorer leurs performances et de les d&eacute;velopper en fonction de vos
          pr&eacute;f&eacute;rences. &nbsp;Aucune information personnelle n&#39;est collect&eacute;e par le
          biais des cookies.</span
        >
      </li>
    </ul>
    <p class="c9"><span class="c2">Le partage et la divulgation de vos informations personnelles</span></p>
    <p class="c9">
      <span class="c3"
        >Nous partageons ou divulguons vos informations personnelles dans les cas suivants et aux parties
        suivantes:</span
      >
    </p>
    <ul class="c17 lst-kix_list_2-0 start">
      <li class="c0 li-bullet-0">
        <span class="c3"
          >Nous partageons les informations fournies par les sites et l&#39;application mobile avec nos
          clients pour leurs &eacute;tudes de marketing et de recherche et &agrave; des fins
          commerciales.</span
        >
      </li>
      <li class="c16 li-bullet-0">
        <span class="c3"
          >Nous partageons les informations fournies par l&#39;interm&eacute;diaire des sites et de
          l&#39;application mobile avec des prestataires de services tiers pour administrer des &eacute;tudes
          et fournir des rapports aux clients. Une liste actualis&eacute;e de nos prestataires de services
          tiers peut &ecirc;tre consult&eacute;e </span
        ><span class="c3 c39">ici</span><span class="c3">.</span>
      </li>
      <li class="c16 li-bullet-0">
        <span class="c3"
          >Nous pouvons partager ou transf&eacute;rer vos informations dans le cadre de tout processus de
          r&eacute;organisation directe ou indirecte, y compris, mais sans se limiter aux fusions,
          acquisitions, cessions, faillites et ventes de tout ou partie de nos actifs. Veuillez consulter la
          cession ci-dessous pour plus d&#39;informations.
        </span>
      </li>
      <li class="c16 li-bullet-0">
        <span class="c3"
          >Nous divulguerons vos informations personnelles pour nous conformer &agrave; la loi vigueur ou si
          nous pensons en toute bonne foi qu&#39;une telle action est n&eacute;cessaire pour nous conformer
          aux exigences de la loi ou &agrave; une proc&eacute;dure judiciaire qui nous est signifi&eacute;e,
          pour prot&eacute;ger et d&eacute;fendre nos droits ou nos biens, ou pour agir en cas d&rsquo;urgence
          afin de prot&eacute;ger la s&eacute;curit&eacute; personnelle de nos utilisateurs.</span
        >
      </li>
    </ul>
    <p class="c9">
      <span class="c3"
        >La vente, au sens des lois am&eacute;ricaines sur la protection de la vie priv&eacute;e des
        consommateurs, est d&eacute;finie comme la vente, la location, la divulgation, la diffusion, la mise
        &agrave; disposition, le transfert ou toute autre forme de communication orale, &eacute;crite,
        &eacute;lectronique ou autre des informations personnelles d&#39;un consommateur par l&#39;entreprise
        &agrave; une autre entreprise ou &agrave; un tiers contre une contrepartie mon&eacute;taire, et
        parfois d&#39;autres contreparties de valeur.</span
      >
    </p>
    <p class="c9">
      <span class="c3"
        >Nous ne vendons pas d&#39;informations personnelles, telles que d&eacute;finies par les lois
        am&eacute;ricaines sur la protection de la vie priv&eacute;e, et nous ne vendrons jamais vos
        informations personnelles &agrave; un tiers dans n&#39;importe quelle partie du monde.</span
      >
    </p>
    <p class="c18">
      <span class="c2">Le recueil et l&rsquo;utilisation d&#39;informations concernant les enfants</span>
    </p>
    <p class="c18">
      <span class="c3"
        >Nous ne recueillons pas sciemment des informations aupr&egrave;s d&#39;enfants &acirc;g&eacute;s de
        moins de 13 ans aux &Eacute;tats-Unis ou de moins de 16 ans dans l&#39;UE. Si nous d&eacute;couvrons
        qu&#39;une personne &acirc;g&eacute;e de moins de 13 ans aux &Eacute;tats-Unis ou de moins de 16 ans
        dans l&#39;UE nous a fourni des informations personnelles, nous supprimerons ces informations.</span
      >
    </p>
    <p class="c18"><span class="c2">L&rsquo;acc&egrave;s et la d&eacute;sinscription</span></p>
    <p class="c18">
      <span class="c3"
        >Si vos informations ont chang&eacute; et que vous souhaitez mettre &agrave; jour les informations que
        nous d&eacute;tenons &agrave; votre sujet, veuillez nous contacter en utilisant les coordonn&eacute;es
        ci-dessous.</span
      >
    </p>
    <p class="c18">
      <span class="c3"
        >Si vous ne souhaitez plus recevoir de communications commerciales de notre part, vous pouvez vous
        d&eacute;sinscrire en suivant les instructions de d&eacute;sinscription fournies dans notre
        communication commerciale ou en nous contactant &agrave; l&#39;adresse ci-dessous. Nous traiterons
        votre d&eacute;sinscription dans les meilleurs d&eacute;lais et conform&eacute;ment &agrave; la
        l&eacute;gislation en vigueur, mais sachez que dans certaines circonstances, il se peut que vous
        receviez quelques messages suppl&eacute;mentaires jusqu&#39;&agrave; ce que la d&eacute;sinscription
        soit trait&eacute;e et termin&eacute;e.
      </span>
    </p>
    <p class="c18">
      <span class="c3"
        >Veuillez noter que m&ecirc;me si vous refusez nos communications marketing, nous vous enverrons
        toujours des messages administratifs concernant vos commandes et les sites et/ou l&#39;application
        mobile, y compris, par exemple, des mises &agrave; jour importantes concernant les sites et/ou
        l&#39;application mobile.</span
      >
    </p>
    <p class="c18"><span class="c2">Vos droits</span></p>
    <p class="c18">
      <span class="c3"
        >Si vous &ecirc;tes un r&eacute;sident de l&#39;EEE, du Royaume-Uni ou de la Suisse, vous avez le
        droit de demander :</span
      >
    </p>
    <p class="c18 c12"><span class="c3"></span></p>
    <ul class="c17 lst-kix_list_7-0 start">
      <li class="c4 li-bullet-0">
        <span class="c3">de savoir si vos informations personnelles sont trait&eacute;es </span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">d&#39;acc&eacute;der &agrave; vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">de modifier ou de vous envoyer une copie de vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">l&#39;effacement ou l&#39;anonymisation de vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">nous restreignons l&#39;utilisation de vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >nous r&eacute;duisons la communication avec vous ou nous nous opposons &agrave; votre participation
          &agrave; l&#39;&eacute;tude.
        </span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >d&#39;&ecirc;tre inform&eacute; si nous utilisons des outils de prise de d&eacute;cision
          automatique et de profilage
        </span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >D&eacute;poser une plainte aupr&egrave;s de l&#39;autorit&eacute; de contr&ocirc;le de la
          protection des donn&eacute;es comp&eacute;tente.</span
        >
      </li>
    </ul>
    <p class="c18">
      <span class="c3">Liste des autorit&eacute;s de protection des donn&eacute;es de l&#39;UE :</span>
    </p>
    <p class="c18">
      <span class="c32"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1666016038238738&amp;usg=AOvVaw27HJM31J_szs1HSmEDLEn3"
          >EEE
        </a></span
      ><span class="c21"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1666016038239100&amp;usg=AOvVaw2HPo6jDGVq51n4pcTnOqiT"
          ><br /></a></span
      ><span class="c32"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1666016038239412&amp;usg=AOvVaw27klyhYVSmG0Xts5KUB9Cc"
          >Suisse</a
        ></span
      ><span class="c21"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1666016038239727&amp;usg=AOvVaw3PK43V1Jl8g4G3WSHyj4i7"
          ><br /></a></span
      ><span class="c32"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1666016038239975&amp;usg=AOvVaw0PLZXmS8775AyOKqz-BN9R"
          >Royaume-Uni
        </a></span
      >
    </p>
    <p class="c18">
      <span class="c3"
        >Si vous &ecirc;tes en Californie, au Connecticut, en Utah, au Colorado, en Virginie ou dans un autre
        &Eacute;tat am&eacute;ricain dot&eacute; d&#39;une l&eacute;gislation sur la protection de la vie
        priv&eacute;e, vous avez le droit de demander :</span
      >
    </p>
    <ul class="c17 lst-kix_list_8-0 start">
      <li class="c18 c23 li-bullet-0">
        <span class="c3">l&#39;acc&egrave;s &agrave; vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3">la rectification de vos informations personnelles</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >savoir si nous avons vendu vos informations et vous opposer &agrave; la vente de vos informations
          personnelles</span
        >
      </li>
      <li class="c4 li-bullet-0"><span class="c3">la suppression de vos informations personnelles</span></li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >le droit de recevoir vos informations personnelles dans un format facilement compr&eacute;hensible
          et, dans la mesure o&ugrave; cela est techniquement possible, dans un format structur&eacute;,
          couramment utilis&eacute; et lisible par machine.</span
        >
      </li>
    </ul>
    <p class="c18">
      <span class="c3"
        >Liste des autorit&eacute;s am&eacute;ricaines charg&eacute;es de la protection des donn&eacute;es
        :</span
      >
    </p>
    <p class="c6 c12"><span class="c20"></span></p>
    <p class="c6">
      <span class="c24"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1666016038241055&amp;usg=AOvVaw0pqZFHg4HcJTSQr7oxgU6K"
          >Californie&nbsp;</a
        ></span
      >
    </p>
    <p class="c6">
      <span class="c24"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1666016038241438&amp;usg=AOvVaw2yEA4dprBQ176yM9egrYE9"
          >Connecticut</a
        ></span
      >
    </p>
    <p class="c6">
      <span class="c24"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1666016038241866&amp;usg=AOvVaw2ni3FgZnEd8lK45tynwhEX"
          >Utah</a
        ></span
      >
    </p>
    <p class="c6">
      <span class="c24"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1666016038242220&amp;usg=AOvVaw3lYAQmKt129B2lzFF6PNdo"
          >Colorado</a
        ></span
      >
    </p>
    <p class="c6">
      <span class="c24"
        ><a
          class="c14"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1666016038242612&amp;usg=AOvVaw18IODodGf_F8wa-5sD_ESb"
          >Virginie</a
        ></span
      >
    </p>
    <p class="c6 c12"><span class="c20"></span></p>
    <p class="c6">
      <span class="c3"
        >Si vous r&eacute;sidez dans les fronti&egrave;res de la R&eacute;publique populaire de Chine,
        veuillez consulter notre politique de confidentialit&eacute; en chinois.
      </span>
    </p>
    <p class="c6">
      <span class="c3"
        >Si vous souhaitez exercer vos droits, veuillez envoyer votre demande par courrier &eacute;lectronique
        &agrave; privacy@spark-nyc.com.</span
      >
    </p>
    <p class="c18"><span class="c2">Les liens vers des sites tiers</span></p>
    <p class="c42">
      <span class="c3"
        >Spark vous encourage &agrave; consulter les d&eacute;clarations de confidentialit&eacute; des sites
        web li&eacute;s aux sites et/ou &agrave; l&#39;application mobile, afin de comprendre comment ces
        sites web recueillent, utilisent et partagent vos informations. Spark n&#39;est pas responsable des
        d&eacute;clarations de confidentialit&eacute; ou de tout contenu de ces sites web en dehors des sites
        et de l&#39;application mobile.</span
      >
    </p>
    <p class="c18"><span class="c2">La s&eacute;curit&eacute; de vos informations personnelles</span></p>
    <p class="c28">
      <span class="c3"
        >Spark met en &oelig;uvre des mesures raisonnables pour prot&eacute;ger vos donn&eacute;es
        personnelles contre tout acc&egrave;s, utilisation ou divulgation non autoris&eacute;s. Cela dit,
        sachez n&eacute;anmoins que nous ne pouvons garantir &agrave; 100% la s&eacute;curit&eacute; de toute
        transmission de donn&eacute;es sur internet. Par cons&eacute;quent, nous ne pouvons pas assurer ou
        garantir la s&eacute;curit&eacute; de toute information que vous nous transmettez et sachez que toute
        information que vous nous transmettez se fait &agrave; vos propres risques. &nbsp;Si nous avons
        connaissance d&#39;une violation des syst&egrave;mes de s&eacute;curit&eacute;, nous tenterons de vous
        en informer par voie &eacute;lectronique afin que vous puissiez prendre les mesures de protection
        appropri&eacute;es. En utilisant les sites et/ou l&#39;application mobile ou en nous fournissant des
        informations personnelles, vous acceptez que nous communiquions avec vous par voie &eacute;lectronique
        concernant les questions de s&eacute;curit&eacute;, de confidentialit&eacute; et d&#39;administration
        li&eacute;es &agrave; votre utilisation des sites et/ ou de l&rsquo;application mobile. Nous pouvons
        publier un avis via les sites et l&#39;application mobile pour toute violation li&eacute;e &agrave; la
        s&eacute;curit&eacute;. Nous pouvons &eacute;galement vous envoyer un courriel &agrave; l&#39;adresse
        &eacute;lectronique que vous nous avez fourni dans de telles circonstances. En fonction de votre lieu
        de r&eacute;sidence, vous avez le droit de recevoir une notification &eacute;crite pour toute
        violation relative &agrave; la s&eacute;curit&eacute;.</span
      >
    </p>
    <p class="c28"><span class="c2">La conservation des donn&eacute;es</span></p>
    <p class="c28">
      <span class="c3"
        >Nous conservons vos informations personnelles pour la p&eacute;riode n&eacute;cessaire &agrave; la
        r&eacute;alisation des objectifs d&eacute;crits dans la pr&eacute;sente politique de
        confidentialit&eacute;, &agrave; moins qu&#39;une p&eacute;riode de conservation plus longue ne soit
        requise ou autoris&eacute;e par la loi.</span
      >
    </p>
    <p class="c28 c12"><span class="c3"></span></p>
    <p class="c10"><span class="c2">Les transferts internationaux de donn&eacute;es</span></p>
    <p class="c10">
      <span class="c3"
        >Si vous choisissez de nous fournir des informations, nous pouvons transf&eacute;rer ces informations
        &agrave; nos soci&eacute;t&eacute;s affili&eacute;es et filiales ou &agrave; d&#39;autres tiers, hors
        des fronti&egrave;res, et provenant de votre pays ou juridiction aupr&egrave;s d&#39;autres pays ou
        juridictions dans le monde. Si vous visitez le site depuis l&#39;UE ou d&#39;autres r&eacute;gions
        dont les lois r&eacute;gissant le recueil et l&#39;utilisation des donn&eacute;es peuvent
        diff&eacute;rer de la l&eacute;gislation am&eacute;ricaine, veuillez noter que vous transf&eacute;rez
        vos informations personnelles vers les &Eacute;tats-Unis et d&#39;autres juridictions qui peuvent ne
        pas avoir les m&ecirc;mes lois de protection des donn&eacute;es que votre pays de r&eacute;sidence.
        Vous comprenez qu&#39;en fournissant vos informations personnelles :</span
      >
    </p>
    <ul class="c17 lst-kix_list_6-0 start">
      <li class="c10 c23 li-bullet-0">
        <span class="c3"
          >Vos informations personnelles seront utilis&eacute;es pour les usages identifi&eacute;s ci-dessus,
          conform&eacute;ment &agrave; la pr&eacute;sente politique de confidentialit&eacute; et &agrave;
          l&#39;accord de protection des donn&eacute;es sign&eacute;.
        </span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c3"
          >Vos informations personnelles peuvent &ecirc;tre transf&eacute;r&eacute;es aux &Eacute;tats-Unis et
          dans d&#39;autres juridictions conform&eacute;ment &agrave; la loi applicable, comme indiqu&eacute;
          ci-dessus. Vous pouvez consulter notre liste actuelle de sous-processeurs et leurs mesures
          suppl&eacute;mentaires pour le transfert de donn&eacute;es ici.</span
        >
      </li>
    </ul>
    <p class="c10"><span class="c2">Le transfert</span></p>
    <p class="c10">
      <span class="c3"
        >Dans le cas o&ugrave; tout ou partie de nos actifs seraient vendus ou acquis par une autre partie, ou
        dans le cas d&#39;un processus de r&eacute;organisation direct ou indirect, y compris, mais sans se
        limiter aux fusions, acquisitions, cessions, faillites et aux ventes de tout ou partie de nos actifs,
        vous nous accordez le droit de c&eacute;der les informations personnelles collect&eacute;es via les
        sites et/ou l&#39;application mobile apr&egrave;s la r&eacute;alisation d&#39;une telle transaction
        et/ou pendant le processus d&#39;&eacute;valuation en attendant le transfert. Si elles sont
        transf&eacute;r&eacute;es dans de telles circonstances, vos informations resteront soumises &agrave;
        la pr&eacute;sente politique de confidentialit&eacute; ou &agrave; une politique de
        confidentialit&eacute; qui, au minimum, prot&egrave;ge votre vie priv&eacute;e dans la m&ecirc;me
        mesure que la pr&eacute;sente politique de confidentialit&eacute;.</span
      >
    </p>
    <p class="c10">
      <span class="c2">Le r&egrave;glement des litiges et la convention d&#39;arbitrage</span>
    </p>
    <p class="c10">
      <span class="c3"
        >Sauf dans la mesure o&ugrave; la loi l&#39;interdit, en utilisant les sites et/ou l&#39;application
        Mobile, vous et Spark convenez qu&#39;en cas de controverse, de r&eacute;clamation, d&#39;action ou de
        litige li&eacute; &agrave; l&#39;utilisation des sites et/ou de l&#39;application mobile, ou &agrave;
        la violation, l&#39;application, l&#39;interpr&eacute;tation ou la validit&eacute; de la
        pr&eacute;sente politique de confidentialit&eacute; ou de toute partie de celle-ci (le &quot; Litige
        &quot;), les deux parties tenteront d&#39;abord de r&eacute;gler le litige de bonne foi en adressant
        &agrave; l&#39;autre partie une notification &eacute;crite d&eacute;crivant les faits et les
        circonstances du litige et en lui demandant d&#39;y r&eacute;pondre, ou la validit&eacute; de la
        pr&eacute;sente politique de confidentialit&eacute; ou la totalit&eacute; ou une partie de celle-ci
        (&quot; Litige &quot;), les deux parties devront d&#39;abord essayer en toute bonne foi de
        r&eacute;gler ce litige en adressant &agrave; l&#39;autre partie une notification &eacute;crite
        d&eacute;crivant les faits et les circonstances du litige et en accordant &agrave; la partie
        destinataire un d&eacute;lai de trente (30) jours pour r&eacute;pondre au litige ou le r&eacute;gler.
        La notification doit nous &ecirc;tre envoy&eacute;e &agrave; l&#39;adresse suivante</span
      >
    </p>
    <p class="c7"><span class="c3">Spark Ideas, LLC</span></p>
    <p class="c26"><span class="c3">1328 Ridge Road</span></p>
    <p class="c1"><span class="c3">Syosset, New York 11791</span></p>
    <p class="c10">
      <span class="c3"
        >Vous et Spark acceptez tous deux que cette proc&eacute;dure de r&egrave;glement des litiges constitue
        une condition pr&eacute;alable qui doit &ecirc;tre remplie avant d&#39;entamer tout litige ou de
        d&eacute;poser toute r&eacute;clamation contre l&#39;autre partie. SI UN LITIGE NE PEUT &Ecirc;TRE
        R&Eacute;SOLU PAR LA PROC&Eacute;DURE DE R&Eacute;SOLUTION DES LITIGES CI-DESSUS, VOUS ACCEPTEZ QUE LA
        SEULE ET UNIQUE JURIDICTION POUR CE LITIGE SOIT D&Eacute;TERMIN&Eacute;E PAR UN ARBITRAGE
        EX&Eacute;CUTOIRE SUR UNE BASE INDIVIDUELLE. L&#39;ARBITRAGE SUR UNE BASE INDIVIDUELLE SIGNIFIE QUE
        VOUS N&#39;AUREZ PAS, ET QUE VOUS RENONCEZ, AU DROIT DE RECOURS AUPR&Egrave;S D&rsquo;UN JUGE OU
        AUPR&Egrave;S D&rsquo;UN JURY POUR D&Eacute;CIDER DE VOS R&Eacute;CLAMATIONS, ET QUE VOUS NE POUVEZ
        PAS INTENTER DE PROC&Eacute;DURE EN TANT QUE GROUPE, UNIT&Eacute; CONSOLID&Eacute;E OU
        REPR&Eacute;SENTANT. Les autres droits dont nous disposons autrement au tribunal ne seront pas
        disponibles ou seront bien plus limit&eacute;s dans l&#39;arbitrage, y compris les droits de
        divulgation et d&#39;appel. Tous ces litiges seront exclusivement transmis &agrave; JAMS
        (www.jamsadr.com) pour un arbitrage ex&eacute;cutoire selon ses r&egrave;gles alors en vigueur, devant
        un arbitre qui sera choisi d&#39;un commun accord par les deux parties. L&#39;arbitre m&egrave;nera
        les audiences, le cas &eacute;ch&eacute;ant, par t&eacute;l&eacute;conf&eacute;rence ou
        vid&eacute;oconf&eacute;rence, plut&ocirc;t que par comparution personnelle, &agrave; moins que
        l&#39;arbitre ne d&eacute;termine, &agrave; votre demande ou &agrave; la n&ocirc;tre, qu&#39;une
        audience en personne soit plus appropri&eacute;e. Toute comparution en personne se tiendra dans un
        lieu raisonnablement pratique pour les deux parties, en tenant compte de leur capacit&eacute; &agrave;
        voyager et d&#39;autres circonstances pertinentes. Si les parties ne parviennent pas &agrave; se
        mettre d&#39;accord sur le lieu, ce sera &agrave; JAMS ou &agrave; l&#39;arbitre d&rsquo;en
        d&eacute;cider.</span
      >
    </p>
    <p class="c10">
      <span class="c3"
        >L&#39;arbitre, et non un tribunal ou une agence f&eacute;d&eacute;rale, &eacute;tatique ou locale,
        aura l&#39;autorit&eacute; exclusive pour r&eacute;soudre tout litige d&eacute;coulant ou li&eacute;
        &agrave; l&#39;interpr&eacute;tation, l&#39;applicabilit&eacute;, l&#39;ex&eacute;cution ou la
        formation de la pr&eacute;sente politique de confidentialit&eacute;, y compris toute
        r&eacute;clamation selon laquelle l&rsquo;int&eacute;grit&eacute; ou une partie de la pr&eacute;sente
        politique de confidentialit&eacute; est nulle ou annulable.</span
      >
    </p>
    <p class="c10"><span class="c2">Les choix de la loi applicable</span></p>
    <p class="c10">
      <span class="c3"
        >La pr&eacute;sente politique de confidentialit&eacute; a &eacute;t&eacute; &eacute;labor&eacute;e et
        sera interpr&eacute;t&eacute;e conform&eacute;ment aux lois de l&#39;&Eacute;tat de New York, sans
        donner effet &agrave; aucun principe de choix de la loi applicable. Les parties reconnaissent que la
        pr&eacute;sente politique de confidentialit&eacute; t&eacute;moigne d&#39;une transaction impliquant
        un commerce inter&eacute;tatique. Nonobstant la disposition du paragraphe pr&eacute;c&eacute;dent
        concernant le droit substantiel en vigueur, tout arbitrage men&eacute; conform&eacute;ment aux termes
        de la pr&eacute;sente politique de confidentialit&eacute; sera r&eacute;gi par la loi
        f&eacute;d&eacute;rale sur l&#39;arbitrage (9 U.S.C. &sect;&sect; 1-16).</span
      >
    </p>
    <p class="c10">
      <span class="c2"
        >Vos droits en mati&egrave;re de protection de la vie priv&eacute;e en Californie et la mani&egrave;re
        dont nous r&eacute;pondons aux signaux &quot;de non suivi&quot;
      </span>
    </p>
    <p class="c10">
      <span class="c3"
        >La section 1798.83 du code civil de Californie permet aux visiteurs des sites et/ou de
        l&#39;application mobile, qui r&eacute;sident en Californie, de demander certaines informations
        concernant notre divulgation d&#39;informations personnelles &agrave; des tiers &agrave; des fins de
        marketing direct. Nous ne partageons pas vos informations personnelles avec des tiers &agrave; des
        fins de marketing direct.</span
      >
    </p>
    <p class="c10">
      <span class="c3"
        >&Agrave; l&#39;heure actuelle, nos sites ne reconnaissent pas les signaux automatis&eacute;s des
        navigateurs concernant les m&eacute;canismes de suivi, qui peuvent inclure des instructions &quot;non
        suivi&quot;.</span
      >
    </p>
    <p class="c10"><span class="c2">Les coordonn&eacute;es</span></p>
    <p class="c25 c12"><span class="c3"></span></p>
    <p class="c25">
      <span class="c3"
        >Spark accueillera volontiers vos questions ou commentaires concernant cette politique de
        confidentialit&eacute; et nos pratiques en la mati&egrave;re. Veuillez nous contacter &agrave;
        l&#39;adresse suivante</span
      >
    </p>
    <p class="c7"><span class="c3">Spark Ideas, LLC</span></p>
    <p class="c26"><span class="c3">1328 Ridge Road</span></p>
    <p class="c1"><span class="c3">Syosset, New York 11791 </span></p>
    <p class="c1"><span class="c3">United States of America</span></p>
    <p class="c1 c12"><span class="c3"></span></p>
    <p class="c6"><span class="c3">Adresse &eacute;lectronique :</span></p>
    <p class="c6"><span class="c38">privacy@spark-nyc.com</span><span class="c3">&nbsp; </span></p>
    <p class="c7"><span class="c3">Num&eacute;ro de t&eacute;l&eacute;phone :</span></p>
    <p class="c29"><span class="c3">(917) 991-0477</span></p>
    <p class="c10">
      <span class="c2">Les modifications de la pr&eacute;sente politique de confidentialit&eacute;</span>
    </p>
    <p class="c33 c12"><span class="c2"></span></p>
    <p class="c33">
      <span class="c3"
        >Spark mettra occasionnellement &agrave; jour cette politique de confidentialit&eacute; afin de
        refl&eacute;ter les r&eacute;actions de la soci&eacute;t&eacute; et des clients. Dans ce cas, nous
        modifierons &eacute;galement la date d&#39;entr&eacute;e en vigueur figurant en haut de la
        pr&eacute;sente politique de confidentialit&eacute;. Si des modifications importantes sont
        apport&eacute;es &agrave; la politique de confidentialit&eacute;, nous vous en informerons en
        pla&ccedil;ant un avis bien visible sur les sites et l&#39;application mobile. Nous vous encourageons
        &agrave; consulter r&eacute;guli&egrave;rement cette politique de confidentialit&eacute; afin
        d&rsquo;&ecirc;tre inform&eacute; de la mani&egrave;re dont Spark traite vos informations.</span
      >
    </p>
    <div>
      <p class="c6 c12"><span class="c21 c40"></span></p>
    </div>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyFR',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyFR {
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_10-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_10-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }
  .lst-kix_list_9-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }
  .lst-kix_list_9-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_3-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_11-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_7-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  .lst-kix_list_10-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_10-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_10-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_10-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_10-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_9-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_11-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_9-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '\0025aa  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c15 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 296.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c11 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 170.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c8 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;

    font-style: normal;
  }
  .c24 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;

    font-style: normal;
  }
  .c0 {
    margin-left: 36pt;
    padding-top: 12.8pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c4 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c16 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c20 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c33 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 7.2pt;
  }
  .c19 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 3.6pt;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c10 {
    padding-top: 13.8pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c29 {
    padding-top: 0.3pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c28 {
    padding-top: 12.9pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c9 {
    padding-top: 12.8pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c26 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c22 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c40 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c7 {
    padding-top: 13.3pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c31 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c42 {
    padding-top: 12.9pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c25 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c27 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c18 {
    padding-top: 13.6pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c34 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    text-decoration: underline;
  }
  .c43 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c32 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }
  .c38 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
  }
  .c30 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 53.2pt 72pt;
  }
  .c23 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c14 {
    color: inherit;
    text-decoration: inherit;
  }
  .c17 {
    padding: 0;
    margin: 0;
  }
  .c39 {
    background-color: #ffff00;
  }
  .c41 {
    font-weight: 700;
  }
  .c21 {
    color: #000000;
  }
  .c36 {
    height: 90pt;
  }
  .c35 {
    height: 105pt;
  }
  .c37 {
    height: 45pt;
  }
  .c12 {
    height: 11pt;
  }
  .c5 {
    height: 135pt;
  }
  .c13 {
    height: 15pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;

    padding-bottom: 4pt;

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
  }
  p {
    margin: 0;
    color: #000000;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
