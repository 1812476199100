<template>
  <LLInputLocked class="PollChoiceListExclusiveSelector" :disabled="isDisabled" dark-caption>
    <template #caption>{{ $t('forum.poll.editor.exclusive.title') }} </template>
    <div class="PollChoiceListExclusiveSelector__content">
      <LLInputSpoiler :placeholder="$t('select_placeholder')" :disabled="isDisabled">
        <template v-if="!!caption" #caption>
          {{ caption }}
        </template>
        <template #content>
          <div class="PollChoiceListExclusiveSelector__input-content">
            <LLActionMenuSelectorContainer @confirm="confirm">
              <template #checkbox-list>
                <div class="PollChoiceListExclusiveSelector__checkbox-list">
                  <LLCheckbox
                    v-for="(choice, index) in localChoiceList"
                    :key="choice.id || choice.tempId"
                    v-model="choice.isExclusive"
                    class="PollChoiceListExclusiveSelector__checkbox"
                  >
                    <span v-if="!choice.text" class="PollChoiceListExclusiveSelector__checkbox-no-text">
                      <span v-if="false" class="PollChoiceListExclusiveSelector__checkbox-no-text-number">
                        #{{ index + 1 }}
                      </span>
                      <span class="PollChoiceListExclusiveSelector__checkbox-no-text-light">
                        {{ $t('forum.poll.editor.exclusive.no_text') }}
                      </span>
                    </span>
                    <span v-else class="PollChoiceListExclusiveSelector__checkbox-text">{{
                      choice.text
                    }}</span>
                  </LLCheckbox>
                </div>
              </template>
              <template #empty-state>{{ $tc('common_amount_groups', 0) }}</template>
              <template #confirm-button-label>
                <slot name="confirm-button-label">{{ $t('button_confirm') }}</slot>
              </template>
            </LLActionMenuSelectorContainer>
          </div>
        </template>
      </LLInputSpoiler>
    </div>
  </LLInputLocked>
</template>

<script>
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLActionMenuSelectorContainer from '@/components/common/ui-components/LLActionMenuSelectorContainer.vue'
import LLInputSpoiler from '@/components/common/LLInput/LLInputSpoiler.vue'
import { PollModel } from '@/models/poll'
import { PollChoiceModel } from '@/models/pollChoice'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'

export default {
  name: 'PollChoiceListExclusiveSelector',
  components: { LLCheckbox, LLInputSpoiler, LLActionMenuSelectorContainer, LLInputLocked },
  props: {
    poll: { type: PollModel, required: true },
    choiceList: { type: Array, required: true },
    forceAbilityToEditAfterCreate: { type: Boolean, default: false }
  },
  data() {
    return {
      localChoiceList: []
    }
  },
  computed: {
    caption() {
      return (
        this.choiceList
          .filter((choice) => choice.isExclusive)
          .map((choice) => choice.text)
          .map((text) => text || this.$t('forum.poll.editor.exclusive.no_text'))
          .join(', ') || null
      )
    },
    isDisabled() {
      return !!((this.poll.isAnswerLimitEnabled && this.poll.answerLimitAmount === 1) || this.poll?.id)
    }
  },
  watch: {
    choiceList: {
      handler() {
        if (!_.isEqual(this.localChoiceList, this.choiceList)) {
          this.localChoiceList = this.choiceList.map((choice) => new PollChoiceModel(choice))
        }
      },
      deep: true,
      immediate: true
    },
    isDisabled: {
      handler(to) {
        if (!to) return
        this.$emit(
          'update:choiceList',
          this.localChoiceList.map((choice) => new PollChoiceModel({ ...choice, isExclusive: false }))
        )
      }
    }
  },
  methods: {
    confirm() {
      this.$emit(
        'update:choiceList',
        this.localChoiceList.map((choice) => new PollChoiceModel(choice))
      )
    }
  }
}
</script>

<style scoped lang="scss">
.PollChoiceListExclusiveSelector {
  max-width: 400px;
  &__checkbox-list {
    max-height: 450px;
    @apply overflow-y-auto;
  }
  &__checkbox {
    @apply mt-2;
    &-text {
      @apply truncate min-w-0;
    }
    &:first-child {
      @apply mt-0;
    }
    &-no-text {
      &-light {
        @apply text-neutral-01-400;
      }
    }
  }
}
</style>
