<template>
  <div v-if="uploadingFilesCount" class="UploadProgress">
    <div class="UploadProgress__inner">
      <div class="UploadProgress__panel">
        <div class="UploadProgress__panel-inner" :style="{ width: `${loadedPercentage}%` }">
          <div class="UploadProgress__panel-inner-background"></div>
        </div>
        <div class="UploadProgress__panel-labels">
          <div v-if="uploadingFilesCount > 1 && !processing" class="UploadProgress__panel-uploading-label">
            {{
              $t('uploading_progress_uploading', {
                uploaded: uploadedFilesCount,
                amount: uploadingFilesCount
              })
            }}
          </div>
          <div
            v-else-if="uploadingFilesCount === 1 && !processing"
            class="UploadProgress__panel-uploading-label"
          >
            {{ $t('uploading_progress_uploading_single') }}
          </div>
          <div v-else-if="processing" class="UploadProgress__panel-uploading-label">
            {{ $t('uploading_progress_processing') }}
          </div>
          <div v-if="!processing" class="UploadProgress__panel-percentage">{{ loadedPercentage }} %</div>
          <UploadingIcon v-if="processing" class="UploadProgress__panel-spinner"></UploadingIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadingIcon from '@/assets/icons/UploadingIcon'
export default {
  name: 'UploadProgress',
  components: { UploadingIcon },
  computed: {
    decoding() {
      return this.isVideoUploading && this.allFilesSent
    },
    allFilesSent() {
      return !this.uploadingFiles.filter((f) => f.loadedSize < f.fileSize).length
    },
    isVideoUploading() {
      return !!this.uploadingFiles.find((f) => f.mime.substr(0, 4) === 'vide')
    },
    uploadingFiles() {
      return this.$store.getters['fileUpload/uploadingFiles']
    },
    uploadingFilesUuids() {
      return this.uploadingFiles.map((uf) => uf.uuid)
    },
    loading() {
      return !!this.uploadingFiles.length
    },
    uploadingFilesCount() {
      return this.uploadingFiles.length
    },
    uploadedFilesCount() {
      return this.uploadingFiles.filter((f) => f.loadedSize >= f.fileSize).length + 1
    },
    loadedPercentage() {
      return this.$store.getters['fileUpload/filesLoadedPercentage'](this.uploadingFilesUuids)
    },
    processing() {
      return this.loadedPercentage >= 100
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch('fileUpload/cancel', this.uploadingFilesUuids)
    }
  }
}
</script>

<style lang="scss" scoped>
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.UploadProgress {
  $root: &;
  @apply h-6 mt-4 flex mx-auto w-full justify-center;

  &__inner {
    width: calc(100% - 32px);
    @screen sm {
      width: 454px;
    }
  }

  &__inner {
    @apply h-6;
    #{$root}__panel {
      width: calc(100% - 64px);
      @screen sm {
        width: 454px;
      }
      @apply fixed border-neutral-01-50 border z-50 h-6 rounded overflow-hidden;
      background: #f1f7ed;
      &-inner {
        background: #badba9;
        @apply h-6 relative;
        &-background {
          @apply w-full h-full absolute bg-repeat;
          background-image: url(~@/assets/images/uploader-progress-background.svg);
        }
      }
      &-labels {
        @apply flex justify-between px-2 items-center text-xs h-full absolute top-0 w-full text-caption-01;
      }
      &-spinner {
        @apply h-4;
        -webkit-animation: spin 3s linear infinite;
        -moz-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
      }
    }
  }
}
</style>
