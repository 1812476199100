<template>
  <div class="set-avatar w-full h-full">
    <LLBreadCrumbs :crumbs="crumbs"></LLBreadCrumbs>
    <div class="people-bg h-full flex flex-col left-0 pt-10">
      <div class="flex-shrink-0 set-avatar__content">
        <div class="UserPhoto flex flex-col items-center relative">
          <h1 class="set-avatar__title mb-6 font-serif text-3xl font-bold text-center">
            {{ $t('hello_name_please_upload_a_selfie_to_get_started', { name }) }}
          </h1>
          <UserImageUploader
            invert-colors
            editable
            :first-name="userObject.firstName"
            :last-name="userObject.lastName"
            :image="userObject.image"
            @update="updateImage"
          ></UserImageUploader>
          <LLButton
            class="set-avatar__done-button"
            :loading="savePending"
            :disabled="!isButtonActive"
            :action="done"
            >{{ $t('done') }}
          </LLButton>
        </div>
      </div>
      <div class="people-bg__content relative">
        <div class="people-bg__content-inner">
          <div class="people-bg__image-wrapper absolute h-full w-full">
            <img class="people-bg__image h-full object-contain" src="@/assets/images/people_bg.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import UserImageUploader from '@/components/common/UserImageUploader'
import { ProspectModel } from '@/models/prospect'
import Api from '@/services/api'
import LLButton from '@/components/common/ui-components/LLButton'

export default {
  name: 'SetAvatar',
  components: { LLButton, UserImageUploader, LLBreadCrumbs },
  data() {
    return {
      savePending: false,
      uploading: false,
      localUser: null
    }
  },
  computed: {
    name() {
      return this.$store.state.user.user.firstName
    },
    crumbs() {
      return [{ name: this.$t('dashboard_label') }]
    },
    userObject() {
      return ProspectModel.parseFromApi({ prospect: this.user })
    },
    user() {
      return this.$store.state.user.user
    },
    isButtonActive() {
      return !!this.userObject.image
    }
  },
  created() {
    this.localUser = this.userObject
  },
  methods: {
    updateImage(image) {
      this.localUser.image = image
    },
    async done() {
      this.savePending = true
      try {
        const { image } = this.localUser
        const newUserData = {
          imageId: image?.id
        }
        const { prospectAccount } = await Api.user.sync({ ...newUserData })
        this.$store.commit('user/UPDATE_USER', { ...prospectAccount })

        await this.$router.push({ name: 'Dashboard' })
      } catch (e) {
        this.savePending = false
        this.$errorAlert(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.set-avatar {
  overflow: hidden;
  min-height: 600px;
  &__done-button {
    @apply my-8;
  }
  &__arrow {
    left: calc(100%);
    top: -55px;
    transform: rotate(12deg);
    width: 90px;
    @screen mob-only {
      transform: rotate(-12deg);
    }
  }

  &__content {
    @apply px-4;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 32px;
  }
}

.people-bg {
  $peopleBg: &;
  &__padding-helper {
  }

  &__content {
    width: 100%;
    height: 300px;
    @screen mob {
      height: 240px;
    }
    @screen mob-only {
      height: 192px;
    }
    &-inner {
      position: absolute;
      right: 50%;
      height: 100%;
      #{$peopleBg}__image-wrapper {
        right: -120px;
        position: relative;
        width: 644px;
        #{$peopleBg}__image {
          height: 340px;
          position: absolute;
          right: 0;
          bottom: 0;
          @screen mob {
            height: 250px;
          }
          @screen mob-only {
            height: 192px;
          }
        }
      }
      /*
      width:100%;
      max-width:1040px;
      height:100%;
      margin:0 auto;
      @apply relative px-5;
      #{$peopleBg}__image-wrapper{
        #{$peopleBg}__image{
          max-height:340px;
          @apply absolute object-top;
        }
      }*/
    }
  }

  @screen mob {
  }
}

.UserPhoto {
  &__loader {
    @apply bg-neutral-01-50;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.upload-button {
  @apply rounded-full flex bg-primary-01-400;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 3px;
  bottom: 3px;
}
</style>
