export const eventNameEnums = {
  CHAT_UPDATE: 'chatUpdate',
  CHAT_META_UPDATE: 'chatMetaUpdate',
  CHAT_MEMBERS_UPDATE: 'chatMembersUpdate',
  CHAT_META_MEMBERS_UPDATE: 'chatMetaMembersUpdate',
  CHAT_NOTE_UPDATE: 'noteUpdate',
  CHAT_NOTE_DELETE: 'noteDelete',
  CHAT_NOTE_CREATE: 'noteCreate',
  CHAT_FILES_UPDATE: 'filesUpdate',
  CHAT_FILE_DELETE: 'filesDelete',
  CHAT_FILES_CREATE: 'filesCreate',
  CHAT_THREAD_CREATE: 'threadCreate',
  CHAT_THREAD_DELETE: 'threadDelete',
  CHAT_THREAD_UPDATE: 'threadUpdate',
  CHAT_THREAD_META_UPDATE: 'threadMetaUpdate',
  REACTIONS_UPDATE: 'reactionsUpdate'
}
