<template>
  <div class="ForumChatThreadCardHeaderTimestamp" :class="classList">
    <LLTimestamp :white="dark" :timestamp="thread.createdAt" strategy="absolute">
      <template #default="data">
        {{ $tc('timestamp_created', data.time) }}
      </template>
    </LLTimestamp>
    <span
      v-if="(thread.isArchived || thread.isHidden) && !compact && !mobile"
      class="ForumChatThreadCardHeaderTimestamp__delimeter"
      >/</span
    >
    <template v-if="thread.isArchived && !compact">
      <LLTimestamp :white="dark" :timestamp="thread.archivedAt" strategy="absolute">
        <template #default="data">
          {{ $tc('timestamp_archived', data.time) }}
        </template>
      </LLTimestamp>
    </template>
    <template v-if="thread.isHidden && !compact">
      <LLTimestamp :white="dark" :timestamp="thread.hiddenAt" strategy="absolute">
        <template #default="data">
          {{ $tc('timestamp_hidden', data.time) }}
        </template>
      </LLTimestamp>
    </template>
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLTimestamp from '@/components/common/LLTimestamp.vue'

export default {
  name: 'ForumChatThreadCardHeaderTimestamp',
  components: { LLTimestamp },
  props: {
    thread: { type: ForumThreadModel, required: true },
    dark: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeaderTimestamp {
  @apply text-body-03 text-neutral-01-400 flex;

  &_mobile {
    @apply flex-col items-end;
  }

  &__delimeter {
    @apply flex-row block mx-1;
  }
}
</style>
