<template>
  <div class="ForumResponseProfile">
    <div class="ForumResponseProfile__common">
      <div v-if="isOwnerVisible" class="ForumResponseProfile__user-name">
        {{ name }}
      </div>
      <div v-else-if="isOwnerHidden" class="ForumResponseProfile__anonymous-name">
        {{ $t('forum.response.anonymous_reply_user_name') }}
      </div>
      <div v-else-if="isOwnerModerator" class="ForumResponseProfile__moderator-name">
        {{ $t('forum.response.moderator_reply_user_name') }}
      </div>
    </div>
    <div v-if="isNoticeVisible" class="ForumResponseProfile__notice">
      <template v-if="isAnonymousNoticeVIsible">
        {{ $t('forum.response.anonymous_reply_label') }}
      </template>
    </div>

    <!--    <div v-if="!isOwnerHidden && !isOwnerModerator" class="ForumResponseHeader__user-info-header">
      <div class="ForumResponseHeader__user-name">
        {{ name }}
      </div>
      <div v-if="response.anonymousState && response.user" class="ForumResponseHeader__user-anon">
        {{ $t('forum.response.moderator_reply_label') }}
      </div>
      <div v-else-if="response.anonymousState && response.prospect" class="ForumResponseHeader__user-anon">
        {{ $t('forum.response.anonymous_reply_label') }}
      </div>
    </div>
    <div v-else-if="isOwnerHidden" class="ForumResponseHeader__user-anonymous-name">
      {{ $t('forum.response.anonymous_reply_user_name') }}
    </div>
    <div v-else-if="isOwnerModerator" class="ForumResponseHeader__user-moderator-name">
      {{ $t('forum.response.moderator_reply_user_name') }}
    </div>-->
  </div>
</template>

<script>
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseProfile',
  props: {
    response: { type: ForumResponseModel, default: null }
  },
  computed: {
    anonymousReplyLetter() {
      return this.$t('forum.response.anonymous_reply_user_name').slice(0, 1)
    },
    isNoticeVisible() {
      return this.isAnonymousNoticeVIsible || this.isModeratorNoticeVIsible
    },
    isModeratorNoticeVIsible() {
      return this.response.anonymousState && this.response.user
    },
    isAnonymousNoticeVIsible() {
      return this.response.anonymousState && this.response.prospect
    },
    isOwnerVisible() {
      return !this.isOwnerHidden && !this.isOwnerModerator
    },
    isOwnerHidden() {
      return (
        this.response.anonymousState &&
        this.response.prospect?.id !== this.$store.getters['user/user']?.id &&
        !this.response.isOwnerModerator
      )
    },
    isOwnerModerator() {
      return (
        this.response.anonymousState &&
        this.response.prospect?.id !== this.$store.getters['user/user']?.id &&
        this.response.isOwnerModerator
      )
    },
    prospect() {
      return this.response?.prospect
    },
    user() {
      return this.response?.user
    },
    name() {
      return (
        (this.response?.prospect
          ? this.response?.prospect?.fullNameWithShortLastName
          : this.response?.user?.fullNameWithShortLastName) || this.$t('not-available')
      )
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseProfile {
  @apply flex items-center;
  &__common {
    @apply text-body-02 min-w-0;
  }
  &__user-name,
  &__anonymous-name {
    @apply text-primary-01-400 truncate;
  }
  &__moderator-name {
    @apply text-accent-01;
  }
  &__notice {
    @apply text-neutral-01-400 text-xs italic ml-1 flex-shrink-0;
  }
}
</style>
