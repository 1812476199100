<template>
  <div class="PollNumber">
    <PollNumberSlider
      v-if="poll.numberStyle === PollStyleEnum.SLIDER"
      :response.sync="localResponse"
      :poll="poll"
      :thread="thread"
      :show-results="showResults"
      :edit-mode="editMode"
      :is-valid.sync="isValid"
      :is-first-time-fill="isFirstTimeFill"
    ></PollNumberSlider>
    <PollNumberStars
      v-if="poll.numberStyle === PollStyleEnum.STARS"
      :response.sync="localResponse"
      :poll="poll"
      :thread="thread"
      :show-results="showResults"
      :edit-mode="editMode"
      :is-valid.sync="isValid"
    ></PollNumberStars>
    <PollNumberValue
      v-if="poll.numberStyle === PollStyleEnum.NUMBER_INPUT"
      :response.sync="localResponse"
      :poll="poll"
      :thread="thread"
      :show-results="showResults"
      :edit-mode="editMode"
      :is-valid.sync="isValid"
    ></PollNumberValue>
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'
import PollNumberSlider from '@/components/modules/forum-chat/poll/number/PollNumberSlider.vue'
import PollNumberStars from '@/components/modules/forum-chat/poll/number/PollNumberStars.vue'
import PollNumberValue from '@/components/modules/forum-chat/poll/number/PollNumberValue.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollNumber',
  components: { PollNumberValue, PollNumberStars, PollNumberSlider },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    showResults: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel(),
      isValid: false
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    }
  },
  watch: {
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
      },
      deep: true
    },
    isValid: {
      handler() {
        this.$emit('update:isValid', this.isValid)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumber {
}
</style>
