<template>
  <svg
    class="icon fill-current"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.30978 18.2134C4.28016 18.2134 0.203125 14.1363 0.203125 9.10666C0.203125 4.07706 4.28016 0 9.30978 0C14.3394 0 18.4165 4.07706 18.4165 9.10666C18.4165 14.1363 14.3394 18.2134 9.30978 18.2134ZM9.30978 0.99069C4.85172 0.99069 1.1938 4.64859 1.1938 9.10666C1.1938 13.5647 4.85172 17.2227 9.30978 17.2227C13.7679 17.2227 17.4258 13.5647 17.4258 9.10666C17.4258 4.61047 13.7679 0.99069 9.30978 0.99069Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.49036 6.2111C6.64278 5.79194 6.8333 5.44905 7.10002 5.18229C7.36673 4.91557 7.70968 4.68696 8.0907 4.53452C8.47174 4.38212 8.92897 4.30591 9.42432 4.30591C9.80536 4.30591 10.1864 4.38211 10.5293 4.49643C10.8723 4.61072 11.1771 4.80124 11.4438 4.99173C11.7105 5.18228 11.901 5.48709 12.0534 5.79193C12.2058 6.09674 12.2821 6.47778 12.2821 6.89693C12.2821 7.43039 12.1678 7.84951 11.9391 8.23056C11.7105 8.61157 11.4057 8.9545 11.0628 9.29744C10.758 9.60225 10.5293 9.83086 10.3769 9.98329C10.1864 10.1357 10.0721 10.3262 9.99587 10.4786C9.91967 10.6311 9.84347 10.8216 9.84347 11.0502C9.80537 11.2788 9.80537 11.4312 9.80537 11.8503H8.92898C8.92898 11.4312 8.92898 11.2407 9.00518 10.974C9.04328 10.6692 9.1576 10.4405 9.2719 10.1738C9.38622 9.94517 9.57674 9.71655 9.76725 9.48794C9.99587 9.25933 10.2245 9.03071 10.5293 8.76397C10.796 8.53536 11.0247 8.26865 11.1771 7.9638C11.3676 7.65899 11.4438 7.31605 11.4438 6.97312C11.4438 6.70638 11.4057 6.43966 11.2914 6.21105C11.1771 5.98244 11.0247 5.79189 10.8723 5.6014C10.7198 5.41088 10.4912 5.29656 10.2245 5.22039C9.99587 5.14416 9.72914 5.06795 9.46242 5.06795C9.08139 5.06795 8.73847 5.14415 8.47174 5.25847C8.1669 5.37279 7.93829 5.56331 7.74779 5.79192C7.55727 6.02053 7.40485 6.28725 7.32863 6.59209C7.25243 6.89691 7.17623 7.16364 7.21433 7.50658H6.33796C6.29986 6.97315 6.33796 6.63021 6.49036 6.21108V6.2111ZM8.77657 12.8792H9.84345V13.946H8.77657V12.8792Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'QuestionIcon'
}
</script>
