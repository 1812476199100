/* This code exports an object with several methods and properties related to a
WebSocket connection for a chat application. It imports some enums and sets up a
connection to a WebSocket server. It also provides methods to connect, disconnect,
trigger events, and add/remove event listeners.

Usage:
import { studyMessages } from '@/utils/modules/studyMessages'
studyMessages.connect({
  token: 'your-token-here'
})
studyMessages.addEvent(eventName, callback)
studyMessages.removeEvent(eventName, callback)

It uses the following events:
- CONVERSATION_STREAM_MESSAGE_UPDATE
- CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED
- CONVERSATION_STREAM_STATE
- QUESTION_VISIBILITY_UPDATE
- NEW_ANSWER_SEND
*/

import { eventNameEnums } from '@/utils/modules/study/enums'

import { Connection } from '@/plugins/conversationWebsocket'
import globalRoutes from '@/utils/globalRoutes'
import { StudyModel } from '@/models/study'
import { TopicModel } from '@/models/topic'
import { QuestionModel } from '@/models/question'
let host = globalRoutes.api?.replace('http', 'ws')

let connection = null
let events = {}

const sendToken = ({ token }) => {
  connection.request('token', token)
}

const runEvents = (eventName, data) => {
  if (events[eventName]) {
    events[eventName].forEach((callback) => callback(data))
  }
}

export default {
  connect({ token, threadId }) {
    events = {}
    connection = new Connection(`${host}/api/pwa/ws`)
    connection.on('_reopen', () => {
      sendToken({ token, threadId })
    })
    connection.on('_open', () => {
      sendToken({ token, threadId })
    })

    connection.on(eventNameEnums.QUESTION_VISIBILITY_UPDATE, ({ questionsToUpdate }) => {
      runEvents(eventNameEnums.QUESTION_VISIBILITY_UPDATE, {
        questionsToUpdate
      })
    })

    connection.on(
      eventNameEnums.CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED,
      ({ conversationMessage: message }) => {
        runEvents(eventNameEnums.CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED, {
          message
        })
      }
    )

    connection.on(
      eventNameEnums.CONVERSATION_STREAM_MESSAGE_UPDATE,
      ({ questionId, studyId, conversationMessage: message }) => {
        runEvents(eventNameEnums.CONVERSATION_STREAM_MESSAGE_UPDATE, {
          message,
          questionId,
          studyId
        })
      }
    )

    connection.on(eventNameEnums.CONVERSATION_STREAM_STATE, ({ questionId, state }) => {
      runEvents(eventNameEnums.CONVERSATION_STREAM_STATE, {
        questionId: questionId,
        state: state
      })
    })

    connection.on(eventNameEnums.PROSPECT_ACCOUNT_UPDATE, ({ prospectAccount }) => {
      runEvents(eventNameEnums.PROSPECT_ACCOUNT_UPDATE, {
        prospectAccount
      })
    })

    connection.on(eventNameEnums.STUDY_UPDATE, ({ study }) => {
      runEvents(eventNameEnums.STUDY_UPDATE, {
        study: StudyModel.parseFromApi({ study })
      })
    })
    connection.on(eventNameEnums.TOPIC_UPDATE, (topic) => {
      runEvents(eventNameEnums.TOPIC_UPDATE, {
        topic: TopicModel.parseFromApi({ topic, studyId: topic.studyId })
      })
    })
    connection.on(eventNameEnums.TOPIC_DELETE, ({ topicId }) => {
      runEvents(eventNameEnums.TOPIC_DELETE, {
        topicId
      })
    })
    connection.on(eventNameEnums.QUESTION_UPDATE, (question) => {
      runEvents(eventNameEnums.QUESTION_UPDATE, {
        question: QuestionModel.parseFromApi({
          question,
          topicId: question.topicId,
          studyId: question.studyId
        })
      })
    })
    connection.on(eventNameEnums.QUESTION_DELETE, ({ questionId }) => {
      runEvents(eventNameEnums.QUESTION_DELETE, {
        questionId
      })
    })
  },
  destroy() {
    if (connection) {
      connection.close()
    }
    events = {}
  },
  trigger(name, data) {
    runEvents(name, data)
  },
  addEvent(name, callback) {
    if (events[name]) {
      events[name].push(callback)
    } else {
      events[name] = [callback]
    }
  },
  removeEvent(name, callback) {
    if (events[name]) {
      events[name].splice(events[name].indexOf(callback), 1)
    }
    if (events[name]?.length === 0) {
      delete events[name]
    }
  },
  enums: eventNameEnums
}
