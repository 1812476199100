<template>
  <LLWordTextContainer class="SubProcessors">
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span>List of our sub-processors:</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c1"><span class="c2"></span></p>
    <table class="c14">
      <tr class="c10">
        <td class="c27 border-t" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">Subprocessor</span></p>
        </td>
        <td class="c27 border-t" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">Purpose</span></p>
        </td>
        <td class="c27 border-t" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">Country</span></p>
        </td>
        <td class="c27 border-t" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">Supplementary Privacy Measures</span></p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Google Oauth</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">User registration</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a
                class="c9"
                href="https://services.google.com/fh/files/misc/safeguards_for_international_data_transfers.pdf"
                >Google - Supplemental Measures
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">AWS</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Database</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://d1.awsstatic.com/Supplementary_Addendum_to_the_AWS_GDPR_DPA.pdf"
                >AWS - Supplemental Measures
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Google LLC</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Communication</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a
                class="c9"
                href="https://services.google.com/fh/files/misc/safeguards_for_international_data_transfers.pdf"
                >Google - Supplemental Measures
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">SendGrid platform - owned by Twilio Inc</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Marketing platform</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://www.twilio.com/legal/bcr">Twilio Binding Corporate Rules </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Google Maps - owned by Google LLC</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Estimate participants geolocation</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://cloud.google.com/terms/data-processing-terms"
                >Google - Data Processing Terms
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">DeepL SE</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Translation</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Germany</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://www.deepl.com/privacy/">DeeplL SE Privacy Policy </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Mailchimp</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Marketing communication</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a
                class="c9"
                href="https://mailchimp.com/legal/data-processing-addendum/#:~:text=per%20calendar%20year.-,6.%20international%20transfers,-6.1%20Data%20center"
                >Mailchimp DPA - International Transfers
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">WordPress</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Website, newsletter</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://automattic.com/privacy/">Automattic Privacy Policy </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Rybbon, Inc.</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Reward platform</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a
                class="c9"
                href="https://www.rybbon.net/dataprotection/#:~:text=the%20Security%20Program.-,ATTACHMENT%202,UNION%20STANDARD%20CONTRACTUAL%20CLAUSES,-%5BCONTROLLER%20TO%20PROCESSOR"
                >Rybbon SCCs
              </a></span
            >
          </p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Slack INC</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">Communication, File sharing</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26"><span class="c2">US</span></p>
        </td>
        <td class="c27" colspan="1" rowspan="1">
          <p class="c26">
            <span class="c7"
              ><a class="c9" href="https://slack.com/blog/news/slack-updates-data-processing-addendum"
                >Slack SCCs, Security measures
              </a></span
            >
          </p>
        </td>
      </tr>
    </table>

    <hr style="page-break-before: always; display: none" />
    <p class="c1"><span class="c2"></span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c4"><span>We collect the following information from and about you:</span></p>
    <p class="c1"><span class="c2"></span></p>
    <p class="c1"><span class="c2"></span></p>
    <table class="c14">
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Demographic data</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c25"><span class="c12 c8">We use this data to</span></p>
        </td>
      </tr>
      <tr class="c23">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Name</span></p>
          <p class="c0"><span class="c2">Telephone number</span></p>
          <p class="c0"><span class="c2">City, state, and country</span></p>
          <p class="c0"><span class="c2">Email address</span></p>
          <p class="c0"><span class="c2">Job Title</span></p>
          <p class="c0"><span class="c2">Company</span></p>
          <p class="c0"><span class="c2">Education</span></p>
          <p class="c0"><span class="c2">Income bracket</span></p>
          <p class="c0"><span class="c2">Marital status</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Conduct studies</span></p>
          <p class="c0">
            <span class="c2">Communicate with clients and prospects (email, contact forms, WeChat)</span>
          </p>
          <p class="c0">
            <span class="c2">Enable customers to use our research platform</span>
          </p>
          <p class="c0">
            <span class="c2">Resolve issues</span>
          </p>
          <p class="c0"><span class="c2">Offer customer support</span></p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
          <p class="c0">
            <span class="c8">Legal basis</span
            ><span class="c2">: consent, contract, legitimate interest</span>
          </p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Preferences</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c6">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Hobbies</span></p>
          <p class="c0"><span class="c2">Interests</span></p>
          <p class="c0"><span class="c2">Shopping habits</span></p>
          <p class="c0"><span class="c2">Brand preference</span></p>
          <p class="c0"><span class="c2">Purchase history</span></p>
          <p class="c0"><span class="c2">Other topics relevant for the study</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0">
            <span class="c2">Conduct studies</span>
          </p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
          <p class="c0"><span class="c8">Legal basis</span><span class="c2">: consent</span></p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c8 c12">Media</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c19">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Photographs</span></p>
          <p class="c0"><span class="c2">Videos</span></p>
          <p class="c0"><span class="c2">Voice</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Conduct studies</span></p>
          <p class="c0"><span class="c2">&nbsp;</span></p>
          <p class="c0"><span class="c8">Legal basis</span><span class="c2">: consent</span></p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Communication</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c24">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Participant interviews</span></p>
          <p class="c0">
            <span class="c2">General inquiries (email or contact for customer requests)</span>
          </p>
          <p class="c0"><span class="c2">Customer support</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0">
            <span class="c2">Conduct studies<br /><br /></span>
          </p>
          <p class="c0">
            <span class="c2">Communicate with clients and prospects (email, contact forms, WeChat)</span>
          </p>
          <p class="c0"><span class="c2">Offer customer support</span></p>
          <p class="c0"><span class="c2">Distribute rewards</span></p>
          <!-- <p class="c0"><span class="c2">&nbsp;</span></p> -->
          <p class="c0">
            <span class="c8">Legal basis</span><span class="c2">: consent, legitimate interest</span>
          </p>
        </td>
      </tr>
      <tr class="c10">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c12 c8">Technology</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c13">
        <td class="c11" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">City geolocation</span></p>
          <p class="c0"><span class="c2">IP address</span></p>
          <p class="c0"><span class="c2">Device and browser data</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1">
          <p class="c0"><span class="c2">Conduct studies</span></p>
          <p class="c0">
            <span class="c2">Enable customers to use our research platform</span>
          </p>
          <p class="c0"><span class="c2">Improve our products and services</span></p>
          <p class="c0"><span class="c2">Resolve issues</span></p>
          <p class="c0">
            <span>Secure platform<br /><br /></span><span class="c8">Legal basis</span
            ><span class="c2">: consent, legitimate interest</span>
          </p>
        </td>
      </tr>
    </table>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'SubProcessorsEN',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.SubProcessors {
  max-width: 730px;
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_2-7 > li:before {
    content: '\0025cb  ';
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_2-5 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-8 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }

  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-2 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_1-4 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-7 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-5 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_list_2-1 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_list_1-8 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-2 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
  }

  ol {
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c11 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c5 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 318pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c3 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c20 {
    padding-top: 0pt;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c16 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c25 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c12 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }

  .c21 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c0 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c7 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }

  .c14 {
    margin-left: -5pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c17 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c15 {
    padding: 0;
  }

  .c9 {
    color: inherit;
    text-decoration: inherit;
  }

  .c13 {
    height: 78.2pt;
  }

  .c8 {
    font-weight: 700;
  }

  .c6 {
    height: 165.2pt;
  }

  .c22 {
    background-color: #ffff00;
  }

  .c18 {
    margin-left: 36pt;
  }

  .c24 {
    height: 115.8pt;
  }

  .c19 {
    height: 75.2pt;
  }

  .c23 {
    height: 241.8pt;
  }

  .c10 {
    height: 24.2pt;
  }

  .c26 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c27 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-right-width: 1pt;
    border-left-color: #000000;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    padding-bottom: 16pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
  }

  p {
    color: #000000;
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    padding-bottom: 4pt;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
