<template>
  <div class="LLFileModalImage flex items-center justify-center">
    <div v-if="!loaded && !loadingError" class="LLFileModalImage__loader-wrapper">
      <LoadingIcon></LoadingIcon>
    </div>
    <transition name="fade">
      <img
        v-if="loaded && !loadingError"
        class="LLFileModalImage__image cursor-pointer"
        :src="src"
        @click="previewImgObject"
      />
    </transition>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from 'v-viewer'
export default {
  name: 'LLFileModalImage',
  components: { LoadingIcon },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      loadingError: false
    }
  },
  computed: {
    isTemporary() {
      return !!this.file?.temporary || !!this.file?.isTemporary
    },
    tempSrc() {
      return URL.createObjectURL(this.file.temporaryData.browserFile)
    },
    src() {
      return this.isTemporary ? this.tempSrc : this.file.location
    }
  },
  mounted() {
    this.tryLoad()
  },
  methods: {
    tryLoad() {
      const newImg = new Image()
      newImg.src = this.src
      newImg.addEventListener('load', () => {
        this.widthLargerThanHeight = newImg.width > newImg.height
        this.imageLoaded()
        newImg.remove()
      })
      newImg.addEventListener('error', () => {
        this.loadingError = true
      })
    },
    imageLoaded() {
      this.loaded = true
    },
    previewImgObject() {
      const images = []
      images.push({ src: this.src, 'data-source': this.src })
      // console.log('images', images)
      viewerApi({
        options: {
          toolbar: false,
          navbar: false,
          title: false,
          url: 'data-source'
        },
        images
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileModalImage {
  .LLFileModalImage__image {
    max-width: 100%;
    max-height: 560px;
    @apply object-contain object-center;
    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 0.2s;
    }
    &.fade-enter, &.fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
      opacity: 0;
    }
  }
  &__loader-wrapper {
    @screen md {
      width: 430px;
      height: 560px;
    }
    @apply flex items-center justify-center;
  }
}
</style>
