<template>
  <div
    class="LLRadioIcon"
    :class="{
      LLRadioIcon_checked: isChecked,
      LLRadioIcon_disabled: disabled,
      LLRadioIcon_hovered: isHovered
    }"
  >
    <div class="LLRadioIcon__hover"></div>
    <div class="LLRadioIcon__border"></div>
    <div class="LLRadioIcon__icon"></div>
  </div>
</template>

<script>
export default {
  name: 'LLRadioIcon',
  props: {
    isChecked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isHovered: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.LLRadioIcon {
  $radio: &;
  width: 18px;
  height: 18px;
  @apply relative;

  &__hover {
    @apply w-8 h-8 bg-primary-01-50 opacity-50 rounded-full absolute transform scale-0 transition duration-200;
    margin-left: -7px;
    margin-top: -7px;
  }

  &__border {
    width: 18px;
    height: 18px;
    @apply border-primary-01-400 bg-primary-01-400 border transition duration-200 absolute rounded-full;
  }

  &__icon {
    width: 16px;
    height: 16px;
    @apply absolute left-0 top-0 bg-white ml-px mt-px rounded-full transition duration-200;
  }

  &_hovered {
    #{$radio}__hover {
      @apply scale-100;
    }
  }

  &_disabled {
    #{$radio}__hover {
      @apply hidden;
    }
    #{$radio}__border {
      @apply border-neutral-01-50;
    }

    &#{$radio}_checked {
      #{$radio}__border {
        /*@apply bg-neutral-01-25;*/
      }

      #{$radio}__icon {
        @apply text-neutral-01-50;
      }
    }
  }

  &_checked {
    #{$radio}__border {
      @apply border-accent-01 bg-accent-01;
    }
    #{$radio}__icon {
      @apply scale-50 transform;
    }
  }
}
</style>
