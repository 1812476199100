var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ForumChatThreadCardActions"},[(_vm.showActions)?_c('LLOptionsList',{staticClass:"ForumChatThreadCardActions__button",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('LLButton',{attrs:{"loading":_vm.isActionPending,"small":"","tertiary-white":_vm.dark,"tertiary-dark":!_vm.dark},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('OptionsIcon')]},proxy:true}],null,false,4285320686)})]},proxy:true},{key:"buttons",fn:function(){return [(_vm.permissions.pin)?_c('LLOptionsListButton',{directives:[{name:"popper-close",rawName:"v-popper-close"}],attrs:{"small":""},on:{"click":function($event){return _vm.updatePinnedState(!_vm.thread.isPinned)}}},[(!_vm.thread.isPinned)?[_vm._v(" "+_vm._s(_vm.$t('forum.thread.actions.pin')))]:[_vm._v(" "+_vm._s(_vm.$t('forum.thread.actions.unpin')))]],2):_vm._e(),(_vm.permissions.edit && _vm.thread.isActive)?_c('router-link',{attrs:{"to":_vm.linkToEditor}},[_c('LLOptionsListButton',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t('button_edit'))+" ")])],1):_vm._e(),(
          _vm.permissions.edit &&
          (_vm.thread.state === _vm.forumThreadState.ARCHIVED || _vm.thread.state === _vm.forumThreadState.HIDDEN)
        )?_c('LLOptionsListButton',{directives:[{name:"popper-close",rawName:"v-popper-close"}],attrs:{"small":""},on:{"click":function($event){return _vm.updateState(_vm.forumThreadState.ACTIVE)}}},[_vm._v(" "+_vm._s(_vm.$t('forum.thread.actions.activate'))+" ")]):_vm._e(),(
          _vm.permissions.edit &&
          (_vm.thread.state === _vm.forumThreadState.ACTIVE || _vm.thread.state === _vm.forumThreadState.HIDDEN)
        )?_c('LLOptionsListButton',{directives:[{name:"popper-close",rawName:"v-popper-close"}],attrs:{"small":""},on:{"click":function($event){return _vm.updateState(_vm.forumThreadState.ARCHIVED)}}},[_vm._v(" "+_vm._s(_vm.$t('forum.thread.actions.archive'))+" ")]):_vm._e(),(
          _vm.permissions.edit &&
          (_vm.thread.state === _vm.forumThreadState.ACTIVE || _vm.thread.state === _vm.forumThreadState.ARCHIVED) &&
          false
        )?_c('LLOptionsListButton',{directives:[{name:"popper-close",rawName:"v-popper-close"}],attrs:{"small":""},on:{"click":function($event){return _vm.updateState(_vm.forumThreadState.HIDDEN)}}},[_vm._v(" "+_vm._s(_vm.$t('forum.thread.actions.hide'))+" ")]):_vm._e(),(_vm.permissions.remove)?_c('LLOptionsListButton',{directives:[{name:"popper-close",rawName:"v-popper-close"}],attrs:{"small":""},on:{"click":function($event){_vm.isRemoveConfirmationOpened = true}}},[_vm._v(" "+_vm._s(_vm.$t('button_remove'))+" ")]):_vm._e()]},proxy:true}],null,false,1787372312)}):_vm._e(),_c('portal',{attrs:{"to":"confirm-modal"}},[(_vm.isRemoveConfirmationOpened)?_c('LLConfirmModalTransition',[_c('LLConfirmModal',{attrs:{"max-width":600},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('forum.thread.remove_confirmation_modal.title')))]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('forum.thread.remove_confirmation_modal.message'))+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('LLButton',{attrs:{"loading":_vm.isRemovePending,"primary":"","small":""},on:{"click":_vm.remove}},[_vm._v(_vm._s(_vm.$t('button_remove')))]),_c('LLButton',{attrs:{"disabled":_vm.isRemovePending,"secondary":"","small":""},on:{"click":function($event){_vm.isRemoveConfirmationOpened = false}}},[_vm._v(_vm._s(_vm.$t('button_cancel')))])]},proxy:true}],null,false,1900388473)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }