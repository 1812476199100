<template>
  <ValidationProvider v-slot="{ errors }" tag="div" :rules="rules" :name="name">
    <div
      :class="[
        {
          'll-checkbox_locked': locked && !shouldBeChecked,
          white: white,
          'll-checkbox_checked': shouldBeChecked
        },
        `ll-checkbox_${styleClass}`
      ]"
      class="ll-checkbox min-w-0 w-full rounded"
    >
      <label class="ll-checkbox__label items-start">
        <div class="flex-shrink-0 mt-0.5">
          <input
            v-model="innerValue"
            class="ll-checkbox__input"
            type="checkbox"
            :value="value"
            :checked="shouldBeChecked"
            @change="updateInput"
          />
          <span class="ll-checkbox__icon" :class="{ 'is-checked': shouldBeChecked }">
            <CheckedIcon />
          </span>
        </div>
        <div class="ll-checkbox__text text-base text-primary-01">
          <slot />
        </div>
      </label>
      <div v-if="errors[0]" class="text-error-01 text-xs mt-2">
        {{ errors[0] }}
      </div>
      <div v-if="validationError" class="text-error-01 text-xs mt-2">
        {{ validationError }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import CheckedIcon from '@/assets/icons/CheckedIcon'

export default {
  name: 'LLCheckbox',
  components: { CheckedIcon },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    errors: { type: Array, default: () => [] },
    value: { type: [String, Number], default: '' },
    modelValue: { type: null, default: false },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    rules: { type: [Object, String], default: '' },
    name: { type: String, required: false, default: '' },
    white: { type: Boolean, default: false },
    styleAnswer: { type: Boolean, default: false }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    validationError() {
      return this.errors?.length ? this.errors[0] : null
    },
    shouldBeChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue === this.trueValue
    },
    styleClass() {
      if (this.styleAnswer) {
        return 'style-answer'
      } else {
        return 'style-white'
      }
    }
  },
  watch: {
    shouldBeChecked: {
      immediate: true,
      handler(to) {
        this.innerValue = to
      }
    }
  },
  methods: {
    updateInput(event) {
      if (this.locked) {
        event.preventDefault()
        return
      }
      const isChecked = event.target.checked

      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ll-checkbox {
  $root: &;

  font-size: 0;

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  &__text {
    @apply min-w-0;
  }

  &#{$root}_locked {
    .ll-checkbox__text {
      @apply text-neutral-01-400;
    }
  }

  &_style-white {
    #{$root}__label {
      @apply w-full inline-flex cursor-pointer;
    }

    #{$root}__label {
      @apply w-full inline-flex cursor-pointer;
    }
    #{$root}__icon {
      @apply inline-flex mr-3 rounded text-white border border-neutral-01 transition duration-200 shadow;
      width: 18px;
      height: 18px;

      svg {
        @apply m-auto transition duration-200;
        width: 10px;
        height: 8px;
        opacity: 0;
      }

      &.is-checked {
        svg {
          opacity: 1;
        }

        box-shadow: 0px 2px 3px rgba(#000, 0.1);
        @apply bg-accent-01 border-transparent;
      }
    }
  }

  &_style-answer {
    @apply p-1.5;

    &#{$root}_checked {
      @apply bg-neutral-01-15;
    }
    &#{$root}_locked {
      .ll-checkbox__text {
        @apply text-neutral-01-400;
      }
    }

    #{$root}__label {
      @apply w-full inline-flex cursor-pointer;
    }

    #{$root}__icon {
      @apply inline-flex mr-3 rounded text-white border border-neutral-01 transition duration-200 shadow;
      width: 18px;
      height: 18px;

      svg {
        @apply m-auto transition duration-200;
        width: 10px;
        height: 8px;
        opacity: 0;
      }

      &.is-checked {
        svg {
          opacity: 1;
        }

        box-shadow: 0px 2px 3px rgba(#000, 0.1);
        @apply bg-accent-01 border-transparent;
      }
    }
  }

  &_white {
  }
}
</style>
