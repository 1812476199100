<template>
  <div class="SearchEmptyFilter" :class="{ SearchEmptyFilter_pending: pendingFilters }">
    <LLFilter :cleared="cleared" @clear="clearFilter" @apply="applyFilter">
      <LLFilterItem>
        <LLFilterChevron>
          <template #label> {{ $t('search-page_filter_channel') }}</template>
          <LLCheckboxList
            :key-fn="(item) => item.id"
            :reduce="(item) => item.id"
            :label="(item) => item.label"
            :selected.sync="localFiltersValues.teamId"
            :values="filterData.teams"
            @checkboxChange="fetchFilters"
          ></LLCheckboxList>
          <LLEmptyState v-if="!filterData.teams.length">
            {{ $tc('common_amount_teams', 0) }}
          </LLEmptyState>
        </LLFilterChevron>
      </LLFilterItem>
      <LLFilterItem>
        <LLFilterChevron :inactive="!studyCheckboxesAreActive">
          <template #label> {{ $t('search-page_filter_study') }}</template>
          <LLCheckboxList
            :key-fn="(item) => item.id"
            :reduce="(item) => item.id"
            :label="(item) => item.id + ': ' + item.label"
            :selected.sync="localFiltersValues.studyId"
            :values="filterData.studies"
          ></LLCheckboxList>
          <LLEmptyState v-if="!filterData.studies.length">
            {{ $tc('common_amount_studies', 0) }}
          </LLEmptyState>
        </LLFilterChevron>
      </LLFilterItem>
      <LLFilterItem v-if="allowToSearchByChats">
        <LLFilterChevron :inactive="!chatCheckboxesAreActive">
          <template #label> {{ $t('search-page_filter_chat') }}</template>
          <LLCheckboxList
            :key-fn="(item) => item.id"
            :reduce="(item) => item.id"
            :label="(item) => item.id + ': ' + item.label"
            :selected.sync="localFiltersValues.chatId"
            :values="filterData.chats"
          ></LLCheckboxList>
          <LLEmptyState v-if="!filterData.chats.length">
            {{ $tc('common_amount_chats', 0) }}
          </LLEmptyState>
        </LLFilterChevron>
      </LLFilterItem>
    </LLFilter>
  </div>
</template>

<script>
import LLFilter from '@/components/pages/search/filter/LLFilter'
import LLFilterItem from '@/components/pages/search/filter/LLFilter/LLFilterItem'
import LLFilterChevron from '@/components/pages/search/filter/LLFilter/LLFilterChevron'
import { FilterHelper } from '@/utils/filterLabels'
import LLCheckboxList from '@/components/common/LLCheckboxList'
import LLEmptyState from '@/components/common/ui-components/LLEmptyState.vue'

export default {
  name: 'SearchEmptyFilter',
  components: { LLEmptyState, LLCheckboxList, LLFilterChevron, LLFilterItem, LLFilter },
  initFilters: { type: Object, default: null },
  data() {
    return {
      pendingFilters: [],
      fetchFiltersPromise: null,
      localFiltersValues: {
        studyId: [],
        teamId: [],
        chetId: []
      },
      filterData: {
        teams: [],
        studies: [],
        chats: []
      }
    }
  },
  computed: {
    allowToSearchByChats() {
      return true
    },
    cleared() {
      // TODO: match this to filters
      return !!(!this.localFiltersValues.teamId.length && !this.localFiltersValues.studyId.length)
    },
    studyCheckboxesAreActive() {
      return !!this.localFiltersValues.teamId?.length
    },
    chatCheckboxesAreActive() {
      return !!this.localFiltersValues.teamId?.length
    },
    filterLabels() {
      const filterHelper = new FilterHelper()
      filterHelper.addCheckboxList('chatId', this.filterData.chats, () => {
        this.fetchFilters()
      })
      filterHelper.addCheckboxList('studyId', this.filterData.studies, () => {
        this.fetchFilters()
      })
      filterHelper.addCheckboxList('teamId', this.filterData.teams, () => {
        this.fetchFilters()
      })
      return filterHelper.getLabels({ filterValues: this.localFiltersValues })
    }
  },
  watch: {
    localFiltersValues: {
      handler() {
        this.applyFilter()
      },
      deep: true
    },
    filterLabels: {
      handler() {
        this.$emit('updateFilterLabels', { filterLabels: this.filterLabels })
      }
    }
  },
  created() {
    this.fetchFilters()
  },
  methods: {
    fetchFilters() {
      this.pendingFilters = true

      this.fetchFiltersPromise = this.$api.search.getResponseFilters({
        chatId: this.localFiltersValues.chatId,
        studyId: this.localFiltersValues.studyId,
        teamId: this.localFiltersValues.teamId
      })

      this.fetchFiltersPromise
        .then((result) => {
          this.filterData.teams = result.teams
            ? result.teams.map((team) => ({ id: team.id, label: team.name }))
            : []
          this.localFiltersValues.teamId = this.localFiltersValues.teamId.filter((teamId) =>
            this.filterData.teams.find((t) => t.id === teamId)
          )

          this.filterData.studies = result.studies
            ? result.studies.map((study) => ({ id: study.id, label: study.name }))
            : []
          this.localFiltersValues.studyId = this.localFiltersValues.studyId.filter((studyId) =>
            this.filterData.studies.find((s) => s.id === studyId)
          )

          this.filterData.chats = result.chats
            ? result.chats.map((chat) => ({ id: chat.id, label: chat.name }))
            : []
          this.localFiltersValues.chatId = this.localFiltersValues.chatId.filter((chatId) =>
            this.filterData.chats.find((chat) => chat.id === chatId)
          )
          this.pendingFilters = false
        })
        .catch(() => {
          this.pendingFilters = false
        })
    },

    clearFilter() {
      if (!this.study) {
        this.localFiltersValues.teamId = []
        this.localFiltersValues.studyId = []
        this.localFiltersValues.chatId = []
      }
      this.applyFilter()
    },
    applyFilter() {
      this.$emit('apply', this.localFiltersValues)
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchEmptyFilter {
  &_pending {
    @apply opacity-50 pointer-events-none select-none;
  }
}
</style>
