<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#9DD470" />
  </svg>
</template>

<script>
export default {
  name: 'RepliedIcon'
}
</script>

<style lang="scss" scoped>
.RepliedIcon {
}
</style>
