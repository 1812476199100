<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.2 0.714844H4.8V5.51484H0V7.91484H4.8V12.7148H7.2V7.91484H12V5.51484H7.2V0.714844Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon'
}
</script>
