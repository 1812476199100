<template>
  <div class="LiveConversationDescription">
    <div class="flex items-center xl-max:hidden mob:items-center">
      <LLRoundProgress
        class="mr-10 mob:mr-0 mob:mb-6"
        :internal="answeredPercents"
        :external="completedPercents"
        small
      >
        <div class="text-primary-01-400">
          <div class="LiveConversationDescription__profile-progress-percents text-center font-semibold">
            {{ answeredPercents }}<span class="text-sm">%</span>
          </div>
        </div>
      </LLRoundProgress>
      <StudyProgress
        class="w-full"
        :answered="answered"
        :completed="completed"
        :not-started="notStarted"
        :all="allQuestions"
      ></StudyProgress>
    </div>

    <LLLineProgress
      class="cursor-pointer mob:mb-5 mb-3 xl:hidden"
      :progress-list="lineTopicsProgress"
    ></LLLineProgress>

    <div class="items-center hidden xl-max:flex md:flex-col md:items-center">
      <StudyProgress
        :answered="answered"
        :completed="completed"
        :not-started="notStarted"
        :all="allQuestions"
        progress-line
        data-e2e="liveStudyProgress"
      ></StudyProgress>
    </div>

    <div class="flex mob:w-full mt-8 mob:mt-3">
      <router-link tag="a" :to="`/study/${study.id}`" class="mob:w-full">
        <LLButton class="px-2 mob:w-full" data-e2e="liveStudyBtn"
          >{{ $t('conversation_jump_into_conversation_button') }}
        </LLButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import LLRoundProgress from '@/components/common/ui-components/LLRoundProgress'
import StudyProgress from '@/components/study/StudyProgress'
import LLLineProgress from '@/components/common/ui-components/LLLineProgress'
import LLButton from '@/components/common/ui-components/LLButton'

export default {
  name: 'LiveConversationDescription',
  components: { LLButton, LLLineProgress, StudyProgress, LLRoundProgress },
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    answered() {
      return this.study.meta.answeredStreamCount
    },
    completed() {
      return this.study.meta.completedStreamCount
    },
    notStarted() {
      return this.study.meta.notAnsweredStreamCount
    },
    allQuestions() {
      return this.answered + this.completed + this.notStarted || 0
    },
    answeredPercents() {
      return this.allQuestions ? Math.ceil(((this.answered + this.completed) / this.allQuestions) * 100) : 0
    },
    completedPercents() {
      return this.allQuestions ? Math.round((this.completed / this.allQuestions) * 100) : 0
    },
    lineTopicsProgress() {
      return [
        {
          color: 'answered-01',
          progress: this.answeredPercents,
          name: 'inProgress'
        },
        {
          color: 'success-01',
          progress: this.completedPercents,
          name: 'completed'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.LiveConversationDescription {
  &__profile-progress-percents {
    font-size: 32px;
  }
}
</style>
