import { PollType } from '@/common/enums'
import { PollResponseModel } from '@/models/pollResponse'

const pollResponseParser = (serverPollResponses, pollType) => {
  if (pollType === PollType.NUMBER_ENTRY) {
    return new PollResponseModel({ numberValue: serverPollResponses[0]?.value })
  } else if (pollType === PollType.RANKED_LIST || pollType === PollType.MULTIPLE_CHOICE) {
    return new PollResponseModel({
      choiceList: [...serverPollResponses]
        .sort((a, b) => a.order - b.order)
        .map((serverResponse) => serverResponse.rowId)
    })
  } else if (pollType === PollType.MATRIX) {
    const rows = {}

    serverPollResponses.forEach((serverResponse) => {
      if (!rows[serverResponse.rowId]) rows[serverResponse.rowId] = []
      rows[serverResponse.rowId].push(serverResponse.columnId)
    })

    return new PollResponseModel({
      matrixList: Object.keys(rows).map((rowId) => ({ id: rowId, columnIdList: rows[rowId] }))
    })
  }
}

export { pollResponseParser }
