<template>
  <div class="UserImageUploader" :class="classes">
    <div class="UserImageUploader__wrapper">
      <ImageLoader
        v-if="showImage"
        empty-picture="slot"
        large
        class="UserImageUploader__image"
        :file="tempImage"
      >
        <template #empty-picture>
          <div class="UserImageUploader__empty">
            <h2 class="UserImageUploader__empty-text">{{ name }}</h2>
          </div>
        </template>
      </ImageLoader>
      <div v-if="showEmpty" class="UserImageUploader__empty">
        <h2 class="UserImageUploader__empty-text">{{ name }}</h2>
      </div>
      <div v-if="uploading" class="UserImageUploader__loading">
        <LoadingIcon></LoadingIcon>
      </div>
    </div>
    <LLAvatarCropperWrapper
      v-show="!uploading && editable"
      :multiple="false"
      file-mime-type="image/*,image/heic"
      data-e2e="imageUpload"
      @startUpload="onStartUpload"
      @upload="onUpload"
    >
      <div class="UserImageUploader__upload-button">
        <PhotoIcon class="UserImageUploader__upload-button-icon"></PhotoIcon>
      </div>
    </LLAvatarCropperWrapper>
    <!--    <LLFileUploaderWrapper
      v-show="!uploading && editable"
      :multiple="false"
      file-mime-type="image/*,image/heic"
      data-e2e="imageUpload"
      @startUpload="onStartUpload"
      @upload="onUpload"
    >
      <div class="UserImageUploader__upload-button">
        <PhotoIcon class="UserImageUploader__upload-button-icon"></PhotoIcon>
      </div>
    </LLFileUploaderWrapper>-->
  </div>
</template>

<script>
import { getTwoLetters } from '@/utils/stringUtils'
import PhotoIcon from '@/assets/icons/PhotoIcon'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
import LoadingIcon from '@/components/common/LoadingIcon'
import LLAvatarCropperWrapper from '@/components/common/LLAvatarCropper/LLAvatarCropperWrapper'
export default {
  name: 'UserImageUploader',
  components: { LLAvatarCropperWrapper, LoadingIcon, ImageLoader, PhotoIcon },
  props: {
    editable: { type: Boolean, default: false },
    firstName: { type: [String, null], default: '' },
    lastName: { type: [String, null], default: '' },
    string: { type: [String, null], default: null },
    image: { type: [Object, null], default: null },
    small: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    invertColors: { type: Boolean, default: false }
  },
  data() {
    return {
      tempImage: null,
      uploading: false
    }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'UserImageUploader'
      if (this.uploading) {
        classes.push(`${rootClass}_uploading`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      if (this.medium) {
        classes.push(`${rootClass}_medium`)
      }
      if (this.invertColors) {
        classes.push(`${rootClass}_inverted-colors`)
      }
      return classes
    },
    name() {
      let str
      if (this.string) {
        str = getTwoLetters(this.string)
      } else {
        str = getTwoLetters([this.firstName, this.lastName].join(' '))
      }
      if (str?.length) {
        return str
      } else {
        return this.$t('recruiting_details_not_available')
      }
    },
    showImage() {
      return !!this.tempImage
    },
    showEmpty() {
      return !this.tempImage
    },
    showLoading() {
      return false
    }
  },
  watch: {
    image: {
      handler() {
        this.tempImage = this.image ? { ...this.image } : null
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {
    onStartUpload() {
      this.uploading = true
    },
    onUpload(file) {
      this.tempImage = file
      this.$emit('update', { ...this.tempImage })
      this.uploading = false
    },
    onError() {
      this.uploading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.UserImageUploader {
  $root: &;
  @apply relative;
  &_uploading {
    #{$root}__image {
      filter: blur(5px);
    }
    #{$root}__empty {
      filter: blur(5px);
    }
  }
  &_inverted-colors {
    #{$root}__empty {
      @apply bg-accent-01;
    }
    #{$root}__upload-button {
      @apply bg-primary-01-400;
    }
  }
  &__loading {
    @apply absolute w-full h-full flex items-center justify-center top-0 left-0;
  }
  &_small {
    #{$root}__wrapper {
      @apply w-8 h-8;
    }
    #{$root}__empty {
      &-text {
        @apply text-caption-02;
      }
    }
  }
  &_medium {
    #{$root}__wrapper {
      @apply w-14 h-14;
    }
    #{$root}__empty {
      &-text {
        @apply text-body-01;
        font-size: 18px;
      }
    }
  }
  &__wrapper {
    width: 120px;
    height: 120px;
    @apply rounded-full overflow-hidden bg-neutral-01-15;
  }
  &__empty {
    @apply w-full h-full text-white flex items-center justify-center transition duration-300 bg-neutral-01-400;
    &-text {
    }
  }
  &__upload-button {
    @apply bg-accent-01-400 w-6 h-6 flex items-center justify-center absolute right-0 bottom-0 mr-2 mb-2 rounded-full cursor-pointer;
    &-icon {
      @apply text-white w-4 h-4;
    }
  }
  &__image {
    @apply w-full h-full object-cover;
  }
}
</style>
