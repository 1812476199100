<template>
  <div class="InvitedConversation">
    <div v-if="study.studyInstructions">
      <div class="font-semibold text-base color-primary-01 leading-tight">
        {{ $t('description') }}
      </div>
      <LLWysiwygOutput
        class="text-base mt-2 color-primary-01 four-lines"
        :text="studyInstructions"
      ></LLWysiwygOutput>
    </div>
    <!--    <router-link
      tag="a"
      :to="`/study/${study.id}`"
      class="font-semibold block text-primary-01-400 mt-4 underline"
      >{{ $t('tell_me_more') }}
    </router-link>-->
    <StudyButtons :study="study" @studyUpdate="onStudyUpdate"></StudyButtons>
  </div>
</template>

<script>
import StudyButtons from '@/components/study/StudyButtons'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput'

export default {
  name: 'InvitedConversationDescription',
  components: { LLWysiwygOutput, StudyButtons },
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    studyInstructions() {
      return this.study?.studyInstructions
    }
  },
  methods: {
    onStudyUpdate({ study }) {
      this.$emit('studyUpdate', { study })
    }
  }
}
</script>

<style lang="scss" scoped>
.InvitedConversation {
}
</style>
