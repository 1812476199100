<template>
  <div class="LLLocationInput">
    <ValidationProvider v-slot="{ errors }" :rules="validationRules" immediate>
      <input v-model="locationString" type="hidden" />
      <LLInputCaption><slot name="caption">Location</slot></LLInputCaption>
      <LLInputSuggestions
        :suggestions="suggestions"
        :key-fn="(v) => v.key"
        :caption-fn="(v) => v.address"
        @select="selectSuggestion"
      >
        <template #input>
          <LLInputText
            v-model="tempLocationString"
            :errors="errors"
            :disabled="getPlaceWaiting"
            :placeholder="placeholder"
            :with-cleaner="showCleaner"
            :cleaner-function="clear"
            @blur="onBlur"
          ></LLInputText>
        </template>
      </LLInputSuggestions>
    </ValidationProvider>
  </div>
</template>

<script>
import _ from 'lodash'
import { ValidationProvider } from 'vee-validate'
import LLInputSuggestions from '@/components/common/LLInput/LLInputSuggestions'
import LLInputText from '@/components/common/LLInput/LLInputText'
import ParticipantUtils from '@/utils/participantUtils'
import Api from '@/services/api'
import LLInputCaption from '@/components/common/LLInput/LLInputCaption'

export default {
  name: 'LLLocationInput',
  components: { LLInputCaption, LLInputText, LLInputSuggestions, ValidationProvider },
  props: {
    validationRules: { type: String, default: '' },
    location: { type: [Object, null], default: null },
    placeholder: { type: String, default: '' },
    withCleaner: { type: Boolean, default: false }
  },
  data() {
    return {
      tempLocationString: '',
      suggestions: [],
      tempLocation: {
        city: null,
        region: null,
        country: null,
        placeId: null
      },
      getPlaceWaiting: false
    }
  },
  computed: {
    locationString() {
      return ParticipantUtils.getLocation(this.tempLocation)
    },
    hasCaptionSlot() {
      return !!this.$slots?.caption
    },
    showCleaner() {
      return !!(
        (this.tempLocation.city || this.tempLocation.region || this.tempLocation.country) &&
        this.withCleaner
      )
    }
  },
  watch: {
    location: {
      handler(value) {
        this.copyValueLocation(value)
      },
      deep: true
    },
    tempLocationString(value) {
      if (!value) {
        this.suggestions = []
        this.loadLocationsDebounced(null)
      } else if (value !== this.locationString) {
        this.loadLocationsDebounced(this.tempLocationString)
      }
    },
    tempLocation: {
      handler() {
        this.tempLocationString = this.locationString
      },
      deep: true
    }
  },
  created() {
    this.copyValueLocation()
  },
  methods: {
    clear() {
      this.tempLocation.city = null
      this.tempLocation.country = null
      this.tempLocation.region = null
      this.tempLocation.placeId = null
      this.updateParentLocation({ ...this.tempLocation })
    },
    onBlur() {
      this.$nextTick(() => {
        if (!this.getPlaceWaiting) {
          this.tempLocationString = this.locationString
        }
      })
    },
    copyValueLocation() {
      this.tempLocation.city = this.location?.city || ''
      this.tempLocation.country = this.location?.country || ''
      this.tempLocation.region = this.location?.region || ''
      this.tempLocation.placeId = this.location?.placeId || ''
    },
    updateParentLocation(location) {
      this.$emit('update', location)
    },
    async selectSuggestion(value) {
      this.getPlaceWaiting = true
      this.suggestions = []
      try {
        const { details } = await Api.system.placeDetails({
          id: value.place_id,
          language: this.$i18n.locale.toUpperCase()
        })
        this.tempLocation = {
          country: details.country?.long_name || null,
          region: details.administrative_area_level_1?.long_name || null,
          city: details.locality?.long_name || null,
          placeId: value.place_id
        }
        this.updateParentLocation({ ...this.tempLocation })
      } catch (e) {
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.getPlaceWaiting = false
    },
    async loadLocations(searchValue) {
      if (!searchValue) {
        return
      }
      try {
        const { places } = await Api.system.placeSearch({
          search: searchValue,
          language: this.$i18n.locale.toUpperCase()
        })
        if (searchValue === this.tempLocationString) {
          this.suggestions = places
        }
      } catch {}
    },
    loadLocationsDebounced: _.debounce(function (value) {
      this.loadLocations(value)
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.LLLocationInput {
  /deep/ .trigger {
    display: block !important;
  }
  &__suggestion {
    @apply py-4 px-2 text-body-01;
  }
}
</style>
