import { interpolateSinebow } from 'd3-scale-chromatic'

export function getBgColor(amount) {
  const backgroundColor = []
  if (amount > 0) {
    for (let i = 0; i < amount; i++) {
      const scaleStart = 0.05
      const scaleEnd = 0.95
      const colorFactor =
        amount > 1
          ? Math.floor((i / (amount - 1)) * (scaleEnd - scaleStart) * 100) / 100 + scaleStart
          : scaleStart
      backgroundColor.push(interpolateSinebow(colorFactor))
    }
  }
  return backgroundColor
}
