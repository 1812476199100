<template>
  <div class="DashboardStudies" :class="{ DashboardStudies_inactive: isLoading && filteredStudyList.length }">
    <div v-if="isLoading && !filteredStudyList.length" class="DashboardStudies__loading">
      <LLLoader>
        <template #default>{{ $t('dashboard.loader') }}</template>
      </LLLoader>
    </div>
    <div v-else-if="error" class="DashboardStudies__error">
      <LLLoaderError @retry="fetch">{{ $t(error) }}</LLLoaderError>
    </div>
    <div v-else-if="!filteredStudyList.length" class="DashboardStudies__empty">
      {{ $tc('common_amount_studies', 0) }}
    </div>
    <div v-else class="DashboardStudies__content" data-e2e="dashboardStudiesContent">
      <div v-for="study in filteredStudyList" :key="study.id" class="DashboardStudies__conversation">
        <ConversationLinkWrapper :study="study" @studyUpdate="onStudyUpdate"></ConversationLinkWrapper>
      </div>
    </div>
    <div
      v-if="filteredStudyList.length && !error && total > perPage"
      class="DashboardStudies__pagination-container"
    >
      <LLPagination
        :disabled="isLoading"
        :per-page="perPage"
        :total="total"
        :current="page"
        @goToPage="changePage"
      />
    </div>
  </div>
</template>

<script>
import LLLoader from '@/components/common/LLLoader'
import LLLoaderError from '@/components/common/LLLoaderError'
import LLPagination from '@/components/common/LLPagination'
import ConversationLinkWrapper from '@/pages/Dashboard/components/conversation/ConversationLinkWrapper'
export default {
  name: 'DashboardStudies',
  components: { ConversationLinkWrapper, LLPagination, LLLoaderError, LLLoader },
  data() {
    return {
      isLoading: false,
      error: null,
      fetchedStudyList: [],
      page: 1,
      total: 0,
      perPage: 10
    }
  },
  computed: {
    filteredStudyList() {
      return this.fetchedStudyList.filter((study) => study.participantInvitationState !== 50)
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    onStudyUpdate({ study: newStudy }) {
      this.fetchedStudyList = this.fetchedStudyList.map((study) =>
        study.id !== newStudy.id ? study : newStudy
      )
    },
    changePage(page) {
      this.page = page
      this.fetch()
    },
    async fetch() {
      this.error = null
      this.isLoading = true
      try {
        const { studies, totalRecords } = await this.$api.studies.fetch({
          page: this.page,
          perPage: this.perPage
        })
        this.fetchedStudyList = studies || []
        this.total = totalRecords
      } catch (e) {
        this.error = this.$getErrorMessage(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardStudies {
  &_inactive {
    @apply opacity-60 pointer-events-none select-none;
  }
  &__loading {
    @apply py-20;
  }
  &__error {
    @apply py-20;
  }
  &__empty {
    @apply text-body-02 text-neutral-01-400 text-center py-20;
  }
  &__pagination-container {
    @apply mt-6 flex justify-center;
  }
  &__content {
    @apply flex flex-wrap;
  }
  &__conversation {
    @apply flex sm:w-1/2 mob:w-full mt-6 mob:mt-5;

    &:nth-child(2n) {
      @screen sm {
        padding-left: 0.75rem;
      }
    }

    &:nth-child(2n + 1) {
      @screen sm {
        padding-right: 0.75rem;
      }
    }
  }
}
</style>
