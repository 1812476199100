<template>
  <img class="ImageLoaderImage" :src="src" />
</template>

<script>
export default {
  name: 'ImageLoaderImage',
  props: {
    src: { type: String, required: true }
  },
  mounted() {
    this.$emit('ready')
  }
}
</script>

<style scoped lang="scss">
.ImageLoaderImage {
}
</style>
