<template>
  <svg
    class="icon fill-current"
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.7 6.1L-3.72843e-07 1.4L1.4 3.72843e-07L4.7 3.3L8 2.13053e-06L9.4 1.4L4.7 6.1Z" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowBorderlessIcon'
}
</script>
