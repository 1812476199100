<template>
  <div class="LLModalLayout" :class="classes">
    <div class="LLModalLayout__content">
      <slot name="content"></slot>
    </div>
    <div v-if="wActions" class="LLModalLayout__actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLModalLayout',
  props: {
    wPaddings: { type: Boolean, default: false },
    wActions: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLModalLayout'
      if (this.wPaddings) {
        classes.push(`${rootClass}_w-paddings`)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.LLModalLayout {
  $root: &;
  @apply flex flex-col h-full relative;
  &__close-button {
    @apply absolute top-0 right-0 p-4 outline-none text-neutral-01;
  }
  &__content {
    @apply flex-1 overflow-y-auto relative;
  }
  &__actions {
    @apply flex-shrink-0;
    box-shadow: 0px -4px 8px rgba(37, 44, 49, 0.07);
  }
  &_w-paddings {
    #{$root}__content {
      @apply py-8 px-10;
      @screen md-max {
        @apply px-4 py-4;
      }
    }
    #{$root}__actions {
      @apply py-8 px-10;
      @screen md-max {
        @apply px-4 py-4;
      }
    }
  }
}
</style>
