<template>
  <v-popover
    ref="popover"
    :open="!!hasSuggestions && inputFocused"
    :show="!!hasSuggestions && inputFocused"
    :popover-class="['tooltip_white', 'tooltip_without-arrow']"
    class="LLInputSuggestions"
    trigger="manual"
    :auto-hide="false"
    placement="bottom-start"
    :offset="offset"
  >
    <div ref="input" class="LLInputSuggestions__input-wrapper">
      <slot name="input"></slot>
    </div>
    <template v-if="hasSuggestions" #popover>
      <div class="LLInputSuggestions__list">
        <div
          v-for="suggestion in suggestions"
          :key="suggestion.key"
          class="LLInputSuggestions__suggestion"
          :class="{ LLInputSuggestions__suggestion_selected: selectedSuggestion === suggestion }"
          @click.prevent="selectSuggestion(suggestion)"
          @mousedown.prevent
          @mouseenter="preselectSuggestion(suggestion)"
        >
          <slot
            name="suggestion"
            :suggestion="suggestion"
            :selected="selectedSuggestion === suggestion"
          ></slot>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'LLInputSuggestions',
  props: {
    suggestions: { type: [Array, null], default: null },
    offset: { type: Number, default: 0 }
  },
  data() {
    return {
      selectedSuggestion: null,
      inputFocused: false
    }
  },
  computed: {
    hasSuggestions() {
      return !!this.suggestions?.length
    },
    selectedSuggestionIndex() {
      return this.suggestions.indexOf(this.selectedSuggestion)
    }
  },
  watch: {
    suggestions: {
      handler() {
        if (this.suggestions.length) {
          this.selectedSuggestion = this.suggestions[0]
        } else {
          this.selectedSuggestion = null
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initInputEvents()
    })
  },
  beforeDestroy() {
    this.destroyInputEvents()
  },
  methods: {
    preselectSuggestion(suggestion) {
      this.selectedSuggestion = suggestion
    },
    getInputElement() {
      const treeWalker = document.createTreeWalker(
        this.$refs?.input,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode() {
            return NodeFilter.FILTER_ACCEPT
          }
        },
        false
      )

      let node
      // eslint-disable-next-line no-empty
      while ((node = treeWalker.nextNode()) && node.tagName !== 'INPUT') {}
      return node
    },
    destroyInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.removeEventListener('keydown', this.inputOnKeyDown)
      element.removeEventListener('focus', this.inputOnFocus)
      element.removeEventListener('blur', this.inputOnBlur)
    },
    initInputEvents() {
      const element = this.getInputElement()
      if (!element) {
        return
      }
      element.addEventListener('keydown', this.inputOnKeyDown)
      element.addEventListener('focus', this.inputOnFocus)
      element.addEventListener('blur', this.inputOnBlur)
    },
    inputOnKeyDown(e) {
      if (!this.hasSuggestions) {
        return
      }
      if (e.keyCode === 38) {
        if (!this.selectedSuggestion) {
          this.selectedSuggestion = this.suggestions[this.suggestions.length - 1]
        } else if (this.selectedSuggestionIndex === 0) {
          this.selectedSuggestion = this.suggestions[this.suggestions.length - 1]
        } else {
          this.selectedSuggestion = this.suggestions[this.selectedSuggestionIndex - 1]
        }
      } else if (e.keyCode === 40) {
        if (!this.selectedSuggestion) {
          this.selectedSuggestion = this.suggestions[0]
        } else if (this.selectedSuggestionIndex === this.suggestions.length - 1) {
          this.selectedSuggestion = this.suggestions[0]
        } else {
          this.selectedSuggestion = this.suggestions[this.selectedSuggestionIndex + 1]
        }
      } else if (e.keyCode === 13) {
        if (this.selectedSuggestion) {
          e.stopPropagation()
          e.preventDefault()
          this.selectSuggestion(this.selectedSuggestion)
        }
      }
    },
    inputOnFocus() {
      this.inputFocused = true
    },
    inputOnBlur() {
      this.inputFocused = false
    },
    selectSuggestion(suggestion) {
      this.$emit('select', suggestion)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSuggestions {
  &__list {
    max-height: 500px;
    @apply overflow-y-auto;
  }
  &__suggestion {
    @apply cursor-pointer;
    &_selected {
      @apply bg-neutral-01-25;
    }
  }
}
</style>
