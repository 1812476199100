<template>
  <LLTimestamp
    v-if="message.createdAt"
    class="StudyConversationMessageTimestamp"
    :timestamp="message.createdAt"
  >
    <template #default="data">
      <span class="StudyConversationMessageTimestamp__text">
        {{ $tc('timestamp_replied', data.time) }}
      </span>
    </template>
  </LLTimestamp>
</template>

<script>
import LLTimestamp from '@/components/common/LLTimestamp.vue'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'

export default {
  name: 'StudyConversationMessageTimestamp',
  components: { LLTimestamp },
  props: {
    message: { type: StudyConversationMessageModel, required: true }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageTimestamp {
  &__text {
    @apply whitespace-nowrap;
  }
}
</style>
