<template>
  <modal
    :name="name"
    :adaptive="true"
    :scrollable="true"
    :click-to-close="!notClosable"
    width="100%"
    height="auto"
    :max-width="780"
    transition="slide-fade"
    @closed="onClosed"
  >
    <slot />
    <button
      v-if="!notClosable"
      class="absolute top-0 right-0 p-4 focus:outline-none"
      data-e2e="closeModal"
      @click="hideModal"
    >
      <CloseIcon class="text-neutral-01" />
    </button>
  </modal>
</template>

<script>
export default {
  name: 'LLModal',
  props: {
    name: {
      type: String,
      required: true
    },
    notClosable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    hideModal() {
      this.$modal.hide(this.name)
    },
    onClosed() {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions. */
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
