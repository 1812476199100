<template>
  <div class="LLSelectListValue" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LLSelectListValue',
  props: {
    selected: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLSelectListValue'
      if (this.selected) {
        classes.push(`${rootClass}_selected`)
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSelectListValue {
  @apply px-4 py-2 text-body-01 break-words cursor-default select-none;
  &_selected {
    @apply bg-neutral-01-50;
  }
}
</style>
