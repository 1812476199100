<template>
  <svg
    class="icon fill-current"
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L5 5L9 1" stroke-width="1.6" />
  </svg>
</template>

<script>
export default {
  name: 'ChevronDown'
}
</script>
