<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7173 1.00059C10.5825 0.727545 10.3044 0.554688 9.9999 0.554688C9.6954 0.554688 9.41731 0.727545 9.28253 1.00059L6.6596 6.31436L0.79394 7.17171C0.492692 7.21574 0.242548 7.42695 0.148646 7.71656C0.0547435 8.00616 0.133362 8.32396 0.351456 8.53639L4.59515 12.6697L3.59363 18.5091C3.54215 18.8093 3.66555 19.1126 3.91193 19.2916C4.15831 19.4706 4.48495 19.4942 4.75448 19.3524L9.9999 16.5939L15.2453 19.3524C15.5148 19.4942 15.8415 19.4706 16.0879 19.2916C16.3342 19.1126 16.4576 18.8093 16.4062 18.5091L15.4046 12.6697L19.6483 8.53639C19.8664 8.32396 19.945 8.00616 19.8511 7.71656C19.7572 7.42695 19.5071 7.21574 19.2059 7.17171L13.3402 6.31436L10.7173 1.00059Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarIcon'
}
</script>
