<template>
  <div className="QuestionSlider">
    <LLRangeSlider
      :value="value"
      :disabled="locked"
      :min="min"
      :interacted="interacted"
      :str-max="strMax"
      :str-min="strMin"
      :max="max"
      @input="onInput"
      @update:interacted="onInteracted"
    />
  </div>
</template>

<script>
import LLRangeSlider from '@/components/common/LLRangeSlider'

export default {
  name: 'QuestionSlider',
  components: { LLRangeSlider },
  props: {
    value: { type: Number, default: null },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 10 },
    locked: { type: Boolean, default: false },
    interacted: { type: Boolean, default: false },
    strMin: { type: String, default: '' },
    strMax: { type: String, default: '' }
  },
  data() {
    return {}
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onInteracted(value) {
      this.$emit('update:interacted', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.QuestionSlider {
  $root: &;
  @mixin sliderValueActive {
    #{$root}__value-tooltip {
      @apply bg-neutral-01-700 text-white;
      #{$root}__value-tooltip-text {
        transform: translateY(0rem);
      }

      &:after {
        border-top-color: var(--color-neutral-01-700);
      }
    }
  }
  @apply select-none;
  &__container {
    @apply w-full;
    max-width: 328px;

    #{$root}__range-values-wrapper {
      @apply w-full px-10 flex justify-between relative;
      #{$root}__range-value {
        @apply text-caption-02 text-neutral-01-400;
      }
    }

    #{$root}__slider-container {
      @apply w-full flex mt-1;
      #{$root}__amount-button-wrapper {
        @apply flex-shrink-0;
        #{$root}__amount-button {
          @apply w-6 h-6 flex items-center justify-center rounded-full cursor-pointer;
          &-icon {
            @apply text-white w-2.5;
          }
        }
      }

      #{$root}__slider-wrapper {
        @apply flex-1 px-4 flex items-center;
      }
    }

    #{$root}__range-prompt-wrapper {
      @apply w-full px-10 flex justify-between mt-1;
      #{$root}__range-prompt {
        @apply text-caption-02 break-words text-neutral-01-400;
        max-width: 45%;
      }
    }
  }

  #{$root}__value-tooltip {
    transition-duration: 200ms;
    background: rgba(0, 0, 0, 0);
    @apply w-8 h-6 absolute top-0 left-0 -mt-8 -ml-1.5 rounded flex items-center justify-center;
    &:after {
      border-top-color: transparent;
      width: 0;
      transition-duration: 200ms;
      height: 0;
      border-style: solid;
      position: absolute;
      content: '';
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      @apply absolute;
    }

    #{$root}__value-tooltip-text {
      @apply text-body-02;
      /*transform: translateY(0.25rem);*/
    }
  }

  #{$root}__slider {
    @apply w-full relative;
    #{$root}__slider-line {
      @apply w-full relative h-1;
      #{$root}__slider-back {
        @apply w-full bg-neutral-01-50 h-1 rounded cursor-pointer;
      }

      #{$root}__slider-filled {
        @apply h-1 rounded w-1/2 absolute left-0 top-0 cursor-pointer;
      }
    }

    #{$root}__slider-toggler {
      @apply w-5 h-5  absolute;
      top: -8px;

      #{$root}__slider-touch-indicator {
        margin-top: -14px;
        margin-left: -14px;
        transform: scale(0);
        @apply w-12 h-12 rounded-full bg-accent-01 absolute left-0 top-0 opacity-10 transition duration-200;
      }

      #{$root}__slider-toggler-round {
        @apply w-5 h-5 border bg-white relative rounded-full cursor-pointer;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      }

      &:hover {
        @include sliderValueActive;

        #{$root}__slider-touch-indicator {
          transform: scale(1);
        }
      }
    }
  }

  & {
    #{$root}__slider-filled {
      @apply bg-accent-01;
    }

    #{$root}__slider-toggler-round {
      @apply border-accent-01;
    }

    #{$root}__amount-button {
      @apply text-accent-01;
    }
  }

  &_active {
    @include sliderValueActive;

    #{$root}__slider-touch-indicator {
      transform: scale(1) !important;
      opacity: 0.3 !important;
    }
  }

  &_locked {
    @apply pointer-events-none;
    #{$root}__slider-filled {
      @apply bg-neutral-01-400;
    }

    #{$root}__slider-toggler-round {
      @apply border-neutral-01-400;
    }

    #{$root}__amount-button {
      @apply text-neutral-01-400;
    }
  }
}
</style>
