const parseEmoji = (match) => {
  let emojiCode = ''
  let i = 0
  const emojiCodeList = []
  const emojiArray = [...match]
  while ((emojiCode = emojiArray[i]?.codePointAt(0))) {
    const hex = emojiCode.toString(16)
    emojiCodeList.push(hex)
    i++
  }
  return emojiCodeList
}

export default { parseEmoji }
