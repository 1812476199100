import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewareList from '@/router/middlewareList'

import MainLayout from '@/layouts/MainLayout'
import Dashboard from '@/pages/Dashboard.vue'
import SignIn from '@/pages/SignIn'
import TermsOfService from '@/pages/TermsOfService'
import SubProcessors from '@/pages/SubProcessors'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import FirstVisit from '@/pages/FirstVisit'
import SetAvatar from '@/pages/SetAvatar'

import AuthAccessor from '@/pages/AuthAccessor'
import ChatPage from '@/pages/Forum/ChatPage'
import EditThreadPage from '@/pages/Forum/EditThreadPage'
import ThreadPage from '@/pages/Forum/ThreadPage'
import ErrorNotFound from '@/pages/ErrorPage.vue'
import middlewareHandler from '@/router/middlewareHandler'
import StudyNewPage from '@/pages/StudyNewPage.vue'
import Maintenance from '@/pages/MaintenancePage.vue'
import LogoutPage from '../pages/LogoutPage.vue'
import ChatSearchPage from '@/pages/Forum/ChatSearchPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        name: 'Dashboard',
        path: '/',
        component: Dashboard,
        beforeEnter: middlewareHandler(
          middlewareList.isAuthorized,
          middlewareList.registrationRequired,
          middlewareList.destinationRedirectIfExists
        )
      },
      {
        path: 'chats',
        component: Dashboard,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'chats/:chatId',
        component: ChatPage,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'chats/:chatId/threads/:threadId/edit',
        component: EditThreadPage,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'chats/:chatId/threads/create',
        component: EditThreadPage,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'chats/:chatId/threads/:threadId',
        component: ThreadPage,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'study/:studyId',
        component: StudyNewPage,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'chats/:chatId/search',
        component: ChatSearchPage,
        props: true,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationRequired)
      },
      {
        path: 'set-avatar',
        component: SetAvatar
      }
    ]
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        name: 'CompleteProfile',
        path: '/complete-profile',
        component: FirstVisit,
        beforeEnter: middlewareHandler(middlewareList.isAuthorized, middlewareList.registrationNotRequired)
      },
      {
        path: 'terms-of-service',
        component: TermsOfService
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicy
      },
      {
        path: 'sub-processors',
        component: SubProcessors
      }
    ]
  },
  {
    name: 'logout',
    path: '/auth/logout',
    component: LogoutPage,
    props: true
  },
  {
    name: 'auth-accessor',
    path: '/auth/:token',
    component: AuthAccessor,
    props: true
  },
  {
    path: '*',
    component: ErrorNotFound
  },
  {
    path: '/404',
    component: ErrorNotFound
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    name: 'sign-in',
    path: '/sign-in',
    props: true,
    component: SignIn
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  /*let loadingFiles = store.getters['fileUpload/uploadingFiles']
  if (loadingFiles.length) {
    if (confirm('You will lost your data message')) {
      store.dispatch(
        'fileUpload/cancel',
        loadingFiles.map((lf) => lf.uuid)
      )
      next()
    }
  } else {*/
  next()
  /*}*/
})

export default router
