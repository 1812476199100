import Vue from 'vue'
/*
const VueInputMask = require('vue-inputmask').default
*/

import InputMask from 'inputmask'
const VueInputMask = {
  install: function (Vue) {
    Vue.directive('mask', {
      bind: (el, binding) => {
        InputMask(binding.value).mask(el)
      }
    })
  }
}
Vue.use(VueInputMask)
