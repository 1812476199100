<template>
  <div v-if="!destroyWhenDisabled || isActive" v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LLTab',
  props: {
    name: { type: [String, Number], default: '' },
    label: { type: String, required: true },
    selected: { type: Boolean, default: false },
    testingString: { type: String, default: '' },
    destroyWhenDisabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false }
  },
  data() {
    return {
      isActive: false
    }
  },
  watch: {
    active() {
      this.isActive = this.active
    }
  },
  created() {
    this.isActive = this.selected
  }
}
</script>
