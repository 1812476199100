import { BaseModel } from '@/models/baseModel'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import { ConversationMessageFileModel } from '@/models/conversationMessageFile'
import { PollResponseModel } from '@/models/pollResponse'
import { pollResponseParser } from '@/utils/poll'
import sanitizeHtml from 'sanitize-html'

export class StudyConversationMessageModel extends BaseModel {
  constructor(messageData = {}) {
    super()
    const {
      id,
      text,
      files,
      user,
      prospect,
      createdAt,
      updatedAt,
      deletedAt,
      studyId,
      questionId,
      userId,
      prospectId,
      isNew,
      isOwnerUser,
      pollResponse,
      isTemporary,
      isSaving,
      isValid,
      pollType,
      messageOptionsType
    } = messageData || {}

    this.id = id || null
    this.text = text || ''
    this.files = Array.isArray(files) ? files.map((file) => new ConversationMessageFileModel(file)) : []
    this.user = user ? new UserModel(user) : null
    this.prospect = prospect ? new ProspectModel(prospect) : null
    this.createdAt = createdAt || null
    this.updatedAt = updatedAt || null
    this.deletedAt = deletedAt || null

    this.studyId = studyId || null
    this.questionId = questionId || null
    this.prospectId = prospectId || null
    this.userId = userId || null

    this.isNew = isNew || false
    this.isOwnerUser = isOwnerUser ?? false
    this.pollResponse = pollResponse ? new PollResponseModel(pollResponse) : new PollResponseModel()

    this.isTemporary = isTemporary || false
    this.isSaving = isSaving || false
    this.isValid = isValid || false

    this.pollType = pollType || null

    this.messageOptionsType = messageOptionsType || null
  }

  static parseFromApi({ message, prospectId, questionId, studyId, user, prospect, pollType }) {
    return new StudyConversationMessageModel({
      id: message?.id,
      text: message?.text,
      userId: user?.id || message?.user?.id || message?.userId || null,
      prospectId: prospectId || null,
      files:
        message?.files
          ?.map((file) =>
            ConversationMessageFileModel.parseFromApi({
              file,
              prospectId,
              questionId,
              messageId: message?.id,
              studyId
            })
          )
          ?.sort((a, b) => a?.order - b?.order) || [],
      createdAt: message?.createdAt,
      updatedAt: message?.updatedAt,
      deletedAt: message?.deletedAt,
      user: message?.user ? UserModel.parseFromApi({ user: message.user }) : null,
      prospect: prospect ? new ProspectModel(prospect) : null,

      studyId,
      questionId,
      isNew: message?.isNew,
      isOwnerUser: !!message?.user,
      pollResponse: message?.pollResponses?.length
        ? pollResponseParser(message?.pollResponses, pollType)
        : new PollResponseModel(),
      pollType,

      messageOptionsType: message?.messageOptionsType
    })
  }

  convertToApi() {
    const { id, text, isAdditionalQuestions, pollResponse, translation } = this
    return {
      id: id || undefined,
      text,
      fileIdList: this.files?.map((file) => file.id) || [],
      isAdditionalQuestions,
      pollResponses: pollResponse && !pollResponse.isEmpty ? pollResponse.convertToApi() : undefined,
      translate: translation ? translation.convertToApi() : undefined
    }
  }

  get isEmpty() {
    return (
      !sanitizeHtml(this.text, { allowedTags: [] }).length &&
      !this.files?.length &&
      !this.pollResponse?.choiceList?.length &&
      !this.pollResponse?.matrixList?.length &&
      !this.pollResponse?.numberValue &&
      this.pollResponse?.numberValue !== 0
    )
  }
}
