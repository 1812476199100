<template>
  <div class="PendingConversationDescription">
    <div class="font-semibold text-base color-primary-01 leading-tight">
      {{ $t('conversation_study_pending_instruction_title') }}
    </div>
    <div class="text-base mt-2 color-primary-01" data-e2e="pendingStudyDetailsText">
      {{ $t('conversation_study_pending_instruction_description') }}
    </div>
    <router-link
      tag="a"
      :to="`/study/${study.id}`"
      class="font-semibold block text-primary-01-400 mt-5 underline mx-auto hidden mob:block"
      data-e2e="pendingStudyDetailsBtn"
      >{{ $t('conversation_study_details') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PendingConversationDescription',
  props: {
    study: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.PendingConversationDescription {
}
</style>
