<template>
  <div class="PollEditorNumberSlider">
    <LLInputLocked class="PollEditorNumberSlider__style-selector" dark-caption required>
      <template #caption>{{ $t('forum.poll.editor.number.slider.title') }}</template>
      <div class="PollEditorNumberSlider__range-inputs">
        <ValidationProvider
          v-slot="{ errors }"
          class="PollEditorNumberSlider__input-wrapper"
          :rules="{
            required: true,
            'not-duplicated': [localPoll.sliderTo],
            max_value: localPoll.sliderTo ? Number(localPoll.sliderTo) - 1 : 2000000000
          }"
        >
          <LLInputText
            v-model="localPoll.sliderFrom"
            class="PollEditorNumberSlider__value-input"
            :errors="errors"
            show-error-in-tooltip
            :disabled="isPollCreated"
            :mask="{ mask: '-{0,1}9{*}', autoUnmask: true }"
          ></LLInputText>
        </ValidationProvider>
        <span class="PollEditorNumberSlider__delimeter">{{ $t('forum.poll.editor.number.slider.to') }}</span>

        <ValidationProvider
          v-slot="{ errors }"
          class="PollEditorNumberSlider__input-wrapper"
          :rules="{
            required: true,
            'not-duplicated': [localPoll.sliderFrom],
            min_value: localPoll.sliderFrom ? Number(localPoll.sliderFrom) + 1 : -Infinity,
            max_value: 2000000000
          }"
        >
          <LLInputText
            v-model="localPoll.sliderTo"
            class="PollEditorNumberSlider__value-input"
            :errors="errors"
            :disabled="isPollCreated"
            show-error-in-tooltip
            :mask="{ mask: '-{0,1}9{*}', autoUnmask: true }"
          ></LLInputText>
        </ValidationProvider>
      </div>
    </LLInputLocked>
    <LLInputLocked class="PollEditorNumberSlider__label-input" dark-caption>
      <template #caption>{{ $t('forum.poll.editor.number.slider.left_label') }}</template>
      <LLInputText v-model="localPoll.sliderFromLabel" show-error-in-tooltip></LLInputText>
    </LLInputLocked>
    <LLInputLocked class="PollEditorNumberSlider__label-input" dark-caption>
      <template #caption>{{ $t('forum.poll.editor.number.slider.right_label') }}</template>
      <LLInputText v-model="localPoll.sliderToLabel" show-error-in-tooltip></LLInputText>
    </LLInputLocked>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { PollModel } from '@/models/poll'
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLInputText from '@/components/common/LLInput/LLInputText.vue'

export default {
  name: 'PollEditorNumberSlider',
  components: { LLInputText, LLInputLocked, ValidationProvider },
  props: {
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      localPoll: new PollModel(this.poll)
    }
  },
  computed: {
    isPollCreated() {
      return !!this.poll?.id
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))

        this.$nextTick(() => {
          if (this.localPoll.sliderTo > 2000000000) {
            this.localPoll.sliderTo = 2000000000
          } else if (this.localPoll.sliderTo < -2000000000) {
            this.localPoll.sliderTo = -2000000000
          }
          if (this.localPoll.sliderFrom > 2000000000) {
            this.localPoll.sliderFrom = 2000000000
          } else if (this.localPoll.sliderFrom < -2000000000) {
            this.localPoll.sliderFrom = -2000000000
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.PollEditorNumberSlider {
  &__range-inputs {
    max-width: 340px;
    @apply flex flex-col justify-between items-start w-full;
    @screen sm {
      @apply flex-row items-center;
    }
  }
  &__input-wrapper {
    @apply w-full;
    @screen sm {
      @apply w-auto;
    }
  }
  &__value-input {
    @apply w-full;
    @screen sm {
      max-width: 150px;
    }
  }
  &__delimeter {
    @apply my-1;
    @screen sm {
      @apply px-1 my-0;
    }
  }
  &__label-input {
    max-width: 340px;
    @apply mt-4;
  }
}
</style>
