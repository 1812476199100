<template>
  <div class="ForumChatMainThreads">
    <h2 class="ForumChatMainThreads__title">{{ $t('forum.chat_page.threads.title') }}</h2>
    <div class="ForumChatMainThreads__header">
      <div class="ForumChatMainThreads__header-filters">
        <LLSearchBar
          class="ForumChatMainThreads__search-bar"
          :placeholder="$t('search-bar_placeholder')"
          @search="searchAction"
        />
        <!--
        <LLFilterButton class="ForumChatMainThreads__filter-button" />
-->
      </div>
      <div class="ForumChatMainThreads__header-actions">
        <router-link v-if="chat.isMembersAbleThreadCreate" :to="newThreadLink">
          <LLAddLabel red> {{ $t('forum.chat_page.threads.add_button') }} </LLAddLabel>
        </router-link>
      </div>
    </div>
    <div class="ForumChatMainThreads__sub-header">
      <LLTabs ref="tabs" class="ForumChatMainThreads__tabs" @change="onTabChange">
        <LLTab
          testing-string="active"
          :name="forumThreadState.ACTIVE"
          :active="selectedTab === forumThreadState.ACTIVE"
          :selected="selectedTab === forumThreadState.ACTIVE"
          destroy-when-disabled
          :label="$t('forum.chat_page.threads.tabs.active')"
        ></LLTab>
        <LLTab
          testing-string="archived"
          :name="forumThreadState.ARCHIVED"
          :active="selectedTab === forumThreadState.ARCHIVED"
          :selected="selectedTab === forumThreadState.ARCHIVED"
          destroy-when-disabled
          :label="$t('forum.chat_page.threads.tabs.archived')"
        ></LLTab>
      </LLTabs>
      <LLInputSelect
        v-model="sort"
        class="ForumChatMainThreads__sort-select"
        :values="sortTypes"
        :key-fn="(v) => v.value"
        :caption-fn="(v) => v.label"
        :reduce="(v) => v.value"
      ></LLInputSelect>
    </div>

    <ForumChatThreadList
      class="ForumChatMainThreads__list"
      :state="selectedTab"
      :search-query="searchQuery"
      :chat="chat"
      :sort="sort"
    />
  </div>
</template>

<script>
import LLSearchBar from '@/components/common/LLSearchBar'
import LLTabs from '@/components/common/tabs/LLTabs'
import LLTab from '@/components/common/tabs/LLTab'
import ForumChatThreadList from '@/components/modules/forum-chat/ForumChatThreadList'
import { ForumChatModel } from '@/models/forum/forumChat'
import LLAddLabel from '@/components/atoms/LLAddLabel'
import { ForumThreadState } from '@/common/enums'
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'
export default {
  name: 'ForumChatMainThreads',
  components: { LLInputSelect, LLAddLabel, ForumChatThreadList, LLTab, LLTabs, LLSearchBar },
  props: {
    chat: { type: ForumChatModel, required: true }
  },
  data() {
    return {
      selectedTab: ForumThreadState.ACTIVE,
      searchQuery: '',
      sort: null
    }
  },
  computed: {
    sortTypes() {
      return [
        {
          label: this.$t('forum.chat_page.threads.sort.newest_first'),
          value: '-createdAt'
        },
        {
          label: this.$t('forum.chat_page.threads.sort.oldest_first'),
          value: '+createdAt',
          default: true
        },
        {
          label: this.$t('forum.chat_page.threads.sort.last_response'),
          value: '-lastResponse'
        },
        ...(this.selectedTab === ForumThreadState.ARCHIVED
          ? [
              {
                label: this.$t('forum.chat_page.threads.sort.archived_at'),
                value: '-archivedAt'
              }
            ]
          : []),
        ...(this.selectedTab === ForumThreadState.HIDDEN
          ? [
              {
                label: this.$t('forum.chat_page.threads.sort.hidden_at'),
                value: '-hiddenAt'
              }
            ]
          : [])
      ]
    },
    forumThreadState() {
      return ForumThreadState
    },
    newThreadLink() {
      return `/chats/${this.chat.id}/threads/create`
    }
  },
  watch: {
    sort: {
      handler() {
        this.$store.commit('chats/SET_THREAD_SORT', this.sort)
        this.checkSort()
      },
      immediate: false
    },
    selectedTab: {
      handler() {
        this.checkSort()
      },
      immediate: false
    }
  },
  created() {
    this.sort = this.$store.state.chats.threadSort
    this.checkSort()
  },
  methods: {
    onTabChange(tab) {
      this.selectedTab = tab
    },
    searchAction(searchQuery) {
      this.searchQuery = searchQuery
    },
    checkSort() {
      if (!this.sortTypes.find((type) => type.value === this.sort)) {
        this.sort = this.sortTypes.find((type) => type.default).value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumChatMainThreads {
  &__header {
    @apply w-full flex flex-col-reverse mt-4;
    @screen md {
      @apply flex-row;
    }
  }
  &__sort {
    @apply mb-4;
    @screen md {
      @apply w-64 ml-4 mb-0;
    }
  }
  &__header-filters {
    @apply flex min-w-0;
  }
  &__search-bar {
    @apply flex-1 min-w-0;
    @screen md {
      @apply flex-initial;
    }
  }
  &__filter-button {
    @apply ml-4 flex-shrink-0;
  }
  &__header-actions {
    @apply flex-shrink-0 mb-4;
    @screen md {
      @apply mb-0 ml-auto;
      @apply pl-4;
    }
  }
  &__sub-header {
    @apply flex flex-col mt-4;
    @screen md {
      @apply flex-row;
    }
  }
  &__sort-select {
    @apply mt-4;
    @screen md {
      @apply ml-auto mt-0;
      width: 250px;
    }
  }
  &__list {
    @apply mt-4;
  }
}
</style>
