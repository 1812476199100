<template>
  <div class="LogoutPage">
    <div class="LogoutPage__loader">
      <LoadingIcon></LoadingIcon>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
export default {
  name: 'AuthAccessor',
  components: { LoadingIcon },
  async created() {
    await this.$store.dispatch('auth/logOut')
    await this.$routerPush.goToSignIn()
  }
}
</script>

<style lang="scss" scoped>
.LogoutPage {
  @apply h-full w-full flex items-center justify-center fixed;
}
</style>
