<template>
  <div class="MaintenancePage">
    <div class="MaintenancePage__content">
      <div class="MaintenancePage__logo-wrapper">
        <LogoBigIcon class="MaintenancePage__logo" />
      </div>
      <div class="MaintenancePage__error-info">
        <span class="MaintenancePage__status"> {{ $t('maintenance-page.title') }} </span>
        <span class="MaintenancePage__message">
          {{ $t('maintenance-page.message') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBigIcon from '@/assets/icons/LogoBigIcon'
export default {
  name: 'MaintenancePage',
  components: { LogoBigIcon },
  layout: 'empty'
}
</script>

<style lang="scss" scoped>
.MaintenancePage {
  @apply w-full h-full absolute overflow-y-auto;
  &__content {
    @apply flex flex-col items-center justify-center py-10 px-4;
    min-height: 100%;
  }
  &__logo {
    @apply w-full;
  }
  &__error-info {
    @apply mt-20 flex flex-col items-center;
  }
  &__status {
    font-size: 80px;
    @apply text-accent-01 mb-5 font-bold;
  }
  &__message {
    font-size: 24px;
    @apply text-center;
  }
}
</style>
