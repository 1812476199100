<template>
  <div class="ForumResponseHeader">
    <ForumResponseProfile class="ForumResponseHeader__profile" :response="response" />
    <ForumResponseTimestamp class="ForumResponseHeader__timestamp" :response="response" />
  </div>
</template>

<script>
import ForumResponseProfile from '@/components/pages/forum/response/ForumResponseProfile.vue'
import ForumResponseTimestamp from '@/components/pages/forum/response/ForumResponseTimestamp.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseHeader',
  components: { ForumResponseTimestamp, ForumResponseProfile },
  props: {
    response: { type: ForumResponseModel, default: null }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseHeader {
  $root: &;
  @apply flex items-center justify-between min-w-0 flex-wrap;
  @screen md {
    @apply justify-start;
    #{$root}__timestamp {
      @apply ml-4;
    }
    #{$root}__profile {
      @apply mr-0;
    }
  }
  &__profile {
    @apply min-w-0 flex-shrink-0 mr-2;
    max-width: 100%;
  }
  &__timestamp {
    @apply whitespace-nowrap truncate;
  }
}
</style>
