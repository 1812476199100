<template>
  <transition name="question-fade" appear>
    <LLSpoiler
      v-if="conversationStream"
      :status="streamState"
      class="StudyNewPageQuestion mt-4 first:mt-0 Question-spoiler"
      :expanded="streamState !== StreamState.RESOLVED"
      :class="[stateClass, animationClass]"
      :disable-body-padding="tailwindScreens.mobOnly"
    >
      <template #status>
        <div class="flex items-center">
          <NewMessageIcon v-if="isNewMessage" class="mr-6 mob:mr-0 -mt-0.5"></NewMessageIcon>
          <ConversationStatus class="mob:hidden" :status="streamState"></ConversationStatus>
        </div>
      </template>
      <template #number>{{ index + 1 }}</template>
      <template #title>{{ bodyReplace(question.title) }}</template>
      <div class="w-full flex justify-center">
        <div
          class="Question w-5/6 py-7 px-8 bg-white border border-neutral-01-50 mob-only:w-full mob-only:p-4 mob-only:border-0"
        >
          <QuestionValue :question="question" data-e2e="questionValue"></QuestionValue>
          <StudyConversationStream
            class="StudyNewPageQuestion__stream"
            :study="study"
            :question="question"
            :conversation-stream="conversationStream"
            @updateMessage="onUpdateMessage"
          />
        </div>
      </div>
    </LLSpoiler>
  </transition>
</template>

<script>
import { StudyModel } from '@/models/study'
import { ConversationStreamModel } from '@/models/conversationStream'
import { QuestionModel } from '@/models/question'
import StudyConversationStream from '@/components/modules/study/StudyConversationStream.vue'
import NewMessageIcon from '@/assets/icons/NewMessageIcon.vue'
import ConversationStatus from '@/pages/Conversation/ConversationStatus.vue'
import QuestionValue from '@/pages/Conversation/Question/QuestionValue.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StreamState } from '@/common/enums'
import studyNextQuestionScroller from '@/utils/modules/studyNextQuestionScroller'
import SmoothScroll from 'smooth-scroll'

export default {
  name: 'StudyNewPageQuestion',
  components: {
    QuestionValue,
    ConversationStatus,
    NewMessageIcon,
    StudyConversationStream
  },
  mixins: [screenCatcher],
  props: {
    study: { type: StudyModel, required: true },
    question: { type: QuestionModel, required: true },
    conversationStream: { type: ConversationStreamModel, default: null },
    index: { type: Number, required: true }
  },
  data() {
    return {
      StreamState,
      isAnimationActive: false
    }
  },
  computed: {
    isNewMessage() {
      const messages = this.conversationStream?.messages?.filter((message) => !message.deletedAt) || []
      return messages[messages.length - 1]?.isOwnerUser
    },
    streamState() {
      return this.conversationStream?.state
    },
    stateClass() {
      switch (this.streamState) {
        case 10:
          return 'Question-spoiler_not-started'
        case 20:
          return 'Question-spoiler_in-progress'
        case 30:
          return 'Question-spoiler_completed'
        default:
          return null
      }
    },
    animationClass() {
      return {
        [`${this.$options.name}_slide-animation`]: this.isAnimationActive
      }
    }
  },
  watch: {
    conversationStream: {
      handler() {
        if (this.conversationStream?.state === StreamState.RESOLVED) {
          this.$store.commit('studyMessages/REMOVE_MESSAGE', {
            questionId: this.conversationStream.questionId
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    studyNextQuestionScroller.addQuestionEvent({
      questionId: this.question?.id,
      callback: this.scrollToThisQuestion
    })
  },
  beforeDestroy() {
    studyNextQuestionScroller.removeQuestionEvent({ questionId: this.question?.id })
  },
  methods: {
    scrollToThisQuestion() {
      const scroll = new SmoothScroll()
      const scrollToBlock = this.$el

      const boundRect = scrollToBlock.getBoundingClientRect()
      const absoluteElementTop = boundRect.top + window.pageYOffset

      const elementOnDisplayPosition = (window.innerHeight - boundRect.height) / 2

      const offset = elementOnDisplayPosition > 170 ? elementOnDisplayPosition : 170

      const middle = absoluteElementTop - offset

      if (scrollToBlock) {
        scroll.animateScroll(middle, null, {
          speedAsDuration: true,
          speed: 300,
          updateURL: false,
          popstate: false
        })
      }
      setTimeout(() => {
        this.isAnimationActive = true
        setTimeout(() => {
          this.isAnimationActive = false
        }, 1000)
      }, 500)
    },
    bodyReplace(body) {
      return body.replace(/(<([^>]+)>)/gi, '')
    },
    onUpdateMessage({ message }) {
      this.$emit('updateMessage', { message })
    }
  }
}
</script>

<style scoped lang="scss">
.Question-spoiler {
  @apply relative;
  /deep/ {
    border-left-width: 0.25rem !important;
    @apply rounded-l;
    .add-section__header {
      @apply rounded-l-none;
      border-left-width: 0;
    }
    .add-section__body {
      @apply rounded-l-none;
      border-left-width: 0;
    }
  }
  &_completed {
    border-left-color: var(--color-success-01) !important;
  }
  &_in-progress {
    border-left-color: var(--color-answered-01) !important;
  }
  &_not-started {
    border-left-color: var(--color-neutral-01-400) !important;
  }
  .Question {
    /deep/ {
      .StudyConversationMessageEditorTextMobile_new {
        @screen sm {
          @apply mt-8;
          &:before {
            margin-left: -32px;
            margin-top: -32px;
            width: calc(100% + 64px);
            height: calc(100% + 64px);
            content: '';
          }
        }
      }
    }
  }

  &.question-fade-enter-active,
  &.question-fade-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.3s;
  }
  &.question-fade-enter,
  &.question-fade-leave-to {
    opacity: 0;
  }

  &.question-fade-enter {
    transform: scale(0.95);
  }

  &.question-fade-leave-to {
    transform: scale(0.95);
  }
}

.StudyNewPageQuestion {
  /* shine */

  &_slide-animation {
    &:after {
      animation: slide 1s;
    }
  }
  &:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    /*
    CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/
    */
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.8)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
