<template>
  <input
    ref="input"
    v-model="tempFilterValue"
    type="text"
    class="LLInputSelectFilteredHiddenInput"
    readonly="readonly"
    @keydown.delete="onBackspace"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
export default {
  name: 'LLInputSelectFilteredTextInput',
  props: {
    focused: { type: Boolean, default: false },
    reduce: { type: Function, default: (value) => value },
    values: { type: Array, default: () => [] },
    filterFn: {
      type: Function,
      default: (value, text) => {
        return value?.toUpperCase()?.startsWith(text?.toUpperCase())
      }
    },
    captionFn: { type: Function, default: (value) => value }
  },
  data() {
    return {
      localFocused: false,
      tempFilterValue: ''
    }
  },
  watch: {
    tempFilterValue() {
      try {
        const filteredValues = this.getFilteredValues(this.tempFilterValue)
        if (filteredValues?.[0]) {
          const newSelectedValue = this.reduce(filteredValues?.[0])
          if (newSelectedValue) {
            this.$emit('updateSelectedValue', newSelectedValue)
          } else {
            this.tempFilterValue = ''
          }
        } else {
          this.tempFilterValue = ''
        }
      } catch (e) {
        this.tempFilterValue = ''
      }
    },
    focused: {
      handler() {
        this.localFocused = this.focused
      },
      immediate: true
    },
    localFocused: {
      handler() {
        if (this.localFocused) {
          this.$refs.input.focus()
        }

        this.$emit('update:focused', this.localFocused)
      }
    }
  },
  methods: {
    getFilteredValues(text) {
      return this.values.filter((v) => {
        return this.filterFn(this.captionFn(v), text)
      })
    },
    onBackspace(e) {
      e.preventDefault()
      this.tempFilterValue = ''
    },
    onFocus() {
      this.localFocused = true
    },
    onBlur() {
      this.localFocused = false
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLInputSelectFilteredHiddenInput {
  @apply w-0 h-0;
}
</style>
