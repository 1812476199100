<template>
  <div class="ForumThreadEditorBody">
    <h3 v-if="!thread" class="ForumThreadEditorBody__title">
      {{ $t('forum.thread_editor_page.title_new') }}
    </h3>
    <h3 v-if="thread" class="ForumThreadEditorBody__title">
      {{ $t('forum.thread_editor_page.title_edit') }}
    </h3>
    <ValidationObserver ref="validation-observer">
      <div class="ForumThreadEditorBody__content">
        <LLInputLocked class="ForumThreadEditorBody__input-container" dark-caption required>
          <template #caption>{{ $t('forum.thread_editor_page.input_caption.title') }}</template>
          <ValidationProvider v-slot="{ errors }" name="threadTitle" rules="required">
            <LLInputText
              v-model="localThread.title"
              :max-length="254"
              class="ForumThreadEditorBody__input"
              :errors="errors"
            ></LLInputText>
          </ValidationProvider>
        </LLInputLocked>
        <LLInputLocked class="ForumThreadEditorBody__input-container" dark-caption required>
          <template #caption>{{ $t('forum.thread_editor_page.input_caption.message') }}</template>
          <ValidationProvider v-slot="{ errors }" name="threadBody" rules="required">
            <LLTextarea
              v-model="localThread.text"
              class="ForumThreadEditorBody__input"
              menu-bar-style="absolute"
              :errors="errors"
            ></LLTextarea>
          </ValidationProvider>
        </LLInputLocked>
        <div class="ForumThreadEditorBody__files">
          <LLFileSlider
            v-if="localThread.files.length"
            editable
            cropper
            class="ForumThreadEditorBody__file-slider"
            :files.sync="localThread.files"
            small
          ></LLFileSlider>
          <div class="ForumThreadEditorBody__files-uploader">
            <LLFileUploader
              class="ForumThreadEditorBody__file-uploader"
              @startUpload="onStartUpload"
              @upload="onUpload"
            ></LLFileUploader>
            <LLUploadProgress
              class="ForumThreadEditorBody__file-uploader-progress"
              :uploading-files-uuids.sync="uploadingFilesUuids"
            ></LLUploadProgress>
          </div>
        </div>
        <LLSpoilerWrapper
          class="ForumThreadEditorBody__poll"
          :expanded.sync="isPollSpoilerExpanded"
          data-e2e="threadPollSpoiler"
        >
          <template #toggler>
            <LLSpoilerTogglerCircleArrow
              class="ForumThreadEditorBody__poll-spoiler"
              :expanded="isPollSpoilerExpanded"
            >
              <template #title>
                <slot name="title">
                  {{ $t('forum.thread_editor_page.poll') }}
                  <span class="ForumThreadEditorBody__poll-spoiler-optional">{{
                    $t('input_caption_optional')
                  }}</span>
                </slot>
              </template>
            </LLSpoilerTogglerCircleArrow>
          </template>
          <template #content>
            <div class="ForumThreadEditorBody__poll-content">
              <PollEditor :poll.sync="localThread.poll" />
            </div>
          </template>
        </LLSpoilerWrapper>
        <LLSpoilerWrapper
          class="ForumThreadEditorBody__settings"
          :expanded.sync="isSettingsSpoilerExpanded"
          data-e2e="threadSettingsSpoiler"
        >
          <template #toggler>
            <LLSpoilerTogglerCircleArrow
              class="ForumThreadEditorBody__settings-spoiler"
              :expanded="isSettingsSpoilerExpanded"
            >
              <template #title>
                <slot name="title">
                  {{ $t('forum.thread_editor_page.settings') }}
                  <span class="ForumThreadEditorBody__settings-spoiler-optional">{{
                    $t('input_caption_optional')
                  }}</span>
                </slot>
              </template>
            </LLSpoilerTogglerCircleArrow>
          </template>
          <template #content>
            <div class="ForumThreadEditorBody__settings-content">
              <LLCheckbox v-model="localThread.isAnonymous">{{
                $t('forum.settings.anonymous_checkbox')
              }}</LLCheckbox>
            </div>
          </template>
        </LLSpoilerWrapper>
        <LLInputLocked class="ForumThreadEditorBody__input-container" dark-caption optional>
          <template #caption>{{ $t('forum.thread_editor_page.input_caption.themes') }}</template>
          <ForumThreadEditorThemes :themes.sync="localThread.themes" />
        </LLInputLocked>
      </div>
    </ValidationObserver>
    <div class="ForumThreadEditorBody__actions">
      <LLButton :loading="isSaving" :disabled="!isValid" small @click="save">
        <template v-if="localThread.id">{{ $t('button_update') }}</template>
        <template v-else>{{ $t('button_post') }}</template>
      </LLButton>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import LLInputText from '@/components/common/LLInput/LLInputText'
import LLInputLocked from '@/components/common/LLInputLocked'
import LLTextarea from '@/components/common/LLTextarea'
import LLButton from '@/components/common/ui-components/LLButton'
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLFileUploader from '@/components/common/LLFileUploader/LLFileUploader'
import LLUploadProgress from '@/components/common/LLUploadProgress'
import LLFileSlider from '@/components/common/LLFileSlider'
import { ForumChatModel } from '@/models/forum/forumChat'
import ForumThreadEditorThemes from '@/components/pages/forum/thread/editor/ForumThreadEditorThemes'
import LLSpoilerWrapper from '@/components/common/ui-components/LLSpoilerWrapper'
import LLSpoilerTogglerCircleArrow from '@/components/common/ui-components/LLSpoiler/Togglers/LLSpoilerTogglerCircleArrow'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2'
import PollEditor from '@/components/pages/forum/thread/poll/PollEditor.vue'
export default {
  name: 'ForumThreadEditorBody',
  components: {
    PollEditor,
    LLCheckbox,
    LLSpoilerTogglerCircleArrow,
    LLSpoilerWrapper,
    ForumThreadEditorThemes,
    LLFileSlider,
    LLUploadProgress,
    LLFileUploader,
    LLButton,
    LLTextarea,
    LLInputLocked,
    LLInputText,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    chat: { type: ForumChatModel, default: null },
    thread: { type: ForumThreadModel, default: null }
  },
  data() {
    return {
      localThread: new ForumThreadModel(),
      isSaving: false,
      isValid: false,
      uploadingFilesUuids: [],
      isSettingsSpoilerExpanded: false,
      isPollSpoilerExpanded: false
    }
  },
  watch: {
    thread: {
      handler() {
        if (!this.$_.isEqual(this.thread, this.localThread)) {
          this.localThread = new ForumThreadModel(this.thread)
        }
      },
      immediate: true,
      deep: true
    },
    localThread: {
      handler() {
        this.$nextTick(async () => {
          this.isValid = (await this.validate()) && !this.uploadingFilesUuids.length
        })
      },
      deep: true
    },
    uploadingFilesUuids: {
      handler() {
        this.$nextTick(async () => {
          this.isValid = (await this.validate()) && !this.uploadingFilesUuids.length
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.isValid = (await this.validate()) && !this.uploadingFilesUuids.length
    })
  },
  methods: {
    async save() {
      this.isSaving = true
      try {
        let method
        if (this.localThread.id) {
          method = this.$api.forum.threads.update
        } else {
          method = this.$api.forum.threads.create
        }
        const { thread: apiThread } = await method({
          chatId: this.chat.id,
          thread: this.localThread.convertToApi()
        })
        const thread = ForumThreadModel.parseFromApi({ thread: apiThread, chatId: this.chat.id })
        this.$router.push({
          path: `/chats/${this.chat.id}/threads/${thread.id}`
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.isSaving = false
    },
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver) {
        const validation = await validationObserver.validateWithInfo({ silent: true })
        return validation?.flags?.valid
      }
      return false
    },
    onStartUpload(uploadingFilesUuids) {
      this.uploadingFilesUuids = uploadingFilesUuids
    },
    onUpload(files) {
      this.localThread.files = [...this.localThread.files, ...files]
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumThreadEditorBody {
  &__title {
    @apply mb-4 text-primary-01-400;
  }
  &__input {
    @apply w-full;
  }
  &__input-container {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
  &__actions {
    @apply mt-6 flex justify-end;
  }
  &__action {
    @apply mr-4;
    &:last-child {
      @apply mr-0;
    }
  }
  &__files {
    @apply mt-4;
  }
  &__file-slider {
    @apply mb-4;
  }
  &__files-uploader {
    @apply flex flex-col justify-between;
    @screen md {
      @apply flex-row;
    }
  }
  &__poll {
    @apply mt-6 mb-6;
    &-spoiler {
      &-optional {
        @apply text-neutral-01-400 text-caption-02;
      }
    }
    &-content {
      @apply pt-4 pl-0;
      @screen md {
        @apply pl-10;
      }
    }
  }
  &__settings {
    @apply mt-6 mb-6;
    &-spoiler {
      &-optional {
        @apply text-neutral-01-400 text-caption-02;
      }
    }
    &-content {
      @apply pt-4 pl-0;
      @screen md {
        @apply pl-10;
      }
    }
  }
  &__file-uploader-progress {
    @apply mt-4;
    @screen md {
      @apply mt-0;
    }
  }
}
</style>
