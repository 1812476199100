<template>
  <div class="LLTag" :class="classes">
    <div class="LLTag__caption">
      <slot></slot>
    </div>
    <div v-if="withRemove" class="LLTag__clear" @click="remove">
      <ClearIcon class="LLTag__clear-icon"></ClearIcon>
    </div>
  </div>
</template>

<script>
import ClearIcon from '@/assets/icons/ClearIcon'
export default {
  name: 'LLTag',
  components: { ClearIcon },
  props: {
    preRemove: { type: Boolean, default: false },
    withRemove: { type: Boolean, default: false },
    highlighted: { type: Boolean, default: false },
    active: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLTag'
      if (this.preRemove) {
        classes.push(`${rootClass}_pre-remove`)
      }
      if (this.highlighted) {
        classes.push(`${rootClass}_highlighted`)
      }
      if (this.active) {
        classes.push(`${rootClass}_active`)
      }
      return classes
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTag {
  @apply min-w-0 bg-neutral-01-25 py-0.5 px-2 flex flex-nowrap items-center;
  &__caption {
    @apply text-neutral-01-600 text-body-02 truncate min-w-0;
  }
  &__clear {
    @apply ml-2 flex-shrink-0 cursor-pointer;
    &-icon {
      @apply w-4 h-4 text-neutral-01-200;
    }
  }
  &_pre-remove {
    @apply opacity-50;
  }
  &_highlighted {
    @apply bg-accent-03-200;
  }
  &_active {
    @apply bg-neutral-01-100;
  }
  max-width: 100%;
}
</style>
