import Vue from 'vue'
import { ProspectModel } from '@/models/prospect'
const app = new Vue()

export default {
  namespaced: true,
  state: {
    user: {},
    userLoading: false,
    interestsList: []
  },
  getters: {
    userObject(state) {
      return ProspectModel.parseFromApi({ prospect: state.user })
    },
    user(state) {
      return state.user
    },
    interests(state) {
      return state.interestsList
    }
  },
  mutations: {
    USER_LOADING(state) {
      state.userLoading = true
    },
    USER_LOADED(state) {
      state.userLoading = false
    },
    UPDATE_USER(state, user) {
      state.user = user
    },
    UPDATE_USER_LANGUAGE(state, { preferredLanguage }) {
      if (state.user) {
        state.user.preferredLanguage = preferredLanguage
      }
    },
    UPDATE_INTERESTS(state, interests) {
      state.interestsList = interests
    },
    USER_REMOVE(state) {
      state.user = {}
    }
  },
  actions: {
    async fetchInterests({ commit }) {
      const { interests } = await app.$api.interests.fetch()
      commit('UPDATE_INTERESTS', interests)
    },
    async fetchUser({ commit }, payload) {
      commit('USER_LOADING')
      const { prospectAccount } = await app.$api.user.fetch(payload)
      commit('UPDATE_USER', prospectAccount)
      commit('USER_LOADED')
    },
    async syncUser({ commit }, payload) {
      commit('USER_LOADING')
      delete payload.id
      delete payload.email
      delete payload.createdAt
      delete payload.updatedAt
      delete payload.image
      const { prospectAccount } = await app.$api.user.sync(payload)
      commit('UPDATE_USER', prospectAccount)

      commit('USER_LOADED')
    },
    async syncUserLanguage({ commit }, { preferredLanguage }) {
      console.log('lang')
      await app.$api.user.syncLanguage({ preferredLanguage })
      commit('UPDATE_USER_LANGUAGE', { preferredLanguage })
    },
    reset({ commit }) {
      commit('USER_REMOVE')
    }
  }
}
