<template>
  <div class="UserAvatar">
    <div class="UserAvatar__image-wrapper">
      <ImageLoader
        :large="large"
        :small="small"
        class="UserAvatar__image"
        empty-picture="profile"
        :file="image"
      ></ImageLoader>
    </div>
    <div class="UserAvatar__hover-backplate"></div>
    <div v-if="slots.rightIndicator" class="UserAvatarIndicator UserAvatar__indicator_right">
      <div class="UserAvatarIndicator__content">
        <slot name="indicator-right"></slot>
      </div>
    </div>
    <div v-if="slots.leftIndicator" class="UserAvatarIndicator UserAvatar__indicator_left">
      <div class="UserAvatarIndicator__content">
        <slot name="indicator-left"></slot>
      </div>
    </div>
    <div v-if="slots.bottomRightIndicator" class="UserAvatarIndicator UserAvatar__indicator_right-bottom">
      <div class="UserAvatarIndicator__content">
        <slot name="indicator-right-bottom"></slot>
      </div>
    </div>
    <div v-if="slots.bottomLeftIndicator" class="UserAvatarIndicator UserAvatar__indicator_left-bottom">
      <div class="UserAvatarIndicator__content">
        <slot name="indicator-left-bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { UserModel } from '@/models/user'

import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'

export default {
  name: 'UserAvatar',
  components: {
    ImageLoader
  },
  props: {
    user: { type: UserModel, required: true },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  data() {
    return {
      tooltipOpened: false,
      slots: {
        bottomRightIndicator: false,
        bottomLeftIndicator: false,
        rightIndicator: false,
        leftIndicator: false
      }
    }
  },
  computed: {
    image() {
      return this.user.image
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  methods: {
    setShowSlots() {
      this.slots.rightIndicator = !!this.$slots?.['indicator-right']?.[0]
      this.slots.leftIndicator = !!this.$slots?.['indicator-left']?.[0]
      this.slots.bottomRightIndicator = !!this.$slots?.['indicator-right-bottom']?.[0]
      this.slots.bottomLeftIndicator = !!this.$slots?.['indicator-left-bottom']?.[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.UserAvatar {
  @apply w-full h-full relative;
  $root: &;

  &__hover-backplate {
    @apply w-full h-full rounded-full absolute top-0 left-0 pointer-events-none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    @apply opacity-0 transition duration-200;
  }
  &__image-wrapper {
    @apply w-full h-full absolute overflow-hidden rounded-full;
  }
  &__image {
    @apply w-full h-full object-cover  transition duration-200 relative;
  }
  &__indicator {
    &_left {
      top: -4%;
      left: -4%;
    }
    &_right {
      top: -4%;
      right: -4%;
    }
    &_left-bottom {
      bottom: -4%;
      left: -4%;
    }
    &_right-bottom {
      bottom: -4%;
      right: -4%;
    }
  }
  &Indicator {
    @apply absolute;
    width: calc(100% / 3);
    height: calc(100% / 3);
    min-width: 14px;
    min-height: 14px;
    &:before {
      @apply absolute bg-white rounded-full;
      content: '';
      width: 112%;
      left: -6%;
      top: -6%;
      height: 112%;
    }
    &_new-message {
      &:before {
        content: none;
      }
    }
    &__content {
      @apply w-full h-full relative rounded-full;
    }
  }
  &NewMessageIndicator {
    @apply w-full h-full bg-white border border-neutral-01-400 shadow-dropdown rounded-full flex items-center justify-center;
    &__icon {
      width: 70%;
    }
  }
}
</style>
