<template>
  <svg
    class="ArrowIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3968 7.69629L19.3343 14.5775C19.5546 14.7979 19.5546 15.1541 19.3343 15.3744L18.4061 16.3025C18.1858 16.5229 17.8296 16.5229 17.6093 16.3025L11.9983 10.7479L6.3874 16.3025C6.16708 16.5229 5.81083 16.5229 5.59052 16.3025L4.6624 15.3744C4.44208 15.1541 4.44208 14.7979 4.6624 14.5775L11.5999 7.69629C11.8202 7.47598 12.1765 7.47598 12.3968 7.69629Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon'
}
</script>

<style scoped lang="scss">
.ArrowIcon {
  @apply fill-current;
}
</style>
