<template>
  <div class="SiteFooter flex justify-center pt-10 pb-4 bg-neutral-01-25">
    <div class="container">
      <div class="flex justify-between mob-only:flex-col">
        <div v-if="!tailwindScreens.mobOnly" class=""></div>
        <div v-if="!tailwindScreens.mobOnly" class=""></div>
        <div v-if="!tailwindScreens.mobOnly" class=""></div>
        <div v-if="!tailwindScreens.mobOnly" class=""></div>
        <div v-if="!tailwindScreens.mobOnly" class=""></div>
        <router-link
          to="/privacy-policy"
          tag="a"
          class="block footer-link text-sm text-neutral-01"
          data-e2e="privacyLink"
          >{{ $t('footer_privacy_link') }}
        </router-link>
        <router-link
          to="/sub-processors"
          tag="a"
          class="block footer-link text-sm text-neutral-01"
          data-e2e="subProcessors"
          >{{ $t('footer_sub_processors_link') }}
        </router-link>
        <router-link
          to="/terms-of-service"
          tag="a"
          class="block footer-link text-sm text-neutral-01"
          data-e2e="termsLink"
          >{{ $t('footer_terms_link') }}
        </router-link>
      </div>
      <div class="w-full h-px bg-neutral-01-400 mt-7 mb-4"></div>
      <div class="flex justify-between items-center">
        <div>
          <!--          <LLDropdown class="language-selector" no-icon noChevron>
            <template #title>
              <div class="language-selector__trigger font-main">
                {{ currentLocale }}
                <ChevronDown></ChevronDown>
              </div>
            </template>
            <button
              class="block w-full text-left px-4 py-2 01 text-sm text-primary-01 hover:bg-neutral-01-25"
              type="button"
              v-for="locale in availableLocales"
              :key="locale.code"
              @click="switchLocale(locale)"
            >
              {{ locale.label }}
            </button>
          </LLDropdown>-->

          <LLInputSelect
            v-model="currentLocale"
            class="SiteFooter__language-selector"
            :values="availableLocales"
            :caption-fn="getLocaleLabel"
            :max-height="500"
            small
            :reduce="(locale) => locale.code"
          />
        </div>
        <div class="text-neutral-01 text-xs">© SPARK IDEAS LLC</div>
      </div>
      <!--      <div class="flex justify-between mob:flex-col">
              <div>
                <div class="text-neutral-01 text-xs">© SPARK IDEAS LLC</div>
              </div>
              <div class="flex mob:flex-col">
                <div class="mr-24">
                  <div class="footer-link text-sm text-neutral-01">Product & Services</div>
                  <div class="footer-link text-sm text-neutral-01">About</div>
                  <div class="footer-link text-sm text-neutral-01">Contact</div>
                </div>
                <div>
                  <div class="footer-link text-sm text-neutral-01">Help</div>
                  <div class="footer-link text-sm text-neutral-01">FAQ</div>
                  <router-link to="/privacy-policy" tag="a" class="block footer-link text-sm text-neutral-01">{{
                    $t('footer_privacy_link')
                  }}</router-link>
                  <router-link to="/terms-of-service" tag="a" class="block footer-link text-sm text-neutral-01">{{
                    $t('footer_terms_link')
                  }}</router-link>
                </div>
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
import { screenCatcher } from '@/mixins/screenCatcher'
import languageLabels from '@/common/languages.json'

import { localeChanged } from 'vee-validate'
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'

export default {
  name: 'SiteFooter',
  components: { LLInputSelect },
  mixins: [screenCatcher],
  computed: {
    /*currentLocale() {
      return this.availableLocales.find((l) => l.code === this.$i18n.locale)?.label
    },*/
    availableLocales() {
      return this.$i18n.availableLocales.map((l) => ({
        code: l,
        label: languageLabels?.[l]
      }))
    },
    currentLocale: {
      get() {
        return this.availableLocales.find((l) => l.code === this.$i18n.locale)?.code
      },
      set(localeCode) {
        if (this.$i18n.locale === localeCode) {
          return
        }
        if (this.$i18n.locale !== localeCode) {
          this.$i18n.locale = localeCode
          this.$store.commit('ui/SET_LOCALE', localeCode)
        }
        localeChanged()
        if (this.$store.getters['auth/isAuthorised']) {
          this.$store.dispatch('user/syncUser', {
            preferredLanguage: localeCode
          })
        }
      }
    }
  },
  methods: {
    getLocaleLabel(locale) {
      return locale?.label
    }
  }
}
</script>

<style lang="scss" scoped>
.SiteFooter {
  &__language-selector {
    @apply w-48;
  }
  /*.language-selector {
    /deep/ .dropdown-nav__button {
    }
    &__trigger {
      min-width: 118px;
      span {
        @apply font-normal;
      }
      line-height: 100% !important;
      @apply bg-white border rounded border-neutral-01-400 py-2 px-3 text-sm font-normal text-primary-01 text-left flex justify-between items-center;
    }
    /deep/ .dropdown-nav__menu {
      left: 0;
      bottom: 40px;
      top: auto;
      right: auto;
      @apply font-normal;
    }
  }*/
}

.footer-link {
  @apply mt-5;
  &:first-child {
    @apply mob-only:mt-0;
  }

  min-width: 30px;
}
</style>
