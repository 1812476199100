<template>
  <div class="PollMultipleResultsBar">
    <PollResultsChartBar
      :chart-bar-data="chartData"
      :max="100"
      :data-label-callback="dataLabel"
      :tooltip-label-callback="tooltipLabel"
    />
  </div>
</template>

<script>
import PollResultsChartBar from '@/components/modules/forum-chat/poll/results/PollResultsChartBar.vue'

export default {
  name: 'PollMultipleResultsBar',
  components: { PollResultsChartBar },
  props: {
    data: { type: Array, required: true }
  },
  computed: {
    chartData() {
      return {
        labels: this.data.map((item) => item.text),
        datasets: [
          {
            barThickness: 18,
            backgroundColor: new Array(this.data?.length).fill('#508BBA'),
            data: this.data.map((item) => item.value)
          }
        ]
      }
    }
  },
  methods: {
    tooltipLabel(ctx) {
      return this.$tc('forum.poll.results.percentage_label', ctx.parsed.x)
    },
    dataLabel(value) {
      return `${value}%`
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultipleResultsBar {
}
</style>
