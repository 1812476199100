<template>
  <div class="WaitingConversationDescription">
    <div class="font-semibold text-base color-primary-01 leading-tight">
      <p>{{ $t('conversation_study_pending_instruction_title') }}</p>
    </div>
    <div class="text-base mt-2 color-primary-01" data-e2e="waitingStudyDetailsText">
      {{ $t('dashboard_top_conversation_scheduled', { startDate }) }}
    </div>
    <router-link
      tag="a"
      :to="`/study/${study.id}`"
      class="font-semibold block text-primary-01-400 mt-5 underline mx-auto hidden mob:block"
      data-e2e="waitingStudyDetailsBtn"
      >{{ $t('conversation_study_details') }}
    </router-link>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WaitingConversationDescription',
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    startDate() {
      return moment.unix(this.study.startDate / 1000).format('MM/DD/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.WaitingConversationDescription {
}
</style>
