<template>
  <svg
    width="42"
    height="43"
    viewBox="0 0 42 43"
    class="fill-current"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.980469" width="42" height="42" rx="21" />
    <circle cx="21.0018" cy="21.9822" r="14.3182" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2734 29.1424L31.0234 21.9833L15.2734 14.8242V20.3924L26.5234 21.9833L15.2734 23.5742V29.1424Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'SendCompactIcon'
}
</script>

<style lang="scss" scoped>
.SendCompactIcon {
}
</style>
