<template>
  <div class="SearchEmpty">
    <SearchContentContainer>
      <template #filters>
        <SearchEmptyFilters
          @updateFilterLabels="updateFilterLabels"
          @apply="applyFilters"
        ></SearchEmptyFilters>
      </template>
      <template #results> </template>
      <template #before-results-load>
        <LLFilterLabels
          v-if="filterLabels.length"
          class="SearchResponses__filter-labels"
          :labels="filterLabels"
        ></LLFilterLabels>
      </template>
    </SearchContentContainer>
  </div>
</template>

<script>
import LLFilterLabels from '../../filter/LLFilterLabels'
import SearchContentContainer from '@/components/pages/search/SearchModule/SearchContent/SearchContentContainer'
import SearchEmptyFilters from '@/components/pages/search/SearchModule/SearchContent/SearchEmpty/SearchEmptyFilter'
export default {
  name: 'SearchEmpty',
  components: { LLFilterLabels, SearchEmptyFilters, SearchContentContainer },
  data() {
    return {
      filterValues: {},
      filterLabels: []
    }
  },
  methods: {
    applyFilters(filters) {
      this.$emit('updateStartFilters', filters)
    },
    updateFilterLabels({ filterLabels }) {
      this.filterLabels = filterLabels
    }
  }
}
</script>
