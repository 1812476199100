import Vue from 'vue'
import LLDropdown from '@/components/common/ui-components/LLDropdown'
import LLTextInput from '@/components/common/ui-components/LLTextInput'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import TextBorders from '@/components/common/TextBorders'
import LLModal from '@/components/common/LLModal'
import LLSelectInput from '@/components/common/LLSelectInput'
import LLSpoiler from '@/components/common/ui-components/LLSpoiler'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('LLDropdown', LLDropdown)
Vue.component('LLTextInput', LLTextInput)
Vue.component('TextBorders', TextBorders)
Vue.component('LLModal', LLModal)
Vue.component('LLSelectInput', LLSelectInput)
Vue.component('LLSpoiler', LLSpoiler)
