<template>
  <svg
    class="icon fill-current"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2691 4.99993C15.2691 7.76132 12.9902 9.99987 10.1792 9.99987C7.36811 9.99987 5.08928 7.76132 5.08928 4.99993C5.08928 2.23855 7.36811 0 10.1792 0C12.9902 0 15.2691 2.23855 15.2691 4.99993ZM0 17.4999C0 14.75 4.5809 12.5 10.1798 12.5C15.7787 12.5 20.3596 14.75 20.3596 17.4999V19.9999H0V17.4999Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AccountIcon'
}
</script>
