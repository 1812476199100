<template>
  <svg width="318" height="63" viewBox="0 0 318 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M185.614 0.591225C185.426 0.591225 185.237 0.687168 185.096 0.78311C184.955 0.927023 184.907 1.11891 184.907 1.31079V38.2485C184.907 38.4404 184.955 38.6323 185.096 38.7762C185.237 38.9201 185.426 38.9681 185.614 38.9681H202.057C202.245 38.9681 202.434 38.9201 202.528 38.7762C202.622 38.6323 202.717 38.4404 202.717 38.2485C202.717 38.0566 202.622 37.9127 202.528 37.7688C202.434 37.6249 202.245 37.5289 202.057 37.5289H186.321V1.31079C186.321 1.11891 186.227 0.927023 186.085 0.78311C185.944 0.687168 185.803 0.591225 185.614 0.591225Z"
      fill="#181818"
    />
    <path
      d="M185.614 0.591225C185.426 0.591225 185.237 0.687168 185.096 0.78311C184.955 0.927023 184.907 1.11891 184.907 1.31079V38.2485C184.907 38.4404 184.955 38.6323 185.096 38.7762C185.237 38.9201 185.426 38.9681 185.614 38.9681H202.057C202.245 38.9681 202.434 38.9201 202.528 38.7762C202.622 38.6323 202.717 38.4404 202.717 38.2485C202.717 38.0566 202.622 37.9127 202.528 37.7688C202.434 37.6249 202.245 37.5289 202.057 37.5289H186.321V1.31079C186.321 1.11891 186.227 0.927023 186.085 0.78311C185.944 0.687168 185.803 0.591225 185.614 0.591225Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M216.472 4.38094C219.063 2.79789 221.843 1.98239 224.858 1.98239C227.873 1.98239 230.653 2.79789 233.197 4.33297C235.742 5.91601 237.767 8.07471 239.275 10.857C240.783 13.6394 241.537 16.6136 241.537 19.7796C241.537 23.0417 240.783 26.0159 239.275 28.7023C237.767 31.3886 235.742 33.5473 233.197 35.1784C230.653 36.7614 227.873 37.5289 224.858 37.5289C221.796 37.5289 219.016 36.7614 216.472 35.1784C213.928 33.5953 211.902 31.4366 210.394 28.7023C208.887 26.0159 208.18 23.0417 208.18 19.7796C208.18 16.6136 208.887 13.6394 210.394 10.857C211.902 8.17065 213.928 6.01195 216.472 4.38094ZM233.904 3.13369C231.077 1.40673 228.062 0.543254 224.858 0.543254C221.56 0.543254 218.498 1.40673 215.765 3.13369C213.033 4.86065 210.818 7.16326 209.169 10.1375C207.567 13.1117 206.766 16.3257 206.766 19.7796C206.766 21.5066 206.955 23.1856 207.332 24.7686C207.709 26.3997 208.321 27.9347 209.169 29.4218C210.771 32.396 212.938 34.6986 215.765 36.4256C218.545 38.1526 221.56 38.9681 224.858 38.9681C228.062 38.9681 231.077 38.1526 233.904 36.4256C236.684 34.6986 238.898 32.396 240.5 29.4218C242.102 26.4956 242.95 23.2815 242.95 19.7796C242.95 16.2778 242.102 13.0637 240.5 10.1375C238.898 7.21123 236.684 4.86065 233.904 3.13369Z"
      fill="#181818"
    />
    <path
      d="M216.472 4.38094C219.063 2.79789 221.843 1.98239 224.858 1.98239C227.873 1.98239 230.653 2.79789 233.197 4.33297C235.742 5.91601 237.767 8.07471 239.275 10.857C240.783 13.6394 241.537 16.6136 241.537 19.7796C241.537 23.0417 240.783 26.0159 239.275 28.7023C237.767 31.3886 235.742 33.5473 233.197 35.1784C230.653 36.7614 227.873 37.5289 224.858 37.5289C221.796 37.5289 219.016 36.7614 216.472 35.1784C213.928 33.5953 211.902 31.4366 210.394 28.7023C208.887 26.0159 208.18 23.0417 208.18 19.7796C208.18 16.6136 208.887 13.6394 210.394 10.857C211.902 8.17065 213.928 6.01195 216.472 4.38094ZM233.904 3.13369C231.077 1.40673 228.062 0.543254 224.858 0.543254C221.56 0.543254 218.498 1.40673 215.765 3.13369C213.033 4.86065 210.818 7.16326 209.169 10.1375C207.567 13.1117 206.766 16.3257 206.766 19.7796C206.766 21.5066 206.955 23.1856 207.332 24.7686C207.709 26.3997 208.321 27.9347 209.169 29.4218C210.771 32.396 212.938 34.6986 215.765 36.4256C218.545 38.1526 221.56 38.9681 224.858 38.9681C228.062 38.9681 231.077 38.1526 233.904 36.4256C236.684 34.6986 238.898 32.396 240.5 29.4218C242.102 26.4956 242.95 23.2815 242.95 19.7796C242.95 16.2778 242.102 13.0637 240.5 10.1375C238.898 7.21123 236.684 4.86065 233.904 3.13369Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M259.647 4.38094C262.238 2.79789 265.018 1.98239 268.033 1.98239C271.049 1.98239 273.828 2.79789 276.373 4.33297C278.917 5.91601 280.943 8.07471 282.45 10.857C283.958 13.6394 284.712 16.6136 284.712 19.7796C284.712 23.0417 283.958 26.0159 282.45 28.7023C280.943 31.3886 278.917 33.5473 276.373 35.1784C273.828 36.7614 271.049 37.5289 268.033 37.5289C264.971 37.5289 262.191 36.7614 259.647 35.1784C257.103 33.5953 255.077 31.4366 253.569 28.7023C252.062 26.0159 251.355 23.0417 251.355 19.7796C251.355 16.6136 252.062 13.6394 253.569 10.857C255.077 8.17065 257.103 6.01195 259.647 4.38094ZM277.079 3.13369C274.252 1.40673 271.237 0.543254 268.033 0.543254C264.735 0.543254 261.673 1.40673 258.94 3.13369C256.208 4.86065 253.993 7.16326 252.344 10.1375C250.743 13.1117 249.942 16.3257 249.942 19.7796C249.942 21.5066 250.13 23.1856 250.507 24.7686C250.884 26.3997 251.496 27.9347 252.344 29.4218C253.946 32.396 256.114 34.6986 258.94 36.4256C261.72 38.1526 264.735 38.9681 268.033 38.9681C271.237 38.9681 274.252 38.1526 277.079 36.4256C279.859 34.6986 282.073 32.396 283.675 29.4218C285.277 26.4956 286.125 23.2815 286.125 19.7796C286.125 16.2778 285.277 13.0637 283.675 10.1375C282.073 7.21123 279.859 4.86065 277.079 3.13369Z"
      fill="#181818"
    />
    <path
      d="M259.647 4.38094C262.238 2.79789 265.018 1.98239 268.033 1.98239C271.049 1.98239 273.828 2.79789 276.373 4.33297C278.917 5.91601 280.943 8.07471 282.45 10.857C283.958 13.6394 284.712 16.6136 284.712 19.7796C284.712 23.0417 283.958 26.0159 282.45 28.7023C280.943 31.3886 278.917 33.5473 276.373 35.1784C273.828 36.7614 271.049 37.5289 268.033 37.5289C264.971 37.5289 262.191 36.7614 259.647 35.1784C257.103 33.5953 255.077 31.4366 253.569 28.7023C252.062 26.0159 251.355 23.0417 251.355 19.7796C251.355 16.6136 252.062 13.6394 253.569 10.857C255.077 8.17065 257.103 6.01195 259.647 4.38094ZM277.079 3.13369C274.252 1.40673 271.237 0.543254 268.033 0.543254C264.735 0.543254 261.673 1.40673 258.94 3.13369C256.208 4.86065 253.993 7.16326 252.344 10.1375C250.743 13.1117 249.942 16.3257 249.942 19.7796C249.942 21.5066 250.13 23.1856 250.507 24.7686C250.884 26.3997 251.496 27.9347 252.344 29.4218C253.946 32.396 256.114 34.6986 258.94 36.4256C261.72 38.1526 264.735 38.9681 268.033 38.9681C271.237 38.9681 274.252 38.1526 277.079 36.4256C279.859 34.6986 282.073 32.396 283.675 29.4218C285.277 26.4956 286.125 23.2815 286.125 19.7796C286.125 16.2778 285.277 13.0637 283.675 10.1375C282.073 7.21123 279.859 4.86065 277.079 3.13369Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M316.463 0.543254C316.274 0.543254 316.086 0.639196 315.945 0.78311L295.45 25.1044V1.26282C295.45 1.07094 295.356 0.879052 295.214 0.78311C295.073 0.687168 294.932 0.591225 294.743 0.591225C294.555 0.591225 294.366 0.687168 294.225 0.78311C294.084 0.879052 294.037 1.07094 294.037 1.26282V38.2485C294.037 38.4884 294.084 38.6802 294.225 38.7762C294.366 38.8721 294.555 38.9201 294.743 38.9201C294.932 38.9201 295.073 38.8721 295.214 38.7762C295.356 38.6802 295.45 38.4884 295.45 38.2485V27.3111L301.339 20.3553L316.18 38.6802C316.321 38.8721 316.51 38.9681 316.698 38.9681C316.887 38.9681 317.028 38.9201 317.17 38.8242C317.311 38.7282 317.405 38.5363 317.452 38.3444C317.452 38.1526 317.405 37.9607 317.264 37.7688L302.281 19.252L316.981 1.74253C317.075 1.64659 317.17 1.4547 317.17 1.26282C317.17 1.07094 317.075 0.927023 316.934 0.78311C316.793 0.639196 316.651 0.543254 316.463 0.543254Z"
      fill="#181818"
    />
    <path
      d="M316.463 0.543254C316.274 0.543254 316.086 0.639196 315.945 0.78311L295.45 25.1044V1.26282C295.45 1.07094 295.356 0.879052 295.214 0.78311C295.073 0.687168 294.932 0.591225 294.743 0.591225C294.555 0.591225 294.366 0.687168 294.225 0.78311C294.084 0.879052 294.037 1.07094 294.037 1.26282V38.2485C294.037 38.4884 294.084 38.6802 294.225 38.7762C294.366 38.8721 294.555 38.9201 294.743 38.9201C294.932 38.9201 295.073 38.8721 295.214 38.7762C295.356 38.6802 295.45 38.4884 295.45 38.2485V27.3111L301.339 20.3553L316.18 38.6802C316.321 38.8721 316.51 38.9681 316.698 38.9681C316.887 38.9681 317.028 38.9201 317.17 38.8242C317.311 38.7282 317.405 38.5363 317.452 38.3444C317.452 38.1526 317.405 37.9607 317.264 37.7688L302.281 19.252L316.981 1.74253C317.075 1.64659 317.17 1.4547 317.17 1.26282C317.17 1.07094 317.075 0.927023 316.934 0.78311C316.793 0.639196 316.651 0.543254 316.463 0.543254Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M0 38.9681H32.4411L32.9373 30.5681H31.6347L31.3246 31.8313C30.5182 35.1786 29.8359 36.6312 24.1913 36.6312H16.7478C11.5994 36.6312 10.8551 35.6207 10.8551 29.305V9.72623C10.8551 3.4105 11.5994 2.39998 16.7478 2.39998V1.07368H0V2.39998C5.1484 2.39998 5.89275 3.4105 5.89275 9.72623V30.2524C5.89275 36.5681 5.1484 37.6418 0 37.6418V38.9681Z"
      fill="#181818"
    />
    <path
      d="M0 38.9681H32.4411L32.9373 30.5681H31.6347L31.3246 31.8313C30.5182 35.1786 29.8359 36.6312 24.1913 36.6312H16.7478C11.5994 36.6312 10.8551 35.6207 10.8551 29.305V9.72623C10.8551 3.4105 11.5994 2.39998 16.7478 2.39998V1.07368H0V2.39998C5.1484 2.39998 5.89275 3.4105 5.89275 9.72623V30.2524C5.89275 36.5681 5.1484 37.6418 0 37.6418V38.9681Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M78.0445 20.0209C78.0445 9.0315 68.7401 0 57.3888 0C45.9755 0 36.6712 9.0315 36.6712 20.0209C36.6712 31.0102 45.9755 40.0417 57.3888 40.0417C68.7401 40.0417 78.0445 31.0102 78.0445 20.0209ZM57.3888 2.46314C68.0578 2.46314 72.1517 12.1894 72.1517 20.0209C72.1517 27.8524 68.0578 37.5154 57.3888 37.5154C46.6578 37.5154 42.5639 27.8524 42.5639 20.0209C42.5639 12.1894 46.6578 2.46314 57.3888 2.46314Z"
      fill="#181818"
    />
    <path
      d="M78.0445 20.0209C78.0445 9.0315 68.7401 0 57.3888 0C45.9755 0 36.6712 9.0315 36.6712 20.0209C36.6712 31.0102 45.9755 40.0417 57.3888 40.0417C68.7401 40.0417 78.0445 31.0102 78.0445 20.0209ZM57.3888 2.46314C68.0578 2.46314 72.1517 12.1894 72.1517 20.0209C72.1517 27.8524 68.0578 37.5154 57.3888 37.5154C46.6578 37.5154 42.5639 27.8524 42.5639 20.0209C42.5639 12.1894 46.6578 2.46314 57.3888 2.46314Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M126.141 20.0209C126.141 9.0315 116.837 0 105.485 0C94.0722 0 84.7678 9.0315 84.7678 20.0209C84.7678 31.0102 94.0722 40.0417 105.485 40.0417C116.837 40.0417 126.141 31.0102 126.141 20.0209ZM105.485 2.46314C116.154 2.46314 120.248 12.1894 120.248 20.0209C120.248 27.8524 116.154 37.5154 105.485 37.5154C94.7545 37.5154 90.6606 27.8524 90.6606 20.0209C90.6606 12.1894 94.7545 2.46314 105.485 2.46314Z"
      fill="#181818"
    />
    <path
      d="M126.141 20.0209C126.141 9.0315 116.837 0 105.485 0C94.0722 0 84.7678 9.0315 84.7678 20.0209C84.7678 31.0102 94.0722 40.0417 105.485 40.0417C116.837 40.0417 126.141 31.0102 126.141 20.0209ZM105.485 2.46314C116.154 2.46314 120.248 12.1894 120.248 20.0209C120.248 27.8524 116.154 37.5154 105.485 37.5154C94.7545 37.5154 90.6606 27.8524 90.6606 20.0209C90.6606 12.1894 94.7545 2.46314 105.485 2.46314Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M147.875 16.9893L158.73 7.51572C163.073 3.72628 165.306 2.39998 170.082 2.39998V1.07368H155.691V2.39998C159.599 2.39998 159.351 4.10523 155.443 7.51572L142.727 18.5683V9.72623C142.727 3.4105 143.471 2.39998 148.62 2.39998V1.07368H131.872V2.39998C137.02 2.39998 137.765 3.4105 137.765 9.72623V30.2524C137.765 36.5681 137.02 37.6418 131.872 37.6418V38.9681H148.62V37.6418C143.471 37.6418 142.727 36.5681 142.727 30.2524V21.4735L144.34 20.1472L160.033 33.9786C162.514 36.126 161.77 37.6418 157.676 37.6418V38.9681H175.106V37.6418C173.431 37.6418 169.399 35.9997 167.104 33.9786L147.875 16.9893Z"
      fill="#181818"
    />
    <path
      d="M147.875 16.9893L158.73 7.51572C163.073 3.72628 165.306 2.39998 170.082 2.39998V1.07368H155.691V2.39998C159.599 2.39998 159.351 4.10523 155.443 7.51572L142.727 18.5683V9.72623C142.727 3.4105 143.471 2.39998 148.62 2.39998V1.07368H131.872V2.39998C137.02 2.39998 137.765 3.4105 137.765 9.72623V30.2524C137.765 36.5681 137.02 37.6418 131.872 37.6418V38.9681H148.62V37.6418C143.471 37.6418 142.727 36.5681 142.727 30.2524V21.4735L144.34 20.1472L160.033 33.9786C162.514 36.126 161.77 37.6418 157.676 37.6418V38.9681H175.106V37.6418C173.431 37.6418 169.399 35.9997 167.104 33.9786L147.875 16.9893Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path d="M0.867474 62.7815V55.0698H1.88803V62.7815H0.867474Z" fill="#181818" />
    <path d="M0.867474 62.7815V55.0698H1.88803V62.7815H0.867474Z" fill="black" fill-opacity="0.2" />
    <path
      d="M12.6978 62.7815V55.0698H13.2846L17.8388 60.815V55.0698H18.7701V62.7815H18.1833L13.629 57.0363V62.7815H12.6978Z"
      fill="#181818"
    />
    <path
      d="M12.6978 62.7815V55.0698H13.2846L17.8388 60.815V55.0698H18.7701V62.7815H18.1833L13.629 57.0363V62.7815H12.6978Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M31.7419 62.9486C31.1721 62.9486 30.6661 62.8586 30.2238 62.6787C29.7816 62.4902 29.4371 62.2674 29.1905 62.0103L29.4201 61.0592C30.0665 61.7618 30.8404 62.1132 31.7419 62.1132C32.2097 62.1132 32.5924 61.9932 32.89 61.7533C33.1962 61.5048 33.3493 61.1749 33.3493 60.7636C33.3493 60.3609 33.2217 60.0396 32.9666 59.7996C32.7114 59.5597 32.2352 59.3455 31.5378 59.157C30.1175 58.78 29.4074 58.0302 29.4074 56.9077C29.4074 56.3422 29.6285 55.8666 30.0707 55.4811C30.513 55.0955 31.1126 54.9027 31.8695 54.9027C32.6859 54.9027 33.3791 55.1383 33.9489 55.6096L33.7192 56.4193C33.0984 55.9652 32.4818 55.7381 31.8695 55.7381C31.3932 55.7381 31.0233 55.8452 30.7596 56.0594C30.496 56.2651 30.3642 56.5478 30.3642 56.9077C30.3642 57.2076 30.479 57.469 30.7086 57.6918C30.9382 57.906 31.3379 58.0902 31.9077 58.2444C32.7412 58.4672 33.3493 58.7714 33.732 59.157C34.1147 59.534 34.3061 60.0395 34.3061 60.6736C34.3061 61.3848 34.0552 61.9418 33.5534 62.3445C33.0516 62.7472 32.4478 62.9486 31.7419 62.9486Z"
      fill="#181818"
    />
    <path
      d="M31.7419 62.9486C31.1721 62.9486 30.6661 62.8586 30.2238 62.6787C29.7816 62.4902 29.4371 62.2674 29.1905 62.0103L29.4201 61.0592C30.0665 61.7618 30.8404 62.1132 31.7419 62.1132C32.2097 62.1132 32.5924 61.9932 32.89 61.7533C33.1962 61.5048 33.3493 61.1749 33.3493 60.7636C33.3493 60.3609 33.2217 60.0396 32.9666 59.7996C32.7114 59.5597 32.2352 59.3455 31.5378 59.157C30.1175 58.78 29.4074 58.0302 29.4074 56.9077C29.4074 56.3422 29.6285 55.8666 30.0707 55.4811C30.513 55.0955 31.1126 54.9027 31.8695 54.9027C32.6859 54.9027 33.3791 55.1383 33.9489 55.6096L33.7192 56.4193C33.0984 55.9652 32.4818 55.7381 31.8695 55.7381C31.3932 55.7381 31.0233 55.8452 30.7596 56.0594C30.496 56.2651 30.3642 56.5478 30.3642 56.9077C30.3642 57.2076 30.479 57.469 30.7086 57.6918C30.9382 57.906 31.3379 58.0902 31.9077 58.2444C32.7412 58.4672 33.3493 58.7714 33.732 59.157C34.1147 59.534 34.3061 60.0395 34.3061 60.6736C34.3061 61.3848 34.0552 61.9418 33.5534 62.3445C33.0516 62.7472 32.4478 62.9486 31.7419 62.9486Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path d="M44.7425 62.7815V55.0698H45.7631V62.7815H44.7425Z" fill="#181818" />
    <path d="M44.7425 62.7815V55.0698H45.7631V62.7815H44.7425Z" fill="black" fill-opacity="0.2" />
    <path
      d="M60.2723 63C59.1327 63 58.1674 62.6273 57.3765 61.8818C56.5941 61.1278 56.2029 60.1424 56.2029 58.9256C56.2029 57.7175 56.5941 56.7449 57.3765 56.008C58.1674 55.2711 59.1327 54.9027 60.2723 54.9027C60.7571 54.9027 61.2376 54.9755 61.7139 55.1212C62.1987 55.2668 62.6026 55.4639 62.9258 55.7124V56.7792C62.1774 56.1023 61.2929 55.7638 60.2723 55.7638C59.3794 55.7638 58.6522 56.0551 58.0909 56.6378C57.5381 57.2119 57.2617 57.9745 57.2617 58.9256C57.2617 59.8767 57.5424 60.6522 58.1037 61.252C58.665 61.8432 59.3879 62.1389 60.2723 62.1389C61.0548 62.1389 61.7054 61.9889 62.2242 61.689V59.941H60.6551V59.0799H63.2447V62.1903C63.2362 62.1988 63.1299 62.2502 62.9258 62.3445C62.7217 62.4302 62.5473 62.503 62.4028 62.563C62.2582 62.6144 62.0626 62.683 61.8159 62.7687C61.5778 62.8458 61.3269 62.9015 61.0633 62.9357C60.7996 62.9786 60.536 63 60.2723 63Z"
      fill="#181818"
    />
    <path
      d="M60.2723 63C59.1327 63 58.1674 62.6273 57.3765 61.8818C56.5941 61.1278 56.2029 60.1424 56.2029 58.9256C56.2029 57.7175 56.5941 56.7449 57.3765 56.008C58.1674 55.2711 59.1327 54.9027 60.2723 54.9027C60.7571 54.9027 61.2376 54.9755 61.7139 55.1212C62.1987 55.2668 62.6026 55.4639 62.9258 55.7124V56.7792C62.1774 56.1023 61.2929 55.7638 60.2723 55.7638C59.3794 55.7638 58.6522 56.0551 58.0909 56.6378C57.5381 57.2119 57.2617 57.9745 57.2617 58.9256C57.2617 59.8767 57.5424 60.6522 58.1037 61.252C58.665 61.8432 59.3879 62.1389 60.2723 62.1389C61.0548 62.1389 61.7054 61.9889 62.2242 61.689V59.941H60.6551V59.0799H63.2447V62.1903C63.2362 62.1988 63.1299 62.2502 62.9258 62.3445C62.7217 62.4302 62.5473 62.503 62.4028 62.563C62.2582 62.6144 62.0626 62.683 61.8159 62.7687C61.5778 62.8458 61.3269 62.9015 61.0633 62.9357C60.7996 62.9786 60.536 63 60.2723 63Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M74.059 62.7815V55.0698H75.0796V58.283H79.3021V55.0698H80.3227V62.7815H79.3021V59.1441H75.0796V62.7815H74.059Z"
      fill="#181818"
    />
    <path
      d="M74.059 62.7815V55.0698H75.0796V58.283H79.3021V55.0698H80.3227V62.7815H79.3021V59.1441H75.0796V62.7815H74.059Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M93.137 62.7815V55.9309H90.4197V55.0698H96.8748V55.9309H94.1575V62.7815H93.137Z"
      fill="#181818"
    />
    <path
      d="M93.137 62.7815V55.9309H90.4197V55.0698H96.8748V55.9309H94.1575V62.7815H93.137Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M108.87 62.9486C108.3 62.9486 107.794 62.8586 107.352 62.6787C106.91 62.4902 106.565 62.2674 106.319 62.0103L106.548 61.0592C107.195 61.7618 107.969 62.1132 108.87 62.1132C109.338 62.1132 109.721 61.9932 110.018 61.7533C110.324 61.5048 110.478 61.1749 110.478 60.7636C110.478 60.3609 110.35 60.0396 110.095 59.7996C109.84 59.5597 109.363 59.3455 108.666 59.157C107.246 58.78 106.536 58.0302 106.536 56.9077C106.536 56.3422 106.757 55.8666 107.199 55.4811C107.641 55.0955 108.241 54.9027 108.998 54.9027C109.814 54.9027 110.507 55.1383 111.077 55.6096L110.848 56.4193C110.227 55.9652 109.61 55.7381 108.998 55.7381C108.521 55.7381 108.152 55.8452 107.888 56.0594C107.624 56.2651 107.492 56.5478 107.492 56.9077C107.492 57.2076 107.607 57.469 107.837 57.6918C108.066 57.906 108.466 58.0902 109.036 58.2444C109.869 58.4672 110.478 58.7714 110.86 59.157C111.243 59.534 111.434 60.0395 111.434 60.6736C111.434 61.3848 111.183 61.9418 110.682 62.3445C110.18 62.7472 109.576 62.9486 108.87 62.9486Z"
      fill="#181818"
    />
    <path
      d="M108.87 62.9486C108.3 62.9486 107.794 62.8586 107.352 62.6787C106.91 62.4902 106.565 62.2674 106.319 62.0103L106.548 61.0592C107.195 61.7618 107.969 62.1132 108.87 62.1132C109.338 62.1132 109.721 61.9932 110.018 61.7533C110.324 61.5048 110.478 61.1749 110.478 60.7636C110.478 60.3609 110.35 60.0396 110.095 59.7996C109.84 59.5597 109.363 59.3455 108.666 59.157C107.246 58.78 106.536 58.0302 106.536 56.9077C106.536 56.3422 106.757 55.8666 107.199 55.4811C107.641 55.0955 108.241 54.9027 108.998 54.9027C109.814 54.9027 110.507 55.1383 111.077 55.6096L110.848 56.4193C110.227 55.9652 109.61 55.7381 108.998 55.7381C108.521 55.7381 108.152 55.8452 107.888 56.0594C107.624 56.2651 107.492 56.5478 107.492 56.9077C107.492 57.2076 107.607 57.469 107.837 57.6918C108.066 57.906 108.466 58.0902 109.036 58.2444C109.869 58.4672 110.478 58.7714 110.86 59.157C111.243 59.534 111.434 60.0395 111.434 60.6736C111.434 61.3848 111.183 61.9418 110.682 62.3445C110.18 62.7472 109.576 62.9486 108.87 62.9486Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M133.153 62.7815V55.0698H135.679C136.419 55.0698 136.972 55.2583 137.337 55.6353C137.711 56.0123 137.899 56.4964 137.899 57.0877C137.899 57.3704 137.822 57.6489 137.669 57.9231C137.516 58.1973 137.324 58.4244 137.095 58.6043C137.461 58.7671 137.754 59.0242 137.975 59.3755C138.196 59.7268 138.307 60.1081 138.307 60.5194C138.307 61.1877 138.098 61.7318 137.682 62.1517C137.265 62.5716 136.64 62.7815 135.806 62.7815H133.153ZM134.174 61.9204H135.806C136.334 61.9204 136.712 61.7875 136.942 61.5219C137.18 61.2563 137.299 60.9221 137.299 60.5194C137.299 60.1167 137.18 59.7825 136.942 59.5169C136.712 59.2512 136.334 59.1184 135.806 59.1184H134.174V61.9204ZM134.174 58.2573H135.679C136.121 58.2573 136.44 58.1459 136.636 57.9231C136.831 57.7003 136.929 57.4218 136.929 57.0877C136.929 56.7535 136.831 56.4793 136.636 56.2651C136.44 56.0423 136.121 55.9309 135.679 55.9309H134.174V58.2573Z"
      fill="#181818"
    />
    <path
      d="M133.153 62.7815V55.0698H135.679C136.419 55.0698 136.972 55.2583 137.337 55.6353C137.711 56.0123 137.899 56.4964 137.899 57.0877C137.899 57.3704 137.822 57.6489 137.669 57.9231C137.516 58.1973 137.324 58.4244 137.095 58.6043C137.461 58.7671 137.754 59.0242 137.975 59.3755C138.196 59.7268 138.307 60.1081 138.307 60.5194C138.307 61.1877 138.098 61.7318 137.682 62.1517C137.265 62.5716 136.64 62.7815 135.806 62.7815H133.153ZM134.174 61.9204H135.806C136.334 61.9204 136.712 61.7875 136.942 61.5219C137.18 61.2563 137.299 60.9221 137.299 60.5194C137.299 60.1167 137.18 59.7825 136.942 59.5169C136.712 59.2512 136.334 59.1184 135.806 59.1184H134.174V61.9204ZM134.174 58.2573H135.679C136.121 58.2573 136.44 58.1459 136.636 57.9231C136.831 57.7003 136.929 57.4218 136.929 57.0877C136.929 56.7535 136.831 56.4793 136.636 56.2651C136.44 56.0423 136.121 55.9309 135.679 55.9309H134.174V58.2573Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M148.671 62.7815V55.0698H153.302V55.9309H149.691V58.283H152.664V59.1441H149.691V61.9204H153.302V62.7815H148.671Z"
      fill="#181818"
    />
    <path
      d="M148.671 62.7815V55.0698H153.302V55.9309H149.691V58.283H152.664V59.1441H149.691V61.9204H153.302V62.7815H148.671Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M166.059 62.7815V59.6968L163.061 55.0698H164.171L166.594 58.8614L169.018 55.0698H170.077L167.079 59.6968V62.7815H166.059Z"
      fill="#181818"
    />
    <path
      d="M166.059 62.7815V59.6968L163.061 55.0698H164.171L166.594 58.8614L169.018 55.0698H170.077L167.079 59.6968V62.7815H166.059Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M185.916 61.8561C185.151 62.6187 184.181 63 183.007 63C181.834 63 180.86 62.6187 180.086 61.8561C179.321 61.0849 178.938 60.1081 178.938 58.9256C178.938 57.7432 179.321 56.7706 180.086 56.008C180.86 55.2368 181.834 54.8513 183.007 54.8513C184.181 54.8513 185.151 55.2368 185.916 56.008C186.681 56.7706 187.064 57.7432 187.064 58.9256C187.064 60.1081 186.681 61.0849 185.916 61.8561ZM180.839 61.2391C181.408 61.839 182.131 62.1389 183.007 62.1389C183.883 62.1389 184.602 61.839 185.163 61.2391C185.725 60.6308 186.005 59.8596 186.005 58.9256C186.005 57.9916 185.725 57.2248 185.163 56.625C184.602 56.0166 183.883 55.7124 183.007 55.7124C182.131 55.7124 181.408 56.0123 180.839 56.6121C180.277 57.2119 179.997 57.9831 179.997 58.9256C179.997 59.8596 180.277 60.6308 180.839 61.2391Z"
      fill="#181818"
    />
    <path
      d="M185.916 61.8561C185.151 62.6187 184.181 63 183.007 63C181.834 63 180.86 62.6187 180.086 61.8561C179.321 61.0849 178.938 60.1081 178.938 58.9256C178.938 57.7432 179.321 56.7706 180.086 56.008C180.86 55.2368 181.834 54.8513 183.007 54.8513C184.181 54.8513 185.151 55.2368 185.916 56.008C186.681 56.7706 187.064 57.7432 187.064 58.9256C187.064 60.1081 186.681 61.0849 185.916 61.8561ZM180.839 61.2391C181.408 61.839 182.131 62.1389 183.007 62.1389C183.883 62.1389 184.602 61.839 185.163 61.2391C185.725 60.6308 186.005 59.8596 186.005 58.9256C186.005 57.9916 185.725 57.2248 185.163 56.625C184.602 56.0166 183.883 55.7124 183.007 55.7124C182.131 55.7124 181.408 56.0123 180.839 56.6121C180.277 57.2119 179.997 57.9831 179.997 58.9256C179.997 59.8596 180.277 60.6308 180.839 61.2391Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M197.504 62.7815V55.0698H198.091L202.645 60.815V55.0698H203.576V62.7815H202.99L198.435 57.0363V62.7815H197.504Z"
      fill="#181818"
    />
    <path
      d="M197.504 62.7815V55.0698H198.091L202.645 60.815V55.0698H203.576V62.7815H202.99L198.435 57.0363V62.7815H197.504Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M214.392 62.7815V55.0698H216.765C218.177 55.0698 219.24 55.4296 219.954 56.1494C220.669 56.8692 221.026 57.7946 221.026 58.9256C221.026 60.0567 220.669 60.9821 219.954 61.7019C219.24 62.4216 218.177 62.7815 216.765 62.7815H214.392ZM215.413 61.8818H216.778C217.926 61.8818 218.742 61.6076 219.227 61.0592C219.721 60.5108 219.967 59.7996 219.967 58.9256C219.967 58.0516 219.721 57.3404 219.227 56.792C218.742 56.2437 217.926 55.9695 216.778 55.9695H215.413V61.8818Z"
      fill="#181818"
    />
    <path
      d="M214.392 62.7815V55.0698H216.765C218.177 55.0698 219.24 55.4296 219.954 56.1494C220.669 56.8692 221.026 57.7946 221.026 58.9256C221.026 60.0567 220.669 60.9821 219.954 61.7019C219.24 62.4216 218.177 62.7815 216.765 62.7815H214.392ZM215.413 61.8818H216.778C217.926 61.8818 218.742 61.6076 219.227 61.0592C219.721 60.5108 219.967 59.7996 219.967 58.9256C219.967 58.0516 219.721 57.3404 219.227 56.792C218.742 56.2437 217.926 55.9695 216.778 55.9695H215.413V61.8818Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M244.65 62.7815L242.01 55.0698H243.056L244.893 60.7636C244.91 60.815 244.931 60.905 244.957 61.0335C244.982 61.162 244.995 61.2306 244.995 61.2391C244.995 61.1535 245.037 60.9949 245.122 60.7636L246.934 55.0698H247.572L249.383 60.7636L249.511 61.2391C249.545 61.0164 249.579 60.8578 249.613 60.7636L251.45 55.0698H252.445L249.804 62.7815H249.166L247.355 57.499C247.346 57.4647 247.329 57.4176 247.304 57.3576C247.287 57.289 247.27 57.229 247.253 57.1776C247.236 57.1177 247.227 57.0834 247.227 57.0748C247.227 57.0834 247.219 57.1177 247.202 57.1776C247.185 57.229 247.163 57.289 247.138 57.3576C247.121 57.4261 247.108 57.4733 247.1 57.499L245.288 62.7815H244.65Z"
      fill="#181818"
    />
    <path
      d="M244.65 62.7815L242.01 55.0698H243.056L244.893 60.7636C244.91 60.815 244.931 60.905 244.957 61.0335C244.982 61.162 244.995 61.2306 244.995 61.2391C244.995 61.1535 245.037 60.9949 245.122 60.7636L246.934 55.0698H247.572L249.383 60.7636L249.511 61.2391C249.545 61.0164 249.579 60.8578 249.613 60.7636L251.45 55.0698H252.445L249.804 62.7815H249.166L247.355 57.499C247.346 57.4647 247.329 57.4176 247.304 57.3576C247.287 57.289 247.27 57.229 247.253 57.1776C247.236 57.1177 247.227 57.0834 247.227 57.0748C247.227 57.0834 247.219 57.1177 247.202 57.1776C247.185 57.229 247.163 57.289 247.138 57.3576C247.121 57.4261 247.108 57.4733 247.1 57.499L245.288 62.7815H244.65Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M268.875 61.8561C268.109 62.6187 267.14 63 265.966 63C264.792 63 263.819 62.6187 263.045 61.8561C262.279 61.0849 261.896 60.1081 261.896 58.9256C261.896 57.7432 262.279 56.7706 263.045 56.008C263.819 55.2368 264.792 54.8513 265.966 54.8513C267.14 54.8513 268.109 55.2368 268.875 56.008C269.64 56.7706 270.023 57.7432 270.023 58.9256C270.023 60.1081 269.64 61.0849 268.875 61.8561ZM263.797 61.2391C264.367 61.839 265.09 62.1389 265.966 62.1389C266.842 62.1389 267.561 61.839 268.122 61.2391C268.683 60.6308 268.964 59.8596 268.964 58.9256C268.964 57.9916 268.683 57.2248 268.122 56.625C267.561 56.0166 266.842 55.7124 265.966 55.7124C265.09 55.7124 264.367 56.0123 263.797 56.6121C263.236 57.2119 262.955 57.9831 262.955 58.9256C262.955 59.8596 263.236 60.6308 263.797 61.2391Z"
      fill="#181818"
    />
    <path
      d="M268.875 61.8561C268.109 62.6187 267.14 63 265.966 63C264.792 63 263.819 62.6187 263.045 61.8561C262.279 61.0849 261.896 60.1081 261.896 58.9256C261.896 57.7432 262.279 56.7706 263.045 56.008C263.819 55.2368 264.792 54.8513 265.966 54.8513C267.14 54.8513 268.109 55.2368 268.875 56.008C269.64 56.7706 270.023 57.7432 270.023 58.9256C270.023 60.1081 269.64 61.0849 268.875 61.8561ZM263.797 61.2391C264.367 61.839 265.09 62.1389 265.966 62.1389C266.842 62.1389 267.561 61.839 268.122 61.2391C268.683 60.6308 268.964 59.8596 268.964 58.9256C268.964 57.9916 268.683 57.2248 268.122 56.625C267.561 56.0166 266.842 55.7124 265.966 55.7124C265.09 55.7124 264.367 56.0123 263.797 56.6121C263.236 57.2119 262.955 57.9831 262.955 58.9256C262.955 59.8596 263.236 60.6308 263.797 61.2391Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M280.463 62.7815V55.0698H282.989C283.822 55.0698 284.447 55.2797 284.864 55.6996C285.281 56.1194 285.489 56.6635 285.489 57.3319C285.489 57.8546 285.357 58.3087 285.094 58.6943C284.83 59.0713 284.434 59.3326 283.907 59.4783L286.229 62.7815H285.042L282.848 59.594H281.483V62.7815H280.463ZM281.483 58.7328H282.989C283.516 58.7328 283.894 58.6 284.124 58.3344C284.362 58.0688 284.481 57.7346 284.481 57.3319C284.481 56.9291 284.362 56.595 284.124 56.3293C283.894 56.0637 283.516 55.9309 282.989 55.9309H281.483V58.7328Z"
      fill="#181818"
    />
    <path
      d="M280.463 62.7815V55.0698H282.989C283.822 55.0698 284.447 55.2797 284.864 55.6996C285.281 56.1194 285.489 56.6635 285.489 57.3319C285.489 57.8546 285.357 58.3087 285.094 58.6943C284.83 59.0713 284.434 59.3326 283.907 59.4783L286.229 62.7815H285.042L282.848 59.594H281.483V62.7815H280.463ZM281.483 58.7328H282.989C283.516 58.7328 283.894 58.6 284.124 58.3344C284.362 58.0688 284.481 57.7346 284.481 57.3319C284.481 56.9291 284.362 56.595 284.124 56.3293C283.894 56.0637 283.516 55.9309 282.989 55.9309H281.483V58.7328Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M296.205 62.7815V55.0698H298.578C299.989 55.0698 301.052 55.4296 301.767 56.1494C302.481 56.8692 302.838 57.7946 302.838 58.9256C302.838 60.0567 302.481 60.9821 301.767 61.7019C301.052 62.4216 299.989 62.7815 298.578 62.7815H296.205ZM297.225 61.8818H298.59C299.739 61.8818 300.555 61.6076 301.04 61.0592C301.533 60.5108 301.78 59.7996 301.78 58.9256C301.78 58.0516 301.533 57.3404 301.04 56.792C300.555 56.2437 299.739 55.9695 298.59 55.9695H297.225V61.8818Z"
      fill="#181818"
    />
    <path
      d="M296.205 62.7815V55.0698H298.578C299.989 55.0698 301.052 55.4296 301.767 56.1494C302.481 56.8692 302.838 57.7946 302.838 58.9256C302.838 60.0567 302.481 60.9821 301.767 61.7019C301.052 62.4216 299.989 62.7815 298.578 62.7815H296.205ZM297.225 61.8818H298.59C299.739 61.8818 300.555 61.6076 301.04 61.0592C301.533 60.5108 301.78 59.7996 301.78 58.9256C301.78 58.0516 301.533 57.3404 301.04 56.792C300.555 56.2437 299.739 55.9695 298.59 55.9695H297.225V61.8818Z"
      fill="black"
      fill-opacity="0.2"
    />
    <path
      d="M315.436 62.9486C314.866 62.9486 314.36 62.8586 313.918 62.6787C313.476 62.4902 313.131 62.2674 312.884 62.0103L313.114 61.0592C313.76 61.7618 314.534 62.1132 315.436 62.1132C315.904 62.1132 316.286 61.9932 316.584 61.7533C316.89 61.5048 317.043 61.1749 317.043 60.7636C317.043 60.3609 316.916 60.0396 316.661 59.7996C316.405 59.5597 315.929 59.3455 315.232 59.157C313.811 58.78 313.101 58.0302 313.101 56.9077C313.101 56.3422 313.322 55.8666 313.765 55.4811C314.207 55.0955 314.807 54.9027 315.563 54.9027C316.38 54.9027 317.073 55.1383 317.643 55.6096L317.413 56.4193C316.792 55.9652 316.176 55.7381 315.563 55.7381C315.087 55.7381 314.717 55.8452 314.454 56.0594C314.19 56.2651 314.058 56.5478 314.058 56.9077C314.058 57.2076 314.173 57.469 314.403 57.6918C314.632 57.906 315.032 58.0902 315.602 58.2444C316.435 58.4672 317.043 58.7714 317.426 59.157C317.809 59.534 318 60.0395 318 60.6736C318 61.3848 317.749 61.9418 317.247 62.3445C316.746 62.7472 316.142 62.9486 315.436 62.9486Z"
      fill="#181818"
    />
    <path
      d="M315.436 62.9486C314.866 62.9486 314.36 62.8586 313.918 62.6787C313.476 62.4902 313.131 62.2674 312.884 62.0103L313.114 61.0592C313.76 61.7618 314.534 62.1132 315.436 62.1132C315.904 62.1132 316.286 61.9932 316.584 61.7533C316.89 61.5048 317.043 61.1749 317.043 60.7636C317.043 60.3609 316.916 60.0396 316.661 59.7996C316.405 59.5597 315.929 59.3455 315.232 59.157C313.811 58.78 313.101 58.0302 313.101 56.9077C313.101 56.3422 313.322 55.8666 313.765 55.4811C314.207 55.0955 314.807 54.9027 315.563 54.9027C316.38 54.9027 317.073 55.1383 317.643 55.6096L317.413 56.4193C316.792 55.9652 316.176 55.7381 315.563 55.7381C315.087 55.7381 314.717 55.8452 314.454 56.0594C314.19 56.2651 314.058 56.5478 314.058 56.9077C314.058 57.2076 314.173 57.469 314.403 57.6918C314.632 57.906 315.032 58.0902 315.602 58.2444C316.435 58.4672 317.043 58.7714 317.426 59.157C317.809 59.534 318 60.0395 318 60.6736C318 61.3848 317.749 61.9418 317.247 62.3445C316.746 62.7472 316.142 62.9486 315.436 62.9486Z"
      fill="black"
      fill-opacity="0.2"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoBigIcon'
}
</script>
