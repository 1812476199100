<template>
  <svg
    class="ForumReplyIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_401_6)">
      <path
        d="M9.58554 18.8968C8.99021 18.6264 8.81712 17.8494 9.24389 17.3634C9.54712 17.018 9.26783 17.0417 13.3594 17.0137C16.9399 16.9892 17.0487 16.9858 17.3978 16.8879C18.6445 16.5383 19.6049 15.5503 19.9064 14.3075C20.0069 13.8936 20.0069 13.1062 19.9064 12.6922C19.6052 11.4503 18.6481 10.4654 17.3978 10.1107C17.0396 10.009 17.0306 10.0088 11.2295 9.99514L5.4199 9.98141L6.12362 10.6981C6.9185 11.5075 7.01927 11.6749 6.96876 12.1013C6.89312 12.74 6.23961 13.1388 5.63704 12.9141C5.44968 12.8442 5.16108 12.5792 3.83042 11.2552C2.13225 9.56561 2.01562 9.42067 2.01562 8.99986C2.01562 8.57906 2.13225 8.43411 3.83042 6.74451C5.16108 5.42055 5.44968 5.15555 5.63704 5.08567C6.23961 4.86092 6.89312 5.25974 6.96876 5.89839C7.01924 6.32458 6.9183 6.49239 6.12635 7.29888L5.42536 8.01275L11.4197 8.02764C17.3888 8.04247 17.4155 8.04296 17.7656 8.1432C18.8575 8.45585 19.5579 8.83797 20.2561 9.50187C21.3813 10.5718 21.9844 11.967 21.9844 13.4999C21.9844 16.1809 20.0636 18.4585 17.4196 18.9127C17.0706 18.9727 16.4191 18.9841 13.3884 18.9832C9.99315 18.9822 9.76202 18.977 9.58554 18.8968Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_401_6">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ForumReplyIcon'
}
</script>

<style lang="scss" scoped>
.ForumReplyIcon {
  @apply fill-current;
}
</style>
