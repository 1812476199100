import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import user from './modules/user'
import studies from './modules/studies'
import studyMessages from './modules/studyMessages'
import chats from './modules/chats'
import study from './modules/study'
import completeRegistration from './modules/completeRegistration'
import ui from './modules/ui'
import fileUpload from './modules/fileUpload'
import unsavedWarning from './modules/unsavedWarning'
import unansweredQuestions from './modules/unansweredQuestions'
import infoPopup from './modules/infoPopup'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appData: null,
    currentTime: 0
  },
  getters: {
    genders(state) {
      return (state.appData && state.appData.genders) || {}
    },
    gendersArray(state, getters) {
      const genders = getters.genders
      return Object.keys(genders).map((key) => {
        return { label: key, value: genders[key] }
      })
    }
  },
  mutations: {
    SET_APP_DATA(state, data) {
      state.appData = data
    },
    INCREMENT_CURRENT_TIME(state) {
      state.currentTime += 1000
    },
    SET_CURRENT_TIME(state, currentTime) {
      state.currentTime = currentTime
    }
  },
  actions: {
    async getAppData({ commit }) {
      const response = await api.appData.get()
      commit('SET_APP_DATA', response)
      commit('SET_CURRENT_TIME', response?.currentTime)
    }
  },
  modules: {
    auth,
    user,
    chats,
    studies,
    study,
    ui,
    completeRegistration,
    studyMessages,
    fileUpload,
    unsavedWarning,
    unansweredQuestions,
    infoPopup
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'user', 'ui', 'completeRegistration', 'chats.threadSort', 'studyMessages.savedMessages']
    })
  ]
})
