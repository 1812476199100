<template>
  <div class="AuthAccessor">
    <div class="AuthAccessor__loader">
      <LoadingIcon></LoadingIcon>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
export default {
  name: 'AuthAccessor',
  components: { LoadingIcon },
  async created() {
    try {
      await this.$store.dispatch('auth/logOut')

      await this.$store.dispatch('auth/setToken', { token: `Bearer ${this.$route.params.token}` })

      let newLocale = String(this.$route.query.lang)

      if (newLocale) {
        if (!this.$i18n.availableLocales.find((l) => l === newLocale)) newLocale = 'en'
        this.$i18n.locale = newLocale
        this.$store.commit('ui/SET_LOCALE', newLocale)

        await this.$store.dispatch('user/syncUserLanguage', {
          preferredLanguage: newLocale
        })
      }

      const studyToJoin = !!Number(this.$route.query?.studyId) ? this.$route.query?.studyId : undefined
      const chatToJoin = !!Number(this.$route.query?.chatId) ? this.$route.query?.chatId : undefined
      if (studyToJoin) {
        this.$store.commit('completeRegistration/SET_DESTINATION_STUDY', { studyId: studyToJoin })
      } else if (chatToJoin) {
        this.$store.commit('completeRegistration/SET_DESTINATION_CHAT', { chatId: chatToJoin })
      }

      this.$router.replace('/')
    } catch (e) {
      console.log(e)
      alert('Error')
    }
  }
}
</script>

<style lang="scss" scoped>
.AuthAccessor {
  @apply h-full w-full flex items-center justify-center fixed;
}
</style>
