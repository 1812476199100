<template>
  <div class="LLPagination">
    <div class="LLPagination__card-wrapper">
      <button
        class="LLPagination__card"
        :disabled="current === 1 || disabled || loading"
        :class="{ LLPagination__card_disabled: current === 1 || disabled || loading }"
        @click="goToPrevious"
      >
        &lt;
      </button>
    </div>
    <div v-for="(card, index) in cards" :key="index" class="LLPagination__card-wrapper">
      <button
        v-if="card.type === 'page'"
        class="LLPagination__card"
        :disabled="disabled || loading"
        :class="{
          LLPagination__card_disabled: disabled || loading,
          LLPagination__card_active: card.type === 'page' && card.page === current
        }"
        @click="goToPage(card.page)"
      >
        {{ card.page }}
      </button>
      <div v-if="card.type === 'skipper'" class="LLPagination__skipper">...</div>
    </div>
    <div class="LLPagination__card-wrapper">
      <button
        class="LLPagination__card"
        :disabled="current === pages || disabled || loading"
        :class="{ LLPagination__card_disabled: current === pages || disabled || loading }"
        @click="goToNext"
      >
        &gt;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLPagination',
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    current: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    perPage: { type: Number, default: 0 }
  },
  data() {
    return {
      cards: []
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.perPage)
    }
  },
  watch: {
    current() {
      this.render()
    },
    total() {
      this.render()
    },
    perPage() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      this.cards = []
      for (let i = 1; i <= this.pages; i++) {
        if (Math.abs(this.current - i) < 2 || i === 1 || i === this.pages) {
          this.cards.push({ type: 'page', page: i })
        } else if (this.cards[this.cards.length - 1]?.type !== 'skipper') {
          this.cards.push({ type: 'skipper' })
        }
      }
    },
    goToPage(page) {
      if (page !== this.current) {
        this.$emit('goToPage', page)
      }
    },
    goToPrevious() {
      if (this.current !== 1) {
        this.goToPage(this.current - 1)
      }
    },
    goToNext() {
      if (this.current !== this.pages) {
        this.goToPage(this.current + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLPagination {
  @apply flex;
  &__card-wrapper {
    @apply ml-3;
    &:first-child {
      @apply ml-0;
    }
  }
  &__card {
    user-select: none;
    transition-duration: 0.2s;
    transition-property: color, border-color, background-color;
    @apply h-10 border px-2 border-neutral-01-50 text-primary-01-400  flex-shrink-0 flex items-center justify-center rounded cursor-pointer;
    min-width: 40px;
    &:hover {
      @apply bg-neutral-01-15 border-neutral-01-200;
    }
    &:focus-visible {
      @apply bg-neutral-01-15 border-neutral-01-200;
    }
    &_disabled {
      @apply pointer-events-none border-neutral-01-50 text-neutral-01-200;
    }
    &_active {
      @apply border-primary-01-400;
      &:hover {
        @apply border-primary-01-400;
      }
    }
  }
  &__skipper {
    @apply flex items-center h-10;
  }
}
</style>
