<template>
  <div class="ForumThreadPageAside">
    <div class="ForumThreadPageAside__content">
      <div ref="scrollable" class="ForumThreadPageAside__scrollable" @scroll="onScroll">
        <ForumChatSmallDetails
          v-if="tailwindScreens.sm"
          class="ForumThreadPageAside__chat-details"
          :chat="chat"
          compact
        />
        <div v-if="tailwindScreens.sm" class="ForumThreadPageAside__return-button-wrapper">
          <router-link tag="a" :to="`/chats/${chat.id}`">
            <LLButton tertiary>
              {{ $t('forum.thread_page.aside.home') }}
              <template #icon-left> <HomeIcon /> </template
            ></LLButton>
          </router-link>
        </div>
        <div v-if="!tailwindScreens.sm" class="ForumThreadPageAside__fixed-panel-helper"></div>
        <ForumThreadPageAsideThreads
          :chat="chat"
          :thread="thread"
          class="ForumThreadPageAside__threads"
        ></ForumThreadPageAsideThreads>
      </div>
      <div v-if="!tailwindScreens.sm" class="ForumThreadPageAside__footer-helper"></div>
      <div v-if="chat.isMembersAbleThreadCreate" class="ForumThreadPageAside__footer">
        <router-link
          v-if="chat.isMembersAbleThreadCreate"
          class="ForumThreadPageAside__new-thread-button"
          :to="newThreadLink"
          tag="a"
        >
          <LLAddLabel red> {{ $t('forum.chat_page.threads.add_button') }} </LLAddLabel>
        </router-link>
        <ForumThreadPageAsideThemes v-if="false" class="ForumThreadPageAside__themes" />
      </div>
      <div class="ForumThreadPageAside__fixed-wrapper">
        <transition name="thread-aside-fixed-transition">
          <div v-if="showFixedBlock" class="ForumThreadPageAside__fixed-shadow"></div>
        </transition>
        <div class="ForumThreadPageAside__fixed-overflow">
          <transition name="thread-aside-fixed-transition">
            <ForumThreadPageAsideFixed
              v-if="showFixedBlock"
              class="ForumThreadPageAside__fixed"
              :chat="chat"
              :show-home-button="showHomeButtonInFixedBlock"
              :show-close-button="!tailwindScreens.sm"
              @close="$emit('close')"
            ></ForumThreadPageAsideFixed>
          </transition>
        </div>
      </div>
    </div>
    <LLButton secondary round class="ForumThreadPageAside__close-button" @click="$emit('close')">
      <template #icon-left> <ArrowIcon class="ForumThreadPageAside__close-button-icon" /> </template>
    </LLButton>
  </div>
</template>

<script>
import { ForumChatModel } from '@/models/forum/forumChat'
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatSmallDetails from '@/components/modules/forum-chat/ForumChatSmallDetails.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import ForumThreadPageAsideThreads from '@/components/pages/forum/thread/aside/ForumThreadPageAsideThreads.vue'
import LLAddLabel from '@/components/atoms/LLAddLabel.vue'
import ForumThreadPageAsideThemes from '@/components/pages/forum/thread/aside/ForumThreadPageAsideThemes.vue'
import ForumThreadPageAsideFixed from '@/components/pages/forum/thread/aside/ForumThreadPageAsideFixed.vue'
import HomeIcon from '@/assets/icons/HomeIcon.vue'
import ArrowIcon from '@/assets/icons/ArrowIcon.vue'
import { screenCatcher } from '@/mixins/screenCatcher'

export default {
  name: 'ForumThreadPageAside',
  components: {
    ArrowIcon,
    HomeIcon,
    ForumThreadPageAsideFixed,
    ForumThreadPageAsideThemes,
    LLAddLabel,
    ForumThreadPageAsideThreads,
    LLButton,
    ForumChatSmallDetails
  },
  mixins: [screenCatcher],
  props: {
    chat: { type: ForumChatModel, required: true },
    thread: { type: ForumThreadModel, default: null }
  },
  data() {
    return {
      scrollY: 0
    }
  },
  computed: {
    showFixedBlock() {
      return this.scrollY > 50 || !this.tailwindScreens.sm
    },
    showHomeButtonInFixedBlock() {
      return this.scrollY > 145 || !this.tailwindScreens.sm
    },
    newThreadLink() {
      return `/chats/${this.chat.id}/threads/create`
    }
  },
  methods: {
    onScroll() {
      this.scrollY = this.$refs.scrollable.scrollTop
    }
  }
}
</script>

<style scoped lang="scss">
.ForumThreadPageAside {
  @apply shadow-dropdown border-neutral-01-50 border flex flex-col bg-white;
  &__content {
    @apply w-full h-full overflow-y-auto  flex flex-col min-h-0;
  }
  &__return-button-wrapper {
    @apply py-1 border-neutral-01-50 border-b mt-4 flex-shrink-0;
  }
  &__chat-details {
    @apply flex-shrink-0;
  }
  &__threads {
    @apply mt-4 flex-1 min-h-0;
    &:first-child {
      @apply mt-0;
    }
  }
  &__scrollable {
    @apply overflow-y-auto min-h-0 p-4 flex-1 border-b border-neutral-01-50;
    min-height: 350px;
    @screen sm {
      @apply p-6;
    }
  }
  &__footer-helper {
    height: 56px;
    display: none;
  }
  &__footer {
    @apply py-4 px-4 bg-white bottom-0 w-full flex-shrink-0;
    @screen sm {
      @apply px-6 relative;
    }
    z-index: 41;
  }
  &__footer-delimeter {
    @apply w-full h-px bg-neutral-01-50 my-4;
  }
  &__new-thread-button {
    @apply mt-4 flex-shrink-0;
  }
  &__themes {
    @apply flex-shrink-0;
  }
  &__fixed {
    @apply w-full;
  }

  .thread-aside-fixed-transition-enter-active {
    transition-property: opacity, transform;
    transition-duration: 0.1s;
  }
  .thread-aside-fixed-transition-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.1s;
  }
  .thread-aside-fixed-transition-enter,
  .thread-aside-fixed-transition-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }
  &__close-button {
    @apply right-0 bottom-0 absolute invisible;
    z-index: 42;
    margin-right: -22px;
    margin-bottom: 22px;
    @screen sm {
      @apply visible;
    }
  }
  &__close-button-icon {
    transform: rotate(-90deg);
  }
  &__mobile-close-button {
    @apply fixed  bottom-0 left-0 w-full flex-shrink-0;
    @screen sm {
      @apply hidden;
    }
  }
  /*&__mobile-close-button-helper {
    height: 44px;
    @screen sm {
      @apply hidden;
    }
  }*/
  &__fixed-wrapper {
    @apply absolute top-0 left-0 w-full;
    z-index: 41;
  }
  &__fixed-panel-helper {
    height: 32px;
  }
  &__fixed-overflow {
    @apply overflow-hidden relative;
  }
  &__fixed-shadow {
    @apply shadow-dropdown absolute w-full h-full;
  }
}
</style>
