<template>
  <div
    class="add-section w-full"
    :class="{
      'is-expanded': isVisible,
      'header-not-separated': headerNotSeparated,
      'disable-body-padding': disableBodyPadding,
      'is-white': white,
      'arrow-on-top': arrowOnTop,
      'is-disabled': disabled,
      'is-light': light,
      'is-clean': clean
    }"
  >
    <div class="add-section__header" @click.prevent="toggleVisibility">
      <div v-if="$slots['beforeNumber']" class="overflow-ellipsis ml-4 text-primary-01 py-4">
        <slot name="beforeNumber" />
      </div>

      <div
        v-if="$slots['number']"
        class="add-section__add-icon w-8 h-8 rounded-full bg-neutral-01-25 flex justify-center items-center"
      >
        <slot name="number" />
      </div>

      <div
        v-if="$slots['title']"
        class="add-section__title ml-2 overflow-ellipsis overflow-hidden whitespace-nowrap"
        data-e2e="title"
      >
        <slot name="title" />
      </div>

      <div v-if="$slots['wrap-title']" class="add-section__title ml-4">
        <slot name="wrap-title" />
      </div>

      <div v-if="$slots['actions']" class="add-section__actions" @click="onActionsClick">
        <slot name="actions" />
      </div>

      <div v-if="$slots['status']" class="add-section__actions">
        <slot name="status" />
      </div>

      <div class="add-section__toggle-icon text-primary-01-400">
        <ArrowDown />
      </div>
    </div>

    <div v-if="isVisible" class="add-section__body p-6 mob:p-4 border border-neutral-01-50 bg-neutral-01-15">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLSpoiler',
  props: {
    expanded: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showAddIcon: { type: Boolean, default: true },
    light: { type: Boolean, default: false },
    clean: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    headerNotSeparated: { type: Boolean, default: false },
    arrowOnTop: { type: Boolean, default: false },
    disableBodyPadding: { type: Boolean, default: false }
  },
  data: () => ({
    isVisible: false
  }),
  watch: {
    disabled(value) {
      if (value) this.isVisible = false
    },
    isVisible(value) {
      this.$emit('change', value)
    },
    expanded() {
      this.isVisible = this.expanded && !this.disabled
    }
  },
  created() {
    this.isVisible = this.expanded && !this.disabled
  },
  methods: {
    toggleVisibility() {
      if (this.disabled) return
      this.isVisible = !this.isVisible
    },
    onActionsClick(event) {
      event.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
.add-section {
  $root: &;

  &__header {
    @apply flex flex-wrap items-center border border-neutral-01-50 rounded cursor-pointer;
  }

  &__add-icon {
    @apply ml-4 mr-2;
  }

  &__title {
    @apply flex-1 text-primary-01 py-4 pr-2;
  }

  &__actions {
    @apply p-4;
    @screen md {
      @apply order-none w-auto py-5 mr-4 border-none;
    }
  }

  &__toggle-icon {
    @apply mr-4;
    @screen md {
      @apply mr-6;
    }
  }

  &.arrow-on-top {
    #{$root}__toggle-icon {
      @apply self-start mt-4;
    }
  }

  &.is-expanded {
    &.header-not-separated {
      #{$root}__header {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      #{$root}__body {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &.is-expanded {
    #{$root}__header {
      // @apply border-primary-01;
      // box-shadow: inset 0px 1px 0px 0px var(--color-primary-01);
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    #{$root}__toggle-icon {
      transform: rotate(180deg);
    }

    #{$root}__body {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.disable-body-padding {
    #{$root}__body {
      padding: 0;
    }
  }

  &:not(.is-expanded):not(.is-disabled) {
    #{$root}__header {
      &:hover {
        @apply bg-neutral-01-15 border-neutral-01-400;
      }
    }
  }

  &.is-disabled {
    #{$root}__header {
      @apply bg-neutral-01-25 border-neutral-01;
    }
  }

  &.is-light {
    #{$root}__header {
      @apply bg-transparent border-none shadow-none;

      .add-section__add-icon {
        @apply hidden;
      }
    }

    #{$root}__body {
      @apply border-none p-0 pb-6;
    }
  }

  &.is-clean {
    #{$root}__header {
      border-left-color: var(--color-neutral-01-300);
      border-left-width: 1px;

      .add-section__add-icon {
        @apply hidden;
      }
    }

    #{$root}__body {
      border-left-color: var(--color-neutral-01-300);
      border-left-width: 1px;
    }
  }

  &.is-white {
    #{$root}__body {
      @apply bg-white;
    }
  }
}
</style>
