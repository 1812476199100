<template>
  <svg
    class="fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8992 11.95C19.8992 11.55 20.1992 11.25 20.5992 11.25H22.6992C22.2992 5.95 17.9992 1.55 12.5992 1.25V3.35C12.5992 3.75 12.2992 4.05 11.8992 4.05C11.4992 4.05 11.1992 3.75 11.1992 3.35V1.25C5.89922 1.55 1.49922 5.95 1.19922 11.35H3.29922C3.69922 11.35 3.89922 11.65 3.89922 12.05C3.89922 12.45 3.59922 12.65 3.29922 12.65H1.19922C1.49922 18.05 5.89922 22.35 11.2992 22.75V20.65C11.2992 20.25 11.5992 19.95 11.9992 19.95C12.3992 19.95 12.6992 20.25 12.6992 20.65V22.75C18.0992 22.45 22.3992 18.05 22.7992 12.65H20.6992C20.0992 12.65 19.8992 12.35 19.8992 11.95ZM18.0992 8.45L12.3992 14.25C12.1992 14.45 11.7992 14.55 11.4992 14.25L7.59922 11.05C7.29922 10.85 7.19922 10.45 7.49922 10.15C7.69922 9.85 8.09922 9.85 8.39922 10.05L11.8992 12.85L17.1992 7.55C17.4992 7.25 17.8992 7.25 18.0992 7.55C18.3992 7.85 18.3992 8.25 18.0992 8.45Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockFilledIcon'
}
</script>

<style lang="scss" scoped>
.ClockFilledIcon {
}
</style>
