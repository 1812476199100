<template>
  <div ref="response" class="ForumResponseScrollToHelper"></div>
</template>

<script>
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'
import { ForumResponseModel } from '@/models/forum/forumResponse'

export default {
  name: 'ForumResponseScrollToHelper',
  props: {
    response: { type: ForumResponseModel, default: null },
    parentResponseId: { type: String, default: null }
  },
  mounted() {
    this.fireScrollToHelper()
    scrollToForumReplyHelper.addListener({ callback: this.fireScrollToHelper })
  },
  beforeDestroy() {
    scrollToForumReplyHelper.removeListener({ callback: this.fireScrollToHelper })
  },
  methods: {
    fireScrollToAction() {
      const element = this.$refs.response
      const boundRect = element.getBoundingClientRect()
      const absoluteElementTop = boundRect.top + window.pageYOffset
      const middle = absoluteElementTop - window.innerHeight / 2
      window.scrollTo(0, middle)
    },
    fireScrollToHelper() {
      const isWatchingResponse = scrollToForumReplyHelper.isWatchingResponse({
        responseId: this.response?.id
      })
      const isWatchingNewReply = this.parentResponseId
        ? scrollToForumReplyHelper.isWatchingNewReply({
            newReplyId: this.parentResponseId
          })
        : false
      if (isWatchingResponse) {
        const hasChild = scrollToForumReplyHelper.hasChild
        if (!hasChild) {
          this.fireScrollToAction()
          this.$emit('fireBackgroundAnimation')
          scrollToForumReplyHelper.removeResponse({ responseId: this.response?.id })
        }
      } else if (!this.response?.id && isWatchingNewReply) {
        this.$emit('focus')
        this.$emit('addMention', {
          user: scrollToForumReplyHelper.newReplyUser(),
          prospect: scrollToForumReplyHelper.newReplyProspect()
        })
        scrollToForumReplyHelper.removeAll()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumResponseScrollToHelper {
}
</style>
