<template>
  <div class="PollMatrixResultsBar">
    <PollResultsChartBar
      :chart-bar-data="chartData"
      :data-label-callback="dataLabel"
      :tooltip-label-callback="tooltipLabel"
      stacked
      :max="max"
      data-labels-anchor="center"
      show-legend
      data-labels-align="center"
      hide-x
    />
  </div>
</template>

<script>
import PollResultsChartBar from '@/components/modules/forum-chat/poll/results/PollResultsChartBar.vue'
import { PollModel } from '@/models/poll'
import { getBgColor } from '@/utils/getRandomBackground'

export default {
  name: 'PollMatrixResultsBar',
  components: { PollResultsChartBar },
  props: {
    data: { type: Array, required: true },
    poll: { type: PollModel, required: true },
    totalCount: { type: Number, required: true }
  },
  computed: {
    sortedColumns() {
      return [...this.poll?.columns].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    max() {
      return Math.max(...this.data.map((row) => row.columns.reduce((acc, column) => acc + column.count, 0)))
    },
    chartData() {
      const backgrounds = getBgColor(this.poll.columns.length)
      return {
        labels: [...this.data.map((item) => item.text)],
        datasets: [
          ...this.sortedColumns.map((column, index) => ({
            label: column.text,
            barThickness: 18,
            backgroundColor: backgrounds[index],
            data: this.data.map((row) => row.columns.find((col) => col.id === column.id).count)
          }))
        ]
      }
    }
  },
  methods: {
    tooltipLabel(ctx) {
      return `${ctx.dataset.label}: ${ctx.parsed.x}`
    },
    dataLabel(value) {
      return `${value}`
    }
  }
}
</script>

<style scoped lang="scss">
.PollMatrixResultsBar {
}
</style>
