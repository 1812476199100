export const StudyStates = {
  computed: {
    studyState() {
      return {
        isLive: this.study.participantApprovalState === 20 && this.study.state === 20,
        isPendingApproval:
          this.study.participantApprovalState === 10 && this.study.participantInvitationState === 40,
        //this.study.participantApprovalState === 10 && this.study.state === 20,
        isWaiting: this.study.participantApprovalState === 20 && this.study.state === 10,
        isInvited:
          this.study.participantApprovalState === 10 &&
          (this.study.participantInvitationState === 10 ||
            this.study.participantInvitationState === 20 ||
            this.study.participantInvitationState === 30 ||
            this.study.participantInvitationState === 60)
      }
    }
  }
}
