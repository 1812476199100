<template>
  <div class="LLTags">
    <div ref="tags-list" class="LLTags__list">
      <div
        v-for="value in visibleTags"
        :key="valueKey(value)"
        ref="tag"
        class="LLTags__tag-wrapper"
        :class="{ 'LLTags__tag-wrapper--with-input': withInput && haveCustomInputTemplate }"
      >
        <div class="LLTags__tag">
          <LLTag
            v-if="!haveCustomTagTemplate"
            :with-remove="withRemove"
            :pre-remove="tagIsPreRemove(value)"
            @remove="remove(value)"
          >
            <slot name="tag-value" :value="value">{{ value }}</slot>
          </LLTag>
          <slot v-else name="tag" :value="value"></slot>
        </div>
      </div>
      <div
        v-if="overflowHidden && visibleNumber !== values.length"
        ref="tag-show-hidden"
        class="LLTags__tag-wrapper"
        :class="{ 'LLTags__tag-wrapper--with-input': withInput && haveCustomInputTemplate }"
      >
        <div class="LLTags__tag">
          <LLPopper :offset="[0, 0]" placement="bottom-start">
            <template #trigger="{ opened }">
              <LLTag
                v-if="!haveCustomTagTemplate"
                class="LLTags__hidden-trigger"
                :active="opened"
                :with-remove="false"
              >
                +{{ hiddenTagsNumber }}
              </LLTag>
              <slot v-else name="tag-additional"></slot>
            </template>
            <template #tooltip>
              <div class="tooltip tooltip_white tooltip_without-arrow">
                <div class="tooltip-inner">
                  <div class="LLTags__hidden-list">
                    <div class="LLTags__hidden-list-inner">
                      <div
                        v-for="value in hiddenTags"
                        :key="valueKey(value)"
                        class="LLTags__tag-wrapper"
                        :class="{ 'LLTags__tag-wrapper--with-input': withInput && haveCustomInputTemplate }"
                      >
                        <div class="LLTags__tag">
                          <LLTag
                            v-if="!haveCustomTagTemplate"
                            :with-remove="withRemove"
                            :pre-remove="tagIsPreRemove(value)"
                            @remove="remove(value)"
                          >
                            <slot name="tag-value" :value="value">{{ value }}</slot>
                          </LLTag>
                          <slot v-else name="tag" :value="value"></slot>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </LLPopper>
        </div>
      </div>
      <div v-if="withInput && !haveCustomInputTemplate" ref="input-wrapper" class="LLTags__input">
        <LLTagsInput
          ref="input"
          v-model="currentInputValue"
          :max-length="maxLength"
          :valid-fn="validFn"
          :suggestions="suggestions"
          :placeholder="inputPlaceholder"
          @add="inputAdd"
          @removeLastKeyDown="removeLastKeyDown"
          @removeLastKeyUp="removeLastKeyUp"
          @selectSuggestion="selectSuggestion"
          @focus="onInputFocus"
          @blur="onInputBlur"
        >
          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </LLTagsInput>
      </div>
      <div
        v-if="withInput && haveCustomInputTemplate"
        :class="{ 'LLTags__input--with-input': withInput && haveCustomInputTemplate }"
        class="LLTags__input"
      >
        <slot name="new-tag-input"></slot>
      </div>
    </div>
  </div>
</template>

<script>
// TODO fix height !withInput
import LLTag from '@/components/common/ui-components/LLTag/LLTag'
import LLTagsInput from '@/components/common/ui-components/LLTag/LLTagsInput'
import LLPopper from '@/components/utils/LLPopper'
import _ from 'lodash'
export default {
  name: 'LLTags',
  components: { LLPopper, LLTagsInput, LLTag },
  props: {
    /* Array of string */
    values: { type: Array, default: () => [] },
    suggestions: { type: [Array, null], default: null },
    validFn: { type: [Function, null], default: null },
    inputPlaceholder: { type: String, default: null },
    keyFn: { type: [Function, null], default: null },
    withInput: { type: Boolean, default: true },
    maxLength: { type: Number, default: null },
    withRemove: { type: Boolean, default: true },
    customInputTemplate: { type: Boolean, default: false },
    overflowHidden: { type: Boolean, default: false },
    overflowLines: { type: Number, default: 1 }
  },
  data() {
    return {
      currentInputValue: '',
      preRemove: false,
      preRemoveKeyUp: true,
      visibleNumber: null,
      lineCounting: false,
      resizeObserver: null
    }
  },
  computed: {
    hiddenTagsNumber() {
      return this.hiddenTags?.length
    },
    visibleTags() {
      if (this.overflowHidden && this.visibleNumber !== null) {
        const newArray = [...this.values]
        newArray.splice(this.visibleNumber)
        return newArray
      } else {
        return this.values
      }
    },
    hiddenTags() {
      if (this.overflowHidden && this.visibleNumber !== null) {
        return [...this.values].splice(this.visibleNumber)
      } else {
        return []
      }
    },
    haveCustomTagTemplate() {
      return this.$slots?.tag
    },
    haveCustomInputTemplate() {
      return this.$slots['new-tag-input'] || this.customInputTemplate
    }
  },
  watch: {
    currentInputValue: {
      handler() {
        this.preRemove = false
        this.$emit('updateInput', this.currentInputValue)
      }
    },
    values: {
      handler() {
        if (this.overflowHidden) {
          this.recountVisibleTags()
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.overflowHidden) {
      this.$nextTick(() => {
        this.initResizeObserver()
        this.recountVisibleTags()
      })
    }
  },
  created() {
    this.debouncedRecountVisibleTags = _.debounce(
      () => {
        this.recountVisibleTags()
      },
      100,
      {
        trailing: false,
        maxWait: 300,
        leading: true
      }
    )
  },
  beforeDestroy() {
    this.destroyResizeObserver()
  },
  methods: {
    destroyResizeObserver() {
      window.removeEventListener('resize', this.debouncedRecountVisibleTags)
    },
    initResizeObserver() {
      window.addEventListener('resize', this.debouncedRecountVisibleTags)
    },
    recountVisibleTags() {
      if (this.lineCounting || !this.overflowHidden) {
        return
      }
      this.lineCounting = true
      this.visibleNumber = 0
      this.$nextTick(() => {
        let lastAdditionalTagY = this.$refs['tags-list']?.getBoundingClientRect()?.height ?? null
        let numberOfLines = 1

        const checkCurrent = () => {
          this.$nextTick(() => {
            const additionalTagY = this.$refs['tags-list']?.getBoundingClientRect()?.height
            if (additionalTagY > lastAdditionalTagY && lastAdditionalTagY !== null) {
              numberOfLines++
            }
            lastAdditionalTagY = additionalTagY

            if (numberOfLines > this.overflowLines) {
              this.visibleNumber--
              this.$nextTick(() => {
                this.lineCounting = false
              })
              return
            }

            if (this.visibleNumber < this.values.length) {
              this.visibleNumber++
              this.$nextTick(checkCurrent)
            } else {
              this.$nextTick(() => {
                this.lineCounting = false
              })
            }
          })
        }
        this.$nextTick(checkCurrent)
      })
    },
    valueKey(value) {
      if (this.keyFn) {
        return this.keyFn(value)
      } else {
        return value
      }
    },
    tagIsPreRemove(value) {
      return this.preRemove && this.values.indexOf(value) === this.values.length - 1
    },
    remove(value) {
      this.$emit('remove', value)
    },
    selectSuggestion(suggestion) {
      this.$emit('selectSuggestion', suggestion)
    },
    inputAdd(value) {
      this.$emit('add', value)
    },
    removeLast() {
      if (this.values.length) {
        if (!this.preRemove) {
          this.preRemove = true
          return
        }
        this.remove(this.values[this.values.length - 1])
        this.preRemove = false
      }
    },
    removeLastKeyDown() {
      if (this.preRemoveKeyUp) {
        this.removeLast()
      }
      this.preRemoveKeyUp = false
    },
    removeLastKeyUp() {
      this.preRemoveKeyUp = true
    },
    onInputFocus() {
      this.$emit('inputFocus')
    },
    onInputBlur() {
      this.$emit('inputBlur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTags {
  &__list {
    @apply flex flex-wrap min-w-0 -mt-2;
    max-width: 100%;
  }
  &__hidden-trigger {
    @apply select-none cursor-pointer;
  }
  &__hidden-list {
    @apply p-4;
    max-width: 450px;
    max-height: 200px;
    overflow-y: auto;
    &-inner {
      @apply -mt-2 flex flex-wrap min-w-0;
    }
  }
  &__tag-wrapper {
    @apply flex-shrink-0 min-w-0 mr-2 mt-2;
    max-width: 100%;
    &--with-input {
      @apply mt-5;
    }
  }
  &__input-popover {
    @apply flex-grow;
    max-width: 100%;
  }
  &__input {
    @apply flex-grow mt-2;
    max-width: 100%;
    &--with-input {
      display: flex;
      @apply mt-5;
    }
  }
  ::v-deep .trigger {
    display: block !important;
  }
}
</style>
