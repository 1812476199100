import { render, staticRenderFns } from "./StudyConversationMessageAvatar.vue?vue&type=template&id=7a27dce8&scoped=true"
import script from "./StudyConversationMessageAvatar.vue?vue&type=script&lang=js"
export * from "./StudyConversationMessageAvatar.vue?vue&type=script&lang=js"
import style0 from "./StudyConversationMessageAvatar.vue?vue&type=style&index=0&id=7a27dce8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a27dce8",
  null
  
)

export default component.exports