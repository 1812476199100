<template>
  <div class="LLFileUploader">
    <div class="LLFileUploader__label">
      {{ $t('file_uploader_upload_media') }}
    </div>
    <div class="LLFileUploader__file-types" data-e2e="filesUploader">
      <LLFileUploaderWrapper
        :preparable="preparable"
        class="LLFileUploader__file-type-wrapper"
        file-mime-type="image/*,image/heic"
        data-e2e="imageUpload"
        :study-id="studyId"
        @upload="onUpload"
        @startUpload="onStartUpload"
        @prepare="onPrepare"
      >
        <LLButton tertiary small class="LLFileUploader__file-type">
          <template #icon-left>
            <PhotoIcon class="LLFileUploader__file-type-icon"></PhotoIcon>
          </template>
          <div class="LLFileUploader__file-type-name">{{ $t('file_uploader_photo') }}</div>
        </LLButton>
      </LLFileUploaderWrapper>
      <LLFileUploaderWrapper
        :preparable="preparable"
        class="LLFileUploader__file-type-wrapper"
        file-mime-type="video/*"
        :study-id="studyId"
        @upload="onUpload"
        @startUpload="onStartUpload"
        @prepare="onPrepare"
      >
        <LLButton tertiary small class="LLFileUploader__file-type">
          <template #icon-left>
            <VideoIcon class="LLFileUploader__file-type-icon"></VideoIcon>
          </template>
          <div class="LLFileUploader__file-type-name">{{ $t('file_uploader_video') }}</div>
        </LLButton>
      </LLFileUploaderWrapper>
    </div>
  </div>
</template>

<script>
import PhotoIcon from '@/assets/icons/PhotoIcon'
import VideoIcon from '@/assets/icons/VideoIcon'
import LLFileUploaderWrapper from '@/components/common/LLFileUploader/LLFileUploaderWrapper'
import LLButton from '@/components/common/ui-components/LLButton.vue'
export default {
  name: 'LLFileUploader',
  components: { LLButton, LLFileUploaderWrapper, VideoIcon, PhotoIcon },
  props: {
    preparable: { type: Boolean, default: false },
    studyId: { type: Number, default: null }
  },
  methods: {
    onStartUpload(e) {
      this.$emit('startUpload', e)
    },
    onUpload(e) {
      this.$emit('upload', e)
    },
    onPrepare(file) {
      this.$emit('prepare', file)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLFileUploader {
  $root: &;
  @apply flex items-center;
  #{$root}__label {
    @apply hidden;
    @screen sm {
      @apply flex;
    }
    @apply text-neutral-01 text-sm mr-4;
  }
  #{$root}__file-types {
    @apply flex;
    #{$root}__file-type-wrapper {
      @apply mr-2 cursor-pointer;
      &:first-child {
        @apply -ml-4;
        @screen md {
          @apply ml-0;
        }
      }
      &:last-child {
        @apply mr-0;
      }
      #{$root}__file-type {
        @apply flex items-center;
        #{$root}__file-type-icon {
          @apply text-accent-01 h-5 w-5;
        }
        #{$root}__file-type-name {
          @apply font-semibold text-primary-01-400 text-sm;
        }
      }
    }
  }
}
</style>
