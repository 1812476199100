<template>
  <svg width="6" height="10" viewBox="0 0 6 10" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.3942 5.42807L1.97095 8.85132C1.73434 9.08792 1.35174 9.08792 1.11765 8.85132L0.548792 8.28245C0.312185 8.04585 0.312185 7.66325 0.548792 7.42916L2.97527 5.00268L0.548793 2.5762C0.312186 2.3396 0.312186 1.957 0.548793 1.72291L1.11514 1.14901C1.35175 0.912406 1.73434 0.912406 1.96843 1.14901L5.39168 4.57226C5.6308 4.80887 5.6308 5.19146 5.3942 5.42807Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowMiniIcon'
}
</script>
