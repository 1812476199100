<template>
  <transition name="full-text" appear>
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'LLConfirmModalTransition'
}
</script>

<style lang="scss">
.full-text-enter-active,
.full-text-leave-active {
  transition-duration: 300ms;
  .LLConfirmModal__background {
    transition-duration: 300ms;
    transition-property: opacity;
  }
  .LLConfirmModal__block {
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.98, 0.18, 0, 1);
  }
}
.full-text-enter, .full-text-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  .LLConfirmModal__background {
    opacity: 0;
  }
  .LLConfirmModal__block {
    opacity: 0;
    transform: scale(0.95);
  }
}
</style>
