<template>
  <LLWordTextContainer class="PrivacyPolicyKO">
    <p class="c8 c18"><span class="c5 c1"></span></p>
    <p class="c8">
      <span class="c0"
        >&#45817;&#49324;&#50640; &#51080;&#50612; &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;
        &#48372;&#54840;&#45716; &#50500;&#51452; &#51473;&#50836;&#54620;
        &#49324;&#54637;&#51077;&#45768;&#45796;. &#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840;
        &#51221;&#52293;&#51008; &#50937;&#49324;&#51060;&#53944;, &#50545;, &#44536;&#47532;&#44256;</span
      ><span class="c19"
        ><a
          class="c37"
          href="https://www.google.com/url?q=http://spark-nyc.com&amp;sa=D&amp;source=editors&amp;ust=1666016699179178&amp;usg=AOvVaw3UNlXU-kXciDNBii754Y9W"
          >spark-nyc.com</a
        ></span
      ><span class="c1">&nbsp;</span><span class="c0">&#48143;looklook.app </span><span class="c1">(</span
      ><span class="c0">&#52509;&#44292;&#54616;&#50668;</span><span class="c1">, &ldquo;</span
      ><span class="c0">&#49324;&#51060;&#53944;</span><span class="c1">&rdquo;) </span
      ><span class="c0">&#48143;</span><span class="c1">&nbsp;</span><span class="c0">&#47337;&#47337;(</span
      ><span class="c1">LookLook) </span><span class="c0">&#47784;&#48148;&#51068; &#50545;</span
      ><span class="c1">(&ldquo;</span><span class="c0">&#47784;&#48148;&#51068; &#50545;</span
      ><span class="c1">&rdquo;)</span><span class="c0">&#51012; &#54252;&#54632;&#54620; </span
      ><span class="c1">Spark Ideas, LLC(&ldquo;</span><span class="c0">&#49828;&#54028;&#53356;</span
      ><span class="c1">,&rdquo; &ldquo;</span><span class="c0">&#50864;&#47532;</span
      ><span class="c1">,&rdquo;</span><span class="c0">&#45817;&#49324;</span><span class="c1">&rdquo; </span
      ><span class="c0">&#46608;&#45716;</span><span class="c1">&nbsp;&ldquo;</span
      ><span class="c0">&#45817;&#49324;&#51032;</span><span class="c1">&rdquo;)</span
      ><span class="c0"
        >&#50640;&#49436; &#44288;&#47532;&#54616;&#45716; &#49436;&#48708;&#49828;&#44032;
        &#54644;&#45817;&#54633;&#45768;&#45796;</span
      ><span class="c1">. </span
      ><span class="c6 c0"
        >&#44480;&#54616;&#51032; &#49324;&#51060;&#53944; &#46608;&#45716; &#47784;&#48148;&#51068; &#50545;
        &#49324;&#50857;&#51008; &#44480;&#54616;&#44032; &#51060; &#47928;&#49436;&#50640;
        &#49444;&#47749;&#46108; &#45936;&#51060;&#53552; &#52376;&#47532; &#44288;&#47168;&#50640;
        &#46041;&#51032;&#54616;&#45716; &#44163;&#51012; &#51032;&#48120;&#54632;&#51004;&#47196; &#51060;
        &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51012; &#51452;&#51032;
        &#44618;&#44172; &#44160;&#53664;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c8 c18"><span class="c5 c1"></span></p>
    <p class="c8">
      <span class="c0"
        >&#44480;&#54616;&#51032; &#49324;&#51060;&#53944; &#46608;&#45716; &#47784;&#48148;&#51068; &#50545;
        &#49324;&#50857;&#51008; &#49324;&#50857; &#50557;&#44288;&#50640;&#46020; &#51201;&#50857;&#51012;
        &#48155;&#51004;&#47728; </span
      ><span class="c0 c50">&#50668;&#44592;&#49436;</span
      ><span class="c0">&nbsp;&#52280;&#51312;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.</span>
    </p>
    <p class="c20 c18"><span class="c33 c13 c1"></span></p>
    <p class="c20">
      <span class="c2 c1 c51">&#51473;&#50836; &#44256;&#51648; &#49324;&#54637;</span
      ><span class="c0">: </span
      ><span class="c3"
        >&#50500;&#47000; &#51221;&#54644;&#51652; &#51473;&#51116; &#51312;&#54637;&#51008;, &#48277;&#50640;
        &#46384;&#46972; &#44552;&#51648;&#46104;&#45716; &#51109;&#49548;&#50752; &#48276;&#50948;&#47484;
        &#51228;&#50808;&#54616;&#44256;&#45716;, &#44480;&#54616;&#44032; &#49828;&#54028;&#53356;&#50640;
        &#51228;&#44592;&#54624; &#49688; &#51080;&#45716; &#47784;&#46304; &#53364;&#47112;&#51076;&#51012;
        &#44060;&#51064; &#51088;&#44201;&#51004;&#47196; &#51473;&#51116;&#54624; &#44163;&#51012;
        &#50836;&#44396;&#54633;&#45768;&#45796;. &#44060;&#51064; &#51088;&#44201;&#51032;
        &#51473;&#51116;&#46976; &#54032;&#49324; &#46608;&#45716; &#48176;&#49900;&#50896;&#51060;
        &#44480;&#54616;&#51032; &#53364;&#47112;&#51076;&#50640; &#45824;&#54644;
        &#54032;&#44208;&#54616;&#51648; &#50506;&#51004;&#47728;, &#44480;&#54616;&#44032; &#44536;
        &#44428;&#47532;&#47484; &#54252;&#44592;&#54616;&#47728;, &#44480;&#54616;&#45716; &#51665;&#45800;,
        &#45800;&#52404; &#46608;&#45716; &#45824;&#54364;&#51088; &#51088;&#44201;&#51004;&#47196;
        &#49548;&#49569;&#51012; &#51652;&#54665;&#54624; &#49688; &#50630;&#51020;&#51012;
        &#51032;&#48120;&#54633;&#45768;&#45796;</span
      ><span class="c0">.</span>
    </p>
    <p class="c11">
      <span class="c2"
        >&#44060;&#51064; &#51221;&#48372;&#51032; &#49688;&#51665; &#48143; &#49324;&#50857;</span
      ><span class="c6 c1 c13"><br /></span>
    </p>
    <p class="c49">
      <span>&ldquo;</span
      ><span class="c6 c0"
        >&#44060;&#51064; &#51221;&#48372;&rdquo;&#46976; &#50612;&#46500; &#49324;&#46988;&#51060;
        &#49885;&#48324;&#46104;&#44144;&#45208; &#50612;&#46500; &#49324;&#46988;&#51012;
        &#49885;&#48324;&#54624; &#49688; &#51080;&#45716; &#47784;&#46304;
        &#51221;&#48372;&#51077;&#45768;&#45796;. &#45817;&#49324;&#45716; &#44480;&#54616;&#50640;
        &#44288;&#54620; &#45796;&#51020; &#51221;&#48372;&#47484; &#44480;&#54616;&#47196;&#48512;&#53552;
        &#49688;&#51665;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c18 c49"><span class="c5 c48"></span></p>
    <a id="t.d4c474b50e40c25350cc61fc733296f024021817"></a><a id="t.0"></a>
    <table class="c27">
      <tr class="c15">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c2">&#51064;&#44396;&#53685;&#44228;&#54617;&#51201;</span
            ><span class="c41">&nbsp;</span><span class="c2">&#45936;&#51060;&#53552;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c2">&#50864;&#47532;&#45716;</span><span class="c41">&nbsp;</span
            ><span class="c2">&#51060;</span><span class="c41">&nbsp;</span
            ><span class="c2">&#45936;&#51060;&#53552;&#47484;</span><span class="c41">&nbsp;</span
            ><span class="c2">&#49324;&#50857;&#54616;&#50668;</span>
          </p>
        </td>
      </tr>
      <tr class="c53">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#51060;&#47492;</span><span><br /></span><span class="c3">&#51204;&#54868;</span
            ><span>&nbsp;</span><span class="c3">&#48264;&#54840;</span><span><br /></span
            ><span class="c3">&#49884;</span><span>, </span><span class="c3">&#51452;</span><span>&nbsp;</span
            ><span class="c3">&#48143;</span><span>&nbsp;</span><span class="c3">&#44397;&#44032;</span
            ><span><br /></span><span class="c3">&#51060;&#47700;&#51068;</span><span>&nbsp;</span
            ><span class="c3">&#51452;&#49548;</span><span><br /></span
            ><span class="c3">&#51649;&#50948;</span><span><br /></span
            ><span class="c3">&#54924;&#49324;</span><span><br /></span
            ><span class="c3">&#44368;&#50977;</span><span><br /></span
            ><span class="c3">&#49548;&#46301;&#44396;&#44036;</span><span><br /></span
            ><span class="c3">&#44208;&#54844;</span><span>&nbsp;</span
            ><span class="c3">&#50668;&#48512;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#50672;&#44396;</span><span>&nbsp;</span><span class="c3">&#49688;&#54665;</span
            ><span><br /></span><span class="c3">&#44256;&#44061;</span><span>&nbsp;</span
            ><span class="c3">&#48143;</span><span>&nbsp;</span><span class="c3">&#51104;&#51116;</span
            ><span>&nbsp;</span><span class="c3">&#44256;&#44061;&#44284;&#51032;</span><span>&nbsp;</span
            ><span class="c3">&#52964;&#48036;&#45768;&#52992;&#51060;&#49496;</span><span>(</span
            ><span class="c3">&#51060;&#47700;&#51068;</span><span>, </span
            ><span class="c3">&#50672;&#46973;&#52376;</span><span>&nbsp;</span
            ><span class="c3">&#50577;&#49885;</span><span>, WeChat)<br /></span
            ><span class="c3">&#44256;&#44061;&#51060;</span><span>&nbsp;</span
            ><span class="c3">&#45817;&#49324;&#51032;</span><span>&nbsp;</span
            ><span class="c3">&#50672;&#44396;</span><span>&nbsp;</span
            ><span class="c3">&#54540;&#47019;&#54268;&#51012;</span><span>&nbsp;</span
            ><span class="c3">&#49324;&#50857;&#54624;</span><span>&nbsp;</span
            ><span class="c3">&#49688;</span><span>&nbsp;</span
            ><span class="c3">&#51080;&#46020;&#47197;</span><span>&nbsp;</span
            ><span class="c3">&#51648;&#50896;</span><span><br /></span
            ><span class="c3">&#47928;&#51228;</span><span>&nbsp;</span
            ><span class="c3">&#54644;&#44208;</span><span><br /></span
            ><span class="c3">&#44256;&#44061;</span><span>&nbsp;</span
            ><span class="c3">&#51648;&#50896;</span><span>&nbsp;</span
            ><span class="c3">&#51228;&#44277;</span
            ><span
              ><br />
              <br />
              <br />
              &nbsp;</span
            ><span class="c3">&#48277;&#51201;</span><span>&nbsp;</span
            ><span class="c3">&#44540;&#44144;</span><span>: </span><span class="c3">&#46041;&#51032;</span
            ><span>, </span><span class="c3">&#44228;&#50557;</span><span>, </span
            ><span class="c3">&#51221;&#45817;&#54620;</span><span>&nbsp;</span
            ><span class="c3">&#51060;&#51061;</span>
          </p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c2">&#44060;&#51064;</span><span class="c41">&nbsp;</span
            ><span class="c2">&#52712;&#54693;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10"><span class="c5 c1">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c26">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#52712;&#48120;</span><span><br /></span><span class="c3">&#51060;&#54644;</span
            ><span><br /></span><span class="c3">&#49660;&#54609;</span><span>&nbsp;</span
            ><span class="c3">&#49845;&#44288;</span><span><br /></span
            ><span class="c3">&#48652;&#47004;&#46300;</span><span>&nbsp;</span
            ><span class="c3">&#49440;&#54840;&#46020;</span><span><br /></span
            ><span class="c3">&#44396;&#47588;</span><span>&nbsp;</span
            ><span class="c3">&#45236;&#50669;</span><span><br /></span
            ><span class="c3">&#50672;&#44396;&#50752;</span><span>&nbsp;</span
            ><span class="c3">&#44288;&#47144;&#46108;</span><span>&nbsp;</span
            ><span class="c3">&#44592;&#53440;</span><span>&nbsp;</span
            ><span class="c3">&#51452;&#51228;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#50672;&#44396;</span><span>&nbsp;</span><span class="c3">&#49688;&#54665;</span
            ><span><br /><br /></span><span class="c3">&#48277;&#51201;</span><span>&nbsp;</span
            ><span class="c3">&#44540;&#44144;</span><span>: </span><span class="c3">&#46041;&#51032;</span>
          </p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c2">&#51204;&#51088;</span><span class="c41">&nbsp;</span
            ><span class="c2">&#54028;&#51068;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10"><span class="c5 c1">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c46">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#49324;&#51652;</span><span><br /></span
            ><span class="c3">&#48708;&#46356;&#50724;</span><span><br /></span
            ><span class="c3">&#47785;&#49548;&#47532;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#50672;&#44396;</span><span>&nbsp;</span><span class="c3">&#49688;&#54665;</span
            ><span><br /><br /></span><span class="c3">&#48277;&#51201;</span><span>&nbsp;</span
            ><span class="c3">&#44540;&#44144;</span><span>: </span><span class="c3">&#46041;&#51032;</span>
          </p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10"><span class="c2">&#51032;&#49324;&#49548;&#53685;</span></p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10"><span class="c5 c1">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c26">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#52280;&#44032;&#51088;</span><span>&nbsp;</span
            ><span class="c3">&#51064;&#53552;&#48624;</span><span><br /></span
            ><span class="c3">&#51068;&#48152;&#47928;&#51032;</span><span>(</span
            ><span class="c3">&#44256;&#44061;&#50836;&#52397;&#51008;</span><span>&nbsp;</span
            ><span class="c3">&#51060;&#47700;&#51068;</span><span>&nbsp;</span
            ><span class="c3">&#46608;&#45716;</span><span>&nbsp;</span
            ><span class="c3">&#50672;&#46973;&#52376;</span><span>)<br /></span
            ><span class="c3">&#44256;&#44061;</span><span>&nbsp;</span
            ><span class="c3">&#51648;&#50896;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#50672;&#44396;</span><span>&nbsp;</span><span class="c3">&#49688;&#54665;</span
            ><span><br /></span><span class="c3">&#44256;&#44061;</span><span>&nbsp;</span
            ><span class="c3">&#48143;</span><span>&nbsp;</span><span class="c3">&#51104;&#51116;</span
            ><span>&nbsp;</span><span class="c3">&#44256;&#44061;&#44284;&#51032;</span><span>&nbsp;</span
            ><span class="c3">&#52964;&#48036;&#45768;&#52992;&#51060;&#49496;</span><span>(</span
            ><span class="c3">&#51060;&#47700;&#51068;</span><span>, </span
            ><span class="c3">&#50672;&#46973;&#52376;</span><span>&nbsp;</span
            ><span class="c3">&#50577;&#49885;</span><span>, WeChat)<br /></span
            ><span class="c3">&#44256;&#44061;</span><span>&nbsp;</span
            ><span class="c3">&#51648;&#50896;</span><span>&nbsp;</span
            ><span class="c3">&#51228;&#44277;</span><span><br /></span
            ><span class="c3">&#48372;&#49345;</span><span>&nbsp;</span
            ><span class="c3">&#48516;&#48176;</span><span><br /><br /></span
            ><span class="c3">&#48277;&#51201;</span><span>&nbsp;</span
            ><span class="c3">&#44540;&#44144;</span><span>: </span><span class="c3">&#46041;&#51032;</span
            ><span>, </span><span class="c3">&#51221;&#45817;&#54620;</span><span>&nbsp;</span
            ><span class="c3">&#51060;&#51061;</span>
          </p>
        </td>
      </tr>
      <tr class="c15">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10"><span class="c2">&#44592;&#49696;</span></p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10"><span class="c5 c1">&nbsp;</span></p>
        </td>
      </tr>
      <tr class="c40">
        <td class="c7" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#46020;&#49884;</span><span>&nbsp;</span><span class="c3">&#50948;&#52824;</span
            ><span>&nbsp;</span><span class="c3">&#51221;&#48372;</span><span><br />IP </span
            ><span class="c3">&#51452;&#49548;</span><span><br /></span
            ><span class="c3">&#44592;&#44592;</span><span>&nbsp;</span><span class="c3">&#48143;</span
            ><span>&nbsp;</span><span class="c3">&#48652;&#46972;&#50864;&#51200;</span><span>&nbsp;</span
            ><span class="c3">&#45936;&#51060;&#53552;</span>
          </p>
        </td>
        <td class="c24" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c3">&#50672;&#44396;</span><span>&nbsp;</span><span class="c3">&#49688;&#54665;</span
            ><span><br /></span><span class="c3">&#44256;&#44061;&#51060;</span><span>&nbsp;</span
            ><span class="c3">&#45817;&#49324;&#51032;</span><span>&nbsp;</span
            ><span class="c3">&#50672;&#44396;</span><span>&nbsp;</span
            ><span class="c3">&#54540;&#47019;&#54268;&#51012;</span><span>&nbsp;</span
            ><span class="c3">&#49324;&#50857;&#54624;</span><span>&nbsp;</span
            ><span class="c3">&#49688;</span><span>&nbsp;</span
            ><span class="c3">&#51080;&#46020;&#47197;</span><span>&nbsp;</span
            ><span class="c3">&#51648;&#50896;</span><span><br /></span
            ><span class="c3">&#51228;&#54408;</span><span>&nbsp;</span><span class="c3">&#48143;</span
            ><span>&nbsp;</span><span class="c3">&#49436;&#48708;&#49828;</span><span>&nbsp;</span
            ><span class="c3">&#44060;&#49440;</span><span><br /></span
            ><span class="c3">&#47928;&#51228;</span><span>&nbsp;</span
            ><span class="c3">&#54644;&#44208;</span><span><br /></span
            ><span class="c3">&#50504;&#51204;&#54620;</span><span>&nbsp;</span
            ><span class="c3">&#54540;&#47019;&#54268;</span><span><br /><br /></span
            ><span class="c3">&#48277;&#51201;</span><span>&nbsp;</span
            ><span class="c3">&#44540;&#44144;</span><span>: </span><span class="c3">&#46041;&#51032;</span
            ><span>, </span><span class="c3">&#51221;&#45817;&#54620;</span><span>&nbsp;</span
            ><span class="c3">&#51060;&#51061;</span>
          </p>
        </td>
      </tr>
    </table>

    <p class="c10">
      <span class="c6 c2 c1"
        >&#53216;&#53412; &#48143; &#45796;&#47480; &#48708;&#49847;&#54620; &#44592;&#49696;</span
      >
    </p>
    <p class="c10">
      <span class="c6 c0"
        >&#45817;&#49324;&#45716; &#54596;&#50836;&#54616;&#44256; &#51061;&#47749; &#51221;&#48372;
        &#49688;&#51665;&#51012; &#50948;&#54644; &#45817;&#49324; &#49324;&#51060;&#53944;&#50752;
        &#47784;&#48148;&#51068; &#50545;&#50640;&#49436; &#53216;&#53412;&#50752; &#45796;&#47480;
        &#48708;&#49847;&#54620; &#44592;&#49696;&#51012; &#49324;&#50857;&#54633;&#45768;&#45796;.
        &#45817;&#49324;&#45716; &#49464;&#49496; &#53216;&#53412;&#50752; &#50689;&#44396;
        &#53216;&#53412;&#47484; &#49324;&#50857;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#49464;&#49496; &#53216;&#53412;&#45716; &#51221;&#54869;&#55176; &#44480;&#54616;&#44032;
        &#48169;&#47928;&#54616;&#45716; &#46041;&#50504;&#50640;&#47564; &#49324;&#50857;&#46104;&#47728;
        &#48652;&#46972;&#50864;&#51200;&#47484; &#45803;&#51004;&#47732;
        &#49325;&#51228;&#46121;&#45768;&#45796;. &#50689;&#44396; &#53216;&#53412;&#45716;
        &#44480;&#54616;&#44032; &#51060;&#47484; &#49325;&#51228;&#54616;&#44144;&#45208;
        &#47564;&#44592;&#44032; &#46112; &#46412;&#44620;&#51648; &#44480;&#54616;
        &#46356;&#48148;&#51060;&#49828;&#51032; &#54616;&#46300;&#46356;&#49828;&#53356;
        &#46300;&#46972;&#51060;&#48652;&#50640; &#45224;&#50500; &#51080;&#49845;&#45768;&#45796;.
        &#45817;&#49324;&#45716; &#45796;&#51020; &#50976;&#54805;&#51032; &#53216;&#53412;&#47484;
        &#49324;&#50857;&#54633;&#45768;&#45796;. &nbsp;</span
      >
    </p>
    <ul class="c25 lst-kix_list_5-0 start">
      <li class="c10 c17 c36 li-bullet-0">
        <span class="c0 c33">&#54596;&#49688;&#51201;</span
        ><span class="c6 c0"
          >: &#51068;&#48512; &#53216;&#53412;&#45716; &#44480;&#54616;&#44032; &#45817;&#49324;
          &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#50640;&#49436;
          &#51060;&#46041;&#54616;&#44256;, &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
          &#47784;&#48148;&#51068; &#50545;&#51032; &#48372;&#50504; &#50689;&#50669;&#50640;
          &#51217;&#49549;&#54616;&#45716; &#46321;&#51032; &#44592;&#45733;&#51012;
          &#49324;&#50857;&#54616;&#46020;&#47197; &#54616;&#45716; &#45936;
          &#54596;&#50836;&#54633;&#45768;&#45796;. &#51060; &#53216;&#53412;&#44032; &#50630;&#51060;&#45716;
          &#45817;&#49324;&#45716; &#44480;&#54616;&#44032; &#49324;&#50857;&#54616;&#45716;
          &#46356;&#48148;&#51060;&#49828; &#50976;&#54805;&#50640; &#47582;&#45716; &#51201;&#51208;&#54620;
          &#53080;&#53584;&#52768;&#47484; &#51228;&#44277;&#54624; &#49688;
          &#50630;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c17 c34 li-bullet-0">
        <span class="c33 c0">&#49457;&#45733; &#48143; &#50528;&#45328;&#47532;&#54001;&#49828;(</span
        ><span class="c33 c1 c39">Analytics):</span><span class="c5 c1">&nbsp;</span
        ><span class="c6 c0"
          >&#45817;&#49324;&#45716; &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
          &#47784;&#48148;&#51068; &#50545;&#51012; &#54693;&#49345;&#54616;&#44256; &#44480;&#54616;&#51032;
          &#49440;&#54840; &#49324;&#54637;&#50640; &#47582;&#52628;&#50612; &#44060;&#48156;&#54616;&#44592;
          &#50948;&#54644; &#44480;&#54616;&#44032; &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
          &#47784;&#48148;&#51068; &#50545;&#51012; &#49324;&#50857;&#54616;&#45716; &#48169;&#48277;&#51012;
          &#48372;&#44592; &#50948;&#54644;&#49436;&#46020; &#53216;&#53412;&#47484;
          &#49324;&#50857;&#54633;&#45768;&#45796;. &nbsp;&#53216;&#53412;&#47484; &#53685;&#54644;
          &#44060;&#51064; &#51221;&#48372;&#47484; &#49688;&#51665;&#54616;&#51648;
          &#50506;&#49845;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <p class="c10">
      <span class="c6 c2 c1"
        >&#44060;&#51064; &#51221;&#48372;&#51032; &#44277;&#50976;&#50752; &#44277;&#44060;</span
      >
    </p>
    <p class="c10">
      <span class="c6 c0"
        >&#45817;&#49324;&#45716; &#45796;&#51020; &#44221;&#50864;&#50640; &#45796;&#51020;&#51032;
        &#49345;&#45824;&#48169;&#50640; &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484;
        &#44277;&#50976;&#54616;&#44144;&#45208; &#44277;&#44060;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c25 lst-kix_list_2-0 start">
      <li class="c10 c17 c36 li-bullet-0">
        <span class="c6 c0"
          >&#45817;&#49324;&#45716; &#45817;&#49324; &#44256;&#44061;&#51032; &#47560;&#52992;&#54021;
          &#48143; &#51312;&#49324; &#50672;&#44396; &#44536;&#47532;&#44256; &#48708;&#51592;&#45768;&#49828;
          &#47785;&#51201;&#51012; &#50948;&#54644; &#45817;&#49324; &#49324;&#51060;&#53944;
          &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#50640;&#49436; &#51228;&#44277;&#46108;
          &#51221;&#48372;&#47484; &#44277;&#50976;&#54633;&#45768;&#45796;.</span
        >
      </li>
      <li class="c34 c17 li-bullet-0">
        <span class="c6 c0"
          >&#45817;&#49324;&#45716; &#45817;&#49324;&#51032; &#51228;&#49340;&#51088; &#49436;&#48708;&#49828;
          &#51228;&#44277;&#51088;&#44032; &#50672;&#44396;&#47484; &#49688;&#54665;&#54616;&#44256;
          &#44256;&#44061;&#50640;&#44172; &#48372;&#44256;&#49436;&#47484; &#51228;&#44277;&#54624; &#49688;
          &#51080;&#46020;&#47197; &#45817;&#49324; &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
          &#47784;&#48148;&#51068; &#50545;&#50640;&#49436; &#51228;&#44277;&#46108; &#51221;&#48372;&#47484;
          &#44277;&#50976;&#54633;&#45768;&#45796;. &nbsp;&#50629;&#45936;&#51060;&#53944;&#46108;
          &#53440;&#49324; &#49436;&#48708;&#49828; &#51228;&#44277;&#50629;&#52404; &#47785;&#47197;&#51008;
          &#50668;&#44592;&#50640;&#49436; &#44160;&#53664;&#54624; &#49688;
          &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c34 c17 li-bullet-0">
        <span class="c6 c0"
          >&#45817;&#49324;&#45716; &#54633;&#48337;, &#51064;&#49688;, &#44592;&#50629; &#48516;&#54624;,
          &#54028;&#49328; &#48143; &#45817;&#49324; &#51088;&#49328;&#51032; &#51204;&#48512;
          &#46608;&#45716; &#51068;&#48512; &#47588;&#44033;&#51012; &#54252;&#54632;&#54616;&#51648;&#47564;,
          &#51060;&#50640; &#51228;&#54620;&#46104;&#51648; &#50506;&#45716; &#51649;&#51217; &#46608;&#45716;
          &#44036;&#51217;&#51201; &#44396;&#51312; &#51312;&#51221; &#44284;&#51221; &#51473;&#50640;
          &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484; &#44277;&#50976; &#46608;&#45716;
          &#51204;&#45804;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
      <li class="c34 c17 li-bullet-0">
        <span class="c6 c0"
          >&#45817;&#49324;&#45716; &#54644;&#45817;&#54616;&#45716; &#48277;&#51012;
          &#51456;&#49688;&#54616;&#44592; &#50948;&#54644;&#49436;, &#44060;&#51064; &#51221;&#48372;&#47484;
          &#44277;&#50976;&#54616;&#45716; &#54665;&#50948;&#44032; &#48277;&#51201; &#50836;&#44148;&#51012;
          &#52649;&#51313;&#54616;&#45716; &#45936; &#54596;&#50836;&#54616;&#45796;&#44256;
          &#49440;&#51032;&#47196; &#48127;&#45716; &#44221;&#50864;&#50640;, &#45817;&#49324;&#44032;
          &#52376;&#54620; &#48277;&#51201; &#51208;&#52264;&#47484; &#46384;&#47476;&#44592;
          &#50948;&#54644;&#49436;, &#45817;&#49324;&#51032; &#44428;&#47532; &#46608;&#45716;
          &#51088;&#49328;&#51032; &#48372;&#54840;&#50752; &#48169;&#50612;&#47484; &#50948;&#54644;&#49436;,
          &#46608;&#45716; &#45817;&#49324; &#49324;&#50857;&#51088;&#51032; &#44060;&#51064;&#51201;
          &#50504;&#51204;&#51012; &#48372;&#54840;&#54624; &#44596;&#44553;&#54620;
          &#49345;&#54889;&#50640;&#49436; &#51060;&#51032; &#45824;&#52376;&#47484; &#50948;&#54644;
          &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484; &#44277;&#44060;&#54624;
          &#44163;&#51077;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <p class="c10">
      <span class="c0">&#51201;&#50857;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44032;&#45733;&#54620;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#48120;&#44397;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49548;&#48708;&#51088;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44060;&#51064;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#48372;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#48372;&#54840;&#48277;&#51032;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#51032;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#45236;&#50640;&#49436;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#54032;&#47588;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49548;&#48708;&#51088;&#51032;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44060;&#51064;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#48372;&#47484;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#54032;&#47588;</span><span class="c1">, </span
      ><span class="c0">&#51076;&#45824;</span><span class="c1">, </span
      ><span class="c0">&#44277;&#44060;</span><span class="c1">, </span
      ><span class="c0">&#44277;&#44060;</span><span class="c1">, </span
      ><span class="c0">&#48372;&#44553;</span><span class="c1">, </span
      ><span class="c0">&#51228;&#44277;</span><span class="c1">, </span
      ><span class="c0">&#51060;&#51204;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#46608;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44396;&#46160;</span><span class="c1">, </span
      ><span class="c0">&#49436;&#47732;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#46608;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51204;&#51088;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#46608;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44592;&#53440;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49688;&#45800;&#51004;&#47196;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51204;&#45804;&#54616;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44163;&#51004;&#47196;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#51032;&#46121;&#45768;&#45796;</span><span class="c1">. </span
      ><span class="c0">&#44552;&#51204;&#51201;</span><span class="c1">, </span
      ><span class="c0">&#46412;&#47196;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44592;&#53440;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44032;&#52824;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51080;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#45824;&#44032;&#47484;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#50948;&#54644;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#45796;&#47480;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49324;&#50629;&#52404;&#45208;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51228;</span><span class="c1">3</span
      ><span class="c0">&#51088;&#50640;&#44172;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49324;&#50629;&#51012;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51228;&#44277;&#54633;&#45768;&#45796;</span><span class="c5 c1">.</span>
    </p>
    <p class="c10">
      <span class="c0">&#45817;&#49324;&#45716;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#48120;&#44397;</span><span class="c1">&nbsp;</span><span class="c0">&#51452;</span
      ><span class="c1">&nbsp;</span><span class="c0">&#44060;&#51064;&#51221;&#48372;</span
      ><span class="c1">&nbsp;</span><span class="c0">&#48372;&#54840;&#48277;&#50640;</span
      ><span class="c1">&nbsp;</span><span class="c0">&#51221;&#51032;&#46108;</span
      ><span class="c1">&nbsp;</span><span class="c0">&#45824;&#47196;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44060;&#51064;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#48372;&#47484;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#54032;&#47588;&#54616;&#51648;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#50506;&#51004;&#47728;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44480;&#54616;&#51032;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44060;&#51064;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51221;&#48372;&#47484;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#49464;&#44228;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#50612;&#45712;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#44275;&#50640;&#49436;&#46020;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#51228;</span><span class="c1">3</span
      ><span class="c0">&#51088;&#50640;&#44172;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#54032;&#47588;&#54616;&#51648;</span><span class="c1">&nbsp;</span
      ><span class="c0">&#50506;&#49845;&#45768;&#45796;</span><span class="c5 c1">.</span>
    </p>
    <p class="c28">
      <span class="c6 c2 c1"
        >&#50612;&#47536;&#51060;&#51032; &#51221;&#48372; &#49688;&#51665; &#48143; &#49324;&#50857;</span
      >
    </p>
    <p class="c28">
      <span class="c6 c0"
        >&#45817;&#49324;&#45716; &#48120;&#44397;&#50640;&#49436;&#45716; 13&#49464; &#48120;&#47564;,
        EU&#50640;&#49436;&#45716; 16&#49464; &#48120;&#47564;
        &#50612;&#47536;&#51060;&#47196;&#48512;&#53552;&#45716; &#51060; &#45208;&#51060;&#47484;
        &#51064;&#51648;&#54620; &#49345;&#53468;&#50640;&#49436;&#45716; &#44060;&#51064;
        &#51221;&#48372;&#47484; &#49688;&#51665;&#54616;&#51648; &#50506;&#49845;&#45768;&#45796;.
        &#45817;&#49324;&#45716; &#48120;&#44397;&#50640;&#49436;&#45716; 13&#49464; &#48120;&#47564;,
        EU&#50640;&#49436;&#45716; 16&#49464; &#48120;&#47564; &#50612;&#47536;&#51060;&#44032;
        &#45817;&#49324;&#50640; &#44060;&#51064; &#51221;&#48372;&#47484;
        &#51228;&#44277;&#54664;&#45796;&#45716; &#49324;&#49892;&#51012; &#50508;&#44172; &#46104;&#47732;
        &#44536; &#44060;&#51064; &#51221;&#48372;&#47484; &#49325;&#51228;&#54624;
        &#44163;&#51077;&#45768;&#45796;.</span
      >
    </p>
    <p class="c28">
      <span class="c6 c2 c1">&#51217;&#49549; &#48143; &#49440;&#53469; &#52712;&#49548;</span>
    </p>
    <p class="c28">
      <span class="c6 c0"
        >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#44032;
        &#48320;&#44221;&#46104;&#50632;&#51004;&#47728; &#45817;&#49324;&#50640;&#49436; &#48372;&#50976;
        &#51473;&#51064; &#44480;&#54616;&#51032; &#54028;&#51068; &#51221;&#48372;&#47484;
        &#50629;&#45936;&#51060;&#53944;&#54616;&#44592; &#50896;&#54616;&#47732; &#50500;&#47000;
        &#50672;&#46973;&#52376; &#51221;&#48372;&#47196;
        &#50672;&#46973;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c28">
      <span class="c6 c0"
        >&#45817;&#49324;&#47196;&#48512;&#53552; &#45908;&#45716; &#47560;&#52992;&#54021;
        &#49548;&#49885;&#51012; &#49688;&#49888;&#54616;&#44592; &#50896;&#54616;&#51648;
        &#50506;&#51004;&#47732; &#47560;&#52992;&#54021; &#49548;&#49885;&#51648;&#50640;
        &#51228;&#44277;&#46108; &#44396;&#46021; &#52712;&#49548; &#50504;&#45236;&#50640; &#46384;&#46972;
        &#49440;&#53469;&#51012; &#52712;&#49548;&#54616;&#44144;&#45208; &#50500;&#47000;
        &#51452;&#49548;&#47196; &#50672;&#46973;&#54616;&#49901;&#49884;&#50724;. &#52572;&#45824;&#54620;
        &#48744;&#47532;, &#54644;&#45817; &#48277;&#51012; &#51456;&#49688;&#54616;&#47732;&#49436;
        &#44396;&#46021; &#52712;&#49548;&#47484; &#52376;&#47532;&#54616;&#44192;&#51648;&#47564;,
        &#46412;&#47196;&#45716; &#44396;&#46021; &#52712;&#49548;&#44032; &#52376;&#47532;&#46104;&#44256;
        &#50756;&#47308;&#46112; &#46412;&#44620;&#51648; &#47751; &#48264; &#45908;
        &#47700;&#49884;&#51648;&#47484; &#48155;&#51012; &#49688;&#46020; &#51080;&#51020;&#51012;
        &#51452;&#51648;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c28">
      <span class="c6 c0"
        >&#45817;&#49324;&#51032; &#47560;&#52992;&#54021; &#49548;&#49885;&#51648;&#51032;
        &#49688;&#49888;&#51060; &#52712;&#49548;&#46104;&#50612;&#46020; &#45817;&#49324;&#45716;
        &#50668;&#51204;&#55176; &#44480;&#54616;&#51032; &#51452;&#47928; &#46608;, &#50696;&#47484;
        &#46308;&#50612;, &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068;
        &#50545;&#50640; &#45824;&#54620; &#51473;&#50836; &#50629;&#45936;&#51060;&#53944; &#46321;&#51012;
        &#54252;&#54632;&#54620; &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068;
        &#44288;&#47532; &#47785;&#51201;&#51032; &#47700;&#49884;&#51648;&#47484; &#48372;&#45236;&#44172;
        &#46112; &#44163;&#51077;&#45768;&#45796;. &nbsp;</span
      >
    </p>
    <p class="c28"><span class="c6 c2 c1">&#44480;&#54616;&#51032; &#44428;&#47532;</span></p>
    <p class="c28">
      <span class="c6 c0"
        >EEA, &#50689;&#44397; &#46608;&#45716; &#49828;&#50948;&#49828; &#44144;&#51452;&#51088;&#51064;
        &#44221;&#50864; &#45796;&#51020;&#51012; &#50836;&#52397;&#54624; &#44428;&#47532;&#44032;
        &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c25 lst-kix_list_7-0 start">
      <li class="c17 c22 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#44032;
          &#52376;&#47532;&#46104;&#45716;&#51648; &#50668;&#48512;&#47484; &#50508;&#44592;
          &#50948;&#54644;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#50640; &#45824;&#54620;
          &#50529;&#49464;&#49828;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484;
          &#54200;&#51665;&#54616;&#44144;&#45208; &#49324;&#48376;&#51012;
          &#48372;&#45236;&#46300;&#47549;&#45768;&#45796;.</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44060;&#51064; &#51221;&#48372;&#51032; &#49325;&#51228; &#46608;&#45716;
          &#51061;&#47749;&#54868;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#50864;&#47532;&#45716; &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;
          &#49324;&#50857;&#51012; &#51228;&#54620;&#54633;&#45768;&#45796;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#50864;&#47532;&#45716; &#45817;&#49888;&#44284;&#51032; &#52628;&#44032; &#50672;&#44396;
          &#52964;&#48036;&#45768;&#52992;&#51060;&#49496; &#46608;&#45716; &#45824;&#49345; &#50672;&#44396;
          &#52280;&#50668;&#47484; &#53356;&#44592; &#51312;&#51221;&#54633;&#45768;&#45796;.</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#51088;&#46041; &#51032;&#49324; &#44208;&#51221; &#48143;
          &#54532;&#47196;&#54028;&#51068;&#47553; &#46020;&#44396; &#54876;&#50857; &#50668;&#48512;&#47484;
          &#50508;&#47140;&#46300;&#47549;&#45768;&#45796;.</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44288;&#47144; &#45936;&#51060;&#53552; &#48372;&#54840; &#44048;&#46021;
          &#44592;&#44288;&#50640; &#48520;&#47564; &#51228;&#44592;</span
        >
      </li>
    </ul>
    <p class="c28">
      <span class="c6 c0"
        >EU &#45936;&#51060;&#53552; &#48372;&#54840; &#44592;&#44288; &#47785;&#47197;:</span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1666016699201026&amp;usg=AOvVaw1PT-VU7nsK52EHcPVps8GE"
          >EEA</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html&amp;sa=D&amp;source=editors&amp;ust=1666016699201431&amp;usg=AOvVaw0sJwCuH73vv_aGrDvDpbuP"
          >&#49828;&#50948;&#49828;</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1666016699201661&amp;usg=AOvVaw1SmfdDlyGWTCVG7mcdo4ho"
          >&#50689;&#44397;</a
        ></span
      >
    </p>
    <p class="c28">
      <span class="c6 c0"
        >&#52888;&#47532;&#54252;&#45768;&#50500;, &#53076;&#45348;&#54000;&#52983;, &#50976;&#53440;,
        &#53084;&#47196;&#46972;&#46020;, &#48260;&#51648;&#45768;&#50500; &#46608;&#45716; &#44592;&#53440;
        &#44060;&#51064;&#51221;&#48372; &#48372;&#54840;&#48277;&#51060; &#51080;&#45716; &#48120;&#44397;
        &#51452;&#50640; &#44144;&#51452;&#54616;&#45716; &#44221;&#50864; &#45796;&#51020;&#51012;
        &#50836;&#52397;&#54624; &#44428;&#47532;&#44032; &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c25 lst-kix_list_8-0 start">
      <li class="c22 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#50640; &#45824;&#54620;
          &#50529;&#49464;&#49828;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c0 c6">&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372; &#49688;&#51221;</span>
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#45817;&#49324;&#44032; &#44480;&#54616;&#51032; &#51221;&#48372;&#47484;
          &#54032;&#47588;&#54664;&#45716;&#51648; &#50668;&#48512;&#47484; &#54869;&#51064;&#54616;&#44256;
          &#44060;&#51064; &#51221;&#48372; &#54032;&#47588;&#47484;
          &#44144;&#48512;&#54633;&#45768;&#45796;.</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0">&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372; &#49325;&#51228;</span>
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484;
          &#44396;&#51312;&#54868;&#46104;&#44256; &#51068;&#48152;&#51201;&#51004;&#47196;
          &#49324;&#50857;&#46104;&#47728; &#44592;&#44228;&#44032; &#51069;&#51012; &#49688; &#51080;&#45716;
          &#54805;&#49885;&#51004;&#47196; &#49789;&#44172; &#51060;&#54644;&#54624; &#49688; &#51080;&#44256;
          &#44592;&#49696;&#51201;&#51004;&#47196; &#44032;&#45733;&#54620; &#48276;&#50948;&#50640;&#49436;
          &#48155;&#51012; &#44428;&#47532;</span
        >
      </li>
    </ul>
    <p class="c28">
      <span class="c6 c0"
        >&#48120;&#44397; &#45936;&#51060;&#53552; &#48372;&#54840; &#44592;&#44288; &#47785;&#47197;:</span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://oag.ca.gov/privacy&amp;sa=D&amp;source=editors&amp;ust=1666016699202197&amp;usg=AOvVaw3x7Qn-xuFlGgUBMF6RIOmb"
          >&#52888;&#47532;&#54252;&#45768;&#50500;</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://portal.ct.gov/AG/Sections/Privacy/The-Privacy-and-Data-Security-Section&amp;sa=D&amp;source=editors&amp;ust=1666016699202480&amp;usg=AOvVaw1IoXO1R3BSDYgVAjl1tkXK"
          >&#53076;&#45348;&#54000;&#52983;</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c3 c19"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://attorneygeneral.utah.gov/&amp;sa=D&amp;source=editors&amp;ust=1666016699202748&amp;usg=AOvVaw1OAhLUdlhJDd4UuI_DZgRH"
          >&#50976;&#53440;</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://coag.gov/resources/data-protection-laws/&amp;sa=D&amp;source=editors&amp;ust=1666016699202987&amp;usg=AOvVaw3hOu34PsOFr2EOWW9QDAgv"
          >&#53084;&#47196;&#46972;&#46020;</a
        ></span
      >
    </p>
    <p class="c12">
      <span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=https://www.oag.state.va.us/index.php?option%3Dcom_content%26view%3Darticle%26id%3D335&amp;sa=D&amp;source=editors&amp;ust=1666016699203240&amp;usg=AOvVaw3X7BthbDq_i19IzwZgnSTe"
          >&#50668;&#51088; &#51060;&#47492;</a
        ></span
      >
    </p>
    <p class="c12 c18"><span class="c6 c0"></span></p>
    <p class="c12">
      <span class="c6 c0"
        >&#51473;&#54868; &#51064;&#48124; &#44277;&#54868;&#44397; &#44397;&#44221; &#45236;&#50640;
        &#44144;&#51452;&#54616;&#45716; &#44221;&#50864; &#51473;&#44397; &#44060;&#51064; &#51221;&#48372;
        &#48372;&#54840; &#51221;&#52293;&#51012; &#48169;&#47928;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c12 c18"><span class="c6 c0"></span></p>
    <p class="c12">
      <span class="c6 c0"
        >&#44428;&#47532;&#47484; &#54665;&#49324;&#54616;&#47140;&#47732;
        privacy@Spark-nyc.com&#51004;&#47196; &#50836;&#52397; &#51060;&#47700;&#51068;&#51012;
        &#48372;&#45236;&#51452;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c28"><span class="c6 c2 c1">&#51228;&#49340;&#51088; &#47553;&#53356;</span></p>
    <p class="c47">
      <span class="c6 c0"
        >&#49828;&#54028;&#53356;&#45716; &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;
        &#45936;&#51060;&#53552;&#44032; &#50937;&#49324;&#51060;&#53944;&#50640;&#49436; &#49688;&#51665;,
        &#49324;&#50857; &#48143; &#44277;&#50976;&#46104;&#45716; &#48169;&#48277;&#51012;
        &#44480;&#54616;&#44032; &#50508;&#50500;&#48380; &#49688; &#51080;&#46020;&#47197;
        &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#50640;
        &#50672;&#46041;&#46108; &#50937;&#49324;&#51060;&#53944;&#51032; &#44060;&#51064; &#51221;&#48372;
        &#47928;&#49436;&#47484; &#44160;&#53664;&#54624; &#44163;&#51012;
        &#44428;&#51109;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <p class="c18 c28"><span class="c6 c2 c1"></span></p>
    <p class="c28 c18"><span class="c6 c2 c1"></span></p>
    <p class="c28">
      <span class="c6 c2 c1">&#44060;&#51064; &#51221;&#48372;&#51032; &#48372;&#54840;</span>
    </p>
    <p class="c21">
      <span class="c6 c0"
        >&#49828;&#54028;&#53356;&#50640;&#49436;&#45716; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372;&#44032; &#47924;&#45800;&#51004;&#47196; &#51217;&#49549;, &#49324;&#50857;,
        &#46608;&#45716; &#44277;&#44060;&#46104;&#45716; &#44163;&#51012; &#48372;&#54840;&#54616;&#44592;
        &#50948;&#54620; &#54633;&#47532;&#51201;&#51064; &#49688;&#51456;&#51032;
        &#45824;&#51025;&#52293;&#51012; &#51201;&#50857;&#54616;&#44256; &#51080;&#49845;&#45768;&#45796;.
        &#44536;&#47111;&#44596; &#54616;&#51648;&#47564;, &#51064;&#53552;&#45367;&#51012; &#53685;&#54620;
        &#50612;&#46500; &#45936;&#51060;&#53552;&#51032; &#51204;&#49569;&#46020; 100%
        &#50504;&#51204;&#54616;&#45796;&#44256; &#48372;&#51109;&#54624; &#49688;&#45716;
        &#50630;&#45796;&#45716; &#51216;&#46020; &#50508;&#50500;&#46160;&#49901;&#49884;&#50724;.
        &#46384;&#46972;&#49436;, &#45817;&#49324;&#45716; &#44480;&#54616;&#44032; &#45817;&#49324;&#50640;
        &#51204;&#49569;&#54616;&#45716; &#47784;&#46304; &#51221;&#48372;&#51032;
        &#50504;&#51204;&#49457;&#51012; &#54869;&#48372; &#46608;&#45716; &#48372;&#51109;&#54624; &#49688;
        &#50630;&#51004;&#47728;, &#44480;&#54616;&#44032; &#45817;&#49324;&#50640;
        &#45936;&#51060;&#53552;&#47484; &#51204;&#49569;&#54616;&#45716; &#44163;&#51008;
        &#51204;&#51201;&#51004;&#47196; &#44480;&#54616;&#51032; &#52293;&#51076;&#51076;&#51012;
        &#51064;&#51648;&#54616;&#49901;&#49884;&#50724;. &#45817;&#49324;&#44032; &#48372;&#50504;
        &#49884;&#49828;&#53596; &#50948;&#48152;&#51012; &#50508;&#44172; &#46104;&#47732;
        &#45817;&#49324;&#45716; &#51204;&#51088; &#48169;&#49885;&#51004;&#47196;
        &#44480;&#54616;&#50640;&#44172; &#51060;&#47484; &#53685;&#48372;&#54616;&#50668;
        &#44480;&#54616;&#44032; &#51201;&#51208;&#54620; &#48372;&#54840; &#51312;&#52824;&#47484;
        &#52712;&#54624; &#49688; &#51080;&#46020;&#47197; &#54624; &#44163;&#51077;&#45768;&#45796;.
        &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#51012;
        &#49324;&#50857;&#54616;&#44144;&#45208;, &#46608;&#45716; &#44480;&#54616;&#44032;
        &#45817;&#49324;&#50640; &#44060;&#51064; &#51221;&#48372;&#47484; &#51228;&#44277;&#54624; &#46412;,
        &#45817;&#49324;&#44032; &#44480;&#54616;&#51032; &#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
        &#47784;&#48148;&#51068; &#50545;&#51032; &#49324;&#50857;&#50640; &#44288;&#54620; &#48372;&#50504;,
        &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#48143; &#44288;&#47532;&#49345;
        &#47928;&#51228;&#50640; &#44288;&#54644; &#50672;&#46973;&#54624; &#46412;, &#44480;&#54616;&#45716;
        &#45817;&#49324;&#44032; &#51204;&#51088; &#48169;&#49885;&#51012; &#49324;&#50857;&#54624; &#49688;
        &#51080;&#45796;&#45716; &#51216;&#50640; &#46041;&#51032;&#54616;&#45716;
        &#44163;&#51077;&#45768;&#45796;. &#48372;&#50504; &#50948;&#48152;&#51060;
        &#48156;&#49373;&#54616;&#47732; &#45817;&#49324;&#45716; &#49324;&#51060;&#53944;
        &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#51012; &#53685;&#54644;
        &#53685;&#51648;&#47928;&#51012; &#44172;&#49884;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.
        &#45817;&#49324;&#45716; &#46608;&#54620; &#51060;&#47084;&#54620; &#49345;&#54889;&#50640;
        &#44480;&#54616;&#44032; &#51228;&#44277;&#54620; &#51060;&#47700;&#51068; &#51452;&#49548;&#47196;
        &#51060;&#47700;&#51068;&#51012; &#48372;&#45244; &#49688;&#46020; &#51080;&#49845;&#45768;&#45796;.
        &#44480;&#54616;&#51032; &#44144;&#51452; &#51648;&#50669;&#50640; &#46384;&#46972;&#49436;
        &#44480;&#54616;&#50640;&#44172;&#45716; &#48372;&#50504; &#50948;&#48152;&#50640; &#44288;&#54644;
        &#49436;&#47732;&#51004;&#47196; &#53685;&#48372;&#48155;&#51012; &#48277;&#51201;
        &#44428;&#47532;&#44032; &#51080;&#51012; &#49688; &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c21"><span class="c6 c2 c1">&#45936;&#51060;&#53552; &#48372;&#51316;</span></p>
    <p class="c21">
      <span class="c6 c0"
        >&#45817;&#49324;&#45716;, &#45908; &#44596; &#44592;&#44036;&#51060;
        &#50836;&#44396;&#46104;&#44144;&#45208; &#48277;&#50640;&#49436; &#54728;&#50857;&#54616;&#45716;
        &#44221;&#50864;&#44032; &#50500;&#45768;&#47732;, &#51060; &#44060;&#51064; &#51221;&#48372;
        &#48372;&#54840; &#51221;&#52293;&#50640; &#49444;&#47749;&#46108; &#47785;&#51201;&#51012;
        &#49688;&#54665;&#54616;&#45716; &#45936; &#54596;&#50836;&#54620; &#46041;&#50504;
        &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484;
        &#48372;&#51316;&#54633;&#45768;&#45796;.
      </span>
    </p>
    <p class="c21 c18"><span class="c5 c1"></span></p>
    <p class="c11">
      <span class="c6 c2 c1">&#44397;&#51228;&#51201; &#45936;&#51060;&#53552; &#51204;&#49569;</span>
    </p>
    <p class="c11">
      <span class="c6 c0"
        >&#44480;&#54616;&#44032; &#45817;&#49324;&#50640; &#51221;&#48372;&#47484;
        &#51228;&#44277;&#54616;&#47732;, &#45817;&#49324;&#45716; &#44536; &#51221;&#48372;&#47484;
        &#44397;&#44221;&#51012; &#45336;&#50612; &#51204; &#49464;&#44228;&#51201;&#51004;&#47196;,
        &#44480;&#54616;&#51032; &#44397;&#44032; &#46608;&#45716; &#44288;&#54624;&#44428;&#50640;&#49436;
        &#45796;&#47480; &#44397;&#44032;&#45208; &#44288;&#54624;&#44428;&#51004;&#47196;,
        &#45817;&#49324;&#51032; &#51228;&#55092;&#49324; &#48143; &#44228;&#50676;&#49324; &#46608;&#45716;
        &#45796;&#47480; &#51228;&#49340;&#51088; &#44592;&#44288;&#51004;&#47196; &#51204;&#49569;&#54624;
        &#49688; &#51080;&#49845;&#45768;&#45796;. &#44480;&#54616;&#44032; &#45936;&#51060;&#53552;
        &#49688;&#51665;&#44284; &#49324;&#50857;&#51012; &#44288;&#51109;&#54616;&#45716; &#48277;&#51060;
        &#48120;&#44397; &#48277;&#44284; &#45796;&#47484; &#49688; &#51080;&#45716; EU &#46608;&#45716;
        &#45796;&#47480; &#51648;&#50669;&#50640;&#49436; &#48169;&#47928;&#54616;&#45716;
        &#51473;&#51060;&#47732;, &#44480;&#54616;&#51032; &#44144;&#51452;
        &#44397;&#44032;&#50640;&#49436;&#50752; &#46041;&#51068;&#54620; &#45936;&#51060;&#53552;
        &#48372;&#54840;&#48277;&#51060; &#50630;&#45716; &#48120;&#44397; &#48143; &#45796;&#47480;
        &#44288;&#54624;&#44428;&#51004;&#47196; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372;&#44032; &#51204;&#49569;&#46120;&#46020;
        &#51452;&#51648;&#54616;&#49901;&#49884;&#50724;. &#44480;&#54616;&#45716; &#44480;&#54616;&#51032;
        &#44060;&#51064; &#51221;&#48372;&#47484; &#51228;&#44277;&#54632;&#51004;&#47196;&#50024;
        &#45796;&#51020;&#51012; &#51060;&#54644;&#54616;&#44256; &#51080;&#51020;&#51012;
        &#51064;&#51221;&#54633;&#45768;&#45796;.</span
      >
    </p>
    <ul class="c25 lst-kix_list_6-0 start">
      <li class="c11 c17 c36 li-bullet-0">
        <span class="c6 c0"
          >&#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#44032; &#51060; &#44060;&#51064;
          &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#50640; &#46384;&#46972; &#50948;&#50640;
          &#49444;&#47749;&#46108; &#49324;&#50857; &#47785;&#51201;&#51012; &#50948;&#54644;
          &#49324;&#50857;&#46108;&#45796;; &#44536;&#47532;&#44256;</span
        >
      </li>
      <li class="c16 c17 li-bullet-0">
        <span class="c6 c0"
          >&#50948;&#50640; &#45208;&#50752; &#51080;&#45716; &#45824;&#47196;, &#44480;&#54616;&#51032;
          &#44060;&#51064; &#51221;&#48372;&#44032; &#54644;&#45817;&#54616;&#45716; &#48277;&#50640;
          &#46384;&#46972; &#48120;&#44397;&#44284; &#45796;&#47480; &#44288;&#54624;&#44428;&#51004;&#47196;
          &#51204;&#49569;&#46112; &#49688; &#51080;&#45796;. &nbsp;&#50668;&#44592;&#50640;&#49436;
          &#54788;&#51116; &#54616;&#50948; &#52376;&#47532;&#51088; &#47785;&#47197;&#44284;
          &#45936;&#51060;&#53552; &#51204;&#49569;&#50640; &#45824;&#54620; &#48372;&#50756;
          &#51312;&#52824;&#47484; &#44160;&#53664;&#54624; &#49688; &#51080;&#49845;&#45768;&#45796;.</span
        >
      </li>
    </ul>
    <p class="c16"><span class="c6 c0">&nbsp;</span></p>
    <p class="c11"><span class="c6 c2 c1">&#50577;&#46020;</span></p>
    <p class="c11">
      <span class="c6 c0"
        >&#45817;&#49324;&#51032; &#51088;&#49328; &#51204;&#52404; &#46608;&#45716; &#51068;&#48512;&#44032;
        &#47588;&#44033;&#46104;&#44144;&#45208; &#45796;&#47480; &#45817;&#49324;&#51088;&#50640;
        &#51032;&#54644; &#47588;&#51077;&#46104;&#45716; &#44221;&#50864;, &#46608;&#45716; &#54633;&#48337;,
        &#51064;&#49688;, &#47588;&#44033;, &#54028;&#49328; &#48143; &#45817;&#49324;
        &#51088;&#49328;&#51032; &#51204;&#52404; &#46608;&#45716; &#51068;&#48512;&#44032;
        &#47588;&#44033;&#46104;&#45716; &#44221;&#50864; &#46321;&#51012;
        &#54252;&#54632;&#54616;&#51648;&#47564;, &#51060;&#50640; &#51228;&#54620;&#46104;&#51648;
        &#50506;&#45716; &#47784;&#46304; &#51649;&#51217; &#46608;&#45716; &#44036;&#51217;&#51201;
        &#51312;&#51649; &#44060;&#54200;&#51060; &#48156;&#49373;&#54616;&#45716; &#44221;&#50864;,
        &#44480;&#54616;&#45716; &#51060;&#47084;&#54620; &#44144;&#47000;&#51032; &#50756;&#47308;
        &#48143;/&#46608;&#45716; &#51060;&#52404;&#47484; &#45824;&#44592;&#54616;&#45716; &#54217;&#44032;
        &#44284;&#51221;&#51060; &#51333;&#47308;&#46108; &#54980;&#50640; &#45817;&#49324;&#44032;
        &#50937;&#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#51012;
        &#53685;&#54644; &#49688;&#51665;&#46108; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372;&#47484; &#50577;&#46020;&#54624; &#44428;&#47532;&#47484; &#45817;&#49324;&#50640;
        &#51228;&#44277;&#54633;&#45768;&#45796;. &#51060;&#47084;&#54620; &#44221;&#50864;&#47484;
        &#51060;&#50976;&#47196; &#50577;&#46020;&#46104;&#45716; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372;&#45716; &#51060; &#44060;&#51064; &#51221;&#48372; &#51221;&#52293; &#46608;&#45716;
        &#52572;&#49548;&#54620;, &#51060; &#44060;&#51064; &#51221;&#48372; &#51221;&#52293;&#44284;
        &#44057;&#51008; &#45236;&#50857;&#51004;&#47196;&#50024; &#44480;&#54616;&#51032; &#44060;&#51064;
        &#51221;&#48372;&#47484; &#48372;&#54840;&#54624; &#44060;&#51064; &#51221;&#48372;
        &#51221;&#52293;&#51012; &#44228;&#49549; &#46384;&#47476;&#44172; &#46121;&#45768;&#45796;.
      </span>
    </p>
    <p class="c11">
      <span class="c6 c2 c1"
        >&#48516;&#51137; &#54644;&#44208; &#48143; &#51473;&#51116;&#50640; &#45824;&#54620;
        &#46041;&#51032;</span
      >
    </p>
    <p class="c20 c18 c43"><span class="c5 c1"></span></p>
    <p class="c20 c43">
      <span class="c6 c0"
        >&#48277;&#50640;&#49436; &#44552;&#54616;&#45716; &#51109;&#49548;&#50752; &#51221;&#46020;&#47484;
        &#51228;&#50808;&#54616;&#44256;&#45716; &#50937;&#49324;&#51060;&#53944; &#48143;/&#46608;&#45716;
        &#47784;&#48148;&#51068; &#50545;&#51012; &#49324;&#50857;&#54632;&#51004;&#47196;&#50024;
        &#44480;&#54616;&#50752; &#49828;&#54028;&#53356; &#50577;&#52769;&#51008; &#44480;&#54616;&#44032;
        &#50937;&#49324;&#51060;&#53944; &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545;&#51032;
        &#49324;&#50857;&#51004;&#47196; &#48156;&#49373;&#54616;&#44144;&#45208; &#51060;&#51032;
        &#49324;&#50857;&#50640; &#50672;&#44288;&#46108; &#44221;&#50864; &#44536;&#47532;&#44256; &#51060;
        &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293; &#46608;&#45716; &#44536;
        &#51068;&#48512;&#50640; &#45824;&#54620; &#50948;&#48152;, &#51665;&#54665;, &#54644;&#49437;
        &#46608;&#45716; &#50976;&#54952;&#49457;&#50640; &#44288;&#54644; &#45436;&#46976;,
        &#53364;&#47112;&#51076;, &#49548;&#49569; &#46608;&#45716; &#48516;&#51137;&#51060;
        &#49373;&#44592;&#45716; &#44221;&#50864;, &#44536;&#47084;&#54620; &#48516;&#51137;&#50640;
        &#45824;&#54620; &#49324;&#49892;&#44284; &#51221;&#54889;&#51012; &#49444;&#47749;&#54616;&#45716;
        &#49436;&#47732; &#53685;&#51648;&#49436;&#47484; &#49345;&#45824;&#48169;&#50640;&#44172;
        &#51228;&#44277;&#54620; &#54980; &#51060;&#47484; &#48155;&#51008; &#49345;&#45824;&#48169;&#51060;
        &#44536; &#48516;&#51137;&#50640; &#45824;&#54644; &#51025;&#45813; &#46608;&#45716;
        &#54633;&#51032;&#54624; &#49688; &#51080;&#46020;&#47197; &#49340;&#49901;(30) &#51068;&#51012;
        &#54728;&#50857;&#54632;&#51004;&#47196;&#50024; &#47676;&#51200; &#48516;&#51137;&#51012;
        &#49440;&#51032;&#47196; &#54633;&#51032;&#54624; &#49688; &#51080;&#46020;&#47197;
        &#49884;&#46020;&#54644;&#50556; &#54633;&#45768;&#45796;. &#53685;&#51648;&#49436;&#45716;
        &#45796;&#51020; &#51452;&#49548;&#47196; &#45817;&#49324;&#50640; &#48372;&#45244; &#49688;
        &#51080;&#49845;&#45768;&#45796;.
      </span>
    </p>
    <p class="c9"><span class="c5 c1">Spark Ideas, LLC</span></p>
    <p class="c31"><span class="c5 c1">1328 Ridge Road</span></p>
    <p class="c29"><span class="c5 c1">Syosset, New York 11791</span></p>
    <p class="c20 c43 c18"><span class="c5 c1"></span></p>
    <p class="c20 c43">
      <span class="c3"
        >&#44480;&#54616;&#50752; &#49828;&#54028;&#53356;&#45716; &#51060; &#48516;&#51137; &#54644;&#44208;
        &#51208;&#52264;&#44032; &#49345;&#45824;&#48169;&#50640; &#45824;&#54620; &#50612;&#46500;
        &#48277;&#51221; &#49548;&#49569;&#51060;&#45208; &#53364;&#47112;&#51076;&#51012;
        &#51217;&#49688;&#54616;&#44592; &#51204;&#50640; &#48152;&#46300;&#49884;
        &#52649;&#51313;&#46104;&#50612;&#50556; &#54616;&#45716; &#49440;&#54665;
        &#51312;&#44148;&#51076;&#50640; &#46041;&#51032;&#54633;&#45768;&#45796;. &#47564;&#50557;
        &#50612;&#46500; &#48516;&#51137;&#51060; &#50948; &#48516;&#51137; &#54644;&#44208;
        &#51208;&#52264;&#47484; &#53685;&#54644; &#54644;&#44208;&#46112; &#49688; &#50630;&#45796;&#47732;,
        &#44480;&#54616;&#45716; &#44536;&#47084;&#54620; &#48516;&#51137;&#50640; &#45824;&#54620;
        &#50976;&#51068;&#54616;&#47728; &#48176;&#53440;&#51201;&#51064; &#44288;&#54624;&#44428;&#51008;
        &#44060;&#48324;&#51201; &#44592;&#48152;&#51032; &#48277;&#51201; &#44396;&#49549;&#47141;&#51012;
        &#44054;&#45716; &#51473;&#51116;&#47484; &#53685;&#54644; &#54032;&#44208;&#46112; &#44163;&#50640;
        &#46041;&#51032;&#54633;&#45768;&#45796;. &#44060;&#48324;&#51201; &#44540;&#44144;&#50640;
        &#51032;&#54620; &#51473;&#51116;&#46976; &#44480;&#54616;&#51032; &#53364;&#47112;&#51076;&#51012;
        &#54032;&#49324; &#46608;&#45716; &#48176;&#49900;&#50896;&#51060; &#54032;&#45800;&#54624;
        &#44428;&#47532;&#44032; &#44480;&#54616;&#50640;&#44172; &#51080;&#51648; &#50506;&#44256;,
        &#44480;&#54616;&#45716; &#51060; &#44428;&#47532;&#47484; &#54252;&#44592;&#54616;&#47728;,
        &#44480;&#54616;&#44032; &#51060;&#47484; &#51665;&#45800;, &#45824;&#54364;, &#46608;&#45716;
        &#45824;&#54364;&#51088; &#51088;&#44201;&#51004;&#47196; &#51652;&#54665;&#54624; &#49688;
        &#50630;&#45796;&#45716; &#51032;&#48120;&#51077;&#45768;&#45796;. &#51473;&#51116;
        &#44284;&#51221;&#50640;&#49436;&#45716; &#44480;&#54616;&#50752; &#45817;&#49324;&#44032;
        &#48277;&#51221;&#50640;&#49436; &#51060;&#50857;&#54624; &#49688; &#51080;&#45716; &#51613;&#44144;
        &#44060;&#49884; &#51208;&#52264; &#48143; &#49345;&#44256;&#44428;&#51012; &#54252;&#54632;&#54620;
        &#45796;&#47480; &#44428;&#47532;&#47484; &#49324;&#50857;&#54624; &#49688; &#50630;&#44144;&#45208;
        &#51060;&#50640; &#45824;&#54620; &#51228;&#54620;&#51060; &#51080;&#49845;&#45768;&#45796;.
        &#44536;&#47084;&#54620; &#47784;&#46304; &#48516;&#51137;&#51008; &#48277;&#51201;
        &#44396;&#49549;&#47141;&#51012; &#44054;&#45716; &#51473;&#51116;&#47484; &#50948;&#54644; &#44536;
        &#51473;&#51116; &#44508;&#52825;&#51012; &#46384;&#47480; &#54980; &#54952;&#47141;&#51060;
        &#48156;&#49373;&#54616;&#45716; JAMS(</span
      ><span class="c19 c3"
        ><a
          class="c37"
          href="https://www.google.com/url?q=http://www.jamsadr.com&amp;sa=D&amp;source=editors&amp;ust=1666016699205769&amp;usg=AOvVaw27NkBVgjyYun0mZoHE8Gk5"
          >www.jamsadr.com</a
        ></span
      ><span class="c6 c0"
        >)&#47196;&#47564; &nbsp;&#51228;&#52636;&#46104;&#50612; &#50577;&#52769;&#50640;&#49436;
        &#49436;&#47196; &#46041;&#51032;&#54620; &#54620; &#47749;&#51032; &#51473;&#51116;&#51088;
        &#50526;&#50640;&#49436; &#51473;&#51116;&#46112; &#44163;&#51077;&#45768;&#45796;.
        &#51473;&#51116;&#51088;&#45716; &#44480;&#54616; &#46608;&#45716; &#45817;&#49324;&#44032;
        &#45824;&#47732; &#44277;&#52397;&#54924;&#44032; &#51201;&#51208;&#54616;&#45796;&#44256;
        &#50836;&#44396;&#54616;&#44256; &#51473;&#51116;&#51088;&#44032; &#51060;&#47484;
        &#51064;&#51221;&#54616;&#45716; &#44221;&#50864;&#44032; &#50500;&#45768;&#47732;, &#45824;&#47732;
        &#52636;&#49437;&#48372;&#45796;&#45716; &#51204;&#54868; &#46608;&#45716; &#50689;&#49345;
        &#54924;&#51032;&#47484; &#53685;&#54620; &#44277;&#52397;&#54924;&#47484; &#50676;&#44172;
        &#46121;&#45768;&#45796;. &#47784;&#46304; &#45824;&#47732; &#52636;&#49437;&#51008;
        &#45817;&#49324;&#51088;&#46308;&#51032; &#51060;&#46041; &#48143; &#44592;&#53440; &#44288;&#47144;
        &#49345;&#54889;&#51012; &#44256;&#47140;&#54616;&#50668; &#50577;&#52769;&#50640;
        &#54633;&#47532;&#51201;&#51004;&#47196; &#54200;&#47532;&#54620; &#51109;&#49548;&#50640;&#49436;
        &#50676;&#47549;&#45768;&#45796;. &#50577;&#52769;&#51060; &#44536; &#51109;&#49548;&#50640;
        &#46041;&#51032;&#54624; &#49688; &#50630;&#51004;&#47732; JAMS &#46608;&#45716;
        &#51473;&#51116;&#51088;&#44032; &#44536; &#44208;&#51221;&#51012;
        &#45236;&#47549;&#45768;&#45796;.</span
      >
    </p>
    <p class="c11">
      <span class="c6 c0"
        >&#50612;&#46500; &#50672;&#48169;, &#51452;, &#46608;&#45716; &#51648;&#48169;
        &#48277;&#50896;&#51060;&#45208; &#44592;&#44288;&#51060; &#50500;&#45772;
        &#51473;&#51116;&#51088;&#44032; &#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840;
        &#51221;&#52293;&#51032; &#51204;&#52404; &#46608;&#45716; &#51068;&#48512;&#44032;
        &#47924;&#54952;&#51060;&#44144;&#45208; &#47924;&#54952;&#44032; &#46112; &#49688;
        &#51080;&#45796;&#45716; &#47784;&#46304; &#51452;&#51109;&#51012; &#54252;&#54632;&#54616;&#50668;
        &#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51032; &#54644;&#49437;,
        &#51201;&#50857;&#49457;, &#51665;&#54665;&#49457; &#46608;&#45716; &#51060;&#51032;
        &#51089;&#49457;&#51004;&#47196; &#51064;&#54644; &#46608;&#45716; &#51060;&#50640;
        &#44288;&#47144;&#54616;&#50668; &#48156;&#49373;&#54616;&#45716; &#47784;&#46304;
        &#48516;&#51137;&#51012; &#54644;&#44208;&#54624; &#48176;&#53440;&#51201; &#44428;&#54620;&#51012;
        &#44054;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c11"><span class="c6 c2 c1">&#48277;&#51032; &#49440;&#53469;</span></p>
    <p class="c11">
      <span class="c0"
        >&#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51008; &#45796;&#47480;
        &#48277;&#47456; &#50896;&#52825;&#51032; &#52649;&#46028;&#50640; &#50689;&#54693;&#51012;
        &#51452;&#51648; &#50506;&#44256; &#45684;&#50837;&#51452; &#48277;&#50640; &#46384;&#46972;
        &#51089;&#49457;&#46104;&#50632;&#51004;&#47728; &#51060;&#50640; &#46384;&#46972;
        &#54644;&#49437;&#46104;&#50612;&#50556; &#54633;&#45768;&#45796;.
        &#45817;&#49324;&#51088;&#46308;&#51008; &#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840;
        &#51221;&#52293;&#51060; &#51452;(State) &#49324;&#51060;&#51032; &#49345;&#50629;&#51012;
        &#54252;&#54632;&#54620; &#44144;&#47000;&#47484; &#51613;&#47749;&#54632;&#51012;
        &#51064;&#51221;&#54633;&#45768;&#45796;. &#54644;&#45817;&#54616;&#45716;
        &#49892;&#52404;&#48277;&#50640; &#44288;&#54620; &#50526; &#45800;&#46973;&#51032;
        &#51312;&#54637;&#50640;&#46020; &#48520;&#44396;&#54616;&#44256; &#51060; &#44060;&#51064;
        &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51032; &#51312;&#44148;&#50640; &#46384;&#46972;
        &#49688;&#54665;&#46104;&#45716; &#47784;&#46304; &#51473;&#51116;&#45716; &#50672;&#48169;
        &#51473;&#51116;&#48277;</span
      ><span class="c1">(9 U.S.C. &sect;&sect; 1-16)</span
      ><span class="c0">&#51012; &#46384;&#46972;&#50556; &#54633;&#45768;&#45796;</span
      ><span class="c1">.</span><span class="c6 c0">&nbsp; &nbsp; </span>
    </p>
    <p class="c11">
      <span class="c6 c2 c1"
        >&#52888;&#47532;&#54252;&#45768;&#50500; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840;
        &#44428;&#47532; &#48143; &ldquo;&#50948;&#52824; &#52628;&#51201; &#44552;&#51648;&rdquo;
        &#49888;&#54840;&#50640; &#45824;&#54620; &#45817;&#49324;&#51032; &#45824;&#51025;
        &#48169;&#48277;</span
      >
    </p>
    <p class="c11">
      <span class="c6 c0"
        >&#52888;&#47532;&#54252;&#45768;&#50500; &#48124;&#48277; &#51228;1798.83&#54637;&#51008;
        &#52888;&#47532;&#54252;&#45768;&#50500; &#51452;&#48124;&#51064; &#49324;&#51060;&#53944;
        &#48143;/&#46608;&#45716; &#47784;&#48148;&#51068; &#50545; &#48169;&#47928;&#51088;&#45716;
        &#45817;&#49324;&#44032; &#44060;&#51064; &#51221;&#48372;&#47484;
        &#51228;&#49340;&#51088;&#50640;&#44172; &#44277;&#44060;&#54616;&#50668; &#44536;&#46308;&#51032;
        &#47560;&#52992;&#54021; &#47785;&#51201;&#51004;&#47196; &#49324;&#50857;&#54616;&#46020;&#47197;
        &#54616;&#45716; &#44221;&#50864;&#50640; &#53945;&#51221; &#51221;&#48372;&#47484;
        &#50836;&#44396;&#54624; &#49688; &#51080;&#46020;&#47197; &#54728;&#50857;&#54616;&#44256;
        &#51080;&#49845;&#45768;&#45796;. &#45817;&#49324;&#45716; &#51228;&#49340;&#51088;&#51032;
        &#51649;&#51217;&#51201; &#47560;&#52992;&#54021; &#47785;&#51201;&#51004;&#47196;
        &#44480;&#54616;&#51032; &#44060;&#51064; &#51221;&#48372;&#47484; &#44277;&#50976;&#54616;&#51648;
        &#50506;&#49845;&#45768;&#45796;.
      </span>
    </p>
    <p class="c11">
      <span class="c6 c0"
        >&#54788;&#51116; &#45817;&#49324; &#49324;&#51060;&#53944;&#45716; &ldquo;&#50948;&#52824;
        &#52628;&#51201; &#44552;&#51648;&rdquo; &#47749;&#47161;&#51012; &#54252;&#54632;&#54624; &#49688;
        &#51080;&#45716; &#52628;&#51201; &#47700;&#52964;&#45768;&#51608;&#50640; &#44288;&#54644;
        &#51088;&#46041;&#54868;&#46108; &#48652;&#46972;&#50864;&#51200; &#49888;&#54840;&#47484;
        &#51064;&#51648;&#54616;&#51648; &#50506;&#44256; &#51080;&#49845;&#45768;&#45796;.</span
      >
    </p>
    <p class="c11"><span class="c6 c2 c1">&#50672;&#46973;&#52376; &#51221;&#48372;</span></p>
    <p class="c14"><span class="c5 c1"></span></p>
    <p class="c44">
      <span class="c6 c0"
        >&#49828;&#54028;&#53356;&#45716; &#51060; &#44060;&#51064; &#48372;&#54840; &#51221;&#52293; &#48143;
        &#45817;&#49324;&#51032; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#44288;&#47168;&#50640;
        &#45824;&#54620; &#44480;&#54616;&#51032; &#51656;&#47928;&#51060;&#45208;
        &#53076;&#47704;&#53944;&#47484; &#54872;&#50689;&#54633;&#45768;&#45796;. &#45796;&#51020;
        &#50672;&#46973;&#52376;&#47196; &#50672;&#46973;&#54616;&#49901;&#49884;&#50724;.</span
      >
    </p>
    <p class="c14"><span class="c5 c1"></span></p>
    <p class="c9"><span class="c5 c1">Spark Ideas, LLC</span></p>
    <p class="c31"><span class="c5 c1">1328 Ridge Road</span></p>
    <p class="c29"><span class="c5 c1">Syosset, New York 11791 </span></p>
    <p class="c29"><span class="c5 c1">United States of America</span></p>
    <p class="c18 c29"><span class="c5 c1"></span></p>
    <p class="c20">
      <span class="c0">&#51060;&#47700;&#51068; &#51452;&#49548;</span><span class="c5 c1">:</span>
    </p>
    <p class="c20"><span class="c19">privacy@spark-nyc.com</span><span class="c5 c1">&nbsp; </span></p>
    <p class="c9"><span class="c0">&#51204;&#54868;&#48264;&#54840;</span><span class="c5 c1">:</span></p>
    <p class="c54"><span class="c5 c1">(917) 991-0477</span></p>
    <p class="c11">
      <span class="c6 c2 c1"
        >&#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51032;
        &#48320;&#44221;</span
      >
    </p>
    <p class="c18 c23"><span class="c6 c13 c1"></span></p>
    <p class="c23">
      <span class="c6 c0"
        >&#49828;&#54028;&#53356;&#45716; &#54924;&#49324;&#50752; &#44256;&#44061;&#51032;
        &#54588;&#46300;&#48177;&#51012; &#48152;&#50689;&#54616;&#44592; &#50948;&#54644; &#51060;
        &#44060;&#51064; &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#51012; &#51333;&#51333;
        &#44060;&#51221;&#54633;&#45768;&#45796;. &#51060;&#47084;&#54620; &#44060;&#51221;&#51012;
        &#54616;&#45716; &#44221;&#50864; &#45817;&#49324;&#45716; &#51060; &#44060;&#51064; &#51221;&#48372;
        &#48372;&#54840; &#51221;&#52293; &#50967;&#48512;&#48516;&#51032; &ldquo;&#50976;&#54952;&rdquo;
        &#45216;&#51676;&#46020; &#48320;&#44221;&#54624; &#44163;&#51077;&#45768;&#45796;. &#44060;&#51064;
        &#51221;&#48372; &#48372;&#54840; &#51221;&#52293;&#50640; &#51473;&#45824;&#54620; &#48320;&#44221;
        &#49324;&#54637;&#51060; &#51080;&#51004;&#47732; &#45817;&#49324;&#45716;
        &#49324;&#51060;&#53944;&#50752; &#47784;&#48148;&#51068; &#50545;&#50640; &#45576;&#50640; &#51096;
        &#46916;&#45716; &#50504;&#45236;&#47928;&#51012; &#44172;&#49884;&#54616;&#50668; &#51060;&#47484;
        &#50508;&#47540; &#44163;&#51077;&#45768;&#45796;. &#45817;&#49324;&#45716; &#44480;&#54616;&#44032;
        &#49828;&#54028;&#53356;&#50640;&#49436; &#44480;&#54616;&#51032; &#51221;&#48372;&#47484;
        &#52376;&#47532;&#54616;&#45716; &#48169;&#48277;&#50640; &#45824;&#54644; &#51096; &#50508; &#49688;
        &#51080;&#46020;&#47197; &#51060; &#44060;&#51064; &#51221;&#48372; &#48372;&#54840;
        &#51221;&#52293;&#51012; &#51452;&#44592;&#51201;&#51004;&#47196; &#44160;&#53664;&#54624;
        &#44163;&#51012; &#44428;&#51109;&#54633;&#45768;&#45796;. &nbsp;</span
      >
    </p>
    <div>
      <p class="c20 c18"><span class="c1 c30"></span></p>
    </div>
  </LLWordTextContainer>
</template>

<script>
import LLWordTextContainer from '@/components/common/LLWordTextContainer'
export default {
  name: 'PrivacyPolicyKO',
  components: { LLWordTextContainer }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyKO {
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_6-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_6-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_6-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_2-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_7-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_4-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-2 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_1-4 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-7 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-5 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_1-6 > li:before {
    content: '\0025cf  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_1-8 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_2-3 > li:before {
    content: '\0025cf  ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c24 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 296.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c7 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 170.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c23 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 7.2pt;
  }
  .c14 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c34 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c16 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c22 {
    margin-left: 36pt;
    padding-top: 13.6pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 3.6pt;
  }
  .c31 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c54 {
    padding-top: 0.3pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c21 {
    padding-top: 12.9pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c49 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c10 {
    padding-top: 12.8pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c47 {
    padding-top: 12.9pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c5 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c45 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c44 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c33 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;

    font-style: normal;
  }
  .c9 {
    padding-top: 13.3pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c11 {
    padding-top: 13.8pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c29 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c12 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c28 {
    padding-top: 13.6pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c52 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c30 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c20 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c27 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c19 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c6 {
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c35 {
    text-decoration: none;
    vertical-align: baseline;

    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
  }
  .c42 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal;
  }
  .c51 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c32 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 53.2pt 72pt;
  }
  .c39 {
    font-weight: 400;
  }
  .c13 {
    font-weight: 700;
  }
  .c37 {
    color: inherit;
    text-decoration: inherit;
  }
  .c2 {
    font-weight: 700;
  }
  .c4 {
    font-weight: 700;
  }
  .c3 {
    font-weight: 400;
  }
  .c25 {
    padding: 0;
    margin: 0;
  }
  .c17 {
    padding-left: 0pt;
  }
  .c26 {
    height: 90pt;
  }
  .c36 {
    margin-left: 36pt;
  }
  .c43 {
    page-break-after: avoid;
  }
  .c15 {
    height: 15pt;
  }
  .c18 {
    height: 11pt;
  }
  .c46 {
    height: 45pt;
  }
  .c40 {
    height: 105pt;
  }
  .c53 {
    height: 135pt;
  }
  .c1 {
    color: #000000;
  }
  .c50 {
    background-color: #ffff00;
  }
  .c41 {
    font-weight: 700;
  }
  .c48 {
    color: #ff0000;
  }
  .c38 {
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;

    padding-bottom: 4pt;

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
  }
  p {
    margin: 0;
    color: #000000;
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 6pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 4pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;

    padding-bottom: 2pt;

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
</style>
