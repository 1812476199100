<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="text-input"
    :class="{
      'is-underlined': underlined,
      'is-search': search,
      'is-title': isTitle,
      'with-prefix': $slots.prefix,
      'with-suffix': $slots.suffix
    }"
    tag="div"
    :rules="rules"
    :name="name"
  >
    <label v-if="caption" class="text-input__caption" @click.prevent="onCaptionClick">
      {{ caption }}
    </label>

    <div class="text-input__control">
      <div v-if="$slots.prefix" class="text-input__prefix">
        <slot name="prefix" />
      </div>

      <input
        ref="input"
        v-model="innerValue"
        class="form-control"
        :class="{ 'has-errors': errors[0] }"
        :name="name"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        @change="onChange"
        @keypress="onKeypress"
      />

      <div v-if="$slots.suffix" class="text-input__suffix">
        <slot name="suffix" />
      </div>
    </div>

    <div v-if="errors[0]" class="text-error-01 text-xs mt-2">
      {{ errors[0] }}
    </div>
    <div v-else-if="showOptionalLabel" class="text-neutral-01 text-xs mt-2">
      {{ $t('main_optional') }}
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'LLTextInput',
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['url', 'text', 'password', 'tel', 'search', 'number', 'email', 'hidden'].includes(value)
      }
    },
    caption: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    underlined: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showOptionalLabel: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    onCaptionClick() {
      this.focus()
    },
    focus() {
      this.$refs.input.focus()
    },
    onChange() {
      this.$emit('change', this.innerValue)
    },
    onKeypress() {
      this.$emit('keypress', this.innerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-input {
  &__caption {
    @apply inline-block mb-1 font-semibold;
  }

  &__control {
    @apply relative;
  }

  &__prefix,
  &__suffix {
    @apply absolute inset-y-0 px-3 flex items-center text-base;
    /deep/ .icon {
      width: 1em;
      height: 1em;
    }
  }
  &__prefix {
    @apply left-0;
  }
  &__suffix {
    @apply right-0;
  }

  .form-control {
    @apply appearance-none block w-full border border-neutral-01-50 rounded px-4 py-3;
    line-height: 1.15;
    &:focus {
      @apply outline-none;
    }
    &::placeholder {
      @apply text-neutral-01;
    }
    &.has-errors {
      @apply border-error-01;
    }
  }

  &.with-prefix {
    .form-control {
      @apply pl-10;
    }
  }
  &.with-suffix {
    .form-control {
      @apply pr-10;
    }
  }

  &.is-underlined {
    .form-control {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.is-title {
    .form-control {
      font-family: var(--font-second);
      font-size: 32px;
      font-weight: 700;
    }
  }
  &.is-search {
    .form-control {
      @apply text-sm py-1;
    }
  }
}
</style>
