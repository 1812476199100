<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M12.1866 8.61597L17.7357 14.1201C17.9119 14.2963 17.9119 14.5813 17.7357 14.7575L16.9933 15.4999C16.8171 15.6761 16.5322 15.6761 16.3559 15.4999L11.8679 11.0568L7.37981 15.4999C7.20359 15.6761 6.91863 15.6761 6.74241 15.4999L6.00003 14.7575C5.8238 14.5813 5.8238 14.2963 6.00003 14.1201L11.5492 8.61597C11.7254 8.43975 12.0103 8.43975 12.1866 8.61597Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1.5C5.92487 1.5 1 6.42487 1 12.5C1 18.5751 5.92487 23.5 12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5ZM12 0.5C5.37258 0.499999 6.74786e-07 5.87258 9.53991e-08 12.5C-4.83988e-07 19.1274 5.37258 24.5 12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CircleArrowUpIcon'
}
</script>
