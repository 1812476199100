<template>
  <button class="LLChevron" :class="classes" @click="onClick">
    <div v-if="arrowPlacement === 'left'" class="LLChevron__arrow-wrapper LLChevron__arrow-wrapper_left">
      <slot name="arrow">
        <ArrowMiniIcon class="LLChevron__arrow"></ArrowMiniIcon>
      </slot>
    </div>
    <div class="LLChevron__content">
      <slot></slot>
    </div>
    <div v-if="arrowPlacement === 'right'" class="LLChevron__arrow-wrapper LLChevron__arrow-wrapper_right">
      <slot name="arrow">
        <ArrowMiniIcon class="LLChevron__arrow"></ArrowMiniIcon>
      </slot>
    </div>
  </button>
</template>

<script>
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon'
export default {
  name: 'LLChevron',
  components: { ArrowMiniIcon },

  props: {
    opened: { type: Boolean, default: false },
    arrowPlacement: { type: String, default: 'right' },
    /**
     * String defining the chevron rotation behavior.
     * Possible values:
     * - 'default': Chevron moves from up to down.
     * - 'to-left': Chevron moves from left to down.
     * @type {String}
     * @default 'default'
     */
    rotateBehavour: { type: String, default: 'default' }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLChevron'
      if (this.opened) {
        classes.push(`${rootClass}_opened`)
      }
      if (this.rotateBehavour === 'to-left') {
        classes.push(`${rootClass}_to-left`)
      }
      return classes
    }
  },
  methods: {
    open() {
      this.$emit('update:opened', true)
    },
    close() {
      this.$emit('update:opened', false)
    },
    onClick() {
      if (this.opened) {
        this.close()
      } else {
        this.open()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLChevron {
  $root: &;
  @apply flex min-w-0 items-center cursor-pointer select-none pr-0.5;
  &__arrow-wrapper {
    @apply transition duration-200;
    transform: rotate(90deg);
    #{$root}_to-left & {
      transform: rotate(0deg);
    }
    &_left {
      @apply mr-2;
    }
    &_right {
      @apply ml-2;
    }
  }
  &_opened {
    &#{$root}_to-left {
      #{$root}__arrow-wrapper {
        transform: rotate(90deg);
      }
    }
    #{$root}__arrow-wrapper {
      transform: rotate(270deg);
    }
  }
}
</style>
