<template>
  <div class="ForumResponseEditor">
    <span
      v-if="!localResponse.id && !localResponse.parentResponseId"
      class="ForumResponseEditor__new-title"
      >{{ $t('forum.response.write_a_reply') }}</span
    >
    <ValidationObserver ref="validation-observer">
      <ValidationProvider name="messageText" rules="required" immediate>
        <LLTextarea
          ref="textarea"
          v-model="localResponse.text"
          :placeholder="$t('type_here')"
          dynamic-size
          size="compact"
          :max-height="300"
        >
          <template v-if="localResponse.files.length" #bottom-content>
            <LLFileSlider
              class="ForumResponseEditor__files"
              :files.sync="localResponse.files"
              small
              editable
              cropper
              draggable
            ></LLFileSlider>
          </template>
        </LLTextarea>
      </ValidationProvider>
    </ValidationObserver>
    <div class="ForumResponseEditor__file-uploader-wrapper">
      <LLFileUploader
        class="ForumResponseEditor__file-uploader"
        @startUpload="onStartUpload"
        @upload="onUpload"
      ></LLFileUploader>
      <LLUploadProgress
        class="ForumResponseEditor__file-uploader-progress"
        :uploading-files-uuids.sync="uploadingFilesUuids"
      ></LLUploadProgress>
    </div>
    <div class="ForumResponseEditor__footer">
      <div class="ForumResponseEditor__anonymous-wrapper">
        <LLCheckbox v-model="localResponse.isAnonymous">
          <span class="ForumResponseEditor__anonymous-label">{{
            $t('forum.response.anonymous_reply_checkbox')
          }}</span>
        </LLCheckbox>
      </div>
      <div class="ForumResponseEditor__actions">
        <LLButton
          v-if="!localResponse.id"
          ref="send-button"
          :loading="isSaving"
          class="ForumResponseEditor__action"
          small
          primary
          :disabled="!isSendButtonActive"
          @click="create"
        >
          {{ $t('button_send') }}
        </LLButton>
        <LLButton
          v-if="localResponse.id || localResponse.parentResponseId"
          :disabled="isSaving"
          class="ForumResponseEditor__action"
          small
          secondary
          @click="cancel"
        >
          {{ $t('button_cancel') }}
        </LLButton>
        <LLButton
          v-if="localResponse.id"
          ref="send-button"
          :loading="isSaving"
          class="ForumResponseEditor__action"
          small
          :disabled="!isSendButtonActive"
          primary
          @click="update"
        >
          {{ $t('button_save') }}
        </LLButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLTextarea from '@/components/common/LLTextarea.vue'
import LLFileUploader from '@/components/common/LLFileUploader/LLFileUploader.vue'
import LLUploadProgress from '@/components/common/LLUploadProgress.vue'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import scrollToForumReplyHelper from '@/utils/modules/forum/scrollToForumReplyHelper'

export default {
  name: 'ForumResponseEditor',
  components: {
    LLCheckbox,
    LLUploadProgress,
    ValidationProvider,
    ValidationObserver,
    LLFileUploader,
    LLTextarea,
    LLButton,
    LLFileSlider
  },
  props: {
    response: { type: ForumResponseModel, default: null }
  },
  data() {
    return {
      localResponse: new ForumResponseModel(),
      isSaving: false,
      isValid: false,
      uploadingFilesUuids: []
    }
  },
  computed: {
    isSendButtonActive() {
      return this.isValid && !this.uploadingFilesUuids?.length
    }
  },
  watch: {
    localResponse: {
      deep: true,
      immediate: true,
      async handler() {
        this.isValid = await this.validate()
      }
    },
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new ForumResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.isValid = await this.validate()
    })
  },
  methods: {
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver) {
        const validation = await validationObserver.validateWithInfo({ silent: true })
        return validation.flags.valid || !!this.localResponse?.files?.length
      }
      return false
    },
    onStartUpload(uploadingFilesUuids) {
      this.uploadingFilesUuids = uploadingFilesUuids
    },
    onUpload(files) {
      this.localResponse.files = [...this.localResponse.files, ...files]
    },
    async create() {
      this.isSaving = true
      try {
        const { chatId, threadId } = this.localResponse
        const { response: serverResponse, user } = await this.$api.forum.responses.create({
          chatId,
          threadId,
          response: this.localResponse.convertToApi()
        })
        const response = ForumResponseModel.parseFromApi({ response: serverResponse, chatId, threadId, user })
        this.$emit('update', { response })
        scrollToForumReplyHelper.setResponsePath({ responsePath: [response.id] })
      } catch {
      } finally {
        this.isSaving = false
      }
    },
    async update() {
      this.isSaving = true
      try {
        const { chatId, threadId } = this.localResponse
        const { response: serverResponse, user } = await this.$api.forum.responses.update({
          chatId,
          threadId,
          response: this.localResponse.convertToApi()
        })
        const response = ForumResponseModel.parseFromApi({ response: serverResponse, chatId, threadId, user })
        this.$emit('update', { response })
        this.cancel()
      } catch {
      } finally {
        this.isSaving = false
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    insertText({ text }) {
      if (this.$refs.textarea) {
        this.$refs.textarea.insertText(text)
      }
    },
    insertMention({ prospectId, userId, fullName }) {
      if (this.$refs.textarea) {
        this.$refs.textarea.insertMention({ prospectId, userId, fullName })
      }
    },
    focus() {
      this.$nextTick(() => {
        if (this.$refs.textarea) {
          this.$refs.textarea.focus()
        }
        /*this.$nextTick(() => {
          const sendButton = this.$refs['send-button']?.$el
          if (sendButton) {
            sendButton.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            })
          }
        })*/
      })
    },
    remove() {}
  }
}
</script>

<style scoped lang="scss">
.ForumResponseEditor {
  &__new-title {
    @apply mb-4 text-primary-01-400 text-button-01 block;
  }
  &__footer {
    @apply w-full flex justify-between flex-wrap;
  }
  &__anonymous-label {
    @apply text-caption-01 text-neutral-01-400;
  }
  &__file-uploader-wrapper {
    @apply flex flex-col justify-between mt-4;
    @screen md {
      @apply flex-row;
    }
  }
  &__file-uploader-progress {
    @apply mt-4;
    @screen md {
      @apply mt-0;
    }
  }
  &__anonymous-wrapper {
    @apply mt-4 mr-4;
  }
  &__actions {
    @apply flex mt-4;
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
  }
  &__files {
    @apply mt-2;
  }
}
</style>
