<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.2289 2H3.14286C1.68769 2 0.5 3.18769 0.5 4.64286V11.4429C0.5 12.9025 1.68325 14.0857 3.14286 14.0857H8.68148L11.3907 16.795C11.5909 16.9952 11.892 17.0551 12.1536 16.9467C12.4152 16.8384 12.5857 16.5831 12.5857 16.3V14.0857H12.8571C14.3168 14.0857 15.5 12.9025 15.5 11.4429V8.2711C15.0568 8.41031 14.5869 8.48947 14.1 8.49902V11.4429C14.1 12.1293 13.5436 12.6857 12.8571 12.6857H11.8857C11.4991 12.6857 11.1857 12.9991 11.1857 13.3857V14.6101L9.4664 12.8907C9.33513 12.7595 9.15708 12.6857 8.97143 12.6857H3.14286C2.45645 12.6857 1.9 12.1293 1.9 11.4429V4.64286C1.9 3.96088 2.46088 3.4 3.14286 3.4H9.00098C9.01053 2.91309 9.08968 2.44325 9.2289 2ZM11.4013 2C11.1617 2.41424 11.0183 2.89109 11.0016 3.4H12.8571C13.5436 3.4 14.1 3.95645 14.1 4.64286V6.49836C14.6089 6.4817 15.0858 6.33828 15.5 6.09865V4.64286C15.5 3.18325 14.3168 2 12.8571 2H11.4013Z"
      fill="#7990A2"
    />
    <circle cx="14" cy="3.5" r="3" fill="#FF6666" />
  </svg>
</template>

<script>
export default {
  name: 'NewMessageIcon'
}
</script>

<style lang="scss" scoped>
.NewMessageIcon {
}
</style>
