export default {
  namespaced: true,
  state: {
    locale: 'en',
    cookieAgreement: false
  },
  getters: {
    cookieAgreement(state) {
      return state.cookieAgreement
    }
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale
    },
    SET_COOKIE_AGREEMENT(state, data) {
      state.cookieAgreement = data
    }
  },
  actions: {}
}
