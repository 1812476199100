import { FileModel } from '@/models/file'
import { UserModel } from '@/models/user'
import { ProspectModel } from '@/models/prospect'
import { ForumThreadState } from '@/common/enums'
import { ForumReactionModel } from '@/models/forum/forumReaction'
import { ForumPollModel } from '@/models/forum/forumPoll'

export class ForumThreadModel {
  constructor(threadData = {}) {
    const {
      id,
      chatId,
      title,
      text,
      files,
      state,
      meta,
      createdAt,
      archivedAt,
      hiddenAt,
      user,
      prospect,
      themes,
      updatedAt,
      reactions,
      isAnonymous,
      anonymousState,
      poll,
      isPinned,
      isNew,
      hasPoll,
      isOwnerModerator
    } = threadData || {}

    const { responseCount, newResponseCount, pollResponseCount } = meta || {}

    this.id = id || null
    this.title = title || ''
    this.chatId = chatId || null
    this.text = text || ''
    this.state = state || 10
    this.files = Array.isArray(files) ? files.map((file) => new FileModel(file)) : []
    this.meta = {
      responseCount: responseCount || 0,
      newResponseCount: newResponseCount || 0,
      pollResponseCount: pollResponseCount || 0
    }
    this.createdAt = createdAt || null
    this.archivedAt = archivedAt || null
    this.hiddenAt = hiddenAt || null
    this.updatedAt = updatedAt || null
    this.user = user ? new UserModel(user) : null
    this.prospect = prospect ? new ProspectModel(prospect) : null
    this.themes = Array.isArray(themes) ? [...themes] : []
    this.reactions = reactions?.map((reaction) => new ForumReactionModel(reaction)) || []
    this.isAnonymous = !!isAnonymous
    this.anonymousState = anonymousState || false
    this.poll = poll ? new ForumPollModel(poll) : null
    this.isPinned = isPinned || false
    this.isNew = isNew || false
    this.isOwnerModerator = isOwnerModerator ?? false
    this.hasPoll = hasPoll || false
  }

  static parseFromApi({ thread, chatId }) {
    return new ForumThreadModel({
      id: thread?.id,
      title: thread?.title,
      text: thread?.text,
      state: thread?.state,
      chatId,
      meta: {
        responseCount: thread?.meta?.responseCount,
        newResponseCount: thread?.meta?.newResponseCount,
        pollResponseCount: thread?.meta?.pollResponseCount
      },
      files:
        thread?.files?.map((file) => FileModel.parseFromApi({ file }))?.sort((a, b) => a?.order - b?.order) ||
        [],
      createdAt: thread?.createdAt,
      updatedAt: thread?.updatedAt,
      hiddenAt: thread?.hiddenAt,
      archivedAt: thread?.archivedAt,
      user: thread?.user ? UserModel.parseFromApi({ user: thread.user }) : null,
      prospect: thread?.prospect ? ProspectModel.parseFromApi({ prospect: thread.prospect }) : null,
      themes: Array.isArray(thread?.themes) ? [...thread.themes] : [],
      reactions: thread?.reactions?.map((reaction) => ForumReactionModel.parseFromApi({ reaction })) || [],
      isAnonymous: thread?.isAnonymous,
      anonymousState: thread?.anonymousState,
      poll: thread?.poll
        ? ForumPollModel.parseFromApi({ poll: thread?.poll, threadId: thread?.id, chatId })
        : null,
      isPinned: thread?.isPinned,
      isNew: thread?.isNew,
      hasPoll: thread?.hasPoll,
      isOwnerModerator: thread?.isOwnerModerator
    })
  }

  setMetaFromApi({ meta }) {
    this.meta = {
      responseCount: meta?.responseCount,
      newResponseCount: meta?.newResponseCount,
      pollResponseCount: meta?.pollResponseCount
    }
  }

  convertToApi() {
    const { id, title, text, themes, isAnonymous, isPinned } = this
    return {
      id,
      title,
      text,
      fileIdList: this.files?.map((file) => file.id) || undefined,
      themes: [...themes],
      isAnonymous,
      poll: this.poll?.convertToApi ? this.poll.convertToApi() : null,
      isPinned
    }
  }

  get isActive() {
    return this.state === ForumThreadState.ACTIVE
  }

  get isArchived() {
    return this.state === ForumThreadState.ARCHIVED
  }

  get isHidden() {
    return this.state === ForumThreadState.HIDDEN
  }
}
