<template>
  <div class="StudyConversationMessagePoll">
    <PollMultipleEditor
      v-if="question.poll.type === PollTypeEnum.MULTIPLE_CHOICE"
      :response.sync="localResponse"
      :poll="question.poll"
      :locked="locked"
      @update:isValid="onIsValidUpdate"
    />
    <PollMatrixEditor
      v-else-if="question.poll.type === PollTypeEnum.MATRIX"
      v-model="localResponse.matrixList"
      :response.sync="localResponse"
      :poll="question.poll"
      :mobile="isMobileLayout || isCompactLayout"
      :is-first-time-fill="!message.id"
      :locked="locked"
      @update:isValid="onIsValidUpdate"
    />
    <PollRankedEditor
      v-else-if="question.poll.type === PollTypeEnum.RANKED_LIST"
      ref="ranked-poll"
      :response.sync="localResponse"
      :poll="question.poll"
      :real-validation="options.type === StudyConversationMessageType.SCREENING"
      :locked="locked"
      @update:isValid="onIsValidUpdate"
      @confirm-send="onRankedConfirm"
    />
    <PollNumberEditor
      v-else-if="question.poll.type === PollTypeEnum.NUMBER_ENTRY"
      :response.sync="localResponse"
      :poll="question.poll"
      :is-first-time-fill="!message.id"
      :locked="locked"
      @update:isValid="onIsValidUpdate"
    />
  </div>
</template>

<script>
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import PollMultipleEditor from '@/components/modules/poll/multiple/PollMultipleEditor.vue'
import { PollResponseModel } from '@/models/pollResponse'
import { QuestionModel } from '@/models/question'
import { PollType } from '@/common/enums'
import PollMatrixEditor from '@/components/modules/poll/matrix/PollMatrixEditor.vue'
import PollRankedEditor from '@/components/modules/poll/ranked/PollRankedEditor.vue'
import PollNumberEditor from '@/components/modules/poll/number/PollNumberEditor.vue'

export default {
  name: 'StudyConversationMessagePoll',
  components: { PollNumberEditor, PollRankedEditor, PollMatrixEditor, PollMultipleEditor },
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, default: null },
    locked: { type: Boolean, default: false },
    question: { type: QuestionModel, required: true }
  },
  data() {
    return {
      isValid: false,
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    StudyConversationMessageType() {
      return StudyConversationMessageType
    },
    response() {
      return this.message?.pollResponse
    },
    PollTypeEnum() {
      return PollType
    }
  },
  watch: {
    response: {
      handler() {
        if (
          !this.$_.isEqual(this.response, this.localResponse) &&
          !(this.response === null && this.localResponse.isEmpty)
        ) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit(
          'update:message',
          new StudyConversationMessageModel({
            ...this.message,
            pollResponse: this.localResponse ? new PollResponseModel(this.localResponse) : null
          })
        )
      },
      deep: true
    },
    isValid: {
      handler() {
        this.$emit('update:isValid', this.isValid)
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    onRankedConfirm() {
      this.$emit('rankedConfirm')
    },
    sendRanked() {
      this.$refs['ranked-poll'].send()
    },
    onIsValidUpdate(isValid) {
      this.isValid = isValid
      this.$emit('update:isValid', isValid)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessagePoll {
}
</style>
