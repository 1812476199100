<template>
  <div class="PollResultsFilterSimple">
    <div class="PollResultsFilterSimple__list">
      <LLRadio
        v-for="option in filterOptions"
        :key="option.value"
        v-model="localFilterType"
        class="PollResultsFilterSimple__item"
        :value="option.value"
        >{{ $t(option.nameId) }}</LLRadio
      >
    </div>
  </div>
</template>

<script>
import LLRadio from '@/components/common/LLRadio.vue'

export default {
  name: 'PollResultsFilterSimple',
  components: { LLRadio },
  props: {
    filterType: { type: String, default: null }
  },
  data() {
    return {
      localFilterType: null
    }
  },
  computed: {
    filterOptions() {
      return [
        {
          nameId: 'user_details_age',
          value: 'age'
        },
        {
          nameId: 'user_details_gender',
          value: 'gender'
        },
        {
          nameId: 'user_details_location',
          value: 'location'
        },
        {
          nameId: 'filter_ethnicity',
          value: 'ethnicity'
        }
      ]
    }
  },
  watch: {
    filterType: {
      handler() {
        this.localFilterType = this.filterType
        this.allSubFiltersSelected = true
      },
      immediate: true
    },
    localFilterType: {
      handler() {
        this.$emit('update:filterType', this.localFilterType)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.PollResultsFilterSimple {
  @apply p-6 flex flex-col;
  &__item {
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
}
</style>
