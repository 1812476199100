<template>
  <div class="StudyProgress" :class="{ 'w-full': progressLine }">
    <div class="mt-2" :class="{ 'w-full': progressLine }">
      <div
        class="flex mt-2 items-center justify-between pb-2 border-neutral-01-50 border-b"
        :class="{ 'w-full': progressLine }"
      >
        <div class="flex items-center">
          <NotStartedIcon class="text-success-01 w-6 h-6"></NotStartedIcon>
          <div class="text-xs text-neutral-01 ml-2">{{ $t('not_started') }}</div>
        </div>
        <div class="font-semibold" data-e2e="notStartedPercents">{{ notStartedPercents }}%</div>
      </div>
    </div>
    <div :class="{ 'w-full': progressLine }">
      <div
        class="flex mt-2 items-center justify-between pb-2 border-neutral-01-50 border-b"
        :class="{ 'w-full': progressLine }"
      >
        <div class="flex items-center">
          <AnsweredIcon class="text-answered-01 w-6 h-6"></AnsweredIcon>
          <div class="text-xs text-neutral-01 ml-2">{{ $t('answered') }}</div>
        </div>
        <div class="font-semibold" data-e2e="answeredPercents">{{ answeredPercents }}%</div>
      </div>
    </div>
    <div class="mt-2" :class="{ 'w-full': progressLine }">
      <div
        class="flex mt-2 items-center justify-between pb-2 border-neutral-01-50"
        :class="{ 'w-full': progressLine }"
      >
        <div class="flex items-center">
          <CompletedIcon class="text-success-01 w-6 h-6"></CompletedIcon>
          <div class="text-xs text-neutral-01 ml-2">{{ $t('completed') }}</div>
        </div>
        <div class="font-semibold" data-e2e="completedPercents">{{ completedPercents }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import NotStartedIcon from '@/assets/icons/NotStartedIcon'
export default {
  name: 'StudyProgress',
  components: { NotStartedIcon },
  props: {
    percentsAlready: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Number,
      default: 0
    },
    answered: {
      type: Number,
      default: 0
    },
    notStarted: {
      type: Number,
      default: 0
    },
    all: {
      type: Number,
      default: 0
    },
    progressLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    answeredPercents() {
      return this.percentsAlready
        ? this.answered
        : this.all
          ? Math.ceil(((this.answered + this.completed) / this.all) * 100)
          : 0
    },
    completedPercents() {
      return this.percentsAlready
        ? this.completed
        : this.all
          ? Math.ceil((this.completed / this.all) * 100)
          : 0
    },
    notStartedPercents() {
      return this.percentsAlready
        ? this.notStarted
        : this.all
          ? Math.floor((this.notStarted / this.all) * 100)
          : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.StudyProgress {
}
</style>
