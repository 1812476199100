<template>
  <div class="ForumChatThreadCardHeaderProfile" :class="classList">
    <div class="ForumChatThreadCardHeaderProfile__image">
      <ForumChatThreadCardHeaderAvatar :thread="thread" />
    </div>
    <div class="ForumChatThreadCardHeaderProfile__text">
      <div class="ForumChatThreadCardHeaderProfile__common">
        <div v-if="isOwnerVisible" class="ForumChatThreadCardHeaderProfile__user-name">
          {{ name }}
        </div>
        <div v-else-if="isOwnerHidden" class="ForumChatThreadCardHeaderProfile__anonymous-name">
          {{ $t('forum.response.anonymous_reply_user_name') }}
        </div>
        <div v-else-if="isOwnerModerator" class="ForumChatThreadCardHeaderProfile__moderator-name">
          {{ $t('forum.response.moderator_reply_user_name') }}
        </div>
      </div>
      <div v-if="isNoticeVisible" class="ForumChatThreadCardHeaderProfile__notice">
        <template v-if="isModeratorNoticeVIsible">
          {{ $t('forum.response.moderator_reply_label') }}
        </template>
        <template v-else-if="isAnonymousNoticeVIsible">
          {{ $t('forum.response.anonymous_reply_label') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import ForumChatThreadCardHeaderAvatar from '@/components/modules/forum-chat/new-thread-card/header/ForumChatThreadCardHeaderAvatar.vue'

export default {
  name: 'ForumChatThreadCardHeaderProfile',
  components: { ForumChatThreadCardHeaderAvatar },
  props: {
    thread: { type: ForumThreadModel, required: true },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    dark: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_dark`]: this.dark
      }
    },
    isNoticeVisible() {
      return this.isAnonymousNoticeVIsible || this.isModeratorNoticeVIsible
    },
    isModeratorNoticeVIsible() {
      return this.thread.anonymousState && this.thread.user
    },
    isAnonymousNoticeVIsible() {
      return this.thread.anonymousState && this.thread.prospect
    },
    isOwnerVisible() {
      return !this.isOwnerHidden && !this.isOwnerModerator
    },
    isOwnerHidden() {
      return (
        (this.thread.anonymousState || this.hasNoOwner) &&
        this.thread.prospect?.id !== this.$store.getters['user/user']?.id &&
        !this.thread.isOwnerModerator
      )
    },
    isOwnerModerator() {
      return (
        (this.thread.anonymousState || this.hasNoOwner) &&
        this.thread.prospect?.id !== this.$store.getters['user/user']?.id &&
        this.thread.isOwnerModerator
      )
    },
    prospect() {
      return this.thread?.prospect
    },
    user() {
      return this.thread?.user
    },
    name() {
      return (
        (this.thread?.prospect
          ? this.thread?.prospect?.fullNameWithShortLastName
          : this.thread?.user?.fullNameWithShortLastName) || this.$t('not-available')
      )
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeaderProfile {
  $root: &;
  @apply flex items-center min-w-0;
  &__common {
    @apply text-caption-01 truncate;
  }
  &__text {
    @apply flex items-center ml-1 min-w-0;
    @at-root #{$root}_dark & {
      @apply text-white;
    }
  }
  &__user-name,
  &__anonymous-name {
    @apply text-primary-01-400 truncate;
    @at-root #{$root}_dark & {
      @apply text-white;
    }
  }
  &__moderator-name {
    @apply text-accent-01;
    @at-root #{$root}_dark & {
      @apply text-white;
    }
  }
  &__notice {
    @apply text-neutral-01-400 text-xs italic ml-1 flex-shrink-0;
  }
  &__image {
    @apply h-4 w-4 rounded-full flex-shrink-0;
  }
}
</style>
