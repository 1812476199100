<template>
  <svg class="icon" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="fill-current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2072 1.70718L4.00008 7.91429L0.292969 4.20718L1.70718 2.79297L4.00008 5.08586L8.79297 0.292969L10.2072 1.70718Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckedIcon'
}
</script>
