let newReplyToWatch = null
let newReplyProspect = null
let newReplyUser = null

let responsePath = null

const listeners = []

export default {
  setResponsePath({ responsePath: path }) {
    responsePath = [...path]
    listeners.forEach((listener) => listener())
  },
  get responseToWatch() {
    return responsePath?.[0] || null
  },
  isWatchingResponse({ responseId }) {
    return this.responseToWatch === responseId
  },
  get hasChild() {
    return !!responsePath?.[1]
  },
  removeResponse({ responseId }) {
    if (responseId === this.responseToWatch) {
      responsePath.splice(0, 1)
    }
    if (responsePath?.length === 0) {
      responsePath = null
    }
  },
  setNewReplyToWatch({ newReplyId, prospect, user }) {
    newReplyToWatch = newReplyId
    newReplyProspect = prospect
    newReplyUser = user
    listeners.forEach((listener) => listener())
  },
  isWatchingNewReply({ newReplyId }) {
    return newReplyToWatch === newReplyId
  },
  newReplyProspect() {
    return newReplyProspect
  },
  newReplyUser() {
    return newReplyUser
  },
  removeAll() {
    responsePath = null
    newReplyToWatch = null
    newReplyProspect = null
    newReplyUser = null
  },
  get newReplyToWatch() {
    return newReplyToWatch
  },
  addListener({ callback }) {
    listeners.push(callback)
  },
  removeListener({ callback }) {
    listeners.splice(listeners.indexOf(callback), 1)
  }
}
