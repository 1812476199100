<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" class="fill-current" />
    <path
      d="M16.2778 11.2219H12.7778V7.7219C12.7778 7.50704 12.6038 7.33301 12.3889 7.33301H11.6112C11.3963 7.33301 11.2223 7.50704 11.2223 7.7219V11.2219H7.72226C7.5074 11.2219 7.33337 11.3959 7.33337 11.6108V12.3886C7.33337 12.6034 7.5074 12.7775 7.72226 12.7775H11.2223V16.2775C11.2223 16.4923 11.3963 16.6663 11.6112 16.6663H12.3889C12.6038 16.6663 12.7778 16.4923 12.7778 16.2775V12.7775H16.2778C16.4927 12.7775 16.6667 12.6034 16.6667 12.3886V11.6108C16.6667 11.3959 16.4927 11.2219 16.2778 11.2219Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IncrementRoundIcon'
}
</script>

<style lang="scss" scoped>
.IncrementRoundIcon {
}
</style>
