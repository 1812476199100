<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7L4 4L1 1" stroke="#7990A2" />
    <path d="M4 7L7 4L4 1" stroke="#7990A2" />
  </svg>
</template>

<script>
export default {
  name: 'BreadCrumbArrowIcon'
}
</script>

<style lang="scss" scoped>
.BreadCrumbArrowIcon {
}
</style>
