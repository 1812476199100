<template>
  <div
    class="LLRoundProgress relative flex-shrink-0"
    :class="{ small: small, medium: medium, large: large, micro: micro }"
  >
    <div class="round__container">
      <div class="round__wrapper border-neutral-01-50">
        <svg class="progress-ring outer-ring" :width="sizeOuter" :height="sizeOuter">
          <circle
            stroke="#9BB3C7"
            :stroke-width="baseStrokeWidth"
            fill="transparent"
            :r="sizeOuter / 2 - 4"
            :cx="sizeOuter / 2"
            :cy="sizeOuter / 2"
          />
          <circle
            id="circle1"
            ref="circle1"
            :style="{ stroke: mainColor }"
            class="progress-ring__circle"
            :stroke-width="strokeWidth"
            fill="transparent"
            :r="sizeOuter / 2 - 4"
            :cx="sizeOuter / 2"
            :cy="sizeOuter / 2"
          />
        </svg>
      </div>
    </div>
    <div class="round__container">
      <div class="round__wrapper border-neutral-01-50">
        <svg class="progress-ring" :width="sizeOuter" :height="sizeOuter">
          <circle
            id="circle2"
            ref="circle2"
            class="progress-ring__circle"
            :style="{ stroke: secondaryColor }"
            :stroke-width="strokeWidth"
            fill="transparent"
            :r="sizeOuter / 2 - 4"
            :cx="sizeOuter / 2"
            :cy="sizeOuter / 2"
          />
        </svg>
      </div>
    </div>
    <div class="round__container">
      <div class="round__wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LLRoundProgress',
  props: {
    percent: { type: Number, default: 0 },
    percentSecondary: { type: Number, default: 0 },
    small: { type: Boolean, default: false },
    micro: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    strokeWidth: { type: Number, default: 8 },
    baseStrokeWidth: { type: Number, default: 4 },
    color: {
      type: String,
      default: null
    },
    colorSecondary: {
      type: String,
      default: null
    }
  },
  computed: {
    mainColor() {
      return this.color ? this.color : 'var(--color-success-01)'
    },
    secondaryColor() {
      return this.colorSecondary ? this.colorSecondary : '#FFD049'
    },
    sizeOuter() {
      if (this.small) {
        return 120
      } else if (this.medium) {
        return 164
      } else if (this.large) {
        return 240
      } else if (this.micro) {
        return 30
      } else {
        return 184
      }
    },
    sizeInner() {
      if (this.small) {
        return 90
      } else if (this.medium) {
        return 110
      } else if (this.large) {
        return 200
      } else {
        return 138
      }
    }
  },
  watch: {
    percent() {
      this.updateRound(this.percent, this.percentSecondary)
    },
    percentSecondary() {
      this.updateRound(this.percent, this.percentSecondary)
    }
  },
  mounted() {
    this.updateRound(this.percent, this.percentSecondary)
  },
  methods: {
    updateRound(internal, external) {
      let circle = this.$refs.circle1
      let radius = circle.r.baseVal.value
      let circumference = radius * 2 * Math.PI

      circle.style.strokeDasharray = `${circumference} ${circumference}`
      circle.style.strokeDashoffset = `${circumference}`

      circle.style.strokeDashoffset = circumference - (internal / 100) * circumference

      circle = this.$refs.circle2
      radius = circle.r.baseVal.value
      circumference = radius * 2 * Math.PI

      circle.style.strokeDasharray = `${circumference} ${circumference}`
      circle.style.strokeDashoffset = `${circumference}`

      circle.style.strokeDashoffset = circumference - (external / 100) * circumference
    }
  }
}
</script>

<style lang="scss" scoped>
.LLRoundProgress {
  width: 188px;
  height: 188px;

  &.micro {
    width: 30px;
    height: 30px;
  }
  &.small {
    width: 124px;
    height: 124px;
  }
  &.medium {
    width: 164px;
    height: 164px;
  }
  &.large {
    width: 240px;
    height: 240px;
  }
}

.round__container {
  @apply flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .round__wrapper {
    @apply m-auto;
  }
}

.progress-ring__circle {
  transition: 1s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
