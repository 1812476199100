<template>
  <div class="LLCheckboxListCheckboxes">
    <div class="LLCheckboxListCheckboxes__item LLCheckboxListCheckboxesItem">
      <div class="LLCheckboxListCheckboxesItem__header">
        <div class="LLCheckboxListCheckboxesItem__header-content">
          <LLCheckbox
            :model-value="currentValue"
            :disabled="disabled || disabledValue(checkboxValue)"
            class="LLCheckboxListCheckboxes__item-checkbox"
            :value="reduce(checkboxValue)"
            :indeterminate="indeterminate(checkboxValue)"
            @checkboxChange="onCheckboxChange"
          >
            <slot name="item-tag" :value="checkboxValue"></slot>
            <div class="LLCheckboxListCheckboxes__item-label_hover">
              <slot name="hover-label" :value="checkboxValue">
                {{ label(checkboxValue) }}
              </slot>
            </div>
            <div class="LLCheckboxListCheckboxes__item-label">{{ label(checkboxValue) }}</div>
          </LLCheckbox>
        </div>
        <div v-if="children" class="LLCheckboxListCheckboxesItem__header-arrow-outer">
          <LLSquareSpoilerArrow
            v-if="checkboxChildren.length"
            class="LLCheckboxListCheckboxesItem__header-arrow"
            :opened.sync="childrenOpened"
          ></LLSquareSpoilerArrow>
        </div>
      </div>
      <div v-if="checkboxChildren && childrenOpened" class="LLCheckboxListCheckboxes__children">
        <LLCheckboxListCheckboxes
          v-for="childCheckboxValue in checkboxChildren"
          :key="keyFn(childCheckboxValue)"
          v-model="currentValue"
          :stopper="stopper + 1"
          :key-fn="keyFn"
          :parent="checkboxValue"
          :indeterminate-value="indeterminateValue"
          :disabled-value="disabledValue"
          :checkbox-value="childCheckboxValue"
          :label="label"
          :disabled="disabled"
          :selected="currentValue"
          :reduce="reduce"
          :children="children"
          @change="onChange"
        >
          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </LLCheckboxListCheckboxes>
      </div>
    </div>
  </div>
</template>

<script>
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2'
import LLSquareSpoilerArrow from '@/components/common/ui-components/LLSquareSpoilerArrow'
export default {
  name: 'LLCheckboxListCheckboxes',
  components: { LLSquareSpoilerArrow, LLCheckbox },
  props: {
    stopper: { type: Number, default: 0 },
    keyFn: { type: Function, default: (item) => item.key },
    reduce: { type: Function, default: (item) => item },
    label: { type: Function, default: (item) => item },
    children: { type: [Function, Boolean, null], default: null },
    disabledValue: { type: [Function, null], default: () => false },
    indeterminateValue: { type: [Function, null], default: () => false },
    disabled: { type: Boolean, default: false },
    checkboxValue: { type: Object, required: true },
    parent: { type: Object, default: null },
    modelValue: { type: [String, Number, Object], default: '' },
    selected: { type: Array, default: () => [] }
  },
  data() {
    return {
      currentValue: [],
      childrenOpened: true
    }
  },
  computed: {
    checkboxChildren() {
      if (this.children) {
        const children = this.children(this.checkboxValue)
        if (children) {
          return children
        } else {
          return null
        }
      } else {
        return false
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.currentValue = [...this.selected]
      }
    }
  },
  methods: {
    indeterminate(v) {
      return this.indeterminateValue(v)
      /* let selectedAmount = 0
      const checkChildren = (checkboxValue) => {
        if (this.children) {
          const children = this.children(checkboxValue)
          children.forEach((c) => {
            if (this.currentValue.includes(this.reduce(c))) {
              selectedAmount++
            }
            checkChildren(c)
          })
        }
      }
      checkChildren(this.checkboxValue)
      return !!selectedAmount */
    },
    onCheckboxChange(isSelected) {
      const newValue = [...this.currentValue]
      /* const setChildrenValue = (checkboxValue, value) => {
        const reducedValue = this.reduce(checkboxValue)
        if (value && !newValue.includes(reducedValue)) {
          newValue.push(reducedValue)
        } else if (!value && newValue.includes(reducedValue)) {
          newValue.splice(newValue.indexOf(reducedValue), 1)
        }
        if (this.children) {
          const children = this.children(checkboxValue)
          children.forEach((c) => setChildrenValue(c, value))
        }
      }
      setChildrenValue(this.checkboxValue, isSelected) */
      const reducedValue = this.reduce(this.checkboxValue)
      if (isSelected && !newValue.includes(reducedValue)) {
        newValue.push(reducedValue)
      } else if (!isSelected && newValue.includes(reducedValue)) {
        newValue.splice(newValue.indexOf(reducedValue), 1)
      }
      this.currentValue = newValue
      this.onChange([...this.currentValue])
    },
    onChange(value) {
      const newValue = [...value]
      /* if (this.children) {
        const children = this.children(this.checkboxValue)
        if (children?.length) {
          const selectedChildren = children.filter((c) => value.includes(this.reduce(c)))
          console.log(children.length, selectedChildren.length)
          const reducedValue = this.reduce(this.checkboxValue)
          if (children.length === selectedChildren.length && !newValue.includes(reducedValue)) {
            newValue.push(reducedValue)
          } else if (children.length !== selectedChildren.length && newValue.includes(reducedValue)) {
            newValue.splice(newValue.indexOf(reducedValue), 1)
          }
        }
      } */
      this.$emit('change', [...newValue])
    }
  }
}
</script>

<style lang="scss" scoped>
.LLCheckboxListCheckboxes {
  &__item {
    @apply select-none mt-2;
    &-checkbox {
      @apply w-full;
    }
    &:hover {
      .LLCheckboxListCheckboxes__item-label {
        @apply hidden;
      }
      .LLCheckboxListCheckboxes__item-label_hover {
        @apply block;
      }
    }
  }

  &__item-label {
    @apply flex-1 truncate min-w-0 text-body-01;
  }

  &__item-label_hover {
    @apply flex-1 truncate min-w-0 text-body-01 hidden;
  }
  &__children {
    @apply pl-2;
  }
}
.LLCheckboxListCheckboxesItem {
  &__header {
    @apply flex min-w-0 w-full items-center;
    &-content {
      @apply flex-1 min-w-0;
    }
    &-arrow-outer {
      @apply flex-shrink-0 w-5;
    }
    &-arrow {
      @apply w-5 h-5;
    }
  }
}
</style>
