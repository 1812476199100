<template>
  <div class="SearchResponsesItemStudyUser">
    <div class="SearchResponsesItemStudyUser__main-row">
      <div class="SearchResponsesItemStudyUser__image-wrapper">
        <ParticipantImageName
          :participant="participant"
          without-name
          click-to-show-profile
        ></ParticipantImageName>
      </div>
      <div class="SearchResponsesItemStudyUser__row">
        <div class="SearchResponsesItemStudyUser__row-item">
          <span class="SearchResponsesItemStudyUser__row-item-description"
            >{{ $t('prospect-account_first-name') }}:</span
          >
          <span class="SearchResponsesItemStudyUser__row-item-value">{{
            prospectName || $t('recruiting_details_not_available')
          }}</span>
        </div>
        <div class="SearchResponsesItemStudyUser__row-item">
          <span class="SearchResponsesItemStudyUser__row-item-description">{{ $t('common_channel') }}:</span>
          <span class="SearchResponsesItemStudyUser__row-item-value">{{ teamName }}</span>
        </div>
        <div class="SearchResponsesItemStudyUser__row-item">
          <span class="SearchResponsesItemStudyUser__row-item-description">{{ $t('common_study') }}:</span>
          <span class="SearchResponsesItemStudyUser__row-item-value">{{ studyName }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="withDemographics"
      class="SearchResponsesItemStudyUser__demography SearchResponsesItemStudyUser__row"
    >
      <div class="SearchResponsesItemStudyUser__row-item">
        <span class="SearchResponsesItemStudyUser__row-item-description"
          >{{ $t('prospect-account_gender') }}:</span
        >
        <span class="SearchResponsesItemStudyUser__row-item-value">{{ prospectDataProcessed.gender }}</span>
      </div>
      <div class="SearchResponsesItemStudyUser__row-item">
        <span class="SearchResponsesItemStudyUser__row-item-description"
          >{{ $t('prospect-account_age') }}:</span
        >
        <span class="SearchResponsesItemStudyUser__row-item-value">{{ prospectDataProcessed.age }}</span>
      </div>
      <div class="SearchResponsesItemStudyUser__row-item">
        <span class="SearchResponsesItemStudyUser__row-item-description"
          >{{ $t('prospect-account_location') }}:</span
        >
        <span class="SearchResponsesItemStudyUser__row-item-value">{{ prospectDataProcessed.location }}</span>
      </div>
      <div class="SearchResponsesItemStudyUser__row-item">
        <span class="SearchResponsesItemStudyUser__row-item-description"
          >{{ $t('prospect-account_ethnicity') }}:</span
        >
        <span class="SearchResponsesItemStudyUser__row-item-value">{{
          prospectDataProcessed.ethnicity || $t('recruiting_details_not_available')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantImageName from '@/components/modules/participants/common/ParticipantImageName'
import ParticipantUtils from '@/utils/participantUtils'
import { getAge } from '@/utils/dateUtils'
export default {
  name: 'SearchResponsesItemStudyUser',
  components: { ParticipantImageName },
  props: {
    response: { type: Object, required: true },
    withDemographics: { type: Boolean, default: false }
  },
  data() {
    return {}
  },
  computed: {
    prospectDataProcessed() {
      const prospect = { ...this.participant }
      return {
        firstName: _.upperFirst(prospect?.firstName) || null,
        lastName: _.upperFirst(prospect?.lastName) || null,
        phone: prospect?.phone || null,
        location: ParticipantUtils.getLocation(prospect),
        email: prospect?.email || null,
        gender: prospect?.gender
          ? this.$t(`gender_${this.genders.find((g) => g.value === prospect?.gender)?.label}`)
          : null,
        age: prospect?.birthday ? getAge(prospect.birthday) : null,
        ethnicity: prospect?.ethnicity
          ? this.$t(`ethnicity_${this.ethnicities.find((g) => g.value === prospect?.ethnicity)?.label}`)
          : null
      }
    },
    ethnicities() {
      return this.$store.getters.ethnicitiesArray
    },
    genders() {
      return this.$store.getters.gendersArray
    },
    participant() {
      return this.response?.prospect
    },
    prospectName() {
      return [this.participant?.firstName, this.participant?.lastName?.charAt(0)].filter(Boolean).join(' ')
    },
    teamName() {
      return this.response?.team?.name
    },
    studyName() {
      return this.response?.study?.name
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.SearchResponsesItemStudyUser {
  &__main-row {
    @apply flex items-center;
  }
  &__row {
    @apply flex flex-col;
    @screen md {
      @apply flex flex-row;
    }
  }
  &__row-item {
    @apply text-body-02 ml-4;
    &:first-child {
      @apply ml-4;
    }
    &-description {
      @apply text-neutral-01-400;
    }
    &-value {
    }
  }
  &__demography {
    @apply ml-7 mt-2;
  }
}
</style>
