<template>
  <div
    class="header fixed top-0 w-full bg-bg-01 border-b border-neutral-01-25 z-50"
    :class="[transparentClass]"
  >
    <div class="container flex items-center h-full">
      <!--      <button v-if="!burgerHidden" type="button" class="header__menu-btn mr-4 focus:outline-none">
        <BurgerIcon/>
      </button>-->
      <RouterLink to="/" class="header__logo">
        <SiteLogo class="header__logo-image" :class="whiteLogoClass" data-e2e="siteLogo" />
      </RouterLink>
      <div v-if="!isAuth" class="ml-auto">
        <LLDropdown :white="white" class="ml-2" no-icon bordered data-e2e="switchLanguage">
          <template #title>{{ currentLocale }}</template>
          <button
            v-for="locale in availableLocales"
            :key="locale.code"
            class="block w-full text-left px-4 py-2 01 text-sm font-semibold hover:bg-neutral-01-25"
            type="button"
            @click="switchLocale(locale)"
          >
            {{ locale.label }}
          </button>
        </LLDropdown>
      </div>
      <div v-if="isAuth" class="flex items-center ml-auto">
        <!--        <form class="mr-8 md:block mob-only:hidden">
          <LLTextInput
            search
            class="max-w-screen-md"
          >
            <template #prefix>
              <button type="submit" class="text-neutral-01 focus:outline-none">
                <SearchIcon/>
              </button>
            </template>
          </LLTextInput>
        </form>-->
        <LLDropdown class="ml-2 mob-only:hidden" no-icon bordered data-e2e="switchLanguage">
          <template #title>{{ currentLocale }}</template>
          <button
            v-for="locale in availableLocales"
            :key="locale.code"
            class="block w-full text-left px-4 py-2 text-neutral-01 text-sm font-semibold hover:bg-neutral-01-25"
            type="button"
            @click="switchLocale(locale)"
          >
            {{ locale.label }}
          </button>
        </LLDropdown>
        <LLDropdown class="ml-8 flex-shrink-0" no-chevron data-e2e="UserProfileDropdown">
          <template #action>
            <div class="flex w-8" style="{ width: 100%}">
              <UserImageUploader
                :editable="false"
                small
                :string="userObject.fullName"
                :image="user.image"
                class="inline-block mr-2"
              />
            </div>
          </template>
          <button
            v-if="!isCompleteProfilePage"
            class="block w-full text-left px-4 py-2 text-neutral-01 text-sm font-semibold hover:bg-neutral-01-25"
            type="button"
            data-e2e="userEditModalBtn"
            @click="userEditModalOpened = true"
          >
            {{ $t('dashboard_profile_edit') }}
          </button>
          <button
            class="block w-full text-left px-4 py-2 text-neutral-01 text-sm font-semibold hover:bg-neutral-01-25"
            type="button"
            data-e2e="headerLogoutBtn"
            @click="logout"
          >
            {{ $t('auth_btn_logout') }}
          </button>
        </LLDropdown>
        <LLModalConditional :opened.sync="userEditModalOpened" name="user-edit-modal">
          <UserEditModalContent @close="closeUserEditModal" />
        </LLModalConditional>
      </div>
    </div>
  </div>
</template>

<script>
import SiteLogo from '@/components/common/SiteLogo'
import { mapState } from 'vuex'
import LLModalConditional from '@/components/common/LLModal/LLModalConditional'
import UserEditModalContent from '@/components/user/UserEditModal/UserEditModalContent'
import languageLabels from '@/common/languages.json'
import UserImageUploader from '@/components/common/UserImageUploader'
import { ProspectModel } from '@/models/prospect'
import { localeChanged } from 'vee-validate'

export default {
  name: 'SiteHeader',
  components: { UserImageUploader, UserEditModalContent, LLModalConditional, SiteLogo },
  props: {
    white: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    burgerHidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userEditModalScope: null,
      userEditModalOpened: false
    }
  },
  computed: {
    ...mapState('user', ['user']),
    userObject() {
      return ProspectModel.parseFromApi({ prospect: this.user })
    },
    pictureUploaded() {
      return !!this.user.image
    },
    timestamp() {
      return process.env.VUE_APP_TIMESTAMP
    },
    currentLocale() {
      return this.$i18n.locale.toUpperCase()
    },
    isCompleteProfilePage() {
      return this.$route.name === 'completeProfile'
    },
    availableLocales() {
      return this.$i18n.availableLocales.map((l) => ({
        code: l,
        label: languageLabels?.[l]
      }))
    },
    isAuth() {
      return !!this.$store.state.auth.token
    },
    whiteLogoClass() {
      return this.white ? 'white' : ''
    },
    transparentClass() {
      return this.transparent ? 'header_transparent' : ''
    },
    profileAvatarLetters() {
      if (this.user.firstName) {
        return this.user.firstName?.charAt(0)?.toUpperCase() + this.user.lastName?.charAt(0)?.toUpperCase()
      }
      return ''
    }
  },
  methods: {
    closeUserEditModal() {
      this.$modal.hide('user-edit-modal')
    },
    logout() {
      this.$routerPush.goToLogout()
    },
    switchLocale(locale) {
      locale = locale.code.toLowerCase()
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        this.$store.commit('ui/SET_LOCALE', locale)
      }
      localeChanged()
      if (this.$store.getters['auth/isAuthorised']) {
        this.$store.dispatch('user/syncUser', {
          preferredLanguage: locale
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: var(--header-height);

  &_transparent {
    background: transparent !important;
    border: none;
  }

  &__menu-btn {
    @screen md {
      display: none;
    }
  }

  &__logo {
    margin-right: 70px;

    &-image {
      width: 100%;
      max-width: 200px;
    }
  }
}
</style>
