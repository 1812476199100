<template>
  <transition :name="showPreloader ? 'image-loader-fade' : null" mode="out-in">
    <ImageLoaderImage v-if="src && file" class="ImageLoader" :src="src" alt="" @ready="onImageReady" />
    <div v-else-if="file && !src && !failedLoad && showPreloader" class="skeleton-box"></div>
    <div v-else-if="file && !src && !failedLoad && !showPreloader" class="pre-skeleton-box"></div>
    <img
      v-else-if="(!file || failedLoad) && emptyPicture === 'profile'"
      src="/images/profile_image_placeholder.jpg"
      alt=""
    />
    <img
      v-else-if="(!file || failedLoad) && emptyPicture === 'picture'"
      src="/images/placeholder.png"
      alt=""
    />
    <div v-else-if="(!file || failedLoad) && emptyPicture === 'slot'" class="ImageLoader">
      <slot name="empty-picture" />
    </div>
  </transition>
</template>

<script>
import ImageLoaderImage from '@/components/common/ImageLoader/ImageLoaderImage.vue'

export default {
  name: 'ImageLoader',
  components: { ImageLoaderImage },
  props: {
    file: { type: Object, default: null },
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    emptyPicture: { type: String, default: 'picture' },
    original: { type: Boolean, default: false }
  },
  data() {
    return {
      src: null,
      failedLoad: false,
      showPreloader: false
    }
  },
  computed: {
    thumbnailPosition() {
      return this.file?.thumbnailPosition
    },
    thumbnail250() {
      return this.file?.thumbnails?.find((t) => t.size === 250)?.location
    },
    thumbnail50() {
      return this.file?.thumbnails?.find((t) => t.size === 50)?.location
    },
    thumbnail80() {
      return this.file?.thumbnails?.find((t) => t.size === 80)?.location
    }
  },
  watch: {
    file: {
      handler(to, from) {
        if (to?.id !== from?.id) {
          this.tryThumbnail()
        }
      },
      immediate: true
    }
  },
  methods: {
    tryThumbnail() {
      this.src = false
      this.$emit('startLoading')
      const sizes = [
        { size: 50, url: this.thumbnail50 },
        { size: 80, url: this.thumbnail80 },
        { size: 250, url: this.thumbnail250 },
        { size: 'original', url: this.file?.location }
      ]

      if (this.small && sizes[0]?.url) {
        this.tryLoad(sizes[0], sizes)
      } else if (this.large && sizes[2]?.url) {
        this.tryLoad(sizes[2], sizes)
      } else if (sizes[1].url && !this.large && !this.original) {
        this.tryLoad(sizes[1], sizes)
      } else if (sizes[3]?.url) {
        this.tryLoad(sizes[3], sizes)
      } else {
        this.failedLoad = true
      }
    },
    tryLoad(size, sizes) {
      if (size.size === 'original' && !size.url) return
      this.loadImage(size.url)
        .then((src) => this.imageLoaded(src))
        .catch(() => {
          if (size.size === 'original') {
            this.failedLoad = true
            return
          }
          this.tryLoad(sizes[sizes.indexOf(size) + 1], sizes)
        })
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const newImg = new Image()
        newImg.src = src
        if (!newImg.complete) {
          this.showPreloader = true
        }
        newImg.addEventListener('load', () => {
          this.widthLargerThanHeight = newImg.width > newImg.height
          resolve(src)
          newImg.remove()
        })
        newImg.addEventListener('error', reject)
      })
    },
    onImageReady() {
      this.isImageReady = true
      this.$nextTick(() => {
        this.$emit('loaded')
      })
    },
    imageLoaded(src) {
      this.src = src
    }
  }
}
</script>

<style lang="scss" scoped>
.image-loader-fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.4s;
}

.image-loader-fade-leave-active {
  transition-property: opacity;
  transition-duration: 0;
}

.image-loader-fade-enter,
.image-loader-fade-leave-to {
  opacity: 0;
}

.pre-skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
