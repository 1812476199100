<template>
  <LLConfirmModal class="StudyNewPageUnfinishedError">
    <template #title>
      {{ $t('conversation-leave-warning_title_error') }}
    </template>
    <template #default>
      <div class="StudyNewPageUnfinishedError__content">
        <div class="StudyNewPageUnfinishedError__top-description">
          {{ $t('conversation-leave-warning_error-description') }}
        </div>
        <div
          v-for="question in localUnsavedQuestions"
          :key="question.id"
          class="StudyNewPageUnfinishedError__question"
        >
          <div v-if="messageState(question.id)" class="StudyNewPageUnfinishedError__question-icon">
            <LoadingIcon
              v-if="messageState(question.id) === 10"
              class="StudyNewPageUnfinishedError__question-icon-loading"
            ></LoadingIcon>
            <ClearIcon
              v-else-if="messageState(question.id) === 30"
              class="StudyNewPageUnfinishedError__question-icon-warning"
            ></ClearIcon>
            <SuccessIcon
              v-else-if="messageState(question.id) === 20"
              class="StudyNewPageUnfinishedError__question-icon-success"
            ></SuccessIcon>
          </div>
          <div class="StudyNewPageUnfinishedError__question-number">
            {{
              $t('conversation-leave-warning_question-number', { questionNumber: questionIndex(question.id) })
            }}
          </div>
          <div class="StudyNewPageUnfinishedError__question-title">{{ question.title }}</div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="StudyNewPageUnfinishedError__actions">
        <LLButton class="StudyNewPageUnfinishedError__action" small @click.native="cancelClick">{{
          $t('conversation-leave-warning_action-cancel')
        }}</LLButton>
      </div>
    </template>
  </LLConfirmModal>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LoadingIcon from '@/components/common/LoadingIcon.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import SuccessIcon from '@/assets/icons/SuccessIcon.vue'
import ClearIcon from '@/assets/icons/ClearIcon.vue'

export default {
  name: 'StudyNewPageUnfinishedError',
  components: { ClearIcon, SuccessIcon, LLConfirmModal, LoadingIcon, LLButton },
  props: {
    sendingStates: { type: Object, default: () => {} },
    questions: { type: Array, required: true, default: () => [] },
    isPending: { type: Boolean, default: false },
    questionIndexes: { type: Object, default: () => {} }
  },
  data() {
    return {}
  },
  computed: {
    localUnsavedQuestions() {
      return this.questions.filter((question) => this.sendingStates?.[question.id] === 30)
    }
  },
  methods: {
    questionIndex(questionId) {
      return this.questionIndexes[questionId] !== undefined ? this.questionIndexes[questionId] + 1 : 0
    },
    cancelClick() {
      this.$emit('cancel')
    },
    messageState(questionId) {
      return this.sendingStates?.[questionId]
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageUnfinishedError {
  &__top-description {
    @apply mb-5;
  }
  &__bottom-description {
    @apply mt-5;
  }
  &__content {
    max-width: 500px;
  }

  &__actions {
    @apply flex;
    @screen mob-only {
      @apply flex-col items-end;
    }
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
    @screen mob-only {
      @apply ml-0 mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
  &__question {
    @apply flex items-center min-w-0;
    &-icon {
      @apply w-5 h-5 mr-3 flex-shrink-0;
      &-loading {
        @apply w-5 h-5;
      }
      &-warning {
        @apply w-full h-full text-status-03-600;
      }
      &-success {
        @apply w-full h-full text-status-01-600;
      }
    }
    &-number {
      @apply mr-2 text-button-01;
    }
    &-title {
      @apply flex-1 truncate;
    }
  }
}
</style>
