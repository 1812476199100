const state = () => ({
  showModal: false
})

let exitCallback = null

export default {
  namespaced: true,
  state: state(),
  getters: {
    unansweredQuestions: (state, getters, rootState, rootGetters) => () => {
      const selectedTopicQuestions = rootGetters['study/selectedTopicQuestions']
      const conversationStreams = rootGetters['studies/conversationStreams']
      const unansweredQuestions = selectedTopicQuestions.filter((q) => {
        const found = conversationStreams.find((cs) => cs.questionId === q.id)
        return found ? found.state === 10 : false
      })
      return unansweredQuestions
    },
    haveUnansweredQuestions: (state, getters, rootState, rootGetters) => () => {
      const selectedTopicQuestions = rootGetters['study/selectedTopicQuestions']
      return (
        getters.unansweredQuestions().length > 0 &&
        getters.unansweredQuestions().length < selectedTopicQuestions.length
      )
    }
  },
  mutations: {
    UPDATE_SHOW_MODAL(state, value) {
      state.showModal = !!value
    }
  },
  actions: {
    async closeModal({ commit }, { withCallback }) {
      commit('UPDATE_SHOW_MODAL', false)
      if (!withCallback && exitCallback) {
        exitCallback()
        exitCallback = null
      }
    },
    async showWarning({ commit }, { callback }) {
      commit('UPDATE_SHOW_MODAL', true)
      exitCallback = callback
    }
  }
}
