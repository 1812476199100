import router from '@/router/index'

const middlewareHandler = (...middlewareList) => {
  return async (to, from, next) => {
    let redirected = false
    for (const i in middlewareList) {
      const middleware = middlewareList[i]
      let nextRoute = null
      await middleware(to, from, (route) => {
        nextRoute = route
      })
      if (nextRoute) {
        redirected = true
        router.replace(nextRoute).catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            logError(err)
          }
        })
        break
      }
    }
    if (!redirected) {
      next()
    }
  }
}

export default middlewareHandler
