<template>
  <LLConfirmModalTransition>
    <LLConfirmModal v-if="localOpened" :max-width="600">
      <LLDialog class="DeletePollResponseModal">
        <template #content>
          <h3 class="DeletePollResponseModal__title">
            {{ confirmMessage }}
          </h3>
        </template>
        <template #actions>
          <LLButton :loading="pending" danger small @click="deletePollResponse">{{
            $t('button_delete')
          }}</LLButton>
          <LLButton v-popper-close :disabled="pending" secondary small @click="closeModal">
            {{ $t('button_cancel') }}
          </LLButton>
        </template>
      </LLDialog>
    </LLConfirmModal>
  </LLConfirmModalTransition>
</template>

<script>
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import { ProspectModel } from '@/models/prospect'
import { PollModel } from '@/models/poll'
import { UserModel } from '@/models/user'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import LLDialog from '@/components/common/LLDialog.vue'
export default {
  name: 'DeletePollResponseModal',
  components: { LLDialog, LLConfirmModalTransition, LLButton, LLConfirmModal },
  props: {
    opened: { type: Boolean, default: false },
    poll: { type: PollModel, required: true },
    prospect: { type: ProspectModel, default: null },
    user: { type: UserModel, default: null }
  },
  data() {
    return {
      pending: false,
      localOpened: false
    }
  },
  computed: {
    confirmMessage() {
      return this.$t('forum.poll.results.table.delete_modal_title', {
        name: this.submitter?.fullName
      })
    }
  },
  watch: {
    opened: {
      immediate: true,
      handler() {
        this.localOpened = this.opened
      }
    },
    localOpened() {
      this.$emit('update:opened', this.localOpened)
    }
  },

  methods: {
    closeModal() {
      this.localOpened = false
    },
    async deletePollResponse() {
      this.pending = true
      try {
        await this.$api.forum.poll.deleteResult({
          chatId: this.poll.chatId,
          threadId: this.poll.threadId,
          prospectId: this.prospect?.id,
          userId: this.user?.id
        })
        this.$emit('deletePollResponse')
        this.$notify({
          group: 'main',
          type: 'success',
          title: 'Response deleted'
        })
        this.closeModal()
      } catch (e) {
        this.$toast.error(this.$getErrorMessage(e))
      } finally {
        this.pending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.DeletePollResponseModal {
  @screen md {
    width: 480px;
  }
  max-width: 100vw;
  &__title {
    @apply break-words;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__collections-list {
    @apply border border-neutral-01-50 rounded p-4 mt-4 overflow-y-auto;
    max-height: 250px;
    &-wrapper {
      @apply flex flex-wrap items-start;
      @apply -mt-2;
    }
  }
  &__collections-tag-wrapper {
    max-width: 100%;
    @apply flex-shrink-0 mr-2 mt-2;
  }
  &__collections-tag {
  }
  &__warning {
    @apply mt-4 text-status-03-600 text-subheading-01;
  }
  &__total-prospects-amount {
    @apply text-caption-01 text-neutral-01-400;
  }
}
</style>
