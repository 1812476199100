import { eventNameEnums } from '@/utils/modules/forum/threadEditor/enums'

import { Connection } from '@/plugins/conversationWebsocket'
import { ForumResponseModel } from '@/models/forum/forumResponse'
import { ForumReactionModel } from '@/models/forum/forumReaction'
import globalRoutes from '@/utils/globalRoutes'
let host = globalRoutes.api?.replace('http', 'ws')

let connection = null
let events = {}

const sendToken = ({ token, threadId }) => {
  connection.request('token', token)
  connection.setRoom(`thread-editing:${threadId}`)
}

const runEvents = (eventName, data) => {
  if (events[eventName]) {
    events[eventName].forEach((callback) => callback(data))
  }
}

export default {
  connect({ token, chatId, threadId }) {
    events = {}
    connection = new Connection(`${host}/api/pwa/ws`)
    connection.on('_reopen', () => {
      sendToken({ token, threadId })
    })
    connection.on('_open', () => {
      sendToken({ token, threadId })
    })

    connection.on(eventNameEnums.RESPONSE_DELETE, ({ responseId, parentResponseId }) => {
      runEvents(eventNameEnums.RESPONSE_DELETE, {
        responseId,
        parentResponseId
      })
    })
    connection.on(eventNameEnums.RESPONSE_CREATE, ({ response, user, prospect }) => {
      runEvents(eventNameEnums.RESPONSE_CREATE, {
        response: ForumResponseModel.parseFromApi({ response, chatId, threadId, user, prospect })
      })
    })
    connection.on(eventNameEnums.RESPONSE_UPDATE, ({ response, user, prospect }) => {
      runEvents(eventNameEnums.RESPONSE_UPDATE, {
        response: ForumResponseModel.parseFromApi({ response, chatId, threadId, user, prospect })
      })
    })
    connection.on(eventNameEnums.REACTIONS_UPDATE, ({ reactions, responseId }) => {
      runEvents(eventNameEnums.REACTIONS_UPDATE, {
        responseId,
        reactions: reactions.map((reaction) => ForumReactionModel.parseFromApi({ reaction }))
      })
    })
    connection.on(eventNameEnums.THREAD_META_UPDATE, (threadMeta) => {
      runEvents(eventNameEnums.THREAD_META_UPDATE, {
        threadMeta
      })
    })
  },
  destroy() {
    if (connection) {
      connection.close()
    }
    events = {}
  },
  trigger(name, data) {
    runEvents(name, data)
  },
  addEvent(name, callback) {
    if (events[name]) {
      events[name].push(callback)
    } else {
      events[name] = [callback]
    }
  },
  removeEvent(name, callback) {
    if (events[name]) {
      events[name].splice(events[name].indexOf(callback), 1)
    }
    if (events[name]?.length === 0) {
      delete events[name]
    }
  },
  enums: eventNameEnums
}
