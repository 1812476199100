<template>
  <svg
    class="icon fill-current"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.66366 2.7256C7.66366 2.16743 8.12367 1.70742 8.68184 1.70742H17.7728C18.3351 1.70742 18.7909 2.16328 18.7909 2.7256V9.08924C18.7909 9.65157 18.3351 10.1074 17.7728 10.1074H16.8637C16.4218 10.1074 16.0637 10.4656 16.0637 10.9074V11.7033L14.7021 10.3417C14.552 10.1917 14.3486 10.1074 14.1364 10.1074H8.68184C8.11951 10.1074 7.66366 9.65157 7.66366 9.08924V2.7256ZM8.68184 0.107422C7.24001 0.107422 6.06366 1.28378 6.06366 2.7256V9.08924C6.06366 10.5352 7.23586 11.7074 8.68184 11.7074H13.805L16.298 14.2004C16.5268 14.4292 16.8709 14.4976 17.1698 14.3738C17.4687 14.25 17.6637 13.9583 17.6637 13.6347V11.7074H17.7728C19.2187 11.7074 20.3909 10.5352 20.3909 9.08924V2.7256C20.3909 1.27962 19.2187 0.107422 17.7728 0.107422H8.68184ZM2.20913 8.18068C2.20913 7.62251 2.66914 7.1625 3.22731 7.1625H4.50001C4.94184 7.1625 5.30001 6.80433 5.30001 6.3625C5.30001 5.92067 4.94184 5.5625 4.50001 5.5625H3.22731C1.78548 5.5625 0.609131 6.73885 0.609131 8.18068V14.5443C0.609131 15.9903 1.78133 17.1625 3.22731 17.1625H3.3364V19.0898C3.3364 19.4133 3.53132 19.7051 3.83026 19.8289C4.1292 19.9527 4.47329 19.8843 4.70209 19.6555L7.19505 17.1625H12.3182C13.7642 17.1625 14.9364 15.9903 14.9364 14.5443V13.4987C14.9364 13.0569 14.5782 12.6987 14.1364 12.6987C13.6946 12.6987 13.3364 13.0569 13.3364 13.4987V14.5443C13.3364 15.1066 12.8805 15.5625 12.3182 15.5625H6.86368C6.6515 15.5625 6.44802 15.6468 6.29799 15.7968L4.9364 17.1584V16.3625C4.9364 15.9207 4.57823 15.5625 4.1364 15.5625H3.22731C2.66499 15.5625 2.20913 15.1066 2.20913 14.5443V8.18068Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ModerateIcon'
}
</script>
