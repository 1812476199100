<template>
  <svg class="icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="fill-current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2677 16.0001L19.5605 11.7072L18.1463 10.293L12.4392 16.0001L18.1463 21.7072L19.5605 20.293L15.2677 16.0001Z"
    />
    <circle class="stroke-current" cx="16" cy="16" r="15.5" />
  </svg>
</template>

<script>
export default {
  name: 'CircleArrowLeftIcon'
}
</script>
