<template>
  <svg
    class="icon fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.28524 22.0004V18.6683H4.8565C3.27836 18.6683 1.99902 17.389 1.99902 15.8108V4.85552C1.99902 3.27738 3.27836 1.99805 4.8565 1.99805H19.1439C20.7155 1.99805 22.0014 3.28391 22.0014 4.85552V15.8108C22.0014 17.389 20.722 18.6683 19.1439 18.6683H10.5715L6.28524 22.0004ZM5.78652 7.99922C5.78652 7.55739 6.14469 7.19922 6.58652 7.19922H17.414C17.8558 7.19922 18.214 7.55739 18.214 7.99922C18.214 8.44105 17.8558 8.79922 17.414 8.79922H6.58652C6.14469 8.79922 5.78652 8.44105 5.78652 7.99922ZM5.78652 12.3C5.78652 11.8582 6.14469 11.5 6.58652 11.5H12.8557C13.2976 11.5 13.6557 11.8582 13.6557 12.3C13.6557 12.7418 13.2976 13.1 12.8557 13.1H6.58652C6.14469 13.1 5.78652 12.7418 5.78652 12.3Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TopicRedIcon'
}
</script>
