<template>
  <div class="PollNumberResults">
    <PollResults
      :filter-type="filterType"
      :poll="poll"
      :thread="thread"
      :export-data="exportData"
      @updateResult="onResultUpdate"
      @updateLocations="onLocationsUpdate"
    >
      <template #average>
        <PollResultsAverage v-if="average !== null" :average="average" :max="maxValue" :min="minValue"
      /></template>
      <template v-if="false" #chart>
        <PollNumberResultsBar
          v-if="result"
          class="PollNumberResults__bar"
          :location-groups="filterLocationGroups"
          :poll="poll"
          :data="result"
          :filter-type="filterType"
          @updateExport="onUpdateExportData"
        />
      </template>
      <template #filters>
        <PollResultsFilterSimple :filter-type.sync="filterType" />
      </template>
    </PollResults>
  </div>
</template>

<script>
import PollResults from '@/components/modules/forum-chat/poll/ForumPollResults.vue'
import { PollModel } from '@/models/poll'
import PollResultsAverage from '@/components/modules/forum-chat/poll/results/PollResultsAverage.vue'
import { PollNumberStyle } from '@/common/enums'
import PollResultsFilterSimple from '@/components/modules/forum-chat/poll/results/PollResultsFilterSimple.vue'
import PollNumberResultsBar from '@/components/modules/poll/number/PollNumberResultsBar.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollNumberResults',
  components: {
    PollNumberResultsBar,
    PollResultsFilterSimple,
    PollResultsAverage,
    PollResults
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      filterType: 'gender',
      filteredResult: null,
      result: null,
      average: null,
      filterLocationGroups: [],
      exportData: []
    }
  },
  computed: {
    minValue() {
      switch (this.poll.numberStyle) {
        case PollNumberStyle.SLIDER:
          return this.poll.sliderFrom
        case PollNumberStyle.STARS:
          return 0
        default:
          return null
      }
    },
    maxValue() {
      switch (this.poll.numberStyle) {
        case PollNumberStyle.SLIDER:
          return this.poll.sliderTo
        case PollNumberStyle.STARS:
          return this.poll.starsAmount
        default:
          return null
      }
    }
  },
  methods: {
    onResultUpdate({ result }) {
      this.average = Number(result?.overallResult?.average)
      this.result = result.results
    },
    onLocationsUpdate({ locations }) {
      this.filterLocationGroups = locations
    },
    onUpdateExportData({ exportData }) {
      this.exportData = exportData
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberResults {
  &__bar {
    @apply w-full;
  }
}
</style>
