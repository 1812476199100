<template>
  <div class="ConversationStatus flex items-center">
    <AnsweredIcon v-if="status === StreamState.ANSWERED" class="text-answered-01"></AnsweredIcon>
    <NotStartedIcon v-if="status === StreamState.NOT_ANSWERED"></NotStartedIcon>
    <CompletedIcon v-if="status === StreamState.RESOLVED" class="text-success-01"></CompletedIcon>
    <RepliedIcon v-if="status === 40"></RepliedIcon>
    <!--
    <div class="ml-2 font-semibold text-neutral-01 text-sm">
      <template v-if="status === 20">{{ $t('answered') }}</template>
      <template v-if="status === 10">{{ $t('not_started') }}</template>
      <template v-if="status === 30">{{ $t('completed') }}</template>
      <template v-if="status === 40">{{ $t('replied') }}</template>
    </div>
    -->
  </div>
</template>

<script>
import { StreamState } from '@/common/enums'

export default {
  name: 'ConversationStatus',
  props: {
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    return { StreamState }
  }
}
</script>

<style lang="scss" scoped>
.ConversationStatus {
}
</style>
