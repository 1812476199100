<template>
  <div class="PollMatrixResults">
    <PollResults
      :filter-type="filterTypeToSend"
      :filter-values="filterValues"
      :poll="poll"
      :export-data="exportData"
      @updateResult="onResultUpdate"
      @updateLocations="onLocationsUpdate"
    >
      <template #chart>
        <PollMatrixResultsBar
          v-if="result"
          class="PollMatrixResults__bar"
          :data="resultPercentage"
          :total-count="totalCount"
          :poll="poll"
        />
      </template>
      <template #filters>
        <PollResultsFilterAdvanced
          :filter-type.sync="filterType"
          :filter-values.sync="filterValues"
          :location-groups="filterLocationGroups"
        />
      </template>
    </PollResults>
  </div>
</template>

<script>
import PollResults from '@/components/modules/forum-chat/poll/ForumPollResults.vue'
import { PollModel } from '@/models/poll'
import PollResultsFilterAdvanced from '@/components/modules/forum-chat/poll/results/PollResultsFilterAdvanced.vue'
import PollMatrixResultsBar from '@/components/modules/poll/matrix/PollMatrixResultsBar.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'PollMatrixResults',
  components: { PollMatrixResultsBar, PollResultsFilterAdvanced, PollResults },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: PollModel, required: true }
  },
  data() {
    return {
      filterType: null,
      filterValues: [],
      filteredResult: null,
      result: null,
      filterLocationGroups: []
    }
  },
  computed: {
    sortedRows() {
      return [...this.poll?.rows].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    sortedColumns() {
      return [...this.poll?.columns].sort((a, b) => a.randomOrder - b.randomOrder)
    },
    exportData() {
      if (!this.resultPercentage) {
        return
      }
      const result = []
      this.resultPercentage.forEach((row) => {
        const csvRow = { [this.$t('forum.poll.results.export.category')]: row.text }
        row.columns.forEach((column) => {
          csvRow[column.text] = column.count
        })
        result.push(csvRow)
      })
      return result
    },
    totalCount() {
      return this.result?.totalCount ?? 0
    },
    resultPercentage() {
      if (!this.result) {
        return []
      }
      const rows = this.sortedRows.map((row) => ({
        id: row.id,
        text: row.text,
        columns: this.sortedColumns.map((column) => {
          const result = this.result.results.find(
            (result) => row.id === result.rowId && column.id === result.columnId
          )
          return {
            id: column.id,
            text: column.text,
            count: result?.count || 0
          }
        })
      }))

      return rows
    },
    resultFilteredPercentage() {
      const total = this.filteredResult?.totalCount ?? 0
      const rows = {}
      this.filteredResult?.results?.forEach((result) => {
        rows[result?.rowId] = Math.round((result?.count / total) * 100)
      })
      return rows
    },
    choiceListPercentage() {
      return this.sortedRows.map((row) => ({
        text: row.text,
        id: row.id,
        value: this.resultPercentage?.[row.id] ?? 0
      }))
    },
    choiceListFilteredPercentage() {
      return this.sortedRows.map((row) => ({
        text: row.text,
        id: row.id,
        value: this.resultFilteredPercentage?.[row.id] ?? 0
      }))
    },
    filterTypeToSend() {
      return this.filterValues?.length ? this.filterType : null
    }
  },
  methods: {
    onResultUpdate({ result }) {
      this.result = result
    },
    onLocationsUpdate({ locations }) {
      this.filterLocationGroups = locations
    }
  }
}
</script>

<style scoped lang="scss">
.PollMatrixResults {
  &__bar {
    @apply w-full;
  }
}
</style>
