<template>
  <div class="SearchResponsesResults">
    <LLListSelector
      v-show="resultIdList.length && !isActionsVisible"
      :id-amount="total"
      :selected-id-list.sync="selectedItems"
      :id-list="resultIdList"
      :selected-all.sync="selectedAll"
      :selected-all-visible.sync="selectedAllVisible"
      @update:selectedIdList="onUpdateSelectedIdListFromSelector"
    ></LLListSelector>
    <SearchResponsesSelectedActions
      v-show="isActionsVisible"
      class="SearchResponsesResults__actions"
      :total="total"
      :filter-values="filterValues"
      :sort-by="sortBy"
      :search-value="searchValue"
      :selected-all="selectedAll"
      :chat="chat"
      :selected-id-list="selectedItems"
      @unselectAll="onUnselectAll"
    ></SearchResponsesSelectedActions>
    <div class="SearchResponsesResults__list">
      <SearchResponsesItem
        v-for="result in results"
        :key="result.id"
        :response="result"
        :with-demographics="withDemographics"
        :selected="isValueSelected(result)"
        class="SearchResponsesResults__card"
        @update:selected="updateSelectedValues(result, $event)"
      ></SearchResponsesItem>
    </div>
  </div>
</template>

<script>
import SearchResponsesItem from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesItem'
import SearchResponsesSelectedActions from '@/components/pages/search/SearchModule/SearchContent/SearchResponses/SearchResponsesSelectedActions'
import LLListSelector from '@/components/common/LLListSelector.vue'
import { ForumChatModel } from '@/models/forum/forumChat'
export default {
  name: 'SearchResponsesResults',
  components: { LLListSelector, SearchResponsesSelectedActions, SearchResponsesItem },
  props: {
    filterValues: { type: Object, required: true },
    sortBy: { type: Number, default: null },
    total: { type: Number, default: 0 },
    results: { type: Array, default: () => [] },
    searchValue: { type: String, default: null },
    pending: { type: Boolean, default: false },
    withDemographics: { type: Boolean, default: false },
    chat: { type: [ForumChatModel, null], default: null }
  },
  data() {
    return {
      selectedItems: [],
      selectedAll: false,
      selectedAllVisible: false
    }
  },
  computed: {
    isActionsVisible() {
      return this.selectedAll || this.selectedItems.length
    },
    resultIdList() {
      return this.results.map((item) => item.id)
    }
  },
  watch: {
    results: {
      handler() {
        this.selectedItems = []
        this.selectedAll = false
        this.selectedAllVisible = false
      }
    }
  },
  methods: {
    isValueSelected(result) {
      const id = (result?.study ? 'study-' : 'chat-') + result.id
      return !!this.selectedItems.find((si) => si === id)
    },
    updateSelectedValues(result, value) {
      const id = (result?.study ? 'study-' : 'chat-') + result.id
      if (value) {
        this.selectedItems.push(id)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(id), 1)
      }
    },
    onUpdateSelectedIdListFromSelector(values) {
      this.selectedItems = values.map((value) => {
        const result = this.results.find((result) => result.id === value)
        return (result?.study ? 'study-' : 'chat-') + result.id
      })
    },
    onUnselectAll() {
      this.selectedItems = []
      this.selectedAll = false
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesResults {
  @apply relative;
  &__list {
    @apply mt-6;
  }
  &__card {
    @apply mt-8 border-b pb-8 border-neutral-01-50;
    &:first-child {
      @apply mt-0;
    }
    &:last-child {
      @apply border-b-0 pb-0;
    }
  }
  &__actions {
    @apply w-full;
  }
}
</style>
