<template>
  <div ref="animation"></div>
</template>

<script>
import lottie from 'lottie-web'
export default {
  name: 'LLAnimation',
  props: {
    animationName: { type: String, required: true }
  },
  computed: {
    animationData() {
      return require(`@/assets/lottie-reactions/animations/${this.animationName}`)
    }
  },
  mounted() {
    const animationEntity = lottie.loadAnimation({
      container: this.$refs.animation,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: this.animationData
    })
    animationEntity.addEventListener('complete', () => {
      this.$emit('complete')
    })
  }
}
</script>

<style scoped lang="scss"></style>
