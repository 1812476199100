<template>
  <div class="ForumChatThreadCardActions">
    <LLOptionsList v-if="showActions" class="ForumChatThreadCardActions__button">
      <template #trigger>
        <LLButton :loading="isActionPending" small :tertiary-white="dark" :tertiary-dark="!dark">
          <template #icon-left><OptionsIcon /></template>
        </LLButton>
      </template>
      <template #buttons>
        <LLOptionsListButton
          v-if="permissions.pin"
          v-popper-close
          small
          @click="updatePinnedState(!thread.isPinned)"
        >
          <template v-if="!thread.isPinned"> {{ $t('forum.thread.actions.pin') }}</template>
          <template v-else> {{ $t('forum.thread.actions.unpin') }}</template>
        </LLOptionsListButton>
        <router-link v-if="permissions.edit && thread.isActive" :to="linkToEditor">
          <LLOptionsListButton small> {{ $t('button_edit') }} </LLOptionsListButton>
        </router-link>
        <LLOptionsListButton
          v-if="
            permissions.edit &&
            (thread.state === forumThreadState.ARCHIVED || thread.state === forumThreadState.HIDDEN)
          "
          v-popper-close
          small
          @click="updateState(forumThreadState.ACTIVE)"
        >
          {{ $t('forum.thread.actions.activate') }}
        </LLOptionsListButton>
        <LLOptionsListButton
          v-if="
            permissions.edit &&
            (thread.state === forumThreadState.ACTIVE || thread.state === forumThreadState.HIDDEN)
          "
          v-popper-close
          small
          @click="updateState(forumThreadState.ARCHIVED)"
        >
          {{ $t('forum.thread.actions.archive') }}
        </LLOptionsListButton>
        <LLOptionsListButton
          v-if="
            permissions.edit &&
            (thread.state === forumThreadState.ACTIVE || thread.state === forumThreadState.ARCHIVED) &&
            false
          "
          v-popper-close
          small
          @click="updateState(forumThreadState.HIDDEN)"
        >
          {{ $t('forum.thread.actions.hide') }}
        </LLOptionsListButton>
        <LLOptionsListButton
          v-if="permissions.remove"
          v-popper-close
          small
          @click="isRemoveConfirmationOpened = true"
        >
          {{ $t('button_remove') }}
        </LLOptionsListButton>
      </template>
    </LLOptionsList>
    <portal to="confirm-modal">
      <LLConfirmModalTransition v-if="isRemoveConfirmationOpened">
        <LLConfirmModal :max-width="600">
          <template #title>{{ $t('forum.thread.remove_confirmation_modal.title') }}</template>
          <template #default>
            {{ $t('forum.thread.remove_confirmation_modal.message') }}
          </template>
          <template #actions>
            <LLButton :loading="isRemovePending" primary small @click="remove">{{
              $t('button_remove')
            }}</LLButton>
            <LLButton
              :disabled="isRemovePending"
              secondary
              small
              @click="isRemoveConfirmationOpened = false"
              >{{ $t('button_cancel') }}</LLButton
            >
          </template>
        </LLConfirmModal>
      </LLConfirmModalTransition>
    </portal>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLOptionsListButton from '@/components/common/LLOptionsList/LLOptionsListButton.vue'
import LLOptionsList from '@/components/common/LLOptionsList.vue'
import OptionsIcon from '@/assets/icons/OptionsIcon.vue'
import { ForumThreadModel } from '@/models/forum/forumThread'
import { ForumThreadState } from '@/common/enums'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'

export default {
  name: 'ForumChatThreadCardActions',
  components: {
    LLConfirmModal,
    LLConfirmModalTransition,
    OptionsIcon,
    LLOptionsList,
    LLOptionsListButton,
    LLButton
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    dark: { type: Boolean, default: false }
  },
  data() {
    return {
      isActionPending: false,
      isRemovePending: false,
      isRemoveConfirmationOpened: false
    }
  },
  computed: {
    linkToEditor() {
      return `/chats/${this.thread.chatId}/threads/${this.thread.id}/edit`
    },
    showActions() {
      return this.permissions.edit || this.permissions.pin || this.permissions.remove
    },
    permissions() {
      return {
        edit: true,
        pin: false,
        remove: true
      }
    },
    forumThreadState() {
      return ForumThreadState
    }
  },
  methods: {
    async updatePinnedState(isPinned) {
      this.isActionPending = true
      try {
        const { thread: serverThread } = await this.$api.forum.threads.update({
          chatId: this.thread.chatId,
          thread: {
            ...this.thread.convertToApi(),
            isPinned
          }
        })
        const thread = ForumThreadModel.parseFromApi({ thread: serverThread, chatId: this.thread.chatId })
        this.$emit('update', { thread })
      } catch (e) {
        console.log(e)
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.isActionPending = false
    },
    async updateState(newState) {
      this.isActionPending = true
      try {
        const { thread: serverThread } = await this.$api.forum.threads.update({
          chatId: this.thread.chatId,
          thread: {
            ...this.thread.convertToApi(),
            state: newState
          }
        })
        const thread = ForumThreadModel.parseFromApi({ thread: serverThread, chatId: this.thread.chatId })
        this.$emit('update', { thread })
      } catch (e) {
        console.log(e)
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.isActionPending = false
    },
    async remove() {
      this.isRemovePending = true
      try {
        await this.$api.forum.threads.delete({
          chatId: this.thread.chatId,
          threadId: this.thread.id
        })
        this.isRemoveConfirmationOpened = false
        this.$emit('remove')
      } catch (e) {
        this.$toast.error(this.$getErrorMessage(e))
      }
      this.isRemovePending = false
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardActions {
}
</style>
