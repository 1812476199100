<template>
  <svg
    class="icon fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.25 14.5705C11.2495 15.1009 11.2486 16.0923 14.025 16.4625C17.25 16.95 17.25 19.5 17.25 19.5V21H0.75V19.5C0.75 19.5 1.2375 16.875 4.0125 16.4625C6.7875 16.05 6.7875 15.1125 6.7875 14.55V13.65C5.775 12.75 5.25 11.2875 5.25 9.6V6.975C5.25 4.125 7.1625 3 9 3C10.8375 3 12.75 4.125 12.75 6.975V9.6C12.75 11.2875 12.2625 12.7875 11.25 13.6875V14.55C11.25 14.5567 11.25 14.5636 11.25 14.5705ZM18.3 12.4507C18.3 12.9007 18.3 13.5757 20.5875 14.0257C23.25 14.5132 23.25 16.5007 23.25 16.5007V17.9257H18.4125C17.925 16.6507 16.6875 15.2632 14.2125 14.9257C13.7065 14.8892 13.2111 14.7622 12.75 14.5507V14.2882L13.0875 13.8007L13.5 13.6882C14.6625 13.2757 14.6625 12.7882 14.6625 12.4132V11.7007L14.2125 10.2382C14.25 10.0507 14.25 9.82567 14.25 9.60067V9.60066V6.97566C14.2571 6.12613 14.0912 5.28405 13.7625 4.50066C13.924 4.2322 14.1125 3.9809 14.325 3.75066C14.9382 3.25077 15.709 2.98497 16.5 3.00066C18 3.00066 19.6875 3.90066 19.6875 6.22566V8.40066C19.7041 9.02328 19.5891 9.6424 19.3501 10.2175C19.111 10.7927 18.7531 11.3108 18.3 11.7382V12.4507Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ParticipantsIcon'
}
</script>
