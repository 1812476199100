<template>
  <div class="SearchContent">
    <SearchEmpty
      v-if="!searchCategory && !study && !chat"
      @updateStartFilters="updateStartFilters"
    ></SearchEmpty>
    <SearchResponses
      v-if="searchCategory === SearchCategories.RESPONSES"
      ref="search-content-responses"
      :search-value="searchValue"
      :study="study"
      :chat="chat"
      :search-results="searchResults"
      :pending-results="pendingResults"
      :total="total"
      :init-filters="initFilters"
      @updateStartFilters="updateStartFilters"
      @search="search"
    ></SearchResponses>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import { SearchCategories } from '@/common/enums'
import SearchResponses from '@/components/pages/search/SearchModule/SearchContent/SearchResponses'
import SearchEmpty from '@/components/pages/search/SearchModule/SearchContent/SearchEmpty'
import { ForumChatModel } from '@/models/forum/forumChat'

export default {
  name: 'SearchContent',
  components: { SearchEmpty, SearchResponses },
  props: {
    study: { type: [StudyModel, null], default: null },
    chat: { type: [ForumChatModel, null], default: null },
    searchCategory: { type: Number, default: SearchCategories.RESPONSES },
    searchValue: { type: String, default: null },
    pendingResults: { type: Boolean, default: false },
    searchResults: { type: Array, default: () => {} },
    total: { type: Number, default: 0 }
  },
  data() {
    return {
      initFilters: {}
    }
  },
  computed: {
    SearchCategories() {
      return SearchCategories
    }
  },
  methods: {
    search(payload) {
      this.$emit('search', payload)
    },
    updateStartFilters(filters) {
      this.initFilters = filters
    }
  }
}
</script>
