<template>
  <div class="SearchContentContainer">
    <div class="SearchContentContainer__filters">
      <slot name="filters"></slot>
    </div>
    <div class="SearchContentContainer__results" :class="resultsClasses">
      <slot v-if="!resultsLoaded" name="before-results-load"></slot>
      <LoadingIcon v-if="!resultsLoaded && pending"></LoadingIcon>
      <slot v-if="resultsLoaded" name="results"></slot>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
export default {
  name: 'SearchContentContainer',
  components: { LoadingIcon },
  props: {
    pending: { type: Boolean, default: false }
  },
  data() {
    return {
      resultsLoaded: false
    }
  },
  computed: {
    resultsClasses() {
      const classes = []
      const rootClass = 'SearchContentContainer__results'
      if (this.pending && this.resultsLoaded) {
        classes.push(`${rootClass}_pending`)
      }
      return classes
    }
  },
  watch: {
    pending() {
      if (!this.pending) {
        this.resultsLoaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchContentContainer {
  @apply flex flex-col;
  @screen md {
    @apply flex-row;
  }
  &__filters {
    @apply flex-shrink-0 w-full border border-neutral-01-50 rounded p-4  self-start;
    @screen md {
      max-width: 285px;
    }
    @screen lg {
      max-width: 340px;
    }
  }
  &__results {
    @apply min-w-0 mt-6;
    @screen md {
      @apply ml-6 mt-0 flex-1;
    }
    @screen lg {
      @apply ml-14;
    }
    &_pending {
      @apply opacity-50 pointer-events-none;
    }
  }
}
</style>
