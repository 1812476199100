<template>
  <svg width="24" height="24" class="fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" />
    <path
      d="M16.8125 11.125H12.875V7.1875C12.875 6.94578 12.6792 6.75 12.4375 6.75H11.5625C11.3208 6.75 11.125 6.94578 11.125 7.1875V11.125H7.1875C6.94578 11.125 6.75 11.3208 6.75 11.5625V12.4375C6.75 12.6792 6.94578 12.875 7.1875 12.875H11.125V16.8125C11.125 17.0542 11.3208 17.25 11.5625 17.25H12.4375C12.6792 17.25 12.875 17.0542 12.875 16.8125V12.875H16.8125C17.0542 12.875 17.25 12.6792 17.25 12.4375V11.5625C17.25 11.3208 17.0542 11.125 16.8125 11.125Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusRoundedSolidIcon'
}
</script>

<style lang="scss" scoped>
.PlusRoundedFilledIcon {
}
</style>
