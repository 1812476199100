<template>
  <div id="app">
    <router-view v-if="ready" />
    <notifications group="main" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      ready: false
    }
  },
  async mounted() {
    try {
      if (this.$store.state.auth.token) {
        await this.$store.dispatch('getAppData')
        await this.$store.dispatch('user/fetchUser')
      }
    } catch (e) {
      this.$errorAlert('Error while loading app data')
    }
    this.ready = true

    setInterval(() => {
      //this.$store.commit('INCREMENT_CURRENT_TIME')
    }, 1000)
  },
  methods: {}
}
</script>

<style lang="scss"></style>
