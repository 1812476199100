<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.46237 1.3562C3.9042 1.3562 4.26237 1.71437 4.26237 2.1562V3.97121H6.07739C6.51921 3.97121 6.87739 4.32939 6.87739 4.77121C6.87739 5.21304 6.51921 5.57121 6.07739 5.57121H4.26237V17.8463C4.26237 18.2881 3.9042 18.6463 3.46237 18.6463C3.02055 18.6463 2.66237 18.2881 2.66237 17.8463V5.57121H0.847363C0.405535 5.57121 0.0473633 5.21304 0.0473633 4.77121C0.0473633 4.32939 0.405535 3.97121 0.847363 3.97121H2.66237V2.1562C2.66237 1.71437 3.02055 1.3562 3.46237 1.3562ZM9.9999 1.3562C10.4417 1.3562 10.7999 1.71437 10.7999 2.1562V14.4313H12.6149C13.0567 14.4313 13.4149 14.7894 13.4149 15.2313C13.4149 15.6731 13.0567 16.0313 12.6149 16.0313H10.7999V17.8463C10.7999 18.2881 10.4417 18.6463 9.9999 18.6463C9.55808 18.6463 9.1999 18.2881 9.1999 17.8463V16.0313H7.38489C6.94306 16.0313 6.58489 15.6731 6.58489 15.2313C6.58489 14.7894 6.94306 14.4313 7.38489 14.4313H9.1999V2.1562C9.1999 1.71437 9.55808 1.3562 9.9999 1.3562ZM16.5374 1.3562C16.9793 1.3562 17.3374 1.71437 17.3374 2.1562V9.20124H19.1524C19.5943 9.20124 19.9524 9.55941 19.9524 10.0012C19.9524 10.4431 19.5943 10.8012 19.1524 10.8012H17.3374V17.8463C17.3374 18.2881 16.9793 18.6463 16.5374 18.6463C16.0956 18.6463 15.7374 18.2881 15.7374 17.8463V10.8012H13.9224C13.4806 10.8012 13.1224 10.4431 13.1224 10.0012C13.1224 9.55941 13.4806 9.20124 13.9224 9.20124H15.7374V2.1562C15.7374 1.71437 16.0956 1.3562 16.5374 1.3562Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon'
}
</script>
