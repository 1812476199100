import { FileModel } from './file'

export class ConversationMessageFileModel extends FileModel {
  constructor(file) {
    const { questionId, prospectId, messageId, meta, studyId, moderatorNotes } = file
    super(file)
    this.questionId = questionId
    this.prospectId = prospectId
    this.studyId = studyId
    this.messageId = messageId
    this.moderatorNotes = moderatorNotes
    this.meta = meta
  }

  static parseFromApi({ file, prospectId, questionId, messageId, studyId, moderatorNotes }) {
    return new ConversationMessageFileModel({
      ...FileModel.parseFromApi({ file }),
      questionId,
      prospectId,
      studyId,
      messageId,
      moderatorNotes,
      meta: file?.meta
    })
  }
}
