import Network from '../../network.js'
export default () => ({
  createResult({ chatId, threadId, pollResponse }) {
    return Network.post(`/forum-chats/${chatId}/threads/${threadId}/poll-results`, { ...pollResponse }, true)
  },
  getResults({ chatId, threadId, filter, filterValues }) {
    return Network.getWithConfig(
      `/forum-chats/${chatId}/threads/${threadId}/poll-results`,
      {
        params: { filter, value: filterValues }
      },
      true
    )
  },
  upateResult({ chatId, threadId, updateBody }) {
    const response = updateBody?.response
    return Network.put(`/forum-chats/${chatId}/threads/${threadId}/poll-results`, { ...response }, true)
  },
  deleteResult({ chatId, threadId }) {
    return Network.delete(`/forum-chats/${chatId}/threads/${threadId}/poll-results`, null, true)
  }
})
