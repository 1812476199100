import { BaseModel } from '@/models/baseModel'

const StudyConversationMessageType = {
  ANSWER: 10,
  COMMENT: 20,
  NEW_COMMENT: 30,
  SCREENING: 40
}

const StudyConversationMessageFileSize = {
  SMALL: 10,
  LARGE: 20
}

export class StudyConversationMessageOptionsModel extends BaseModel {
  constructor(options = {}) {
    super()
    const {
      isAnswer,
      highlightSerachText,
      type,
      allowToEdit,
      allowToRemove,
      elementsVisibility,
      fileSliderSize,
      isHighlighterEnabled
    } = options || {}

    this.isAnswer = isAnswer ?? false
    this.type = type || StudyConversationMessageType.NEW_COMMENT

    this.allowToEdit = allowToEdit ?? false
    this.allowToRemove = allowToRemove ?? false

    this.isHighlighterEnabled = isHighlighterEnabled ?? true

    this.elementsVisibility = {
      participantAvatar: elementsVisibility?.participantAvatar ?? true,
      header: elementsVisibility?.header ?? true,
      edited: elementsVisibility?.edited ?? true,
      repliedTime: elementsVisibility?.repliedTime ?? true,
      translation: elementsVisibility?.translation ?? true,
      transcription: elementsVisibility?.transcription ?? true,
      images: elementsVisibility?.images ?? true,
      videos: elementsVisibility?.videos ?? true,
      actions: elementsVisibility?.actions ?? true
    }

    this.fileSliderSize = fileSliderSize ?? StudyConversationMessageFileSize.SMALL
    this.highlightSerachText = highlightSerachText || null
  }
}

export { StudyConversationMessageType, StudyConversationMessageFileSize }
