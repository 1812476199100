<template>
  <div class="LLTable" :class="tableClasses" :style="tableStyle">
    <div v-if="selectedSomeRows" class="LLTable__selection">
      <div class="LLTable__selection-cell">
        <div class="LLTable__selection-inner">
          <slot name="selection-some" :selected-values="selectedSlotProps">
            Selected {{ selectedSlotProps.selected }} items of the list
          </slot>
        </div>
      </div>
    </div>
    <div v-if="selectedAllButHiddenRows" class="LLTable__selection">
      <div class="LLTable__selection-cell">
        <div class="LLTable__selection-inner">
          <slot name="selection-all-visible" :selected-values="selectedSlotProps">
            Selected {{ selectedSlotProps.selected }} loaded items of the list.
            <span @click="selection.allHiddenSelected = true"> Select {{ selectedSlotProps.all }} items </span
            >in this table?
          </slot>
        </div>
      </div>
    </div>
    <div v-if="selectedAllAndHiddenRows" class="LLTable__selection">
      <div class="LLTable__selection-cell">
        <div class="LLTable__selection-inner">
          <slot name="selection-all" :selected-values="selectedSlotProps"
            >Selected all {{ selectedSlotProps.all }} items</slot
          >
        </div>
      </div>
    </div>
    <div v-if="slots.additionalRow" class="LLTable__selection">
      <div class="LLTable__selection-cell">
        <div class="LLTable__selection-inner">
          <slot name="additional-row"></slot>
        </div>
      </div>
    </div>
    <div ref="table-wrapper" class="LLTable__container" @scroll="scrollEvent">
      <draggable
        :value="localRows"
        :disabled="!draggable"
        v-bind="dragOptions"
        handle=".row-handler"
        tag="table"
        class="LLTable__table"
        @start="onDragStart"
        @end="onDragEnd"
      >
        <thead class="ignore-draggable">
          <tr>
            <th v-if="draggable" class="LLTable__header-handler"></th>
            <LLTableColumn v-if="expandable">
              <template #table-column></template>
            </LLTableColumn>
            <LLTableColumn v-if="selectionEnabled && $slots['all-checkbox']">
              <template #table-column>
                <slot name="all-checkbox"></slot>
              </template>
            </LLTableColumn>
            <LLTableColumn v-if="selectionEnabled && !$slots['all-checkbox']">
              <template #table-column>
                <div>
                  <LLCheckbox
                    :disabled="selectedAllCheckboxDisabled"
                    :model-value="selectedAllVisible"
                    without-margin
                    data-e2e="rowCheckBox"
                    @change="selectAllEvent"
                  ></LLCheckbox>
                </div>
              </template>
            </LLTableColumn>
            <LLTableColumn
              v-for="column in columns"
              :key="column.field"
              :sorting="sorting.column === column"
              :sorting-type="sorting.type"
              :column="column"
              @click.native="columnClick(column)"
            >
              <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot :name="slot" v-bind="scope" />
              </template>
            </LLTableColumn>
          </tr>
        </thead>
        <tbody
          v-for="lr in localRows"
          :key="lr.key"
          class="LLTable__row-tbody draggable"
          :class="rowTbodyClasses"
        >
          <LLTableRow
            :zebra="zebra"
            :draggable="draggable"
            :expandable="expandable"
            :no-padding="noRowPadding"
            :selection-enabled="selectionEnabled"
            :columns="columns"
            :expanded="isRowExpanded(lr)"
            :row="lr.originalRow"
            :row-key="lr.key"
            :selected="isRowSelected(lr)"
            @updateSelection="updateSelection($event, lr)"
            @updateExpanded="updateExpanded($event, lr)"
          >
            <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </LLTableRow>
          <slot
            v-if="isRowExpanded(lr)"
            name="table-expanded-row"
            :row="lr.originalRow"
            :columns-num="columnsNum"
          />
        </tbody>
        <tr
          v-if="!paginationFinished"
          ref="pagination-helper"
          class="LLTable__pagination-loading ignore-draggable"
        >
          <td class="LLTable__pagination-loading-inner" :colspan="columnsNum">
            {{ $t('label_loading') }}
          </td>
        </tr>
        <tr v-if="!rows.length" class="LLTable__emptystate ignore-draggable">
          <td class="LLTable__emptystate-inner" :colspan="columnsNum">
            <div class="LLTable__emptystate-inner-inner">
              <slot name="emptystate"></slot>
            </div>
          </td>
        </tr>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { v4 as uuidV4 } from 'uuid'
import LLTableRow from '@/components/common/table/LLTable/LLTableRow'
import LLTableColumn from '@/components/common/table/LLTable/LLTableColumn'
import LLCheckbox from '@/components/common/ui-components/LLCheckbox2.vue'
export default {
  name: 'LLTable',
  components: { LLCheckbox, LLTableColumn, LLTableRow, draggable },
  props: {
    rows: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    selectedKeys: { type: Array, default: () => [] },
    selectionEnabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    paginationLoading: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false },
    totalRows: { type: Number, default: 0 },
    maxHeight: { type: Number, default: null },
    fixedFirstRow: { type: Boolean, default: false },
    allSelected: { type: Boolean, default: false },
    paginationEnabled: { type: Boolean, default: false },
    allowSelect: { type: Boolean, default: false },
    zebra: { type: Boolean, default: true },
    noRowPadding: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    expandable: { type: Boolean, default: false },
    expandAll: { type: Boolean, default: false },
    fixScrollTopAfterUpdate: { type: Boolean, default: true }
  },
  data() {
    return {
      drag: false,
      sorting: {
        column: null,
        type: null
      },
      selection: {
        selectedKeys: [],
        allHiddenSelected: false
      },
      tableWidth: null,
      slots: {
        additionalRow: false
      },
      expandedKeys: {}
    }
  },
  computed: {
    rowTbodyClasses() {
      const classes = []
      const rootClass = 'LLTable__row-tbody'
      if (this.zebra) {
        classes.push(`${rootClass}_zebra`)
      }
      return classes
    },
    tableActionStyle() {
      return {
        width: this.tableWidth ? `${this.tableWidth}px` : '`100%'
      }
    },
    dragOptions() {
      return {
        animation: 200,
        ghostClass: 'ghost',
        draggable: '.draggable',
        filter: '.ignore-draggable'
      }
    },
    selectedSlotProps() {
      return {
        all: this.totalRows || this.rows.length,
        selected: this.selection.selectedKeys.length
      }
    },
    paginationFinished() {
      return this.rows.length >= this.totalRows || !this.totalRows || !this.paginationEnabled
    },
    localColumns() {
      return this.columns.map((c) => ({
        field: c.field,
        label: c?.label,
        sortable: !!c?.sortable,
        formatFn: c?.formatFn,
        sortFn: c?.sortFn,
        originalColumn: c
      }))
    },
    localRows() {
      const modifyRow = (r) => {
        const modifiedRow = {}
        for (const [key, value] of Object.entries(r)) {
          const column = this.columns.find((c) => c.field === key)
          if (column?.formatFn) {
            modifiedRow[key] = column.formatFn(value)
          } else {
            modifiedRow[key] = value
          }
        }
        return modifiedRow
      }
      const sortFn = this.localColumns.find((lc) => lc.field === this.sorting?.column?.field)?.sortFn
      let localRows = this.rows.map((r) => ({
        formattedRow: modifyRow(r.data),
        originalRow: r.data,
        key: r.key
      }))
      if (this.sorting.column && sortFn) {
        localRows = [...localRows].sort((lc1, lc2) => {
          return (
            sortFn(lc1.formattedRow, lc2.formattedRow, lc1.originalRow, lc2.originalRow) *
            (this.sorting?.type === 'desc' ? 1 : -1)
          )
        })
      }
      return localRows
    },
    tableStyle() {
      const styles = []
      if (this.maxHeight) {
        styles.push({ maxHeight: `${this.maxHeight}px` })
      }
      return styles
    },
    tableClasses() {
      const classes = []
      if (this.fixedHeader) {
        classes.push('LLTable_fixed-header')
      }
      if (this.isLoading) {
        classes.push('LLTable_loading')
      }
      return classes
    },
    columnsNum() {
      return (
        this.columns.length +
        (this.selectionEnabled ? 1 : 0) +
        (this.draggable ? 1 : 0) +
        (this.expandable ? 1 : 0)
      )
    },
    selectedAllVisible() {
      return this.rows.length === this.selection.selectedKeys.length && !!this.rows.length
    },
    selectedAllCheckboxDisabled() {
      return !this.rows.length
    },
    allRowsLoaded() {
      return this.rows.length === this.totalRows || !this.totalRows
    },
    selectedSomeRows() {
      return this.selection.selectedKeys.length && !this.selectedAllVisible
    },
    selectedAllButHiddenRows() {
      return this.selectedAllVisible && !this.allRowsLoaded && !this.selection.allHiddenSelected
    },
    selectedAllAndHiddenRows() {
      return (this.selectedAllVisible && this.allRowsLoaded) || this.selection.allHiddenSelected
    },
    keys() {
      return this.rows.map((row) => row.key)
    }
  },
  watch: {
    keys: {
      handler(to, from) {
        if (!this.expandAll) {
          return
        }
        const questionKeysDifference = this.$_.difference(to, from)
        if (questionKeysDifference?.length) {
          questionKeysDifference.forEach((key) => {
            this.$set(this.expandedKeys, key, true)
          })
        }
      }
    },
    expandAll: {
      handler() {
        if (this.expandAll) {
          const keys = {}
          this.localRows.forEach((lr) => (keys[lr.key] = true))
          this.expandedKeys = keys
        } else {
          this.expandedKeys = {}
        }
      },
      immediate: true
    },
    expandable: {
      handler() {
        if (!this.expandable) this.expandedKeys = {}
      },
      immediate: true
    },
    'selection.allHiddenSelected'(value) {
      this.$emit('update:allSelected', value)
    },
    allSelected: {
      immediate: true,
      handler(value) {
        this.selection.allHiddenSelected = value
      }
    },
    selectedKeys() {
      if (this.selection.allHiddenSelected) {
        this.selection.allHiddenSelected = false
      }
      this.selection.selectedKeys = [...this.selectedKeys]
    },
    rows() {
      if (this.selection.allHiddenSelected) {
        this.clearSelection()
        this.selectAllEvent(true)
        this.$nextTick(() => {
          this.selection.allHiddenSelected = true
        })
      }
      this.scrollEvent()
    }
  },
  mounted() {
    this.initTableCounting()
    this.checkSlots()
  },
  beforeUpdate() {
    this.checkSlots()
  },
  destroyed() {
    this.destroyTableCounting()
  },
  updated() {
    if (this.fixScrollTopAfterUpdate) {
      this.fixScrollTop()
    }
  },
  methods: {
    fixScrollTop() {
      const wrapper = this.$refs['table-wrapper']
      if (!wrapper) {
        return
      }
      wrapper.scrollTop = wrapper.scrollTop > 1 ? wrapper.scrollTop - 1 : 0
    },
    updateExpanded(value, localRow) {
      if (this.expandedKeys?.[localRow.key] && !value) {
        this.$delete(this.expandedKeys, localRow.key)
      } else if (!this.expandedKeys?.[localRow.key] && value) {
        this.$set(this.expandedKeys, localRow.key, value)
      }
    },
    onDragStart() {
      this.drag = true
    },
    onDragEnd(data) {
      this.drag = false
      data.newIndex = data.newDraggableIndex
      data.oldIndex = data.oldDraggableIndex
      this.$emit('orderEvent', data)
    },
    checkSlots() {
      this.slots.additionalRow = !!this.$slots?.['additional-row']?.[0]
    },
    isRowSelected(row) {
      return !!this.selection.selectedKeys.find((r) => r === row.key)
    },
    isRowExpanded(row) {
      return this.expandedKeys?.[row.key]
    },
    updateSelection(value, row) {
      if (this.selection.selectedKeys.find((sl) => sl === row.key)) {
        this.selection.selectedKeys.splice(this.selection.selectedKeys.indexOf(row.key), 1)
      } else {
        this.selection.selectedKeys.push(row.key)
      }
      this.updateSelectionEvent()
    },
    clearSelection() {
      this.selection.selectedKeys.splice(0, this.selection.selectedKeys.length)
      this.updateSelectionEvent()
    },
    updateSelectionEvent() {
      this.$emit('update:selectedKeys', [...this.selection.selectedKeys])
    },
    selectAllEvent(value) {
      this.clearSelection()
      if (value) {
        this.rows.forEach((r) => {
          this.selection.selectedKeys.push(r.key)
        })
      }
      this.updateSelectionEvent()
    },
    initTableCounting() {
      this.countTable()
      window.addEventListener('resize', this.countTable)
    },
    destroyTableCounting() {
      window.removeEventListener('resize', this.countTable)
    },
    countTable() {
      this.tableWidth = this.$refs['table-wrapper'].offsetWidth
    },
    scrollEvent() {
      const tableWrapper = this.$refs['table-wrapper']
      this.$nextTick(() => {
        const paginationHelper = this.$refs['pagination-helper']
        if (!paginationHelper || this.paginationLoading || this.isLoading) return
        if (tableWrapper.scrollTop + tableWrapper.offsetHeight - 50 > paginationHelper.offsetTop) {
          this.$emit('paginationEvent')
        }
      })
    },
    getRowKey(row) {
      if (row.key) {
        return row.key
      } else {
        return uuidV4()
      }
    },
    columnClick(column) {
      if (this.isLoading || this.paginationLoading) {
        return
      }
      if (column.sortable) {
        if (this.sorting.column === column) {
          this.sorting.type = this.sorting.type === 'desc' ? 'asc' : 'desc'
        } else {
          this.sorting.column = column
          this.sorting.type = 'asc'
        }
        this.clearSelection()
        this.$emit('sortEvent', {
          field: column.field,
          type: this.sorting.type
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTable {
  $root: &;
  @apply flex flex-col;
  &__container {
    @apply overflow-x-auto w-full flex-1;
  }
  &__table {
    @apply w-full;
  }
  &_fixed-header {
    position: relative;
    th {
      height: 52px;
      top: 0;
      @apply z-10 sticky top-0;
    }
    tbody {
      .LLTable__data-row {
        position: relative;
      }
    }
  }
  &_loading {
    tbody {
      filter: blur(10px);
      @apply pointer-events-none;
    }
    #{$root}__emptystate-inner {
      filter: blur(10px);
      @apply pointer-events-none;
    }
  }
  tbody {
  }
  &__selection {
    @apply flex-shrink-0;
    &-cell {
      @apply w-full bg-primary-01-400 z-20 text-white;
    }
    &-inner {
      @apply flex items-center justify-center;
    }
  }
  &__pagination-loading {
    &-inner {
      @apply w-full text-center items-center justify-center py-4;
    }
  }
  &__header-handler {
    @apply w-0;
  }
  &__emptystate {
    height: 300px;
    &-inner {
      @apply absolute w-full left-0;
      &-inner {
        @apply sticky w-full left-0;
      }
    }
  }
  &__row-tbody {
    &:not([draggable='true']) {
      transition: background-color 0.2s;
    }
    @apply border-b border-neutral-01-50;
    &:last-child {
      @apply border-b-0;
    }
    &_zebra {
      &:nth-child(even) {
        @apply bg-neutral-01-15;
      }
    }
    &:hover {
      @apply bg-primary-01-25;
    }
  }
}
</style>
