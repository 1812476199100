<template>
  <div class="PollEditor">
    <LLInputLocked dark-caption>
      <template #caption>{{ $t('forum.poll.editor.type') }}</template>

      <LLInputSelect
        v-model="pollType"
        :key-fn="(type) => type.value"
        :caption-fn="(type) => $t(`forum.poll.type.${type.label}`)"
        :reduce="(type) => type.value"
        :disabled="isPollCreated"
        :values="typeList"
        :max-height="500"
        :placeholder="$t('select_placeholder')"
        class="PollEditor__type-selector"
      >
      </LLInputSelect>
    </LLInputLocked>
    <PollEditorMultiple
      v-if="pollType === PollTypeEnum.MULTIPLE_CHOICE || pollType === PollTypeEnum.RANKED_LIST"
      class="PollEditor__editor-section"
      :poll.sync="localPoll"
    ></PollEditorMultiple>
    <PollEditorMatrix
      v-if="pollType === PollTypeEnum.MATRIX"
      class="PollEditor__editor-section"
      :poll.sync="localPoll"
    ></PollEditorMatrix>
    <PollEditorNumber
      v-if="pollType === PollTypeEnum.NUMBER_ENTRY"
      class="PollEditor__number-section"
      :poll.sync="localPoll"
    ></PollEditorNumber>
  </div>
</template>

<script>
import LLInputSelect from '@/components/common/LLInput/LLInputSelect.vue'
import { PollType } from '@/common/enums'
import { PollModel } from '@/models/poll'
import PollEditorMultiple from '@/components/pages/forum/thread/poll/editor/PollEditorMultiple.vue'
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import PollEditorMatrix from '@/components/pages/forum/thread/poll/editor/PollEditorMatrix.vue'
import PollEditorNumber from '@/components/pages/forum/thread/poll/editor/PollEditorNumber.vue'

export default {
  name: 'PollEditor',
  components: {
    PollEditorNumber,
    PollEditorMatrix,
    LLInputLocked,
    PollEditorMultiple,
    LLInputSelect
  },
  props: {
    poll: { type: PollModel, default: null }
  },
  data() {
    return {
      localPoll: new PollModel()
    }
  },
  computed: {
    isPollCreated() {
      return !!this.poll?.id
    },
    PollTypeEnum() {
      return PollType
    },
    pollType: {
      get() {
        return this.localPoll?.type !== null ? this.localPoll.type : 'null'
      },
      set(type) {
        this.localPoll.type = type === 'null' ? null : type
      }
    },
    typeList() {
      const typeList = Object.keys(PollType).map((key) => ({
        label: key.toLowerCase(),
        value: PollType[key]
      }))
      typeList.unshift({ label: 'none', value: 'null' })
      return typeList
    }
  },
  watch: {
    poll: {
      handler() {
        if (!this.$_.isEqual(this.poll, this.localPoll)) {
          this.localPoll = new PollModel(this.poll)
        }
      },
      immediate: true,
      deep: true
    },
    localPoll: {
      handler() {
        this.$emit('update:poll', new PollModel(this.localPoll))
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.PollEditor {
  &__type-selector {
    @apply w-full;
    max-width: 400px;
  }
  &__editor-section {
    @apply mt-8;
  }
  &__number-section {
    @apply mt-4;
  }
}
</style>
