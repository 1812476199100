<template>
  <LLButton
    class="StudyConversationStreamAddButton"
    primary-transparent
    small
    no-x-padding
    @click="isNewMessageExpanded = !isNewMessageExpanded"
  >
    <template #icon-left>
      <AddCircleIcon
        v-if="!isNewMessageExpanded"
        class="StudyConversationStreamAddButton__icon"
      ></AddCircleIcon>
      <AddCircleFalseIcon v-else class="StudyConversationStreamAddButton__icon"></AddCircleFalseIcon>
    </template>
    {{ $t('conversation_stream_add_more') }}
  </LLButton>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import AddCircleFalseIcon from '@/assets/icons/AddCircleFalseIcon.vue'
import AddCircleIcon from '@/assets/icons/AddCircleIcon.vue'

export default {
  name: 'StudyConversationStreamAddButton',
  components: { AddCircleIcon, AddCircleFalseIcon, LLButton },
  props: {
    expanded: { type: Boolean, default: false }
  },
  data() {
    return {
      isNewMessageExpanded: false
    }
  },
  watch: {
    expanded: {
      handler() {
        this.isNewMessageExpanded = this.expanded
      },
      immediate: true
    },
    isNewMessageExpanded() {
      this.$emit('update:expanded', this.isNewMessageExpanded)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationStreamAddButton {
  &__icon {
    @apply w-5 h-5;
  }
}
</style>
