const globalRoutes = {
  get loginPage() {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.VUE_APP_DEV_HOST}:${process.env.VUE_APP_DEV_LOGIN_PORT}`
    } else {
      return `${process.env.VUE_APP_HOST_LOGIN_PAGE}`
    }
  },
  get api() {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.VUE_APP_DEV_HOST}:${process.env.VUE_APP_DEV_API_PORT}`
    } else {
      return process.env.VUE_APP_API_HOST
    }
  },
  get admin() {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.VUE_APP_DEV_HOST}:${process.env.VUE_APP_DEV_ADMIN_PORT}`
    } else {
      return `${process.env.VUE_APP_ADMIN_PROTOCOL}://${process.env.VUE_APP_ADMIN_HOST}`
    }
  },
  get pwa() {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.VUE_APP_DEV_HOST}:${process.env.VUE_APP_DEV_PWA_PORT}/#`
    } else {
      return `${process.env.HOST_PARTICIPANT_APP}`
    }
  }
}

export default globalRoutes
