<template>
  <LLPage class="Dashboard">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #content>
      <UploadProgress></UploadProgress>
      <DashboardPage />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'
import UploadProgress from '@/components/common/UploadProgress'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import { screenCatcher } from '@/mixins/screenCatcher'
import DashboardPage from '@/components/pages/dashboard/DashboardPage'
export default {
  name: 'MainDashboard',
  components: { LLBreadCrumbs, DashboardPage, LLPage, UploadProgress },
  mixins: [screenCatcher],
  computed: {
    crumbs() {
      return [{ name: this.$t('main_home_label') }]
    }
  }
}
</script>

<style lang="scss" scoped>
.Dashboard {
}
</style>
