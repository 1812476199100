import router from '@/router'
import store from '@/store'
import studyState from '@/plugins/studyState'
import { ProspectModel } from '@/models/prospect'

let checkForDataCollected = () => {
  const user = ProspectModel.parseFromApi({ prospect: store.getters['user/user'] })
  return !user.requireRegistration
}

const Routes = function () {
  this.push = () => {}
  this.goToStudy = async ({ studyId, forceScreening = false, forceDetails = false }) => {
    if (!checkForDataCollected()) {
      await this.goToCompleteProfile()
    }
    try {
      await store.dispatch('studies/fetchStudy', { studyId })
    } catch (e) {
      console.log(e)
    }
    let study = store.getters['studies/getStudyById'](studyId)
    if (!study) {
      store.commit('completeRegistration/SET_DESTINATION_DASHBOARD')
      await this.push({
        path: `/404`
      })
      return
    }
    if (studyState.isInvited) forceScreening = true
    if (
      !studyState.isInvited(study) &&
      (forceDetails || studyState.isWaiting(study) || studyState.isPendingApproval(study))
    ) {
      await this.push({
        path: `/study/${studyId}/details`
      })
    } else if (studyState.isLive(study)) {
      await this.push({
        path: `/study/${studyId}`
      })
    } else if (studyState.isInvited(study)) {
      if (forceScreening && study.screeningQuestions.length) {
        await this.push({
          path: `/study/${studyId}/screening`
        })
      } else {
        await this.push({
          path: `/study/${studyId}/details`
        })
      }
    } else {
      await this.goToDashboard()
    }
    store.commit('completeRegistration/RESET_DESTINATION')
  }
  this.goToCompleteProfile = async () => {
    if (checkForDataCollected()) {
      await this.goToDashboard()
    } else {
      await this.push({
        path: '/complete-profile'
      })
    }
  }
  this.goToLogout = async () => {
    await this.push({
      name: 'logout'
    })
  }
  this.goToSignIn = async ({ preloadedEmail = '' } = {}) => {
    if (store.getters['auth/isAuthorised']) {
      await this.goToDashboard()
    } else {
      await this.push({
        name: 'sign-in',
        params: {
          preloadedEmail
        }
      })
    }
  }
}

export const rootRoute = function ({ to, next }) {
  let routes = new Routes()
  routes.push = (newRoute) => {
    if (to.path === newRoute.path || (to.name && newRoute.name && to.name === newRoute.name)) {
      next()
    } else {
      next(newRoute)
    }
  }
  return routes
}

export default function () {
  let routes = new Routes()
  routes.push = async (newRoute) => {
    await router.push(newRoute)
  }
  return routes
}
