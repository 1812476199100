<template>
  <div class="InfiniteScrollObserver"></div>
</template>

<script>
export default {
  name: 'InfiniteScrollObserver',
  props: {
    options: { type: Object, default: () => ({}) },
    rootMargin: { type: String, default: '0px' },
    threshold: { type: [Number, String], default: 1 }
  },
  data: () => ({
    observer: null
  }),
  mounted() {
    const options = this.options || {
      rootMargin: this.rootMargin,
      threshold: this.threshold
    }
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      } else if (entry && !entry.isIntersecting) {
        this.$emit('intersectOut')
      }
    }, options)

    this.observer.observe(this.$el)
  },
  destroyed() {
    this.observer.disconnect()
  }
}
</script>
