<template>
  <div class="LLTextarea" :class="inputClasses">
    <LLInputCaption v-if="showCaption" :disabled="disabled"><slot name="caption"></slot></LLInputCaption>
    <div class="LLTextarea__container">
      <div class="LLTextarea__header">
        <LLTextareaMenuBar
          v-if="!hideMenuBar && editor"
          :disabled="disabled"
          class="LLTextarea__top-menubar"
          :editor="editor"
        >
          <template #additional-actions> <slot name="additional-actions"></slot> </template>
        </LLTextareaMenuBar>
        <LLPopper
          v-if="!hideEmoji"
          :show.sync="menuStates.emojiTop"
          :offset="[0, 0]"
          tooltip-white
          :disabled="disabled"
          placement="top-start"
        >
          <template #trigger>
            <LLButton :disabled="disabled" :active="menuStates.emojiTop" tertiary small>
              <template #icon-left>
                <EmojiIcon />
              </template>
            </LLButton>
          </template>
          <template #tooltip>
            <LLTextareaEmoji @select="onEmojiSelect" />
          </template>
        </LLPopper>
      </div>
      <div class="LLTextarea__input-wrapper">
        <div class="LLTextarea__input-content" :style="inputStyle">
          <div class="LLTextarea__editor-wrapper">
            <EditorContent ref="editor-content" class="LLTextarea__editor" :editor="editor"></EditorContent>
          </div>
          <div
            v-if="!hideMenuBar || !hideEmoji || (showAdditionalActions && hideMenuBar)"
            class="LLTextarea__actions"
            @click="onActionsClick"
          >
            <slot v-if="hideMenuBar" name="additional-actions"></slot>
            <LLPopper
              v-if="!hideMenuBar"
              :show.sync="menuStates.menuBar"
              :offset="[0, 0]"
              :disabled="disabled"
              class="LLTextarea__action"
              tooltip-white
              placement="top-start"
            >
              <template #trigger>
                <LLButton :disabled="disabled" :active="menuStates.menuBar" tertiary small>
                  <template #icon-left>
                    <TextareaMenuBarIcon></TextareaMenuBarIcon>
                  </template>
                </LLButton>
              </template>
              <template #tooltip>
                <LLTextareaMenuBar v-if="editor" class="LLTextarea__popup-menubar" :editor="editor">
                  <template #additional-actions> <slot name="additional-actions"></slot> </template>
                </LLTextareaMenuBar>
              </template>
            </LLPopper>
            <LLPopper
              v-if="!hideEmoji"
              :show.sync="menuStates.emoji"
              :disabled="disabled"
              :offset="[0, 0]"
              class="LLTextarea__action"
              tooltip-white
              placement="top-start"
            >
              <template #trigger>
                <LLButton :disabled="disabled" :active="menuStates.emoji" tertiary small>
                  <template #icon-left>
                    <EmojiIcon />
                  </template>
                </LLButton>
              </template>
              <template #tooltip>
                <LLTextareaEmoji @select="onEmojiSelect" />
              </template>
            </LLPopper>
          </div>
        </div>
        <div v-if="showBottomContent" class="LLTextarea__additional-content">
          <slot name="bottom-content"></slot>
        </div>
      </div>
      <LLInputError v-if="hasError || showError">
        <div v-if="hasError" class="LLTextInputNew__error LLTextInputNew__styling-error-color">
          <slot name="error"></slot>
          <template v-if="hasError">{{ errorsString }}</template>
        </div>
      </LLInputError>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'

import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import Link from '@tiptap/extension-link'
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import OrderedList from '@tiptap/extension-ordered-list'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import { isNumber } from 'lodash'
import TextareaMenuBarIcon from '../../assets/icons/TextareaMenuBarIcon'
import emojiToHtml from '../../utils/emojiToHtml'
import EmojiImage from '@/utils/tiptap/EmojiImage'
import Mention from '@/utils/tiptap/Mention'

import LLButton from '@/components/common/ui-components/LLButton'
import LLPopper from '@/components/utils/LLPopper'
import LLTextareaMenuBar from '@/components/common/LLTextarea/LLTextareaMenuBar'
import LLTextareaEmoji from '@/components/common/LLTextarea/LLTextareaEmoji'
import EmojiIcon from '@/assets/icons/EmojiIcon'
import LLInputError from '@/components/common/LLInput/LLInputError'
import LLInputCaption from '@/components/common/LLInput/LLInputCaption'
import EnterHardBreak from '@/utils/tiptap/enterHardBreak'
import { screenCatcher } from '@/mixins/screenCatcher'
import mentionToHtml from '@/utils/mentionToHtml'

export default {
  name: 'LLTextarea',
  $_veeValidate: {
    // fetch the current value from the innerValue defined in the component data.
    value() {
      return this.text
    }
  },
  components: {
    TextareaMenuBarIcon,
    LLInputCaption,
    LLInputError,
    EmojiIcon,
    LLTextareaEmoji,
    LLTextareaMenuBar,
    LLPopper,
    LLButton,
    EditorContent
  },
  mixins: [screenCatcher],
  props: {
    errors: { type: Array, default: () => [] },
    /**
     * @type {string|number}
     * @enum ['normal', 'compact', 'any']
     */
    size: { type: String, Number, default: 'normal' },
    /**
     * @type {string}
     * @enum ['compact', 'normal', 'absolute']
     */
    menuBarStyle: { type: String, default: 'compact' },
    disabled: { type: Boolean, default: false },
    dynamicSize: { type: Boolean, default: false },
    maxHeight: { type: Number, default: null },
    hideMenuBar: { type: Boolean, default: false },
    hideEmoji: { type: Boolean, default: false },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    hardBreakOnEnter: { type: Boolean, default: false }
  },
  data() {
    return {
      text: '',
      editor: null,
      editorStates: {
        isFocused: false
      },
      showError: false,
      showCaption: false,
      showBottomContent: false,
      showAdditionalActions: false,
      menuStates: {
        emojiTop: false,
        emoji: false,
        menuBar: false
      }
    }
  },
  computed: {
    filled() {
      return this.text !== '<p></p>' && this.text?.length
    },
    hasError() {
      return !!this.errors?.length
    },
    errorsString() {
      return this.hasError ? this.errors.join(', ') : null
    },
    inputStyle() {
      const style = {}
      let height
      if (isNumber(this.size)) {
        height = `${this.size}px`
      } else if (this.size === 'normal') {
        height = '122px'
      } else if (this.size === 'compact') {
        height = '46px'
      }
      if (this.maxHeight) {
        style.maxHeight = `${this.maxHeight}px`
      }

      if (this.dynamicSize) {
        style.minHeight = height
      } else {
        style.height = height
      }
      return style
    },
    inputClasses() {
      const classes = []
      const rootClass = 'LLTextarea'
      if (this.size === 'normal') {
        classes.push(`${rootClass}_size-normal`)
      } else if (this.size === 'compact') {
        classes.push(`${rootClass}_size-compact`)
      }
      if (this.menuBarStyle === 'absolute') {
        if (this.tailwindScreens.sm) {
          classes.push(`${rootClass}_menu-bar-absolute`)
        } else {
          classes.push(`${rootClass}_menu-bar-compact`)
        }
      } else if (this.menuBarStyle === 'compact') {
        classes.push(`${rootClass}_menu-bar-compact`)
      } else if (this.menuBarStyle === 'normal') {
        classes.push(`${rootClass}_menu-bar-normal`)
      }
      if (this.editorStates.isFocused) {
        classes.push(`${rootClass}_focused`)
      }
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.hasError) {
        classes.push(`${rootClass}_error`)
      }
      if (this.filled) {
        classes.push(`${rootClass}_filled`)
      }
      return classes
    },
    hasContent() {
      return this.text?.length
    }
  },
  watch: {
    text: {
      handler(text) {
        if (this.editor && text !== this.editor.getHTML()) {
          const newText = this.textReplacer(text)
          this.editor.commands.setContent(newText, true, {
            preserveWhitespace: 'full'
          })
        }
      }
    },
    value: {
      handler() {
        this.text = emojiToHtml.img(this.value)
        this.text = mentionToHtml.tiptap(this.value)
      },
      immediate: true
    },
    placeholder() {
      this.setPlaceholder()
    }
  },
  created() {
    this.setShowSlots()
  },
  beforeUpdate() {
    this.setShowSlots()
  },
  mounted() {
    const extensions = [
      EmojiImage.configure({
        inline: true,
        HTMLAttributes: {
          class: 'emoji'
        }
      }),
      Mention.configure({
        inline: true,
        HTMLAttributes: {
          class: 'emoji'
        }
      }),
      Text,
      Bold,
      Italic,
      Underline,
      BulletList,
      OrderedList,
      Document,
      ListItem,
      Link,
      HardBreak,
      Paragraph,
      Placeholder.configure({
        placeholder: () => {
          return this.placeholder
        },
        showOnlyWhenEditable: false,
        showOnlyCurrent: true
      })
    ]
    /* if (this.limit) {
      const maxSize = new MaxSize()
      maxSize.options.maxSize = this.limit
      extensions.push(maxSize)
    } */
    if (this.hardBreakOnEnter) {
      extensions.push(EnterHardBreak)
    }
    this.editor = new Editor({
      content: this.value,
      editable: !this.disabled,
      extensions,
      onUpdate: (event) => {
        const content = this.editor.getHTML()
        this.text = this.textReplacer(content)
        if (this.text !== content && content !== '<p></p>') {
          this.editor.commands.setContent(this.text, true, {
            preserveWhitespace: 'full'
          })
        }

        this.$emit('input', this.htmlToMention(this.htmlToEmoji(this.text)), event)
      },
      onFocus: () => {
        if (!this.disabled) {
          this.onFocus()
        }
      },
      onBlur: () => {
        this.onBlur()
      }
    })
    this.setPlaceholder()
  },
  beforeDestroy() {
    if (this.editor && this.editor?.destroy) {
      this.editor.destroy()
    }
  },
  methods: {
    htmlToEmoji(htmlContent) {
      const result = htmlContent.replace(
        /<img src="[\w/.]+" class="emoji-img" emojicode="[\w\d+]+">/gi,
        (match) => {
          const emojiCodeList = [...match.matchAll(/emojicode="([\w\d+]+)"/g)]?.[0]?.[1]?.split('+') || []
          return String.fromCodePoint(...emojiCodeList.map((code) => parseInt(code, 16)))
        }
      )
      return result
    },
    htmlToMention(htmlContent) {
      const result = htmlContent.replaceAll(/<span class="mention"([^>]*)>([^<]+)<\/span>/gi, (match) => {
        const prospectId = [...match.matchAll(/prospectid="([^"]+)"/gi)]?.[0]?.[1]
        const userId = [...match.matchAll(/userid="([^"]+)"/gi)]?.[0]?.[1]
        const fullName = [...match.matchAll(/fullname="([^"]+)"/gi)]?.[0]?.[1]
        return `{{mention:${JSON.stringify(
          JSON.stringify({
            prospectId,
            userId,
            fullName
          })
        )}}}`
      })
      return result
    },
    textReplacer(content) {
      if (content === '<p></p>') {
        return ''
      }
      const newContent = mentionToHtml.tiptap(emojiToHtml.img(content))
      return newContent
    },
    setPlaceholder() {
      this.editor.commands.resetAttributes('placeholder')
    },
    onActionsClick(e) {
      if (
        !e.composedPath().find((el) => el?.classList?.contains('LLTextarea__action')) &&
        this.editor?.focus
      ) {
        this.editor.commands.focus('end')
      } else {
        this.editor.commands.focus()
      }
    },
    setShowSlots() {
      this.$nextTick(() => {
        this.showError = !!this.$slots?.error?.[0]
        this.showCaption = !!this.$slots?.caption?.[0]
        this.showBottomContent = !!this.$slots?.['bottom-content']?.[0]
        this.showAdditionalActions = !!this.$slots?.['additional-actions']?.[0]
      })
    },
    insertText(text) {
      this.editor.commands.insertContent(text, {
        parseOptions: {
          preserveWhitespace: 'full'
        }
      })
    },
    setContent(text) {
      this.editor.commands.setContent(text, {
        parseOptions: {
          preserveWhitespace: 'full'
        }
      })
    },
    onEmojiSelect(emoji) {
      this.editor.commands.setEmoji({ emoji: emoji.emoji })
      this.editor.commands.focus()
    },
    insertMention({ prospectId, userId, fullName }) {
      this.editor.commands.setMention({
        prospectId,
        userId,
        fullName
      })
      this.editor.commands.focus()
      this.editor.commands.insertContent(' ', {
        parseOptions: {
          preserveWhitespace: 'full'
        }
      })
    },
    focus() {
      if (this.editor.commands) {
        this.editor.commands.focus()
      }
    },
    onFocus() {
      this.editorStates.isFocused = true
      this.$emit('focus')
    },
    onBlur() {
      this.editorStates.isFocused = false
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.LLTextarea {
  $root: &;
  min-width: 0;
  min-height: 0;
  @apply relative;

  &_menu-bar-compact {
    #{$root}__header {
      @apply hidden;
    }
  }
  &_menu-bar-absolute {
    #{$root}__header {
      @apply absolute top-0 right-0;
      margin-top: -34px;
    }
    #{$root}__actions {
      @apply hidden;
    }
  }
  &_menu-bar-normal {
    #{$root}__header {
      @apply w-full;
    }
    #{$root}__actions {
      @apply hidden;
    }
  }

  &__header {
    @apply flex justify-end;
  }
  &_error {
    #{$root}__input-wrapper {
      @apply border-status-03-600;
    }
  }
  &_focused {
    #{$root}__input-wrapper {
      @apply border-neutral-01-700;
    }
  }
  &_disabled {
    #{$root}__input-wrapper {
      @apply border-neutral-01-100 bg-neutral-01-15 text-neutral-01-400;
    }
    @apply pointer-events-none;
  }
  &_error {
    #{$root}__input-wrapper {
      @apply border-status-03-600;
    }
  }
  &:hover:not(#{$root}_disabled):not(#{$root}_error):not(#{$root}_focused) {
    #{$root}__input-wrapper {
      @apply border-neutral-01-400;
    }
  }
  &__editor {
    min-height: 20px !important;
    max-width: 100% !important;
    height: 100%;
  }
  &__editor-content {
    @apply w-full;
  }
  &__container {
    @apply relative h-full;
    min-width: 0;
    min-height: 0;
  }
  &__input-wrapper {
    @apply border rounded transition duration-200  w-full border-neutral-01-75 h-full bg-white relative flex flex-col;
    min-height: 46px;
  }
  &__input-content {
    @apply w-full flex h-full min-w-0;
  }
  &__editor-wrapper {
    @apply flex-1 min-w-0 overflow-y-auto;
    @apply px-4 py-2.5;
  }
  &__actions {
    @apply flex-shrink-0 pb-1.5 pr-1.5 flex items-end;
  }
  &__additional-content {
    @apply w-full px-4 pb-4;
  }
  &__popup-menubar {
    @apply p-1;
  }

  ::v-deep .ProseMirror {
    * {
      font-family: 'Open Sans', 'Arial', 'Noto Color Emoji', 'Noto Emoji', sans-serif;
    }
    height: 100%;
    &-focused,
    &:focus {
      @apply outline-none border-neutral-01;
      .is-disabled & {
        @apply outline-none border-neutral-01-15;
      }
    }

    /*.has-errors & {
      @apply border-error-01;
    }*/

    .mention {
      @apply text-accent-01;
    }

    .is-empty {
      @apply text-neutral-01-400;
    }

    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      pointer-events: none;
      height: 0;
    }

    ol,
    ul {
      list-style: initial;
      @apply pl-6 my-2;
    }

    a {
      @apply underline cursor-pointer text-primary-01-400;
    }
  }
}
</style>
