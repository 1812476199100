<template>
  <div class="SearchResponsesFilter" :class="{ SearchResponsesFilter_pending: pendingFilters }">
    <LLFilter :cleared="cleared" @clear="clearFilter" @apply="applyFilter">
      <LLFilterItem v-if="!study">
        <LLFilterChevron :inactive="!chatIdSelected">
          <template #label> {{ $t('search-page_responses_filter_thread') }}</template>
          <LLCheckboxList
            :key-fn="(item) => item.id"
            :reduce="(item) => item.id"
            :label="(item) => item.label"
            :selected.sync="localFiltersValues.threadId"
            :values="threads"
          ></LLCheckboxList>
          <LLEmptyState v-if="!threads.length">
            {{ $tc('common_amount_threads', 0) }}
          </LLEmptyState>
        </LLFilterChevron>
      </LLFilterItem>
      <LLFilterItem v-if="!study && !chat.isMemberAnonymous && false">
        <LLFilterChevron :inactive="!chatIdSelected">
          <template #label> {{ $t('search-page_responses_filter_member') }}</template>
          <LLCheckboxList
            :key-fn="(item) => item.id"
            :reduce="(item) => item.id"
            :label="(item) => item.label"
            :selected.sync="localFiltersValues.memberId"
            :values="members"
          ></LLCheckboxList>
          <LLEmptyState v-if="!members.length">
            {{ $tc('common_amount_members', 0) }}
          </LLEmptyState>
        </LLFilterChevron>
      </LLFilterItem>
    </LLFilter>
  </div>
</template>

<script>
import LLFilter from '@/components/pages/search/filter/LLFilter'
import LLFilterItem from '@/components/pages/search/filter/LLFilter/LLFilterItem'
import { StudyModel } from '@/models/study'
import LLFilterChevron from '@/components/pages/search/filter/LLFilter/LLFilterChevron'
import { FilterHelper } from '@/utils/filterLabels'
import LLCheckboxList from '@/components/common/LLCheckboxList'
import { pad } from '@/utils/stringUtils'
import { ForumChatModel } from '@/models/forum/forumChat'
import LLEmptyState from '@/components/common/ui-components/LLEmptyState.vue'
export default {
  name: 'SearchResponsesFilter',
  components: {
    LLEmptyState,
    LLCheckboxList,
    LLFilterChevron,
    LLFilterItem,
    LLFilter
  },
  props: {
    chat: { type: [ForumChatModel, null], default: null },
    study: { type: [StudyModel, null], default: null },
    initFilters: { type: Object, default: null }
  },
  data() {
    return {
      chats: [],
      threads: [],
      members: [],
      studies: [],
      teams: [],
      groups: [],
      participants: [],
      questions: [],
      topics: [],
      tags: [],
      pendingFilters: [],
      fetchFiltersPromise: null,
      localFiltersValues: {
        teamId: this.initFilters?.teamId?.length ? [...this.initFilters.teamId] : [],
        studyId: this.initFilters?.studyId?.length ? [...this.initFilters.studyId] : [],
        chatId: this.initFilters?.chatId?.length ? [...this.initFilters.chatId] : [],
        groupId: [],
        participantId: [],
        topicId: [],
        questionId: [],
        tagId: [],
        threadId: [],
        memberId: []
      },
      topicsQuestionsTagsRadio: 'topics',
      groupsParticipantsRadio: 'groups'
    }
  },
  computed: {
    filterLabels() {
      const filterHelper = new FilterHelper()
      filterHelper.addCheckboxList(
        'studyId',
        this.studies,
        () => {
          this.fetchFilters()
        },
        {
          allowToRemove: !this.study
        }
      )
      filterHelper.addCheckboxList(
        'chatId',
        this.chats,
        () => {
          this.fetchFilters()
        },
        {
          allowToRemove: !this.chat
        }
      )
      filterHelper.addCheckboxList(
        'teamId',
        this.teams,
        () => {
          this.fetchFilters()
        },
        {
          allowToRemove: !this.study && !this.chat
        }
      )
      filterHelper.addCheckboxList('threadId', this.threads)
      filterHelper.addCheckboxList('memberId', this.members)
      filterHelper.addCheckboxList('groupId', this.groups)
      filterHelper.addCheckboxList('participantId', this.participants)
      filterHelper.addCheckboxList('topicId', this.topics)
      filterHelper.addCheckboxList('questionId', this.questions)
      filterHelper.addCheckboxList('tagId', this.tags)
      return filterHelper.getLabels({ filterValues: this.localFiltersValues })
    },
    studyCheckboxesAreActive() {
      return !!this.localFiltersValues.teamId?.length
    },
    chatCheckboxesAreActive() {
      return true
    },
    chatIdSelected() {
      return !!this.localFiltersValues.chatId?.length
    },
    studyIdSelected() {
      return !!this.localFiltersValues.studyId?.length
    },
    cleared() {
      return !!(
        !this.localFiltersValues.threadId.length &&
        !this.localFiltersValues.memberId.length &&
        !this.localFiltersValues.groupId.length &&
        !this.localFiltersValues.participantId.length &&
        !this.localFiltersValues.topicId.length &&
        !this.localFiltersValues.questionId.length &&
        !this.localFiltersValues.tagId.length &&
        (!this.localFiltersValues.teamId.length || this.study || this.chat) &&
        (!this.localFiltersValues.studyId.length || this.study) &&
        (!this.localFiltersValues.chatId.length || this.chat)
      )
    }
  },
  watch: {
    localFiltersValues: {
      deep: true,
      handler() {
        if (!this.pendingFilters) {
          this.applyFilter()
        }
      }
    },
    filterLabels: {
      handler() {
        this.$emit('updateFilterLabels', { filterLabels: this.filterLabels })
      }
    },
    'localFiltersValues.studyId'() {
      this.$emit('updateStartFilters', {
        teamId: this.localFiltersValues.teamId,
        studyId: this.localFiltersValues.studyId,
        chatId: this.localFiltersValues.chatId
      })
    },
    'localFiltersValues.teamId'() {
      this.$emit('updateStartFilters', {
        teamId: this.localFiltersValues.teamId,
        studyId: this.localFiltersValues.studyId,
        chatId: this.localFiltersValues.chatId
      })
    },
    'localFiltersValues.chatId'() {
      this.$emit('updateStartFilters', {
        teamId: this.localFiltersValues.teamId,
        studyId: this.localFiltersValues.studyId,
        chatId: this.localFiltersValues.chatId
      })
    }
  },
  created() {
    this.localFiltersValues.studyId = [...this.initFilters.studyId]
    this.localFiltersValues.chatId = [...this.initFilters.chatId]
    this.localFiltersValues.teamId = [...this.initFilters.teamId]
    this.localFiltersValues.groupId = [...this.initFilters.groupId]
    this.localFiltersValues.participantId = [...this.initFilters.participantId]
    this.localFiltersValues.questionId = [...this.initFilters.questionId]
    this.localFiltersValues.topicId = [...this.initFilters.topicId]
    this.localFiltersValues.tagId = [...this.initFilters.tagId]

    this.fetchFilters()
  },
  methods: {
    fetchFilters() {
      this.pendingFilters = true

      this.fetchFiltersPromise = this.$api.search.getResponseFilters({
        studyId: this.localFiltersValues.studyId,
        chatId: this.localFiltersValues.chatId
      })

      this.fetchFiltersPromise
        .then((result) => {
          this.teams = result.teams ? result.teams.map((team) => ({ id: team.id, label: team.name })) : []
          this.localFiltersValues.teamId = this.localFiltersValues.teamId.filter((teamId) =>
            this.teams.find((t) => t.id === teamId)
          )

          this.studies = result.studies
            ? result.studies.map((study) => ({ id: study.id, label: study.name }))
            : []
          this.localFiltersValues.studyId = this.localFiltersValues.studyId.filter((studyId) =>
            this.studies.find((s) => s.id === studyId)
          )

          /*this.chats = result.chats ? result.chats.map((chat) => ({ id: chat.id, label: chat.name })) : []
          this.localFiltersValues.chatId = this.localFiltersValues.chatId.filter((chatId) =>
            this.chats.find((chat) => chat.id === chatId)
          )*/

          this.groups = result.groups
            ? result.groups.map((group) => ({ id: group.id, label: group.name }))
            : []
          this.localFiltersValues.groupId = this.localFiltersValues.groupId.filter((groupId) =>
            this.groups.find((g) => g.id === groupId)
          )
          this.participants = result.participants
            ? result.participants.map((participant) => ({
                id: participant.id,
                label: participant.name || this.$t('prospect-account_full-name_not-available')
              }))
            : []
          this.localFiltersValues.participantId = this.localFiltersValues.participantId.filter(
            (participantId) => this.participants.find((p) => p.id === participantId)
          )
          this.topics = result.topics
            ? result.topics.map((topic) => ({
                id: topic.id,
                label: `T${pad(topic.order, 2)}: ${topic.name}`
              }))
            : []
          this.localFiltersValues.topicId = this.localFiltersValues.topicId.filter((topicId) =>
            this.topics.find((t) => t.id === topicId)
          )
          this.questions = result.questions
            ? result.questions.map((question) => ({
                id: question.id,
                label: `#${question.topic.study.id} T${pad(question.topic.order, 2)}Q${pad(
                  question.order,
                  2
                )}: ${question.title}`
              }))
            : []
          this.localFiltersValues.questionId = this.localFiltersValues.questionId.filter((questionId) =>
            this.questions.find((q) => q.id === questionId)
          )
          this.tags = result.tags ? result.tags.map((tag) => ({ id: tag.id, label: tag.name })) : []
          this.localFiltersValues.tagId = this.localFiltersValues.tagId.filter((tagId) =>
            this.tags.find((t) => t.id === tagId)
          )
          this.threads = result.threads
            ? result.threads.map((thread) => ({ id: thread.id, label: thread.title }))
            : []
          this.localFiltersValues.threadId = this.localFiltersValues.threadId.filter((threadId) =>
            this.threads.find((t) => t.id === threadId)
          )
          this.members = result.members
            ? result.members.map((member) => ({ id: member.id, label: member.name }))
            : []
          this.localFiltersValues.memberId = this.localFiltersValues.memberId.filter((memberId) =>
            this.members.find((m) => m.id === memberId)
          )
          this.pendingFilters = false
        })
        .catch(() => {
          this.pendingFilters = false
        })
    },

    clearFilter() {
      if (!this.study && !this.chat) {
        this.localFiltersValues.teamId = []
      }
      if (!this.study) {
        this.localFiltersValues.studyId = []
      }
      if (!this.chat) {
        this.localFiltersValues.chatId = []
      }
      this.localFiltersValues.threadId = []
      this.localFiltersValues.memberId = []
      this.localFiltersValues.groupId = []
      this.localFiltersValues.participantId = []
      this.localFiltersValues.questionId = []
      this.localFiltersValues.topicId = []
      this.localFiltersValues.tagId = []
      this.applyFilter()
    },
    applyFilter() {
      this.$emit('apply', this.localFiltersValues)
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesFilter {
  &_pending {
    @apply opacity-50 pointer-events-none select-none;
  }
  &__radio-button {
    @apply mr-4;
    &:first-child {
      @apply ml-0;
    }
  }
  &__radio-button-row {
    @apply flex;
  }
  &__radio-button-row-content {
    @apply mt-5;
  }
}
</style>
