<template>
  <LLPage class="TermsOfService">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #title>
      <h1 class="TermsOfService__title">{{ $t('terms_of_service_title') }}</h1>
    </template>
    <template #content>
      <p class="TermsOfService__date">{{ $t('terms_of_service_updated_at') }}</p>
      <TermsOfServiceNL v-if="currentLanguage === 'nl'" />
      <TermsOfServiceOtherLanguages v-else />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import TermsOfServiceNL from '@/components/pages/terms-of-service/languages/TermsOfServiceNL'
import TermsOfServiceOtherLanguages from '@/components/pages/terms-of-service/TermsOfServiceOtherLanguages'
export default {
  name: 'TermsOfService',
  components: { TermsOfServiceOtherLanguages, TermsOfServiceNL, LLBreadCrumbs, LLPage },
  data() {
    return {}
  },
  computed: {
    crumbs() {
      return [{ name: this.$t('main_home_label'), path: '/' }, { name: this.$t('terms_of_service_title') }]
    },
    currentLanguage() {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss" scoped>
.TermsOfService {
  &__date {
    @apply text-neutral-01 text-sm font-semibold mb-10;
  }
}
</style>
