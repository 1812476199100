<template>
  <div class="ForumThreadPageAsideFixed" :class="classList">
    <div class="ForumThreadPageAsideFixed__arrow-wrapper">
      <router-link tag="a" :to="`/chats/${chat.id}`">
        <LLButton tertiary small>
          <template #icon-left>
            <HomeIcon />
          </template>
        </LLButton>
      </router-link>
    </div>
    <div class="ForumThreadPageAsideFixed__chat">
      <div class="ForumThreadPageAsideFixed__chat-image-wrapper">
        <ImageLoader :file="chat.image" small class="ForumThreadPageAsideFixed__chat-image"></ImageLoader>
      </div>
      <div class="ForumThreadPageAsideFixed__chat-name">
        {{ chat.name }}
      </div>
    </div>
    <div class="ForumThreadPageAsideFixed__counters">
      <div class="ForumThreadPageAsideFixed__counter">
        <LLMetaIconInfo
          :text="$tc('common_amount_no_digits_members', chat.meta.memberCount)"
          :show-text="false"
          :value="chat.meta.memberCount"
        >
          <ParticipantsIcon />
        </LLMetaIconInfo>
      </div>
      <div class="ForumThreadPageAsideFixed__counter">
        <LLMetaIconInfo
          :text="$tc('common_amount_no_digits_threads', chat.meta.threadCount)"
          :show-text="false"
          :value="chat.meta.threadCount"
        >
          <TopicRedIcon />
        </LLMetaIconInfo>
      </div>
    </div>
    <div class="ForumThreadPageAsideFixed__close-buttor-wrapper">
      <LLButton
        v-if="showCloseButton"
        small
        tertiary
        class="ForumThreadPageAsideFixed__close-button"
        @click="$emit('close')"
      >
        <template #icon-left>
          <CloseRegularIcon />
        </template>
      </LLButton>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import { ForumChatModel } from '@/models/forum/forumChat'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'
import HomeIcon from '@/assets/icons/HomeIcon.vue'
import LLMetaIconInfo from '@/components/atoms/LLMetaIconInfo.vue'
import CloseRegularIcon from '@/assets/icons/CloseRegularIcon.vue'

export default {
  name: 'ForumThreadPageAsideFixed',
  components: { CloseRegularIcon, LLMetaIconInfo, HomeIcon, ImageLoader, LLButton },
  props: {
    chat: { type: ForumChatModel, required: true },
    showHomeButton: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_home-button-visible`]: this.showHomeButton
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumThreadPageAsideFixed {
  $root: &;
  @apply py-2 px-4 border-neutral-01-50 flex items-center bg-white min-w-0 shadow-dropdown;
  &_home-button-visible {
    #{$root}__chat {
      transform: translateX(0);
    }
    #{$root}__arrow-wrapper {
      @apply visible opacity-100;
      transform: translateX(0);
    }
  }
  &__chat-image {
    @apply w-6 h-6 object-cover rounded-full;
  }
  &__chat {
    @apply w-full flex items-center min-w-0 ml-2 transition duration-200;
    transform: translateX(-40px);
  }
  &__arrow-wrapper {
    @apply flex-shrink-0 opacity-0 invisible transition duration-200;
    transform: translateX(-40px);
  }
  &__chat-image-wrapper {
    @apply mr-2 w-6 h-6 flex-shrink-0;
  }
  &__chat-name {
    @apply text-body-02 truncate;
  }
  &__counters {
    @apply flex flex-shrink-0;
  }
  &__counter {
    @apply ml-2.5;
    &:first-child {
      @apply ml-0;
    }
  }
  &__close-button {
    @apply ml-4 w-8;
  }
}
</style>
