<template>
  <svg
    width="11"
    class="fill-current"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.04257 2.14413C4.42898 1.75771 4.95308 1.54062 5.49955 1.54062C6.04603 1.54062 6.57012 1.75771 6.95653 2.14413C7.34295 2.53054 7.56004 3.05464 7.56004 3.60111V4.97609C7.56004 4.97625 7.56004 4.9764 7.56004 4.97656H3.43906C3.43906 4.9764 3.43906 4.97625 3.43906 4.97609V3.60111C3.43906 3.05464 3.65615 2.53054 4.04257 2.14413ZM2.03907 4.9781C2.03906 4.97743 2.03906 4.97676 2.03906 4.97609V3.60111C2.03906 2.68333 2.40365 1.80315 3.05262 1.15418C3.70158 0.505211 4.58177 0.140625 5.49955 0.140625C6.41733 0.140625 7.29752 0.505211 7.94648 1.15418C8.59545 1.80315 8.96004 2.68333 8.96004 3.60111V4.97609C8.96004 4.97675 8.96004 4.97742 8.96004 4.97809C9.63452 5.01082 10.1713 5.56804 10.1713 6.25063V10.4975C10.1713 11.2012 9.60089 11.7716 8.89724 11.7716H2.1022C1.39855 11.7716 0.828125 11.2012 0.828125 10.4975V6.25063C0.828125 5.56815 1.36474 5.011 2.03907 4.9781ZM5.4998 6.83203C5.8864 6.83203 6.1998 7.14543 6.1998 7.53203V9.21699C6.1998 9.60359 5.8864 9.91699 5.4998 9.91699C5.11321 9.91699 4.7998 9.60359 4.7998 9.21699V7.53203C4.7998 7.14543 5.11321 6.83203 5.4998 6.83203Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockIcon'
}
</script>

<style lang="scss" scoped>
.Lock {
}
</style>
