<template>
  <div class="ForumChatThreadCardIndicators">
    <LLPopper
      v-if="thread.isNew"
      data-e2e="forumChatThreadIndicatorNew"
      tooltip-red
      tooltip-with-arrow
      trigger="hover"
      :offset="[0, 0]"
      disabled
      placement="top"
      class="ForumChatThreadCardIndicators__indicator-wrapper"
    >
      <template #tooltip>{{ $t('forum.thread.indicators.new') }}</template>
      <template #trigger>
        <div
          class="ForumChatThreadCardIndicators__indicator ForumChatThreadCardIndicators__indicator_new-thread"
        >
          <div class="ForumChatThreadCardIndicators__indicator-inner">
            <span class="ForumChatThreadCardIndicators__indicator-text">{{
              $t('forum.thread.indicators.new')
            }}</span>
          </div>
        </div>
      </template>
    </LLPopper>
    <LLPopper
      v-if="thread.hasPoll"
      data-e2e="forumChatThreadIndicatorNew"
      tooltip-red
      tooltip-with-arrow
      trigger="hover"
      :offset="[0, 0]"
      disabled
      placement="top"
      class="ForumChatThreadCardIndicators__indicator-wrapper"
    >
      <template #tooltip>{{ $t('forum.thread.indicators.poll') }}</template>
      <template #trigger>
        <div
          class="ForumChatThreadCardIndicators__indicator ForumChatThreadCardIndicators__indicator_new-thread"
        >
          <div class="ForumChatThreadCardIndicators__indicator-inner">
            <PollIcon class="ForumChatThreadCardIndicators__status-icon"></PollIcon>
            <span class="ForumChatThreadCardIndicators__indicator-text">{{
              $t('forum.thread.indicators.poll')
            }}</span>
          </div>
        </div>
      </template>
    </LLPopper>
    <LLPopper
      v-if="thread.isPinned"
      data-e2e="forumChatThreadIndicatorPinned"
      tooltip-dark
      tooltip-with-arrow
      trigger="hover"
      :offset="[0, 0]"
      disabled
      placement="top"
      class="ForumChatThreadCardIndicators__indicator-wrapper"
    >
      <template #tooltip>{{ $t('forum.thread.indicators.pinned') }}</template>
      <template #trigger>
        <div class="ForumChatThreadCardIndicators__indicator ForumChatThreadCardIndicators__indicator_pinned">
          <div class="ForumChatThreadCardIndicators__indicator-inner">
            <PinIcon
              class="ForumChatThreadCardIndicators__status-icon ForumChatThreadCardIndicators__status-icon_is-pinned"
            ></PinIcon>
            <span class="ForumChatThreadCardIndicators__indicator-text">{{
              $t('forum.thread.indicators.pinned')
            }}</span>
          </div>
        </div>
      </template>
    </LLPopper>
  </div>
</template>

<script>
import { ForumThreadModel } from '@/models/forum/forumThread'
import LLPopper from '@/components/utils/LLPopper.vue'
import PinIcon from '@/assets/icons/PinIcon.vue'
import PollIcon from '@/assets/icons/PollIcon.vue'

export default {
  name: 'ForumChatThreadCardIndicators',
  components: { PollIcon, PinIcon, LLPopper },
  props: {
    thread: { type: ForumThreadModel, required: true }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardIndicators {
  @apply flex;
  &__indicator-wrapper {
    @apply ml-2;
    &:first-child {
      @apply ml-0;
    }
  }
  &__indicator {
    @apply h-6 border border-neutral-01-50 rounded-full ml-2 bg-white flex items-center justify-center;
    min-width: 24px;
    &:first-child {
      @apply ml-0;
    }
    &-inner {
      @apply px-2 flex items-center;
    }
    &-text {
      @apply font-bold ml-1 cursor-default select-none;
      font-size: 10px;
      &:first-child {
        @apply ml-0;
      }
    }
    &_new-thread {
      @apply text-accent-01;
    }
    &_pinned {
      @apply text-neutral-01-400;
    }
  }
  &__status-icon {
    @apply w-4 h-4;
    &_is-pinned {
      @apply text-neutral-01-400 w-3 h-3;
    }
  }
}
</style>
