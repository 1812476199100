<template>
  <div class="StudyNewPageScreeningHeader">
    <div class="flex mob:flex-col">
      <div class="flex-1">
        <div class="font-serif text-3xl text-primary-01 font-bold" data-e2e="studyName">
          {{ study.name }}
        </div>
        <div class="flex mob:flex-col mt-5">
          <div v-if="study.image" class="mr-10 w-52 h-52 flex-shrink-0 mob:w-full">
            <ImageLoader :file="studyObject.image" large class="object-cover w-full h-full rounded" />
          </div>
          <div class="mob:ml-0 mob:mt-7">
            <div class="flex items-center">
              <div class="flex items-center">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.285 21.0004V17.6683H3.85626C2.27811 17.6683 0.998779 16.389 0.998779 14.8108V3.85552C0.998779 2.27738 2.27811 0.998047 3.85626 0.998047H18.1436C19.7153 0.998047 21.0011 2.28391 21.0011 3.85552V14.8108C21.0011 16.389 19.7218 17.6683 18.1436 17.6683H9.57121L5.285 21.0004ZM4.78628 6.99922C4.78628 6.55739 5.14445 6.19922 5.58628 6.19922H16.4137C16.8556 6.19922 17.2137 6.55739 17.2137 6.99922C17.2137 7.44105 16.8556 7.79922 16.4137 7.79922H5.58628C5.14445 7.79922 4.78628 7.44105 4.78628 6.99922ZM4.78628 11.3C4.78628 10.8582 5.14445 10.5 5.58628 10.5H11.8555C12.2973 10.5 12.6555 10.8582 12.6555 11.3C12.6555 11.7418 12.2973 12.1 11.8555 12.1H5.58628C5.14445 12.1 4.78628 11.7418 4.78628 11.3Z"
                    fill="#FF6666"
                  />
                </svg>
                <div class="text-neutral-01 text-sm ml-3" data-e2e="topicsNum">
                  {{ topicsNum }}
                  <template v-if="topicsNum === 1">{{ $t('topic') }}</template>
                  <template v-else>{{ $t('topics') }}</template>
                </div>
              </div>
              <div class="flex items-center ml-8">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.7149 22.0004V18.6683H19.1436C20.7218 18.6683 22.0011 17.389 22.0011 15.8108V4.85552C22.0011 3.27738 20.7218 1.99805 19.1436 1.99805H4.85626C3.28464 1.99805 1.99878 3.28391 1.99878 4.85552V15.8108C1.99878 17.389 3.27811 18.6683 4.85626 18.6683H13.4287L17.7149 22.0004ZM12.18 6.83532C11.849 6.77854 11.5086 6.84075 11.219 7.01093C10.9294 7.18111 10.7094 7.44828 10.598 7.76512C10.4514 8.18191 9.99463 8.40093 9.57783 8.25431C9.16104 8.10769 8.94202 7.65096 9.08864 7.23417C9.32549 6.56088 9.79297 5.99315 10.4083 5.63152C11.0236 5.26988 11.7471 5.13769 12.4505 5.25835C13.154 5.37901 13.792 5.74474 14.2517 6.29076C14.7112 6.83661 14.9627 7.52743 14.9618 8.24093C14.9616 9.40606 14.0983 10.1702 13.4945 10.5727C13.2239 10.7531 12.9573 10.8937 12.7396 10.9962V11.6134C12.7396 12.0553 12.3814 12.4134 11.9396 12.4134C11.4978 12.4134 11.1396 12.0553 11.1396 11.6134V10.4626C11.1396 10.1183 11.3599 9.81255 11.6866 9.70366L11.9396 10.4626C11.6866 9.70366 11.6868 9.70359 11.6866 9.70366L11.6966 9.70015C11.7076 9.69624 11.7258 9.68962 11.7503 9.68029C11.7994 9.66159 11.8727 9.63234 11.9619 9.59267C12.1427 9.51231 12.3777 9.39426 12.607 9.24141C13.1142 8.90325 13.3618 8.55643 13.3618 8.24038L13.3618 8.23919C13.3623 7.90332 13.2439 7.57811 13.0276 7.32116C12.8113 7.06421 12.5111 6.8921 12.18 6.83532ZM11.9967 13.5527C11.7126 13.5527 11.4582 13.6358 11.2765 13.8371C11.1131 14.0186 11.036 14.2437 11.036 14.4937C11.036 14.7437 11.1126 14.9686 11.2761 15.1502L11.282 15.1564C11.4649 15.3457 11.7173 15.4216 11.9967 15.4216C12.2764 15.4216 12.5293 15.345 12.712 15.1559L12.7143 15.1534C12.8834 14.9721 12.964 14.7463 12.964 14.4937C12.964 14.2419 12.8838 14.0165 12.7156 13.8355C12.5339 13.6354 12.2801 13.5527 11.9967 13.5527Z"
                    fill="#FF6666"
                  />
                </svg>
                <div class="text-neutral-01 text-sm ml-3" data-e2e="questionNum">
                  {{ questionsNum }} {{ $tc('question', questionsNum) }}
                </div>
              </div>
            </div>
            <LLWysiwygOutput
              class="text-base mt-5 color-primary-01"
              data-e2e="screeningQuestionsInstructions"
              :text="study.screeningQuestionsInstructions"
            ></LLWysiwygOutput>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 ml-24 mob:w-full mob:mt-8 mob:ml-0"></div>
    </div>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'
import ImageLoader from '@/components/common/ImageLoader/ImageLoader.vue'

export default {
  name: 'StudyNewPageScreeningHeader',
  components: { ImageLoader, LLWysiwygOutput },
  props: {
    study: { type: StudyModel, required: true },
    topics: { type: Array, default: () => [] }
  },
  computed: {
    studyObject() {
      return StudyModel.parseFromApi({ study: this.study })
    },
    topicsNum() {
      return this.topics?.length
    },
    questionsNum() {
      return this.topics?.reduce((acc, topic) => acc + topic?.questionIdList?.length, 0)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageScreeningHeader {
}
</style>
