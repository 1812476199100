<template>
  <LLInputContainer
    :focused="focus"
    class="ForumThreadEditorThemes"
    free-size
    @mousedown.native="onClickEvent"
    @mouseup.native="onClickEvent"
  >
    <template #text-input>
      <div class="ForumThreadEditorThemes__inner">
        <LLTags
          ref="tags"
          class="ForumThreadEditorThemes__tags"
          :values="localThemes"
          :input-placeholder="$t('forum.thread_editor_page.themes.placeholder')"
          :max-length="50"
          :valid-fn="validFn"
          @updateInput="onUpdateInput"
          @remove="onRemove"
          @add="onAdd"
          @inputFocus="focus = true"
          @inputBlur="focus = false"
          @click.native.stop
        ></LLTags>
      </div>
    </template>
  </LLInputContainer>
</template>

<script>
import LLInputContainer from '@/components/common/LLInput/LLInputContainer'
import LLTags from '@/components/common/ui-components/LLTags'
export default {
  name: 'ForumThreadEditorThemes',
  components: { LLTags, LLInputContainer },
  props: {
    themes: { type: Array, default: () => [] }
  },
  data() {
    return {
      localThemes: [],
      focus: false
    }
  },
  watch: {
    localThemes: {
      handler() {
        this.$emit('update:themes', [...this.localThemes])
      },
      deep: true
    },
    themes: {
      handler() {
        if (!this.$_.isEqual(this.themes, this.localThemes)) {
          this.localThemes = [...this.themes]
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    validFn(value) {
      if (this.localThemes.find((theme) => theme?.toLowerCase() === value?.toLowerCase())) {
        return {
          value: false,
          error: 'forum.thread_editor_page.themes.errors.already_exists'
        }
      }
      return {
        value: true
      }
    },
    onAdd(value) {
      this.localThemes.push(value)
    },
    onClickEvent(event) {
      if (event.composedPath().includes(this.$refs?.tags?.$el)) {
        return
      }
      this.$nextTick(() => {
        if (this.$refs?.tags?.$refs?.input?.$refs?.input?.focus) {
          this.$refs?.tags?.$refs?.input?.$refs?.input?.focus()
        }
      })
    },
    onUpdateInput() {},
    onRemove(value) {
      this.localThemes = this.localThemes.filter((tag) => tag !== value)
    }
  }
}
</script>

<style lang="scss" scoped>
.ForumThreadEditorThemes {
  @apply cursor-text;
  &__inner {
    @apply p-4 min-w-0 w-full;
  }
  &__tags {
  }
}
</style>
