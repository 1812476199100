<template>
  <div
    class="PollMultipleEditorItem"
    :class="{
      PollMultipleEditorItem_checked: isChecked,
      PollMultipleEditorItem_disabled: disabled || (locked && !isChecked)
    }"
  >
    <input
      :id="`poll-checkbox-${choice.id}-${choiceUUID}`"
      v-model="isChecked"
      :disabled="disabled || locked"
      class="PollMultipleEditorItem__input"
      :type="!isRadio ? 'checkbox' : 'radio'"
      :value="true"
      @focus="onFocus"
      @blur="isFocused = false"
    />
    <label
      class="PollMultipleEditorItem__content"
      :disabled="disabled || locked"
      :for="`poll-checkbox-${choice.id}-${choiceUUID}`"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      @mousedown="isMouseDown = true"
    >
      <div v-if="isRadio" class="PollMultipleEditorItem__radio">
        <LLRadioIcon :is-hovered="showHoveredState" :disabled="disabled || locked" :is-checked="isChecked" />
      </div>
      <div v-if="!isRadio" class="PollMultipleEditorItem__checkbox">
        <LLCheckboxIcon
          :is-hovered="showHoveredState"
          :disabled="disabled || locked"
          :is-checked="isChecked"
        />
      </div>
      <div class="PollMultipleEditorItem__answer">
        <div v-if="choice.image" class="PollMultipleEditorItem__image-wrapper" @click.stop.prevent>
          <LLFileSlider
            :pre-large="poll.isThumbnailsLarge && !mediumThumbnails"
            :medium="poll.isThumbnailsLarge && mediumThumbnails"
            :files="[choice.image]"
            :small="!poll.isThumbnailsLarge"
          ></LLFileSlider>
        </div>
        <div v-if="!poll.isLabelsHidden" class="PollMultipleEditorItem__text-wrapper">
          {{ choice.text }}
        </div>
      </div>
    </label>
  </div>
</template>

<script>
// TODO: hide label only for non-admin

import { PollChoiceModel } from '@/models/pollChoice'
import { PollModel } from '@/models/poll'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import LLCheckboxIcon from '@/components/common/LLCheckboxIcon.vue'
import LLRadioIcon from '@/components/common/LLRadioIcon.vue'
import { v4 as uuidV4 } from 'uuid'

export default {
  name: 'PollMultipleEditorItem',
  components: { LLRadioIcon, LLCheckboxIcon, LLFileSlider },
  mixins: [screenCatcher],
  props: {
    choice: { type: PollChoiceModel, required: true },
    poll: { type: PollModel, required: true },
    disabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
    value: { type: Array, required: true }
  },
  data() {
    return {
      isChecked: false,
      isHovered: false,
      isFocused: false,
      isMouseDown: false
    }
  },
  computed: {
    choiceUUID() {
      return uuidV4()
    },
    showHoveredState() {
      return this.isHovered || this.isFocused
    },
    isRadio() {
      return this.poll.isAnswerLimitEnabled && this.poll.answerLimitAmount === 1
    },
    mediumThumbnails() {
      return this.tailwindScreens.mobOnly
    }
  },
  watch: {
    value: {
      handler() {
        this.isChecked = !!this.value.find((choiceId) => choiceId === this.choice.id)
      },
      deep: true,
      immediate: true
    },
    isChecked: {
      handler() {
        const valueIndex = this.value.findIndex((choiceId) => choiceId === this.choice.id)
        if (this.isChecked && this.isRadio) {
          this.$emit('input', [this.choice.id])
          return
        }
        if (this.isChecked && valueIndex === -1) {
          this.$emit('input', [...this.value, this.choice.id])
        } else if (!this.isChecked && valueIndex !== -1) {
          const value = [...this.value]
          value.splice(valueIndex, 1)
          this.$emit('input', value)
        }
      }
    }
  },
  methods: {
    onFocus() {
      if (!this.isMouseDown) {
        this.isFocused = true
      }
      this.isMouseDown = false
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultipleEditorItem {
  $root: &;

  &__checkbox {
    width: 24px;
    height: 24px;
    @apply flex items-center justify-center flex-shrink-0;
  }

  &__radio {
    width: 24px;
    height: 24px;
    @apply flex items-center justify-center flex-shrink-0;
  }

  &__input {
    @apply w-0 h-0 opacity-0 absolute;
  }

  &__content {
    @apply flex p-2 rounded cursor-pointer transition duration-200;
  }

  &__answer {
    @apply ml-2 select-none flex min-w-0;
  }

  &__image-wrapper:not(&__image-wrapper:last-child) {
    @apply mr-2 flex-shrink-0;
  }

  &__text-wrapper {
    @apply break-words min-w-0;
  }

  &_checked {
    #{$root}__content {
      @apply bg-neutral-01-15;
    }
  }

  &_disabled {
    #{$root}__content {
      @apply cursor-default;
    }

    #{$root}__answer {
      @apply text-neutral-01-400;
    }
  }
}
</style>
