<template>
  <div class="SearchResponsesSelectedActions">
    <div class="SearchResponsesSelectedActions__content">
      <div v-if="!selectedAll" class="SearchResponsesSelectedActions__label">
        {{ $tc('common_amount_selected_responses', selectedIdAmount) }}
      </div>
      <div v-if="selectedAll" class="SearchResponsesSelectedActions__label">
        {{ $tc('common_amount_selected_responses', total) }}
      </div>
      <div class="SearchResponsesSelectedActions__actions">
        <LLButton :loading="loading" outline small @click="onDownloadClick">{{
          $t('button_download')
        }}</LLButton>
      </div>
      <div class="SearchResponsesSelectedActions__right-actions">
        <button class="SearchResponsesSelectedActions__unselect-button" small @click="unselectAll">
          <u>{{ $t('table_selected_unselect-all') }}</u>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton'
import { SearchCategories, SearchResponsesSortTypes } from '@/common/enums'
import { ForumChatModel } from '@/models/forum/forumChat'
export default {
  name: 'SearchResponsesSelectedActions',
  components: { LLButton },
  props: {
    searchValue: { type: String, default: null },
    selectedIdList: { type: Array, default: () => [] },
    selectedAll: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    filterValues: { type: Object, required: true },
    sortBy: { type: Number, default: null },
    chat: { type: [ForumChatModel, null], default: null }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    selectedObjects() {
      const studyMessageIdList = this.selectedIdList
        .filter((id) => /^study-/.test(id))
        .map((id) => id.replace('study-', ''))
      const chatResponseIdList = this.selectedIdList
        .filter((id) => /^chat-/.test(id))
        .map((id) => id.replace('chat-', ''))
      return {
        studyMessageIdList,
        chatResponseIdList
      }
    },
    selectedIdAmount() {
      return this.selectedIdList?.length
    }
  },
  methods: {
    unselectAll() {
      this.$emit('unselectAll')
    },
    startDownload(url) {
      const link = document.createElement('a')

      link.href = url

      document.body.appendChild(link)

      link.click()
      link.remove()
    },
    async getPreparedLinkForAll() {
      let sort
      if (this.sortBy === SearchResponsesSortTypes.DATE) {
        sort = 'updated_at'
      }

      const { url } = await this.$api.search.prepareExportAllResponses({
        type: SearchCategories.RESPONSES,
        query: this.searchValue,
        filters: this.filterValues,
        sort,
        chatId: this.chat?.id
      })
      return url
    },
    async getPreparedLinkForSome() {
      const { url } = await this.$api.search.prepareExportResponses({
        messageIds: [...this.selectedObjects.studyMessageIdList],
        responseIds: [...this.selectedObjects.chatResponseIdList],
        chatId: this.chat?.id
      })
      return url
    },
    async onDownloadClick() {
      this.loading = true
      try {
        let url
        if (this.selectedAll) {
          url = await this.getPreparedLinkForAll()
        } else {
          url = await this.getPreparedLinkForSome()
        }
        this.startDownload(url)
      } catch {}
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.SearchResponsesSelectedActions {
  @apply w-full bg-primary-01-400 py-4 px-4 text-primary-01 text-body-01;
  @screen lg {
    @apply h-12 px-8;
  }
  &__content {
    @apply h-full w-full flex flex-col items-start;
    @screen lg {
      @apply flex-row items-center;
    }
  }
  &__label {
    @apply text-body-01 text-white;
  }
  &__actions {
    @apply mt-2;
    @screen lg {
      @apply ml-4 mt-0 flex;
    }
  }
  &__action {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
    @screen lg {
      @apply mt-0 ml-4;
      &:first-child {
        @apply ml-0;
      }
    }
  }
  &__list {
    width: 280px;
  }
  &__unselect-button {
    @apply transition duration-200 cursor-pointer text-caption-01 text-white outline-none;
    &:focus-visible {
      @apply text-primary-01-50;
    }
    &:active {
      @apply text-primary-01-100;
    }
    &:hover {
      @apply text-primary-01-50;
    }
  }
  &__right-actions {
    @apply mt-2;
    @screen lg {
      @apply ml-auto mt-0;
    }
  }
}
</style>
