<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="fill-current" cx="12" cy="12" r="12" />
    <path
      d="M16.4723 11.4668H7.52782C7.42039 11.4668 7.33337 11.5464 7.33337 11.6446V12.3557C7.33337 12.4539 7.42039 12.5335 7.52782 12.5335H16.4723C16.5797 12.5335 16.6667 12.4539 16.6667 12.3557V11.6446C16.6667 11.5464 16.5797 11.4668 16.4723 11.4668Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DecrementRoundIcon'
}
</script>

<style lang="scss" scoped>
.DecrementRoundIcon {
}
</style>
