<template>
  <LLPage class="ChatPage">
    <template #breadcrumbs>
      <LLBreadCrumbs :crumbs="crumbs" />
    </template>
    <template #content>
      <LLLoader v-if="isFetching"></LLLoader>
      <LLLoaderError v-else-if="error">
        {{ error }}
      </LLLoaderError>
      <ForumChatMainPage v-else-if="chat" :chat="chat" />
    </template>
  </LLPage>
</template>

<script>
import LLPage from '@/components/layout/LLPage'
import LLLoader from '@/components/common/LLLoader'
import LLLoaderError from '@/components/common/LLLoaderError'
import LLBreadCrumbs from '@/components/common/ui-components/LLBreadCrumbs'
import { ForumChatModel } from '@/models/forum/forumChat'
import ForumChatMainPage from '@/components/pages/forum/chat/main/ForumChatMainPage'
import chatEditor from '@/utils/modules/forum/chatEditor'
export default {
  name: 'ChatPage',
  components: { ForumChatMainPage, LLBreadCrumbs, LLLoaderError, LLLoader, LLPage },
  data() {
    return {
      chat: '',
      isFetching: false,
      error: null,
      redirectedFromDestination: false
    }
  },
  computed: {
    crumbs() {
      return [
        { name: this.$t('main_home_label'), path: '/' },
        {
          name: this.$t('forum.chat_list_page.title', { teamName: this.selectedTeam?.name }),
          path: '/chats'
        },
        {
          name: this.chat?.name || this.$t('forum.breadcrumbs.chat_placeholder')
        }
      ]
    },
    chatIdParam() {
      return +this.$route.params.chatId
    }
  },
  created() {
    if (+this.$store.state?.completeRegistration?.chatId === this.chatIdParam) {
      this.redirectedFromDestination = true
    }
    this.$store.commit('completeRegistration/RESET_DESTINATION')
    this.fetch()
  },
  mounted() {
    chatEditor.connect({
      token: this.$store.state.auth.token.replace('Bearer ', ''),
      chatId: this.chatIdParam
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_UPDATE, ({ chat }) => {
      this.chat = chat
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_MEMBERS_UPDATE, ({ memberCount } = {}) => {
      this.chat.meta.memberCount = memberCount ?? this.serverChat.meta.memberCount
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_META_MEMBERS_UPDATE, ({ memberCount } = {}) => {
      this.chat.meta.memberCount = memberCount ?? this.serverChat.meta.memberCount
    })
    chatEditor.addEvent(chatEditor.enums.CHAT_META_UPDATE, ({ chatMeta }) => {
      this.chat = new ForumChatModel(this.chat)
      this.chat.setMetaFromApi({ meta: chatMeta })
    })
  },
  destroyed() {
    chatEditor.destroy()
  },
  methods: {
    async fetch() {
      this.error = null
      this.isFetching = true
      try {
        const { chat: serverChat } = await this.$api.forum.chats.get({ chatId: this.chatIdParam })
        const chat = ForumChatModel.parseFromApi({ chat: serverChat })
        this.chat = chat
      } catch (e) {
        if (e.response.status === 404) {
          if (this.redirectedFromDestination) {
            this.$router.replace('/chats')
          } else {
            this.$router.replace('/404')
          }
        } else {
          this.error = this.$getErrorMessage(e)
        }
      }
      this.isFetching = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ChatPage {
}
</style>
