<template>
  <div class="PollNumberEditorValue__value-wrapper">
    <LLInputLocked dark-caption class="PollNumberValue__value-wrapper">
      <template #caption>{{ $t('forum.poll.number_value.input_caption') }}</template>
      <ValidationObserver ref="validation-observer">
        <ValidationProvider
          v-slot="{ errors }"
          mode="eager"
          :disalbed="locked"
          :rules="{
            required: true,
            max_value: 2000000000
          }"
        >
          <LLInputText
            v-model="inputValue"
            :errors="errors"
            :disabled="locked"
            class="PollNumberValue__value"
            :mask="{
              alias: 'numeric',
              groupSeparator: '',
              allowMinus: true,
              digits: 0,
              digitsOptional: false,
              placeholder: '',
              rightAlign: false
            }"
          ></LLInputText>
        </ValidationProvider>
      </ValidationObserver>
    </LLInputLocked>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import LLInputLocked from '@/components/common/LLInputLocked.vue'
import LLInputText from '@/components/common/LLInput/LLInputText.vue'
import { PollModel } from '@/models/poll'
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'

export default {
  name: 'PollNumberEditorValue',
  components: { LLInputText, LLInputLocked, ValidationProvider, ValidationObserver },
  props: {
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    locked: { type: Boolean, default: false }
  },
  data() {
    return {
      localValue: '',
      localResponse: new PollResponseModel(),
      isLocalResponseValid: false
    }
  },
  computed: {
    inputValue: {
      get() {
        // Если есть локальное значение, возвращаем его
        if (this.localValue !== '') {
          return this.localValue
        }
        if (this.localResponse.numberValue !== null && this.localResponse.numberValue !== undefined) {
          return String(this.localResponse.numberValue)
        }
        return ''
      },
      set(value) {
        this.localValue = value

        if (value === '' || isNaN(Number(value))) {
          this.localResponse.numberValue = null
        } else {
          this.localResponse.numberValue = Number(value)
        }
      }
    },
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    }
  },
  watch: {
    response: {
      handler() {
        if (!_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$nextTick(() => {
          this.validate()
          this.$emit('update:response', new PollResponseModel(this.localResponse))
        })
      },
      deep: true
    }
  },
  mounted() {
    this.validate()
  },
  methods: {
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver?.validate) {
        const validation = await validationObserver.validateWithInfo({ silent: true })
        this.isLocalResponseValid = validation?.isValid
      } else {
        this.isLocalResponseValid = false
      }
      this.$emit('update:isValid', this.isLocalResponseValid)
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberEditorValue {
  &__value-wrapper {
    max-width: 600px;
    width: 100%;
  }
}
</style>
