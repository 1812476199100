<template>
  <div class="PollChoiceListEditorItemFileUploader">
    <div v-if="uploading" class="PollChoiceListEditorItemFileUploader__loading-wrapper">
      <LoadingIcon class="PollChoiceListEditorItemFileUploader__loading" />
    </div>
    <LLFileUploaderWrapper
      v-show="!uploading && !image"
      file-mime-type="image/*,image/heic"
      :multiple="false"
      @upload="onUpload"
      @startUpload="onStartUpload"
    >
      <LLButton tertiary small data-e2e="optionAddFileButton">
        <template #icon-left>
          <PhotoIcon />
        </template>
      </LLButton>
    </LLFileUploaderWrapper>
    <div v-if="image" class="PollChoiceListEditorItemFileUploader__files-wrapper">
      <LLFileSlider
        class="PollChoiceListEditorItemFileUploader__files"
        :files="[image]"
        single
        small
        :cropper="!isCloned"
        editable
        @update:files="onUpdateFile"
      ></LLFileSlider>
    </div>
  </div>
</template>

<script>
import PhotoIcon from '@/assets/icons/PhotoIcon.vue'
import LoadingIcon from '@/components/common/LoadingIcon.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLFileUploaderWrapper from '@/components/common/LLFileUploader/LLFileUploaderWrapper.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import { PollChoiceModel } from '@/models/pollChoice'
import { FileModel } from '@/models/file'

export default {
  name: 'PollChoiceListEditorItemFileUploader',
  components: { LLFileSlider, LLFileUploaderWrapper, LLButton, LoadingIcon, PhotoIcon },
  props: {
    choiceOption: { type: PollChoiceModel, required: true },
    isCloned: { type: Boolean, default: false }
  },
  data() {
    return {
      uploading: false
    }
  },
  computed: {
    image() {
      return this.choiceOption?.image
    }
  },
  methods: {
    onUpload(files) {
      this.uploading = false
      const file = files[0]
      this.$emit(
        'update:choiceOption',
        new PollChoiceModel({ ...this.choiceOption, image: FileModel.parseFromApi({ file }) })
      )
    },
    onUpdateFile(files) {
      this.$emit(
        'update:choiceOption',
        new PollChoiceModel({ ...this.choiceOption, image: files?.[0] || null })
      )
    },
    onStartUpload() {
      this.uploading = true
    }
  }
}
</script>

<style scoped lang="scss">
.PollChoiceListEditorItemFileUploader {
  &__loading {
    @apply w-4 h-4;
  }
  &__loading-wrapper {
    @apply w-full h-full flex items-center justify-center;
  }
  &__files {
    @apply w-14  absolute;
    margin-top: -9px;
  }
  &__files-wrapper {
    @apply w-14 relative h-full;
  }
}
</style>
