<template>
  <button class="LLAddLabel" :disabled="disabled" :class="classes" @click="onClick">
    <div class="LLAddLabel__icon">
      <slot name="icon">
        <PlusRoundedSolidIcon class="LLAddLabel__icon-remove" />
      </slot>
    </div>
    <div class="LLAddLabel__label">
      <slot></slot>
    </div>
  </button>
</template>

<script>
import PlusRoundedSolidIcon from '@/assets/icons/PlusRoundedSolidIcon'
export default {
  name: 'LLAddLabel',
  components: { PlusRoundedSolidIcon },
  props: {
    disabled: { type: Boolean, default: false },
    blue: { type: Boolean, default: false },
    red: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      const classes = []
      const rootClass = 'LLAddLabel'
      if (this.disabled) {
        classes.push(`${rootClass}_disabled`)
      }
      if (this.blue) {
        classes.push(`${rootClass}_blue`)
      } else if (this.red) {
        classes.push(`${rootClass}_red`)
      }
      if (this.small) {
        classes.push(`${rootClass}_small`)
      }
      if (this.tiny) {
        classes.push(`${rootClass}_tiny`)
      }
      return classes
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLAddLabel {
  @apply flex items-center;
  $root: &;
  &__icon {
    @apply flex-shrink items-center justify-center flex rounded-full transition duration-200 w-6 h-6;
  }
  &__label {
    @apply text-primary-01-400 ml-2 min-w-0 truncate text-button-01;
  }
  &:hover {
    #{$root}__label {
      @apply text-primary-01-300;
    }
  }
  &:focus {
    @apply outline-none;
    #{$root}__label {
      @apply text-primary-01-300;
    }
  }
  &:active {
    #{$root}__label {
      @apply text-primary-01-600;
    }
  }
  &:disabled {
    @apply cursor-default;
    #{$root}__label {
      @apply text-primary-01-50;
    }
  }

  &_small {
    #{$root}__icon {
      @apply w-5 h-5;
    }
  }
  &_tiny {
    #{$root}__icon {
      @apply w-3 h-3;
    }
    #{$root}__label {
      @apply text-body-02;
    }
  }

  &_blue {
    #{$root}__icon {
      @apply text-primary-01-400;
    }
    &:hover {
      #{$root}__icon {
        @apply text-primary-01-300;
      }
    }
    &:focus {
      #{$root}__icon {
        @apply text-primary-01-300;
      }
    }
    &:active {
      #{$root}__icon {
        @apply text-primary-01-600;
      }
    }
    &:disabled {
      #{$root}__icon {
        @apply text-primary-01-50;
      }
    }
  }

  &_red {
    #{$root}__icon {
      @apply text-accent-01-400;
    }
    &:hover {
      #{$root}__icon {
        @apply text-accent-01-300;
      }
    }
    &:focus {
      #{$root}__icon {
        @apply text-accent-01-300;
      }
    }
    &:active {
      #{$root}__icon {
        @apply text-accent-01-600;
      }
    }
    &:disabled {
      #{$root}__icon {
        @apply text-accent-01-50;
      }
    }
  }
}
</style>
