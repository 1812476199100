<template>
  <div
    class="ConversationProgress pt-6 pb-4 border-neutral-01-50 border rounded flex flex-col items-center justify-between"
    :class="{ 'full-width': fullWidth }"
  >
    <div class="text-base font-bold text-2xl font-serif mob-only:hidden">
      {{ $t('conversation_progress_title') }}
    </div>
    <div class="mt-5 mob-only:mt-0">
      <LLRoundProgress
        size="36"
        :medium="tailwindScreens.mobOnly"
        :internal="inProgressQuestionsPercentage"
        :external="completedQuestionsPercentage"
        data-e2e="studyRoundProgress"
      >
        <div class="text-primary-01-400">
          <div class="ConversationProgress__progress-percent text-center text-2xl font-semibold">
            {{ inProgressQuestionsPercentage }}<span class="text-sm">%</span>
          </div>
        </div>
      </LLRoundProgress>
    </div>
    <div class="mt-6 mob-only:mt-5 ConversationProgress__progress-values-wrapper">
      <StudyProgress
        :completed="completedQuestionsCount"
        :answered="inProgressQuestionsCount"
        :not-started="notAnsweredQuestionsCount"
        :all="allQuestionsCount"
        class="ConversationProgress__progress-values w-44"
        data-e2e="StudyProgress"
      ></StudyProgress>
    </div>
  </div>
</template>

<script>
import LLRoundProgress from '@/components/common/ui-components/LLRoundProgress'
import StudyProgress from '@/components/study/StudyProgress'
import { mapGetters } from 'vuex'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StudyModel } from '@/models/study'
import { StreamState } from '@/common/enums'

export default {
  name: 'ConversationProgress',
  components: { StudyProgress, LLRoundProgress },
  mixins: [screenCatcher],
  props: {
    fullWidth: { type: Boolean, default: false },
    study: { type: StudyModel, required: true },
    questionIdList: { type: Array, required: true, default: () => [] },
    conversationStreams: { type: [Array, null], default: () => null }
  },
  computed: {
    ...mapGetters('study', ['selectedStudy']),
    conversationStreamsObject() {
      return this.conversationStreams?.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    allQuestionsCount() {
      return this.questionIdList.length
    },
    inProgressQuestionsPercentage() {
      return (
        Math.ceil(
          ((this.inProgressQuestionsCount + this.completedQuestionsCount) * 100) / this.allQuestionsCount
        ) || 0
      )
    },
    completedQuestionsPercentage() {
      return Math.ceil((this.completedQuestionsCount * 100) / this.allQuestionsCount)
    },
    notAnsweredQuestionsPercentage() {
      return Math.round((this.notAnsweredQuestionsCount * 100) / this.allQuestionsCount)
    },
    inProgressQuestionsCount() {
      return this.questionIdList.filter(
        (questionId) => this.conversationStreamsObject[questionId]?.state === StreamState.ANSWERED
      ).length
    },
    completedQuestionsCount() {
      return this.questionIdList.filter(
        (questionId) => this.conversationStreamsObject[questionId]?.state === StreamState.RESOLVED
      ).length
    },
    notAnsweredQuestionsCount() {
      return this.allQuestionsCount - this.inProgressQuestionsCount - this.completedQuestionsCount
    }
  }
}
</script>

<style lang="scss" scoped>
.ConversationProgress {
  $root: &;
  padding-left: 62px;
  padding-right: 62px;
  &__progress-percent {
    font-size: 32px;
  }
  &.full-width {
    padding-left: 0;
    padding-right: 0;
    #{$root}__progress-values {
      width: 100%;
    }
    #{$root}__progress-values-wrapper {
      width: 100%;
    }
  }
}
</style>
