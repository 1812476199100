import sanitizeHtml from 'sanitize-html'

export class MessageModel {
  constructor(messageData = {}) {
    const {
      id,
      questionId,
      studyId,
      topicId,
      chosenOptions,
      fileIdList,
      starsValue,
      textValue,
      rangeInteracted,
      yesOrNo,
      rangeValue,
      sendingPromise
    } = messageData
    this.id = id || null

    this.questionId = questionId || null
    this.studyId = studyId || null
    this.topicId = topicId || null

    this.textValue = textValue || ''
    this.fileIdList = fileIdList?.length ? [...fileIdList] : []

    this.chosenOptions = chosenOptions?.length ? [...chosenOptions] : []
    this.starsValue = starsValue || null
    this.yesOrNo = yesOrNo || null
    this.rangeInteracted = rangeInteracted || false
    this.rangeValue = rangeValue || null
    this.sendingPromise = sendingPromise || null
  }

  static parseFromApi({ message }) {
    return new MessageModel({
      id: message?.id
    })
  }

  static parseFromTempMessage({ message, sendingPromise, questionId, studyId, topicId }) {
    return new MessageModel({
      id: message?.id,
      questionId: questionId,
      studyId: studyId,
      topicId: topicId,
      chosenOptions: message.chosenOptions,
      fileIdList: message.fileIdList,
      textValue: message.currentTextValue,
      starsValue: message.currentStarsValue,
      rangeInteracted: message.currentRangeInteracted,
      rangeValue: message.currentRangeValue,
      yesOrNo: message.yesOrNo,
      sendingPromise: sendingPromise
    })
  }

  get isEmpty() {
    return (
      !sanitizeHtml(this.textValue, { allowedTags: [] }).length &&
      !this.fileIdList?.length &&
      (this.starsValue === null || this.starsValue === undefined) &&
      (this.rangeValue === null || this.rangeValue === undefined || !this.rangeInteracted) &&
      (this.yesOrNo === null || this.yesOrNo === undefined) &&
      !this.chosenOptions.length
    )
  }
}
