<template>
  <div class="ForumChatThreadCardHeaderMainAsideHeaderMainAside" :class="classList">
    <LLPopper
      v-if="isReactionsEnabled"
      class="ForumChatThreadCardHeaderMainAsideHeaderMainAside__action"
      :offset="[0, 0]"
      tooltip-white
      :show.sync="isAnimatedReactionSilectorVisible"
      placement="top-start"
    >
      <template #trigger>
        <LLButton :tertiary-dark="!dark" :tertiary-white="dark" small>
          <template #icon-left>
            <ReactionAnimatedIcon />
          </template>
        </LLButton>
      </template>
      <template #tooltip>
        <LLTextareaEmoji show-animated-only @select="onReactionSelect" />
      </template>
    </LLPopper>
    <LLPopper
      v-if="isReactionsEnabled"
      class="ForumChatThreadCardHeaderMainAsideHeaderMainAside__action"
      :offset="[0, 0]"
      tooltip-white
      :show.sync="isReactionSilectorVisible"
      placement="top-start"
    >
      <template #trigger>
        <LLButton :tertiary-dark="!dark" :tertiary-white="dark" small>
          <template #icon-left>
            <ReactionIcon />
          </template>
        </LLButton>
      </template>
      <template #tooltip>
        <LLTextareaEmoji show-suggested @select="onReactionSelect" />
      </template>
    </LLPopper>
    <div class="ForumChatThreadCardHeaderMainAsideHeaderMainAside__action">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import ReactionIcon from '@/assets/icons/ReactionIcon.vue'
import ReactionAnimatedIcon from '@/assets/icons/ReactionAnimatedIcon.vue'
import LLPopper from '@/components/utils/LLPopper.vue'
import LLTextareaEmoji from '@/components/common/LLTextarea/LLTextareaEmoji.vue'
import { ForumThreadState } from '@/common/enums'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'ForumChatThreadCardHeaderMainAsideHeaderMainAside',
  components: { LLTextareaEmoji, LLPopper, ReactionAnimatedIcon, ReactionIcon, LLButton },
  props: {
    isReactionAnimationsEnabled: { type: Boolean, default: true },
    thread: { type: ForumThreadModel, required: true },
    dark: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false }
  },
  data() {
    return {
      isReactionSilectorVisible: false,
      isAnimatedReactionSilectorVisible: false
    }
  },
  computed: {
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.mobile,
        [`${this.$options.name}_compact`]: this.compact
      }
    },
    isReactionsEnabled() {
      return this.thread?.state === ForumThreadState.ACTIVE
    }
  },
  methods: {
    onReactionSelect({ emoji: reaction }) {
      this.isReactionSilectorVisible = false
      this.isAnimatedReactionSilectorVisible = false
      this.$emit('reactionSelect', { reaction })
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatThreadCardHeaderMainAsideHeaderMainAside {
  $root: &;
  @apply flex ml-4;
  &__action {
    @apply ml-1;
    @at-root #{$root}_mobile & {
      @apply ml-0.5;
    }
    &:first-child {
      @apply ml-0;
    }
  }
}
</style>
