<template>
  <div class="LLSearchBar" :class="classList">
    <input
      v-model="currentValue"
      class="LLSearchBar__input"
      :placeholder="placeholder"
      type="text"
      :disabled="disabled"
      @keypress="onKeyPress"
      @focus="inputFocus"
      @blur="inputBlur"
    />
    <button v-if="!disabled" class="LLSearchBar__cleaner-wrapper" @click="cleanInput">
      <CloseIcon class="LLSearchBar__cleaner"></CloseIcon>
    </button>
    <div class="LLSearchBar__button-wrapper">
      <LLButton :disabled="disabled" class="LLSearchBar__button" @click="searchAction">
        <template #icon-left>
          <SearchIcon />
        </template>
      </LLButton>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/CloseIcon'
import LLButton from '@/components/common/ui-components/LLButton'
import SearchIcon from '@/assets/icons/SearchIcon'
export default {
  name: 'LLSearchBar',
  components: { SearchIcon, LLButton, CloseIcon },
  props: {
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' }
  },
  data() {
    return {
      currentValue: '',
      active: false
    }
  },
  computed: {
    filled() {
      return !!this.currentValue.length
    },
    classList() {
      const baseClass = 'LLSearchBar'
      const classes = []
      if (this.filled) {
        classes.push(`${baseClass}_filled`)
      }
      if (this.disabled) {
        classes.push(`${baseClass}_disabled`)
      }
      if (this.active) {
        classes.push(`${baseClass}_active`)
      }
      return classes
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.currentValue = this.value
      }
    },
    currentValue: {
      handler() {
        this.$emit('input', this.currentValue)
      }
    }
  },
  methods: {
    onKeyPress(e) {
      if (e.keyCode === 13) {
        this.searchAction()
      }
    },
    cleanInput() {
      if (this.disabled) return
      this.currentValue = ''
      this.$nextTick(() => {
        this.searchAction()
      })
    },
    inputFocus() {
      this.active = true
    },
    inputBlur() {
      this.active = false
    },
    searchAction() {
      this.$emit('search', this.currentValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.LLSearchBar {
  $root: &;
  /** elements **/
  @apply pl-4 flex h-11 items-center border rounded transition duration-200 bg-white;
  width: 328px;
  @screen xs {
    width: auto;
  }
  padding-right: 2px;
  &__input {
    @apply text-body-01 border-0 bg-transparent outline-none flex-grow min-w-0;
    &::placeholder {
      @apply text-neutral-01-400 text-body-01;
    }
    @apply text-neutral-01-900;
  }
  &__cleaner-wrapper {
    @apply flex-shrink-0 px-3 mr-1 cursor-pointer h-full items-center text-neutral-01-400 outline-none transition duration-200;
    #{$root}__cleaner {
      @apply h-3;
    }
    &:hover {
      @apply text-neutral-01-700;
    }
    &:focus {
      @apply text-neutral-01-700;
    }
    &:active {
      @apply text-neutral-01-900;
    }
  }
  &__button-wrapper {
    @apply flex-shrink-0;
    #{$root}__button {
      @apply cursor-pointer;
    }
  }
  /** states **/
  & {
    @apply border-neutral-01-75;
    #{$root}__input {
    }
    #{$root}__cleaner-wrapper {
      @apply hidden;
      #{$root}__cleaner {
      }
    }
    #{$root}__button-wrapper {
      #{$root}__button {
        width: 38px;
        height: 38px;
      }
    }
  }
  &_active {
    border-color: var(--color-neutral-01-600) !important;
  }
  &:hover {
    @apply border-neutral-01-400;
  }
  &_filled {
    #{$root}__input {
    }
    #{$root}__cleaner-wrapper {
      @apply flex;
      #{$root}__cleaner {
      }
    }
    #{$root}__button-wrapper {
      #{$root}__button {
      }
    }
  }
  &_disabled {
    border-color: var(--color-neutral-01-100) !important;
    #{$root}__input {
      &::placeholder {
        @apply text-neutral-01-100;
      }
      @apply text-neutral-01-100;
    }
    #{$root}__cleaner-wrapper {
      cursor: default;
      #{$root}__cleaner {
        @apply text-neutral-01-100;
      }
    }
    #{$root}__button-wrapper {
      #{$root}__button {
        cursor: default;
      }
    }
  }
  @import '../../assets/styles/components/input';
}
</style>
