import { Node } from '@tiptap/core'

const Mention = Node.create({
  name: 'mention',
  atom: true,
  selectable: false,
  draggble: false,
  content: 'text*',
  isolating: true,
  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {}
    }
  },
  inline() {
    return this.options.inline
  },
  group() {
    return this.options.inline ? 'inline' : 'block'
  },
  draggable: false,
  addAttributes() {
    return {
      prospectId: {
        default: null
      },
      userId: {
        default: null
      },
      fullName: {
        default: null
      }
    }
  },
  renderText({ node }) {
    return `@${node.attrs.fullName}`
  },
  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => {
          if (!element.classList.contains('mention')) {
            return false
          } else {
            const prospectId = element.getAttribute('prospectId')
            const userId = element.getAttribute('userId')
            const fullName = element.getAttribute('fullName')
            return {
              prospectId,
              userId,
              fullName
            }
          }
        }
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    const prospectId = HTMLAttributes.prospectId
    const userId = HTMLAttributes.userId
    const fullName = HTMLAttributes.fullName
    return [
      'span',
      {
        class: 'mention',
        prospectId,
        userId,
        fullName
      },
      '@' + fullName
    ]
  },
  addCommands() {
    return {
      setMention:
        (options) =>
        ({ commands }) => {
          commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  }
})

export { Mention, Mention as default }
