<template>
  <div class="MainLayout flex flex-col min-h-screen h-auto w-full">
    <SiteHeader />
    <div class="flex-auto main-content relative">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <SiteFooter />
    <ScrollToTopButton />
    <CookiesAgreement class="z-50 fixed bottom-0 w-full" />
    <InfoPopup />
    <portal-target name="confirm-modal"></portal-target>
  </div>
</template>

<script>
import SiteHeader from '@/components/layout/SiteHeader'
import SiteFooter from '@/components/layout/SiteFooter'
import CookiesAgreement from '@/components/layout/cookies/CookiesAgreement'
import ScrollToTopButton from '@/components/common/ScrollToTopButton'
import InfoPopup from '@/components/common/InfoPopup.vue'

export default {
  name: 'MainLayout',
  components: { InfoPopup, ScrollToTopButton, CookiesAgreement, SiteFooter, SiteHeader },
  computed: {
    user() {
      return this.$store.getters['user/user']
    }
  },
  watch: {
    user: {
      async handler() {
        if (this.$store.state.auth.token) {
          if (this.user.requireRegistration) {
            try {
              await this.$router.push({ name: 'CompleteProfile' })
            } catch {}
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MainLayout {
  .main-content {
    padding-top: var(--header-height);
  }
}
</style>
