<template>
  <svg
    class="icon fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.768 17.5H12.4858C12.4858 18.875 11.3551 20 9.97298 20C8.59089 20 7.4601 18.875 7.4601 17.5H1.17791C0.675338 17.5 0.172761 17.125 0.0471166 16.625C-0.0785276 16.125 0.0471166 15.5 0.424049 15.25C1.68049 14.25 2.43435 12.875 2.43435 11.25V7.5C2.43435 3.375 5.82673 0 9.97298 0C14.1192 0 17.5116 3.375 17.5116 7.5V11.25C17.5116 12.875 18.2655 14.25 19.5219 15.25C19.8988 15.625 20.1501 16.125 19.8988 16.625C19.7732 17.125 19.2706 17.5 18.7681 17.5H18.768Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'NotificationIcon'
}
</script>
