import store from '@/store'
import { CompleteRegistrationDestinationType } from '@/common/enums'
import { ProspectModel } from '@/models/prospect'

const isAuthorized = (to, from, next) => {
  if (store.getters['auth/isAuthorised']) {
    next()
  } else {
    next('/sign-in')
  }
}

const registrationRequired = (to, from, next) => {
  const user = ProspectModel.parseFromApi({ prospect: store.getters['user/user'] })
  if (user.requireRegistration) {
    next({ name: 'CompleteProfile' })
  } else {
    next()
  }
}

const registrationNotRequired = (to, from, next) => {
  const user = ProspectModel.parseFromApi({ prospect: store.getters['user/user'] })
  if (!user.requireRegistration) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
}

const destinationRedirectIfExists = (to, from, next) => {
  if (
    store.state?.completeRegistration?.destination === CompleteRegistrationDestinationType.STUDY &&
    store.state?.completeRegistration?.studyId
  ) {
    next(`/study/${store.state.completeRegistration.studyId}`)
  } else if (
    store.state?.completeRegistration?.destination === CompleteRegistrationDestinationType.CHAT &&
    store.state?.completeRegistration?.chatId
  ) {
    next(`/chats/${store.state.completeRegistration.chatId}`)
  } else {
    next()
  }
}

const test = (to, from, next) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('resolve')
      resolve(next())
    }, 1000)
  })
}

export default {
  isAuthorized,
  destinationRedirectIfExists,
  registrationRequired,
  registrationNotRequired,
  test
}
