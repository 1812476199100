<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.02434 11.534C6.82353 11.534 5.8499 11.239 5.11427 10.6491C4.37863 10.0539 4 9.22162 4 8.14707V2H6.2664V8.15234C6.2664 8.73175 6.41785 9.15841 6.72617 9.43759C7.03448 9.71676 7.46721 9.85371 8.02434 9.85371C8.57607 9.85371 8.99797 9.71149 9.29547 9.43759C9.59297 9.15841 9.72819 8.73175 9.72819 8.14707V2H12V8.15234C12 9.22689 11.6376 10.0591 10.902 10.6491C10.1717 11.239 9.21433 11.534 8.02434 11.534ZM3 13H13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H3C2.44772 15 2 14.5523 2 14C2 13.4477 2.44772 13 3 13Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UnderlineIcon'
}
</script>
