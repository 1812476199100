<template>
  <svg
    class="icon fill-current"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 8.91985L1.2096 15.7103L0.290359 14.791L7.08076 8.00061L0.290409 1.21025L1.20965 0.291016L8 7.08137L14.7904 0.291016L15.7096 1.21025L8.91924 8.00061L15.7096 14.791L14.7904 15.7103L8 8.91985Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
