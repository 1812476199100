<template>
  <ForumReactions
    show-animation
    :reactions="mergedReactions"
    :disabled="disabled"
    :disable-reactanimation="disableReactanimation"
    @select="onSelectReaction"
    @viewReaction="onViewReaction"
  />
</template>

<script>
import { ForumResponseModel } from '@/models/forum/forumResponse'
import emojiToHtml from '@/utils/emojiToHtml'
import { ForumReactionModel } from '@/models/forum/forumReaction'
import ForumReactions from '@/components/pages/forum/ForumReactions'
import { ReactionsEnums } from '@/common/reactionsEnums'
import _ from 'lodash'

const debounceTime = 1000

export default {
  name: 'ForumResponseReactions',
  components: { ForumReactions },
  props: {
    response: { type: ForumResponseModel, required: true },
    reactionEmojiList: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    disableReactanimation: { type: Boolean, default: false }
  },
  data() {
    return {
      debounceActive: false,
      localReactionEmojiList: {},
      updatingRequest: null,
      cachedReactions: null
    }
  },
  computed: {
    reactionsLength() {
      return this.response?.reactions?.length
    },
    mergedReactions() {
      const currentReactions = {}
      this.response.reactions.forEach((reaction) => {
        currentReactions[reaction.emoji] = reaction
      })

      const unknownReactions = this.reactionEmojiList.filter((emoji) => !currentReactions[emoji])
      const allReactions = [
        ...this.response.reactions.map((reaction) => ({
          ...reaction,
          emojiHtml: emojiToHtml.span(reaction.emoji)
        })),
        ...unknownReactions.map((emoji) => ({
          emoji,
          emojiHtml: emojiToHtml.span(emoji),
          count: 1,
          isViewed: true,
          isOwnReaction: true
        }))
      ]

      return allReactions
        .map((reaction) => {
          let count
          if (!reaction.isOwnReaction && this.reactionEmojiList.find((emoji) => emoji === reaction.emoji)) {
            count = reaction.count + 1
          } else if (
            reaction.isOwnReaction &&
            !this.reactionEmojiList.find((emoji) => emoji === reaction.emoji)
          ) {
            count = reaction.count - 1
          } else {
            count = reaction.count
          }
          return {
            ...reaction,
            count,
            isOwnReaction: this.reactionEmojiList.find((emoji) => emoji === reaction.emoji)
          }
        })
        .filter((reaction) => reaction.count !== 0)
        .sort((a, b) => {
          if (a.count !== b.count) {
            return b.count - a.count
          }
          if (a.isOwnReaction && !b.isOwnReaction) {
            return -1
          } else if (!a.isOwnReaction && b.isOwnReaction) {
            return 1
          } else {
            return 0
          }
        })
    }
  },
  watch: {
    response: {
      handler() {
        if (this.debounceActive) {
          return
        }
        const localReactionEmojiList =
          this.response?.reactions
            ?.filter((reaction) => reaction?.isOwnReaction)
            ?.map((reaction) => reaction?.emoji) || []
        if (!this.$_.isEqual(localReactionEmojiList, this.localReactionEmojiList)) {
          this.localReactionEmojiList = localReactionEmojiList
        }
      }
    },
    reactionEmojiList: {
      deep: true,
      handler() {
        if (this.debounceActive) {
          return
        }
        if (!this.$_.isEqual(this.reactionEmojiList, this.localReactionEmojiList)) {
          this.localReactionEmojiList = [...this.reactionEmojiList]
          this.sendReactions()
        }
      }
    },
    localReactionEmojiList: {
      deep: true,
      handler() {
        this.$emit('updateReactionEmojiList', [...this.localReactionEmojiList])
      }
    }
  },
  created() {
    this.initReactionEmojiList()
  },
  methods: {
    initReactionEmojiList() {
      const reactions = this.response?.reactions
        ?.filter((reaction) => reaction?.isOwnReaction)
        .map((reaction) => reaction.emoji)
      this.localReactionEmojiList = reactions
      this.$emit('updateReactionEmojiList', [...this.localReactionEmojiList])
    },
    onViewReaction(reaction) {
      this.$api.forum.responses.viewReaction({
        chatId: this.response.chatId,
        threadId: this.response.threadId,
        responseId: this.response.id,
        emoji: reaction.emoji
      })
    },
    onSelectReaction(reaction) {
      const reactionIndex = this.localReactionEmojiList.indexOf(reaction.emoji)
      if (reactionIndex !== -1) {
        this.localReactionEmojiList.splice(reactionIndex, 1)
      } else {
        this.localReactionEmojiList.push(reaction.emoji)
      }

      this.sendReactions()
    },
    sendReactions() {
      this.debounceActive = true
      this.sendReactionsDebounced()
    },
    sendReactionsDebounced: _.debounce(function () {
      const isEmojiAnimated = (emoji) => {
        return !!Object.keys(ReactionsEnums).find((reactionKey) => ReactionsEnums[reactionKey] === emoji)
      }
      this.debounceActive = false
      const updatingRequest = this.$api.forum.responses.updateReactions({
        chatId: this.response.chatId,
        threadId: this.response.threadId,
        responseId: this.response.id,
        emojiList: this.localReactionEmojiList.map((emoji) => ({
          emoji,
          isAnimated: isEmojiAnimated(emoji)
        }))
      })
      this.updatingRequest = updatingRequest
      const currentReactions = this.response.reactions.map((reaction) => new ForumReactionModel(reaction))
      updatingRequest
        .then((response) => {
          if (this.updatingRequest !== updatingRequest) {
            return
          }
          const reactions = response?.reactions?.map((reaction) =>
            ForumReactionModel.parseFromApi({ reaction })
          )
          if (this.debounceActive) {
            this.cachedReactions = reactions
          }
          this.cachedReactions = null
          console.log('update')
          this.$emit('updateReactions', { reactions })
        })
        .catch((e) => {
          console.log(e)
          if (this.cachedReactions) {
            this.$emit('updateReactions', { reactions: this.cachedReactions })
            this.cachedReactions = null
          } else {
            this.$emit('updateReactions', { reactions: currentReactions })
          }
        })
    }, debounceTime)
  }
}
</script>

<style lang="scss" scoped>
.ForumResponseReactions {
}
</style>
